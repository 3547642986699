import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// React i18next
import { withTranslation } from "react-i18next";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import FlagSelect from "kayfik/components/FlagSelect/FlagSelect.jsx";
import IconButton from "@material-ui/core/IconButton";

// eslint-disable-next-line react/prop-types

class EditAccDetails extends React.Component {
  constructor(props) {
    super(props);
    const { userDetails } = this.props;
    this.state = {
      language: userDetails && userDetails.language ? userDetails.language : "",
      phone: userDetails && userDetails.phone ? userDetails.phone : ""
    };
  }
  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  handleOnChange = (value, data) => {
    const { userDetails } = this.props;
    let code = "+" + data.dialCode;
    if (value.indexOf(code) === 0) {
      this.setState({ phone: value });
    } else if (userDetails && data.dialCode !== userDetails.country_code) {
      this.setState({ phone: code });
    } else {
      this.setState({ phone: this.state.phone });
    }
  };

  handleSubmit = e => {
    const { editUserDetails } = this.props;
    const { phone } = this.state;
    e.preventDefault();

    let form_data = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      phone: phone
      //language: e.target.language.value
    };
    editUserDetails(form_data);
  };

  handleLanguageChange = value => {
    this.setState({ language: value.value });
  };
  handleSelectChange = value => {
    document.getElementById("editDetailsLanguage").value = value.value;
  };
  render() {
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      closeModal,
      userDetails,
      t,
      direction,
      account_fetching
    } = this.props;

    // var { language } = this.state;

    return (
      <div>
        {account_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {" "}
                {t("profileModal.accountSettings.generalUser.title")}
              </h2>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <GridContainer className="mt-3">
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText={t(
                        "profileModal.accountSettings.generalUser.firstname"
                      )}
                      id="id_first_name"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "first_name",
                        required: true,
                        type: "text",
                        defaultValue: userDetails.first_name
                          ? userDetails.first_name
                          : null,
                        disableUnderline: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText={t(
                        "profileModal.accountSettings.generalUser.lastname"
                      )}
                      id="id_last_name"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "last_name",
                        required: true,
                        type: "text",
                        defaultValue: userDetails.last_name
                          ? userDetails.last_name
                          : null,
                        disableUnderline: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} className="dp-f alignItem">
                    <FlagSelect
                      value={this.state.phone}
                      handleFlagOnChange={this.handleOnChange}
                    />
                    <CustomInput
                      labelText={t(
                        "profileModal.accountSettings.generalUser.phone"
                      )}
                      id="id_phone"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "phone",
                        required: true,
                        type: "number",
                        disableUnderline: true,
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText={t(
                        "profileModal.accountSettings.generalUser.email"
                      )}
                      id="id_email"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "email",
                        required: true,
                        type: "email",
                        defaultValue: userDetails.email
                          ? userDetails.email
                          : null,
                        disableUnderline: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={10}>
                    <Button
                      color="primaryWhiteWithBorder"
                      size="lg"
                      className="mb-3"
                      fullWidth
                      type="submit"
                    >
                      {t("profileModal.accountSettings.generalUser.save")}
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  editUserDetails: form_data => {
    dispatch({
      type: AccountActionTypes.EDIT_USER_DETAIL_REQUEST,
      payload: form_data
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(EditAccDetails);
