import { takeLatest } from "redux-saga/effects";
// Modal actions
import ModalActionTypes from "constants/modalConstants.jsx";
import { showModalWorker, hideModalWorker } from "sagas/modalSaga.jsx";
// Global actions
import GlobalActionTypes from "constants/globalConstants.jsx";
import { languageWorker, pageDataWorker } from "sagas/globalSaga.jsx";
//Combo Actions
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import {
  productClassListWorker,
  productDuplicateListWorker,
  productClassAttrWorker,
  productCreateWorker,
  productCreateImagesWorker
} from "sagas/comboProductSaga.jsx";
// Account actions
import AccountActionTypes from "constants/accountConstants.jsx";
import {
  loginWorker,
  personalSignupWorker,
  forgotPasswordWorker,
  signOutWorker,
  companySignUpWorker,
  checkValidTokenWorker,
  userDetailsWorker,
  editUserDetailsWorker,
  changePasswordWorker,
  postConfirmEmailWorker,
  postResendEmailConfirmationWorker,
  postResendPhoneConfirmationWorker,
  socialLoginWorker,
  getPaymentInfoWorker,
  postPaymentInfoWorker,
  putPaymentInfoWorker,
  passwordResetWorker,
  postPhoneCodeVerify,
  patchPhoneNumberWorker,
  businessAccountWorker
} from "sagas/accountSaga.jsx";

import {
  postUserAddress,
  getUserAddressList,
  updateUserAddressList,
  getAllowedAreaList
} from "sagas/addressSaga.jsx";

import {
  sendMessageWorker,
  subscribeEmailWorker,
  getTimeWindowWorker
} from "sagas/globalSaga.jsx";

//Product Actions
import ProductActionTypes from "constants/productConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import BidActionTypes from "constants/bidConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";

import {
  getProductListWorker,
  getProductDetailWorker,
  getInitialFacetsWorker
} from "sagas/productSaga.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import {
  getPartnerProductListWorker,
  getPartnerProductBidListWorker,
  postProcessBidWorker,
  getPartnerProductDetailWorker,
  postPickupWorker,
  getPartnerAddressWorker,
  postPartnerAddressWorker,
  getReplacementProductWorker,
  getBidDetailWorker,
  deletePartnerProductWorker,
  createInitialCounterOfferWorker,
  getCounterOfferListWorker,
  getCounterOfferDetailWorker,
  getAuctionNotificationCountWorker,
  patchCounterOfferWorker,
  createCounterOfferWorker,
  postNotificationCountUrlWorker,
  getPartnerProductDeliveryListWorker
} from "sagas/vendorProductSaga.jsx";

import {
  getReviewCheckoutWorker,
  postAddToCartWorker,
  deleteItemWorker,
  updateCartWorker,
  getOrderListWorker,
  postCheckoutWorker,
  postPaymentWorker,
  getOrderDetailWorker,
  PostPromoCodeWorker,
  DeletePromoCodeWorker
} from "sagas/cartSaga.jsx";

import { postBid, getCreateBidHistory } from "sagas/bidSaga.jsx";

function* watcherSaga() {
  // modal
  yield takeLatest(ModalActionTypes.SHOW_MODAL_REQUEST, showModalWorker);
  yield takeLatest(ModalActionTypes.HIDE_MODAL_REQUEST, hideModalWorker);
  // global (language, tokens)
  yield takeLatest(GlobalActionTypes.CHANGE_LANGUAGE_REQUEST, languageWorker);
  yield takeLatest(GlobalActionTypes.CMS_PAGE_REQUEST, pageDataWorker);

  //Contact send message
  yield takeLatest(
    GlobalActionTypes.POST_SEND_MESSAGE_REQUEST,
    sendMessageWorker
  );

  //Subscribe email
  yield takeLatest(
    GlobalActionTypes.POST_SUBSCRIBE_REQUEST,
    subscribeEmailWorker
  );
  //Replacement product
  yield takeLatest(
    VendorProductActionTypes.GET_REPLACEMENT_PRODUCT_REQUEST,
    getReplacementProductWorker
  );

  //PArtner address
  yield takeLatest(
    VendorProductActionTypes.GET_PARTNER_ADDRESS_REQUEST,
    getPartnerAddressWorker
  );
  yield takeLatest(
    VendorProductActionTypes.POST_PARTNER_ADDRESS_REQUEST,
    postPartnerAddressWorker
  );

  //Vendor Products list
  yield takeLatest(
    VendorProductActionTypes.GET_PARTNER_PRODUCT_LIST_REQUEST,
    getPartnerProductListWorker
  );
  yield takeLatest(
    VendorProductActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_REQUEST,
    getPartnerProductDeliveryListWorker
  );
  //Vendor Products bidl ist
  yield takeLatest(
    VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST,
    getPartnerProductBidListWorker
  );

  //Vendor Products detail
  yield takeLatest(
    VendorProductActionTypes.GET_PARTNER_PRODUCT_DETAIL_REQUEST,
    getPartnerProductDetailWorker
  );

  yield takeLatest(
    VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_REQUEST,
    getBidDetailWorker
  );

  //Vendor Process Bid
  yield takeLatest(
    VendorProductActionTypes.PROCESS_BID_REQUEST,
    postProcessBidWorker
  );

  //Vendor Pickup action
  yield takeLatest(
    VendorProductActionTypes.POST_PICKUP_ACTION_REQUEST,
    postPickupWorker
  );
  //  Vendor creating counter offer
  yield takeLatest(
    VendorProductActionTypes.CREATE_COUNTER_OFFER_REQUEST,
    createInitialCounterOfferWorker
  );
  //  Vendor creating counter offer LIST
  yield takeLatest(
    VendorProductActionTypes.GET_COUNTER_OFFER_LIST_REQUEST,
    getCounterOfferListWorker
  );

  // Vendor counter offer detail
  yield takeLatest(
    VendorProductActionTypes.GET_COUNTER_OFFER_DETAIL_REQUEST,
    getCounterOfferDetailWorker
  );

  // vendor update counter offer
  yield takeLatest(
    VendorProductActionTypes.PATCH_COUNTER_OFFER_DETAIL_REQUEST,
    patchCounterOfferWorker
  );

  // vendor new counter offer on existing one
  yield takeLatest(
    VendorProductActionTypes.CREATE_NEW_COUNTER_OFFER_REQUEST,
    createCounterOfferWorker
  );

  //Delete partner product
  yield takeLatest(
    VendorProductActionTypes.DELETE_PARTNER_PRODUCT_REQUEST,
    deletePartnerProductWorker
  );

  //Products
  yield takeLatest(
    ProductActionTypes.GET_PRODUCT_LIST_REQUEST,
    getProductListWorker
  );
  yield takeLatest(
    ProductActionTypes.GET_PRODUCT_DETAIL_REQUEST,
    getProductDetailWorker
  );
  yield takeLatest(
    ProductActionTypes.GET_INITIAL_FACET_REQUEST,
    getInitialFacetsWorker
  );

  //BID
  yield takeLatest(BidActionTypes.POST_CREATE_BID_REQUEST, postBid);

  yield takeLatest(BidActionTypes.GET_BID_HISTORY_REQUEST, getCreateBidHistory);

  // Review Checkout (basket)
  yield takeLatest(
    CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST,
    getReviewCheckoutWorker
  );

  // Post Checkout (basket)
  yield takeLatest(CartActionTypes.POST_CHECKOUT_REQUEST, postCheckoutWorker);

  // Post payment url
  yield takeLatest(CartActionTypes.POST_PAYMENT_URL_REQUEST, postPaymentWorker);

  // ORder LIst
  yield takeLatest(CartActionTypes.GET_ORDERS_REQUEST, getOrderListWorker);

  // ORder Details
  yield takeLatest(
    CartActionTypes.GET_ORDER_DETAILS_REQUEST,
    getOrderDetailWorker
  );

  //Add to Cart (basket)
  yield takeLatest(CartActionTypes.ADD_TO_CART_REQUEST, postAddToCartWorker);

  //update Cart (basket)
  yield takeLatest(CartActionTypes.UPDATE_CART_REQUEST, updateCartWorker);

  //Delete item  (basket)
  yield takeLatest(CartActionTypes.DELETE_ITEM_REQUEST, deleteItemWorker);

  //Post Promo Code
  yield takeLatest(CartActionTypes.POST_VOUCHER_REQUEST, PostPromoCodeWorker);

  // Remove voucher
  yield takeLatest(
    CartActionTypes.REMOVE_VOUCHER_REQUEST,
    DeletePromoCodeWorker
  );

  //ADD ADDRESS user
  yield takeLatest(AddressActionTypes.ADD_ADDRESS_REQUEST, postUserAddress);

  //UPDATE ADDRESS user
  yield takeLatest(
    AddressActionTypes.UPDATE_ADDRESS_REQUEST,
    updateUserAddressList
  );
  //Area list
  yield takeLatest(
    AddressActionTypes.GET_AREA_LIST_REQUEST,
    getAllowedAreaList
  );
  //GET ADDRESS user
  yield takeLatest(AddressActionTypes.GET_ADDRESS_REQUEST, getUserAddressList);

  //ComboProducts
  yield takeLatest(
    ComboProductActionTypes.UPDATE_PRODUCT_CLASS_LIST_REQUEST,
    productClassListWorker
  );
  yield takeLatest(
    ComboProductActionTypes.UPDATE_PRODUCT_DUPLICATE_LIST_REQUEST,
    productDuplicateListWorker
  );
  yield takeLatest(
    ComboProductActionTypes.UPDATE_PRODUCT_CLASS_ATTR_REQUEST,
    productClassAttrWorker
  );
  yield takeLatest(
    ComboProductActionTypes.PRODUCT_CREATE_REQUEST,
    productCreateWorker
  );
  yield takeLatest(
    ComboProductActionTypes.PRODUCT_CREATE_IMAGES_REQUEST,
    productCreateImagesWorker
  );

  // ACCOUNTS
  yield takeLatest(AccountActionTypes.LOGIN_REQUEST, loginWorker);
  yield takeLatest(AccountActionTypes.SOCIAL_LOGIN_REQUEST, socialLoginWorker);

  yield takeLatest(
    AccountActionTypes.PERSONALSIGNUP_REQUEST,
    personalSignupWorker
  );
  yield takeLatest(
    AccountActionTypes.COMPANY_SIGNUP_REQUEST,
    companySignUpWorker
  );
  yield takeLatest(
    AccountActionTypes.CHANGE_PASSWORD_REQUEST,
    changePasswordWorker
  );
  yield takeLatest(
    AccountActionTypes.RESEND_EMAIL_CONFIRMATION_REQUEST,
    postResendEmailConfirmationWorker
  );
  yield takeLatest(
    AccountActionTypes.RESEND_PHONE_CONFIRMATION_REQUEST,
    postResendPhoneConfirmationWorker
  );
  yield takeLatest(
    AccountActionTypes.VERIFY_PHONE_CODE_REQUEST,
    postPhoneCodeVerify
  );
  yield takeLatest(
    AccountActionTypes.FORGOTPASSWORD_REQUEST,
    forgotPasswordWorker
  );
  yield takeLatest(
    AccountActionTypes.PASSWORD_RESET_REQUEST,
    passwordResetWorker
  );
  yield takeLatest(AccountActionTypes.SIGNOUT_REQUEST, signOutWorker);
  // token Validation
  yield takeLatest(
    AccountActionTypes.VERIFY_TOKEN_REQUEST,
    checkValidTokenWorker
  );
  //USer details
  yield takeLatest(AccountActionTypes.USER_DETAIL_REQUEST, userDetailsWorker);
  yield takeLatest(
    AccountActionTypes.EDIT_USER_DETAIL_REQUEST,
    editUserDetailsWorker
  );
  yield takeLatest(
    AccountActionTypes.PATCH_PHONE_REQUEST,
    patchPhoneNumberWorker
  );
  yield takeLatest(
    AccountActionTypes.CONFIRM_EMAIL_REQUEST,
    postConfirmEmailWorker
  );
  // Payment info
  yield takeLatest(
    AccountActionTypes.GET_PAYMENT_INFO_REQUEST,
    getPaymentInfoWorker
  );
  yield takeLatest(
    AccountActionTypes.POST_PAYMENT_INFO_REQUEST,
    postPaymentInfoWorker
  );
  yield takeLatest(
    AccountActionTypes.PUT_PAYMENT_INFO_REQUEST,
    putPaymentInfoWorker
  );
  yield takeLatest(
    GlobalActionTypes.GET_TIMEWINDOW_REQUEST,
    getTimeWindowWorker
  );

  // for getting auction notification count
  yield takeLatest(
    VendorProductActionTypes.GET_ACTION_COUNT_REQUEST,
    getAuctionNotificationCountWorker
  );

  // posting to clear the auction count
  yield takeLatest(
    VendorProductActionTypes.CLEAR_ACTION_COUNT_REQUEST,
    postNotificationCountUrlWorker
  );

  // business Account creatiuon
  yield takeLatest(
    AccountActionTypes.BUSINESS_ACCOUNT_REQUEST,
    businessAccountWorker
  );

}

export default watcherSaga;
