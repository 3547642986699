import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import ActionTypes from "constants/productConstants.jsx";

const initialState = {
  product_detail_fetching: false,

  product_fetching: false,
  has_error: false,
  error: false,
  objects: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  product_detail: null,
  quick_add: null,
  sort_by: [],
  active_sort: null,
  category: [],
  parent_category:[],
  searchUrl: null,
  static_categories: [],
  static_parent_categories:[],
  top_category_products: [],
  active_category: null,
  active_parent_category: null,
  active_category_params: null,
  top_categories: [], // used in shop home page to show top categories in rows
  price_facet: "",
  custom_price_low: null,
  custom_price_high: null,
  attribute_value: [],
  active_attribute: null,
  attActive: [],
  custom_price: {},
  fetching_more_products: false,
  url_to_facets_mobile: null,
  loader_horizontal: false,
  listing_prod_detail: null,
};

// price_facet can have value of the following
// 0  - any
// 1 - > Under KD 25
// 2 - >KD 25 - 50
// 3 - > KD 50 - 100
// 4 - > Over KD 100
// 5 - Custom

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // INITIAL FACETS
    case ActionTypes.GET_INITIAL_FACET_REQUEST:
      return {
        ...state,
        product_fetching: true,
        has_error: false,
        error: false
      };

    case ActionTypes.GET_INITIAL_FACET_SUCCESS:
      return {
        ...state,
        has_error: false,
        error: false,
        static_categories: action.categories,
        static_parent_categories:action.parent_category,
        attribute_value: action.attribute_value
      };

    case ActionTypes.GET_INITIAL_FACET_FAILED:
      return {
        ...state,
        product_fetching: false,
        has_error: true,
        error: action.error
      };

    //PRODUCT LIST
    case ActionTypes.GET_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        product_fetching: true,
        has_error: false,
        error: false
      };

    case ActionTypes.GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        product_fetching: false,
        has_error: false,
        error: false,
        objects: action.objects,
        sort_by: action.sort_by,
        parent_category:action.parent_category,
        category: action.category,
        // static_categories: action.product_class,
        attribute_value: action.attribute_value
      };

    case ActionTypes.GET_PRODUCT_LIST_FAILED:
      return {
        ...state,
        product_fetching: false,
        has_error: true,
        error: action.error
      };

    // case ActionTypes.FETCHING_MORE_PRODUCTS:
    //   return {
    //     ...state,
    //     fetching_more_products: action.payload
    //   };
    //SHOW_LOADER
    case ActionTypes.SHOW_PRODUCTS_LOADER:
      return {
        ...state,
        fetching_more_products: action.payload,
        currentFetch: action.currentFetch
      };

    //PRODUCT DETAIL
    case ActionTypes.GET_PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        product_detail_fetching: true,
        product_fetching: true,
        has_error: false,
        error: false,
        product_detail: null
      };

    case ActionTypes.GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product_detail_fetching: false,
        product_fetching: false,
        has_error: false,
        error: false,
        product_detail: action.data
      };



    case ActionTypes.GET_PRODUCT_DETAIL_FAILED:
      return {
        ...state,
        product_detail_fetching: false,
        product_fetching: false,
        has_error: true,
        error: action.error,
        product_detail: null
      };

    //QUICK ADD
    case ActionTypes.UPDATE_QUICKADD:
      return {
        ...state,
        has_error: false,
        error: false,
        quick_add: action.data
      };
    // update active category
    case ActionTypes.UPDATE_ACTIVE_CATEGORY:
      return {
        ...state,
        active_category: action.category,
        active_category_params: action.active_category_params
      };

      // update active category
    case ActionTypes.UPDATE_ACTIVE_PARENT_CATEGORY:
      return {
        ...state,
        active_parent_category: action.parent_category,
        // active_category_params: action.active_category_params
      };

    // update active attribute
    case ActionTypes.ACTIVE_ATTRIBUTE_UPDATE:
      return {
        ...state,
        attActive: action.attribute
      };
    // update top categories
    case ActionTypes.UPDATE_TOP_CATEGORY:
      return {
        ...state,
        top_categories: action.top_categories,
        product_fetching: false
      };
    //SEARCH URL
    case ActionTypes.UPDATE_SEARCH_URL:
      return {
        ...state,
        //product_fetching: true,
        has_error: false,
        error: false,
        searchUrl: action.setUrl
      };

    // update facet price
    case ActionTypes.UPDATE_ACTIVE_PRICE_FACET:
      return { ...state, price_facet: action.value };

    // update facet price
    case ActionTypes.UPDATE_ACTIVE_SORT:
      return { ...state, active_sort: action.value };
    // update facet price
    case ActionTypes.UPDATE_CUSTOM_PRICE:
      return { ...state, custom_price: action.value };
    // update facet price from url
    // case ActionTypes.UPDATE_CUSTOM_PRICE_URL:
    //   return { ...state, custom_price_url: action.value };

    // update facet price
    case ActionTypes.UPDATE_MOBILE_FACET_URL:
      return { ...state, url_to_facets_mobile: action.value };

    //RESET OBJECTS WHEN CATEROGY CHANGES
    case ActionTypes.RESET_OBJECTS:
      return {
        ...state,
        objects: {
          count: 0,
          next: null,
          previous: null,
          results: []
        },
        price_facet: "",
        attActive: [],
        url_to_facets_mobile: null
        // searchUrl: null
      };

    //RESET OBJECTS WHEN CATEROGY CHANGES
    case ActionTypes.RESET_SEARCH_PARAMS:
      return {
        ...state,
        price_facet: "",
        attActive: [],
        active_category: null,
        active_parent_category:null,
        custom_price: {},
        active_sort: null,
        active_category_params: null
        // searchUrl: null
      };

    //RESET OBJECTS WHEN CATEROGY CHANGES
    case ActionTypes.RESET_PRICE_ATTS:
      return {
        ...state,
        custom_price: {},
        active_sort: null
      };

    case ActionTypes.UPDATE_LOADER_HORIZONTAL:
      return { ...state, loader_horizontal: action.value };

    //Listing prod detail

    case ActionTypes.LISTING_PROD_DETAIL:
      return {
        ...state,
        product_detail_fetching: false,
        product_fetching: false,
        has_error: false,
        error: false,
        listing_prod_detail: action.data
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: "product",
  storage: storage,
  blacklist: ["loader_horizontal"]

  // blacklist: [
  //   //   // "product_detail_fetching",
  //   //   // "product_fetching", 
  //   //   "error",
  //   "category",
  //   "static_categories",
  //   "top_category_products",
  //   "objects",
  //   "top_categories",
  //   // "active_category",
  //   "product_detail"
  // ]
};

export default persistReducer(persistConfig, productReducer);
