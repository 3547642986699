import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// React i18next
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import globalActionTypes from "constants/globalConstants.jsx";
import customSectionStyle from "kayfik/assets/jss/components/SectionContact/sectionStyle";

class SectionContact extends React.Component {
  handleSubmit = e => {
    const { subscribeRequest } = this.props;
    e.preventDefault();
    let subscribe_data = {
      email: e.target.subscribe_email.value
    };
    e.target.subscribe_email.value = "";
    subscribeRequest(subscribe_data);
  };

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.container + " py-5"} >
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4} className={classes.textCenter}>
            <h3 className={classNames(classes.title, "color-pl ")}>
              {t("homePage.sectionFive.signUpForNotification")}
            </h3>

            <form onSubmit={this.handleSubmit} autoComplete="off">
              <div className="d-flex flex-sm-column">
                <CustomInput
                  id="id_subscribe_email"
                  size="lg"
                  white
                  formControlProps={{
                    fullWidth: true,
                    className: "p-0 m-0"
                  }}
                  inputProps={{
                    name: "subscribe_email",
                    required: true,
                    type: "email",
                    placeholder: t("homePage.sectionFive.enterYourEmail"),
                    disableUnderline: true
                  }}
                />
                <Button
                  type="submit"
                  size="lg"
                  color="primaryLight"
                  className="m-0 sm-margin-top"
                >
                  {t("homePage.sectionFive.signUp")}
                </Button>
              </div>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  subscribeRequest: subscribe_data => {
    dispatch({
      type: globalActionTypes.POST_SUBSCRIBE_REQUEST,
      subscribe_data
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(customSectionStyle),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SectionContact);
