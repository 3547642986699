import { call, put } from "redux-saga/effects";
import i18n from "locales/i18n.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import ActionTypes from "constants/bidConstants.jsx";
import { postCreateBid, getBidHistory } from "actions/bidActions.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import * as Sentry from '@sentry/browser';

function* postBid(action) {
  const { payload, prod_url } = action;
  try {
    let response = yield call(postCreateBid, payload);
    let data = response.data;
    let modalType = "bidReceivedModal";
    let modalProps = {
      open: true,
      title: modalType
    };
    yield put({ type: ActionTypes.POST_CREATE_BID_SUCCESS, data });
    yield put({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
    yield put({ type: ProductActionTypes.GET_PRODUCT_DETAIL_REQUEST, payload: prod_url });
  } catch (e) {
    
    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    }
    else if (e.response.status === 404) {
      let txt = i18n.t("common.page");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.POST_CREATE_BID_FAILED, error });
  }
}

function* getCreateBidHistory() {
  try {
    let response = yield call(getBidHistory);
    let data = response.data;
    let past_bids = [];
    if (data) {
      past_bids = data.map(item => {
        return item.auction_product;
      });
    }

    yield put({ type: ActionTypes.GET_BID_HISTORY_SUCCESS, data, past_bids });
  } catch (e) {
    Sentry.captureException(e);
    let error = e;
    yield put({ type: ActionTypes.GET_BID_HISTORY_FAILED, error });
  }
}

export { postBid, getCreateBidHistory };
