import api from "utils/api.jsx";

const postLogin = data => {
  let url = api.login();
  if (data.url) {
    url = data.url;
  }

  return api.postFunction(url, data);
};
const postpersonalSignup = data => {
  let url = api.personal_signup();
  if (data.url) {
    url = data.url;
  }
  return api.postFunction(url, data);
};
const postforgotPassword = data => {
  return api.postFunction(api.forgotpassword(), data);
};
const postpasswordReset = data => {
  return api.postFunction(api.passwordReset(), data);
};

const postchangePassword = data => {
  return api.postFunction(api.changepassword(), data);
};

const postSignOut = data => {
  return api.postFunction(api.signout(), data);
};

const postConfirmEmail = data => {
  return api.postFunction(api.confirmemail(), data);
};

const companySignUp = data => {
  let url = api.company_signup();
  if (data.get("url")) {
    url = data.get("url");
  }

  return api.postFunction(url, data, true);
};

const companySignUpVerification = data => {
  return api.postFunction(api.company_signup_verification(), data);
};

const postCheckValidToken = data => {
  let url = api.verify_token();
  if (data.url) {
    url = data.url;
  }

  return api.postFunction(url, data);
};

const getUserDetails = () => {
  return api.getFunction(api.get_user_details());
};

const editUserDetails = data => {
  const url = api.get_user_details();
  return api.patchFunction(url, data);
};

const patchPhoneNumber = data => {
  const url = api.get_user_details();
  return api.patchFunction(url, data);
};

const postResendEmailConfirmation = () => {
  return api.postFunction(api.resend_email_confirmation());
};

const postPhoneConfirmation = data => {
  return api.postFunction(api.resend_phone_confirmation(), data);
};

const postPhoneVerifyCode = data => {
  return api.postFunction(api.phone_verify_code(), data);
};

const postFacebookLogin = data => {
  return api.postFunction(api.facebookLogin(), data);
};

const postGoogleLogin = data => {
  return api.postFunction(api.googleLogin(), data);
};

const postTwitterLogin = data => {
  return api.postFunction(api.twitterLogin(), data);
};

const getPaymentInfo = () => {
  return api.getFunction(api.paymentInfo());
};

const postPaymentInfo = data => {
  return api.postFunction(api.paymentInfo(), data);
};

const putPaymentInfo = (url, data) => {
  return api.putFunction(url, data);
};

const requestProductPost = data => {
  return api.postFunction(api.req_product_post(), data);
};

const instantCashProductList = () => {
  return api.getFunction(api.instant_cash_list());
};

const getDetail = url => {
  return api.getFunction(url);
};

const patchReqProds = (url, data) => {
  return api.patchFunction(url, data);
};
const postCivilID = data => {
  return api.patchFunction(api.get_user_details(), data, true);
};
const postBusinessCreation = () => {
  return api.postFunction(api.business_account());
};

export {
  postLogin,
  postpersonalSignup,
  postforgotPassword,
  postSignOut,
  companySignUp,
  postCheckValidToken,
  getUserDetails,
  editUserDetails,
  postchangePassword,
  postConfirmEmail,
  postResendEmailConfirmation,
  postFacebookLogin,
  postGoogleLogin,
  postTwitterLogin,
  getPaymentInfo,
  postPaymentInfo,
  postpasswordReset,
  postPhoneConfirmation,
  postPhoneVerifyCode,
  patchPhoneNumber,
  companySignUpVerification,
  requestProductPost,
  instantCashProductList,
  getDetail,
  patchReqProds,
  postCivilID,
  putPaymentInfo,
  postBusinessCreation
};
