const htPrimaryGreen = "#006978";
const htPrimaryLight = "#00ACC1";
const htSecondaryLight = "#DDE3E8";
const htPrimaryYellow = "#FFC107";
const htDark = "#0E0E0E";
const htWhite = "#FFFF";
const htDarkGrey = "#3C4858";
const htPrimaryGrayColor = "#3C4858"; //usually used in title from main jss material.jsx
const separatorColor = "#006978";
const successSnack = "#49A2AD";
const GraphicArabicWeb = "Graphik Arabic Web";
// const mainDarkColor=["#006978"];
export {
  htPrimaryGreen,
  htPrimaryLight,
  htPrimaryYellow,
  htDark,
  htSecondaryLight,
  htPrimaryGrayColor,
  GraphicArabicWeb,
  htDarkGrey,
  htWhite,
  separatorColor,
  successSnack
};
