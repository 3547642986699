import { call, put } from "redux-saga/effects";
import i18n from "locales/i18n.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import ActionTypes from "constants/vendorProductConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import { store } from "utils/store.jsx";
import {
  getVendorProductList,
  getVendorProductDeliveryList,
  getVendorProductBidList,
  postProcessBid,
  getVendorProductDetail,
  postPickupAction,
  getPartnerAddress,
  postPartnerAddress,
  getReplacementProduct,
  deletePartnerProduct,
  createInitailCounterOffer,
  getCounterOfferList,
  getCounterOfferDetail,
  patchCounterOffer,
  getAuctionNotificationCount,
  postAuctionCountUrl
} from "actions/vendorProductActions.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import { getExtraParameter } from "utils/helpers.jsx";
import * as Sentry from '@sentry/browser';

function* postPartnerAddressWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(postPartnerAddress, payload);
    let data = response.data;
    yield put({ type: ActionTypes.POST_PARTNER_ADDRESS_SUCCESS, data });
    let modal_props = store.getState().modalReducer.modalProps;
    if (
      modal_props.confirm_pickup_data &&
      modal_props.confirm_pickup_data.payload) {
      yield put({
        type: ActionTypes.POST_PICKUP_ACTION_REQUEST,
        payload: modal_props.confirm_pickup_data.payload,
        detailUrl: modal_props.confirm_pickup_data.detailUrl
      });
    }

    let address = null;
    yield put({ type: AddressActionTypes.UPDATE_MAP_ADDRESS, address });

    const modalProps = {
      title: "profileModal",
      open: true,
      active: "partner-address",
      modalLarge: true
    };
    const modalType = "profileModal";
    yield put({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  } catch (e) {
    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.POST_PARTNER_ADDRESS_FAILURE, error });
  }
}

function* getReplacementProductWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(getReplacementProduct, payload);
    let data = response.data;
    yield put({ type: ActionTypes.GET_REPLACEMENT_PRODUCT_SUCCESS, data });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {

      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    yield put({ type: ActionTypes.GET_REPLACEMENT_PRODUCT_FAILURE, error });
  }
}

function* getPartnerAddressWorker(payload) {
  const { backgroundFetch } = payload;
  try {
    let response = yield call(getPartnerAddress);
    let data = response.data;
    let fetching = false;
    if (backgroundFetch) {
      fetching = true;
    }
    yield put({ type: ActionTypes.GET_PARTNER_ADDRESS_SUCCESS, data, fetching });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.GET_PARTNER_ADDRESS_FAILURE, error });
  }
}

function* getPartnerProductListWorker() {
  try {
    let response = yield call(getVendorProductList);
    let data = response.data;

    yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_LIST_SUCCESS, data });
    let list_action_req = false;
    let action_data = null;
    action_data = data.results.find(
      item => item.is_ordered && item.is_ordered.action_required
    );
    if (action_data) {
      list_action_req = true;
    }
    yield put({ type: ActionTypes.PROD_LIST_ACTION_REQ, list_action_req });
    if (data.count > 0) {
    }
  } catch (e) {
    Sentry.captureException(e);
    let error = e;
    yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_LIST_FAILURE, error });
    let txt = i18n.t("common.error.network");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
}

function* getPartnerProductDeliveryListWorker() {
  try {
    let response = yield call(getVendorProductDeliveryList);
    let data = response.data;

    yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_SUCCESS, data });
    

  } catch (e) {
    Sentry.captureException(e);
    let error = e;
    yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_FAILURE, error });
    let txt = i18n.t("common.error.network");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
}

function* getPartnerProductDetailWorker(action) {
  const { payload, post_pickup } = action;
  try {
    let response = yield call(getVendorProductDetail, payload);
    let data = response.data;
    yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_DETAIL_SUCCESS, data });

    let res = yield call(getVendorProductList);
    let prod_list = res.data;
    let params = getExtraParameter();
    let list_action_req = false;
    let action_data = null;
    action_data = prod_list.results.find(item => item.is_ordered && item.is_ordered.action_required);
    if (action_data) {
      list_action_req = true;
    }
    if (params && params.email === "true" && !list_action_req && post_pickup !== true) {
      let txt = i18n.t("profileModal.offers.productbid.action");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );

    }
    yield put({ type: ActionTypes.PROD_LIST_ACTION_REQ, list_action_req });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_DETAIL_FAILURE, error });
  }
}

function* getPartnerProductBidListWorker(action) {
  let { url } = action;
  try {
    let response = yield call(getVendorProductBidList, url);
    let data = response.data;
    // let data = res.results;
    // let prev = res.previous;
    // let next = res.next;
    yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_BID_LIST_SUCCESS, data });
    // yield put({ type: ActionTypes.UPDATE_SEARCH_URL, setUrl });
  } catch (e) {
    Sentry.captureException(e);
    let error = e;
    yield put({
      type: ActionTypes.GET_PARTNER_PRODUCT_BID_LIST_FAILURE,
      error
    });
    let txt = i18n.t("common.error.network");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
}

function* getBidDetailWorker(action) {
  const { payload, from } = action;
  try {
    let response = yield call(getVendorProductDetail, payload);
    let data = response.data;
    let params = getExtraParameter();
    let action_req;
    if (data && data.bid && data.bid.accepted) {
      action_req = data.bid.accepted;
    } else if (data && data.bid && data.bid.rejected) {
      action_req = data.bid.rejected;
    } else if (data && data.bid && data.bid.has_counter_offer) {
      action_req = data.bid.has_counter_offer;
    }

    if (params && params.email === "true" && action_req) {
      if (from && from === "counterCreated") {
      } else {
        let txt = i18n.t("profileModal.offers.productbid.action");
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }

      if (data && data.bid && data.bid.has_counter_offer) {
        let component = "bidlist";
        yield put({
          type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
          component
        });
        yield put({
          type: ActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST
        });
      }
    } else if (data && data.bid && data.bid.has_counter_offer) {
      let component = "bidlist";
      yield put({
        type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
        component
      });
      yield put({
        type: ActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST
      });
    } else if (data && data.bid === null) {
      //if bid is rejected we getting null , so we redirect to list page
      if (params && params.email === "true") {
        let txt = i18n.t("profileModal.offers.productbid.action");
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
      let component = "bidlist";
      yield put({
        type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
        component
      });
      yield put({
        type: ActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST
      });
    }
    yield put({
      type: ActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_SUCCESS,
      data
    });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({
      type: ActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_FAILURE,
      error
    });
  }
}

// counter offers
function* createInitialCounterOfferWorker(action) {
  const { data } = action;
  try {
    yield call(createInitailCounterOffer, data);
    yield put({
      type: ActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_REQUEST,
      payload: data.bid_url,
      from: "counterCreated"
    });
    yield put({
      type: ActionTypes.CREATE_COUNTER_OFFER_SUCCESS,
    });
    let txt = i18n.t("profileModal.offers.productbid.counteroffercreated");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({
      type: ActionTypes.CREATE_COUNTER_OFFER_FAILURE,
      error
    });
  }
}

function* createCounterOfferWorker(action) {
  let { payload } = action;
  try {
    let response = yield call(createInitailCounterOffer, payload);
    let data = response.data;
    yield put({
      type: ActionTypes.CREATE_NEW_COUNTER_OFFER_SUCCESS,
      data
    });
    let txt = i18n.t("profileModal.offers.productbid.counteroffercreated");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({
      type: ActionTypes.CREATE_NEW_COUNTER_OFFER_FAILED,
      error
    });
  }
}

function* getCounterOfferListWorker() {
  try {
    let response = yield call(getCounterOfferList);
    let data = response.data;
    yield put({
      type: ActionTypes.GET_COUNTER_OFFER_LIST_SUCCESS,
      data
    });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({
      type: ActionTypes.GET_COUNTER_OFFER_LIST_FAILED,
      error
    });
  }
}

function* getCounterOfferDetailWorker(action) {
  let { payload } = action;
  try {
    let response = yield call(getCounterOfferDetail, payload);
    let data = response.data;
    yield put({
      type: ActionTypes.GET_COUNTER_OFFER_DETAIL_SUCCESS,
      data
    });
    let params = getExtraParameter();
    if (params && params.email === "true" && (data.accepted || data.rejected)) {
      let txt = i18n.t("profileModal.offers.productbid.action");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({
      type: ActionTypes.GET_COUNTER_OFFER_DETAIL_FAILED,
      error
    });
  }
}

function* patchCounterOfferWorker(action) {
  let { payload, isBuyer } = action;
  try {
    let response = yield call(patchCounterOffer, payload);
    let data = response.data.data;
    yield put({
      type: ActionTypes.PATCH_COUNTER_OFFER_DETAIL_SUCCESS,
      data
    });
    if (isBuyer) {
      yield put({ type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
    }
    let txt = response.data.message;
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({
      type: ActionTypes.PATCH_COUNTER_OFFER_DETAIL_FAILED,
      error
    });
  }
}

function* postProcessBidWorker(action) {
  let { payload } = action;
  let item_url = payload.item_url;
  try {
    let response = yield call(postProcessBid, payload);
    let data = response.data.message;
    yield put({ type: ActionTypes.PROCESS_BID_SUCCESS, data });
    yield put({
      type: ActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST
    });
    yield put({
      type: ActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_REQUEST,
      payload: item_url,
      from: "bidPosted"
    });

    let txt = data;
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.PROCESS_BID_FAILURE, error });
  }
}

function* postPickupWorker(action) {
  let { payload, detailUrl, unavailable } = action;
  try {
    let response = yield call(postPickupAction, payload);
    let data = response.data.message;
    yield put({ type: ActionTypes.POST_PICKUP_ACTION_SUCCESS, data });
    if (detailUrl) {
      let payload = detailUrl;
      yield put({
        type: ActionTypes.GET_PARTNER_PRODUCT_DETAIL_REQUEST,
        payload,
        post_pickup:true
      });
      yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_LIST_REQUEST });
    } else {
      yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    }
    let txt = data;
    if(unavailable){
      txt = i18n.t("profileModal.products.pickup_unavailable");
    }
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {
    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.POST_PICKUP_ACTION_FAILURE, error });
  }
}

function* deletePartnerProductWorker(action) {
  let { url } = action;
  try {
    let response = yield call(deletePartnerProduct, url);
    let data = response.data.message;
    yield put({ type: ActionTypes.DELETE_PARTNER_PRODUCT_SUCCESS, data });
    yield put({ type: ActionTypes.GET_PARTNER_PRODUCT_LIST_REQUEST });
    let component = "productlist";
    yield put({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
    let txt = data;
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.DELETE_PARTNER_PRODUCT_FAILURE, error });
  }
}

function* getAuctionNotificationCountWorker(action) {
  let { payload } = action;
  try {
    let response = yield call(getAuctionNotificationCount, payload);
    let data = response.data;
    yield put({
      type: ActionTypes.GET_ACTION_COUNT_SUCCESS,
      data
    });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({
      type: ActionTypes.GET_ACTION_COUNT_FAILED,
      error
    });
  }
}

function* postNotificationCountUrlWorker(action) {
  let { payload } = action;
  try {
    let response = yield call(postAuctionCountUrl, payload);
    let data = response.data.message;
    yield put({ type: ActionTypes.CLEAR_ACTION_COUNT_SUCCESS, data });
    // to update the count again to show latest data
    yield put({
      type: ActionTypes.GET_ACTION_COUNT_REQUEST
    });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.CLEAR_ACTION_COUNT_FAILED, error });
  }
}

export {
  getPartnerProductListWorker,
  getPartnerProductBidListWorker,
  postProcessBidWorker,
  getPartnerProductDetailWorker,
  postPickupWorker,
  getPartnerAddressWorker,
  postPartnerAddressWorker,
  getReplacementProductWorker,
  getBidDetailWorker,
  deletePartnerProductWorker,
  createInitialCounterOfferWorker,
  getCounterOfferListWorker,
  getCounterOfferDetailWorker,
  patchCounterOfferWorker,
  createCounterOfferWorker,
  getAuctionNotificationCountWorker,
  postNotificationCountUrlWorker,
  getPartnerProductDeliveryListWorker
};
