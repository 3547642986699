import React from "react";
import classNames from "classnames";

//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import face1 from "assets/img/faces/card-profile6-square.jpg";

class ItemsSold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bidNow: false,
      buyNow: true
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    return (
      <div className="main-background-color marginAuto pt-3">
        <h4 className={classNames("ta-left my-0 fw-400")}>Items Sold</h4>
        <GridContainer
          className={classNames("m-0", "modalScroll", "scrollbar")}
        >
          <Card className="mb-3">
            <CardBody className=" p-0">
              <GridContainer className="w-100">
                <GridItem xs={12} sm={3} md={3} className="px-0">
                  <img
                    src={face1}
                    className={classNames("cardHeightImg w-100")}
                    alt="..."
                  />
                </GridItem>
                <GridItem xs={12} sm={9} md={9} className="pl-4">
                  <GridContainer className=" py-4">
                    <GridItem xs={6}>
                      <span className="fw-500 pl-4 ">PRODUCT NAME</span>
                      <div className="pl-4">
                        <span>KD 110 </span>
                        <p>
                          Order Information Lorem ipsum dolor sit amet,
                          consectetur{" "}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={6} className="px-0 ">
                      <h6 className="fw-500 pl-4 htPrimaryGreen">
                        STATUS DELIVERED
                      </h6>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className=" p-0">
              <GridContainer className="w-100">
                <GridItem xs={12} sm={3} md={3} className="px-0">
                  <img
                    src={face1}
                    className={classNames("cardHeightImg w-100")}
                    alt="..."
                  />
                </GridItem>
                <GridItem xs={12} sm={9} md={9} className="pl-4">
                  <GridContainer className=" py-4">
                    <GridItem xs={6}>
                      <span className="fw-500 pl-4 ">PRODUCT NAME</span>
                      <div className="pl-4">
                        <span>KD 110 </span>
                        <p>
                          Order Information Lorem ipsum dolor sit amet,
                          consectetur{" "}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={6} className="px-0 ">
                      <h6 className="fw-500 pl-4 htPrimaryGreen">
                        STATUS DELIVERED
                      </h6>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className=" p-0">
              <GridContainer className="w-100">
                <GridItem xs={12} sm={3} md={3}className="px-0">
                  <img
                    src={face1}
                    className={classNames("cardHeightImg w-100")}
                    alt="..."
                  />
                </GridItem>
                <GridItem xs={12} sm={9} md={9}className="pl-4">
                  <GridContainer className=" py-4">
                    <GridItem xs={6}>
                      <span className="fw-500 pl-4 ">PRODUCT NAME</span>
                      <div className="pl-4">
                        <span>KD 110 </span>
                        <p>
                          Order Information Lorem ipsum dolor sit amet,
                          consectetur{" "}
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={6} className="px-0 ">
                      <h6 className="fw-500 pl-4 htPrimaryGreen">
                        STATUS DELIVERED
                      </h6>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(modalStyle)(ItemsSold);
