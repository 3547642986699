import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  // Combo Product Class list
  UPDATE_PRODUCT_CLASS_LIST_REQUEST: null,
  UPDATE_PRODUCT_CLASS_LIST_SUCCESS: null,
  UPDATE_PRODUCT_CLASS_LIST_FAILED: null,
  // Current product class attributes
  UPDATE_PRODUCT_CLASS_ATTR_REQUEST: null,
  UPDATE_PRODUCT_CLASS_ATTR_SUCCESS: null,
  UPDATE_PRODUCT_CLASS_ATTR_FAILED: null,
  // duplicate list
  UPDATE_PRODUCT_DUPLICATE_LIST_REQUEST: null,
  UPDATE_PRODUCT_DUPLICATE_LIST_SUCCESS: null,
  UPDATE_PRODUCT_DUPLICATE_LIST_FAILED: null,
  // create product
  PRODUCT_CREATE_REQUEST: null,
  PRODUCT_CREATE_SUCCESS: null,
  PRODUCT_CREATE_FAILED: null,
  // images
  PRODUCT_CREATE_IMAGES_REQUEST: null,
  PRODUCT_CREATE_IMAGES_SUCCESS: null,
  PRODUCT_CREATE_IMAGES_FAILED: null,
  // selected attr and value
  UPDATE_SELECTED_ATTR_VALUE: null,
  UPDATE_SELECTED_PRODUCT_CLASS: null,
  UPDATE_SELECTED_CATEGORY: null,
  FILTERED_PRODUCT_CLASS_LIST: null,
  UPDATE_SELECTED_CONDITION: null,

  UPDATE_PRODUCT_DATA: null,
  UPDATE_BID_DATA: null,
  UPDATE_BUY_DATA: null,
  UPDATE_DESCRIPTION_EN: null,
  UPDATE_DESCRIPTION_AR: null,
  UPDATE_PRODUCT_IMAGES: null,
  UPDATE_PRODUCT_DATA_IMAGES: null,
  UPDATE_BID_MESSAGE: null,
  UPDATE_BUY_MESSAGE: null,
  RESET_COMBO: null,
  UPDATE_QUANTITY: null,

  LIST_ITEM_BUTTON_DISABLE: null,
  UPDATE_LISTING_KIND: null,
  LIST_TO_DUPLICATE: null,

  UPDATE_SELECTED_DUPLICATE_ITEM: null,
  UPDATE_PREVIOUS_IMAGES: null,
  UPDATE_PICKUP_TIME: null,
  UPDATE_OTHER_INPUT_DATA: null,
  UPDATE_SELECTED_OTHER_DATA: null,
  UPDATE_INSTANT_CASH_OBJ: null,
  UPDATE_OTHER_PRODUCT_DATA: null
});

export default ActionTypes;
