import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import compose from "recompose/compose";
// redux
import { connect } from "react-redux";
// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";

import customAccordionStyle from "kayfik/assets/jss/components/Accordion/customAccordionStyle.jsx";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      single: false
    };
  }

  componentWillMount() {
    if (this.state.active.length === undefined) {
      this.setState({
        active: [this.state.active],
        single: true
      });
    }
  }

  handleChange = panel => (event, expanded) => {
    let newArray;

    if (this.state.single) {
      if (this.state.active[0] === panel) {
        newArray = [];
      } else {
        newArray = [panel];
      }
    } else {
      if (this.state.active.indexOf(panel) === -1) {
        newArray = [...this.state.active, panel];
      } else {
        newArray = [...this.state.active];
        newArray.splice(this.state.active.indexOf(panel), 1);
      }
    }
    this.setState({
      active: newArray
    });
  };
  render() {
    const { classes, collapses, activeColor, titleClass,arrow } = this.props;
    return (
      <div className={classes.root}>
        {collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded={
                this.state.active === key ||
                this.state.active.indexOf(key) !== -1
              }
              onChange={this.handleChange(key)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded
              }}
            >
              <ExpansionPanelSummary
                expandIcon={
                  prop.noIcon ? null : (
                    <ExpandMore className={classes.iconColor} />
                  )
                }
                classes={{
                  root: `${classes.expansionPanelSummary} ${
                    classes[activeColor + "ExpansionPanelSummary"]
                  }`,
                  expanded:`${arrow==="left"?classes.Expand:classes.expansionPanelSummaryExpaned} ${
                    classes[activeColor + "ExpansionPanelSummaryExpaned"]
                  }`,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon:arrow==="left"?classes.expansionPanelSummaryExpat:classes.expansionPanelSummaryExpandIcon
                }}
              >
                <h4 className={classNames(classes.title, titleClass,classes.facetsAlign)}>
                  {prop.title}
                </h4>
              </ExpansionPanelSummary>
              {prop.content ? (
                <ExpansionPanelDetails
                  className={classNames(classes.expansionPanelDetails)}
                >
                  {prop.content}
                </ExpansionPanelDetails>
              ) : null}
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

Accordion.defaultProps = {
  active: -1,
  activeColor: "primary",
  iconColor: "colorYellow",
};

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active collapse
  active: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired,
  activeColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "accordianColor",
    "htPrimaryLight",
    "colorYellow"
  ])
};

const mapStateToProps = state => ({
  ...state.globalReducer,
});

export default compose(
  withStyles(customAccordionStyle),
  connect(
    mapStateToProps,
  )
)(Accordion);

