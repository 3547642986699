import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import { connect } from "react-redux";
// kayfik components
import shopStyle from "kayfik/assets/jss/views/Shop/ShopStyle.jsx";
import Header from "kayfik/components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// Contants
import Facets from "kayfik/views/Shop/Sections/Facets.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Close from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
// React i18next
import { withTranslation } from "react-i18next";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import Cached from "@material-ui/icons/Cached";

class FacetMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortingBy: "relevancy"
    };
  }

  navigateToShop = () => {
    const { history } = this.props;
    history.push("/shop");
  };

  handleSortbyChange = value => {
    const { sort_by, UpdateActiveSort } = this.props;
    let a = sort_by.find(x => x.code === value.value);
    UpdateActiveSort(a);
    this.setState({ sortingBy: a.code });
    let sortUrl = a.url;
    let otherparams = null;
    if (sortUrl.indexOf("?") !== -1) {
      otherparams = sortUrl.split("?").pop();
    }
    if (otherparams) {
      let parArr = otherparams.split("&");

      let newarr = parArr.filter(a => a.indexOf("page") === -1);
      if (newarr.length > 0) {
        sortUrl = a.url.slice(0, a.url.indexOf("?")) + "?" + newarr.join("&");
      }
    }
  };
  renderSortByItems = () => {
    const { sort_by } = this.props;
    // process menuItems
    let menuItems = [];
    if (sort_by) {
      menuItems = sort_by.map(item => {
        return {
          value: item.code,
          label: item.title
        };
      });
    }
    return menuItems;
  };

  resetFilter = () => {
    const { resetSearchParams, getProducts, history, updateLoaderHorizontal ,updateSearchUrl} = this.props;
    resetSearchParams();
    getProducts();
    updateSearchUrl("");
    history.push({pathname:"/shop", search:""});
    updateLoaderHorizontal(true);
  };

  render() {
    const {
      classes,
      t,
      sort_by,
      active_sort,
      product_fetching,
      direction
    } = this.props;
    let menuItems = this.renderSortByItems();
    let selected_sortby = menuItems[0];
    if (sort_by) {
      let sortByObject = sort_by[0];
      if (active_sort) {
        sortByObject = sort_by.find(item => item.code === active_sort.code);
      }
      if (sortByObject) {
        selected_sortby = menuItems.find(
          item => item.value === sortByObject.code
        );
      }
    }


    return (
      <div className={product_fetching ? "facets_mobile" : ""}>
        <Header />
        <div className={classNames(classes.main, "mb-5")}>
          <Button
            color="secondaryLight"
            fullWidth
            size="lg"
            onClick={this.navigateToShop}
            className="d-flex justify-content-between align-items-center"
          >
            <div>
              <i
                className={
                  direction === "rtl"
                    ? "fa fa-sliders-h ml-2"
                    : "fa fa-sliders-h mr-2"
                }
              />
              {t("buyingPage.shopFacets.filter")}
            </div>
            <Close />
          </Button>


          <GridContainer className="my-3 dp-f jcc">
            <GridItem xs={10}>
              <div className="w-100">
                <SelectAutocomplete
                  inputId={"sort_by"}
                  noSelectMb
                  inputProps={{ name: "sort_by", required: true }}
                  value={selected_sortby}
                  autocompleteOptions={menuItems}
                  selectOnChange={this.handleSortbyChange}
                />
              </div>
            </GridItem>
            <GridItem xs={2}>
              <div className="dp-f align-items-center jcfe px-4">
                <Button
                  fullWidth
                  link
                  size="md"
                  justIcon
                  onClick={this.resetFilter}
                  className={`${classes.pullRight} ${classes.refineButton}`}
                >
                  <Cached />
                </Button>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Facets className="m-0" facetsMobile={true} />
            </GridItem>
          </GridContainer>
        </div>
        <BottomBar />
      </div>
    );
  }
}

FacetMobile.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.productReducer,
  ...state.modalReducer,
  ...state.globalReducer
});

const mapDispatchToProps = dispatch => ({
  getProducts: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_REQUEST,
      payload: url
    });
  },
  resetObjects: () => {
    dispatch({
      type: ProductActionTypes.RESET_OBJECTS
    });
  },
  UpdateActiveSort: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_SORT,
      value
    });
  },
  updateLoaderHorizontal: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_LOADER_HORIZONTAL,
      value
    });
  },
  resetSearchParams: () => {
    dispatch({
      type: ProductActionTypes.RESET_SEARCH_PARAMS
    });
  },
  updateSearchUrl: url => {
    dispatch({
      type: ProductActionTypes.UPDATE_SEARCH_URL,
      setUrl: url
    });
  },
});

export default compose(
  withStyles(shopStyle),
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(FacetMobile);
