import React from "react";
import classNames from "classnames";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// custom core components
import Header from "kayfik/components/Header/Header.jsx";
import ScrollToTop from "kayfik/components/ScrollToTop/scrollToTop.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import checkoutStyles from "kayfik/assets/jss/views/Checkout/checkoutStyles.jsx";
// Sections
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
import Cart from "kayfik/views/Checkout/Cart.jsx";
//actions
import CartActionTypes from "constants/cartConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
// React i18next
import { withTranslation } from "react-i18next";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import Room from "@material-ui/icons/Room";
import Link from "@material-ui/core/Link";
import Muted from "components/Typography/Muted.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// custom core components
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// @material-ui/core components
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import BreadCrumbs from "kayfik/components/BreadCrumbs/BreadCrumbs.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import { getUserDetails } from "actions/accountActions.jsx";


class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLat: 29.3759,
      currentLong: 47.9774,
      error: null,
      paymentSelected: "knet",
      first_name_state: "",
      last_name_state: "",
      promoCode: ""
    };
  }

  componentDidMount() {
    const {
      update_user_location,
      reviewCheckout,
      getAddressList,
      account_token,
      goTo,
      resetGoTo,
      resetNextPageToGo,
      nextPageToGo,
      getAreaList,
      getTimeWindows,
      checkoutPayment,
      basket_id,
    } = this.props;

    getTimeWindows("delivery");
    if (goTo === "checkout") {
      resetGoTo();
    }
    if (nextPageToGo === "/checkout") {
      resetNextPageToGo(null);
    }

    this.checkAccount();
    // Fetch addresslist only when user is logged in.
    if (account_token) {
      let old_basket = basket_id;

      if (checkoutPayment) {
        reviewCheckout(checkoutPayment, old_basket);
      } else {
        reviewCheckout(null, old_basket);
      }
      getAddressList();
      getAreaList();
      if (navigator.geolocation) {
        this.props.update_map_loader(true);
        navigator.geolocation.getCurrentPosition(
          position => {
            this.setState(
              {
                currentLat: position.coords.latitude,
                currentLong: position.coords.longitude,
                error: null
              },
              () => {
                const location = {
                  currentLat: this.state.currentLat,
                  currentLong: this.state.currentLong
                };
                update_user_location(location);
                this.props.update_map_loader(false);
              }
            );
          },
          error => {this.setState({ error: error.message },()=>console.log("error.message",error.message));this.props.update_map_loader(false)},
          { enableHighAccuracy: true, timeout: 200000 }
        );
      }
    }
  }

  renderBack = () => {
    const { t, direction } = this.props;
    if (direction === "rtl") {
      return (
        <div className="d-flex alignItem">
          <ChevronRight className="svg_icons green " />{" "}
          <span className="m-0 green F16">{t("buyingPage.checkOut.back")}</span>
        </div>
      );
    } else {
      return (
        <div className="d-flex alignItem">
          <ChevronLeft className="svg_icons green" />{" "}
          <span className="m-0 green F16">{t("buyingPage.checkOut.back")}</span>
        </div>
      );
    }
  };

  sortObject = obj => {
    var arr = [];
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        arr.push({
          key: prop,
          value: obj[prop]
        });
      }
    }
    arr.sort(function(a, b) {
      return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
    });
    return arr; // returns array
  };

  renderCustomMenuItems = () => {
    const {
      review_checkout,
      post_payment,
      voucher_success,
      updateVoucher
    } = this.props;

    //If voucher is success clear the input field
    if (voucher_success && voucher_success.code) {
      updateVoucher("");
    }
    let menuItems = [];
    if (review_checkout) {
      let payment_methods = post_payment
        ? post_payment
        : review_checkout.payment_methods;
      let methodsArr = this.sortObject(payment_methods);
      methodsArr.map(item => {
        return menuItems.push({
          value: item.key,
          label: item.value
        });
      });
    }

    return menuItems;
  };

  handlePaymentChange = value => {
    const { update_payment , reviewCheckout} = this.props;
    if (value && value.value) {
      reviewCheckout(value.value, null);
    }
    update_payment(value.value);
  };

  checkAccount = () => {
    const { account_token, history, showSnackbar, t } = this.props;
    if (!account_token) {
      let txt = t("buyingPage.checkOut.please");
      showSnackbar(txt, "warning");
      this.openModal("loginModal", {
        open: true,
        nextPage: "/checkout",
        location: "checkout",
        activeCheckout: true
      })();
      history.push("/");
      return;
    } else {
      getUserDetails()
        .then(ressponse => ressponse.data)
        .then(data => {
          this.props.userDetailsSuccess(data);
          this.setState({
            first_name_state: data.first_name,
            last_name_state: data.last_name
          });
          if (!data.is_email_verified) {
            this.openModal("verifyEmailModal", {
              open: true,
              verifyFrom: "checkout",
              activeCheckout: true
            })();
            return;
          } else if (!data.is_phone_verified) {
            this.openModal("verifyPhoneModal", {
              open: true,
              verifyFrom: "checkout",
              activeCheckout: true
            })();
            return;
          }
        })
        .catch(error => {
          this.props.userDetailsFailed(error);
        });
    }
  };

  checkAddress = () => {
    const {
      address_list,
      account_token,
      showSnackbar,
      guest_address,
      t
    } = this.props;
    let userAddress = address_list ? address_list[0] : null;
    let display_address = account_token ? userAddress : guest_address;
    if (!display_address) {
      let txt = t("buyingPage.checkOut.customerInformation.must");
      showSnackbar(txt, "warning");
      return;
    }
  };

  handleChange = e => {
    const { updateFullName } = this.props;
    e.preventDefault();
    if (e.target.name === "first_name") {
      this.setState({ first_name_state: e.target.value }, () =>
        updateFullName(this.state.first_name_state, this.state.last_name_state)
      );
    } else {
      this.setState({ last_name_state: e.target.value }, () =>
        updateFullName(this.state.first_name_state, this.state.last_name_state)
      );
    }
  };

  handleChangePromo = e => {
    const { updateVoucher} = this.props;
    e.preventDefault();
    updateVoucher(e.target.value); //update redux to save and clear the input field
    if (e.target.value) {
      this.setState({ promoCode: e.target.value });
    } else {
      this.setState({ promoCode: "" });
    }
  };

  //post promo code
  updatePromo = e => {
    const { postVoucher} = this.props;
    const { promoCode } = this.state;
    e.preventDefault();
    if (promoCode) {
      let data = {
        "vouchercode" : promoCode
      };
      postVoucher(data);
    }
  };

  onClickContinueStep = () => {
    const {
      guest_address,
      address_list,
      account_token,
      update_checkout_address,
      t,
      showSnackbar
    } = this.props;

    var userAddress = address_list ? address_list[0] : null;
    var display_address = account_token ? userAddress : guest_address;
    if (display_address) {
      update_checkout_address(display_address);
      this.postCheckout();
    } else {
      let txt = t("buyingPage.checkOut.customerInformation.must");
      showSnackbar(txt, "warning");
      return;
    }
  };

  openModal = (modal_type, modal_props = { open: true }) => async () => {
    const { showModal, modalProps, closeModal } = this.props;
    if (modalProps.open && closeModal) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  postCheckout = () => {
    const {
      review_checkout,
      postCheckoutRequest,
      checkoutPayment,
      showSnackbar,
      t,
      updateBasketId,
      account_token,
      guest_address,
      post_checkout_req_sent,
      delivery_time
    } = this.props;

    if (account_token) {
      // Dont allow user to checkout without Login
      if (review_checkout && review_checkout.basket_info) {
        let basketUrl = review_checkout.basket_info.url;
        let total = review_checkout.basket_info.total_incl_tax;

        // Dont allow user to checkout without anything on the cart
        if (total <= 0) {
          let txt = t("buyingPage.checkOut.notallowedcheckout");
          showSnackbar(txt, "warning");
          return;
        }
        if (!delivery_time) {
          let txt = t("buyingPage.checkOut.deliverytimewarning");
          showSnackbar(txt, "warning");
          return;
        }
        let address = {};

        if (account_token) {
          let current_address = review_checkout.addresses[0];
          current_address["json_address"] = current_address.address;
          address = {
            shipping_address: current_address
          };
        } else {
          address = guest_address;
        }

        const data = {
          basket: basketUrl,
          total: total,
          shipping_method_code: "free-shipping",
          payment_method_code: checkoutPayment,
          deliveryTime: delivery_time,
          ...address
        };

        const success_modal = {
          success_modal_type: "completeOrderModal",
          success_modalProps: {
            open: true,
            title: "completeOrderModal",
            goToShop: this.goToShop
          }
        };
        if (!post_checkout_req_sent) {
          //<---- saving basket id here for recall ---->
            if (
              review_checkout &&
              review_checkout.basket_info &&
              review_checkout.basket_info.id
            ) {
              let basket_id = review_checkout.basket_info.id;
              updateBasketId(basket_id);
            }
           //<---- saving basket id here for recall ---->
          postCheckoutRequest(data, success_modal);

        }
      }
    } else {
      let txt = t("common.unauthorized");
      showSnackbar(txt, "warning");
      return;
    }
  };

  goToShop = () => {
    this.props.history.push("/shop");
  };

  handleAddNewAddress = () => {
    const {
      showSnackbar,
      t,
      updateAddressEvent,
      account_token,
      checkout_firstName,
      checkout_lastName
    } = this.props;

    // Change the event to the appropriate user status
    if (account_token) {
      updateAddressEvent("loggedin_shipping");
    } else {
      updateAddressEvent("loggedin_shipping");
      let modal_props = {
        open: true
      };
      let modal_type = "initialLoginModal";
      modal_props["nextModal"] = "mapModal";
      this.openModal(modal_type, modal_props)();
      return;
    }
    if (checkout_firstName !== "" && checkout_lastName !== "") {
      this.openModal("mapModal")();
    } else {
      let txt = t("buyingPage.checkOut.customerInformation.addFullName");
      showSnackbar(txt, "warning");
      return;
    }
  };

  handleAddressChange = () => {
    const { updateAddressEvent, account_token, updateMapAddress } = this.props;
    if (account_token) {
      updateAddressEvent("loggedin_shipping");
      updateMapAddress({});
    }
    this.openModal("confirmAddress")();
  };

  handleBack = () => {
    return this.props.history.push("/shop");
  };

  getAreaName = id => {
    const { area_list } = this.props;
    if (area_list && area_list.length > 0) {
      let add = area_list.find(item => item.code === id);
      if (add) {
        return add.name;
      }
      return null;
    }
  };

  handleDeliveryChange = value => {
    const { update_delivery_time } = this.props;
    update_delivery_time(value);
  };

  render() {
    const {
      guest_address,
      address_list,
      account_token,
      classes,
      checkoutPayment,
      cart_fetching,
      address_fetching,
      userDetails,
      t,
      global_fetching,
      direction,
      delivery_time,
      timeWindows,
      voucher_success
    } = this.props;


    let userAddress = address_list ? address_list[0] : null;
    let display_address = account_token ? userAddress : guest_address;
    let fullAddress = null;
    let first_name = "";
    let last_name = "";
    if (display_address) {
      if (account_token !== null) {
        first_name = display_address.first_name;
        last_name = display_address.last_name;
        fullAddress = display_address.address;
      } else {
        fullAddress = display_address.address;
      }
    }
    let menuItems = this.renderCustomMenuItems();
    let selected_payment = "";

    let current_payment = menuItems.find(
      item => item.value === checkoutPayment
    );
    if (current_payment) {
      selected_payment = current_payment;
    }

    return (
      <div id="landing-top">
        {cart_fetching || address_fetching || global_fetching ? (
          <Loader fixed />
        ) : null}
        <Header />
        <ScrollToTop />
        <div className={classes.container + " main-background-color min-100vh"}>
          <GridContainer justify="center" className="bg-pl">
            <GridItem xs={12} sm={10} md={10}>
              <div className="checkout-header">
                <h2 className="white-text">{t("buyingPage.checkOut.title")}</h2>
                <h5 className="white-text">
                  {t("buyingPage.checkOut.subTitle")}
                  {/* <button onClick={()=>this.props.update_user_location(null)}> reset user location </button> */}
                </h5>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" className="mb-5">
            <GridItem xs={12} md={10} className="px-0">
              <GridContainer>
                <GridItem xs={12} md={8}>
                  <div className="my-4">
                    <BreadCrumbs
                      linkObjects={[
                        {
                          text: t("buyingPage.checkOut.home"),
                          url: "/"
                        },
                        {
                          text: t("buyingPage.checkOut.label")
                        }
                      ]}
                    />
                  </div>
                  <h4 className="color-pl">
                    {t("buyingPage.checkOut.reviewOrder")}
                  </h4>
                  <hr className="strightline" />
                  <GridContainer>
                    <GridItem
                      className={direction === "rtl" ? "pr-0" : "pl-0"}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <h3>
                        {t("buyingPage.checkOut.customerInformation.title")}
                      </h3>
                      {display_address ? null : (
                        <GridContainer className="mt-3">
                          <GridItem
                            className={direction === "rtl" ? "pr-0" : "pl-0"}
                            xs={12}
                            sm={6}
                            md={6}
                          >
                            <CustomInput
                              labelText={t(
                                "profileModal.accountSettings.generalUser.firstname"
                              )}
                              id="id_first_name"
                              size="lg"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                name: "first_name",
                                required: true,
                                type: "text",
                                defaultValue: userDetails
                                  ? userDetails.first_name
                                  : null,
                                onChange: this.handleChange,
                                disableUnderline: true
                              }}
                            />
                          </GridItem>
                          <GridItem
                            className={direction === "rtl" ? "pr-0" : "pl-0"}
                            xs={12}
                            sm={6}
                            md={6}
                          >
                            <CustomInput
                              labelText={t(
                                "profileModal.accountSettings.generalUser.lastname"
                              )}
                              id="id_last_name"
                              size="lg"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                name: "last_name",
                                required: true,
                                type: "text",
                                defaultValue: userDetails
                                  ? userDetails.last_name
                                  : null,
                                disableUnderline: true,
                                onChange: this.handleChange
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      )}
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          className={direction === "rtl" ? "pr-0" : "pl-0"}
                        >
                          {display_address ? null : (
                            <h6 className=" F12 htPrimaryGreen mb-0 dp-f align-items-center">
                              {t(
                                "buyingPage.checkOut.customerInformation.address"
                              )}{" "}
                              <Room className="roomIcon  colorYellow" />
                            </h6>
                          )}

                          {display_address ? (
                            <Card bordered>
                              <CardBody>
                                <div className="d-flex justify-content-between">
                                  <h5 className="m-0">
                                    {t("profileModal.accountSettings.address")}
                                  </h5>
                                  <Link
                                    underline="none"
                                    className="color-pl"
                                    component="button"
                                    onClick={this.handleAddressChange}
                                  >
                                    <h5 className="m-0">
                                      {" "}
                                      {t(
                                        "buyingPage.checkOut.customerInformation.edit"
                                      )}
                                    </h5>
                                  </Link>
                                </div>
                                {fullAddress ? (
                                  <div>
                                    {first_name ? (
                                      <h5 className="mb-1">
                                        <Muted>
                                          {t("common.firstname")} {first_name}
                                        </Muted>
                                      </h5>
                                    ) : null}
                                    {last_name ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.lastname")} {last_name}
                                        </Muted>
                                      </h5>
                                    ) : null}

                                    {fullAddress.area ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.area")}
                                          {this.getAreaName(
                                            fullAddress.area_id
                                          )}
                                        </Muted>
                                      </h5>
                                    ) : null}
                                    {fullAddress.block ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.block")}{" "}
                                          {fullAddress.block}
                                        </Muted>
                                      </h5>
                                    ) : null}
                                    {fullAddress.street ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.street")}{" "}
                                          {fullAddress.street}
                                        </Muted>
                                      </h5>
                                    ) : null}
                                    {fullAddress.avenue ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.avenue")}{" "}
                                          {fullAddress.avenue}
                                        </Muted>
                                      </h5>
                                    ) : null}

                                    {fullAddress.building ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.house")}{" "}
                                          {fullAddress.building}
                                        </Muted>
                                      </h5>
                                    ) : null}
                                    {fullAddress.floor ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.floor")}{" "}
                                          {fullAddress.floor}
                                        </Muted>
                                      </h5>
                                    ) : null}
                                    {fullAddress.unit ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.apartment")}{" "}
                                          {fullAddress.unit}
                                        </Muted>
                                      </h5>
                                    ) : null}
                                    {display_address.notes ? (
                                      <h5 className="mt-0 mb-1">
                                        <Muted>
                                          {t("common.additional")}
                                          {display_address.notes}
                                        </Muted>
                                      </h5>
                                    ) : null}
                                  </div>
                                ) : null}
                              </CardBody>
                            </Card>
                          ) : (
                            <GridItem xs={12} sm={6} md={5} className="p-0">
                              <Button
                                color="primaryWhiteWithBorder"
                                size="lg"
                                // type="submit"
                                onClick={this.handleAddNewAddress}
                              >
                                {t(
                                  "buyingPage.checkOut.customerInformation.addaddress"
                                )}
                              </Button>
                            </GridItem>
                          )}
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          className={direction === "rtl" ? "pr-0" : "pl-0"}
                        >
                          <Card bordered className={classes.overflowUnset}>
                            <CardBody>
                              <div className="d-flex justify-content-between">
                                <h5>
                                  {t(
                                    "buyingPage.checkOut.customerInformation.deliveryTime"
                                  )}
                                </h5>
                              </div>
                              <GridContainer>
                                <GridItem className="p-0" xs={8}>
                                  <SelectAutocomplete
                                    maxMenuHeight={145}
                                    inputId="select delivery"
                                    value={
                                      delivery_time
                                        ? timeWindows.find(
                                            i => i.id === delivery_time.id
                                          )
                                        : ""
                                    }
                                    placeholder={t(
                                      "buyingPage.checkOut.customerInformation.selectDelivery"
                                    )}
                                    controlProps={{ marginBottom: "15px" }}
                                    inputProps={{
                                      name: "delivery",
                                      required: true
                                    }}
                                    autocompleteOptions={
                                      timeWindows ? timeWindows : []
                                    }
                                    selectOnChange={this.handleDeliveryChange}
                                    getOptionLabel={({
                                      timefrom,
                                      timeto,
                                      name
                                    }) =>
                                      timefrom && timeto
                                        ? timefrom + " - " + timeto
                                        : name
                                    }
                                  />
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          className={direction === "rtl" ? "pr-0" : "pl-0"}
                        >
                          <h3>
                            {t(
                              "buyingPage.checkOut.customerInformation.coupon"
                            )}
                          </h3>
                          <Card bordered className={classes.overflowUnset}>
                            <CardBody>
                              <div className="d-flex justify-content-between">
                                <h5>
                                  {t(
                                    "buyingPage.checkOut.customerInformation.applyCoupon"
                                  )}
                                </h5>
                              </div>
                              <GridContainer>
                                <GridItem className="p-0" lg={8} xs={7}>
                                  <form
                                    onSubmit={this.updatePromo}
                                    autoComplete="off"
                                  >
                                    <CustomInput
                                      id="promo_code"
                                      size="lg"
                                      formControlProps={{
                                        fullWidth: true
                                      }}
                                      inputProps={{
                                        name: "Promo",
                                        required: true,
                                        type: "text",
                                        value: voucher_success,
                                        disableUnderline: true,
                                        onChange: this.handleChangePromo
                                      }}
                                    />
                                  </form>
                                </GridItem>
                                <GridItem lg={4} xs={5} className="jcfe">
                                  <Button
                                    fullWidth
                                    size="lg"
                                    disabled={voucher_success ? false : true}
                                    className="m-0 apply-coupan"
                                    color="primaryWhiteWithBorder"
                                    onClick={this.updatePromo}
                                  >
                                    {t(
                                      "buyingPage.checkOut.customerInformation.apply"
                                    )}
                                  </Button>
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem
                          xs={12}
                          className={direction === "rtl" ? "pr-0" : "pl-0"}
                        >
                          <h3>
                            {t(
                              "buyingPage.checkOut.customerInformation.payment"
                            )}
                          </h3>
                          <Card bordered className={classes.overflowUnset}>
                            <CardBody>
                              <div className="d-flex justify-content-between">
                                <h5>
                                  {t(
                                    "buyingPage.checkOut.reviewDetails.payment"
                                  )}
                                </h5>
                              </div>
                              <GridContainer>
                                <GridItem className="p-0" xs={8}>
                                  <SelectAutocomplete
                                    controlProps={{ marginBottom: "15px" }}
                                    inputProps={{
                                      name: "payment",
                                      required: true
                                    }}
                                    value={selected_payment}
                                    autocompleteOptions={menuItems}
                                    selectOnChange={this.handlePaymentChange}
                                  />
                                </GridItem>
                              </GridContainer>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>

                      <Hidden smDown implementation="css">
                        <GridContainer
                          className={classNames(
                            "stepperButtonsContainer",
                            classes.backgroundTransparent
                          )}
                        >
                          <Cart className="d-none md mb-3" />
                          <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            className={classNames(
                              direction === "rtl"
                                ? "fw-500 ta-end dp-f jcfs pr-0"
                                : "fw-500 ta-end dp-f jcfs pl-0"
                            )}
                          >
                            <Link
                              underline="none"
                              className="color-pl"
                              component="button"
                              onClick={this.handleBack}
                            >
                              {this.renderBack()}
                            </Link>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} className="dp-f jcfe">
                            <Button
                              fullWidth
                              size="lg"
                              color="primaryWhiteWithBorder"
                              onClick={this.onClickContinueStep}
                            >
                              {t(
                                "buyingPage.checkOut.customerInformation.order"
                              )}
                            </Button>
                          </GridItem>
                        </GridContainer>
                      </Hidden>
                      <Hidden mdUp implementation="css">
                        <GridContainer
                          className={classNames(
                            "stepperButtonsContainer",
                            classes.backgroundTransparent
                          )}
                        >
                          <Cart className="d-none md mb-3" />
                          <GridItem xs={12} sm={6} md={6} className="dp-f jcfe">
                            <Button
                              fullWidth
                              size="lg"
                              color="primaryWhiteWithBorder"
                              onClick={this.onClickContinueStep}
                            >
                              {t(
                                "buyingPage.checkOut.customerInformation.order"
                              )}
                            </Button>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            className={classNames(
                              direction === "rtl"
                                ? "fw-500 ta-end dp-f jcfs pr-0"
                                : "fw-500 ta-end dp-f jcfs pl-0"
                            )}
                          >
                            <Link
                              underline="none"
                              className="color-pl"
                              component="button"
                              onClick={this.handleBack}
                            >
                              {this.renderBack()}
                            </Link>
                          </GridItem>
                        </GridContainer>
                      </Hidden>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <Cart className="d-none sm" />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
        <BottomBar />
        <CustomFooter />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  update_checkout_address: address => {
    dispatch({
      type: CartActionTypes.UPDATE_CHECKOUT_ADDRESS,
      data: address
    });
  },
  update_checkout_payment: payment => {
    dispatch({
      type: CartActionTypes.UPDATE_CHECKOUT_PAYMENT,
      data: payment
    });
  },
  update_user_location: location => {
    dispatch({
      type: AddressActionTypes.USER_CURRENT_LOCATION,
      payload: location
    });
  },
  updateFullName: (first_name, last_name) => {
    dispatch({
      type: AddressActionTypes.UPDATE_FULL_NAME,
      first_name,
      last_name
    });
  },
  updateAddressEvent: event => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT,
      event
    });
  },
  reviewCheckout: (payment_code, old_basket) => {
    dispatch({
      type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST,
      recall: old_basket,
      code: payment_code
    });
  },
  postCheckoutRequest: (data, success_modal) => {
    dispatch({
      type: CartActionTypes.POST_CHECKOUT_REQUEST,
      payload: data,
      success_modal: success_modal
    });
  },
  getAddressList: () => {
    dispatch({
      type: AddressActionTypes.GET_ADDRESS_REQUEST
    });
  },
  update_payment: payment => {
    dispatch({
      type: CartActionTypes.UPDATE_CHECKOUT_PAYMENT,
      data: payment
    });
  },
  resetGoTo: () => {
    dispatch({
      type: GlobalActionTypes.RESET_GO_TO
    });
  },
  addAddress: (address, page = "checkout") => {
    dispatch({
      type: AddressActionTypes.ADD_ADDRESS_REQUEST,
      payload: address,
      page
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  resetNextPageToGo: nextPageToGo => {
    dispatch({
      type: AccountActionTypes.UPDATE_NEXT_PAGE_TO_GO,
      nextPageToGo
    });
  },
  userDetailsFailed: error => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_FAILED,
      error
    });
  },
  userDetailsSuccess: data => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_SUCCESS,
      data
    });
  },
  getAreaList: () => {
    dispatch({
      type: AddressActionTypes.GET_AREA_LIST_REQUEST
    });
  },
  update_delivery_time: data => {
    dispatch({
      type: CartActionTypes.UPDATE_DELIVERY_TIME,
      data
    });
  },
  getTimeWindows: window => {
    dispatch({
      type: GlobalActionTypes.GET_TIMEWINDOW_REQUEST,
      window
    });
  },
  updateMapAddress: address => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_ADDRESS,
      address
    });
  },
  getPaymentCardFees: payment_code => {
    dispatch({
      type: CartActionTypes.GET_CARD_FEES_REQUEST,
      payment_code
    });
  },
  update_map_loader: mapLoader => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_LOADER,
      mapLoader
    });
  },
  updateBasketId: data => {
    dispatch({
      type: CartActionTypes.UPDATE_BASKET_ID_SUCCESS,
      data
    });
  },
  updateVoucher: data => {
    dispatch({
      type: CartActionTypes.UPDATE_VOUCHER_CODE,
      data
    });
  },
  postVoucher: data => {
    dispatch({
      type: CartActionTypes.POST_VOUCHER_REQUEST,
      payload: data
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.cartReducer,
  ...state.addressReducer,
  ...state.globalReducer,
  ...state.comboProductReducer
});

export default compose(
  withStyles(checkoutStyles),
  withWidth(),
  withTranslation(),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Checkout);
