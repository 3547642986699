import React from "react";
import classNames from "classnames";
import ImageGallery from "react-image-gallery";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import api from "utils/api.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import Products from "kayfik/views/ProductDetail/products.jsx";
// custom core components
import Header from "kayfik/components/Header/Header.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
// Style
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Sections
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
// images
import ModalActionTypes from "constants/modalConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
//404 page
import Loader from "kayfik/components/Loader/loader.jsx";
import Link from "@material-ui/core/Link";
import BidActionTypes from "constants/bidConstants.jsx";
import PageNotFound from "kayfik/components/PageNotFound/PageNotFound.jsx";
import SharePopOver from "kayfik/components/SharePopOver/sharePopOver.jsx";
import ScrollToTop from "kayfik/components/ScrollToTop/scrollToTop.jsx";
import Badge from "components/Badge/Badge.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
// helmet
import { Helmet } from "react-helmet";
import company_icon from "kayfik/assets/images/company_icon.png";
import Tooltip from "@material-ui/core/Tooltip";
import { checkItemAvailableInCart } from "utils/helpers.jsx";

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1
    };
    this.getDetail();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getDetail = () => {
    const { getProductDetail, match } = this.props;
    const itemId = match.params.itemId;
    const detailURL = api.root + "products/" + itemId + "/";
    getProductDetail(detailURL);
  };

  handleOpenSellModal = () => {
    let target_modal = "whatSellingModal";
    let modal_type = target_modal;
    let modal_props = {
      open: true
    };
    this.openModal(modal_type, modal_props)();
  };

  backPage = () => {
    window.history.back();
  };

  onAddToCart = () => {
    const { product_detail, addToCartRequest } = this.props;
    const data = {
      url: product_detail.url,
      quantity: 1 // NOTE: for now default to 1
    };
    const success_modal_type = "addtocartModal";
    const success_modalProps = {
      open: true,
      title: "addtocartModal",
      shopping: this.shopping,
      checkoutNow: this.checkoutNow
    };
    addToCartRequest(data, { success_modal_type, success_modalProps });
  };

  shopping = () => {
    this.props.history.push("/shop");
  };
  checkoutNow = () => {
    this.props.history.push("/checkout");
  };

  renderProductTypeIcon = () => {
    const { product_detail, t, classes } = this.props;
    let is_company = product_detail && product_detail.vendor && product_detail.vendor.is_vendor_type === "COMPANY" && product_detail.vendor.company_approved === true ? true : false;
    if (is_company) {
      return (
        <Tooltip
          title={t("buyingPage.shopFacets.verifiedCompany")}
          placement="top"
          enterTouchDelay={0}
          classes={{
            tooltip: classNames(classes.tooltip)
          }}
        >
          <img
            src={company_icon}
            className="typeIcon productDetailIcon detailPage"
            alt={t("buyingPage.shopFacets.verifiedCompany")}
          />
        </Tooltip>
      );
    }
  };

  renderVerfiedTitle = () => {
    const { product_detail, t } = this.props;
    let is_company =
      product_detail &&
      product_detail.vendor &&
      product_detail.vendor.is_vendor_type === "COMPANY" &&
      product_detail.vendor.company_approved === true
        ? true
        : false;
    if (is_company) {
      return (
        <Badge
          className="text-badge  image-badge-prodDetail"
          classes={{
            badge: "productBadge"
          }}
        >
          <span className="verification-text-Background">
            {" "}
            {t("buyingPage.shopFacets.verifiedCompany")}{" "}
          </span>
        </Badge>
      );
    }
  };

  renderAttributes = () => {
    const { product_detail } = this.props;
    const p = product_detail.parent_attributes;
    var attributes = [];
    for (var key in p) {
      if (p[key]) {
        attributes.push(
          <div key={key}>
            <h4 className="my-0 mt-2 mb-2">
              {key}: {p[key]}
            </h4>
          </div>
        );
      }
    }
    return attributes;
  };

  getImages = () => {
    const { product_detail } = this.props;
    return (
      product_detail &&
      product_detail.images.map(img => {
        return {
          original: img.url,
          thumbnail: img.url,
          originalClass: "carouselHeight",
          thumbnailClass: "carouselthumbnail"
        };
      })
    );
  };

  renderBuyButton = () => {
    const { product_detail, t, userDetails } = this.props;
    let is_buy_disable = false;
    let available_status = null;
    if (userDetails && product_detail && product_detail.vendor) {
      is_buy_disable = userDetails.pk === product_detail.vendor.pk;
    }
    //from the helper Class
    if (product_detail && product_detail.url) {
      available_status = checkItemAvailableInCart(product_detail.url);
    }
    //disable add to cart button if item already available on the cart
    if (available_status) {
      is_buy_disable = true;
    }
    if (product_detail.is_buy) {
      return (
        <Button
          color="primaryWhiteWithBorder"
          size="lg"
          className={classNames("mb-1 product-button-text")}
          fullWidth
          disabled={is_buy_disable}
          onClick={this.onAddToCart}
        >
          {!available_status
            ? t("buyingPage.shopProductDetail.cart")
            : t("buyingPage.shopProductDetail.item_in_cart")}
        </Button>
      );
    }
  };

  handleOpenBidModal = () => {
    const { account_token, updateBidItem, product_detail, userDetails } = this.props;
    let target_modal = "makeBidModal";
    let modal_type = target_modal;
    let modal_props = {
      open: true
    };
    if (product_detail) {
      updateBidItem({ ...product_detail });
    }
    if (!account_token) {
      modal_type = "loginModal";
      modal_props["nextModal"] = target_modal;
      this.openModal(modal_type, modal_props)();
    } else {
      if (userDetails && !userDetails.is_email_verified) {
        this.openModal("verifyEmailModal", {
          open: true,
          verifyFrom: "makeBid",
          activeBid: true
        })();
      } else if (userDetails && !userDetails.is_phone_verified) {

        this.openModal("verifyPhoneModal", {
          open: true,
          verifyFrom: "makeBid",
          activeBid: true
        })();
      } else {
        this.openModal(modal_type, modal_props)();
      }

    }
  };

  openModal = (modal_type, modal_props) => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  renderBidButton = () => {
    const { product_detail, t, userDetails } = this.props;
    let available_status = null;
    //from the helper Class
    if (product_detail && product_detail.url) {
      available_status = checkItemAvailableInCart(product_detail.url);
    }
    if (product_detail && product_detail.is_auction && !available_status) {
      return (
        <Button
          color="primaryWhiteWithBorder"
          size="lg"
          className="product-button-text"
          fullWidth
          disabled={
            !product_detail.is_allowed_to_bid[0] ||
            (userDetails &&
              product_detail &&
              product_detail.vendor &&
              userDetails.pk === product_detail.vendor.pk)
          }
          onClick={this.handleOpenBidModal}
        >
          {product_detail &&
          product_detail.is_allowed_to_bid &&
          product_detail.is_allowed_to_bid[0] === false
            ? t("buyingPage.shopProductDetail.bid_unAvailable")
            : t("buyingPage.shopProductDetail.bid")}
        </Button>
      );
    }
    return null;
  };

  renderButton = () => {
    const { product_detail, t } = this.props;
    if (product_detail !== null) {
      if (product_detail.availability.is_available_to_buy !== true) {
        return (
          <Button
            color="primaryWhiteWithBorder"
            size="lg"
            fullWidth
            disabled={true}
          >
            {t("buyingPage.shopProductDetail.unAvailable")}
          </Button>
        );
      } else {
        return (
          <div>
            {this.renderBuyButton()}
            {this.renderBidButton()}
          </div>
        );
      }
    }
  };

  renderSimilarProducts = () => {
    const { product_detail, t } = this.props;
    if (product_detail && product_detail.similar_products && product_detail.similar_products.length > 0) {
      return product_detail.similar_products.map((prod, i) => {
        return (
          <GridItem xs={12} sm={12} md={3} className="p-md-0" key={i}>
            <Products item={prod} key={i} display={"rtl"} />
          </GridItem>
        );
      });
    } else {
      return (
        <GridItem xs={12} sm={12} md={3}>
          <p>{t("buyingPage.shopProductDetail.similar")} </p>
        </GridItem>
      );
    }
  };

  shareActionTitle = () => {
    const { t, direction } = this.props;
    if (direction === "rtl") {
      return (
        <h5 className="color-pl m-0">
          {t("buyingPage.shopProductDetail.share")}{" "}
          <i className={"fas fa-share fa-flip-horizontal  "} />
        </h5>
      );
    } else {
      return (
        <h5 className="color-pl m-0">
          {t("buyingPage.shopProductDetail.share")}{" "}
          <i className={"fas fa-share   "} />
        </h5>
      );
    }
  };

  renderContent = () => {
    const {
      t,
      classes,
      product_detail,
      product_detail_fetching,
      global_fetching,
      direction
    } = this.props;
    if (product_detail_fetching) {
      return <Loader fixed />;
    } else if (product_detail !== null) {
      const images = this.getImages();
      return (
        <div id="landing-top">
          {/* For SEO */}
          <Helmet>
            <title>{product_detail.title}</title>
            <meta name="description" content={product_detail.description} />
          </Helmet>
          {global_fetching ? <Loader fixed /> : null}
          <Header />
          <ScrollToTop />
          <div className="cd-section main-background-color product-detail-page min-70vh">
            <GridContainer className="mb-3">
              <GridItem xs={12} sm={7} md={7} className="px-0">
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  startIndex={0}
                  items={images}
                  showNav={false}
                  showBullets={true}
                />
                <div className={direction === "rtl" ?"mobile-detail-cart-ar hide-web":"mobile-detail-cart hide-web"}>
                  <i className="fas fa-arrow-left fa-1x" onClick={this.backPage} aria-hidden="true"></i>
                </div>
                {product_detail.is_pending ? (
                  <Badge
                    color="gray"
                    className="image-badge image-badge-prodDetail"
                    classes={{
                      badge: "productBadge"
                    }}
                  >
                    <span className="pendingVerificationBackground">
                      {" "}
                      {t("buyingPage.shopProductDetail.pending")}{" "}
                    </span>
                  </Badge>
                ) : null}

       
              </GridItem>

              <GridItem
                xs={12}
                sm={5}
                md={5}
                className={classNames(
                  "bg-secondary-light",
                  "px-5",
                  "product_description"
                )}
              > 
              {product_detail.condition ?
              <Badge
                className="text-badge image-badge-prodDetail"
                classes={{
                  badge: "productBadge"
                }}
              >
                <span className="verification-text-Background  bg-pl">
                  {product_detail.condition}
                </span>
              </Badge>

              // <Badge className="mr-1 bg-pl br-0">
              //   {product_detail.condition}
              // </Badge>
              :null}
               {this.renderVerfiedTitle()}
                <h4
                  className={classNames(
                    direction === "rtl"
                      ? (classes.modalTitle, "arabic")
                      : classes.modalTitle
                  )}
                >
                  {product_detail.title}
                </h4>
                <h4 className={direction === "rtl" ? "similar_arabic" : null}>
                  {t("common.priceamount", {
                    amount: product_detail.price.incl_tax
                  })}
                </h4>
                <p>{product_detail.description}</p>
                {this.renderAttributes()}
                <GridContainer className={classNames(classes.marginAuto)}>
                  <div className={classes.borderBottom} />
                  <GridItem
                    xs={12}
                    sm={12}
                    md={8}
                    className={classNames("p-0", "py-2")}
                  >
                    {this.renderButton()}
                  </GridItem>
                  <div className={classes.borderBottom} />
                </GridContainer>
                <div className="d-flex">
                  <SharePopOver
                    noLiPadding
                    noPosition
                    dropPlacement={"right-start"}
                    caret={false}
                    className={classes.svgIcon + " pointer"}
                    action_title={this.shareActionTitle()}
                    product={product_detail}
                  />
                </div>
                <div className="d-flex">
                  <h5 className="m-0 mr-1">
                    {t("buyingPage.shopProductDetail.sell")}
                  </h5>
                  <Link
                    underline="none"
                    component="button"
                    onClick={this.handleOpenSellModal}
                  >
                    <h5 className={direction==="rtl"? "color-pl product-detail-sell-now-ar":"color-pl m-0"}>
                      {t("buyingPage.shopProductDetail.now")}
                    </h5>
                  </Link>
                </div>
              </GridItem>
            </GridContainer>
            {product_detail && product_detail.similar_products.length > 0 ? (
              <GridContainer>
                <GridItem xs={12}>
                  <h3 className="headerColor">
                    {t("buyingPage.shopProductDetail.similarProducts")}
                  </h3>
                </GridItem>
                {this.renderSimilarProducts()}
              </GridContainer>
            ) : null}
          </div>
          <BottomBar />
          <CustomFooter />
        </div>
      );
    } else {
      return <PageNotFound />;
    }
  };

  render() {
    return this.renderContent();
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  addToCartRequest: (data, success_modal) => {
    dispatch({
      type: CartActionTypes.ADD_TO_CART_REQUEST,
      payload: data,
      success_modal: success_modal
    });
  },
  getProductDetail: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  updateBidItem: data => {
    dispatch({
      type: BidActionTypes.UPDATE_BID_ITEM,
      data
    });
  },
  showSnackbar: (txt, variant) => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.productReducer,
  ...state.accountReducer,
  ...state.globalReducer,
  ...state.cartReducer,
  ...state.accountReducer,
  ...state.comboProductReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductDetail);
