import React from "react";
import api from "utils/api.jsx";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import Muted from "components/Typography/Muted.jsx";
import queryString from "query-string";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AccountActionTypes from "constants/accountConstants.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import { decimalPlace } from "utils/helpers.jsx";
import {
  convertHindiToArabic,
  converToArabicString,
  isInputEnglish,
  postActionNotificationClicked,
  showStatusMessage
} from "utils/helpers.jsx";
import Badge from "components/Badge/Badge.jsx";

class CounterOfferDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: null,
      product: null,
      counter_input: "",
      counter_input_display: "",
      counter_offer_submitted:false,
      send_counter_offer: null
    };
  }

  componentDidMount() {
    this.renderSpecificProduct();
  }

  renderSpecificProduct = () => {
    let params = this.getExtraParameter();
    if (params.counterOfferId) {
      this.showDetail(params.counterOfferId);
    }
  };
  isMd = () => {
    if (isWidthDown("md", this.props.width)) {
      return true;
    } else {
      return false;
    }
  };

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);

    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }

    return params;
  };

  submitBid = (value, item) => () => {
    const { patchData } = this.props;

    let data = {
      url: item.update_url
    };

    if (value === "accept") {
      data["accepted"] = true;
    } else if (value === "reject") {
      data["rejected"] = true;
    }
    let isBuyer = !this.showMessage();
    patchData(data, isBuyer);
  };

  showDetail = id => {
    const { getDetail } = this.props;
    const detailUrl = api.root + "auction/counter/active/" + id + "/";
    getDetail(detailUrl);
  };

  submitCounterOffer = e => {
    e.preventDefault();
    const { counter_offer_detail, postCounterOffer,t ,showSnackbar} = this.props;
    let offer_amount = parseFloat(this.state.counter_input);
    let seller_buy_price = this.calculateCommission(counter_offer_detail.product_details.price.excl_tax,'orgPrice');     
    let is_seller=this.get_is_seller(counter_offer_detail);
    let seller_counter=this.calculateCommission(offer_amount,'plus');
    if(is_seller &&( parseFloat(offer_amount) > parseFloat(seller_buy_price))){
      let txt = t("profileModal.offers.productbid.counteroffernotallowed", { amount: seller_buy_price });
      showSnackbar(txt, "warning");
    }
    else if((parseFloat(offer_amount) < parseFloat(counter_offer_detail.product_details.is_auction_min_price))){
      let txt = t("profileModal.offers.productbid.counteroffernotallowedLower", { amount: counter_offer_detail.product_details.is_auction_min_price });
      showSnackbar(txt, "warning");
    }
    else{
      this.setState({counter_offer_submitted:true});
      this.setState({send_counter_offer:offer_amount});
      let data = {
        create_url: counter_offer_detail.create_counter_url,
        offer_amount:is_seller?seller_counter:offer_amount,
        bid_history: counter_offer_detail.bid_history
      };
      postCounterOffer(data);
      this.setState({ counter_input: "" });
    }
  };

  formatNumber(num) {
    if (num && num.length > 0) {
      num = convertHindiToArabic(num, true);
      return num;
    } else {
      return '';
    }
  }

  handlePriceChange = e => {
    e.preventDefault();
    let targetValue = e.target.value;
    var lastChar = targetValue.substr(targetValue.length - 1);
    let afterDec = targetValue.split(".")[1];
    if(afterDec === undefined || afterDec === "" || afterDec.length<4){
      let isEng = isInputEnglish(targetValue);
      let targetValue1 = this.formatNumber(targetValue);
      let targetValueInt = parseFloat(targetValue1); //parseFloat(targetValue1);
      if (lastChar === "." || lastChar === "٫") {
        targetValueInt = targetValueInt + "."
      }
      let displayPrice = targetValueInt ? targetValueInt + "" : "";
      if (displayPrice && !isEng) {
        displayPrice = converToArabicString(displayPrice);
      }

      this.setState({
        counter_input: targetValueInt,
        counter_input_display: displayPrice
      });
    }
  };

  pendingFrom=()=>{
    const { userDetails , counter_offer_detail, t }=this.props;
    if(userDetails && counter_offer_detail && userDetails.pk === counter_offer_detail.product_details.vendor.pk){
      return t("profileModal.offers.productbid.pendingResBuyer");
    }
    return t("profileModal.offers.productbid.pendingResSeller");
  }

  calculateCommission = (value,type) => {
    const { counter_offer_detail } = this.props;
    let pc = null;
    if (
      counter_offer_detail.product_details &&
      counter_offer_detail.product_details.product_class
    ) {
      pc = counter_offer_detail.product_details.product_class;
    }
    if (pc) {
      let flat_rate = parseFloat(pc.flat_rate);
      let percent_rate = parseFloat(pc.percentage_rate);

      let float_value = parseFloat(value);
      let partner_value = flat_rate;

      let current_percentage = (percent_rate / 100) * float_value;
      if (current_percentage > flat_rate) {
        partner_value = current_percentage;
        if(type && type==='orgPrice'){
          let orgPrice=(float_value*100)/(100+percent_rate);
          return decimalPlace(orgPrice);
        }
      }
      if(type && type==='plus'){
        float_value = float_value + partner_value;
      }
      else{
        float_value = float_value - partner_value;
      }
      float_value = decimalPlace(float_value);
      return float_value;
    }
  };

  showMessage = () => {
    const { counter_offer_detail, userDetails } = this.props;
    if (
      counter_offer_detail &&
      userDetails &&
      counter_offer_detail.product_details &&
      counter_offer_detail.product_details.vendor &&
      userDetails.pk === counter_offer_detail.product_details.vendor.pk
    ) {
      return true;
    } else {
      return false;
    }
  };

  renderCounterOfferSection = () => {
    const { counter_offer_detail, t } = this.props;
    if (counter_offer_detail.create_counter_url) {
      return (
        <form onSubmit={this.submitCounterOffer} autoComplete="off">
          <GridContainer className="mt-3">
            <GridItem
              xs={6}
              className="dp-f jcc align-items-center textButtonDiv"
            >
              <CustomInput
                labelText={t("profileModal.counteroffer")}
                placeholder={t(
                  "profileModal.offers.productbid.counterofferamount"
                )}
                id="offer_amount_id"
                size="lg"
                formControlProps={{
                  fullWidth: true,
                  className: "mb-0"
                }}
                inputProps={{
                  onChange: this.handlePriceChange,
                  name: "offer_amount",
                  required: true,
                  value: this.state.counter_input_display,
                  type: "text",
                  disableUnderline: true
                }}
                inputActualProps={{
                  step: ".01"
                }}
              />
            </GridItem>
            <GridItem xs={6} className="dp-f jcc align-items-fe buttonTextDiv">
            {this.isMd() ?
              <Button
                color="primaryWhiteWithBorder"
                type="submit"
                fullWidth
                size="lg"
                className="mb-0 offer-button"
              >
                {t("profileModal.offers.productbid.counter_offer")}
              </Button> : 
               <Button
               color="primaryWhiteWithBorder"
               type="submit"
               fullWidth
               size="lg"
               className="mb-0"
             >
               {t("profileModal.offers.productbid.counterofferamount")}
             </Button>}
            </GridItem>
          </GridContainer>
        </form>
      );
    }
    return null;
  };

  showMessage = () => {
    const { counter_offer_detail, userDetails } = this.props;
    if (
      counter_offer_detail &&
      userDetails &&
      counter_offer_detail.product_details &&
      counter_offer_detail.product_details.vendor &&
      userDetails.pk === counter_offer_detail.product_details.vendor.pk
    ) {
      return true;
    } else {
      return false;
    }
  };

  renderAction = () => {
    const { counter_offer_detail, t } = this.props;

    if (!counter_offer_detail.accepted && !counter_offer_detail.rejected) {
      return (
        <div className="mt-3">
          <GridContainer>
            <GridItem xs={12} className="d-flex">
              <Button
                color="primaryWhiteWithBorder"
                fullWidth
                size="lg"
                className="text_wrap"
                onClick={this.submitBid("accept", counter_offer_detail)}
              >
                {this.isMd()
                  ? t("profileModal.offers.productbid.acc")
                  : t("profileModal.offers.productbid.accept")}
                {/* {!this.isMd() &&
                  " " +
                    counter_offer_detail.product_details.price.currency}{" "}
                {!this.isMd() && counter_offer_detail.offer_amount} */}
              </Button>
              <Button
                color="primaryWhiteWithBorder"
                fullWidth
                size="lg"
                className="text_wrap"
                onClick={this.submitBid("reject", counter_offer_detail)}
              >
                {t("profileModal.offers.productbid.rej")}
                {/* {!this.isMd() &&
                  " " +
                    counter_offer_detail.product_details.price.currency}{" "}
                {!this.isMd() && counter_offer_detail.offer_amount} */}
              </Button>
            </GridItem>
          </GridContainer>
          {this.renderCounterOfferSection()}
        </div>
      );
    } else {
      if (counter_offer_detail.accepted && this.showMessage()) {
        return (
          <>
            <Badge
            classes={{
              badge: "productBadge font11 p-0 status-badge"
            }}
          >
            <span
              className="order_status accepted"
            >
            {t("profileModal.offers.productbid.accepted")}
            </span>
          </Badge>
          <p >
            {t("profileModal.offers.productbid.acceptbit")}
          </p>
        </>
        );
      } else if (counter_offer_detail.rejected && !this.state.counter_offer_submitted) {
        return (
          <>
            <Badge
            classes={{
              badge: "productBadge font11 p-0 status-badge"
            }}
            
          >
            <span
              className="order_status rejected"
            >
             {t("profileModal.offers.productbid.rejected")}
            </span>
          </Badge>
          <p >
            {t("profileModal.offers.productbid.rejectbit")}
          </p>
        </>
        );
      } else if (counter_offer_detail.accepted && !this.showMessage()) {
        return (
          <>
            <Badge
            classes={{
              badge: "productBadge font11 p-0 status-badge"
            }}
          >
            <span
              className="order_status accepted"
            >
            {t("profileModal.offers.productbid.accepted")}
            </span>
          </Badge>
          <p >
            {t("profileModal.offers.productbid.buyerAcceptBit")}
          </p>
        </>
        );
      }
      return (
        <>
         <Muted>
            <h5 className="mb-1">
                {this.pendingFrom()}
            </h5>
          </Muted>
        {/* <p >{t("profileModal.offers.productbid.counter_submitted")}</p> */}
      </>
      );
    }
  };
  renderBack = () => {
    const { t, direction } = this.props;
    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="image_back" />
          {t("profileModal.offers.productbid.backtoList")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="image_back" />
          {t("profileModal.offers.productbid.backtoList")}
        </div>
      );
    }
  };

  get_is_seller=(item)=>{
    const { userDetails } = this.props;
    let is_seller=false;
    if(item.product_details && item.product_details.vendor.pk === userDetails.pk){
      is_seller=true;
    }
    return is_seller;
  }
  getPrice=(item, prevOffer)=>{
    let is_seller=this.get_is_seller(item);
    if(is_seller){
      if(prevOffer){
        return this.calculateCommission(item.previous_offer,'orgPrice');
      }
      return this.calculateCommission(item.offer_amount);
    }
    else{
      if(prevOffer){
        return item.previous_offer;
      }
      return item.offer_amount;
    }
  }

  partnerProductBidDetail = () => {
    const {
      classes,
      counter_offer_detail,
      vendor_fetching,
      t,
      direction,
      updateAccountPageComponent,
      bid_message, auction_notification_count
    } = this.props;
    const {counter_input_display} = this.state;

    let counter_offers_item_count =
      auction_notification_count && auction_notification_count.counter_offer
        ? auction_notification_count.counter_offer
        : null;
    // clear the count if page loaded from email link
    if (counter_offers_item_count && counter_offers_item_count.count > 0) {
      postActionNotificationClicked(counter_offers_item_count.url_to_read);
    }

    let product_status = null;
    if (
      counter_offer_detail &&
      counter_offer_detail.product_details &&
      counter_offer_detail.product_details.product_status
    ) {
      product_status = showStatusMessage(
        counter_offer_detail.product_details.product_status,
        "seller"
      );
    }

    if (vendor_fetching) {
      return (
        <Hidden smDown implementation="css">
          <Loader />
        </Hidden>
      );
    }

    if (counter_offer_detail) {
      return (
        <div>
          <Button
            link
            size="lg"
            onClick={() => updateAccountPageComponent("counteroffers")}
            className={direction === "rtl" ? "pr-0" : "pl-0"}
          >
            {this.renderBack()}
          </Button>
          <Card
             bordered
            className={
              product_status && product_status.color
                ? "pointer listed_item"
                : "pointer"
            }
            style={
              product_status && product_status.color
                ? {
                    borderColor: product_status ? product_status.color : null
                  }
                : null
            }
          >
            <CardBody
              className={
                !counter_offer_detail.accepted && !counter_offer_detail.rejected
                  ? "cardBodyMinHeight"
                  : null
              }
            >
              <div className="d-flex justify-content-center">
                <div className={classes.bidDetailContainer}>
                  <img
                    src={counter_offer_detail.product_details.image}
                    className={classes.img}
                    alt={counter_offer_detail.product_details.title}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <h5>{counter_offer_detail.product_details.title}</h5>
                </div>
                <div>
                  {product_status && product_status.message ? (
                    <Badge
                      classes={{
                        badge: "productBadge font11 p-0 status-badge"
                      }}
                    >
                      <span
                        style={{ backgroundColor: product_status.color }}
                        className="order_status"
                      >
                        {product_status.message}
                      </span>
                    </Badge>
                ) : null}
                </div>
              </div>
              <Muted>
                <p>{counter_offer_detail.product_details.description}</p>
              </Muted>
              <Muted>
                {counter_offer_detail.previous_offer ? (
                  <h5 className="mb-1">
                    {t("profileModal.offers.productbid.previous_offer")}{" "}
                    {counter_offer_detail.product_details.price.currency}{" "}
                    {this.getPrice(counter_offer_detail,true)}
                  </h5>
                ) : null}
                <h5 className="mb-1">
                  {this.get_is_seller(counter_offer_detail) ? t("profileModal.offers.productbid.received_counter_buyer"):t("profileModal.offers.productbid.received_counter_seller") }:{" "}
                  {counter_offer_detail.product_details.price.currency}{" "}
                  {this.getPrice(counter_offer_detail)}
                </h5>
                {this.state.send_counter_offer ? 
                <h5 className="mb-1">
                 {t("profileModal.offers.productbid.send_counter_offer")}:{" "}
                 {counter_offer_detail.product_details.price.currency}{" "}{decimalPlace(this.state.send_counter_offer)}
                </h5> : null}
              </Muted>
              {/* ------- This message only shows to the seller  not buyer   -------   */}
              {counter_input_display !== "" &&
              this.showMessage() &&
              bid_message &&
              !counter_offer_detail.accepted &&
              !counter_offer_detail.rejected ? (
                <Muted>
                  <h5 className="mb-1">
                    {t("profileModal.offers.productbid.counter_offer")}:{" "}
                    {counter_offer_detail.product_details.price.currency}{" "}
                    {decimalPlace(counter_input_display)} {bid_message}
                  </h5>
                </Muted>
              ) : null}
              {/* -------  End message   -------   */}
              {this.renderAction()}
            </CardBody>
          </Card>
        </div>
      );
    } else {
      return <p>{t("profileModal.offers.productbid.product")}</p>;
    }
  };
  render() {
 
    return this.partnerProductBidDetail();
  }
}

const mapDispatchToProps = dispatch => ({
  patchData: (data, isBuyer) => {
    dispatch({
      type: VendorProductActionTypes.PATCH_COUNTER_OFFER_DETAIL_REQUEST,
      //url:url,
      payload: data,
      isBuyer
    });
  },

  getDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_COUNTER_OFFER_DETAIL_REQUEST,
      payload: url
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  update_pickup_time: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PICKUP_TIME,
      data
    });
  },

  showSnackbar: (txt, variant = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  postCounterOffer: data => {
    dispatch({
      type: VendorProductActionTypes.CREATE_NEW_COUNTER_OFFER_REQUEST,
      payload: data
    });
  },
  updateBidMessage: message => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_BID_MESSAGE,
      message
    });
  }
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.modalReducer,
  ...state.vendorProductReducer,
  ...state.globalReducer,
  ...state.comboProductReducer,
  ...state.accountReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withWidth()
)(CounterOfferDetail);
