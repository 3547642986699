import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import BidActionTypes from "constants/bidConstants.jsx";

const initialState = {
  make_bid: null,
  bid_fetching: false,
  bid_error: null,
  bid_history: null,
  past_bids: null,
  bidItem: null,
  post_bid_req_sent: false
};

const bidReducer = (state = initialState, action) => {
  switch (action.type) {
    // POST BID
    case BidActionTypes.POST_CREATE_BID_REQUEST:
      return { ...state, bid_error: null, bid_fetching: true, post_bid_req_sent: true };

    case BidActionTypes.POST_CREATE_BID_SUCCESS:
      return {
        ...state,
        bid_fetching: false,
        bid_error: null,
        make_bid: action.data,
        post_bid_req_sent: false
      };

    case BidActionTypes.POST_CREATE_BID_FAILED:
      return { ...state, bid_fetching: false, bid_error: action.error, post_bid_req_sent: false };

    //GET BID  HISTORY
    case BidActionTypes.GET_BID_HISTORY_REQUEST:
      return { ...state, bid_error: null, bid_fetching: true };

    case BidActionTypes.GET_BID_HISTORY_SUCCESS:
      return {
        ...state,
        bid_fetching: false,
        bid_error: null,
        bid_history: action.data,
        past_bids: action.past_bids
      };

    case BidActionTypes.GET_BID_HISTORY_FAILED:
      return { ...state, bid_fetching: false, bid_error: action.error };

    case BidActionTypes.UPDATE_BID_ITEM:
      return { ...state, bidItem: action.data };
    //RESET
    case BidActionTypes.BID_RESET:
      return initialState;
    default:
      return state;
  }
};

const persistConfig = {
  key: "bid",
  storage: storage,
  blacklist: ["bidItem",]
};

export default persistReducer(persistConfig, bidReducer);
