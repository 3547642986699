import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx";

import {
  title,
  container,
  blackColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

const gridSectionStyle = theme => ({
  ...projectsStyle,
  ...javascriptStyles(theme),
  center: {
    padding: "0.9375rem 1.875rem",
    WebkitBoxFlex: "1",
    position: "relative",
    zIndex: "2",
    minHeight: "280px",
    paddingTop: "40px",
    paddingBottom: "40px",
    maxWidth: "440px",
    margin: "auto",
    flex: "none",
    alignItems: "center",
    display: "flex"
  },
  icons: {
    width: "32px",
    height: "32px"
  },
  bigRoundButton: {
    width: "100px !important",
    height: "100px !important",
    borderRadius: "50%"
  },
  modalLargepopup: {
    // width: "1200px !important",
    // maxWidth: "1200px !important",
    // border:"2px solid red",
    [theme.breakpoints.up("md")]: {
      maxWidth: "1000px !important"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none !important",
      width: "80%",
      marginLeft: "0 !important",
      marginRight: "0 !important"
    }
  },
  title: {
    ...title,
    position: "absolute",
    bottom: 0,
    zIndex: 2
  },
  container,
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    }
  }
});

export default gridSectionStyle;
