import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  SHOW_CART_DROPDOWN: null,

  UPDATE_CHECKOUT_ADDRESS: null,
  UPDATE_CHECKOUT_PAYMENT: null,
  UPDATE_FORCE_STEP: null,

  GET_REVIEW_CHECKOUT_REQUEST: null,
  GET_REVIEW_CHECKOUT_SUCCESS: null,
  GET_REVIEW_CHECKOUT_FAILED: null,

  GET_ORDERS_REQUEST: null,
  GET_ORDERS_SUCCESS: null,
  GET_ORDERS_FAILED: null,

  UPDATE_ORDER_LIST_DETAIL: null,

  GET_ORDER_DETAILS_REQUEST: null,
  GET_ORDER_DETAILS_SUCCESS: null,
  GET_ORDER_DETAILS_FAILED: null,

  ADD_TO_CART_REQUEST: null,
  ADD_TO_CART_SUCCESS: null,
  ADD_TO_CART_FAILED: null,

  POST_CHECKOUT_REQUEST: null,
  POST_CHECKOUT_SUCCESS: null,
  POST_CHECKOUT_FAILED: null,

  UPDATE_CART_REQUEST: null,
  UPDATE_CART_SUCCESS: null,
  UPDATE_CART_FAILED: null,

  DELETE_ITEM_REQUEST: null,
  DELETE_ITEM_SUCCESS: null,
  DELETE_ITEM_FAILED: null,

  POST_PAYMENT_URL_REQUEST: null,
  POST_PAYMENT_URL_SUCCESS: null,
  POST_PAYMENT_URL_FAILED: null,

  UPDATE_ORDER_DETAIL_VARIABLE: null,
  UPDATE_ORDERS_DETAILS_REQUEST_NUMBER: null,
  UPDATE_DELIVERY_TIME: null,
  CART_RESET: null,

  GET_CARD_FEES_REQUEST: null,
  GET_CARD_FEES_SUCCESS: null,
  GET_CARD_FEES_FAILED: null,

  UPDATE_BASKET_ID_SUCCESS: null,

  POST_VOUCHER_REQUEST: null,
  POST_VOUCHER_SUCCESS: null,
  POST_VOUCHER_FAILED: null,

  UPDATE_VOUCHER_CODE: null,

  REMOVE_VOUCHER_REQUEST: null,
  REMOVE_VOUCHER_SUCCESS: null,
  REMOVE_VOUCHER_FAILED: null
});

export default ActionTypes;
