import buttonStyle from "assets/jss/material-kit-pro-react/components/buttonStyle.jsx";
import {
  htPrimaryYellow,
  htPrimaryLight,
  htPrimaryGreen,
  GraphicArabicWeb,
  htSecondaryLight,
  htDarkGrey
} from "kayfik/assets/jss/universal.jsx";
import {
  //grayColor,
  blackColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

const customButtonStyle = {
  ...buttonStyle,
  button: {
    minHeight: "auto",
    minWidth: "100px",
    // backgroundColor: grayColor[0],
    color: whiteColor,
    // boxShadow:
    //   "0 2px 2px 0 rgba(" +
    //   hexToRgb(grayColor[0]) +
    //   ", 0.14), 0 3px 1px -2px rgba(" +
    //   hexToRgb(grayColor[0]) +
    //   ", 0.2), 0 1px 5px 0 rgba(" +
    //   hexToRgb(grayColor[0]) +
    //   ", 0.12)",
    border: "none",
    borderRadius: "0",
    position: "relative",
    padding: ".375rem .75rem",
    margin: ".3125rem 1px",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "uppercase",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: whiteColor
      // backgroundColor: grayColor[0],
      // boxShadow:
      //   "0 14px 26px -12px rgba(" +
      //   hexToRgb(grayColor[0]) +
      //   ", 0.42), 0 4px 23px 0px rgba(" +
      //   hexToRgb(blackColor) +
      //   ", 0.12), 0 8px 10px -5px rgba(" +
      //   hexToRgb(grayColor[0]) +
      //   ", 0.2)"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle"
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle"
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginTop: "0px",
        marginRight: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px"
      }
    }
  },
  lg: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "-4px"
      }
    },
    padding: "1.125rem 2.25rem",
    fontSize: "1rem",
    lineHeight: "1.333333",
    borderRadius: "0 "
  },

  sm: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "1px"
      }
    },
    padding: "0.40625rem 1.25rem",
    fontSize: "0.6875rem",
    lineHeight: "1.5",
    borderRadius: "0 "
  },
  justIcon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    fontSize: "20px",
    height: "41px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px"
    },
    borderRadius: "100px",
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px"
      },
      "& svg": {
        width: "32px",
        height: "32px"
      }
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px"
      },
      "& svg": {
        width: "17px",
        height: "17px"
      }
    }
  },
  //primary yellow background
  primaryYellow: {
    backgroundColor: htPrimaryYellow,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(htPrimaryYellow) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(htPrimaryYellow) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(htPrimaryYellow) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: htPrimaryYellow,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(htPrimaryYellow) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(htPrimaryYellow) +
        ", 0.2)"
    }
  },
  // primary light background
  primaryLight: {
    backgroundColor: htPrimaryLight,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(htPrimaryLight) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(htPrimaryLight) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(htPrimaryLight) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: htPrimaryLight,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(htPrimaryLight) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(htPrimaryLight) +
        ", 0.2)"
    }
  },
  secondaryLight: {
    color: htPrimaryGreen,
    backgroundColor: htSecondaryLight,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(htSecondaryLight) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(htSecondaryLight) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(htSecondaryLight) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: htSecondaryLight,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(htSecondaryLight) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(htSecondaryLight) +
        ", 0.2)"
    }
  },
  htPrimaryGreen: {
    backgroundColor: htPrimaryGreen,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(htPrimaryGreen) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(htPrimaryGreen) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(htPrimaryGreen) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: htPrimaryGreen,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(htPrimaryGreen) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(htPrimaryGreen) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(htPrimaryGreen) +
        ", 0.2)"
    }
  },
  // white back ground with border
  primaryWhiteWithBorder: {
    backgroundColor: whiteColor,
    border: "solid",
    borderWidth: "0.05rem",
    borderColor: htPrimaryLight,
    color: htPrimaryGreen,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(htPrimaryLight) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(htPrimaryLight) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(htPrimaryLight) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: htPrimaryLight,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(htPrimaryLight) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(htPrimaryLight) +
        ", 0.2)"
    }
  },
  //disabled Buttons grey color 
  DarkGreyWithBorder: {
    backgroundColor: '#f4f7fa',
    border: "solid",
    borderWidth: "0.05rem",
    borderColor: "#dbdcde",
    color: '#979ea9',
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(htDarkGrey) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(htDarkGrey) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(htDarkGrey) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: htDarkGrey,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(htDarkGrey) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(htDarkGrey) +
        ", 0.2)"
    }
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "rgba(255,255,255,0)",
      color: htPrimaryGreen,
      boxShadow: "none"
    }
  },
  font: {
    fontFamily: GraphicArabicWeb + "!important"
  },
  // yellowText: {
  //   "&:hover": {
  //     color: htPrimaryYellow + "!important"
  //   }
  // }
};

export default customButtonStyle;
