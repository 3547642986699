
import { getProductList } from "actions/productActions.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import { store } from "utils/store.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";

const ModalPagination = {
  isBottom: el => {
    if (el) {
      let modalRootElement = document.getElementById("modalRootId");
      return (
        el.getBoundingClientRect().bottom <=
        modalRootElement.getBoundingClientRect().bottom
      );
    } else {
      return null;
    }
  },

  loadProducts: (nextLinkToFetch, loadMore) => {
    let currentFetch = store.getState().vendorProductReducer.currentFetch;
    if (nextLinkToFetch && nextLinkToFetch !== currentFetch) {
      currentFetch = nextLinkToFetch;
      let payload = true;
      store.dispatch({
        type: VendorProductActionTypes.SHOW_LOADER,
        payload,
        currentFetch
      });
      getProductList(nextLinkToFetch)
        .then(res => res.data)
        .then(data1 => {
          if (data1) {
            let newDataObj = data1;
            let updatedResults = null;
            let partner_product_list = store.getState().vendorProductReducer
            .partner_product_list;
            let orders_list = store.getState().cartReducer.orders_list;
            let partner_product_bid_list = store.getState().vendorProductReducer
            .partner_product_bid_list;
            let counter_offers = store.getState().vendorProductReducer
            .counter_offers;
            let reqProductList = store.getState().accountReducer
            .reqProductList;
            let prevRes = null;
            if (loadMore === "partner-products") {
              prevRes = partner_product_list.results;
            } else if (loadMore === "orders") {
              prevRes = orders_list.results;
            } else if (loadMore === "product-bids") {
              prevRes = partner_product_bid_list.results;
            } else if (loadMore === "counter-offers") {
              prevRes = counter_offers.results;
            }else if (loadMore === "reqProd") {
              prevRes = reqProductList.results;
            }
            let newRes = data1.results;
            updatedResults = [...prevRes, ...newRes];
            let objects1 = {};
            objects1["previous"] = newDataObj.previous;
            objects1["next"] = newDataObj.next;
            objects1["count"] = newDataObj.count;
            objects1["results"] = updatedResults;
            let data = objects1;
            if (loadMore === "partner-products") {
              store.dispatch({
                type: VendorProductActionTypes.GET_PARTNER_PRODUCT_LIST_SUCCESS,
                data
              });
            } else if (loadMore === "orders") {
              store.dispatch({
                type: CartActionTypes.GET_ORDERS_SUCCESS,
                data
              });
            } else if (loadMore === "product-bids") {
              store.dispatch({
                type:
                  VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_LIST_SUCCESS,
                data
              });
            } else if (loadMore === "counter-offers") {
              store.dispatch({
                type:
                  VendorProductActionTypes.GET_COUNTER_OFFER_LIST_SUCCESS,
                data
              });
            }
            else if (loadMore === "reqProd") {
              let reqProductList = data;
              store.dispatch({
                type: AccountActionTypes.REQ_PROD_LIST_UPDATE,
                reqProductList
              });
            }
          }
          payload = false;
          store.dispatch({
            type: VendorProductActionTypes.SHOW_LOADER,
            payload,
            currentFetch
          });
        })
        .catch(e => {
          let error = e;
          if (loadMore === "partner-products") {
            store.dispatch({
              type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DETAIL_FAILURE,
              error
            });
          } else if (loadMore === "orders") {
            store.dispatch({
              type: CartActionTypes.GET_ORDERS_FAILED,
              error
            });
          } else if (loadMore === "product-bids") {
            store.dispatch({
              type:
                VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_LIST_FAILURE,
              error
            });
          }
        });
    }
  },

  trackScrolling: (elementID, nextLinkToFetch, loadMore) => {
    const wrappedElement = document.getElementById(elementID);
    if (ModalPagination.isBottom(wrappedElement)) {
      ModalPagination.loadProducts(nextLinkToFetch, loadMore);
    }
  }
};

export default ModalPagination;
