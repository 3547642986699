import { store } from "utils/store.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import i18n from "i18next";
import queryString from "query-string";

export const productCreateRequestCall = () => {
  let product_data = store.getState().comboProductReducer.product_data;
  let userDetails = store.getState().accountReducer.userDetails;
  if (product_data.product_class) {
    let form_data = product_data;
    form_data["user_email"] = userDetails.email;
    store.dispatch({
      type: ComboProductActionTypes.PRODUCT_CREATE_REQUEST,
      form_data
    });
  }
};

export const showModalHelper = (modalProps, modalType) => {
  store.dispatch({
    type: ModalActionTypes.SHOW_MODAL_REQUEST,
    modalProps,
    modalType
  });
};

export const showSnackbar = (txt, variant) => {
  store.dispatch(
    enqueueSnackbar({
      message: txt,
      options: {
        variant: variant,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      }
    })
  );
};

export const resetUnauthorized = () => {
  store.dispatch({ type: AccountActionTypes.ACCOUNT_RESET });
  store.dispatch({ type: ProductActionTypes.RESET_PRICE_ATTS });
  let modalType = "loginModal";
  let modalProps = { open: true, title: modalType };
  showModalHelper(modalProps, modalType);
};

export const getuserDetails = () => {
  store.dispatch({
    type: AccountActionTypes.USER_DETAIL_REQUEST
  });
};

export const removeParam = (key, sourceURL) => {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
};

export const decimalPlace = value => {
  // 3 decimal places
  value = parseFloat(value);
  return value.toFixed(3);
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

//for showing message based on seller and buyer (Order details and listed Items)

export const showStatusMessage = (order_status, user_status , action_url) => {

  
  let statuses = [
    //shippa status for listed item only
    {
      code: "order_created",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.order_created")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "enroute_to_pickup",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.enroute_to_pickup")
          : null,
      color: "#FFC107"
    },
    {
      code: "pickup_completed",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.pickup_completed")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "pickup_cancelled",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.pickup_cancelled")
          : null,
      color: "black"
    },
    {
      code: "enroute_to_drop_off",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.enroute_to_drop_off")
          : null,
      color: "#00ACC1"
    },
    {
      code: "package_delivered",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.package_delivered")
          : null,
      color: "#FFF350"
    },
    {
      code: "no_answer",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.no_answer")
          : null,
      color: "black"
    },
    {
      code: "reschedule_tomorrow",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.reschedule_tomorrow")
          : null,
      color: "#00ACC1"
    },
    {
      code: "carry_action_required",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.carry_action_required")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "customer_not_available",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.customer_not_available")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "wants_to_change_address",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.wants_to_change_address")
          : null,
      color: "#FFF350"
    },
    {
      code: "invalid_ddress",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.invalid_ddress")
          : null,
      color: "#00ACC1"
    },
    {
      code: "id_problem",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.id_problem")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "rejected_by_customer",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.rejected_by_customer")
          : null,
      color: "black"
    },
    {
      code: "payment_problem",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.payment_problem")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "missing_info_document",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.missing_info_document")
          : null,
      color: "#006978"
    },
    {
      code: "wants_to_reschedule",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.wants_to_reschedule")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "enroute_to_return",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.enroute_to_return")
          : null,
      color: "#006978"
    },
    {
      code: "pending_return",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.pending_return")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "returned",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.returned")
          : null,
      color: "#FFC107"
    },
    {
      code: "new",
      message:
        user_status === "seller" ? i18n.t("listedProductStatus.new") : null,
      color: "grey"
    },
    {
      code: "with_bid",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.with_bid")
          : null,
      color: "#FFC107"
    },
    {
      code: "with_counter_offer",
      message:
        user_status === "seller" && action_url && !action_url.url 
        ? i18n.t("listedProductStatus.pending")
        :i18n.t("listedProductStatus.with_counter_offer"),
      color: "black"
    },
    {
      code: "Paid",
      message:
        user_status === "seller" ? i18n.t("listedProductStatus.paid") : null,
      color: "#006978"
    },
    {
      code: "To be ship",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.to_be_ship")
          : null,
      color: "#00ACC1"
    },
    {
      code: "Unavailable",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.unavailable")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "Confirmed unavailable",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.confirmed_unavailable")
          : null,
      color: "#5DDEF4"
    },
    {
      code: "Cancelled",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.cancelled")
          : null,
      color: "#C79100"
    },
    {
      code: "Delivered",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.delivered")
          : null,
      color: "#FFC107"
    },
    {
      code: "Refund requested",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.refund_requested")
          : null,
      color: "#FFC107"
    },
    {
      code: "Refund requested to TAP",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.refund_requested_to_tap")
          : null,
      color: "#FFC107"
    },
    {
      code: "Refunded",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.refunded")
          : null,
      color: "grey"
    },
    {
      code: "Settled",
      message: i18n.t("listedProductStatus.settled"),
      color: "#FFC107"
    },
    {
      code: "Timeout",
      message: i18n.t("listedProductStatus.time_out"),
      color: "#FFC107"
    },
    {
      code: "added_to_cart",
      message: i18n.t("listedProductStatus.added_to_the_cart"),
      color: "grey"
    },
    {
      code: "Shipping",
      message:
        user_status === "seller"
          ? i18n.t("listedProductStatus.enroute_to_drop_off")
          : null,
      color: "#00ACC1"
    },
  ];

  let lineStatus = statuses.find(item => item.code === order_status);
  return lineStatus;
};

export const getExtraParameter = () => {
  let params = {};
  params = queryString.parse(window.location.search);

  for (let item in params) {
    let new_item = item.replace("amp;", "");
    params[new_item] = params[item];
  }
  return params;
};

// To convert numbers to arabic
const getArabic = (str, allowDecimal) => {
  return (str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
    return d.charCodeAt(0) - 1632; // Convert Arabic numbers
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function(d) {
    return d.charCodeAt(0) - 1776; // Convert Persian numbers
  }));
}

export const convertHindiToArabic = (str, allowDecimal) => {
  let arabic = getArabic(str, allowDecimal);
  return arabic;
};

// eslint-disable-next-line no-extend-native
String.prototype.toIndiaDigits = function() {
  var id = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", "."]; // To validate that only numbers are accepted
  return this.replace(/[0-9]/g, function(w) {
    return id[+w];
  });
};

export const converToArabicString = str => {
  return str.toIndiaDigits();
};

export const isInputEnglish = str => {
  if (/^[0-9a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/.test(str)) {
    // To check if input is arabic or english
    return true;
  } else {
    return false;
  }
};

export const fromArabicToASCII = arabic => {
  // To convert arabic numberst to eng
  return arabic.replace(/[\u0660-\u0669\u06F0-\u06F9]/g, function(a) {
    return String.fromCharCode((a.charCodeAt(0) & 15) + 48);
  });
};

//posting pending auction notification count if count more than zero
export const postActionNotificationClicked = url => {
  if (url) {
    store.dispatch({
      type: VendorProductActionTypes.CLEAR_ACTION_COUNT_REQUEST,
      payload: url
    });
  }
};

//get notification count
export const getNotificationCount = () => {
  const account_token = store.getState().accountReducer.account_token;
  //If token is available update the count
  if(window.location.href.indexOf("/checkout") <0){
    if (account_token) {
      store.dispatch({
        type: VendorProductActionTypes.GET_ACTION_COUNT_REQUEST,
      });
    }
  }

};

export const showCurrentOrderStep = status => {
  let curr = null;
  curr = status.find(i => i.status === true);
  if (curr) {
    return i18n.t("OrderSteps." + curr.code + "_badge");
  }
};

// Checking if item already available on the cart based on product url
export const checkItemAvailableInCart = url => {
  let review_checkout = store.getState().cartReducer.review_checkout;
  if (
    url &&
    review_checkout &&
    review_checkout.basket_info &&
    review_checkout.basket_info.lines &&
    review_checkout.basket_info.lines.length > 0
  ) {
    let statuses = review_checkout.basket_info.lines;
    let lineStatus = statuses.find(item => item.product === url);
    if (lineStatus) {
      return true;
    }
  }
};
