import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// actions
import { showModal, hideModal } from "actions/modalActions.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import IconButton from "@material-ui/core/IconButton";
import api from "utils/api.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import CartActionTypes from "constants/cartConstants.jsx";

class ICLineDeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: null,
      selectedReason: null,
      stateLoader: false
    };
  }

  componentDidMount() {
    let delete_url = api.instant_item_delete();
    this.setState({stateLoader: true});
    api.getFunction(delete_url)
      .then(res => {
        let data = res.data;
        this.setState({stateLoader: false});
        this.setState({ reasons: data });
        
      })
      .catch(e => {
        this.setState({stateLoader: false});
      })
  }

  handleSubmit = e => {
    const { modalProps, reviewCheckout } = this.props;
    const { selectedReason } = this.state;
    let lineId = modalProps.lineId;
    e.preventDefault();
    if(selectedReason && lineId){
      let form_data = {
        line: lineId,
        reason: selectedReason.id,
      };
      if(selectedReason.is_other){
        form_data["description"] = e.target.description.value;
      }
      let post_url = api.instant_item_delete();
      api.postFunction(post_url, form_data)
      .then(res=>{
        reviewCheckout();
        this.props.closeModal();
      })
      .catch(e=>{
        console.log("error", e.response);
      })
    }
  };
  handleReasonChange=(value)=>{
    this.setState({ selectedReason: value});
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, t, direction, global_fetching } = this.props;
    const { stateLoader } = this.state;
    if (global_fetching || stateLoader) {
      return <Loader />;
    }
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <form onSubmit={this.handleSubmit} autoComplete="off" className="w-100">
              <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className="m-0 mb-3 htPrimaryGreen">
                    {t("buyingPage.checkOut.instantCash.instantCashTitle")}
                  </h2>
                  <h5 className="m-0 mb-3 pb-4">
                    {t("buyingPage.checkOut.instantCash.instantCashSubtitle")}
                  </h5>
                </GridItem>
                <GridItem xs={12}>
                 {this.state.reasons &&  
                 <SelectAutocomplete
                    controlProps={{ marginBottom: "15px" }}
                    required={true}
                    inputProps={{
                      name: "reasons",
                      required: true
                    }}
                    value={this.state.selectedReason}
                    autocompleteOptions={this.state.reasons}
                    selectOnChange={this.handleReasonChange}
                  //placeholder={t("companySignup.CompanyDetailTWo.selectLanguage")}
                  labelText={t("buyingPage.checkOut.instantCash.reason")}
                  getOptionLabel={({ title }) => title}
                  />}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                 {this.state.selectedReason && this.state.selectedReason.is_other &&  <CustomInput
                    labelText={t("buyingPage.checkOut.instantCash.description")}
                    id="id_description"
                    size="lg"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "description",
                      required: true,
                      type: "text",
                      rows: 3,
                      multiline: true,
                      disableUnderline: true
                    }}
                  />}
                </GridItem>
                <GridItem
                  xs={12}
                  sm={9}
                  md={9}
                  className={classNames(classes.marginAuto, "mt-4")}
                >
                  <Button
                    type="submit"
                    color="primaryWhiteWithBorder"
                    size="lg"
                    className="mb-3"
                    fullWidth
                  >
                   {t("buyingPage.checkOut.reviewcart.remove")}
                  </Button>
                </GridItem>
              </GridItem>
            </form>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  hidemodal: () => dispatch(hideModal()),
  showmodal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
  reviewCheckout: () => {
    dispatch({
      type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST
    });
  },
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ICLineDeleteModal);
