import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// React i18next
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
// React router
import { withRouter } from "react-router-dom";
// @material-ui/core components
import compose from "recompose/compose";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import styles from "kayfik/assets/jss/views/Shop/Sections/HorizontalProductGridStyle.jsx";
import Products from "kayfik/views/ProductDetail/products.jsx";
import { hideModal } from "actions/modalActions.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
//action
import ProductActionTypes from "constants/productConstants.jsx";
import _ from "lodash";
import queryString from "query-string";
import LoaderHorizontal from "kayfik/components/Loader/loaderhorizontal.jsx";
class SectionProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applyFilter: false,
      mobileCurrentURL: null,
      low: 0,
      high: 0
    };
  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal, showmodal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    const success_modal_type = "addtocartModal";
    const success_modalProps = {
      open: true,
      title: "addtocartModal",
      shopping: this.shopping,
      checkoutNow: this.checkoutNow
    };

    showmodal(
      {
        open: true,
        title: modal_type,
        modalLarge: modal_type === "quickAddModal" ? true : false,
        success_modalProps: success_modalProps,
        success_modal_type: success_modal_type
      },
      modal_type
    );
  };

  shopping = () => {
    this.props.history.push("/shop");
  };
  checkoutNow = () => {
    this.props.history.push("/checkout");
  };

  handleSelectedProductCategory = (category,parent_category) => {
    const {
      updateActiveCategory,
      updateLoaderHorizontal,
      resetObjects,
      showLoader,
      active_sort,
      history,
    } = this.props;   
    let url = category.narrow_url;
    let new_url = new URL(url);
    let params = this.getExtraParameter(new_url.search);
    resetObjects();
    showLoader(false, null);
    updateActiveCategory({ ...category }, params.params);
    if (active_sort) {
      history.push(
        "/search/?params=category_exact:"+parent_category.text+"&params=" + params.params + "&sort_by=" + active_sort.code
      );
      url = url + "&sort_by=" + active_sort.code;
    } else {
      history.push("/search/?params=category_exact:"+parent_category.text+"&params=" + params.params);
    }
    // getProducts(url); 
    updateLoaderHorizontal(true);
  };

  getExtraParameter = url => {
    let params = {};
    params = queryString.parse(url);
    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }
    return params;
  };

  renderProducts = products => {
    const { t } = this.props;
    if (products.length > 0) {
      return products.map((item, index) => {
        return <Products item={item} key={index} />;
      });
    } else {
      return <p>{t("buyingPage.HorizontalProducts.products")} </p>;
    }
  };

  byKey = key => {
    return function(o) {
      var v = parseInt(o[key], 10);
      return isNaN(v) ? o[key] : v;
    };
  };

  renderRows = () => {
    const {category, top_categories, classes, direction, t, active_parent_category} = this.props;
    const settings = {
      dots: false,
      infinite: false,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        }
      ]
    };

    if (direction === "rtl") {
      settings["rtl"] = true;
    }

    let sortedMenuItems =[];
    if(active_parent_category){
      let new_cat = category.filter(i=>i.count>0);
      let categories_text=new_cat.map(i=>{
        return i.text;
      })
      let new_top_categories = top_categories.filter(i=>categories_text.includes(i.category.text));
      sortedMenuItems = _.sortBy(new_top_categories, o => o.category.text);
    }
    else{
      sortedMenuItems = _.sortBy(top_categories, o => o.category.text);
    }
    return sortedMenuItems.map((item, index) => {
      return (
        <div className="product-category-carousel" key={index}>
          {item.products.length > 0 ? (
            <GridContainer>
              <GridItem sm={12} md={12} className="px-0">
                <h2 className={classes.title + "pointer category"}>
                  {item.category.title}&nbsp;
                  <span
                    className="F12 color-pl pointer"
                    onClick={() =>
                      this.handleSelectedProductCategory(item.category, item.parent_category)}
                  >
                    {t("buyingPage.HorizontalProducts.viewAll")}
                  </span>
                </h2>
              </GridItem>
              <GridItem sm={12} md={12} className="px-0">
                <Carousel
                  {...settings}
                  className={
                    item.products.length > 3
                      ? "product-carousel"
                      : "product-list"
                  }
                >
                  {this.renderProducts(item.products, item.category)}
                </Carousel>
              </GridItem>
            </GridContainer>
          ) : null}
        </div>
      );
    });
  };

  render() {
    const{ loader_horizontal,product_fetching }= this.props;
    if(loader_horizontal === true && product_fetching){
      return <LoaderHorizontal  />
    }
    return(this.renderRows()) 
  }
}

const mapDispatchToProps = dispatch => ({
  hidemodal: () => dispatch(hideModal()),
  showmodal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getProducts: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_REQUEST,
      payload: url
    });
  },
  updateActiveCategory: category => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_CATEGORY,
      category
    });
  },
  updateQuickAdd: (data, success_modal) => {
    dispatch({
      type: ProductActionTypes.UPDATE_QUICKADD,
      data: data
    });
  },
  getProductDetail: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  updateLoaderHorizontal: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_LOADER_HORIZONTAL,
      value
    });
  },
  showLoader: (payload, currentFetch) => {
    dispatch({
      type: ProductActionTypes.SHOW_PRODUCTS_LOADER,
      payload,
      currentFetch
    });
  },
  resetObjects: () => {
    dispatch({
      type: ProductActionTypes.RESET_OBJECTS
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.productReducer,
  ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SectionProducts);
