import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";

class Tagline extends React.Component {
  render() {
    const { text, classes } = this.props;
    return (
      <Card color="primaryYellow" className="br-0 mt-0" id="cards">
        <CardBody color className="taglineBody">
          <h2 className={classes.cardTitleWhite + " mt-0 h2tag"}>{text}</h2>
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(styles)(Tagline);
