import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import compose from "recompose/compose";
import { connect } from "react-redux";
import scrollToTopStyle from "kayfik/assets/jss/components/ScrollToTop/scrollToTop.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import { getProductList } from "actions/productActions.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import i18n from "locales/i18n.jsx";

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFetch: null
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  isBottom(el) {
    if (el) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    } else {
      return null;
    }
  }

  loadProducts = () => {
    const {
      prod_next_list,
      updateProducts,
      objects,
      updateFetching,
      errorLoading,
      showSnackbar,
      showLoader,
      fetching_more_products,
      currentFetch
    } = this.props;
    
    if (
      prod_next_list &&
      prod_next_list !== currentFetch &&
      !fetching_more_products
    ) {
      updateFetching(true);
      this.setState({ currentFetch: prod_next_list }, () => {
        showLoader(true, prod_next_list);
        getProductList(prod_next_list)
          .then(res => res.data)
          .then(data => {
            if (data.objects) {
              let newDataObj = data.objects;
              let updatedResults = null;
              let prevRes = objects.results;
              let newRes = data.objects.results;
              updatedResults = [...prevRes, ...newRes];
              let objects1 = {};
              objects1["previous"] = newDataObj.previous;
              objects1["next"] = newDataObj.next;
              objects1["count"] = newDataObj.count;
              objects1["results"] = updatedResults;
              let sort_by = data.sort_by;
              let category = data.fields.category;
              let attribute_value = data.fields.attribute_value;
              updateProducts(objects1, sort_by, category, attribute_value);
              updateFetching(false);
              showLoader(false, prod_next_list);
            }
          })
          .catch(e => {
            let error = e;
            errorLoading(error);
            let txt = i18n.t("common.error.network");
            showSnackbar(txt);
          });
      });
    }
  };

  trackScrolling = () => {
    const { elementID, loadMore } = this.props;
    const wrappedElement = document.getElementById(elementID);
    if (this.isBottom(wrappedElement)) {
      if (loadMore === "products") {
        this.loadProducts();
      }
    }
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  getProducts: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_REQUEST,
      payload: url
    });
  },
  updateFetching: value => {
    dispatch({
      type: ProductActionTypes.FETCHING_MORE_PRODUCTS,
      payload: value
    });
  },
  updateProducts: (objects, sort_by, category, attribute_value) => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_SUCCESS,
      objects,
      sort_by,
      category,
      attribute_value
    });
  },
  errorLoading: error => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_FAILED,
      error
    });
  },
  showLoader: (payload, currentFetch) => {
    dispatch({
      type: ProductActionTypes.SHOW_PRODUCTS_LOADER,
      payload,
      currentFetch
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

const mapStateToProps = state => ({
  ...state.productReducer
});

export default compose(
  withStyles(scrollToTopStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(Pagination);
