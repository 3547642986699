import axios from "axios";
import { store } from "utils/store.jsx";

axios.defaults.withCredentials = true;

const api = {
  // root: "http://localhost:8000/api/v1/",
  // root: "https://api2.kayfik.hypertext.site/api/v1/",
  root: "https://api.kayfik.com/api/v1/",
  enableSentry: true, //TRUE FOR PROD
  facebookAppId: "362166054718564",
  googleAppId:
    "689111673202-2a5nfqvmthrgks6pf9rn3e7uptgrt5ts.apps.googleusercontent.com",

  // ACCOUNT RELATED API
  login: () => {
    return api.root + "accounts/login/";
  },
  personal_signup: () => {
    return api.root + "accounts/registration/";
  },
  forgotpassword: () => {
    return api.root + "accounts/password/reset/";
  },
  passwordReset: () => {
    return api.root + "accounts/password/reset/confirm/";
  },
  changepassword: () => {
    return api.root + "accounts/password/change/";
  },
  signout: () => {
    return api.root + "accounts/logout/";
  },
  verify_token: () => {
    return api.root + "accounts/verify-token/";
  },
  company_signup: () => {
    return api.root + "accounts/company/register/";
  },
  company_signup_verification: () => {
    return api.root + "accounts/company/register/verification/";
  },
  checkout: () => {
    return api.root + "checkout/";
  },
  confirmemail: () => {
    // this expect to have a key data on POST
    return api.root + "accounts/registration/verify-email/";
  },
  paymentInfo: () => {
    // this expect to have a key data on POST
    return api.root + "accounts/partner/payment/information/";
  },
  resend_email_confirmation: () => {
    return api.root + "accounts/resend/";
  },
  resend_phone_confirmation: () => {
    return api.root + "phone/start/verification/";
  },
  phone_verify_code: () => {
    return api.root + "phone/verify/code/";
  },
  area_list: () => {
    return api.root + "area/list/";
  },

  // SOCIAL LOGIN
  facebookLogin: () => {
    return api.root + "accounts/login/facebook/";
  },
  googleLogin: () => {
    return api.root + "accounts/login/google/";
  },
  twitterLogin: () => {
    return api.root + "accounts/login/twitter/";
  },

  //VENDOR PRODUCT LIST
  partner_address: () => {
    return api.root + "accounts/partner/address/";
  },

  //VENDOR PRODUCT LIST
  vendor_product_list: () => {
    return api.root + "partner/product/list/";
  },

  //Delivery list
  vendor_product_delivery: () => {
    return api.root + "partner/product/todeliver/";
  },

  //VENDOR PRODUCT BID LIST
  vendor_product_bid_list: () => {
    return api.root + "partner/bid/list/";
  },

  // POST ADDRESS USER
  user_address: () => {
    return api.root + "useraddresses/";
  },

  // PRODUCT RELATED API
  get_product_list: () => {
    return api.root + "search/facets/";
  },

  // ADD TO CART
  post_add_to_cart: () => {
    return api.root + "basket/add-product/";
  },

  // REVIEW CHECKOUT
  get_review_checkout: code => {
    if (code) {
      return api.root + "review/checkout/?payment_code=" + code;
    } else {
      return api.root + "review/checkout/";
    }
  },

  // REVEIW CHECKOUT ONLY ON CHECKOUT PAGE
  get_review_checkout_recall: basket_id => {
    return (
      api.root +
      "review/checkout/?recall=" +
      basket_id +
      "&date=" +
      new Date().getTime()
    );
  },

  get_country_url: () => {
    return api.root + "countries/KW/";
  },

  // ORDERS
  get_orders: () => {
    return api.root + "orders/";
  },

  // COMBO APP RELATED API
  product_class_list: () => {
    return api.root + "combo/product/class/";
  },
  product_duplicate_list: () => {
    return api.root + "combo/product/duplicate/"; // only for authenticated users
  },
  product_create_other: () => {
    return api.root + "combo/create/product/other/";
  },
  product_create: () => {
    return api.root + "combo/create/product/";
  },
  product_create_images: () => {
    return api.root + "combo/create/product/image/";
  },
  get_user_details: () => {
    return api.root + "accounts/user/";
  },
  // REVIEW CHECKOUT
  create_bid_history: () => {
    return api.root + "auction/create/bid/";
  },
  // CMS PAGE URL
  get_cms_page: () => {
    return api.root + "page/detail/"; // this expect the code of the page Ex. home
  },

  // CONTACT SEND  MESSAGE
  contact_send_message: () => {
    return api.root + "contact/send/message/";
  },

  // SUBSCRIBE EMAIL
  subscribe_email: () => {
    return api.root + "contact/email/";
  },

  // TIME WINDOW
  time_window: () => {
    return api.root + "time/list/";
  },

  // Counter offer
  counter_offer_list: () => {
    return api.root + "auction/counter/active/list/";
  },

  // auction notification count under profile modal
  auction_notification_count: () => {
    return api.root + "auction/notification/count/";
  },

  // Req offer
  req_product_post: () => {
    return api.root + "combo/create/instant/cash/";
  },

  // instant_cash_list offer
  instant_cash_list: () => {
    return api.root + "combo/instant/cash/list/";
  },
  // Counter offer
  instant_cash_available: () => {
    return api.root + "combo/instant/cash/for/seller/";
  },
  // Counter offer
  instant_item_delete: () => {
    return api.root + "baskets/instant/cash/line/delete/";
  },

  // web push notification
  web_push_url: () => {
    return api.root + "notification/register/push/web/";
  },

  // For Promo code (Voucher)
  addVoucher: () => {
    return api.root + "basket/add-voucher/";
  },
  //business account
  business_account: () => {
    return api.root + "accounts/create/business/tap/";
  },

  //remove Voucher
  removeVoucher: () => {
    return api.root + "basket/remove-voucher/";
  },

  // HEADERS
  headers: () => {
    let language = store.getState().globalReducer.language;
    let header_items = {
      "Content-Type": "application/json",
      "Accept-Language": language
    };
    const account_token = store.getState().accountReducer.account_token;
    if (account_token !== null) {
      header_items["Authorization"] = "JWT " + account_token;
    }
    return header_items;
  },

  // METHODS
  getFunction(url) {
    console.log(url,"urlll")
    return axios({
      method: "get",
      url: url,
      headers: api.headers()
    });
  },

  postFunction(url, data = {}, file = false, includeToken = true) {
    let headers = api.headers(includeToken);
    if (file) {
      headers["Content-Type"] = "multipart/form-data";
    }
    return axios({
      method: "post",
      url: url,
      headers: headers,
      data: data
    });
  },

  putFunction(url, data = {}, file = false, includeToken = true) {
    let headers = api.headers(includeToken);
    if (file) {
      headers["Content-Type"] = "multipart/form-data";
    }
    return axios({
      method: "put",
      url: url,
      headers: headers,
      data: data
    });
  },

  deleteFunction(url) {
    return axios({
      method: "delete",
      url: url,
      headers: api.headers()
    });
  },

  patchFunction(url, data = {}, file = false) {
    let headers = api.headers();
    if (file) {
      headers["Content-Type"] = "multipart/form-data";
    }
    return axios({
      method: "patch",
      url: url,
      headers: headers,
      data: data
    });
  },

  multipleCall(axios_list = []) {
    return axios.all(axios_list);
  }
};

export default api;
