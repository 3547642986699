import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// actions
import { showModal, hideModal } from "actions/modalActions.jsx";
import globalActionTypes from "constants/globalConstants.jsx";
import FlagSelect from "kayfik/components/FlagSelect/FlagSelect.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import IconButton from "@material-ui/core/IconButton";


class ContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      phone: "",
      stateDialCode: this.props.defaultCountryCode
    };
  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    this.props.showmodal(
      {
        open: true,
        title: modal_type,
        modalLarge: modal_type === "whatSellingModal" ? true : false
      },

      modal_type
    );
  };

  handleSubmit = e => {
    const { sendMessageRequest } = this.props;
    const { phone } = this.state;
    e.preventDefault();
    let message_data = {
      name: e.target.name.value,
      email: e.target.email.value,
      number: phone,
      message: e.target.message.value
    };
    sendMessageRequest(message_data);
  };

  handleOnChange = (value, data) => {
    const { stateDialCode } = this.state;
    let code = "+" + data.dialCode;
    this.setState({ stateDialCode: data.dialCode });
    if (value.indexOf(code) === 0) {
      this.setState({ phone: value });
    } else if (data.dialCode !== stateDialCode) {
      this.setState({ phone: code });
    } else {
      this.setState({ phone: this.state.phone });
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, t, direction, global_fetching } = this.props;
    if (global_fetching) {
      return <Loader />;
    }
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <form onSubmit={this.handleSubmit} autoComplete="off">
              <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
                <GridItem xs={12} sm={12} md={12}>
                  <h2 className="m-0 mb-3 htPrimaryGreen">
                    {t("homePage.footer.contactModal.title")}
                  </h2>
                  <h5 className="m-0 mb-3 pb-4">
                    {t("homePage.footer.contactModal.subtitle")}
                  </h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t("homePage.footer.contactModal.name")}
                    id="id_name"
                    size="lg"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "name",
                      required: true,
                      disableUnderline: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t("homePage.footer.contactModal.email")}
                    id="id_email"
                    size="lg"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "email",
                      type: "email",
                      required: true,
                      disableUnderline: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className="dp-f alignItem">
                  <FlagSelect
                    value={this.state.phone}
                    handleFlagOnChange={this.handleOnChange}
                    required={true}
                  />
                  <CustomInput
                    labelText={t("homePage.footer.contactModal.phone")}
                    id="id_phone"
                    size="lg"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "phone",
                      required: true,
                      type: "number",
                      disabled: true,
                      disableUnderline: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t("homePage.footer.contactModal.message")}
                    id="id_message"
                    size="lg"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "message",
                      required: true,
                      type: "text",
                      rows: 3,
                      multiline: true,
                      disableUnderline: true
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={9}
                  md={9}
                  className={classNames(classes.marginAuto, "mt-4")}
                >
                  <Button
                    type="submit"
                    color="primaryWhiteWithBorder"
                    size="lg"
                    className="mb-3"
                    fullWidth
                  >
                    {t("homePage.footer.contactModal.send")}
                  </Button>
                </GridItem>
              </GridItem>
            </form>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  hidemodal: () => dispatch(hideModal()),
  showmodal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
  sendMessageRequest: message_data => {
    dispatch({
      type: globalActionTypes.POST_SEND_MESSAGE_REQUEST,
      message_data
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ContactModal);
