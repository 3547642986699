import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import classNames from "classnames";
// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";
import customAccordionStyle from "kayfik/assets/jss/components/Accordion/customAccordionStyle.jsx";
// Grid item
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      single: false,
      showAll: false,
      expandedPanels: []
    };
  }

  componentWillMount() {
    if (this.state.active.length === undefined) {
      this.setState({
        active: [this.state.active],
        single: false
      });
    }
  }

  handleChange = panel => (event, expanded) => {
    let newArray;
    var newExpanded = this.state.expandedPanels;
    newExpanded.push(panel);
    if (this.state.single) {
      if (this.state.active[0] === panel) {
        newArray = [];
      } else {
        newArray = [panel];
      }
    } else {
      if (this.state.active.indexOf(panel) === -1) {
        newArray = [...this.state.active, panel];
      } else {
        newArray = [...this.state.active];
        newArray.splice(this.state.active.indexOf(panel), 1);
      }
    }
    this.setState({
      active: newArray
    });
  };
  renderRowItem = () => {
    const { classes, items } = this.props;
    return (
      <GridContainer justify="space-between" alignItems="center">
        {items && items.map((item, i) => {
          return (
            <GridItem key={i} className={classes.widthAuto}>
              <CardBody plain>
                <Card plain className="m-0">
                  <CardAvatar profile plain className="mb-3">
                    <img src={item.img} alt="..." />
                  </CardAvatar>
                  <h4
                    className={classNames(
                      classes.cardCategory,
                      classes.whiteFont + " text-center"
                    )}
                  >
                    {item.title}
                  </h4>
                </Card>
              </CardBody>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  };
  render() {
    const { classes, collapses, activeColor, color } = this.props;
    return (
      <div className={classNames(classes.root)}>
        {collapses && collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded={
                this.state.active === key ||
                this.state.active.indexOf(key) !== -1
              }
              onChange={this.handleChange(key)} //this.handleChange(key)
              key={key}
              classes={{
                root: classNames(
                  classes.expansionPanel,
                  classes.container,
                  color === "odd" ? classes.oddRow : classes.evenRow
                ),
                expanded: classes.expansionPanelExpanded
              }}
            >
              <ExpansionPanelSummary
                classes={{
                  root: `${classNames(
                    classes.expansionPanelSummary,
                    classes.inline,
                    classes.width100
                  )} ${classes[activeColor + "ExpansionPanelSummary"]}`,
                  expanded: `${classes.expansionPanelSummaryExpaned} ${
                    classes[activeColor + "ExpansionPanelSummaryExpaned"]
                  }`,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classNames(
                    classes.expansionPanelSummaryExpandIcon,
                    classes.accordionIcon
                  )
                }}
              >
                <div className="d-flex justify-content-between align-items-center text_alignAR">
                  <h2 className="accordian_title m-0">{prop.title}</h2>
                  <ExpandMore
                    className={classNames(
                      classes.expansionPanelSummaryExpandIcon,
                      classes.accordionIcon
                    )}
                  />
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                className={classNames(classes.expansionPanelDetails)}
              >
                <GridContainer justify="center">
                  <GridItem xs={12} md={11}>
                    {this.renderRowItem()}
                  </GridItem>
                </GridContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

Accordion.defaultProps = {
  active: -1,
  activeColor: "primary"
};

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active collapse
  active: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired,
  activeColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ])
};

export default withStyles(customAccordionStyle)(Accordion);
