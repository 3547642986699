import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  GET_PRODUCT_LIST_REQUEST: null,
  GET_PRODUCT_LIST_SUCCESS: null,
  GET_PRODUCT_LIST_FAILED: null,

  GET_INITIAL_FACET_REQUEST: null,
  GET_INITIAL_FACET_SUCCESS: null,
  GET_INITIAL_FACET_FAILED: null,

  GET_PRODUCT_DETAIL_REQUEST: null,
  GET_PRODUCT_DETAIL_SUCCESS: null,
  GET_PRODUCT_DETAIL_FAILED: null,

  UPDATE_SEARCH_URL: null,

  UPDATE_QUICKADD: null,
  UPDATE_ACTIVE_CATEGORY: null,
  UPDATE_ACTIVE_PARENT_CATEGORY: null,
  UPDATE_ACTIVE_ATTRIBUTE: null,
  UPDATE_TOP_CATEGORY: null,

  RESET_OBJECTS: null,
  RESET_SEARCH_PARAMS: null,
  UPDATE_ACTIVE_PRICE_FACET: null,
  UPDATE_MOBILE_FACET_URL: null,
  UPDATE_CUSTOM_PRICE: null,
  RESET_PRICE_ATTS: null,
  UPDATE_ACTIVE_SORT: null,
  ACTIVE_ATTRIBUTE_UPDATE: null,

  SHOW_PRODUCTS_LOADER: null,

  FETCHING_MORE_PRODUCTS: null,
  LISTING_PROD_DETAIL: null,

  UPDATE_LOADER_HORIZONTAL: null
});

export default ActionTypes;
