import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import GridContainer from "components/Grid/GridContainer.jsx";
// redux
import { connect } from "react-redux";
// core component
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import Facets from "kayfik/views/Shop/Sections/Facets.jsx";
import IconButton from "@material-ui/core/IconButton";

class FilterModal extends React.Component {

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal,direction } = this.props;
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classNames(classes.modalHeader,"separator bg-dark-grey color-dark fsize-20 py-4")}
        >
          <i className="fa fa-sliders-h mr-2 color-dark"></i>  Filter 
          <IconButton
            simple="true"
            className={classNames(classes.modalCloseButton,"previewClose",direction==="rtl"?classes.modalSmallCloseButtonAR:null)}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classNames(classes.modalBody,"pt-0")}
        >
          <GridContainer className="mb-5" justify="center">
             <Facets/>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FilterModal);
