import React from "react";
import classNames from "classnames";

//material ui comp
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

class CompanyAccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bidNow: false,
      buyNow: true
    };
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="main-background-color marginAuto pt-3">
        <h4 className={classNames(" my-0 fw-400")}>Account Settings</h4>
        <GridContainer
          className={classNames("m-0", "modalScroll", "scrollbar")}
        >
          <Card className="mb-3">
            <CardBody className="cardHeight">
              <GridContainer>
                <GridItem xs={12}>
                  <span className="fw-500 mr-1">Banking Information</span>{" "}
                  <span className={classNames("separator mx-2")}>|</span>{" "}
                  <span className="color-pl">Edit</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>Account ending in 3342</GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className="cardHeight">
              <GridContainer>
                <GridItem xs={12}>
                  <span className="fw-500 mr-1">Billing Address</span>{" "}
                  <span className={classNames("separator mx-2")}>|</span>{" "}
                  <span className="color-pl">Edit</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>First Name LAst Name</GridItem>
                <GridItem xs={12}>Address</GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className="cardHeight">
              <GridContainer>
                <GridItem xs={12}>
                  <span className="fw-500 mr-1">Language </span>{" "}
                  <span className={classNames("separator mx-2")}>|</span>{" "}
                  <span className="color-pl">Edit</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>Visa ending 3342</GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className="cardHeight">
              <GridContainer>
                <GridItem xs={12}>
                  <span className="fw-500 mr-1">Email</span>{" "}
                  <span className={classNames("separator mx-2")}>|</span>{" "}
                  <span className="color-pl">Edit</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>Visa ending 3342</GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className="cardHeight">
              <GridContainer>
                <GridItem xs={12}>
                  <span className="fw-500 mr-1">Password</span>{" "}
                  <span className={classNames("separator mx-2")}>|</span>{" "}
                  <span className="color-pl">Edit</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>Visa ending 3342</GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className="cardHeight">
              <GridContainer>
                <GridItem xs={12}>
                  <span className="fw-500 mr-1">Push Notifications</span>{" "}
                  <span className={classNames("separator mx-2")}>|</span>{" "}
                  <span className="color-pl">Edit</span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} className="dp-f align-items-center">
                  Allow push notifications
                </GridItem>
                <GridItem xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.buyNow}
                        onChange={this.handleChange("buyNow")}
                        value="buyNow"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    labelPlacement="start"
                    classes={{
                      label: classes.label
                    }}
                  />
                </GridItem>
                <GridItem xs={6} className="dp-f align-items-center">
                  Allow push notifications
                </GridItem>
                <GridItem xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.bidNow}
                        onChange={this.handleChange("buyNow")}
                        value="buyNow"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    labelPlacement="start"
                    classes={{
                      label: classes.label
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(modalStyle)(CompanyAccountSettings);
