import { call, put } from "redux-saga/effects";

import i18n from "locales/i18n.jsx";
import ActionTypes from "constants/addressConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import {
  postAddress,
  getAddress,
  updateAddress,
  getAreaList
} from "actions/addressActions.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import { resetUnauthorized } from "utils/helpers.jsx";
import * as Sentry from '@sentry/browser';

function* postUserAddress(action) {
  let { payload, page } = action;
  try {
    let response = yield call(postAddress, payload);
    let data = response.data;
    yield put({ type: ActionTypes.ADD_ADDRESS_SUCCESS, data });
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    yield put({ type: ActionTypes.GET_ADDRESS_REQUEST });
    let address = null;
    yield put({ type: ActionTypes.UPDATE_MAP_ADDRESS, address });

    // update review checkout if from checkout page
    if (page === "checkout") {
      yield put({ type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
    }

    // show a notification
    let txt = i18n.t(
      "profileModal.accountSettings.shippingAddress.addedsuccess"
    );
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let catched_errors = [400, 403, 401, 406];
    if (e.response && catched_errors.includes(e.response.status)) {
      if (e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];

        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.ADD_ADDRESS_FAILED, e });
  }
}

function* updateUserAddressList(action) {
  const { payload, page } = action;
  try {
    let response = yield call(updateAddress, payload);
    let data = response.data;
    yield put({ type: ActionTypes.UPDATE_ADDRESS_SUCCESS, data });
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    yield put({ type: ActionTypes.GET_ADDRESS_REQUEST });

    // update review checkout if from checkout page
    if (page === "checkout") {
      yield put({ type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
    }

    // show a notification
    let txt = i18n.t(
      "profileModal.accountSettings.shippingAddress.updatesuccess"
    );
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];

        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.UPDATE_ADDRESS_FAILED, error });
  }
}

function* getUserAddressList() {
  try {
    let response = yield call(getAddress);
    let data = response.data.results;
    yield put({ type: ActionTypes.GET_ADDRESS_SUCCESS, data });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.GET_ADDRESS_FAILED, error });
  }
}

function* getAllowedAreaList() {
  try {
    let response = yield call(getAreaList);
    let data = response.data;
    yield put({ type: ActionTypes.GET_AREA_LIST_SUCCESS, data });
  } catch (e) {
    Sentry.captureException(e);
    let error = e;
    let txt = i18n.t("common.error.network");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
    yield put({ type: ActionTypes.GET_AREA_LIST_FAILED, error });
  }
}

export {
  postUserAddress,
  getUserAddressList,
  updateUserAddressList,
  getAllowedAreaList
};
