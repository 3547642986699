import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import AddressActionTypes from "constants/addressConstants.jsx";

const initialState = {
  address_fetching: false,
  address_error: null,
  user_current_location: null,
  user_address_object_map: null,
  add_address: null,
  guest_address: null,
  company_address: null,
  address_list: [],
  map_address: null,
  checkout_firstName: null,
  checkout_lastName: null,
  dummy: null,
  area_list: [],
  activeArea: {
    area: null,
    area_id: null
  },
  mapLoader: false,
  mapDragged: false,
  add_address_event: "anonymous" // this is the identifier
  
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case AddressActionTypes.ADD_ADDRESS_REQUEST:
      return { ...state, address_error: null, address_fetching: true };
    case AddressActionTypes.ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        address_fetching: false,
        address_error: null,
        add_address: action.payload
      };
    case AddressActionTypes.ADD_ADDRESS_FAILED:
      return { ...state, address_fetching: false, address_error: action.error };

    case AddressActionTypes.GET_ADDRESS_REQUEST:
      return { ...state, address_error: null, address_fetching: true };
    case AddressActionTypes.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        address_fetching: false,
        address_error: null,
        address_list: action.data
      };
    case AddressActionTypes.GET_ADDRESS_FAILED:
      return { ...state, address_fetching: false, address_error: action.error };
    // AREA LIST
    case AddressActionTypes.GET_AREA_LIST_REQUEST:
      return { ...state, address_error: null, address_fetching: true };
    case AddressActionTypes.GET_AREA_LIST_SUCCESS:
      return {
        ...state,
        address_fetching: false,
        address_error: null,
        area_list: action.data
      };
    case AddressActionTypes.GET_AREA_LIST_FAILED:
      return { ...state, address_fetching: false, address_error: action.error };

    case AddressActionTypes.UPDATE_ADDRESS_REQUEST:
      return { ...state, address_error: null, address_fetching: true };
    case AddressActionTypes.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        address_fetching: false,
        address_error: null,
        address_list: action.data
      };
    case AddressActionTypes.UPDATE_ADDRESS_FAILED:
      return { ...state, address_fetching: false, address_error: action.error };

    case AddressActionTypes.UPDATE_ACTIVE_AREA:
      return {
        ...state,
        activeArea: action.activeArea
      };

    case AddressActionTypes.SET_GUEST_ADDRESS:
      return {
        ...state,
        guest_address: action.payload
      };

    case AddressActionTypes.SET_COMPANY_ADDRESS:
      return {
        ...state,
        company_address: action.data
      };

    case AddressActionTypes.USER_CURRENT_LOCATION:
      return {
        ...state,
        address_fetching: false,
        user_current_location: action.payload
      };

    case AddressActionTypes.UPDATE_USER_ADDRESS_MAP:
      return {
        ...state,
        address_fetching: false,
        user_address_object_map: action.payload
      };

    // update add_address_event whos user
    case AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT:
      return {
        ...state,
        add_address_event: action.event
      };

    // Update map_address
    case AddressActionTypes.UPDATE_MAP_ADDRESS:
      return {
        ...state,
        map_address: action.address
      };
    // // Update map coordinates
    case AddressActionTypes.UPDATE_MAP_LOADER:
      return {
        ...state,
        mapLoader: action.mapLoader
      };
    // // Update map coordinates
    case AddressActionTypes.UPDATE_MAP_DRAGGED:
      return {
        ...state,
        mapDragged: action.mapDragged
      };
    // Update full name
    case AddressActionTypes.UPDATE_FULL_NAME:
      return {
        ...state,
        checkout_firstName: action.first_name,
        checkout_lastName: action.last_name
      };

    case AddressActionTypes.ADDRESS_RESET:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  key: "address",
  storage: storage,
  whitelist: ["dummy", "company_address", ]
};

export default persistReducer(persistConfig, accountReducer);
