/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// redux
import { connect } from "react-redux";
// @material-ui/icons
import PlayArrow from "@material-ui/icons/PlayArrow";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// React i18next
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Card from "kayfik/components/Card/CardNoBorder.jsx";
import gridSectionStyle from "kayfik/assets/jss/views/LandingPage/Sections/gridSectionStyle.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";

class SectionGrid extends React.Component {


  openModal = (modal_type, video_url) => async () => {
    const { showModal, closeModal, modalProps } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    const video_modalProps = {
      open: true,
      url: video_url 
    };

    showModal(
      video_modalProps,
      modal_type
    );
  };

  renderSectionGrid = (images) => {
    const { classes } = this.props;
    if (images.length > 0) {
      return images.map((item, i) => {
      
        return (
          <GridItem key={i} xs={12} sm={6} md={6} className="p-0">
          {i === 0 ?
            <Card
              background
              style={item.img ?{ backgroundImage: `url(${item.img})` }: null}
              className={classNames(classes.container, "m-0 br-0")}
            >
              <CardBody
                className={classes.center}
                background
              >
                <Button
                  round
                  justIcon
                  color="htPrimaryGreen"
                  size="lg"
                  className={classes.bigRoundButton}
                  onClick={this.openModal("VideoModal", item.link)}
                >
                  <PlayArrow className="sectionGridArrow" />
                </Button>
              </CardBody> 
                {item.title ?
                  <h2 className={classNames(classes.title, "htPrimaryGreen plLanding ml-2 mx-3")}>
                    {item.title}</h2>
                  : null}
            </Card> :
              <Link to={"/shop"}>
                <Card
                  background
                  style={item.img ? { backgroundImage: `url(${item.img})` } : null}
                  className={classNames(classes.container, "m-0 br-0")}
                >
                  {item.title ?
                    <h2 className={classNames(classes.title, "htPrimaryGreen plLanding ml-2 mx-3")}>
                      {item.title}</h2>
                    : null}
                </Card>
             </Link>           
          }
          </GridItem>
        );
      });
    }
  };

  render() {
    const { cms_data,classes } = this.props;
    if (cms_data.home) {
      let sections = cms_data.home.sections;
      let grid = sections.find(sec => sec.slug === "grid");
      if (grid) {
        return (
          <div className="main-background-color">
            <div className={classes.container}>
              <GridContainer>
                {this.renderSectionGrid(grid.images)}
              </GridContainer>
            </div>
          </div>
        );
      }
      return null;

    }
    return null;
  }

}

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
});

export default compose(
  withStyles(gridSectionStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(SectionGrid);

