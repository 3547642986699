import React from "react";
import compose from "recompose/compose";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
// @ core components
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import { default as modalTypes } from "kayfik/components/Modal//modalTypes.jsx";

export const MODAL_TYPES = {
  initialLoginModal: modalTypes.initialLoginModal,
  loginModal: modalTypes.loginModal,
  accountTypeModal: modalTypes.accountTypeModal,
  personalSignupModal: modalTypes.personalSignupModal,
  makeBidModal: modalTypes.makeBidModal,
  bidReceivedModal: modalTypes.bidReceivedModal,
  whatSellingModal: modalTypes.whatSellingModal,
  imagesModal: modalTypes.imagesModal,
  productPreviewModal: modalTypes.productPreviewModal,
  listingPostedModal: modalTypes.listingPostedModal,
  otherListingPostedModal: modalTypes.otherListingPostedModal,
  createdAccount: modalTypes.createdAccount,
  profileModal: modalTypes.profileModal,
  companyProfileModal: modalTypes.companyProfileModal,
  quickAddModal: modalTypes.quickAddModal,
  mapModal: modalTypes.mapModal,
  confirmAddress: modalTypes.confirmAddress,
  contactModal: modalTypes.contactModal,
  filterModal: modalTypes.filterModal,
  requestProductModal: modalTypes.requestProductModal,
  addtocartModal: modalTypes.addtocartModal,
  completeOrderModal: modalTypes.completeOrderModal,
  forgotPassword: modalTypes.forgotPassword,
  editAccountDetailsModal: modalTypes.editAccountDetailsModal,
  changePassword: modalTypes.changePassword,
  replacementProduct: modalTypes.replacementProduct,
  confirmEmailModal: modalTypes.confirmEmailModal,
  verifyEmailModal: modalTypes.verifyEmailModal,
  verifyPhoneModal: modalTypes.verifyPhoneModal,
  VideoModal: modalTypes.VideoModal,
  getIbanModal: modalTypes.getIbanModal,
  passwordResetModal: modalTypes.passwordResetModal,
  instantCashPostedModal: modalTypes.instantCashPostedModal,  
  iCLineDeleteModal: modalTypes.iCLineDeleteModal,
  civilIdModal: modalTypes.civilIdModal
};

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        modalIsOpen: nextProps.modalProps.open
      });
    }
  }

  closeModal = () => {
    const { hideModal } = this.props;
    this.setState({ modalIsOpen: false }, () => {
      hideModal();
    });
  };

  render() {
    if (!this.props.modalType) {
      return null;
    }
    const { classes, modalType } = this.props;
    const SpecifiedModal = MODAL_TYPES[modalType];

    var paper_classes = classes.modal + " " + classes.modalMedium;
    let scrollPaperClasses = classes.scrollPaper + " scrollPaperClass ";
    let large_modals = [
      "whatSellingModal",
      "profileModal",
      "VideoModal",
      "productPreviewModal"
    ];
    let overflowModal = ["whatSellingModal"];
    let noScrollModal = [""];
    if (large_modals.includes(modalType)) {
      paper_classes = paper_classes + " " + classes.modalLarger;
    }
    if (overflowModal.includes(modalType)) {
      paper_classes = paper_classes + " " + classes.overflowList;
    }

    if (this.props.modalProps.modalLarge) {
      paper_classes = paper_classes + " " + classes.modalLarger;
    }

    if (noScrollModal.includes(modalType)) {
      scrollPaperClasses = scrollPaperClasses + " " + classes.noScrollModal;
      paper_classes = paper_classes + " " + classes.noScrollModal;
    }

    return (
      <Dialog
        id="modalRootId"
        classes={{
          root: classNames(classes.modalRoot, "modalCs "),
          paper: paper_classes,
          container: classNames(classes.modalContainer, "scrollbar"),
          scrollPaper: scrollPaperClasses
        }}
        BackdropProps={{
          classes: {
            root: classes.backdropRoot
          }
        }}
        scroll={"paper"}
        open={this.state.modalIsOpen}
        onClose={this.closeModal}
      >
        <SpecifiedModal
          closeModal={this.closeModal}
          {...this.props.modalProps}
        />
      </Dialog>
    );
  }
}

ModalContainer.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
  classes: PropTypes.object,
  hideModal: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => {
    dispatch({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(ModalContainer);
