import ActionTypes from "constants/cartConstants.jsx";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  cart_fetching: false,
  showCart: false,
  checkoutAddress: null,
  checkoutPayment: "knet",
  forceStep: 0,
  add_to_cart: null,
  delete_item: null,
  update_cart: null,
  review_checkout: null,
  orders_list: null,
  orderDetailshow: false,
  post_checkout: null,
  post_payment: null,
  order_details: null,
  reqNumber: 0,
  dummy: null,
  order_list_detail: null,
  post_checkout_req_sent: false,
  delivery_time: null,
  basket_id: null,
  voucher_success: ""
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_CART_DROPDOWN:
      return { ...state, showCart: action.show };

    case ActionTypes.UPDATE_CHECKOUT_ADDRESS:
      return { ...state, checkoutAddress: action.data };

    case ActionTypes.UPDATE_CHECKOUT_PAYMENT:
      return { ...state, checkoutPayment: action.data };

    case ActionTypes.UPDATE_FORCE_STEP:
      return { ...state, forceStep: action.data };

    //ADD_TO_CART
    case ActionTypes.ADD_TO_CART_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false,
        add_to_cart: null
      };

    case ActionTypes.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        cart_fetching: false,
        has_error: false,
        error: false,
        add_to_cart: action.data
      };

    case ActionTypes.ADD_TO_CART_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error,
        add_to_cart: null
      };

    //UPDATE_CART_REQUEST
    case ActionTypes.UPDATE_CART_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false,
        update_cart: null
      };

    case ActionTypes.UPDATE_CART_SUCCESS:
      return {
        ...state,
        cart_fetching: false,
        has_error: false,
        error: false,
        update_cart: action.data
      };

    case ActionTypes.UPDATE_CART_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error,
        update_cart: null
      };

    //DELETE_ITEM
    case ActionTypes.DELETE_ITEM_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false,
        delete_item: null
      };

    case ActionTypes.DELETE_ITEM_SUCCESS:
      return {
        ...state,
        cart_fetching: false,
        has_error: false,
        error: false,
        delete_item: action.data
      };

    case ActionTypes.DELETE_ITEM_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error,
        delete_item: null
      };

    //REVIEW CHECKOUT
    case ActionTypes.GET_REVIEW_CHECKOUT_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false
      };

    case ActionTypes.GET_REVIEW_CHECKOUT_SUCCESS:
      return {
        ...state,
        cart_fetching: false,
        has_error: false,
        error: false,
        review_checkout: action.data
      };

    case ActionTypes.GET_REVIEW_CHECKOUT_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error,
        // review_checkout: null
      };

    // POST CHECKOUT
    case ActionTypes.POST_CHECKOUT_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false,
        post_checkout_req_sent: true
      };

    case ActionTypes.POST_CHECKOUT_SUCCESS:
      return {
        ...state,
        has_error: false,
        error: false,
        post_checkout: action.data,
        review_checkout: null,
        post_checkout_req_sent: false
      };

    case ActionTypes.POST_CHECKOUT_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error,
        post_checkout_req_sent: false
      };

    // POST PAYMENT URL
    case ActionTypes.POST_PAYMENT_URL_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false
      };

    case ActionTypes.POST_PAYMENT_URL_SUCCESS:
      return {
        ...state,
        has_error: false,
        error: false,
        post_payment: action.data,
        review_checkout: null
      };

    case ActionTypes.POST_PAYMENT_URL_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error
      };

    //ORDERS
    case ActionTypes.GET_ORDERS_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false
      };

    case ActionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        cart_fetching: false,
        has_error: false,
        error: false,
        orders_list: action.data
      };

    case ActionTypes.GET_ORDERS_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error
      };

    case ActionTypes.UPDATE_ORDER_LIST_DETAIL:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: false,
        order_list_detail: action.data
      };

    //ORDER DETAILS
    case ActionTypes.GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false
      };

    case ActionTypes.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        cart_fetching: false,
        has_error: false,
        error: false,
        order_details: action.data
      };

    case ActionTypes.GET_ORDER_DETAILS_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error
      };

    //ORDER LIST variable
    case ActionTypes.UPDATE_ORDER_DETAIL_VARIABLE:
      return { ...state, orderDetailshow: action.payload };

    //ORDER DETAIL REQuest number
    case ActionTypes.UPDATE_ORDERS_DETAILS_REQUEST_NUMBER:
      return { ...state, reqNumber: action.reqNumber };

    //RESET
    case ActionTypes.CART_RESET:
      return initialState;

    //UPDATE DELIVERY TIME
    case ActionTypes.UPDATE_DELIVERY_TIME:
      return { ...state, delivery_time: action.data };

    case ActionTypes.UPDATE_BASKET_ID_SUCCESS:
      return { ...state, basket_id: action.data };

    // POST VOUCHER
    case ActionTypes.POST_VOUCHER_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false
      };

    case ActionTypes.POST_VOUCHER_SUCCESS:
      return {
        ...state,
        voucher_success: action.data,
        cart_fetching: false,
        has_error: false,
        error: false
      };

    case ActionTypes.POST_VOUCHER_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error
      };
    case ActionTypes.UPDATE_VOUCHER_CODE:
      return {
        ...state,
        voucher_success: action.data,
        cart_fetching: false,
        has_error: false,
        error: false
      };

    // POST VOUCHER
    case ActionTypes.REMOVE_VOUCHER_REQUEST:
      return {
        ...state,
        cart_fetching: true,
        has_error: false,
        error: false
      };

    case ActionTypes.REMOVE_VOUCHER_SUCCESS:
      return {
        ...state,
        cart_fetching: false,
        has_error: false,
        error: false
      };

    case ActionTypes.REMOVE_VOUCHER_FAILED:
      return {
        ...state,
        cart_fetching: false,
        has_error: true,
        error: action.error
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: "cart",
  storage: storage,
  // blacklist: ["forceStep", "reqNumber"]
  whitelist: ["dummy", "review_checkout","delivery_time","checkoutPayment" ,"basket_id"]
};
export default persistReducer(persistConfig, cartReducer);
