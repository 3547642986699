import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Link from "@material-ui/core/Link";
import { store } from "utils/store.jsx";
import classNames from "classnames";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton
} from "react-share";
import CustomDropdownStyles from "kayfik/assets/jss/components/CustomDropdown/CustomDropdownStyles.jsx";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

class SharePopOver extends React.Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
      open: !this.state.open
    });
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({
      anchorEl: null,
      open: false
    });
  };
  getShareUrl = item => {
    let protocol = window.location.protocol;
    let host = window.location.hostname;
    let port = window.location.port;
    let url = protocol + "//" + host;
    if (port) {
      url = url + ":" + port;
    }
    url = url + "/shop" + item.product_uri;
    return url;
  };

  renderSocialIcons = () => {
    const { product } = this.props;
    let shareUrl = this.getShareUrl(product);
    let quote = product.title;
    return (
      <div className="social-media-list">
        <Button justIcon color="facebook" simple>
          <FacebookShareButton url={shareUrl} quote={quote} className="button">
            <i className="fab fa-facebook-square" />
          </FacebookShareButton>
        </Button>
        <Button justIcon color="whatsapp" simple>
          <WhatsappShareButton url={shareUrl} quote={quote} className="button">
            <i className="fab fa-whatsapp" />
          </WhatsappShareButton>
        </Button>
        <Button justIcon color="twitter" simple>
          <TwitterShareButton url={shareUrl} quote={quote} className="button">
            <i className="fab fa-twitter" />
          </TwitterShareButton>
        </Button>
        <Button justIcon color="twitter" simple>
          <LinkedinShareButton url={shareUrl} quote={quote} className="button">
            <i className="fab fa-linkedin" />
          </LinkedinShareButton>
        </Button>
      </div>
    );
  };

  render() {
    const {
      action_title,
      is_button,
      classes,
      dropPlacement,
      navDropdown,
      dropup,
      noPosition,
      innerDropDown
    } = this.props;
    const { open } = this.state;
    const shareList = this.renderSocialIcons();

    return (
      <div className={is_button ? "w-100" : null}>
        {is_button ? (
          <Button
            color="primaryWhiteWithBorder"
            size="lg"
            aria-owns={open ? "simple-popper" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
            fullWidth
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            {action_title}
          </Button>
        ) : (
          <Link
            underline="none"
            component="button"
            aria-owns={open ? "simple-popper" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <span
              className="pointer"
              ref={node => {
                this.anchorEl = node;
              }}
            >
              {" "}
              {action_title}{" "}
            </span>
          </Link>
        )}
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [classes.pooperResponsive]: true,
            [classes.positionAr]:
              store.getState().globalReducer.direction === "rtl" ? true : false,
            [classes.position]:
              store.getState().globalReducer.direction === "ltr" && !noPosition
                ? true
                : false,
            [classes.pooperNav]: open && navDropdown
          })}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: "0 100% 0" }
                  : { transformOrigin: "0 0 0" }
              }
            >
              <Paper className={classNames(classes.dropdown, classes.share)}>
                {innerDropDown ? (
                  shareList
                ) : (
                  <ClickAwayListener onClickAway={this.handleClose} ref="cacat">
                    {shareList}
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

SharePopOver.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  navDropdown: PropTypes.bool,
  dropup: PropTypes.bool,
  rtlActive: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    "bottom",
    "top",
    "right",
    "left",
    "bottom-start",
    "bottom-end",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "left-start",
    "left-end"
  ])
};
export default withStyles(CustomDropdownStyles)(SharePopOver);
