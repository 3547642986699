import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
// eslint-disable-next-line react/prop-types
import ModalActionTypes from "constants/modalConstants.jsx";
import IconButton from "@material-ui/core/IconButton";
import { getUserDetails } from "actions/accountActions.jsx";
import { showSnackbar } from "utils/helpers.jsx";

class VerifyEmailModal extends React.Component {
  componentDidMount = () => {
    const { getUserDetailsSaga } = this.props;
    getUserDetailsSaga();
  };
  componentWillUnmount = () => {
    const { updateEmailModalStatus } = this.props;
    updateEmailModalStatus(false);
  };

  continueClicked = () => {
    const {
      getUserDetailsSaga,
      updateEmailModalStatus,
      modalProps,
      t,
      updateAccountPageComponent,
      userDetailsSuccess
    } = this.props;
    if (
      modalProps.verifyFrom === "makeBid" ||
      modalProps.verifyFrom === "reqProd"
    ) {
      getUserDetails().then(res => {
        if(res.data){
          userDetailsSuccess(res.data);
        }
        if (res.data && !res.data.is_email_verified) {
          let txt = t("buyingPage.checkOut.verifyemail.verifySnackbar");
          showSnackbar(txt, "warning");
        } else if (res.data && !res.data.is_phone_verified) {
          let modalType = "verifyPhoneModal";
          let modal_props = {
            open: true,
            title: modalType,
            verifyFrom: modalProps.verifyFrom
          };
          this.openModal(modalType, modal_props)();
        } else if (modalProps.verifyFrom === "makeBid") {
          let modalProps = {
            open: true,
            title: "makeBidModal",
            verifyFrom: "makeBid"
          };
          this.openModal("makeBidModal", modalProps)();
        } else if (modalProps.verifyFrom === "reqProd") {
          let modalProps = {
            open: true,
            title: "profileModal"
          };
          this.openModal("profileModal", modalProps)();
          updateAccountPageComponent("requestProducts");
        }
      });
    } else {
      getUserDetailsSaga();
      updateEmailModalStatus(true);
    }
  };

  openModal = (modal_type, modal_props) => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        ...modal_props
      },
      modal_type
    );
  };

  getTitle = () => {
    const { modalProps, t } = this.props;
    if (modalProps) {
      switch (modalProps.verifyFrom) {
        case "checkout":
          return t("buyingPage.checkOut.verifyemail.checkoutTitle");
        case "makeBid":
          return t("buyingPage.checkOut.verifyemail.makeBidTitle");
        case "reqProd":
          return t("profileModal.accountSettings.reqProdTitle");
        default:
          return t("buyingPage.checkOut.verifyemail.listingTitle");
      }
    }
  }

  getText = () => {
    const { modalProps, t } = this.props;
    if (modalProps) {
      switch (modalProps.verifyFrom) {
        case "checkout":
          return t("buyingPage.checkOut.verifyemail.checkoutText");
        case "makeBid":
          return t("buyingPage.checkOut.verifyemail.makeBidText");
        case "reqProd":
          return t("profileModal.accountSettings.reqProdText");
        default:
          return t("buyingPage.checkOut.verifyemail.listingText");
      }
    }
  };

  getButtonText = () => {
    const { modalProps, t } = this.props;
    if (modalProps) {
      switch (modalProps.verifyFrom) {
        case "checkout":
          return t("buyingPage.checkOut.verifyemail.checkout");
        case "makeBid":
          return t("buyingPage.checkOut.verifyemail.continueBid");
        case "reqProd":
          return t("profileModal.accountSettings.reqProd");
        default:
          return t("buyingPage.checkOut.verifyemail.continue");
      }
    }
  };
  render() {
    const {
      classes,
      closeModal,
      direction,
      resendConfirmation,
      t,
      account_fetching,
      combo_fetching,
      modalProps
    } = this.props;
    // let currentUrl = window.location.href;

    return (
      <div>
        {account_fetching || combo_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {this.getTitle()}
              </h2>
              <h4 className="m-0 mb-3">{this.getText()}</h4>
              <Button
                color="primaryWhiteWithBorder"
                size="lg"
                fullWidth
                onClick={resendConfirmation}
              >
                {t("buyingPage.checkOut.verifyemail.resend")}
              </Button>
              {modalProps.verifyFrom !== "profileModal" && (
                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  fullWidth
                  onClick={this.continueClicked}
                >
                  {this.getButtonText()}
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resendConfirmation: () => {
    dispatch({
      type: AccountActionTypes.RESEND_EMAIL_CONFIRMATION_REQUEST
    });
  },
  getUserDetailsSaga: () => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_REQUEST
    });
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updateEmailModalStatus: emailModalActive => {
    dispatch({
      type: AccountActionTypes.UPDATE_EMAIL_MODAL_STATUS,
      emailModalActive
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  userDetailsSuccess: data => {
    dispatch({
      type: AccountActionTypes.EDIT_USER_DETAIL_SUCCESS,
      data
    });
  },
});

VerifyEmailModal.propTypes = {
  resendConfirmation: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.accountReducer,
  ...state.comboProductReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(VerifyEmailModal);
