import { combineReducers } from "redux";
import modalReducer from "./modalReducer.jsx";
import globalReducer from "./globalReducer.jsx";
import cartReducer from "./cartReducer.jsx";
import addressReducer from "./addressReducer.jsx";
import comboProductReducer from "reducers/comboProductReducer.jsx";
import notifierReducer from "reducers/notifierReducer.jsx";
import accountReducer from "reducers/accountReducer.jsx";
import productReducer from "reducers/productReducer.jsx";
import bidReducer from "reducers/bidReducer.jsx";
import vendorProductReducer from "reducers/vendorProductReducer.jsx";

export default combineReducers({
  globalReducer,
  modalReducer,
  comboProductReducer,
  notifierReducer,
  accountReducer,
  cartReducer,
  productReducer,
  addressReducer,
  bidReducer,
  vendorProductReducer
});
