import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// React i18next
import { withTranslation } from "react-i18next";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Help from "@material-ui/icons/Help";
import InputAdornment from "@material-ui/core/InputAdornment";
import Loader from "kayfik/components/Loader/loader.jsx";
import IconButton from "@material-ui/core/IconButton";

// eslint-disable-next-line react/prop-types
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  handleSubmit = e => {
    const { changePassword, showSnackbar, t } = this.props;
    e.preventDefault();
    let form_data = {
      new_password1: e.target.newPassword1.value,
      new_password2: e.target.newPassword2.value
    };
    if (form_data.new_password1 !== form_data.new_password2) {
      let txt = t("profileModal.accountSettings.changePassword.match");
      showSnackbar(txt, "warning");
    } else {
      changePassword(form_data);
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, t, direction, account_fetching } = this.props;

    return (
      <div>
        {account_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {t("profileModal.accountSettings.changePassword.title")}
              </h2>
              <form className="mt-3" onSubmit={this.handleSubmit}>
                <CustomInput
                  labelText={t(
                    "profileModal.accountSettings.changePassword.new"
                  )}
                  id="id_pass_new_1"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "newPassword1",
                    required: true,
                    type: "password",
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <Tooltip
                          title= {t("personalSignupModal.helpText")}
                          placement="top"
                          enterTouchDelay={0}
                          classes={{
                            tooltip: classNames(
                              classes.tooltip,
                            )
                          }}
                        >
                          <Help className={classes.inputAdornmentIcon} />
                        </Tooltip>{" "}
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText={t(
                    "profileModal.accountSettings.changePassword.retype"
                  )}
                  id="id_pass_new_2"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "newPassword2",
                    required: true,
                    type: "password",
                    disableUnderline: true
                  }}
                />
                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  className="mb-3"
                  fullWidth
                  type="submit"
                >
                  {t("profileModal.accountSettings.changePassword.change")}
                </Button>
              </form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  changePassword: form_data => {
    dispatch({
      type: AccountActionTypes.CHANGE_PASSWORD_REQUEST,
      payload: form_data
    });
  },
  showSnackbar: txt => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(ChangePassword);
