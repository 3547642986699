import React from "react";
//material ui comp
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import Muted from "components/Typography/Muted.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import ModalPagination from "kayfik/components/Pagination/newPage.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import { decimalPlace } from "utils/helpers.jsx";

import {
  postActionNotificationClicked,
  showStatusMessage
} from "utils/helpers.jsx";
import Badge from "components/Badge/Badge.jsx";

class ProductListing extends React.Component {
  showProductBidDetail = url => {
    const {
      getBidDetail,
      updateAccountPageComponent,
      resetLoadMore
    } = this.props;
    getBidDetail(url);
    updateAccountPageComponent("biddetail");
    resetLoadMore(false, null);
  };

  // To updated the list if it comes with email link.
  componentDidMount() {
    const { getPartnerBidProducts } = this.props;
    getPartnerBidProducts();
  }

  openModal = modal_type => async () => {
    const { showModal } = this.props;
    showModal(
      {
        open: true,
        title: modal_type,
        nextModal: "profileModal"
      },
      modal_type
    );
  };

  notAvailableData=()=>{
    const { t } = this.props;
    return(
      <GridContainer className="mt-2" key="notAvailable">
        <p>{t("profileModal.offers.data")}</p>
        <GridItem xs={12} className="mt-2">
          <Button
            color="primaryWhiteWithBorder"
            size="lg"
            className="mb-3"
            fullWidth
            onClick={this.openModal("whatSellingModal")}
          >
            {t("homePage.header.sell")}
          </Button>
        </GridItem>
      </GridContainer>
    );
  };


  calculateCommission = item => {
    let pc = null;
    if (item.product && item.product.product_class) {
      pc = item.product.product_class;
    }
    if (pc) {
      let flat_rate = parseFloat(pc.flat_rate);
      let percent_rate = parseFloat(pc.percentage_rate);

      let float_value = parseFloat(item.bid.bid_amount);
      let partner_value = flat_rate;

      let current_percentage = (percent_rate / 100) * float_value;
      if (current_percentage > flat_rate) {
        partner_value = current_percentage;
      }
      float_value = float_value - partner_value;
      float_value = decimalPlace(float_value);
      return float_value;
    }
  };

  // renderInitialPercentageAmount = item => {
  //   const { direction } = this.props;
  //   let currency = direction === "rtl" ? "د.ك." : "KD";
  //   if (item && item.bid && item.bid.bid_amount) {
  //     let value = this.calculateCommission(item);
  //     return (
  //       <Trans i18nKey="profileModal.ifAcceptedyoureceive">
  //         [
  //         <strong>
  //           {{ currency: currency }} {{ amount: value }}
  //         </strong>
  //         ]
  //       </Trans>
  //     );
  //   }
  // };


  partnerProductBidList = () => {
    const {
      classes,
      partner_product_bid_list,
      showLoader,
      auction_notification_count, language } = this.props;

    let received_offer_item_count =
      auction_notification_count && auction_notification_count.receive_offer
        ? auction_notification_count.receive_offer
        : null;

    if (received_offer_item_count && received_offer_item_count.count > 0) {
      postActionNotificationClicked(received_offer_item_count.url_to_read);
    }
    let dataToReturn = [];
    if (partner_product_bid_list) {
      if (partner_product_bid_list.count > 0) {
        let available_product = partner_product_bid_list.results.filter(
          item => item.product.availability.is_available_to_buy && item.bid
        );
        if (available_product.length > 0) {
          available_product.map((item, i) => {
            let product_status = null;
            if (item && item.product && item.product.product_status) {
              product_status = showStatusMessage(
                item.product.product_status,
                "seller"
              );
            }
            dataToReturn.push(
              <Card
                bordered
                key={i}
                className={
                  product_status && product_status.color
                    ? "pointer listed_item "
                    : "pointer"
                }
                style={
                  product_status && product_status.color
                    ? {
                        borderColor: product_status
                          ? product_status.color
                          : null
                      }
                    : null
                }
                onClick={() => this.showProductBidDetail(item.url)}
                id={
                  i === partner_product_bid_list.results.length - 1
                    ? "lastBid"
                    : ( i === 0?"firstbidprod" : "bidprod" + i)
                }
              >
                <CardBody className="p-0">
                  <div className="d-flex listing-images">
                    <div
                      className={classNames(
                        "d-flex jcc align-items-center",
                        classes.f15
                      )}
                    >
                      <div className={classes.bidListingContainer}>
                        <img
                          src={item.product.image}
                          className={classes.img}
                          alt={item.product.title}
                        />
                      </div>
                    </div>
                    <div className={classes.listDetailContainer + " p-3"}>
                      <h5 className="m-0">{item.product.title}</h5>
                      <h5 className="mb-1">
                        <Muted>
                          {item.product.price.currency} {this.calculateCommission(item)}{" "}
                          {/* {this.renderInitialPercentageAmount(item)} */}
                        </Muted>
                      </h5>
                      {product_status && product_status.message ? (
                        <Badge
                            // color="primary"
                            className={
                              language === "en"
                                ? "dp-f jcfe listing_badge"
                                : "dp-f jcfe listing_badgeAR"
                            }
                            classes={{
                              badge: "productBadge font11 "
                            }}
                          >
                            <span
                              style={{ backgroundColor: product_status.color }}
                              className="order_status"
                            >
                              {product_status.message}
                            </span>
                          </Badge>
                        ) : null}
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
            return true;
          });
          if (showLoader) {
            dataToReturn.push(<div className="dp-f jcc" key="progress" >
              <CircularProgress
                disableShrink
                classes={{ root: classes.indicator }}
              />
            </div>)
          }
        }
        else {
          dataToReturn.push(
            this.notAvailableData()
          )
        }
      }        else {
        dataToReturn.push(
          this.notAvailableData()
        )
      }
    }        else {
      dataToReturn.push(
        this.notAvailableData()
      )
    }
    return dataToReturn;
  };

  render() {
    const { vendor_fetching, t, partner_product_bid_list } = this.props;
    let nextLink = partner_product_bid_list
      ? partner_product_bid_list.next
      : null;
    let elementID = "lastBid";
    let nextLinkToFetch = nextLink;
    let loadMore = "product-bids";
    if (vendor_fetching) {
      return (
        <Hidden smDown implementation="css">
          <Loader noHeight={true} />
        </Hidden>
      );
    }
    return (
      <div className="main-background-color product_listing">
        <h4 className="profile_section">{t("profileModal.offers.title")}</h4>
        <div
          className="partnerProductListing scrollbar"
          onScroll={() =>
            ModalPagination.trackScrolling(elementID, nextLinkToFetch, loadMore)
          }
        >
          {this.partnerProductBidList()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBidDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_REQUEST,
      payload: url
    });
  },
  // Update account page component
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  resetLoadMore: (payload, currentFetch) => {
    dispatch({
      type: VendorProductActionTypes.SHOW_LOADER,
      payload,
      currentFetch
    });
  },
  getPartnerBidProducts: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST
    });
  }
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.modalReducer,
  ...state.vendorProductReducer,
   ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(ProductListing);
