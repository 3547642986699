import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// nodejs library that concatenates classes
import CameraAlt from "@material-ui/icons/CameraAlt";
import Home from "@material-ui/icons/Home";
// core components
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// import { Link as router_link } from "react-router-dom";
import compose from "recompose/compose";
import Badge from "@material-ui/core/Badge";
import { withRouter } from "react-router-dom";
import ProductActionTypes from "constants/productConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
// material ui Icon
// React i18next
import { withTranslation } from "react-i18next";
import { decimalPlace } from "utils/helpers.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import Link from "@material-ui/core/Link";
//bottom navigation
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import classNames from "classnames";
import WorkBag from "@material-ui/icons/Work";
// redux
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";
import { htPrimaryGreen } from "kayfik/assets/jss/universal.jsx";

const styles = {
  root: {
    width: "100%"
  },
  selected: {
    color: htPrimaryGreen + "!important"
  }
};

class BottomBar extends React.Component {
  state = {
    cartPopover: false,
    value: 0
  };

  componentDidMount() {
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0)
      document.getElementById(href).scrollIntoView();
    window.addEventListener("scroll", this.updateView); // update the view when  scroll event is triggered
    this.updateView();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateView); // hiding pop over when  scroll event is triggered
  }

  updateView = () => {
    const { cartPopover } = this.state;
    if (cartPopover) {
      this.handleChangePopover();
    }
  };

  getLineCount = () => {
    const { review_checkout } = this.props;
    let count = 0;
    if (review_checkout) {
      if (review_checkout.basket_info.lines.length > 0) {
        count = review_checkout.basket_info.lines.length;
      }
    }
    return count;
  };

  openModal = (modal_type, modal_props = { open: true }) => async () => {
    const { modalProps, closeModal, showModal } = this.props;

    if (modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  handleOpenSellModal = () => {
    let target_modal = "whatSellingModal";
    let modal_type = target_modal;
    let modal_props = {
      open: true
    };

    this.openModal(modal_type, modal_props)();
  };

  cartItemClicked = item => () => {
    const { getProductDetail, history } = this.props;
    getProductDetail(item.product);
    history.push("/shop" + item.product_uri);
  };

  goToCheckout = () => {
    const { history, account_token } = this.props;
    if (!account_token) {
      this.openModal("initialLoginModal", {
        open: true,
        nextPage: "/checkout",
        location: "checkout",
        activeCheckout: true
      })();
    } else {
      history.push("/checkout");
    }
  };

  deleteItem = line => () => {
    this.setState({ cartPopover: !this.state.cartPopover }, () => {
      const { deleteItemRequest } = this.props;
      let url = line.url;
      if(line.from_instant_cash){
        let mprops={
          open: true,
          lineId: line.id
        }
        this.openModal("iCLineDeleteModal", mprops)();
      }else{
        deleteItemRequest(url);
      }
    });
  };


  renderCartItems = () => {
    const { classes, review_checkout, t, direction } = this.props;
    let content = [];
    let lines = [];

    if (review_checkout) {
      lines = review_checkout.basket_info.lines;
    }

    if (lines.length > 0) {
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
        let prc = line.price_incl_tax;
        if (line.from_bid) {
          prc = line.line_price;
        }
        content.push(
          <div
            key={i}
            className={
              classes.dropdownLink +
              " " +
              "d-flex align-items-center m-3 pointer-auto"
            }
          >
            <div
              className={classNames(
                classes.imgContainer,
                direction === "rtl" ? " bottom_itemsAR" : " bottom_items"
              )}
              onClick={this.cartItemClicked(line)}
            >
              <img
                src={line.image}
                alt={line.title}
                className={classNames(classes.img, "bottom_cart")}
              />
            </div>
            <div>
              <p
                className={classes.tdNameAnchor + " m-0 pointer"}
                onClick={this.cartItemClicked(line)}
              >
                {line.title}
              </p>
              <p className={classNames(classes.tdNameAnchor, "m-0")}>
                {t("homePage.header.cartLinks.quantity")}: {line.quantity}
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <p className={classNames(classes.tdNameAnchor, "m-0")}>
                  {line.price_currency}: {decimalPlace(prc)}
                </p>
              </div>
              <Link
                underline="none"
                component="button"
                className="color-pl"
                onClick={this.deleteItem(line)}
              >
                <p className="m-0">
                  {t("buyingPage.checkOut.reviewcart.remove")}
                </p>
              </Link>
            </div>
          </div>
        );
      }

      content.push(
        <div
          key={"last"}
          className={
            classes.dropdownLink +
            " " +
            "d-flex justify-center align-items-center m-3"
          }
        >
          <Button
            fullWidth
            color="primaryWhiteWithBorder"
            size="lg"
            onClick={this.goToCheckout}
          >
            {t("homePage.header.cartLinks.checkout")}
          </Button>
        </div>
      );
    } else {
      content.push(
        <div
          key="last"
          className={
            classes.dropdownLink +
            " " +
            "d-flex justify-center align-items-center"
          }
        >
          <p className="dropdown-color">
            {t("homePage.header.cartLinks.noItem")}
          </p>
        </div>
      );
    }
    return content;
  };

  handleChangePopover = () => {
    this.setState({ cartPopover: !this.state.cartPopover }, () => {
      this.renderPopOver();
    });
  };

  renderPopOver = () => {
    const { cartPopover, } = this.state;
    const { review_checkout } = this.props;
    let lines = [];

    if (review_checkout) {
      lines = review_checkout.basket_info.lines;
    }
    return (
      <Popover
        open={cartPopover}
        onClose={this.handleChangePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        classes={{
          // paper: "vw100 maxCartPopover"
          paper: classNames(
            "vw100 maxCartPopover ",
            lines.length>0? " " : " cart_empty "
          )
        }}
      >
        {this.renderCartItems()}
      </Popover>
    );
  };

  renderCartPopOver = () => {
    const { t, classes } = this.props;
    return (
      <BottomNavigationAction
        label={t("homePage.bottombar.cart")}
        classes={{ selected: classes.selected }}
        icon={<ShoppingCart className="bottomBarIcon" />}
      />
    );
  };

  handleChange = (event, value) => {
    const { history } = this.props;
    this.setState({ value }, () => {
      if (this.state.value === 3) {
        this.handleChangePopover();
      } else if (this.state.value === 2) {
        history.push("/shop");
      } else if (this.state.value === 1) {
        this.handleOpenSellModal();
      } else {
        history.push("/");
      }
    });
  };

  render() {
    const { t, classes } = this.props;
    const { value } = this.state;
    return (
      <div className="d-none-smd-md ">
        <BottomNavigation
          value={value}
          onChange={this.handleChange}
          showLabels
          className={classNames(
            classes.root,
            "bottomBar bottomBarShadow d-none-smd-md "
          )}
          id={"bottombar"}
        >
          <BottomNavigationAction
            label={t("homePage.bottombar.home")}
            classes={{ root: classes.selected, label: "bottomBarLabel" }}
            icon={<Home className="bottomBarIcon" />}
          />
          <BottomNavigationAction
            label={t("homePage.bottombar.sell")}
            classes={{ root: classes.selected, label: "bottomBarLabel" }}
            icon={<CameraAlt className="bottomBarIcon" />}
          />
          <BottomNavigationAction
            label={t("homePage.bottombar.buy")}
            classes={{ root: classes.selected, label: "bottomBarLabel" }}
            icon={<WorkBag className="bottomBarIcon" />}
          />
          <BottomNavigationAction
            label={t("homePage.bottombar.cart")}
            classes={{ root: classes.selected, label: "bottomBarLabel" }}
            icon={
              <div>
                <Badge
                  badgeContent={this.getLineCount()}
                  color="primary"
                  classes={{
                    badge: "bg-color-primaryyellow badgePositionCart"
                  }}
                >
                  <ShoppingCart className="bottomBarIcon" />
                </Badge>
              </div>
            }
          />
        </BottomNavigation>
        {this.renderPopOver()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProductDetail: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  deleteItemRequest: url => {
    dispatch({
      type: CartActionTypes.DELETE_ITEM_REQUEST,
      payload: url
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.accountReducer,
  ...state.productReducer,
  ...state.cartReducer,
  ...state.modalReducer,
  ...state.accountReducer
});

export default compose(
  withStyles(styles),
  withTranslation(),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BottomBar);
