import { call, put } from "redux-saga/effects";
import i18n from "locales/i18n.jsx";
import ActionTypes from "constants/accountConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import BidActionTypes from "constants/bidConstants.jsx";
import VendorActionTypes from "constants/vendorProductConstants.jsx";
import { store } from "utils/store.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import {
  postLogin,
  postpersonalSignup,
  postforgotPassword,
  postSignOut,
  companySignUp,
  postCheckValidToken,
  getUserDetails,
  editUserDetails,
  postchangePassword,
  postConfirmEmail,
  postResendEmailConfirmation,
  postFacebookLogin,
  postGoogleLogin,
  postTwitterLogin,
  getPaymentInfo,
  postPaymentInfo,
  postpasswordReset,
  postPhoneConfirmation,
  postPhoneVerifyCode,
  patchPhoneNumber,
  putPaymentInfo,
  postBusinessCreation
} from "actions/accountActions.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import {
  productCreateRequestCall,
  showModalHelper,
  getuserDetails,
  resetUnauthorized,
  showSnackbar
} from "utils/helpers.jsx";
import * as Sentry from '@sentry/browser';
import history from "history.js";


function productCreateRequest() {
  // let payment_info = store.getState().accountReducer.payment_info;
  let emailModalActive = store.getState().accountReducer.emailModalActive;
  let userDetails = store.getState().accountReducer.userDetails;
  let modal_props = store.getState().modalReducer.modalProps;
  let tempModalProps = store.getState().modalReducer.tempModalProps;
  let combo_fetching = store.getState().comboProductReducer.combo_fetching;

  if ((modal_props.activeSell || tempModalProps.activeSell) && !combo_fetching) {
    if (
      userDetails &&
      userDetails.is_phone_verified &&
      userDetails.is_email_verified
    ) {
      productCreateRequestCall();
    } else if (userDetails && !userDetails.is_email_verified) {
      let modalType = "verifyEmailModal";
      let modalProps = {
        open: true,
        title: modalType,
        verifyFrom: "listing",
        activeSell: modal_props.activeSell || tempModalProps.activeSell
      };
      showModalHelper(modalProps, modalType);
      if (emailModalActive) {
        let txt = i18n.t("buyingPage.checkOut.verifyemail.verifySnackbar");
        showSnackbar(txt, "warning");
      }
    } else if (
      userDetails &&
      userDetails.is_email_verified &&
      !userDetails.is_phone_verified
    ) {
      let modalType = "verifyPhoneModal";
      let modalProps = {
        open: true,
        title: modalType,
        verifyFrom: "listing",
        activeSell: modal_props.activeSell || tempModalProps.activeSell
      };
      showModalHelper(modalProps, modalType);
    }
    // else if (
    //   userDetails &&
    //   userDetails.is_phone_verified &&
    //   userDetails.is_email_verified &&
    //   (payment_info.length <= 0 ||
    //     (payment_info.length > 0 && !payment_info[0].merchant_id))
    // ) {
    //   let modalType = "getIbanModal";
    //   let modalProps = {
    //     open: true,
    //     title: modalType,
    //     verifyFrom: "listing",
    //     activeSell: modal_props.activeSell || tempModalProps.activeSell
    //   };
    //   showModalHelper(modalProps, modalType);
    // } 
    else {
      getuserDetails();
    }
  }
}

function* loginWorker(params) {
  let { form_data } = params;
  let modal_props = store.getState().modalReducer.modalProps;
  try {
    let response = yield call(postLogin, form_data);
    let data = response.data;
    let token = data.token;
    let user = data.user;
    let loader = false;
    let language = store.getState().globalReducer.language;
    if (
      language !== user.language &&
      !modal_props.activeSell &&
      !modal_props.nextPage
    ) {
      let lang = user.language;
      let dir = "ltr";
      i18n.changeLanguage(lang);
      if (lang === "en") {
        dir = "ltr";
        document.body.lang = "en";
        document.body.dir = "ltr";
      } else {
        dir = "rtl";
        document.body.lang = "ar";
        document.body.dir = "rtl";
      }
      yield put({
        type: GlobalActionTypes.CHANGE_LANGUAGE_REQUEST,
        lang
      });
      yield put({
        type: GlobalActionTypes.CHANGE_DIRECTION_SUCCESS,
        dir
      });
      setTimeout(() => {
        window.location = window.location.href;
      }, 500);
    }

    yield put({ type: ActionTypes.LOGIN_SUCCESS, token, user, loader });
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    let path = window.location.pathname;

    yield put({ type: ActionTypes.USER_DETAIL_SUCCESS, loader, data: user });

    let paymentresponse = yield call(getPaymentInfo);
    let paydata = paymentresponse.data;
    yield put({ type: ActionTypes.GET_PAYMENT_INFO_SUCCESS, data: paydata });
    
    yield put({ type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
    yield put({ type: VendorActionTypes.GET_ACTION_COUNT_REQUEST });
    yield put({ type: VendorActionTypes.GET_PARTNER_PRODUCT_LIST_REQUEST});
    // if its successfull close the modal
    if (modal_props && modal_props.nextPage && modal_props.activeCheckout) {
      let nextPageToGo = modal_props.nextPage;
      //let nextPageToGoActive = false;
      // let protocol = window.location.protocol;
      // let host = window.location.hostname;
      // let port = window.location.port;
      // let target_url = host;

      // if (port !== "") {
      //   target_url = target_url + ":" + port;
      // }
      // target_url = protocol + "//" + target_url + nextPageToGo;
      setTimeout(() => {
        history.push(nextPageToGo);
      }, 500);
      yield put({
        type: AccountActionTypes.UPDATE_NEXT_PAGE_TO_GO,
        nextPageToGo
        // nextPageToGoActive
      });
    } else if (modal_props && modal_props.nextModal) {
      if (modal_props.nextModal === "productPreviewModal") {
        if (modal_props.activeSell) {
          productCreateRequest();
        }
      }
      else if (modal_props.nextModal === "makeBidModal") {
        if (user && !user.is_email_verified) {
          yield put({
            type: ModalActionTypes.SHOW_MODAL_REQUEST,
            modalProps: {
              open: true,
              verifyFrom: "makeBid",
            },
            modalType: "verifyEmailModal"
          });
        }
        else if (user && !user.is_phone_verified) {
          yield put({
            type: ModalActionTypes.SHOW_MODAL_REQUEST,
            modalProps: {
              open: true,
              verifyFrom: "makeBid",
            },
            modalType: "verifyPhoneModal"
          });
        }
        else {
          yield put({
            type: ModalActionTypes.SHOW_MODAL_REQUEST,
            modalProps: {
              open: true
            },
            modalType: modal_props.nextModal
          });
        }
      } else {

        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps: {
            open: true
          },
          modalType: modal_props.nextModal
        });
      }

    } else if (path.includes("company-signup")) {
      history.push("/");
    }

    // show a notification
    let txt = i18n.t("loginModal.successLogin");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {
    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.LOGIN_FAILED, error });
  }
}

function* socialLoginWorker(params) {
  let { form_data, social_type } = params;
  let modal_props = store.getState().modalReducer.modalProps;

  let postfunction = postFacebookLogin;

  if (social_type === "google") {
    postfunction = postGoogleLogin;
  } else if (social_type === "twitter") {
    postfunction = postTwitterLogin;
  }
  try {
    let response = yield call(postfunction, form_data);
    let data = response.data;
    let token = data.token;
    let user = data.user;
    let loader = false;
    yield put({ type: ActionTypes.SOCIAL_LOGIN_SUCCESS, token, user });
    let language = store.getState().globalReducer.language;
    if (
      language !== user.language &&
      !modal_props.activeSell &&
      !modal_props.nextPage
    ) {
      let lang = user.language;
      let dir = "ltr";
      i18n.changeLanguage(lang);
      if (lang === "en") {
        dir = "ltr";
        document.body.lang = "en";
        document.body.dir = "ltr";
      } else {
        dir = "rtl";
        document.body.lang = "ar";
        document.body.dir = "rtl";
      }
      yield put({
        type: GlobalActionTypes.CHANGE_LANGUAGE_REQUEST,
        lang
      });
      yield put({
        type: GlobalActionTypes.CHANGE_DIRECTION_SUCCESS,
        dir
      });
      setTimeout(() => {
        window.location = window.location.href;
      }, 500);
    }
    yield put({ type: ActionTypes.USER_DETAIL_SUCCESS, loader, data: user });
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    // let paymentresponse = yield call(getPaymentInfo);
    // let paydata = paymentresponse.data;

    // yield put({ type: ActionTypes.GET_PAYMENT_INFO_SUCCESS, data: paydata });
    yield put({ type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
    // if its successfull close the modal

    // open next modal if there is

    if (modal_props && modal_props.nextModal) {
      if (modal_props.nextModal === "productPreviewModal") {
        let product_data = store.getState().comboProductReducer.product_data;
        if (product_data.product_class && modal_props.activeSell) {

          productCreateRequest();
        }
      } else {
        let largeModal = false;
        if (modal_props.nextModal === "profileModal") {
          largeModal = true;
        }
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps: {
            open: true,
            modalLarge: largeModal
          },
          modalType: modal_props.nextModal
        });
      }
    }
    // if there is a next page
    if (modal_props && modal_props.nextPage && modal_props.activeCheckout) {
      let nextPageToGo = modal_props.nextPage;
      yield put({
        type: AccountActionTypes.UPDATE_NEXT_PAGE_TO_GO,
        nextPageToGo
        //nextPageToGoActive
      });
      setTimeout(() => {
        history.push(nextPageToGo);
      }, 500);
    }

    // show a notification
    let txt = i18n.t("loginModal.successLogin");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.SOCIAL_LOGIN_FAILED, error });
  }
}

function* companySignUpWorker(params) {
  let { form_data } = params;
  try {
    let response = yield call(companySignUp, form_data);
    let data = response.data;
    let token = data.token;
    let user = data.user;
    let loader = true;
    yield put({ type: ActionTypes.COMPANY_SIGNUP_SUCCESS });
    yield put({
      type: ActionTypes.LOGIN_SUCCESS,
      token,
      user,
      loader
    });
    // if its successfull close the modal
    yield put({ type: ActionTypes.USER_DETAIL_SUCCESS, loader, data: user });
    // after signing up redirect to home
    // let protocol = window.location.protocol;
    // let host = window.location.hostname;
    // let port = window.location.port;
    // let target_url = host;
    // if (port !== "") {
    //   target_url = target_url + ":" + port;
    // }
    // target_url = protocol + "//" + target_url;
    let urltoPush =  "/?company=created";
    history.push(urltoPush);
    // let paymentresponse = yield call(getPaymentInfo);
    // let paydata = paymentresponse.data;

    // yield put({ type: ActionTypes.GET_PAYMENT_INFO_SUCCESS, data: paydata });
    let tempModalProps = store.getState().modalReducer.tempModalProps;

    if (tempModalProps && tempModalProps.nextModal) {
      if (tempModalProps.nextModal === "productPreviewModal") {
        let product_data = store.getState().comboProductReducer.product_data;
        if (product_data.product_class && tempModalProps.activeSell) {
          productCreateRequest();
        }
      } else {
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps: {
            open: true
          },
          modalType: tempModalProps.nextModal
        });
      }
    }
  } catch (e) {

    let error = e;
    if (e.response && e.response.status === 400) {
      let data = 0;
      yield put({ type: ActionTypes.UPDATE_COMPANY_STEP, data });
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.COMPANY_SIGNUP_FAILED, error });
  }
}

function* personalSignupWorker(params) {
  let { form_data } = params;
  try {
    let modal_props = store.getState().modalReducer.modalProps;
    let response = yield call(postpersonalSignup, form_data);
    let data = response.data;
    let token = data.token;
    let user = data.user;
    let loader = true;
    yield put({ type: ActionTypes.LOGIN_SUCCESS, token, user, loader });
    yield put({ type: ActionTypes.USER_DETAIL_SUCCESS, loader, data: user });
    // let paymentresponse = yield call(getPaymentInfo);
    // let paydata = paymentresponse.data;
    // yield put({ type: ActionTypes.GET_PAYMENT_INFO_SUCCESS, data: paydata });
    // open next modal if there is
    if (modal_props && modal_props.nextModal) {
      if (modal_props.nextModal === "productPreviewModal") {
        let product_data = store.getState().comboProductReducer.product_data;
        if (product_data.product_class && modal_props.activeSell) {
          productCreateRequest();
        }
      } else if (modal_props.nextModal === "makeBidModal") {
        if (user && !user.is_email_verified) {
          yield put({
            type: ModalActionTypes.SHOW_MODAL_REQUEST,
            modalProps: {
              open: true,
              verifyFrom: "makeBid",
            },
            modalType: "verifyEmailModal"
          });
        }
        else if (user && !user.is_phone_verified) {
          yield put({
            type: ModalActionTypes.SHOW_MODAL_REQUEST,
            modalProps: {
              open: true,
              verifyFrom: "makeBid",
            },
            modalType: "verifyPhoneModal"
          });
        }
        else {
          yield put({
            type: ModalActionTypes.SHOW_MODAL_REQUEST,
            modalProps: {
              open: true
            },
            modalType: modal_props.nextModal
          });
        }
      }
      else {
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps: {
            open: true
          },
          modalType: modal_props.nextModal
        });
      }
    } else if (
      modal_props &&
      modal_props.nextPage &&
      modal_props.activeCheckout
    ) {
      let nextPageToGo = modal_props.nextPage;
      yield put({
        type: AccountActionTypes.UPDATE_NEXT_PAGE_TO_GO,
        nextPageToGo
        //nextPageToGoActive
      });
      setTimeout(() => {
        history.push(nextPageToGo);
      }, 500);
    } else {
      // if its successfull close the modal
      yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    }
    yield put({ type: AccountActionTypes.ACCOUNT_FETCHING, payload: false });
    // show a notification
    let txt = i18n.t("personalSignupModal.successmessage");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];
      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.PERSONALSIGNUP_FAILED, error });
  }
}

function* forgotPasswordWorker(params) {
  let { form_data } = params;
  try {
    yield call(postforgotPassword, form_data);
    yield put({ type: ActionTypes.FORGOTPASSWORD_SUCCESS });
    // if its successfull close the modal
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    // show a notification
    let txt = i18n.t("forgotPassword.successmessage");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("unauthorizedAccess"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.FORGOTPASSWORD_FAILED, error });
  }
}

function* passwordResetWorker(params) {
  let { form_data } = params;
  try {
    let response = yield call(postpasswordReset, form_data);
    let data = response.data;
    yield put({ type: ActionTypes.PASSWORD_RESET_SUCCESS, data });
    // if its successfull close the modal
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    // show a notification
    let txt = i18n.t("resetPassword.successMessage");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.PASSWORD_RESET_FAILED, error });
  }
}

function* changePasswordWorker(action) {
  let { payload } = action;
  try {
    let response = yield call(postchangePassword, payload);
    let data = response.detail;
    yield put({ type: ActionTypes.CHANGE_PASSWORD_SUCCESS, data });
    // if its successfull close the modal
    resetUnauthorized();
    // show a notification
    let txt = i18n.t("profileModal.accountSettings.passwordChanged");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];

        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.CHANGE_PASSWORD_FAILED, error });
  }
}

function* signOutWorker() {
  try {
    yield call(postSignOut);
    yield put({ type: ActionTypes.SIGNOUT_SUCCESS });
    // if its successfull close the modal
    resetUnauthorized();
    setTimeout(() => {
      history.push("/");
    }, 1000);
  } catch (e) {

    let error = e;
    if (e.response && e.response.status === 401) {
      yield put({ type: ActionTypes.ACCOUNT_RESET });
      yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
      yield put({ type: AddressActionTypes.ADDRESS_RESET });
      yield put({ type: CartActionTypes.CART_RESET });
      yield put({ type: BidActionTypes.BID_RESET });
      yield put({ type: GlobalActionTypes.RESET_GO_TO });
      yield put({ type: VendorActionTypes.VENDOR_PRODUCT_RESET });
      yield put({ type: ProductActionTypes.RESET_PRICE_ATTS });

    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.SIGNOUT_FAILED, error });
  }
}

function* checkValidTokenWorker(params) {
  let { form_data } = params;
  try {
    yield call(postCheckValidToken, form_data);
    yield put({ type: ActionTypes.VERIFY_TOKEN_SUCCESS });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("verifytokenfailed"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    else {
      Sentry.captureException(e);
    }
    yield put({ type: ActionTypes.VERIFY_TOKEN_FAILED, error });
  }
}

function* userDetailsWorker() {
  try {
    let response = yield call(getUserDetails);
    let data = response.data;
    let loader = false;
    yield put({ type: ActionTypes.USER_DETAIL_SUCCESS, loader, data });
    // let payment_info = store.getState().accountReducer.payment_info;
    let modal_props = store.getState().modalReducer.modalProps;
    let tempModalProps = store.getState().modalReducer.tempModalProps;
    let goTo = store.getState().globalReducer.goTo;

    // <------ check the conditions if true post business account creation ------>
    if (
      data &&
      data.civil_id_back &&
      data.civil_id_front &&
      data.user_payment_info &&
      !data.has_business_account
    ) {
      yield put({ type: ActionTypes.BUSINESS_ACCOUNT_REQUEST });
    }
    // <------ check the conditions if true post business account creation ------>

    let showNextModal = store.getState().accountReducer.showNextModal;
    let nextPageToGo = store.getState().accountReducer.nextPageToGo;
    let emailModalActive = store.getState().accountReducer.emailModalActive;
    if (nextPageToGo === "/checkout" && !showNextModal) {
      // let protocol = window.location.protocol;
      // let host = window.location.hostname;
      // let port = window.location.port;
      // let target_url = host;

      // if (port !== "") {
      //   target_url = target_url + ":" + port;
      // }
      // target_url = protocol + "//" + target_url + nextPageToGo;
      if (!data.is_email_verified) {
        let modalType = "verifyEmailModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: nextPageToGo === "/checkout" ? "checkout" : "listing",
          activeCheckout: true
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
        if (emailModalActive) {
          let txt = i18n.t("buyingPage.checkOut.verifyemail.verifySnackbar");
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      } else if (data.is_email_verified && !data.is_phone_verified) {
        let modalType = "verifyPhoneModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: nextPageToGo === "/checkout" ? "checkout" : "listing",
          activeCheckout: true
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      } else {
        history.push(nextPageToGo);
      }
    } else if (showNextModal && (modal_props.activeSell || tempModalProps.activeSell)) {
      if (!data.is_email_verified) {
        let modalType = "verifyEmailModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: "listing",
          activeSell: modal_props.activeSell || tempModalProps.activeSell
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
        if (emailModalActive) {
          let txt = i18n.t("buyingPage.checkOut.verifyemail.verifySnackbar");
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      } else if (data.is_email_verified && !data.is_phone_verified) {
        let modalType = "verifyPhoneModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: "listing",
          activeSell: modal_props.activeSell || tempModalProps.activeSell
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      }
      // else if (
      //   data.is_email_verified &&
      //   data.is_phone_verified &&
      //   (payment_info.length <= 0 ||
      //     (payment_info.length > 0 && !payment_info[0].merchant_id))
      // ) {
      //   let modalType = "getIbanModal";
      //   let modalProps = {
      //     open: true,
      //     title: modalType,
      //     verifyFrom: "listing",
      //     activeSell: modal_props.activeSell || tempModalProps.activeSell
      //   };
      //   yield put({
      //     type: ModalActionTypes.SHOW_MODAL_REQUEST,
      //     modalProps,
      //     modalType
      //   });
      // }       
      else if (
        (modal_props.activeSell || tempModalProps.activeSell) &&
        data.is_email_verified &&
        data.is_phone_verified
      ) {
        let product_data = store.getState().comboProductReducer.product_data;
        if (product_data.product_class) {
          productCreateRequest();
        }
      }
    } else if (goTo === "checkout" && modal_props.activeCheckout) {
      if (!data.is_email_verified) {
        let modalType = "verifyEmailModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: "checkout",
          activeCheckout: true
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
        if (emailModalActive) {
          let txt = i18n.t("buyingPage.checkOut.verifyemail.verifySnackbar");
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      } else if (data.is_email_verified && !data.is_phone_verified) {
        let modalType = "verifyPhoneModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: "checkout",
          activeCheckout: true
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      } else if (data.is_email_verified && data.is_phone_verified) {
        yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
        history.push("/checkout");
      }
    } else if (window.location.href.indexOf("checkout") !== -1) {
      if (!data.is_email_verified) {
        let modalType = "verifyEmailModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: "checkout",
          activeCheckout: true
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
        if (emailModalActive) {
          let txt = i18n.t("buyingPage.checkOut.verifyemail.verifySnackbar");
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      } else if (data.is_email_verified && !data.is_phone_verified) {
        let modalType = "verifyPhoneModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: "checkout",
          activeCheckout: true
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      }
    }
  } catch (e) {

    let error = e;
    if (e.response && e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("unauthorizedAccess"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.USER_DETAIL_FAILED, error });
  }
}

function* editUserDetailsWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(editUserDetails, payload);
    let data = response.data;
    // if its successfull close the modal
    // show a notification
    let modal_props = store.getState().modalReducer.modalProps;
    let modalType = "profileModal";
    let modalProps = { open: true, title: modalType, modalLarge: true };
    if (modal_props && modal_props.nextModal) {
      modalType = modal_props.nextModal;
      modalProps = { open: true, title: modalType, modalLarge: true };
    }

    yield put({ type: ActionTypes.EDIT_USER_DETAIL_SUCCESS, data });
    if (data.message) {
      let txt = data.message;
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
      yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
      yield put({ type: ActionTypes.SIGNOUT_REQUEST });
    } else {
      if (modalType !== "productPreviewModal") {
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      }
    }
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];

        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.EDIT_USER_DETAIL_FAILED, error });
  }
}

function* patchPhoneNumberWorker(action) {
  const { payload } = action;
  try {
    yield call(patchPhoneNumber, payload);
    yield put({ type: ActionTypes.PATCH_PHONE_SUCCESS });
    yield put({ type: AccountActionTypes.USER_DETAIL_REQUEST });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];

        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.PATCH_PHONE_FAILED, error });
  }
}

function* postConfirmEmailWorker(params) {
  const { form_data } = params;
  let modal_props = store.getState().modalReducer.modalProps;
  let emailConfirmMsg = "";
  try {
    yield call(postConfirmEmail, form_data);
    emailConfirmMsg = i18n.t("confirmemail.emailconfirmed");
    yield put({ type: ActionTypes.CONFIRM_EMAIL_SUCCESS, emailConfirmMsg });
    // let txt = i18n.t("confirmemail.emailconfirmed");
    // yield put(
    //   enqueueSnackbar({
    //     message: txt,
    //     options: {
    //       variant: "success",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center"
    //       }
    //     }
    //   })
    // );
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    // open login directly
    if (modal_props && modal_props.nextModal) {
      yield put({
        type: ModalActionTypes.SHOW_MODAL_REQUEST,
        modalProps: {
          open: true
        },
        modalType: modal_props.nextModal
      });
    }
  } catch (e) {

    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        emailConfirmMsg = i18n.t("unauthorizedAccess");
        // yield put(
        //   enqueueSnackbar({
        //     message: i18n.t("unauthorizedAccess"),
        //     options: {
        //       variant: "error",
        //       anchorOrigin: {
        //         vertical: "top",
        //         horizontal: "center"
        //       }
        //     }
        //   })
        // );
      } else {
        const res_object = e.response.data;
        let messages = [];

        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          emailConfirmMsg = emailConfirmMsg + " " + txt
          // yield put(
          //   enqueueSnackbar({
          //     message: txt,
          //     options: {
          //       variant: "warning",
          //       anchorOrigin: {
          //         vertical: "top",
          //         horizontal: "center"
          //       }
          //     }
          //   })
          // );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      emailConfirmMsg = txt;
      // yield put(
      //   enqueueSnackbar({
      //     message: txt,
      //     options: {
      //       variant: "error",
      //       anchorOrigin: {
      //         vertical: "top",
      //         horizontal: "center"
      //       }
      //     }
      //   })
      // );
    }
    yield put({ type: ActionTypes.CONFIRM_EMAIL_FAILED, e, emailConfirmMsg });
  }
}

function* postResendEmailConfirmationWorker() {
  try {
    yield call(postResendEmailConfirmation);
    yield put({ type: ActionTypes.RESEND_EMAIL_CONFIRMATION_SUCCESS });
    // show a notification
    let txt = i18n.t("loginModal.resendSuccess");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.RESEND_EMAIL_CONFIRMATION_FAILED, error });
  }
}

function* postResendPhoneConfirmationWorker(payload) {
  let { form_data } = payload;

  try {
    let response = yield call(postPhoneConfirmation, form_data);
    const data = response.data;
    yield put({ type: ActionTypes.RESEND_PHONE_CONFIRMATION_SUCCESS, data });
    yield put({ type: ActionTypes.RESET_TIMER });
    let txt = data.message;
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.RESEND_PHONE_CONFIRMATION_FAILED, error });
  }
}

function* postPhoneCodeVerify(payload) {
  let { data } = payload;
  let showNextModal = store.getState().accountReducer.showNextModal;
  let modal_props = store.getState().modalReducer.modalProps;
  let goTo = store.getState().globalReducer.goTo;
  try {
    yield call(postPhoneVerifyCode, data);
    yield put({ type: ActionTypes.VERIFY_PHONE_CODE_SUCCESS });
    let payload = {
      phone: data.phone
    };
    yield put({ type: ActionTypes.PATCH_PHONE_REQUEST, payload });
    if (showNextModal && modal_props.activeSell) {
      productCreateRequest();
    } else if (goTo === "checkout") {
      yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
      history.push("/checkout");
    } else if (modal_props && modal_props.verifyFrom === "makeBid") {
      yield put({
        type: ModalActionTypes.SHOW_MODAL_REQUEST,
        modalProps: {
          open: true
        },
        modalType: "makeBidModal"
      });
    } else if (modal_props && modal_props.verifyFrom === "reqProd") {
      let component = "requestProducts";
      yield put({
        type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
        component
      });
      yield put({
        type: ModalActionTypes.SHOW_MODAL_REQUEST,
        modalProps: {
          open: true
        },
        modalType: "profileModal"
      });
    } else {
      yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    }
    // show a notification
    let txt = i18n.t("loginModal.phoneVerificationSuccess");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.VERIFY_PHONE_CODE_FAILED, error });
  }
}

function* getPaymentInfoWorker() {
  try {
    let response = yield call(getPaymentInfo);
    let data = response.data;
    let modal_props = store.getState().modalReducer.modalProps;
    yield put({ type: ActionTypes.GET_PAYMENT_INFO_SUCCESS, data });
    let showNextModal = store.getState().accountReducer.showNextModal;
    if (showNextModal && modal_props.activeSell) {
      productCreateRequest();
    }
  } catch (e) {
    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.GET_PAYMENT_INFO_FAILED, error });
  }
}

function* postPaymentInfoWorker(action) {
  let { form_data } = action;
  try {
    let response = yield call(postPaymentInfo, form_data);
    let userDetails = store.getState().accountReducer.userDetails;
    let data = response.data;
    yield put({ type: ActionTypes.POST_PAYMENT_INFO_SUCCESS, data });
    let paymentresponse = yield call(getPaymentInfo);
    let paydata = paymentresponse.data;
    yield put({ type: ActionTypes.GET_PAYMENT_INFO_SUCCESS, data: paydata });
    yield put({ type: AccountActionTypes.USER_DETAIL_REQUEST});
    // let modal_props = store.getState().modalReducer.modalProps;
    // if (modal_props && modal_props.nextModal === "profileModal") {
    let nextModal="profileModal";
    if(userDetails && !userDetails.civil_id_back && !userDetails.civil_id_front){
      nextModal="civilIdModal";
    }
    yield put({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps: {
        open: true
      },
      modalType:nextModal
    });
  } catch (e) {
    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.POST_PAYMENT_INFO_FAILED, error });
  }
}

function* putPaymentInfoWorker(action) {
  let { url, form_data } = action;
  try {
    let response = yield call(putPaymentInfo, url, form_data);
    let data = response.data;
    yield put({ type: ActionTypes.PUT_PAYMENT_INFO_SUCCESS, data });

    let paymentresponse = yield call(getPaymentInfo);
    let paydata = paymentresponse.data;
    yield put({ type: ActionTypes.GET_PAYMENT_INFO_SUCCESS, data: paydata });
    let userDetails = store.getState().accountReducer.userDetails;
    // let modal_props = store.getState().modalReducer.modalProps;
    let nextModal="profileModal";
    if(userDetails && !userDetails.civil_id_back && !userDetails.civil_id_front){
      nextModal="civilIdModal";
    }
    // if (modal_props && modal_props.nextModal === "profileModal") {
    yield put({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps: {
        open: true
      },
      modalType: nextModal
    });
    // }
  } catch (e) {
    let error = e;
    console.log("e.response", e.response);
    // if (e.response && e.response.status >= 400 && e.response.status < 500) {
    //   if (e.response && e.response.status === 401) {
    //     resetUnauthorized();
    //     yield put(
    //       enqueueSnackbar({
    //         message: i18n.t("unauthorizedAccess"),
    //         options: {
    //           variant: "error",
    //           anchorOrigin: {
    //             vertical: "top",
    //             horizontal: "center"
    //           }
    //         }
    //       })
    //     );
    //   } else {
    //     const res_object = e.response.data;
    //     let messages = [];
    //     for (let i in res_object) {
    //       let error_val = res_object[i];
    //       if (error_val) {
    //         messages.push(error_val);
    //       }
    //     }
    //     for (let i = 0; i < messages.length; i++) {
    //       let txt = messages[i];
    //       yield put(
    //         enqueueSnackbar({
    //           message: txt,
    //           options: {
    //             variant: "warning",
    //             anchorOrigin: {
    //               vertical: "top",
    //               horizontal: "center"
    //             }
    //           }
    //         })
    //       );
    //     }
    //   }
    // } else {
    //   Sentry.captureException(e);
    //   let txt = i18n.t("common.error.network");
    //   yield put(
    //     enqueueSnackbar({
    //       message: txt,
    //       options: {
    //         variant: "error",
    //         anchorOrigin: {
    //           vertical: "top",
    //           horizontal: "center"
    //         }
    //       }
    //     })
    //   );
    // }

    yield put({ type: ActionTypes.PUT_PAYMENT_INFO_FAILED, error });
  }
}

function* businessAccountWorker() {
  try {
    yield call(postBusinessCreation);
    yield put({ type: ActionTypes.BUSINESS_ACCOUNT_SUCCESS });
    yield put({ type: AccountActionTypes.USER_DETAIL_REQUEST });
  } catch (e) {
    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response && e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.BUSINESS_ACCOUNT_FAILED, error });
  }
}

export {
  loginWorker,
  personalSignupWorker,
  forgotPasswordWorker,
  signOutWorker,
  companySignUpWorker,
  checkValidTokenWorker,
  userDetailsWorker,
  editUserDetailsWorker,
  changePasswordWorker,
  postConfirmEmailWorker,
  postResendEmailConfirmationWorker,
  socialLoginWorker,
  getPaymentInfoWorker,
  postPaymentInfoWorker,
  putPaymentInfoWorker,
  passwordResetWorker,
  postResendPhoneConfirmationWorker,
  postPhoneCodeVerify,
  patchPhoneNumberWorker,
  businessAccountWorker
};
