import {
  title,
  whiteColor,
  grayColor
} from "assets/jss/material-kit-pro-react.jsx";
import {
  htPrimaryLight,
  htSecondaryLight
} from "kayfik/assets/jss/universal.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";

const modalStyle = theme => ({
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  ...tooltipsStyle,
  modalRoot: {
    display: "block"
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
      margin: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px !important",
      marginTop: "10vh !important",
      height: "100%"
    },
    borderRadius: "0px",
    overflowX: "hidden",
    overflowY: "auto", // prev auto
    maxHeight: "95vh",
    width: "100%"
    // marginTop: "130px !important"
  },
  modalHeader: {
    borderBottom: "none",
    paddingTop: "24px",
    paddingRight: "24px",
    paddingLeft: "24px",
    minHeight: "43px"
  },
  modalTitle: {
    ...title,
    margin: "0 !important",
    lineHeight: "1.5"
  },
  modalCloseButton: {
    "&, &:hover": {
      color: grayColor[0]
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    padding: "1rem",
    margin: "-1rem -1rem -1rem auto",
    backgroundColor: "transparent",
    bidListingContainerr: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + whiteColor,
    opacity: ".5",
    zIndex: 99999 + "!important"
  },
  modalClose: {
    width: "20px",
    height: "20px",
    color: htPrimaryLight
  },
  htPrimaryLightBackground: {
    background: htPrimaryLight + "!important"
  },
  selectMenuItem: {
    "&:hover": {
      background: htPrimaryLight + "!important"
    }
  },
  modalBody: {
    paddingTop: "24px",
    paddingRight: "15px",
    paddingBottom: "0px",
    paddingLeft: "15px",
    position: "relative",
    overflow: "unset"
  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0"
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  instructionNoticeModal: {
    marginBottom: "25px"
  },
  imageNoticeModal: {
    maxWidth: "150px"
  },
  modalLarger: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1000px !important"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none !important",
      width: "100%",
      maxHeight: "100vh",
      paddingBottom: "10vh !important",
      position: "fixed",
      overflowX: "hidden",
      overflowY: "overlay"
    },
    maxHeight: "95vh"
    // minHeight: "95vh"
  },

  modalSmall: {
    [theme.breakpoints.up("sm")]: {
      width: "300px",
      margin: "auto"
    },
    margin: "0 auto"
  },
  modalSmallBody: {
    marginTop: "20px"
  },
  modalSmallFooterFirstButton: {
    margin: "0",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "auto"
  },
  modalSmallFooterSecondButton: {
    marginBottom: "0",
    marginLeft: "5px"
  },
  modalLogin: {
    maxWidth: "360px",
    width: "100%",
    "& $modalCloseButton": {
      color: whiteColor,
      top: "-10px",
      right: "10px",
      textShadow: "none",
      position: "relative"
    },
    "& $modalHeader": {
      borderBottom: "none",
      paddingTop: "24px",
      paddingRight: "24px",
      paddingBottom: "0",
      paddingLeft: "24px"
    },
    "& $modalBody": {
      paddingBottom: "0",
      paddingTop: "0"
    },
    "& $modalFooter": {
      paddingBottom: "0",
      paddingTop: "0"
    }
  },
  modalLoginCard: {
    marginBottom: "0",
    margin: "0",
    "& $modalHeader": {
      paddingTop: "0"
    }
  },
  modalSignup: {
    maxWidth: "900px",
    width: "100%",
    "& $modalHeader": {
      paddingTop: "0"
    },
    "& $modalTitle": {
      textAlign: "center",
      width: "100%",
      marginTop: "0.625rem"
    },
    "& $modalBody": {
      paddingBottom: "0",
      paddingTop: "0"
    }
  },
  modalSignupCard: {
    padding: "40px 0",
    margin: "0"
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },
  modalMedium: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "700px"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
      width: "100%"
      // marginLeft: "15px !important",
      // marginRight: "15px !important"
    }
    // [theme.breakpoints.down("md")]:{
    //   margin: "0px !important"
    // },
  },
  inputAdornment: {
    marginRight: "15px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: htPrimaryLight + "!important"
  },
  withAdormentBG: {
    backgroundColor: htSecondaryLight
  },
  modalContainer: {
    [theme.breakpoints.down("sm")]: {
      height: "100vh !important"
    }
    // [theme.breakpoints.down("sm")]:{
    //   margin: "0px !important",
    //   marginTop: "50px !important"
    // },
  },
  similarProdImage: {
    borderRadius: "0 !important",
    boxShadow: "unset !important"
  },
  previewProduct: {
    objectFit: "cover",
    height: "550px",
    width: "100%"
  },
  backdropRoot: {
    [theme.breakpoints.down("sm")]: {
      //marginTop: "65px !important"
    }
  },
  avatar: {
    marginTop: "30px !important",
    maxHeight: "165px !important",
    maxWidth: "165px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px !important",
      maxHeight: "130px !important",
      maxWidth: "130px !important"
    }
  },
  f15: {
    flex: "1.5 !important"
  },
  bidListingContainer: {
    height: "15vh",
    // width: "25vw",
    textAlign: "center",
    display: "flex",
    // flex: 1.8,
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "contain"
    },
    [theme.breakpoints.down("sm")]: {
      height: "17vh",
      width: "40vw"
    }
  },
  listDetailContainer: {
    // width: "70%",
    flex: 2
  },

  bidDetailContainer: {
    width: "auto",
    height: "30vh",

    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  },
  modalSmallCloseButtonAR: {
    // left: "1rem",
    [theme.breakpoints.down("sm")]: {
      // left: "0rem !important"
    }
  },
  labelCheckbox: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    verticalAlign: "middle"
  },
  labelCheckboxAR: {
    marginRight: "-14px",
    marginLeft: "0px"
  },
  addToCartButtonAR: {
    marginRight: "-14px"
  },
  shareAR: {
    marginRight: "20px"
  },
  modalBuy: {
    paddingRight: "15px",
    paddingBottom: "15px",
    paddingLeft: "15px",
    position: "relative",
    overflow: "unset"
  },
  overflowList: {
    [theme.breakpoints.up("sm")]: {
      //overflow: "visible !important"
    }
  },
  modalBuyClose: {
    "&, &:hover": {
      color: grayColor[0]
    },
    "&:hover": {
      opacity: "1"
    },
    cursor: "pointer",
    margin: "-1.3rem -1.5rem -1.5rem auto",
    backgroundColor: "transparent",
    bidListingContainerr: "0",
    WebkitAppearance: "none",
    float: "right",
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "1",
    textShadow: "0 1px 0 " + whiteColor,
    opacity: ".5",
    zIndex: 99999 + "!important"
  },
  modalBuySmallAR: {
    left: "-1rem"
  },
  indicator: {
    color: htPrimaryLight
  },
  scrollPaper: {
    alignItems: "unset"
  },
  noScrollModal: {
    [theme.breakpoints.up("sm")]: {
      overflowY: "hidden !important"
    }
  },
  modalBodyCivilID:{
    paddingBottom: "24px",
  }
});

export default modalStyle;
