import React from "react";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";


class OrderStatusStepper extends React.Component {


  getSteps = () => {
    const { lineSteps, t} = this.props;
    return lineSteps.map(status=>{
      return { title: t('OrderSteps.'+status.code), description: t('OrderSteps.'+status.code+"_data") }
    })
  }

  getStepClass = (index, totalSteps) => {
    const { activeStep } = this.props;
    let cls = " ";
    if (index === activeStep) {
      cls = cls + " current ";
    }
    else if (index < activeStep) {
      cls = cls + " is-done ";
    }

    if (index === activeStep - 1) {
      cls = cls + " beforeActive ";
    }
    if (index === totalSteps - 1) {
      cls = cls + " last ";
    }
    return cls;
  }

  render() {
    const steps = this.getSteps();
    return (
      <div className="orderStepperRoot">
        <div className="wrapper">
          <ul className="StepProgress mb-0">
            {steps.map((step, index) => {
              return (
                <li className={"StepProgress-item " + this.getStepClass(index, steps.length)} key={index}>
                  <div className="indicatorContainer">
                    <div className="stepContents">
                      <strong>{step.title}</strong>
                      {step.description}
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getProductDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  postActionClicked: (url, detailUrl) => {
    dispatch({
      type: VendorProductActionTypes.POST_PICKUP_ACTION_REQUEST,
      payload: url,
      detailUrl: detailUrl
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  }
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer,
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(OrderStatusStepper);
