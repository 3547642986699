import api from "utils/api.jsx";

const postAddress = data => {
  return api.postFunction(api.user_address(), data);
};

const getAddress = () => {
  return api.getFunction(api.user_address());
};

const getAreaList = () => {
  return api.getFunction(api.area_list());
};

const updateAddress = data => {
  return api.patchFunction(data.url, data);
};

export { postAddress, getAddress, updateAddress, getAreaList };
