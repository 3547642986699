import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// redux
import { connect } from "react-redux";
import compose from "recompose/compose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Popper from "@material-ui/core/Popper";
import Badge from "@material-ui/core/Badge";
// core components
// import Button from "components/CustomButtons/Button.jsx";
// import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";

import customDropdownStyle from "assets/jss/material-kit-pro-react/components/customDropdownStyle.jsx";

class CustomDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
  }

  handleClick = (open) => {
    if(open){
      this.setState({ open: open});
    }
    else{
      this.setState(state => ({ open: !state.open }));
    }
    
  };
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleCloseMenu(param) {
    this.setState({ open: false });
    if (this.props && this.props.onClick) {
      this.props.onClick(param);
    }
  }
  render() {
    const { open } = this.state;
    const {
      classes,
      buttonText,
      buttonIcon,
      dropdownList,
      buttonProps,
      dropup,
      dropdownHeader,
      caret,
      hoverColor,
      dropPlacement,
      rtlActive,
      noLiPadding,
      innerDropDown,
      navDropdown,
      lastNoHover,
      badge,
      badgeCount,
      pooperLastItem,
      dropdownBG,
      direction,
      hoverYellow,
      categories_dropdown,
      categories_dropdown_menu,
      openOnHover,
      zoomOnHover,
      childDropdown,
      category_button
    } = this.props;
    const caretClasses = classNames({
      [classes.caret]: true,
      [classes.caretDropup]: dropup && !open,
      [classes.caretActive]: open && !dropup,
      [classes.caretRTL]: rtlActive
    });
    const dropdownItem = classNames({
      [classes.dropdownItem]: true,
      [classes[hoverColor + "Hover"]]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive
    });
    const dropDownMenuClasses = classNames({
      [classes.menuList]: true,
      [classes.categories_dropdown_menu]: categories_dropdown_menu? true: false,

    });
    const dropDownMenu = (
      <MenuList role="menu" className={classNames(dropDownMenuClasses)}>
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => this.handleCloseMenu(dropdownHeader)}
            className={classes.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {dropdownList.map((prop, key) => {
          if (prop.divider) {
            return (
              <Divider
                key={key}
                onClick={() => this.handleCloseMenu("divider")}
                className={classes.dropdownDividerItem}
              />
            );
          } else if (prop.ref === "multi") {
            return (
              <MenuItem
                key={key}
                className={dropdownItem}
                style={{ overflow: "visible", padding: 0 }}
              >
                {prop}
              </MenuItem>
            );
          } else if (lastNoHover && dropdownList.length === key + 1) {
            return (
              <MenuItem
                key={key}
                className={dropdownItem}
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none"
                }}
              >
                {prop}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={key}
              onClick={() => this.handleCloseMenu(prop)}
              className={dropdownItem}
            >
              {prop}
            </MenuItem>
          );
        })}
      </MenuList>
    );
    return (
      <div className={classNames(innerDropDown ? classes.innerManager : classes.manager)}>
        {badge && badgeCount !== null ? (
          <div className={buttonText !== undefined ? "" : classes.target}>
            <Button
              aria-label="Notifications"
              aria-owns={open ? "menu-list" : null}
              aria-haspopup="true"
              buttonRef={node => {
                this.anchorEl = node;
              }}
              {...buttonProps}
              onClick={this.handleClick}
            >
              {buttonIcon !== undefined ? (
                <Badge
                  badgeContent={badgeCount}
                  color="primary"
                  classes={{
                    badge: "bg-color-primaryyellow"
                  }}
                >
                  <this.props.buttonIcon
                    className={
                      hoverYellow
                        ? classNames(classes.buttonIcon, classes.yellowText)
                        : classes.buttonIcon
                    }
                  />
                </Badge>
              ) : null}
              <span className={hoverYellow ? classes.yellowText : ""}>
                {" "}
                {buttonText !== undefined ? buttonText : null}
                {caret ? <b className={caretClasses} /> : null}{" "}
              </span>
            </Button>
          </div>
        ) : (
          <div 
          className={classNames(buttonText !== undefined ? "" : classes.target , 
            category_button? "category_button":"", 
            zoomOnHover?" zoom ":"", 
            childDropdown?" w100 px-0 childButton":"" 
            )} 
          onMouseEnter={openOnHover? ()=>this.handleClick(true) : null}
          onMouseLeave={openOnHover? this.handleClose : null} 
          >
            <Button
              aria-label="Notifications"
              aria-owns={open ? "menu-list" : null}
              aria-haspopup="true"
              buttonRef={node => {
                this.anchorEl = node;
              }}
              {...buttonProps}
              onClick={this.handleClick}

            >
              {buttonIcon !== undefined ? (
                <this.props.buttonIcon
                  className={
                    hoverYellow
                      ? classNames(classes.buttonIcon, classes.yellowText)
                      : classes.buttonIcon
                  }
                />
              ) : null}
              <span className={hoverYellow ? classes.yellowText : ""}>
                {" "}
                {buttonText !== undefined ? buttonText : null}
                {caret ? <b className={caretClasses} /> : null}{" "}
              </span>
            </Button>
          </div>
        )}
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          //transition
          disablePortal
          onMouseLeave={openOnHover? this.handleClose : null}
          placement={dropPlacement}
          className={classNames({
            [classes.popperClose]: !open,
            [direction === "rtl"
              ? classes.pooperResponsiveAR
              : classes.pooperResponsive]: categories_dropdown? false: true,
            // [classes.pooperResponsive]: true,
            [classes.pooperNav]: open && navDropdown,
            [classes.dropdownResponsive]:  categories_dropdown? false: true,
            [classes.categories_dropdown]:  categories_dropdown? true: false,
            [classes.pooperResponsiveLastItem]: pooperLastItem
          },( categories_dropdown_menu?" scrollbar ":""))}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: "0 100% 0" }
                  : { transformOrigin: "0 0 0" }
              }
            >
              <Paper
                className={classNames({
                  [classes.dropdown]: true,
                  [classes.dropdownBG]: dropdownBG,
                }, (categories_dropdown_menu?" m-0 ":""))}
              >
                {innerDropDown ? (
                  dropDownMenu
                ) : (
                  <ClickAwayListener onClickAway={this.handleClose} ref="cacat">
                    {dropDownMenu}
                  </ClickAwayListener>
                )}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

CustomDropdown.defaultProps = {
  caret: true,
  dropup: false,
  hoverColor: "primary"
};

CustomDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "htPrimaryGreen",
    "htPrimaryLight"
  ]),
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.func,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    "bottom",
    "top",
    "right",
    "left",
    "bottom-start",
    "bottom-end",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "left-start",
    "left-end"
  ]),
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // This is a function that returns the clicked menu item
  onClick: PropTypes.func,
  badge: PropTypes.bool,
  badgeCount: PropTypes.number
};

const mapStateToProps = state => ({
  ...state.globalReducer
});

export default compose(
  withStyles(customDropdownStyle),
  connect(mapStateToProps)
)(CustomDropdown);
