import React from "react";
import classNames from "classnames";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
// React i18next
import { withTranslation } from "react-i18next";
// @material-ui/core components
import compose from "recompose/compose";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.jsx";
// kayfik components
import Tagline from "kayfik/views/Shop/Sections/Tagline.jsx";
import Facets from "kayfik/views/Shop/Sections/Facets.jsx";
import HorizontalProductGrid from "kayfik/views/Shop/Sections/HorizontalProductGrid.jsx";
import BreadCrumbs from "kayfik/components/BreadCrumbs/BreadCrumbs.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import Hidden from "@material-ui/core/Hidden";

class SectionProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isSm = () => {
    if (isWidthDown("sm", this.props.width) || this.props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };

  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type,
        openFacets: () => this.props.history.push("/facets")
      },
      modal_type
    );
  };

  navigateToFacets = () => {
    const { history, updateMobileFacetsURL } = this.props;
    updateMobileFacetsURL(window.location.href);
    history.push("/facets");
  };

  renderRows = () => {
    const { top_categories, t } = this.props;
    if (top_categories.length > 0) {
      return <HorizontalProductGrid />;
    } else {
      return <p>{t("buyingPage.sectionProducts.nodata")} </p>;
    }
  };

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classNames(classes.container, "shop-sectionProducts")}>
        <GridContainer>
        {!this.isSm()&&  <GridItem lg={3} md={3}>
            <Facets />
          </GridItem>}
          <GridItem lg={9} md={9} sm={12} className="px-0">
            <GridContainer>
              <GridItem xs={12} className="px-0">
                <Tagline text={t("buyingPage.tagline")} />
              </GridItem>
              <GridItem xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <BreadCrumbs
                    linkObjects={[
                      {
                        text: t("buyingPage.sectionProducts.home"),
                        url: "/"
                      },
                      {
                        text: t("buyingPage.sectionProducts.shop"),
                        url: "/shop"
                      }
                    ]}
                  />
                  <Hidden mdUp implementation="css">
                    <Button
                      color="secondaryLight"
                      onClick={this.navigateToFacets}
                    >
                      <i className="fa fa-sliders-h mr-2 " />{" "}
                      {t("buyingPage.sectionProducts.filter")}
                    </Button>
                  </Hidden>
                </div>
                {this.renderRows()}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  clearSearchUrl: () => {
    dispatch({
      type: ProductActionTypes.UPDATE_SEARCH_URL,
      setUrl: null
    });
  },
  updateMobileFacetsURL: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_MOBILE_FACET_URL,
      value
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.productReducer,
  ...state.bidReducer
});

export default compose(
  withTranslation(),
  withStyles(styles),
  withWidth(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SectionProducts);
