import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
// eslint-disable-next-line react/prop-types
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ModalActionTypes from "constants/modalConstants.jsx";
import FlagSelect from "kayfik/components/FlagSelect/FlagSelect.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import { postPhoneConfirmation } from "actions/accountActions.jsx";
import { patchPhoneNumber } from "actions/accountActions.jsx";
import { resetUnauthorized } from "utils/helpers.jsx";
import IconButton from "@material-ui/core/IconButton";

class VerifyPhoneModal extends React.Component {
  constructor(props) {
    super(props);
    const { userDetails } = this.props;
    this.state = {
      resendClicked: false,
      showCodeField: false,
      showCountdown: false,
      method: "sms",
      remainingTime: 0,
      phone: userDetails && userDetails.phone ? userDetails.phone : ""
    };
    this.props.resetReduxTimer();
  }

  resendConfirmationFunc = e => {
    e.preventDefault();
    const {
      resendConfirmationFetching,
      userDetails,
      language,
      showSnackbar,
      userDetailsReq,
      patchSuccess,
      patchFailed,
      t,
      showCodeInput
    } = this.props;
    const { method, phone } = this.state;

    if (phone.length > 5) {
      this.setState({
        resendClicked: true,
        showCodeField: true,
        showCountdown: true
      });

      if (userDetails && userDetails.phone !== phone) {
        let form_data = {
          phone: phone
        };
        resendConfirmationFetching(true);
        patchPhoneNumber(form_data)
          .then(ressponse => ressponse.data)
          .then(patch_data => {
            userDetailsReq();
            patchSuccess();
            let data = {
              phone: phone,
              channel: method,
              locale: language
            };
            postPhoneConfirmation(data)
              .then(ressponse => ressponse.data)
              .then(data => {
                showSnackbar(data.message, "success");
                this.showTimer();
                showCodeInput(true);
                resendConfirmationFetching(false);
              })
              .catch(e => {
                if (
                  e.response &&
                  e.response.status >= 400 &&
                  e.response.status < 500
                ) {
                  const res_object = e.response.data;
                  let messages = [];
                  for (let i in res_object) {
                    let error_val = res_object[i];
                    if (error_val) {
                      messages.push(error_val);
                    }
                  }
                  for (let i = 0; i < messages.length; i++) {
                    let txt = messages[i];
                    showSnackbar(txt, "warning");
                  }
                } else {
                  showSnackbar(t("common.error.network", "error"));
                }
                resendConfirmationFetching(false);
              });
          })
          .catch(e => {
            let error = e;

            if (
              e.response &&
              e.response.status >= 400 &&
              e.response.status < 500
            ) {
              if (e.response && e.response.status === 401) {
                resetUnauthorized();
                showSnackbar(t("unauthorizedAccess"), "error");
              } else {
                const res_object = e.response.data;
                let messages = [];

                for (let i in res_object) {
                  let error_val = res_object[i];
                  if (error_val) {
                    messages.push(error_val);
                  }
                }
                for (let i = 0; i < messages.length; i++) {
                  let txt = messages[i];
                  showSnackbar(txt, "warning");
                }
              }
            } else {
              let txt = t("common.error.network");
              showSnackbar(txt, "error");
            }
            patchFailed(error);
            resendConfirmationFetching(false);
          });
      } else {
        let data = {
          phone: phone,
          channel: method,
          locale: language
        };
        resendConfirmationFetching(true);
        postPhoneConfirmation(data)
          .then(ressponse => ressponse.data)
          .then(data => {
            showSnackbar(data.message, "success");
            this.showTimer();
            showCodeInput(true);
            resendConfirmationFetching(false);
          })
          .catch(e => {
            if (
              e.response &&
              e.response.status >= 400 &&
              e.response.status < 500
            ) {
              const res_object = e.response.data;
              let messages = [];
              for (let i in res_object) {
                let error_val = res_object[i];
                if (error_val) {
                  messages.push(error_val);
                }
              }
              for (let i = 0; i < messages.length; i++) {
                let txt = messages[i];
                showSnackbar(txt, "warning");
              }
            } else {
              showSnackbar(t("common.error.network", "error"));
            }
          });
        resendConfirmationFetching(false);
      }
    } else {
      showSnackbar(t("buyingPage.checkOut.verifyPhone.valid"), "error");
    }
  };

  componentWillReceiveProps = () => {
    const { phoneConfirmSent } = this.props;
    if (phoneConfirmSent && this.state.showCountdown) {
      this.showTimer();
    }
  };

  showTimer = () => {
    const { updateReduxTimer, t } = this.props;
    this.setState({ showCountdown: false });
    let timeleft = 60;
    this.downloadTimer = setInterval(function () {
      let element = document.getElementById("countdown");
      if (element) {
        element.innerHTML = t("buyingPage.checkOut.verifyPhone.resendMessage", {
          timeleft
        }); //"Resend after " + timeleft + " seconds";
        timeleft -= 1;
        updateReduxTimer(timeleft);
        if (timeleft < 0) {
          clearInterval(this.downloadTimer);
          element.innerHTML = "";
        }
      }
    }, 1000);
  };

  verifyCode = e => {
    const { verifyCode, resetReduxTimer } = this.props;
    const { phone } = this.state;
    e.preventDefault();
    let data = {
      phone: phone,
      code: e.target.code.value
    };
    clearInterval(this.downloadTimer);
    resetReduxTimer();
    verifyCode(data);
  };

  handleSelectedMethod = method => {
    this.setState({ method: method });
  };

  componentDidUpdate() {
    const { resetReduxTimer } = this.props;
    if (this.props.timerValue < 0) {
      clearInterval(this.downloadTimer);
      resetReduxTimer();
    }
  }
  componentWillUnmount() {
    const { showCodeInput } = this.props;
    showCodeInput(false);
  }


  handleOnChange = (value, data) => {
    const { userDetails } = this.props;
    let code = "+" + data.dialCode;
    if (value.indexOf(code) === 0) {
      this.setState({ phone: value });
    } else if (userDetails && data.dialCode !== userDetails.country_code) {
      this.setState({ phone: code });
    } else {
      this.setState({ phone: this.state.phone });
    }
  };

  getTitle = () => {
    const { modalProps, t } = this.props;
    if (modalProps) {
      switch (modalProps.verifyFrom) {
        case "checkout":
          return t("buyingPage.checkOut.verifyPhone.checkoutTitle");
        case "makeBid":
          return t("buyingPage.checkOut.verifyPhone.makeBidTitle");
        case "reqProd":
          return t("profileModal.accountSettings.reqProdTitlePhone");
        default:
          return t("buyingPage.checkOut.verifyPhone.listingTitle");
      }
    }
  }

  getText = () => {
    const { modalProps, t } = this.props;
    if (modalProps) {
      switch (modalProps.verifyFrom) {
        case "checkout":
          return t("buyingPage.checkOut.verifyPhone.checkout");
        case "makeBid":
          return t("buyingPage.checkOut.verifyPhone.makeBidText");
        case "reqProd":
          return t("profileModal.accountSettings.reqProdTextPhone");
        default:
          return t("buyingPage.checkOut.verifyPhone.listing");
      }
    }
  }



  render() {
    const {
      classes,
      closeModal,
      direction,
      t,
      account_fetching,
      combo_fetching,
      // modalProps
    } = this.props;
    const { method } = this.state;
    return (
      <div>
        {account_fetching || combo_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {this.getTitle()}
              </h2>
              <h4 className="m-0 mb-3">
                {this.getText()}
              </h4>
              <form onSubmit={this.resendConfirmationFunc} autoComplete="off">
                <h4 className="m-0 mb-2 dp-f jcsb">
                  <div className="dp-f alignItem w-100">
                    <FlagSelect
                      value={this.state.phone}
                      handleFlagOnChange={this.handleOnChange}
                    />
                    <CustomInput
                      labelText={t(
                        "profileModal.accountSettings.generalUser.phone"
                      )}
                      id="id_phone"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "phone",
                        required: true,
                        type: "text",
                        disableUnderline: true,
                        disabled: true
                      }}
                    />
                  </div>
                </h4>

                <FormControlLabel
                  control={
                    <Radio
                      checked={method === "sms"}
                      onChange={() => this.handleSelectedMethod("sms")}
                      value={"sms"}
                      name="radio button enabled"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={"SMS"}
                />
                {this.state.resendClicked === false ? null : (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={method === "call"}
                        onChange={() => this.handleSelectedMethod("call")}
                        value={"call"}
                        name="radio button enabled"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={"Call"}
                  />
                )}
                <div id="countdown" />
                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  fullWidth
                  disabled={
                    this.props.timerValue !== null && this.props.timerValue >= 0
                  }
                  type={"submit"}
                  className="mt-5"
                >
                  {this.state.showCode
                    ? t("buyingPage.checkOut.verifyPhone.resend")
                    : t("buyingPage.checkOut.verifyPhone.sendVerification")}
                </Button>
              </form>
              {this.props.showCode ||
                (this.props.timerValue !== null && this.props.timerValue >= 0) ? (
                  <form className="my-4" onSubmit={this.verifyCode}>
                    <h4 className="m-0 mb-3">
                      {t("buyingPage.checkOut.verifyPhone.codeMessage")}
                    </h4>
                    <CustomInput
                      labelText={t("buyingPage.checkOut.verifyPhone.code")}
                      id="id_code"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "code",
                        required: true,
                        type: "text",
                        disableUnderline: true
                      }}
                    />
                    <Button
                      color="primaryWhiteWithBorder"
                      size="lg"
                      fullWidth
                      type={"submit"}
                    >
                      {t("buyingPage.checkOut.verifyPhone.verify")}
                    </Button>
                  </form>
                ) : null}
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resendConfirmationFetching: fetching => {
    dispatch({
      type: AccountActionTypes.RESEND_PHONE_CONFIRMATION_FETCHING,
      fetching
    });
  },
  verifyCode: data => {
    dispatch({
      type: AccountActionTypes.VERIFY_PHONE_CODE_REQUEST,
      data
    });
  },
  updateReduxTimer: value => {
    dispatch({
      type: AccountActionTypes.UPDATE_TIMER,
      value
    });
  },
  showCodeInput: value => {
    dispatch({
      type: AccountActionTypes.SHOW_CODE_INPUT,
      value
    });
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  patchPhoneNumber: form_data => {
    dispatch({
      type: AccountActionTypes.PATCH_PHONE_REQUEST,
      payload: form_data
    });
  },
  resetReduxTimer: () => {
    dispatch({
      type: AccountActionTypes.RESET_TIMER
    });
  },
  userDetailsReq: () => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_REQUEST
    });
  },
  patchSuccess: () => {
    dispatch({
      type: AccountActionTypes.PATCH_PHONE_SUCCESS
    });
  },
  patchReq: () => {
    dispatch({
      type: AccountActionTypes.PATCH_PHONE_REQUEST
    });
  },
  patchFailed: error => {
    dispatch({
      type: AccountActionTypes.PATCH_PHONE_FAILED,
      error
    });
  },
  showSnackbar: (txt, variant = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

VerifyPhoneModal.propTypes = {
  resendConfirmation: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.accountReducer,
  ...state.comboProductReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(VerifyPhoneModal);
