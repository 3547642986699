import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import DropZone from "kayfik/components/CustomInput/dropZone.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
import Help from "@material-ui/icons/Help";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// React i18next
import { withTranslation, Trans } from "react-i18next";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import { decimalPlace, convertHindiToArabic , converToArabicString, isInputEnglish} from "utils/helpers.jsx";
import IconButton from "@material-ui/core/IconButton";



class ImagesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goBack: true,
      content: "",
      instantCashAvailable: false,
      showinstantCashSection: false
    };
  }

  componentDidMount() {
    const {
      is_buy_price,
      is_bid_price,
      updateBid,
      updateBuy,
      is_bid,
      is_buy,
      updateBidMessage,
      updateBuyMessage,
      direction
    } = this.props;

    if (is_buy_price) {
      let value = "";
      let valueAr = "";
      // value = this.calculateCommission(is_buy_price);
      value = is_buy_price;
      if(value){
        valueAr = converToArabicString(value);
      }

      updateBuy(is_buy, is_buy_price, value);

      let currency = direction === "rtl" ? "د.ك." : "KD";
      let amt = direction === "rtl" ? valueAr : value;

      updateBuyMessage(
        <Trans i18nKey="sellingPage.imageModal.youreceive">
          [
          <strong>
            {{ currency: currency }} {{ amount: amt }}
          </strong>
          ]
        </Trans>
      );
    }

    if (is_bid_price) {
      let value = "";
      let valueAr = "";
      // value = this.calculateCommission(is_bid_price);
      value = is_bid_price;
      if(value){
        valueAr =converToArabicString(value)
      }
      updateBid(is_bid, is_bid_price, value);
      let currency = direction === "rtl" ? "د.ك." : "KD";
      let amt = direction === "rtl" ? valueAr : value;

      updateBidMessage(
        <Trans i18nKey="sellingPage.imageModal.youreceive">
          [
          <strong>
            {{ currency: currency }} {{ amount: amt }}
          </strong>
          ]
        </Trans>
      );
    }

  }
  isInstantCashAvailable = () => {
    const { instantCashObj } = this.props;
    if (instantCashObj ) {
      this.setState({
        instantCashAvailable: true,
        showinstantCashSection: true
      })
    }
    else{
      this.setState({
        instantCashAvailable: false,
        showinstantCashSection: false
      })
    }
  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type,
        modalLarge:
          modal_type === "productPreviewModal" ||
            modal_type === "whatSellingModal"
            ? true
            : false,
        activeSell: modalProps.activeSell
      },
      modal_type
    );
  };

  handleChange = name => event => {
    const {
      updateBid,
      updateBuy,
      updateBuyMessage,
      updateBidMessage
    } = this.props;
    let message = "";

    if (name === "buy") {
      updateBuy(event.target.checked, "");
      if (!event.target.checked) {
        updateBuyMessage(message);
      }
    } else {
      updateBid(event.target.checked, "");
      if (!event.target.checked) {
        updateBidMessage(message);
      }
    }
  };


  formatNumber(num) {
    if (num && num.length > 0) {
      num = convertHindiToArabic(num);
      return num;
    } else {
      return '';
    }
  }



  handlePriceChange = e => {
    const {
      updateBid,
      updateBuy,
      is_bid,
      is_buy,
      direction,
      updateBidMessage,
      updateBuyMessage
    } = this.props;

    let value = "";
    // let valueAr = "";
    let targetValue = e.target.value;
    let lastChar = targetValue.substr(targetValue.length - 1);
    let afterDec = targetValue.split(".")[1];
    if(afterDec === undefined || afterDec === "" || afterDec.length<4){
    let isEng = isInputEnglish(targetValue);
    let targetValue1 = this.formatNumber(targetValue);
    let targetValueInt = parseFloat(targetValue1);
    if(lastChar === "." || lastChar ==="٫" ){
      targetValueInt = targetValueInt + "."
    }
    let displayPrice = targetValueInt? targetValueInt+"": "";
    if(displayPrice && !isEng ){      
      displayPrice = converToArabicString(displayPrice);
    }
    if (!isNaN(targetValueInt) || targetValue === "") {
      if (targetValue) {
        // value = this.calculateCommission(targetValueInt);
        value = targetValueInt;
      }
      if (e.target.name === "bid_price") {
        updateBid(is_bid, displayPrice, value);
        let message = "";
        if (value) {
          let currency = direction === "rtl" ? "د.ك." : "KD";
          let amt =  value;
          message = (
            <Trans i18nKey="sellingPage.imageModal.youreceive">
              [
              <strong>
                {{ currency: currency }} {{ amount: amt }}
              </strong>
              ]
            </Trans>
          );
        }
        updateBidMessage(message);
      } else {
        updateBuy(is_buy, displayPrice, value);
        let message = "";
        // eslint-disable-next-line no-undef

        let currency = direction === "rtl" ? "د.ك." : "KD";
        let amt = value;
        if (value) {
          message = (
            <Trans i18nKey="sellingPage.imageModal.youreceive">
              [
              <strong>
                {{ currency: currency }} {{ amount: amt }}
              </strong>
              ]
            </Trans>
          );
        }
        updateBuyMessage(message);
      }
    }}

  };

  calculateCommission = value => {
    const { selected_product_class, product_class_list } = this.props;

    let pc = product_class_list.find(p => p.id === selected_product_class.id);
    if (pc) {
      let flat_rate = parseFloat(pc.flat_rate);
      let percent_rate = parseFloat(pc.percentage_rate);

      let float_value = parseFloat(value);
      let partner_value = flat_rate;

      let current_percentage = (percent_rate / 100) * float_value;
      if (current_percentage > flat_rate) {
        partner_value = current_percentage;
      }
      float_value = float_value + partner_value;
      float_value = decimalPlace(float_value);
      return float_value;
    }
  };

  handleTextBoxChange = e => {
    const { updateDescriptionEN, updateDescriptionAR } = this.props;
    if (e.target.name === "description_en") {
      updateDescriptionEN(e.target.value);
    } else {
      updateDescriptionAR(e.target.value);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      is_bid,
      is_buy,
      is_bid_price,
      is_buy_price,
      showSnackbar,
      t,
      updateProductData,
      product_data,
      updateProductDataImages,
      description_en,
      description_ar,
      product_images,
      product_class_list,
      selected_product_class,
      previous_images,
      updateOtherProductdata,
      other_product_data
    } = this.props;

    let product_class_obj = product_class_list.find(
      pc => pc.id === selected_product_class.id
    );

    if (product_images.length <= 0 && previous_images.length <= 0) {
      let txt = t("sellingPage.imageModal.minImage");
      showSnackbar(txt, "warning");
      return;
    }

    if (!is_bid && !is_buy) {
      let txt = t("sellingPage.imageModal.selectAction");
      showSnackbar(txt, "warning");
      return;
    }

    if (
      (is_buy_price !== "" && is_buy_price <= 0) ||
      (is_bid_price !== "" && is_bid_price <= 0)
    ) {
      let txt = t("sellingPage.imageModal.zeroAmount");
      showSnackbar(txt, "warning");
      return;
    }

    // Check if the amount for both buy and bid reach the minimum for the product class
    if (
      is_buy &&
      parseFloat(is_buy_price) < parseFloat(product_class_obj.minimum_price)
    ) {
      let minimum_buy = product_class_obj.minimum_price.toString();
      let txt = t("sellingPage.imageModal.buyamount", { amount: minimum_buy });
      showSnackbar(txt, "warning");
      return;
    }
    if (
      is_bid &&
      parseFloat(is_bid_price) < parseFloat(product_class_obj.minimum_price)
    ) {
      let minimum_bid = product_class_obj.minimum_price.toString();
      let txt = t("sellingPage.imageModal.bidamount", { amount: minimum_bid });
      showSnackbar(txt, "warning");
      return;
    }

    // check if buynow price exist, make sure bid now is lower than that
    if (
      is_buy &&
      is_bid &&
      parseFloat(is_bid_price) >= parseFloat(is_buy_price)
    ) {
      let txt = t("sellingPage.imageModal.cannot");
      showSnackbar(txt, "warning");
      return;
    }

    let new_product_data = product_data.product_class
      ? { ...product_data }
      : { ...other_product_data };
    if (description_ar !== "") {
      new_product_data["description_ar"] = description_ar;
    }
    if (description_en !== "") {
      new_product_data["description_en"] = description_en;
    }
    new_product_data["is_bid"] = is_bid;
    if (is_bid_price !== "") {
      new_product_data["is_bid_price"] = this.calculateCommission(is_bid_price);
    }
    new_product_data["is_buy"] = is_buy;
    if (is_buy_price !== "") {
      new_product_data["is_buy_price"] = this.calculateCommission(is_buy_price);
    }
    if (product_data.product_class) {
      updateProductData(new_product_data);
    } else {
      updateOtherProductdata(new_product_data);
    }

    //update images for later post
    updateProductDataImages(product_images);
    this.openModal("productPreviewModal")();
  };

  backClickedFunc = () => {
    this.openModal("whatSellingModal")();
  };
  renderBack = () => {
    const { t, direction } = this.props;

    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="image_back" />
          {t("sellingPage.whatSellingModal.back")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="image_back" />
          {t("sellingPage.whatSellingModal.back")}
        </div>
      );
    }
  };

  clearComboAndClose = () => {
    const { closeModal, resetCombo } = this.props;
    resetCombo();
    closeModal();
  };

  priceInputFocus = sell_type => () => {
    const {
      updateBuy,
      updateBid,
      is_buy_price,
      is_buy_price_final,
      is_bid_price,
      is_bid_price_final
    } = this.props;
    if (sell_type === "buy") {
      updateBuy(true, is_buy_price, is_buy_price_final);
    } else {
      updateBid(true, is_bid_price, is_bid_price_final);
    }
  };

  getContents = () => {
    const {
      classes,
      is_buy,
      is_bid,
      is_bid_price,
      is_buy_price,
      description_ar,
      description_en,
      t,
      direction
    } = this.props;
      return (
        <>
          <h4 className="m-0 mb-3">
            {t("sellingPage.imageModal.priceDetails")}
          </h4>
          <GridContainer justify="center">
            <GridItem xs={12} md={10} className="px-0">
              <GridContainer alignItems="center">
                <GridItem xs={6}>
                  <FormControlLabel
                    className={
                      direction === "rtl" ? "toggleFixAR" : "toggleFix"
                    }
                    control={
                      <Switch
                        checked={is_buy}
                        onChange={this.handleChange("buy")}
                        value="buyNow"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon:
                            direction === "rtl"
                              ? classes.switchIconAR
                              : classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    labelPlacement="start"
                    classes={{
                      label:
                        direction === "rtl"
                          ? classes.labelSwitchAR
                          : classes.labelSwitch
                    }}
                    label={t("sellingPage.imageModal.buyNow")}
                  />
                </GridItem>
                <GridItem xs={6} className="d-flex align-items-center">
                  <CustomInput
                    labelText={t("sellingPage.imageModal.price")}
                    id="id_buy_price"
                    size="lg"
                    className="circle"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "buy_price",
                      required: is_buy,
                      type: "text",
                      value: is_buy_price,
                      step: 1,
                      disableUnderline: true,
                      onChange: this.handlePriceChange,
                      onFocus: this.priceInputFocus("buy")
                    }}
                  />
                  <Tooltip
                    id="tooltip-top"
                    title={t("sellingPage.imageModal.sellitem")}
                    placement="top"
                    enterTouchDelay={0}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Help className="icon-primarylight mx-2 helpIcon" />
                  </Tooltip>
                </GridItem>
                {/* {is_buy_price !== "" ? (
                  <GridContainer>
                    <GridItem xs={6} />
                    <GridItem
                      xs={6}
                      className="d-flex align-items-center"
                    >
                      <div className="mb-3 htPrimaryGreen">
                        <p className="dropdown-color">{buy_message}</p>
                      </div>
                    </GridItem>
                  </GridContainer>
                ) : null} */}
                <GridItem xs={6}>
                  <FormControlLabel
                    className={
                      direction === "rtl" ? "toggleFixAR" : "toggleFix"
                    }
                    control={
                      <Switch
                        checked={is_bid}
                        onChange={this.handleChange("bidNow")}
                        value="bidNow"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon:
                            direction === "rtl"
                              ? classes.switchIconAR
                              : classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    classes={{
                      label:
                        direction === "rtl"
                          ? classes.labelSwitchAR
                          : classes.labelSwitch
                    }}
                    labelPlacement="start"
                    label={t("sellingPage.imageModal.bitNow")}
                  />
                </GridItem>
                <GridItem xs={6} className="d-flex align-items-center">
                  <CustomInput
                    labelText={t("sellingPage.imageModal.price")}
                    id="id_bid_price"
                    size="lg"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "bid_price",
                      required: is_bid,
                      value: is_bid_price,
                      type: "text",
                      step: 1,
                      onChange: this.handlePriceChange,
                      onFocus: this.priceInputFocus("bid"),
                      disableUnderline: true
                    }}
                  />
                  <Tooltip
                    id="tooltip-top"
                    title={t("sellingPage.imageModal.receivingbids")}
                    placement="top"
                    enterTouchDelay={0}
                    classes={{ tooltip: classNames(classes.tooltip) }}
                  >
                    <Help className="icon-primarylight mx-2 helpIcon" />
                  </Tooltip>
                </GridItem>
                {/* {is_bid_price !== "" ? (
                  <GridContainer>
                    <GridItem xs={6} />
                    <GridItem
                      xs={6}
                      className="d-flex align-items-center"
                    >
                      <div className="mb-3 htPrimaryGreen">
                        <p className="dropdown-color">{bid_message}</p>
                      </div>
                    </GridItem>
                  </GridContainer>
                ) : null} */}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <h4 className="m-0 mb-3">
            {t("sellingPage.imageModal.subDetails")}
          </h4>
          <CustomInput
            labelText={t("sellingPage.imageModal.english")}
            id="id_description_en"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: description_en,
              name: "description_en",
              required: false,
              type: "text",
              disableUnderline: true,
              multiline: true,
              rows: 3,
              onChange: this.handleTextBoxChange
            }}
          />
          <CustomInput
            labelText={t("sellingPage.imageModal.arabic")}
            id="id_description_ar"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: description_ar,
              name: "description_ar",
              required: false,
              type: "text",
              disableUnderline: true,
              multiline: true,
              rows: 3,
              onChange: this.handleTextBoxChange
            }}
          />

          <GridContainer justify="center">
            <GridItem xs={10}>
              <Button
                color="primaryWhiteWithBorder"
                size="lg"
                className="mb-3"
                fullWidth
                type="submit"
              >
                {t("sellingPage.imageModal.continue")}
              </Button>
              <Button
                link
                size="lg"
                className="mb-3"
                fullWidth
                onClick={() => {
                  if (
                    window.confirm(
                      t("sellingPage.imageModal.lossingImage")
                    )
                  )
                    this.openModal("whatSellingModal")();
                }}
              >
                {this.renderBack()}
              </Button>
            </GridItem>
          </GridContainer>

        </>
      )
    
  }

  render() {
    const {
      classes,
      t,
      direction
    } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              simple="true"
              className={classNames(
                classes.modalCloseButton,
                "previewClose",
                direction === "rtl" ? classes.modalSmallCloseButtonAR : null
              )}
              key="close"
              aria-label="Close"
              onClick={this.clearComboAndClose}
            >
              {" "}
              <Close
                className={classNames(classes.modalClose, classes.closeStyle)}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer className="mb-5 scrollbar">
              <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
                <Button
                  link
                  size="lg"
                  className={direction === "rtl" ? "pr-0 mb-3" : "pl-0 mb-3"}
                  onClick={() => {
                    if (
                      window.confirm(t("sellingPage.imageModal.lossingImage"))
                    )
                      this.openModal("whatSellingModal")();
                  }}
                >
                  {this.renderBack()}
                </Button>
                <h2 className={classes.modalTitle}>
                  {t("sellingPage.imageModal.photos")}
                </h2>
                <h4 className="m-0 mb-3">
                  {t("sellingPage.imageModal.recommend")}
                </h4>
                <DropZone
                  inputProps={{
                    name: "images"
                  }}
                />
                {this.getContents()}
              </GridItem>
            </GridContainer>
          </DialogContent>
        </div>
      </form>
    );
  }
}

ImagesModal.propTypes = {
  is_buy: PropTypes.bool,
  is_bid: PropTypes.bool,
  modalProps: PropTypes.object,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  updateBid: PropTypes.func,
  updateBuy: PropTypes.func,
  classes: PropTypes.object,
  is_bid_price: PropTypes.string,
  is_buy_price: PropTypes.string,
  t: PropTypes.func,
  updateDescriptionEN: PropTypes.func,
  updateDescriptionAR: PropTypes.func,
  showSnackbar: PropTypes.func,
  updateProductData: PropTypes.func,
  product_data: PropTypes.object,
  description_ar: PropTypes.string,
  description_en: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updateBid: (is_bid, is_bid_price, is_bid_price_final = "") => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_BID_DATA,
      is_bid,
      is_bid_price,
      is_bid_price_final
    });
  },
  updateBuy: (is_buy, is_buy_price, is_buy_price_final = "") => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_BUY_DATA,
      is_buy,
      is_buy_price,
      is_buy_price_final
    });
  },
  updateDescriptionAR: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_DESCRIPTION_AR,
      data
    });
  },
  updateDescriptionEN: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_DESCRIPTION_EN,
      data
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  updateProductData: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PRODUCT_DATA,
      data
    });
  },
  updateProductImages: files => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PRODUCT_IMAGES,
      files
    });
  },
  updateProductDataImages: files => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PRODUCT_DATA_IMAGES,
      files
    });
  },
  updateBidMessage: message => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_BID_MESSAGE,
      message
    });
  },
  updateBuyMessage: message => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_BUY_MESSAGE,
      message
    });
  },
  resetCombo: () => {
    dispatch({
      type: ComboProductActionTypes.RESET_COMBO
    });
  },
  update_pickup_time: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PICKUP_TIME,
      data
    });
  },
  updateOtherProductdata: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_OTHER_PRODUCT_DATA,
      data
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.comboProductReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ImagesModal);
