import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  CHANGE_LANGUAGE_REQUEST: null,
  CHANGE_LANGUAGE_SUCCESS: null,

  CMS_PAGE_REQUEST: null,
  CMS_PAGE_SUCCESS: null,
  CMS_PAGE_FAILED: null,

  CHANGE_DIRECTION_SUCCESS: null,
  CHANGE_DIRECTION_REQUEST: null,

  POST_SEND_MESSAGE_REQUEST: null,
  POST_SEND_MESSAGE_SUCCESS: null,
  POST_SEND_MESSAGE_FAILED: null,

  POST_SUBSCRIBE_REQUEST: null,
  POST_SUBSCRIBE_SUCCESS: null,
  POST_SUBSCRIBE_FAILED: null,

  DYNAMIC_HISTORY_PUSH: null,
  UPDATE_DEVICE_ID: null,
  UPDATE_WEB_PUSH: null,

  GET_TIMEWINDOW_REQUEST: null,
  GET_TIMEWINDOW_SUCCESS: null,
  GET_TIMEWINDOW_FAILED: null,

  UPDATE_MOBILE_DROPDOWN: null,
  RESET_GO_TO: null,
  UPDATE_GO_TO: null,
  UPDATE_GRID_VIDEO_URL: null,
  UPDATE_CATEGORIES: null,
  UPDATE_BANNER_VIDEO_URL: null
});

export default ActionTypes;
