import {
  htSecondaryLight,
  htPrimaryLight,
  htPrimaryGrayColor
} from "kayfik/assets/jss/universal.jsx";

const dropZoneStyle = {
  dropZoneContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: htPrimaryLight,
    borderStyle: "dashed",
    backgroundColor: htSecondaryLight,
    color: htPrimaryGrayColor,
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "20rem",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "1rem",
    "&:focus, &:hover": {
      borderColor: htPrimaryLight
    }
  },
  thumb: {
    display: "flex",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: htPrimaryLight,
    borderStyle: "dashed",
    height: "8rem",
    padding: "1rem",
    justifyContent: "center",
    alignItems: "center"
  },
  imgFluid: {
    maxWidth: "100%",
    height: "100%",
    objectFit: "cover"
  },
  formInputStyle: {
    padding: ".375rem .75rem",
    display: "block",
    fontSize: "0.9rem",
    backgroundColor: htSecondaryLight,
    fontWeight: 400,
    color: "grey",
    opacity: " 0.65 !important"
  },
  dropZoneTextContainer:{
    fontWeight: 400
  }
};

export default dropZoneStyle;
