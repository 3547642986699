import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
// @material-ui/icons
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class FlagSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ReactPhoneInput
        country={"kw"}
        onlyCountries={["kw"]}
        value={this.props.value}
        onChange={this.props.handleFlagOnChange}
        inputExtraProps={{
          required: true,
          name: "flagSelect",
          autoComplete: "off",
        }}
        countryCodeEditable={false}
        enableSearchField={true}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default FlagSelect;
