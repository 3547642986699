import React from "react";
import api from "utils/api.jsx";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Muted from "components/Typography/Muted.jsx";
import queryString from "query-string";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import {
  postActionNotificationClicked,
  showStatusMessage
} from "utils/helpers.jsx";
import Badge from "components/Badge/Badge.jsx";
import { decimalPlace } from "utils/helpers.jsx";
class ProductDetail extends React.Component {
  componentDidMount() {
    this.renderSpecificProduct();
    this.props.getTimeWindows("pickup");
  }

  renderSpecificProduct = () => {
    let params = this.getExtraParameter();
    if (params.productId) {
      this.showProductDetail(params.productId)();
    }
  };
  deleteItem = deleteUrl => {
    const { deletePartnerProduct, t } = this.props;
    if (
      window.confirm(
        t(
          "profileModal.accountSettings.shippingAddress.productDetailPage.deleteItem"
        )
      )
    ) {
      deletePartnerProduct(deleteUrl);
    }
  };

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);

    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }
    return params;
  };

  showProductDetail = id => () => {
    const { getProductDetail} = this.props;
    const detailUrl = api.root + "partner/product/" + id + "/";
    getProductDetail(detailUrl);
  };
  renderBack = () => {
    const { t, direction } = this.props;

    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="image_back" />
          {t("profileModal.products.backtoList")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="image_back" />
          {t("profileModal.products.backtoList")}
        </div>
      );
    }
  };
  calculateCommission = product => {
    let pc = null;
    if (product && product.product_class) {
      pc = product.product_class;
    }
    if (pc) {
      let flat_rate = parseFloat(pc.flat_rate);
      let percent_rate = parseFloat(pc.percentage_rate);

      let float_value = parseFloat(product.price.incl_tax);
      let partner_value = flat_rate;
      let current_percentage = (percent_rate / 100) * float_value;
      
      if (current_percentage > flat_rate) {
        partner_value = current_percentage;
        let orgPrice=(float_value*100)/(100+percent_rate);
        return decimalPlace(orgPrice);
      }

      float_value = float_value - partner_value;
      float_value = decimalPlace(float_value);
      return float_value;
    }
  };
  actionClicked = (url, detailUrl) => () => {
    const {
      postActionClicked,
      pickup_time,
      showSnackbar,
      t,
      showModal,
      updateAddressEvent,
      partner_address
    } = this.props;

    if (url.includes("confirm")) {
      if (pickup_time) {
        let pickup_id = pickup_time.id;
        url = url + "?pickuptime=" + pickup_id;
        if (
          !partner_address ||
          Object.keys(partner_address.address).length === 0
        ) {
          const modalProps = {
            title: "mapModal",
            open: true,
            address: "pickup",
            confirm_pickup_data: {
              payload: url,
              detailUrl: detailUrl
            }
          };
          const modalType = "mapModal";
          let event = "loggedin_partner";
          showSnackbar(t("companySignup.companyDetailOne.fillup"), "warning");
          showModal(modalProps, modalType);
          updateAddressEvent(event);
        } else {
          postActionClicked(url, detailUrl, false);
        }
      } else {
        let txt = t(
          "profileModal.accountSettings.shippingAddress.productDetailPage.pickupTimewarning"
        );
        showSnackbar(txt, "warning");
        return;
      }
    } else {
      postActionClicked(url, detailUrl, true);
    }
  };
  handlePickupChange = value => {
    const { update_pickup_time } = this.props;
    update_pickup_time(value);
  };

  renderAction = () => {
    const { partner_product_detail, t, pickup_time, timeWindows } = this.props;
    if (partner_product_detail) {
      return (
        <div>
          {partner_product_detail &&
          partner_product_detail.is_ordered &&
          partner_product_detail.is_ordered.action_required ? (
            <div className="mt-3">
              <GridContainer>
                <GridItem xs={12}>
                  <SelectAutocomplete
                    maxMenuHeight={120}
                    inputId="select pickuptime"
                    value={pickup_time ? pickup_time : ""}
                    inputProps={{
                      required: true,
                      name: "pickuptime"
                    }}
                    placeholder={t(
                      "profileModal.accountSettings.shippingAddress.productDetailPage.select"
                    )}
                    labelText={t(
                      "profileModal.accountSettings.shippingAddress.productDetailPage.pickup_time"
                    )}
                    controlProps={{ marginBottom: "15px" }}
                    autocompleteOptions={timeWindows ? timeWindows : []}
                    selectOnChange={this.handlePickupChange}
                    getOptionLabel={({ timefrom, timeto, name }) =>
                      timefrom && timeto ? timefrom + " - " + timeto : name
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} className="d-flex">
                  <Button
                    color="primaryWhiteWithBorder"
                    fullWidth
                    className="unavailable"
                    size="lg"
                    onClick={this.actionClicked(
                      partner_product_detail.is_ordered.confirm_pickup,
                      partner_product_detail.url
                    )}
                  >
                    {t(
                      "profileModal.accountSettings.shippingAddress.productDetailPage.pickup"
                    )}
                  </Button>
                  <Button
                    color="primaryWhiteWithBorder"
                    fullWidth
                    className="unavailable"
                    size="lg"
                    onClick={this.actionClicked(
                      partner_product_detail.is_ordered.reject_pickup,
                      partner_product_detail.url
                    )}
                  >
                    {t(
                      "profileModal.accountSettings.shippingAddress.productDetailPage.unavailable"
                    )}
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  partnerProductDetail = () => {
    const {
      classes,
      partner_product_detail,
      vendor_fetching,
      t,
      direction,
      updateAccountPageComponent,
      auction_notification_count
    } = this.props;

    let listed_item_count =
      auction_notification_count && auction_notification_count.listed_items
        ? auction_notification_count.listed_items
        : null;

    if (listed_item_count && listed_item_count.count > 0) {
      postActionNotificationClicked(listed_item_count.url_to_read);
    }
    let product_status = null;
    if (
      partner_product_detail &&
      partner_product_detail.is_ordered &&
      partner_product_detail.is_ordered.status
    ) {
      product_status = showStatusMessage(
        partner_product_detail.is_ordered.status,
        "seller"
      );
    } else if (
      partner_product_detail &&
      partner_product_detail.product_status
    ) {
      product_status = showStatusMessage(
        partner_product_detail.product_status,
        "seller",
        partner_product_detail.action_url,
      );
    }
    if (partner_product_detail !== null) {
      return (
        <div>
          <Button
            link
            size="lg"
            onClick={() => updateAccountPageComponent("productlist")}
            className={direction === "rtl" ? "pr-0" : "pl-0"}
          >
            {this.renderBack()}
          </Button>
          <Card
            bordered
            className={
              product_status && product_status.color
                ? "pointer listed_item  cardOverflowVisible"
                : "pointer cardOverflowVisible"
            }
            style={
              product_status && product_status.color
                ? {
                    borderColor: product_status ? product_status.color : null
                  }
                : null
            }
          >
            {vendor_fetching ? (
              <Hidden smDown implementation="css">
                <Loader />
              </Hidden>
            ) : null}
            <CardBody>
              <div className="d-flex justify-content-center">
                {partner_product_detail.images ? (
                  <div className={classes.bidDetailContainer}>
                    <img
                      src={
                        partner_product_detail &&
                        partner_product_detail.images.length &&
                        partner_product_detail.images[0].url
                      }
                      className={classes.img}
                      alt={partner_product_detail.title}
                    />
                  </div>
                ) : null}
              </div>
              <div className="d-flex justify-content-between">
                <div className="product-title-width">
                  <h5>{partner_product_detail.title}</h5>
                </div>
                <div>
                  {product_status && product_status.message ? (
                    <Badge
                      classes={{
                        badge: "productBadge font11 p-0 status-badge"
                      }}
                    >
                      <span
                        style={{ backgroundColor: product_status.color }}
                        className="order_status"
                      >
                        {product_status.message}
                      </span>
                    </Badge>
                  ) : null}
                </div>
              </div>
              <h5 className="mb-1">
                <Muted>
                  {partner_product_detail.price.currency}{" "}
                  {/* {partner_product_detail.price.incl_tax} */}
                  {this.calculateCommission(partner_product_detail)}{" "} 
                </Muted>
              </h5>

              <Muted>
                <p>{partner_product_detail.description}</p>
              </Muted>
              {partner_product_detail.delete_product_url !== null ? (
                <div className={"dp-f jcfe w-100"}>
                  <Button
                    color="primaryWhiteWithBorder"
                    size="sm"
                    onClick={() =>
                      this.deleteItem(partner_product_detail.delete_product_url)
                    }
                  >
                    {t(
                      "profileModal.accountSettings.shippingAddress.productDetailPage.delete"
                    )}
                  </Button>
                </div>
              ) : null}
              {this.renderAction()}
            </CardBody>
          </Card>
        </div>
      );
    } else {
      return (
        <div>
          {vendor_fetching ? (
            <Hidden smDown implementation="css">
              <Loader />
            </Hidden>
          ) : null}
          <p>
            {t(
              "profileModal.accountSettings.shippingAddress.productDetailPage.data"
            )}
          </p>
        </div>
      );
    }
  };

  render() {
    return this.partnerProductDetail();
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getProductDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  postActionClicked: (url, detailUrl, unavailable) => {
    dispatch({
      type: VendorProductActionTypes.POST_PICKUP_ACTION_REQUEST,
      payload: url,
      detailUrl: detailUrl,
      unavailable:unavailable
    });
  },
  deletePartnerProduct: url => {
    dispatch({
      type: VendorProductActionTypes.DELETE_PARTNER_PRODUCT_REQUEST,
      url
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  update_pickup_time: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PICKUP_TIME,
      data
    });
  },
  showSnackbar: (txt, variant = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  getTimeWindows: window => {
    dispatch({
      type: GlobalActionTypes.GET_TIMEWINDOW_REQUEST,
      window
    });
  },
  updateAddressEvent: event => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT,
      event
    });
  }
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer,
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.comboProductReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductDetail);
