import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.jsx";
import { cardTitle } from "assets/jss/material-kit-pro-react.jsx";

import { htPrimaryLight } from "kayfik/assets/jss/universal.jsx";

const horizontalStyle = theme => ({
  ...styles,
  inputAdornmentLeft: {
    marginLeft: "15px",
    marginRight: "0",
    position: "relative"
  },
  svgIcon: {
    width: "22px",
    height: "22px",
    
    verticalAlign: "middle",
    color: htPrimaryLight
  },

  fontAwesomeIcon: {
    width: "100%",
    height: "100%",
    fontSize: "20px",
    display: "flex",
    color: htPrimaryLight
  },
  prodGridImage: {
    borderRadius: "0"
  },
  cardTitle: {
    ...cardTitle,
    // textAlign: "center",
    marginBottom: "0px !important"
  },
  fontSize: {
    fontSize: "0.7rem!important"
  },
  indicator: {
    color: htPrimaryLight
  },
  mB0:{
    marginBottom: "0 !important"
  }
});

export default horizontalStyle;
