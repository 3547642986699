import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Header from "kayfik/components/Header/Header.jsx";
// kayfik components
import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.jsx";
import KAYFIK_LOGO from "kayfik/assets/images/KAYFIK_LOGO.jpg";
import KAYFIK_LOGO_AR from "kayfik/assets/images/kayfik_logo_arabic.jpg";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
import { withTranslation } from "react-i18next";
import Loader from "kayfik/components/Loader/loader.jsx";

class PageNotFound extends React.Component {
  render() {
    const { classes, global_fetching, t, language } = this.props;
    return (
      <div>
        {global_fetching ? <Loader fixed /> : null}
        <Header />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + (language && language === 'ar'?KAYFIK_LOGO_AR :KAYFIK_LOGO) + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.contentCenter}>
            <GridContainer>
              <GridItem md={12}>
                <h1 className={classes.title}>{t("pageNotFound.number")}</h1>
                <h2 className={classes.subTitle}>
                  {t("pageNotFound.message")}
                </h2>
                <h4 className={classes.description}>
                  {t("pageNotFound.error")}
                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <BottomBar />
        <CustomFooter />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  ...state.globalReducer
});

export default compose(
  withStyles(errorPageStyle),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PageNotFound);
