import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import ResponsivePlayer from "kayfik/components/VideoPlayer/ResponsivePlayer.jsx";
//  core component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import IconButton from "@material-ui/core/IconButton";

class VideoModal extends React.Component {
  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };
  render() {
    const { classes, closeModal, direction, modalProps } = this.props;
    let path_url = modalProps.url;
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classNames(classes.modalHeader, "p-3")}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalBuyClose,
              "previewClose",
              direction === "rtl" ? classes.modalBuySmallAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBuy}
        >
          <ResponsivePlayer url={path_url} />
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  }
});

VideoModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(VideoModal);
