import React from "react";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import { deleteItem } from "actions/cartActions.jsx";
import { instantCashProductList, patchReqProds } from "actions/accountActions.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import * as Sentry from '@sentry/browser';
import Muted from "components/Typography/Muted.jsx";
class ProductReqDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reqQuantity: "",
      startDate: "",
      endDate: "",
      showSave: false
    };
  }
  componentDidMount() {
    const { reqProdDetail } = this.props;
    if (reqProdDetail.start_date && reqProdDetail.end_date) {
      let sd = reqProdDetail.start_date.slice(0, 10);
      let ed = reqProdDetail.end_date.slice(0, 10);
      this.setState({ startDate: sd, endDate: ed, reqQuantity: reqProdDetail.quantity });
    }
  }

  getReqProds = () => {
    const { updateReqProdList } = this.props;
    instantCashProductList()
      .then(res => {
        let data = res.data;
        if (data) {
          updateReqProdList(data);
        }
      })
  }

  deleteItem = deleteUrl => {
    const { t, showSnackbar, updateAccountPageComponent } = this.props;
    if (
      window.confirm(
        t(
          "requestProducts.deleteConfirm"
        )
      )
    ) {
      deleteItem(deleteUrl)
        .then(res => {
          if (res.status === 204) {
            let txt = t("requestProducts.deleteSuccess");
            showSnackbar(txt, "success");
            this.getReqProds();
            updateAccountPageComponent("productRequests");
          }
        })
        .catch(e => {
          if (
            e &&
            e.response.status === 403 &&
            e.response.data &&
            e.response.data.message
          ) {
            let msg = e.response.data.message;
            let txt = msg;
            showSnackbar(txt, "warning");
          }
          console.log(e.response, e.response);
        });
    }
  };
  renderBack = () => {
    const { t, direction } = this.props;

    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="image_back" />
          {t("profileModal.products.backtoList")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="image_back" />
          {t("profileModal.products.backtoList")}
        </div>
      );
    }
  };


  renderStartEndDate = () => {
    const {
      t
    } = this.props;;
    return (
      <>
        <GridContainer>
          <GridItem md={6} xs={12}>
            <FormControl className="datepicker reqProdDateInput start">
              <Datetime
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={this.state.startDate}
                isValidDate={this.validDate}
                inputProps={{
                  placeholder: t("requestProducts.startDate"),
                }}
                onChange={(date) => this.onChangeDate(date, "start")}
              />
            </FormControl>
            <CustomInput
              labelText={t("requestProducts.startDate")}
              id="startDate"
              size="lg"
              formControlProps={{
                fullWidth: true,
              }}
              className="reqProdDateInput "
              inputProps={{
                name: "startDate",
                required: true,
                type: "text",
                disabled: true,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem md={6} xs={12}>
            <FormControl className="datepicker reqProdDateInput end">
              <Datetime
                dateFormat="DD/MM/YYYY"
                value={this.state.endDate}
                timeFormat={false}
                isValidDate={this.validDate}
                inputProps={{
                  placeholder: t("requestProducts.endDate"),
                }}
                onChange={(date) => this.onChangeDate(date, "end")}
              />
            </FormControl>
            <CustomInput
              labelText={t("requestProducts.endDate")}
              id="endDate"
              size="lg"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "endDate",
                required: true,
                type: "text",
                disabled: true,
                disableUnderline: true
              }}
            />
          </GridItem>
        </GridContainer>
      </>
    )

  };
  onChangeDate = (date, type) => {
    if (typeof (date) === 'string') {
      this.setState({ invalidDate: true, showSave: true });
    }
    else {
      let newDate = new Date(date)
      let dt = newDate.getDate();

      if (dt < 10) {
        dt = "0" + dt;
        // dt = parseInt(dt);
      }
      let mnt = newDate.getMonth() + 1;
      if (mnt < 10) {
        mnt = "0" + mnt;
      }
      let yr = newDate.getFullYear();
      if (type === "start") {
        this.setState({ startDate: dt + "/" + mnt + "/" + yr, showSave: true })
      }
      else {
        this.setState({ endDate: dt + "/" + mnt + "/" + yr, showSave: true })
      }
      this.setState({ invalidDate: false, showSave: true });
    }
  }

  saveChanges = () => {
    const { showSnackbar, t } = this.props;
    let datatoPost = {};
    if (!this.state.invalidDate) {
      if (this.state.startDate && this.state.endDate && this.state.reqQuantity) {
        datatoPost["start_date"] = this.state.startDate;
        datatoPost["end_date"] = this.state.endDate;
        datatoPost["quantity"] = this.state.reqQuantity;
        this.patchData(datatoPost);
      }
    }
    else {
      let txt = t("requestProducts.validDates");
      showSnackbar(txt, "warning");
    }
  }

  showError = (e) => {
    const { showSnackbar, t } = this.props;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        showSnackbar(txt, "warning");
      }
    } else {
      Sentry.captureException(e);
      let txt = t("common.error.network");
      showSnackbar(txt, "error");
    }
  }

  patchData = (datatoPost) => {
    const { reqProdDetail, showSnackbar, t } = this.props;
    let form_data = {
      "quantity": datatoPost.quantity,
      "start_date": datatoPost.start_date,
      "end_date": datatoPost.end_date
    }
    patchReqProds(reqProdDetail.url, form_data)
      .then(res => {
        let data = res.data;
        if (data) {
          let txt = t("requestProducts.reqUpdateSuccess");
          showSnackbar(txt, "success");
          this.getReqProds();
        }
      })
      .catch(e => {
        this.showError(e);
        console.log("e.repsonse", e.response)
      })
  }
  validDate = (current) => {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  reqProductDetail = () => {
    const { reqProdDetail, updateAccountPageComponent, direction, t,language} = this.props;
    if (reqProdDetail) {
      return (
        <>
          <Button
            link
            size="lg"
            onClick={() => updateAccountPageComponent("productRequests")}
            className={direction === "rtl" ? "pr-0" : "pl-0"}
          >
            {this.renderBack()}
          </Button>
          <Card
            className="cardHeightDetail overflowVisible"
            bordered
          >
            <CardBody className="p-0">
              <div className={"p-3"}>
                <GridContainer>
                  <GridItem xs={12}>
                    <h5 className="mb-0">
                      {" "}
                      {reqProdDetail.product_class} - {reqProdDetail.name}{" "}
                    </h5>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={6} className="dp-f align-items-center ">
                    <h5 className="request-item-price">
                    <span className={language === "en"?"request-price-view-en":"request-price-view-ar"}>{t("buyingPage.shopFacets.price")}:</span>
                        <Muted>
                          {" "}{reqProdDetail.currency}{" "}{reqProdDetail.price}
                        </Muted>
                    </h5>
                  </GridItem>
                </GridContainer>
                {this.renderStartEndDate()}
                <GridContainer>
                  <GridItem
                    xs={6}
                    className="dp-f align-items-center prodDetailQty"
                  >
                    <h5 className="m-0">
                      {" "}
                      {t("homePage.header.cartLinks.quantity")}:{" "}
                    </h5>
                    <div className="dp-f jcc align-items-center mx-2">
                      <Button
                        round
                        justIcon
                        color="primaryWhiteWithBorder"
                        className="m-0 minWidth p-0"
                        size="sm"
                        onClick={() =>
                          this.setState({
                            reqQuantity: this.state.reqQuantity - 1,
                            showSave: true
                          })
                        }
                      >
                        <Remove className="w-100" />
                      </Button>
                      <input
                        value={this.state.reqQuantity}
                        type="number"
                        onChange={e =>
                          this.setState({
                            reqQuantity: e.target.value,
                            showSave: true
                          })
                        }
                      />
                      <Button
                        round
                        justIcon
                        color="primaryWhiteWithBorder"
                        className="m-0 minWidth p-0"
                        size="sm"
                        onClick={() =>
                          this.setState({
                            reqQuantity: this.state.reqQuantity + 1,
                            showSave: true
                          })
                        }
                      >
                        <Add className="w-100"/>
                      </Button>

                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="my-3">
                  <GridItem xs={6} className="dp-f align-items-center">
                    {this.state.showSave ? (
                      <div className={"dp-f jcfs w-100"}>
                        <Button
                          color="primaryWhiteWithBorder"
                          size="sm"
                          onClick={this.saveChanges}
                        >
                          {t("requestProducts.save")}
                        </Button>
                      </div>
                    ) : null}
                  </GridItem>
                  <GridItem xs={6} className="dp-f align-items-center">
                    {reqProdDetail.url !== null ? (
                      <div className={"dp-f jcfe w-100"}>
                        <Button
                          color="primaryWhiteWithBorder"
                          size="sm"
                          onClick={() =>
                            this.deleteItem(reqProdDetail.url)
                          }
                        >
                          {t(
                            "profileModal.accountSettings.shippingAddress.productDetailPage.delete"
                          )}
                        </Button>
                      </div>
                    ) : null}
                  </GridItem>
                </GridContainer>

              </div>
            </CardBody>
          </Card>
        </>
      )
    }
    else {
      return t("requestProducts.notAvailable");
    }
  }

  render() {
    return this.reqProductDetail();
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getProductDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  postActionClicked: (url, detailUrl) => {
    dispatch({
      type: VendorProductActionTypes.POST_PICKUP_ACTION_REQUEST,
      payload: url,
      detailUrl: detailUrl
    });
  },
  deletePartnerProduct: url => {
    dispatch({
      type: VendorProductActionTypes.DELETE_PARTNER_PRODUCT_REQUEST,
      url
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  update_pickup_time: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PICKUP_TIME,
      data
    });
  },
  showSnackbar: (txt, variant = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  getTimeWindows: window => {
    dispatch({
      type: GlobalActionTypes.GET_TIMEWINDOW_REQUEST,
      window
    });
  },
  updateAddressEvent: event => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT,
      event
    });
  },
  updateReqProdList: reqProductList => {
    dispatch({
      type: AccountActionTypes.REQ_PROD_LIST_UPDATE,
      reqProductList
    });
  },
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer,
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.comboProductReducer,
  ...state.accountReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductReqDetail);
