import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import styles from "kayfik/assets/jss/views/Shop/Sections/HorizontalProductGridStyle.jsx";
//action
import ProductActionTypes from "constants/productConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import SharePopOver from "kayfik/components/SharePopOver/sharePopOver.jsx";
import Badge from "components/Badge/Badge.jsx";
import company_icon from "kayfik/assets/images/company_icon.png";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
class Products extends React.Component {
  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    const success_modal_type = "addtocartModal";
    const success_modalProps = {
      open: true,
      title: "addtocartModal",
      shopping: this.shopping,
      checkoutNow: this.checkoutNow
    };
    showModal(
      {
        open: true,
        title: modal_type,
        modalLarge: modal_type === "quickAddModal" ? true : false,
        success_modalProps: success_modalProps,
        success_modal_type: success_modal_type
      },
      modal_type
    );
  };

  shopping = () => {
    this.props.history.push("/shop");
  };

  checkoutNow = () => {
    this.props.history.push("/checkout");
  };

  quickAddClicked = item => () => {
    const { updateQuickAdd } = this.props;
    updateQuickAdd(item);
    this.openModal("quickAddModal")();
  };

  productDetailCall = item => () => {
    const { getProductDetail } = this.props;
    getProductDetail(item.url);
    this.props.history.push("/shop" + item.product_uri);
  };

  getShareUrl = item => {
    let protocol = window.location.protocol;
    let host = window.location.hostname;
    let port = window.location.port;
    let url = protocol + "//" + host + ":" + port + "/details/" + item.id;
    return url;
  };

  shareActionTitle = () => {
    const { direction, classes } = this.props;

    if (direction === "rtl") {
      return (
        <i
          className={
            "mr-1 share fas fa-share fa-flip-horizontal " +
            classes.fontAwesomeIcon
          }
        />
      );
    } else {
      return (
        <i className={"ml-3 share fas fa-share " + classes.fontAwesomeIcon} />
      );
    }
  };

  // renderProductTypeIcon = () => {
  //   const { item, t, classes , product_fetching } = this.props;
  //   let is_company = item && item.vendor && item.vendor.is_vendor_type === "COMPANY" && item.vendor.company_approved=== true? true : false;
  //   if (is_company && !product_fetching) {
  //     return (
  //       <Tooltip
  //         title={t("buyingPage.shopFacets.verifiedCompany")}
  //         placement="top"
  //         enterTouchDelay={0}
  //         classes={{
  //           tooltip: classNames(classes.tooltip)
  //         }}
  //       >
  //         <img
  //           src={company_icon}
  //           className="typeIcon"
  //           alt={t("buyingPage.shopFacets.verifiedCompany")}
  //         />
  //       </Tooltip>
  //     )
  //   }
  // }

  renderVerfiedTitle = () => {
    const { item, t} = this.props;
    let is_company = item && item.vendor && item.vendor.is_vendor_type === "COMPANY" && item.vendor.company_approved=== true? true : false;
    if (is_company) {
      return (
        <Badge
          className="text-badge  image-badge-prodDetail"
          classes={{
            badge: "productBadge"
          }}
        >
          <span className="verification-text-Background">
            {" "}
            {t("buyingPage.shopFacets.verifiedCompanyShop")}{" "}
          </span>
        </Badge>
      );
    }
  };

  render() {
    const { item, classes, direction, t, display } = this.props;

    return (
      <Card plain>
        <CardHeader
          noShadow
          image
          className="ml-0 mr-0 prodGridImage pointer"
          onClick={this.productDetailCall(item)}
        >
          <img
            src={item.image}
            className="br-0 product_Image"
            alt={item.title}
          />
          {item.is_pending ? (
            <Badge
              color="gray"
              className="image-badge"
              classes={{
                badge: "productBadge"
              }}
            >
              <span className="pendingVerificationBackground">
                {" "}
                {t("buyingPage.shopProductDetail.pending")}{" "}
              </span>
            </Badge>
          ) : null}
          {/* {this.renderProductTypeIcon()} */}
        </CardHeader>
        <CardBody plain>
          <div
            className={
              direction === "rtl" ? "productAR productCarsole" : "product "
            }
          >

            {item.condition ?
              <Badge
                className="text-badge mb-0 image-badge-prodDetail"
                classes={{
                  badge: "productBadge"
                }}
              >
                <span className="verification-text-Background  bg-pl">
                  {item.condition}
                </span>
              </Badge>
            :null}
            {this.renderVerfiedTitle()}
            <div
              className={
                direction === "rtl"
                  ? "d-flex justify-content-between productCarsole"
                  : "d-flex justify-content-between"
              }
            >
              <h4
                onClick={this.productDetailCall(item)}
                className={
                  direction === "rtl"
                    ? classes.cardTitle + " mt-0 product-title arabic"
                    : classes.cardTitle + " mt-0 product-title"
                }
              >
                {item.title}
              </h4>
              <div
                className={
                  direction === "rtl"
                    ? "d-flex justify-content-between quick px-5  productCarsole"
                    : "d-flex justify-content-between quick px-5"
                }
              >
                <ShoppingCart
                  className={classes.svgIcon + " pointer ml-3"}
                  onClick={this.quickAddClicked(item)}
                />
                <SharePopOver
                  noLiPadding
                  dropPlacement={"right-start"}
                  caret={false}
                  className={classes.svgIcon + " pointer"}
                  action_title={this.shareActionTitle()}
                  product={item}
                />
              </div>
            </div>
            <h4
              className={
                display && direction === "rtl" ? "mt-0 similar_arabic" : "mt-0"
              }
            >
              {item.price !== null
                ? t("common.priceamount", { amount: item.price.incl_tax })
                : null}
            </h4>
          </div>
        </CardBody>

      </Card>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updateQuickAdd: data => {
    dispatch({
      type: ProductActionTypes.UPDATE_QUICKADD,
      data: data
    });
  },
  getProductDetail: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.productReducer,
  ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Products);
