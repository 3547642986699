import { call, put } from "redux-saga/effects";
import i18n from "locales/i18n.jsx";
import { store } from "utils/store.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import ActionTypes from "constants/comboProductConstants.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import {
  getProductClassList,
  getProductDuplicateList,
  getProductClassAttr,
  postProductCreate,
  postProductCreateImages
} from "actions/comboProductActions.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import { resetUnauthorized } from "utils/helpers.jsx";
import * as Sentry from "@sentry/browser";

function* productClassListWorker() {
  try {
    let response = yield call(getProductClassList);
    let data = response.data;
    yield put({ type: ActionTypes.UPDATE_PRODUCT_CLASS_LIST_SUCCESS, data });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response.status === 403) {
        yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
        let modalType = "verifyEmailModal";
        let modalProps = { open: true, title: modalType };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.UPDATE_PRODUCT_CLASS_LIST_FAILED, error });
  }
}
// Duplicate product list
function* productDuplicateListWorker() {
  try {
    let response = yield call(getProductDuplicateList);
    let data = response.data;
    yield put({
      type: ActionTypes.UPDATE_PRODUCT_DUPLICATE_LIST_SUCCESS,
      data
    });
  } catch (e) {

    if (e.response && e.response.status === 401) {
      resetUnauthorized();
    }
    else {
      Sentry.captureException(e);
    }
    let error = e;
    yield put({
      type: ActionTypes.UPDATE_PRODUCT_DUPLICATE_LIST_FAILED,
      error
    });
  }
}
// PRODUCT CREATE
function* productCreateWorker(params) {
  let userDetails = store.getState().accountReducer.userDetails;
  let modal_props = store.getState().modalReducer.modalProps;
  let { form_data } = params;
  let value = false;
  let showNextModal = true;
  yield put({ type: AccountActionTypes.SHOW_NEXT_MODAL, showNextModal });
  try {
    let response = yield call(postProductCreate, form_data);
    let data = response.data;
    yield put({ type: ProductActionTypes.LISTING_PROD_DETAIL, data });
    yield put({
      type: ActionTypes.PRODUCT_CREATE_SUCCESS,
      data
    });
    // if res status is 200 means with approval
    let appr = response.status === 200 ? true : false;
    let formData = generateFormImageData(data.id, appr, data.created_products);
    let prod_create_data = form_data;
    yield put({
      type: ActionTypes.PRODUCT_CREATE_IMAGES_REQUEST,
      formData,
      prod_create_data
    });
    yield put({
      type: ActionTypes.LIST_ITEM_BUTTON_DISABLE,
      value
    });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status <= 500) {
      if (e.response.status === 403) {
        let messageError = e.response.data.detail;
        let modalType = "verifyEmailModal";
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: "listing",
          activeSell: modal_props.activeSell
        };
        if (userDetails && !userDetails.is_phone_verified) {
        }
        if (messageError.indexOf("Phone") !== -1) {
          modalType = "verifyPhoneModal";
          modalProps["nextModal"] = "productPreviewModal";
        }
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      } else if (e.response.status === 500) {
        let txt = i18n.t("sellingPage.whatSellingModal.missingdata");
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        let modalType = "productPreviewModal";
        let modalProps = { open: true, title: modalType };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({
      type: ActionTypes.LIST_ITEM_BUTTON_DISABLE,
      value
    });
    yield put({ type: ActionTypes.PRODUCT_CREATE_FAILED, error });
  }
}
// create images

const generateFormImageData = (product_id, with_approval = false, product_ids=[]) => {
  let product_data_images = store.getState().comboProductReducer
    .product_data_images;

  let previous_images = store.getState().comboProductReducer.previous_images;

  let formData = new FormData();
  formData.append("product", product_id);

  // Change product_id to a list
  for (let key in product_ids) {
    formData.append("other_products", product_ids[key]);
  }

  for (let key in product_data_images) {
    formData.append("images", product_data_images[key]);
  }

  for (let key in previous_images) {
    formData.append("previous_images", previous_images[key].id);
  }

  if (with_approval) {
    formData.append("other", true);
  }

  return formData;
};

function* productCreateImagesWorker(params) {
  let { formData, prod_create_data } = params;
  try {

    let response = yield call(postProductCreateImages, formData);
    yield put({
      type: ActionTypes.PRODUCT_CREATE_IMAGES_SUCCESS
    });
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    yield put({ type: ModalActionTypes.UPDATE_TEMP_MODAL_PROPS, tempModalProps: {} });

    if (prod_create_data && "instant_cash" in prod_create_data) {
      let modalType = "instantCashPostedModal";
      let modalProps = { open: true, title: modalType };
      yield put({
        type: ModalActionTypes.SHOW_MODAL_REQUEST,
        modalProps,
        modalType
      });
      let txt = i18n.t("requestProducts.successMessage");
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
    }
    else {
      if (response.status && response.status === 200) {
        let modalType = "otherListingPostedModal";
        let modalProps = { open: true, title: modalType };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      } else {
        let modalType = "listingPostedModal";
        let modalProps = { open: true, title: modalType };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      }
    }
    yield put({ type: ActionTypes.RESET_COMBO });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.PRODUCT_CREATE_IMAGES_FAILED, error });
  }
}

function* productClassAttrWorker(params) {
  let { url, id } = params;
  let previous_attr = store.getState().comboProductReducer
    .current_product_class_attributes;
  // To check and not updating selected one if id not null (Eg : brand)
  if (id) {
    var previous_selected_attr = previous_attr.filter(i => i.id === id); // adding selected data into the variable
    var index = previous_attr.findIndex(
      i => i.id === previous_selected_attr[0].id // for postioning we find index and use it below
    );
  }
  try {
    let response = yield call(getProductClassAttr, url);
    //only if id is not null
    if (id) {
      var filter_data = response.data.filter(i => i.id !== id);
    }
    //updating data other than selected one
    let data;
    if (id) {
      filter_data.splice(index, 0, previous_selected_attr[0]);
      data = filter_data; // passing data without updating selected dropdown menu
    } else {
      data = response.data; // first loading the modal if ID is not available
    }
    // if data is empty keep the last query and show a notifcation
    if (response.data.length > 0) {
      yield put({
        type: ActionTypes.UPDATE_PRODUCT_CLASS_ATTR_SUCCESS,
        data,
        url
      });
    } else {
      let txt = i18n.t("sellingPage.whatSellingModal.noCombiFound");
      yield put({ type: ActionTypes.UPDATE_PRODUCT_CLASS_ATTR_FAILED, txt });
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
  } catch (e) {
    Sentry.captureException(e);
    let error = e;
    yield put({ type: ActionTypes.UPDATE_PRODUCT_CLASS_ATTR_FAILED, error });
  }
}

export {
  productClassListWorker,
  productDuplicateListWorker,
  productClassAttrWorker,
  productCreateWorker,
  productCreateImagesWorker
};
