import initialLoginModal from "./initialLoginModal.jsx";
import loginModal from "./loginModal.jsx";
import forgotPassword from "./forgotPassword.jsx";
import accountTypeModal from "./accountTypeModal.jsx";
import personalSignupModal from "./personalSignupModal.jsx";
import whatSellingModal from "./whatSellingModal.jsx";
import imagesModal from "./imagesModal.jsx";
import productPreviewModal from "./productPreviewModal.jsx";
import listingPostedModal from "./listingPosted.jsx";
import otherListingPostedModal from "./otherListingPostedModal";
import createdAccount from "./createdAccount.jsx";
import profileModal from "./profileModal.jsx";
import companyProfileModal from "./companyProfileModal.jsx";
import makeBidModal from "./makeBidModal.jsx";
import bidReceivedModal from "./bidReceivedModal.jsx";
import quickAddModal from "./quickAddModal.jsx";
import mapModal from "./MapModal.jsx";
import confirmAddress from "./confirmAddress.jsx";
import contactModal from "./contactModal.jsx";
import filterModal from "./filterModal.jsx";
import requestProductModal from "./requestProductModal.jsx";
import addtocartModal from "./addtocartModal.jsx";
import completeOrderModal from "./completeOrderModal.jsx";
import editAccountDetailsModal from "./editAccountDetailsModal.jsx";
import changePassword from "./changePassword.jsx";
import replacementProduct from "./replacementProductModal.jsx";
import confirmEmailModal from "kayfik/components/Modal/confirmEmailModal.jsx";
import verifyEmailModal from "kayfik/components/Modal/verifyEmailModal.jsx";
import verifyPhoneModal from "kayfik/components/Modal/verifyPhoneModal.jsx";
import VideoModal from "kayfik/components/Modal/videoModal.jsx";
import getIbanModal from "kayfik/components/Modal/getIbanModal.jsx";
import passwordResetModal from "kayfik/components/Modal/passwordResetModal.jsx";
import instantCashPostedModal from "kayfik/components/Modal/instantCashPostedModal.jsx";
import iCLineDeleteModal from "kayfik/components/Modal/instantCashItemDelete.jsx";
import civilIdModal from "kayfik/components/Modal/civilIdModal.jsx";

const modalTypes = {
  initialLoginModal, // Initial modal consist of type of signup process
  loginModal, // login modal
  accountTypeModal, // Account type modal
  personalSignupModal, // Personal signup modal
  whatSellingModal, // What are you selling modal
  imagesModal, // images modal
  productPreviewModal, //Product preview modal
  listingPostedModal, //Listed posted successfully message
  otherListingPostedModal,
  createdAccount, // Created account successully
  profileModal, // profile for seller or buyer
  companyProfileModal, // Company dashboard
  makeBidModal, // Make a big from buy flow
  bidReceivedModal, // Bid Received 
  quickAddModal, // shop page quick add 
  mapModal, // Map modal to select address
  confirmAddress,// after selecting address from map
  contactModal, // Contact us
  filterModal, // filter modal browse products page
  requestProductModal,
  addtocartModal, // addtocart Success model
  completeOrderModal, // completeOrderModal
  forgotPassword, //forget password reset
  editAccountDetailsModal, // editing acc details 
  changePassword, // PassWord Change
  replacementProduct, // Product Replacement Modal
  confirmEmailModal, //Email Confirm
  verifyEmailModal,  // Email Verfiy
  VideoModal,  // Buy on Kayfik Video Modal
  getIbanModal,
  passwordResetModal,
  verifyPhoneModal,
  instantCashPostedModal,
  iCLineDeleteModal,
  civilIdModal
};

export default modalTypes;
