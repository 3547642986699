import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import ActionTypes from "constants/modalConstants.jsx";

const initialState = {
  modalType: null,
  modalProps: {},
  nextModal: {},
  tempModalProps: {},
  modalPushPage:{}
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_MODAL_SUCCESS:
      return {
        ...state,
        modalProps: action.modalProps,
        modalType: action.modalType,
        type: action.type
      };
    case ActionTypes.UPDATE_NEXT_MODAL:
      return { ...state, nextModal: action.nextModal };

    case ActionTypes.UPDATE_TEMP_MODAL_PROPS:
      return { ...state, tempModalProps: action.tempModalProps };

    case ActionTypes.HIDE_MODAL_SUCCESS:
      return { ...state, modalType: null, modalProps: {}, nextModal: {} };

    default:
      return state;
  }
};

const persistConfig = {
  key: "modal",
  storage: storage,
  blacklist: ["modalType", "modalProps"]
};

export default persistReducer(persistConfig, modalReducer);
