import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// React i18next
import { withTranslation  } from "react-i18next";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Link from "@material-ui/core/Link";
import { truncateString } from "utils/helpers.jsx";
import { decimalPlace } from "utils/helpers.jsx";
import IconButton from "@material-ui/core/IconButton";


class ProductPreviewModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      goBack: true,
      instantCashAvailable: false,
      showinstantCashSection: false
      //submitDisabled: false
    };
    //this.props.getUserDetails();
    this.props.showNextModalFunc(true);
  }

  isInstantCashAvailable = () => {
    const { instantCashObj } = this.props;
    if (instantCashObj) {
      this.setState({
        instantCashAvailable: true,
        showinstantCashSection: true
      })
    }
    else {
      this.setState({
        instantCashAvailable: false,
        showinstantCashSection: false
      })
    }
  }
  componentDidMount() {
    this.isInstantCashAvailable();
  }

  openModal = (modal_type, modal_props) => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    if (modal_props) {
      if (modalProps.activeSell) {
        modal_props["activeSell"] = modalProps.activeSell;
      }
      modal_props["modalLarge"] =
        modal_type === "productPreviewModal" ||
          modal_type === "whatSellingModal"
          ? true
          : false;
      showModal(modal_props, modal_type);
    } else {
      let modal_props = {
        open: true,
        title: modal_type,
        activeSell: modalProps.activeSell
      };
      modal_props["modalLarge"] =
        modal_type === "productPreviewModal" ||
          modal_type === "whatSellingModal"
          ? true
          : false;
      showModal(modal_props, modal_type);
    }
  };

  getImages = () => {
    const { product_images, previous_images } = this.props;
    let imgs = [];

    if (product_images && product_images.length > 0) {
      for (let i = 0; i < product_images.length; i++) {
        let preview_url = product_images[i].preview;
        imgs.push({
          originalClass: "previewCarouselHeight",
          thumbnailClass: "previewCarouselthumbnail",
          original: preview_url,
          thumbnail: preview_url
        });
      }
    }

    // adding previous images
    if (previous_images.length > 0) {
      for (let i = 0; i < previous_images.length; i++) {
        let preview_url = previous_images[i].original;
        imgs.push({
          originalClass: "previewCarouselHeight",
          thumbnailClass: "previewCarouselthumbnail",
          original: preview_url,
          thumbnail: preview_url
        });
      }
    }

    return imgs;
  };

  renderName = () => {
    const { selected_attr_and_value } = this.props;
    let name = "";
    for (let i = 0; i < selected_attr_and_value.length; i++) {
      let obj = selected_attr_and_value[i];
      name += obj.value_name + " ";
    }
    return name;
  };

  renderAttrAndValue = () => {
    const { selected_attr_and_value } = this.props;
    return selected_attr_and_value.map((item, index) => {
      return (
        <h4 className="my-0 mt-2 mb-2" key={index}>
          {item.attr_name}: {item.value_name}
        </h4>
      );
    });
  };

  renderDescription = () => {
    const { description_en, description_ar, language } = this.props;

    if (language === "en" && description_en) {
      let en = truncateString(description_en, 200);

      return <p>{en}</p>;
    } else if (language === "ar" && description_ar) {
      let ar = truncateString(description_ar, 200);

      return <p>{ar}</p>;
    }
    return null;
  };

  renderPrice = () => {
    const {
      is_buy_price,
      is_bid_price,
      t
    } = this.props;
    let price = 0;
    if (is_buy_price) {
      price = is_buy_price;
    } else if (is_bid_price && !is_buy_price) {
      price = is_bid_price;
    }
    price=this.calculateCommission(price, 'add');
    return (
      <h4>
        {t("sellingPage.productPreview.currency")} {decimalPlace(price)}
      </h4>
    );
  };

  handleOpenSellModal = () => {
    const { modalProps } = this.props;
    let target_modal = "whatSellingModal";
    let modal_type = target_modal;
    let modal_props = {
      open: true,
      activeSell: modalProps.activeSell
    };

    this.openModal(modal_type, modal_props)();
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      product_data,
      productPost,
      account_token,
      updateListItemButton,
      // getPaymentInfo,
      // payment_info,
      other_product_data,
    } = this.props;

    let formData = product_data && product_data.product_class
      ? product_data
      : other_product_data;

    if (!account_token) {
      const { modalProps } = this.props;
      let modal_type = "initialLoginModal";
      let modal_props = {
        open: true,
        activeSell: modalProps.activeSell
      };
      modal_props["nextModal"] = "productPreviewModal";
      this.openModal(modal_type, modal_props)();
    } else {
      // if (
      //   payment_info.length <= 0 ||
      //   (payment_info.length > 0 && !payment_info[0].merchant_id)
      // ) {
      //   getPaymentInfo();
      // } else {
        updateListItemButton(true);
        productPost(formData);
      // }

      // }
    }
  };

  backClickedFunc = () => {
    this.openModal("whatSellingModal")();
  };

  clearComboAndClose = () => {
    const { closeModal, resetCombo } = this.props;
    resetCombo();
    closeModal();
  };

  acceptInstantCash = () => {
    const { instantCashObj, product_data, productPost,account_token } = this.props;
    let formData =
      product_data && product_data.product_class ? product_data : null;
    if (formData && instantCashObj && instantCashObj.id) {
      formData["instant_cash"] = instantCashObj.id;
    }
    if (account_token) {
      productPost(formData);
    } else {
      const { modalProps } = this.props;
      let modal_type = "initialLoginModal";
      let modal_props = {
        open: true,
        activeSell: modalProps.activeSell
      };
      modal_props["nextModal"] = "productPreviewModal";
      this.openModal(modal_type, modal_props)();
    }
  };


  calculateCommission = (value, type) => {
    const { selected_product_class, product_class_list } = this.props;
    let pc = product_class_list.find(p => p.id === selected_product_class.id);
    if (pc) {
      let flat_rate = parseFloat(pc.flat_rate);
      let percent_rate = parseFloat(pc.percentage_rate);

      let float_value = parseFloat(value);
      let partner_value = flat_rate;

      let current_percentage = (percent_rate / 100) * float_value;
      if (current_percentage > flat_rate) {
        partner_value = current_percentage;
      }
      if(type==='add'){
        float_value = float_value + partner_value;
      }
      else{
        float_value = float_value - partner_value;
      }
      float_value = decimalPlace(float_value);
      return float_value;
    }
  };

  // renderInitialPercentageAmount = () => {
  //   const {instantCashObj, direction } = this.props;
  //   let currency = direction === "rtl" ? "د.ك." : "KD";
  //   if (instantCashObj && instantCashObj.price) {
  //     let value = this.calculateCommission(instantCashObj.price);
  //     return (
  //       <Trans i18nKey="profileModal.ifAcceptedyoureceive">
  //         [
  //         <strong>
  //           {{ currency: currency }} {{ amount: value }}
  //         </strong>
  //         ]
  //       </Trans>
  //     );
  //   }
  // };

  instantCashSection = () => {
    const { instantCashObj, t, direction, classes } = this.props;
    let reqObj = instantCashObj;
    let currency = direction === "rtl" ? "د.ك." : "KD";
    return (<>
      <GridContainer className="mb-3">
        <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={12} className="px-0">
              <h4 className="">{reqObj.product_class} - {reqObj.name}<span className=" edit_instant color-pl pointer mx-2"
                onClick={() => {
                  if (
                    window.confirm(
                      t("sellingPage.imageModal.lossingImage")
                    )
                  )
                    this.openModal("whatSellingModal")();
                }}
              >  {t("sellingPage.productPreview.editListing")}</span> </h4>
            </GridItem>
          </GridContainer>
            <h3 className="text-center m-0">
              {" "}
              {currency} {this.calculateCommission(reqObj.price)}{" "}
            </h3>
            {/* <h4 className="text-center mt-2 mb-0">
              {this.renderInitialPercentageAmount()}
            </h4> */}
        </GridItem>
      </GridContainer>
      <GridContainer className="my-2">
          <GridItem xs={11} sm={10} md={8} className={classes.marginAuto}>
            <h4> {t("sellingPage.productPreview.instantCashMessage")} </h4>
          </GridItem>
        </GridContainer>
      <GridContainer className="my-5 mb-3">
        <GridItem xs={10} sm={10} md={6} className={classes.marginAuto}>
          <Button
            color="primaryWhiteWithBorder"
            size="lg"
            fullWidth
            onClick={this.acceptInstantCash}
          >
            {t("profileModal.offers.productbid.acc")}
          </Button>
          <Button
            color="primaryWhiteWithBorder"
            size="lg"
            fullWidth
            onClick={() => this.setState({ showinstantCashSection: false })}
          >
            {t("profileModal.offers.productbid.rej")}
          </Button>
        </GridItem>
      </GridContainer>
    </>)
  }

  itemListingData = () => {

    const images = this.getImages();
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      t,
      direction,
      is_bid,
      is_buy,
      list_item_disable,
    } = this.props;
    const { instantCashAvailable } = this.state;
    return (
      <>
        <GridContainer className={classNames("mb-3")}>
          <GridItem xs={12} sm={7} md={7} className="px-0">
            <ImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              items={images}
              showNav={false}
              showBullets={true}
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={5}
            md={5}
            className="bg-secondary-light px-0"
          >
            <GridContainer>
              <GridItem xs={12}>
                <h4 className={classNames(classes.modalTitle, "pt-5")}>
                  {this.renderName()}
                </h4>
                {this.renderPrice()}
                {this.renderDescription()}
                {this.renderAttrAndValue()}
                <div className={classes.borderBottomPreview} />
                <GridContainer
                  className={classNames(classes.marginAuto, "jccSm")}
                >
                  <GridItem
                    xs={10}
                    sm={10}
                    md={10}
                    className={classNames("py-2", "pl-0")}
                  >
                    {is_buy === true ? (
                      <Button
                        size="lg"
                        className={classNames(
                          direction === "rtl"
                            ? (classes.addToCartButtonAR, "dark-grey")
                            : "mb-1 dark-grey"
                        )}
                        fullWidth
                        disabled
                      >
                        {t("sellingPage.productPreview.cart")}
                      </Button>
                    ) : null}
                    {is_bid === true ? (
                      <Button
                        size="lg"
                        className={classNames(
                          direction === "rtl"
                            ? (classes.addToCartButtonAR, "dark-grey")
                            : "mb-1 dark-grey"
                        )}
                        disabled
                        fullWidth
                      >
                        {t("sellingPage.productPreview.bid")}
                      </Button>
                    ) : null}
                  </GridItem>
                </GridContainer>
                <div className={classes.borderBottomPreview} />
                <div className="d-flex disabled">
                  <Link underline="none" component="button" disabled={true}>
                    <h5 className="color-pl m-0">
                      {t("sellingPage.productPreview.share")}{" "}
                      <i
                        className={
                          direction === "rtl"
                            ? "fas fa-share  fa-flip-horizontal"
                            : "fas fa-share"
                        }
                      />
                    </h5>
                  </Link>
                </div>
                <div className="d-flex disabled">
                  <h5 className="m-0 mr-1 disabled">
                    {t("sellingPage.productPreview.sell")}
                  </h5>
                  <Link underline="none" component="button" disabled={true}>
                    <h5 className="color-pl m-0 disabled">
                      {t("sellingPage.productPreview.now")}
                    </h5>
                  </Link>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer className="my-2">
          <GridItem xs={11} sm={10} md={8} className={classes.marginAuto}>
            <h4> {t("sellingPage.productPreview.message")} </h4>
          </GridItem>
        </GridContainer>
        {instantCashAvailable && <GridContainer justify="center">
          <GridItem xs={12}>
            <h6 className="my-2 htPrimaryGreen pointer text-center" onClick={() => this.setState({ showinstantCashSection: true })}>
              {t("sellingPage.imageModal.showinstantCash")}{" "}
            </h6>
          </GridItem>
        </GridContainer>}
        <GridContainer className="my-5 mb-3">
          <GridItem xs={10} sm={8} md={6} className={classes.marginAuto}>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              fullWidth
              onClick={() => {
                if (
                  window.confirm(t("sellingPage.imageModal.lossingImage"))
                )
                  this.openModal("whatSellingModal")();
              }}
            >
              {t("sellingPage.productPreview.editListing")}
            </Button>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              className="mb-3"
              fullWidth
              type="submit"
              disabled={list_item_disable}
            >
              {t("sellingPage.productPreview.listProduct")}
            </Button>
          </GridItem>
        </GridContainer>
      </>
    )
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      t,
      direction,
      combo_fetching,
      account_fetching,
      instantCashObj
    } = this.props;
    const { instantCashAvailable, showinstantCashSection } = this.state;
    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        {combo_fetching || account_fetching ? <Loader /> : null}
        <div>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              simple="true"
              className={classNames(
                classes.modalCloseButton,
                "previewClose",
                direction === "rtl" ? classes.modalSmallCloseButtonAR : null
              )}
              key="close"
              aria-label="Close"
              onClick={this.clearComboAndClose}
            >
              {" "}
              <Close
                className={classNames(classes.modalClose, classes.closeStyle)}
              />
            </IconButton>
          </DialogTitle>

          <DialogContent
            id="classic-modal-slide-description"
            className={classNames(classes.modalBody, "scrollbar px-0")}
          >
            <GridContainer className="mb-3">
              <GridItem xs={11} sm={10} md={8} className={classes.marginAuto}>
                {instantCashAvailable && showinstantCashSection ?
                  <h2 className={classes.modalTitle}>
                    {t("sellingPage.imageModal.instantCashOffer")}
                  </h2>
                  : <h2 className={classes.modalTitle}>
                    {t("sellingPage.productPreview.title")}
                  </h2>}

                {instantCashAvailable && showinstantCashSection ? <h4 className="m-0 mb-3">
                  {t("sellingPage.imageModal.instantCash")}
                </h4> : <h4 className="m-0 mb-3">
                    {t("sellingPage.productPreview.subtitle")}{" "}
                  </h4>}
              </GridItem>
            </GridContainer>
            {instantCashAvailable && showinstantCashSection && instantCashObj ? this.instantCashSection() : this.itemListingData()}
          </DialogContent>
        </div>
      </form>
    );
  }
}

ProductPreviewModal.propTypes = {
  classes: PropTypes.object,
  description_en: PropTypes.string,
  description_ar: PropTypes.string,
  is_bid_price: PropTypes.string,
  is_buy_price: PropTypes.string,
  product_images: PropTypes.array,
  selected_attr_and_value: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  showNextModalFunc: showNextModal => {
    dispatch({
      type: AccountActionTypes.SHOW_NEXT_MODAL,
      showNextModal
    });
  },
  productPost: form_data => {
    dispatch({
      type: ComboProductActionTypes.PRODUCT_CREATE_REQUEST,
      form_data
    });
  },
  updateListItemButton: value => {
    dispatch({
      type: ComboProductActionTypes.LIST_ITEM_BUTTON_DISABLE,
      value
    });
  },
  // getPaymentInfo: () => {
  //   dispatch({
  //     type: AccountActionTypes.GET_PAYMENT_INFO_REQUEST
  //   });
  // },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  resetCombo: () => {
    dispatch({
      type: ComboProductActionTypes.RESET_COMBO
    });
  },
  getUserDetails: () => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_REQUEST
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.comboProductReducer,
  ...state.globalReducer,
  ...state.accountReducer
});

export default compose(
  withStyles(modalStyle),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductPreviewModal);
