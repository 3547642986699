import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";
import { htPrimaryYellow } from "kayfik/assets/jss/universal.jsx";

const checkoutStyles = theme => ({
  ...projectsStyle,
  stepperContainer: {
    marginLeft: "20px !important"
  },
  bgY: {
    backgroundColor: htPrimaryYellow + "!important"
  },
  qtyLabelCart: {
    // background: "rgb(" + hexToRgb(htPrimaryLight) + ", 1) !important",
    width: "60px !important",
    padding: "0px !important",
    [theme.breakpoints.down("md")]: {
      width: "100px !important"
    }
  },
  editableText: {
    fontWeight: " 300 !important",
    fontSize: "0.9rem !important",
    paddingLeft: 0
  },
  overflowUnset: {
    overflow: "unset !important"
  }
});

export default checkoutStyles;
