import React from "react";
import classNames from "classnames";
// @material-ui/core components
import compose from "recompose/compose";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Room from "@material-ui/icons/Room";
import CameraAlt from "@material-ui/icons/CameraAlt";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import IconButton from "@material-ui/core/IconButton";
// custom core components
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
// Style
import customInputStyle from "kayfik/assets/jss/components/CustomInput/customInputStyle.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import FlagSelect from "kayfik/components/FlagSelect/FlagSelect.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Help from "@material-ui/icons/Help";
import { companySignUpVerification } from "actions/accountActions.jsx";

class CompanyDetails1 extends React.Component {
  constructor(props) {
    super(props);
    const { company_signup_data } = this.props;
    this.state = {
      addressClicked: false,
      phone:
        company_signup_data && company_signup_data.phone
          ? company_signup_data.phone
          : "",
      work_phone:
        company_signup_data && company_signup_data.work_phone
          ? company_signup_data.work_phone
          : "",
      stateDialCode: this.props.defaultCountryCode,
      verifyLoader: false
    };
  }

  handleOpenMap = () => {
    const { updateAddressEvent, company_address } = this.props;
    updateAddressEvent("company_signup");
    let modal_type = "mapModal";
    if (company_address) {
      modal_type = "confirmAddress";
    }

    this.openModal(modal_type)();
  };

  openModal = modal_type => async () => {
    const { showModal, modalProps, closeModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  componentDidMount() {
    // const { update_user_location } = this.props;
    window.scrollTo(0, 0);
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     position => {
    //       this.setState(
    //         {
    //           currentLat: position.coords.latitude,
    //           currentLong: position.coords.longitude,
    //           error: null
    //         },
    //         () => {
    //           const location = {
    //             currentLat: this.state.currentLat,
    //             currentLong: this.state.currentLong
    //           };
    //           update_user_location(location);
    //         }
    //       );
    //     },
    //     error => this.setState({ error: error.message }),
    //     { timeout: 200000 }
    //   );
    // }
  }

  onClickAddress = () => {
    this.setState({ addressClicked: true });
  };

  handleTextChange = e => {
    const { updateData, company_signup_data } = this.props;

    let new_data = { ...company_signup_data };

    if (e.target.name === "first_name") {
      new_data["first_name"] = e.target.value;
      // addAddress(new_data)
    } else if (e.target.name === "last_name") {
      new_data["last_name"] = e.target.value;
    } else if (e.target.name === "name") {
      new_data["name"] = e.target.value;
    } else if (e.target.name === "email") {
      new_data["email"] = e.target.value;
    } else if (e.target.name === "password1") {
      new_data["password1"] = e.target.value;
    } else if (e.target.name === "password2") {
      new_data["password2"] = e.target.value;
    }
    updateData(new_data);
  };

  handleFileChange = e => {
    const { updateData, company_signup_data } = this.props;
    let new_data = { ...company_signup_data };
    new_data[e.target.name] = e.target.files[0];
    updateData(new_data);
  };

  updateCompanyData = event => {
    event.preventDefault();
    const {
      updateData,
      company_signup_data,
      update_company_force_step,
      companyForceStep,
      company_address,
      showSnackbar,
      t
    } = this.props;

    if (!company_address) {
      let txt = t("companySignup.companyDetailOne.fillup");
      showSnackbar(txt, "warning");
      return;
    }

    let new_data = {
      ...company_signup_data,
      name: event.target.name.value,
      first_name: event.target.first_name.value,
      last_name: event.target.last_name.value,
      email: event.target.email.value,
      phone: this.state.phone,
      password1: event.target.password1.value,
      password2: event.target.password2.value,
      ...company_address
    };
    let dataToVerify = {
      email: event.target.email.value,
      phone: this.state.phone,
      password1: event.target.password1.value,
      password2: event.target.password2.value
    };

    if (this.state.work_phone && this.state.work_phone !== "+965") {
      new_data["work_phone"] = this.state.work_phone;
      dataToVerify["work_phone"] = this.state.work_phone;
    }

    // add if logo exist
    if (event.target.logo && event.target.logo.files.length > 0) {
      new_data["logo"] = event.target.logo.files[0];
    } else {
      new_data["logo"] = "";
    }

    this.setState({ verifyLoader: true });
    companySignUpVerification(dataToVerify)
      .then(res => res.data)
      .then(data => {
        this.setState({ verifyLoader: false });
        updateData(new_data);
        update_company_force_step(parseInt(companyForceStep) + 1);
      })
      .catch(error => {
        this.setState({ verifyLoader: false });
        const res_object = error.response.data;
        let messages = [];

        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          showSnackbar(txt, "warning");
        }
      });
  };

  handleBack = () => {
    const { companyForceStep, update_company_force_step } = this.props;
    update_company_force_step(companyForceStep - 1);
  };

  renderBack = () => {
    const { t, direction } = this.props;
    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="company_icons" />
          {t("companySignup.companyDetailOne.back")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="company_icons" />
          {t("companySignup.companyDetailOne.back")}
        </div>
      );
    }
  };

  renderBackMobile = () => {
    const { t, direction } = this.props;
    if (direction === "rtl") {
      return (
        <div className="contact_us">
          <ChevronRight className="company_icons" />
          <span> {t("companySignup.companyDetailOne.back")}</span>
        </div>
      );
    } else {
      return (
        <div className="contact_us">
          <ChevronLeft className="company_icons " />
          <span>{t("companySignup.companyDetailOne.back")}</span>
        </div>
      );
    }
  };

  handleOnChange = (value, data) => {
    const { stateDialCode } = this.state;
    const { updateData, company_signup_data } = this.props;

    let new_data = { ...company_signup_data };
    let code = "+" + data.dialCode;
    this.setState({ stateDialCode: data.dialCode });
    if (value.indexOf(code) === 0) {
      this.setState({ phone: value });
      new_data["phone"] = value;
    } else if (data.dialCode !== stateDialCode) {
      this.setState({ phone: code });
      new_data["phone"] = code;
    } else {
      this.setState({ phone: value });
      new_data["phone"] = this.state.phone;
    }
    updateData(new_data);
  };

  handleOnChangeWork = (value, data) => {
    const { stateDialCode } = this.state;
    const { updateData, company_signup_data } = this.props;

    let new_data = { ...company_signup_data };
    let code = "+" + data.dialCode;
    this.setState({ stateDialCode: data.dialCode });
    if (value.indexOf(code) === 0) {
      this.setState({ work_phone: value });
      new_data["work_phone"] = value;
    } else if (data.dialCode !== stateDialCode) {
      this.setState({ work_phone: code });
      new_data["work_phone"] = code;
    } else {
      this.setState({ work_phone: value });
      new_data["work_phone"] = this.state.work_phone;
    }
    updateData(new_data);
  };

  render() {
    const {
      company_signup_data,
      direction,
      t,
      company_address,
      classes
    } = this.props;
    const { verifyLoader } = this.state;
    let company_address_detail = "";
    if (company_address) {
      let address = company_address;
      company_address_detail =
        t("common.area") +
        address.area +
        " | " +
        t("common.block") +
        address.block +
        " | " +
        t("common.street") +
        address.street +
        " | " +
        (address.avenue ? t("common.avenue") + address.avenue + " | " : "") +
        t("common.house") +
        address.building +
        (address.floor ? " | " + t("common.floor") + address.floor : "") +
        (address.unit ? " | " + t("common.apartment") + address.unit : "");
    }

    return (
      <div className="main-background-color  pt-3">
        {verifyLoader ? <Loader fixed /> : null}
        <GridItem xs={12} sm={10} md={10} className="marginAuto mb-5">
          <h3
            className={classNames(
              direction === "rtl" ? "ta-right m-0" : "ta-left m-0"
            )}
          >
            {t("companySignup.companyDetailOne.companyInformation")}
          </h3>
          <h5
            className={classNames(
              direction === "rtl" ? "ta-right m-0" : "ta-left m-0"
            )}
          >
            {t("companySignup.companyDetailOne.tellUsUrSelf")}
          </h5>
        </GridItem>
        <form onSubmit={this.updateCompanyData} autoComplete="off">
          <GridContainer className="m-0 py-5 bg-grey">
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={
                direction === "rtl" ? "pr-0 marginAuto" : "pl-0 marginAuto"
              }
            >
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.name")}
                      id="id_name"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "name",
                        defaultValue: company_signup_data.name,
                        required: true,
                        type: "text",
                        onChange: this.handleTextChange,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className="dp-f align-items-center"
                >
                  <div className="mb-3">
                    <h5>{t("companySignup.companyDetailOne.uploadName")}</h5>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.firstname")}
                      id="id_firstname"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "first_name",
                        defaultValue: company_signup_data.first_name,
                        required: true,
                        type: "text",
                        onChange: this.handleTextChange,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.lastname")}
                      id="id_lastname"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "last_name",
                        defaultValue: company_signup_data.last_name,
                        required: true,
                        type: "text",
                        onChange: this.handleTextChange,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.email")}
                      id="id_email"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: company_signup_data.email,
                        name: "email",
                        required: true,
                        type: "email",
                        onChange: this.handleTextChange,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3 colorYellow">
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.address")}
                      id="id_address"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "address",
                        value: company_address_detail,
                        required: true,
                        type: "text",
                        disableUnderline: true,
                        onClick: this.handleOpenMap,
                        disabled: true,
                        endAdornment: (
                          <InputAdornment position="end"  className={classes.inputAdornment}>
                            <IconButton  className="p-0" onClick={this.handleOpenMap}>
                              <Room className={classNames("colorYellow")} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.password")}
                      id="id_password"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "password1",
                        defaultValue: company_signup_data.password1,
                        required: true,
                        type: "password",
                        onChange: this.handleTextChange,
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.inputAdornment}
                          >
                            <Tooltip
                              title={t("personalSignupModal.helpText")}
                              placement="top"
                              enterTouchDelay={0}
                              classes={{
                                tooltip: classNames(classes.tooltip)
                              }}
                            >
                              <Help className={classes.inputAdornmentIcon} />
                            </Tooltip>{" "}
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t(
                        "companySignup.companyDetailOne.confirmPassword"
                      )}
                      id="id_confirm_password"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "password2",
                        defaultValue: company_signup_data.password2,
                        required: true,
                        type: "password",
                        onChange: this.handleTextChange,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3 dp-f alignItem">
                    <FlagSelect
                      value={
                        this.state.phone
                          ? this.state.phone
                          : company_signup_data.phone
                      }
                      handleFlagOnChange={this.handleOnChange}
                    />
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.mobileNo")}
                      id="id_mobile_no"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "phone",
                        required: true,
                        type: "number",
                        disabled: true,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3 dp-f alignItem">
                    <FlagSelect
                      value={
                        this.state.work_phone
                          ? this.state.work_phone
                          : company_signup_data.work_phone
                      }
                      handleFlagOnChange={this.handleOnChangeWork}
                    />
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.work_phone")}
                      id="id_work_phone"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "work_phone",
                        type: "number",
                        disabled: true,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.companyDetailOne.logo")}
                      id="id_logo"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "logo",
                        type: "file",
                        inputProps: {
                          accept: "image/png,image/jpg,image/jpeg"
                        },
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <CameraAlt
                              className={classNames("colorYellow", "mr-3")}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className="dp-f align-items-center"
                >
                  <div className="mb-3">
                    <h5>{t("companySignup.companyDetailOne.uploadLogo")}</h5>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <div className="display-sm-up">
            <GridContainer className="py-5">
              <GridItem
                xs={12}
                sm={10}
                md={10}
                className={
                  direction === "rtl"
                    ? "marginAuto dp-f jcsb p-0 pr-0"
                    : "marginAuto dp-f jcsb"
                }
              >
                <Button
                  link
                  size="lg"
                  className={direction === "rtl" ? "pr-0 mb-3" : "pl-0 mb-3"}
                  onClick={this.handleBack}
                >
                  {this.renderBack()}
                </Button>
                <div
                  className={direction === "rtl" ? "pl-4 dp-f" : "pr-4 dp-f"}
                >
                  <h5 className="ta-right">
                    {t("companySignup.companyDetailOne.finishregister")}
                    <br />
                    <span
                      className="contact_us dp-f jcfe"
                      onClick={this.openModal("contactModal")}
                    >
                      {t("companySignup.companyDetailOne.contactUs")}
                    </span>
                  </h5>
                  <Button
                    size="lg"
                    type="submit"
                    color="primaryWhiteWithBorder"
                    className={direction === "rtl" ? "mr-4" : "ml-4"}
                  >
                    {t("companySignup.companyDetailOne.saveAndContinue")}
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <div className="display-sm-down">
            <GridContainer className="py-5">
              <GridItem
                xs={12}
                sm={10}
                md={10}
                className={
                  direction === "rtl" ? "pr-0 marginAuto" : "pl-0 marginAuto"
                }
              >
                <GridContainer className="dp-f jcc">
                  <div onClick={this.handleBack}>{this.renderBackMobile()}</div>
                </GridContainer>
                <GridContainer className="dp-f jcc pl-0">
                  <h5 className="text-center">
                    {t("companySignup.companyDetailOne.finishregister")}
                    <br />
                    <span
                      className="contact_us"
                      onClick={this.openModal("contactModal")}
                    >
                      {t("companySignup.companyDetailOne.contactUs")}
                    </span>
                  </h5>
                </GridContainer>
                <GridContainer className="dp-f jcc pl-0">
                  <Button
                    size="lg"
                    type="submit"
                    color="primaryWhiteWithBorder"
                  >
                    {t("companySignup.companyDetailOne.saveAndContinue")}
                  </Button>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  update_user_location: location => {
    dispatch({
      type: AddressActionTypes.USER_CURRENT_LOCATION,
      payload: location
    });
  },
  updateData: data => {
    dispatch({
      type: AccountActionTypes.UPDATE_COMPANY_SIGNUP_DATA,
      data
    });
  },
  update_company_force_step: number => {
    dispatch({
      type: AccountActionTypes.UPDATE_COMPANY_STEP,
      data: number
    });
  },
  updateAddressEvent: event => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT,
      event
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer,
  ...state.addressReducer
});

export default compose(
  withStyles(customInputStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(CompanyDetails1);
