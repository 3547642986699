import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
//import { SnackbarProvider } from "notistack";
import { store, persistor } from "utils/store.jsx";
import "assets/scss/material-kit-pro-react.scss?v=1.3.0";
import "kayfik/assets/scss/custom-style.scss?v=0.0.1";
import "kayfik/assets/scss/arabic-custom-style.scss?v=0.0.1";
import App from "./App";
import * as Sentry from "@sentry/browser";
// import i18n (needs to be bundled ;))
import "locales/i18n.jsx";
import api from "utils/api.jsx";

// register sentry
if (api.enableSentry) {
  Sentry.init({
    dsn: "https://aec40675b23b4c29932cc0848e9296f0@sentry.io/5171344"
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
