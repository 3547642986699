import React from "react";
import classNames from "classnames";

//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

class ProdReqHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bidNow: false,
      buyNow: true
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    return (
      <div className="main-background-color marginAuto pt-3">
        <h4 className={classNames(" my-0 fw-400")}>
          Product Request History
        </h4>
        <GridContainer
          className={classNames("m-0", "modalScroll", "scrollbar")}
        >
          <Card className="mb-3">
            <CardBody className=" p-0">
              <GridContainer className="w-100">
                <GridItem xs={12} className="px-0">
                  <GridContainer className=" py-4">
                    <GridItem xs={8}>
                      <GridContainer className="pl-5">
                        <GridItem xs={12}>
                          <span className=" mr-1">Product Category</span>{" "}
                          <span
                            className={classNames("separator colorGrey mx-2")}
                          >
                            |
                          </span>{" "}
                          <span className="color-pl">Edit</span>
                          <span
                            className={classNames("separator colorGrey mx-2")}
                          >
                            |
                          </span>{" "}
                          <span className="color-pl">Remove</span>
                        </GridItem>
                        <GridItem xs={12}>
                          <span>Brand</span>
                          <br />
                          <span>Model</span>
                          <br />
                          <span>Capacity</span>
                          <br />
                          <span>Color</span>
                          <br />
                          <span>Condition</span>
                          <br />
                          <span>Quantity</span>
                          <br />
                          <span>Price</span>
                          <br />
                          <span>Date Request Filled By</span>
                          <br />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={4} className="px-0 ">
                      <span className="fw-500 htPrimaryGreen">
                        Status: In Process
                      </span>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className=" p-0">
              <GridContainer className="w-100">
                <GridItem xs={12} className="px-0">
                  <GridContainer className=" py-4">
                    <GridItem xs={8}>
                      <GridContainer className="pl-5">
                        <GridItem xs={12}>
                          <span className=" mr-1">Product Category</span>{" "}
                          <span
                            className={classNames("separator colorGrey mx-2")}
                          >
                            |
                          </span>{" "}
                          <span className="color-pl">Edit</span>
                          <span
                            className={classNames("separator colorGrey mx-2")}
                          >
                            |
                          </span>{" "}
                          <span className="color-pl">Remove</span>
                        </GridItem>
                        <GridItem xs={12}>
                          <span>Brand</span>
                          <br />
                          <span>Model</span>
                          <br />
                          <span>Capacity</span>
                          <br />
                          <span>Color</span>
                          <br />
                          <span>Condition</span>
                          <br />
                          <span>Quantity</span>
                          <br />
                          <span>Price</span>
                          <br />
                          <span>Date Request Filled By</span>
                          <br />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={4} className="px-0 ">
                      <span className="fw-500 htPrimaryGreen">
                        Status: In Process
                      </span>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardBody className=" p-0">
              <GridContainer className="w-100">
                <GridItem xs={12} className="px-0">
                  <GridContainer className=" py-4">
                    <GridItem xs={8}>
                      <GridContainer className="pl-5">
                        <GridItem xs={12}>
                          <span className=" mr-1">Product Category</span>{" "}
                          <span
                            className={classNames("separator colorGrey mx-2")}
                          >
                            |
                          </span>{" "}
                          <span className="color-pl">Edit</span>
                          <span
                            className={classNames("separator colorGrey mx-2")}
                          >
                            |
                          </span>{" "}
                          <span className="color-pl">Remove</span>
                        </GridItem>
                        <GridItem xs={12}>
                          <span>Brand</span>
                          <br />
                          <span>Model</span>
                          <br />
                          <span>Capacity</span>
                          <br />
                          <span>Color</span>
                          <br />
                          <span>Condition</span>
                          <br />
                          <span>Quantity</span>
                          <br />
                          <span>Price</span>
                          <br />
                          <span>Date Request Filled By</span>
                          <br />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={4} className="px-0 ">
                      <span className="fw-500 htPrimaryGreen">
                        Status: In Process
                      </span>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(modalStyle)(ProdReqHistory);
