import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// custom core components
import Header from "kayfik/components/Header/Header.jsx";
import ScrollToTop from "kayfik/components/ScrollToTop/scrollToTop.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
// Style
import checkoutStyles from "kayfik/assets/jss/views/Checkout/checkoutStyles.jsx";
// Sections
import SectionContact from "kayfik/components/SectionContact/sectionContact.jsx";
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
import compose from "recompose/compose";
// React i18next
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
import Loader from "kayfik/components/Loader/loader.jsx";
import parse from "html-react-parser";
// helmet
import {Helmet} from "react-helmet";

class About extends React.Component {
  constructor(props) {
    super(props);
    // get cms data for home page
    this.props.getCMSData("about"); // this 'home' is the identifier of cms landing page data
  }

  renderHeaderContent = text => {
    if (text.length > 0) {
      return text.map((item, index) => {
        return (
          <div key={index} className={classNames("sub-title m-0 about-header")}>
            {parse(item.content)}
          </div>
        );
      });
    }
    return null;
  };

  renderBodyContent = text => {
    if (text.length > 0) {
      return text.map((item, index) => {
        return (
          <div className="about-text" key={index}>
            {parse(item.content)}
          </div>
        );
      });
    }
    return null;
  };

  renderBody() {
    const { cms_data } = this.props;
    if (cms_data.about) {
      let sections = cms_data.about.sections;
      let body = sections.find(sec => sec.slug === "about-body");
      if (body) {
        return this.renderBodyContent(body.text);
      }
    }
    return null;
  }

  render() {
    const {
      cms_data,
      classes,
      direction,
      global_fetching,
      t,
      ...rest
    } = this.props;
    if (cms_data.about) {
      let sections = cms_data.about.sections;
      let about = sections.find(sec => sec.slug === "about-header");
      if (about) {
        return (
          <div id="landing-top">
            {/* For SEO */}
            <Helmet>
              <title>{cms_data.about.title}</title>
              <meta
                name="description"
                content={cms_data.about.meta_description}
              />
            </Helmet>

            {global_fetching ? <Loader fixed /> : null}
            <Header {...rest}/>
            <ScrollToTop />
            <div className="cd-section main-background-color min-70vh">
              <GridContainer className="bg-pl ">
                <GridItem
                  xs={10}
                  sm={10}
                  md={10}
                  className={classNames(
                    classes.container,
                    " header-container marginAuto p-o"
                  )}
                >
                  <div />
                  <h2
                    className={classNames("header-title m-0 py-2 about-title")}
                  >
                    {cms_data.about.title}
                  </h2>
                  {this.renderHeaderContent(about.text)}
                  <div />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={12} xs={12} md={3} />
                <GridItem md={9} sm={12} xs={12} className="bg-grey">
                  <GridItem
                    xs={10}
                    className={
                      direction === "ltr"
                        ? "about-body htPrimaryGreen py-5 mt-4"
                        : "about-bodyAR htPrimaryGreen py-5 mt-4"
                    }
                  >
                    {this.renderBody()}
                  </GridItem>
                  <div className="sectionSpace" />
                  <div className="sectionSpace" />
                </GridItem>
              </GridContainer>
            </div>
            <SectionContact />
            <BottomBar />
            <CustomFooter />
          </div>
        );
      }
    }
    return <Loader />;
  }
}

const mapDispatchToProps = dispatch => ({
  getCMSData: code => {
    dispatch({
      type: GlobalActionTypes.CMS_PAGE_REQUEST,
      code
    });
  }
});
const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(checkoutStyles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(About);
