import React from "react";
// React i18next
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
import withWidth from "@material-ui/core/withWidth";
import Hidden from "@material-ui/core/Hidden";
// @material-ui icons
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import styles from "kayfik/assets/jss/views/Shop/Sections/HorizontalProductGridStyle.jsx";
//CUSTOM CORE COMP
import ModalActionTypes from "constants/modalConstants.jsx";
import { hideModal } from "actions/modalActions.jsx";
import Products from "kayfik/views/ProductDetail/products.jsx";
import Pagination from "kayfik/components/Pagination/Pagination.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";

class ProductGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortingBy: "relevancy"
    };
  }
  componentDidMount() {
    const { showLoader } = this.props;

    showLoader(false, null);
  }
  
  openModal = modal_type => async () => {
    const { modalProps, closeModal, showmodal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showmodal(
      {
        open: true,
        title: modal_type,
        modalLarge: modal_type === "quickAddModal" ? true : false
      },
      modal_type
    );
  };

  renderObjects = () => {
    const { objects, t } = this.props;
    if (objects && objects.count > 0) {
      return objects.results.map((item, i) => {
        return (
          <GridItem
            xs={12}
            md={4}
            key={i}
            className="p-md-0"
            id={i === objects.results.length - 1 ? "lastProd" : "prod" + i}
          >
            <Products item={item} key={i} display={"rtl"} />
          </GridItem>
        );
      });
    } else {
      return (
        <GridItem xs={12}>
          <p>{t("buyingPage.productGrid.products")}</p>
        </GridItem>
      );
    }
  };
  renderSortByItems = () => {
    const { sort_by } = this.props;
    let menuItems = [];
    if (sort_by) {
      menuItems = sort_by.map(item => {
        return {
          value: item.code,
          label: item.title
        };
      });
    }
    return menuItems;
  };
  handleSortbyChange = value => {
    const {
      getProducts,
      sort_by,
      history,
      UpdateActiveSort,
      updateLoaderHorizontal
    } = this.props;
    let a = sort_by.find(x => x.code === value.value);
    UpdateActiveSort(a);
    this.setState({ sortingBy: a.code });
    let sortUrl = a.url;
    let otherparams = null;
    if (sortUrl.indexOf("?") !== -1) {
      otherparams = sortUrl.split("?").pop();
    }
    if (otherparams) {
      let parArr = otherparams.split("&");

      let newarr = parArr.filter(a => a.indexOf("page") === -1);
      if (newarr.length > 0) {
        sortUrl = a.url.slice(0, a.url.indexOf("?")) + "?" + newarr.join("&");
      }
    }
    let sortFullParams = sortUrl.split("?").pop();
    history.push("/search?" + sortFullParams);
    getProducts(sortUrl);
    updateLoaderHorizontal(true);
  };
  render() {
    const {
      classes,
      objects,
      active_category,
      t,
      fetching_more_products,
      sort_by,
      active_sort
    } = this.props;
    let menuItems = this.renderSortByItems();
    let nextLink = objects ? objects.next : null;
    let selected_sortby = menuItems[0];
    if (sort_by) {
      let sortByObject = sort_by[0];
      if (active_sort) {
        sortByObject = sort_by.find(item => item.code === active_sort.code);
      }
      if (sortByObject) {
        selected_sortby = menuItems.find(
          item => item.value === sortByObject.code
        );
      }
    }
    return (
      <GridContainer className="mb-5">
        <GridItem md={9} sm={12}className="shopDataRow">
          {active_category && active_category.title ? (
            <h2 className={classNames(classes.title, "category_title m-0")}>
              {active_category.title}
            </h2>
          ) : (
            <h2 className={classes.title + " mb-0 m-0 searchSignleLine"}>
              {t("buyingPage.productGrid.results")}

              {this.props.searchUrl
                ? t("buyingPage.productGrid.resultFor")
                : null}
                {this.props.searchUrl ?
                  <span className="userName-capitalize">
                    {" " + this.props.searchUrl}
                  </span>
              : null}
            </h2>
          )}
        </GridItem>
        <Hidden smDown>
          <GridItem sm={3} className="shopDataRow">
            <div className="w-100">
              <SelectAutocomplete
                inputId={"sort_by"}
                noSelectMb
                inputProps={{ name: "sort_by", required: true }}
                value={selected_sortby}
                autocompleteOptions={menuItems}
                selectOnChange={this.handleSortbyChange}
              />
            </div>
          </GridItem>
        </Hidden>
        {objects ? this.renderObjects() : null}
        <GridItem xs={12} className="text-center">
          {fetching_more_products ? (
            <CircularProgress
              disableShrink
              classes={{ root: classes.indicator }}
            />
          ) : null}
        </GridItem>
        <Pagination
          elementID="lastProd"
          prod_next_list={nextLink}
          loadMore="products"
        />
      </GridContainer>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  getProductDetail: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  updateQuickAdd: data => {
    dispatch({
      type: ProductActionTypes.UPDATE_QUICKADD,
      data: data
    });
  },
  hidemodal: () => dispatch(hideModal()),
  showmodal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getProducts: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_REQUEST,
      payload: url
    });
  },
  resetObjects: () => {
    dispatch({
      type: ProductActionTypes.RESET_OBJECTS
    });
  },
  showLoader: (payload, currentFetch) => {
    dispatch({
      type: ProductActionTypes.SHOW_PRODUCTS_LOADER,
      payload,
      currentFetch
    });
  },
  updateLoaderHorizontal: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_LOADER_HORIZONTAL,
      value
    });
  },
  UpdateActiveSort: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_SORT,
      value
    });
  }
});
const mapStateToProps = state => ({
  ...state.productReducer,
  ...state.modalReducer,
  ...state.globalReducer
});
export default compose(
  withTranslation(),
  withStyles(styles),
  withWidth(),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductGrid);
