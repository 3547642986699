import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";
import { htPrimaryLight } from "kayfik/assets/jss/universal.jsx";

const customStepperStyle = theme => ({
  ...projectsStyle,
  customIconContainer: {
    display: "none !important"
  },
  activeStyle: {
    color: htPrimaryLight + "!important"
  },
  labelStyle: {
    color: "rgba(0, 0, 0, 0.54) !important"
  },
  stepperRoot: {
    //border: "1px solid white !important"
  },
  labelContainer: {
    paddingBottom: "20px !important",
    paddingHorizontal: "10px !important"
  },
  stepsContainerMain: {
    display: "inline-flex !important",
    width: "85% !important",
    paddingLeft: "0px !important",
    paddingRight: "0px !important"
  },
  StepBorder: {
    borderBottom: "1px solid white !important",
    borderBottomWidth: "1px !important",
    borderBottomColor: "rgba(0, 0, 0, 0.35) !important",
    height: "40px !important",
    [theme.breakpoints.down("sm")]: {
      height: "70px !important"
    }
  },
  activeBorder: {
    borderBottom: "1px solid white !important",
    borderBottomWidth: "1px !important",
    borderBottomColor: htPrimaryLight + "!important",
    height: "40px !important",
    [theme.breakpoints.down("sm")]: {
      height: "70px !important"
    }
  },
  hide: {
    display: "none !important"
  },
  gridPadding: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px !important",
      paddingRight: "20px !important"
    }
  },
  label: {
    color: "rgba(0, 0, 0, 0.35) !important"
  },
  backgroundTransparent: {
    backgroundColor: "transparent !important"
  },
  stepsContainerMainProps: {
    width: "100% !important"
  },
  backButtonPadding: {
    paddingLeft: "7px !important",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px !important"
    }
  }
});

export default customStepperStyle;
