import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import BidActionTypes from "constants/bidConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import { decimalPlace } from "utils/helpers.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import IconButton from "@material-ui/core/IconButton";
import { convertHindiToArabic, converToArabicString, isInputEnglish } from "utils/helpers.jsx";

class makeBidModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bid_value: "",
      hint_message: false,
      bid_value_display:""
    };
    // this.isVerified();
  }

  isVerified = () => {
    const { userDetails } = this.props;
    if (userDetails && !userDetails.is_email_verified) {
      this.openModal("verifyEmailModal", {
        open: true,
        verifyFrom: "makeBid",
        activeBid: true
      })();
    } else if (userDetails && !userDetails.is_phone_verified) {

      this.openModal("verifyPhoneModal", {
        open: true,
        verifyFrom: "makeBid",
        activeBid: true
      })();
    }
  }

  componentDidMount = () => {
    const { account_token, closeModal } = this.props;
    if (!account_token) {
      closeModal();
    }
    let bidInput = document.getElementById("bid_id");
    if(bidInput){
      bidInput.setAttribute("step", ".001");
    }
  };

  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };
  
  formatNumber(num) {
    if (num && num.length > 0) {
      num = convertHindiToArabic(num, true);
      return num;
    } else {
      return '';
    }
  }

  getBitAmount = e => {
    let value = e.target.value;
    var lastChar = value.substr(value.length - 1);
    let afterDec = value.split(".")[1];
    if(afterDec === undefined || afterDec === "" || afterDec.length<4){
    let isEng = isInputEnglish(value);
    let targetValue1 = this.formatNumber(value);
    let targetValueInt =  parseFloat(targetValue1); //parseFloat(targetValue1);
    if(lastChar === "." || lastChar ==="٫" ){
      targetValueInt = targetValueInt + "."
    }
    let displayPrice = targetValueInt ? targetValueInt + "" : "";
    if (displayPrice && !isEng) {
      displayPrice = converToArabicString(displayPrice);
    }
    this.setState({ bid_value: targetValueInt, bid_value_display: displayPrice  });
    }
  };

  placeBid = e => {
    e.preventDefault();
    const { postBid, bidItem, post_bid_req_sent, showSnackbar, t } = this.props;

    let product_amount = parseFloat(bidItem.price.incl_tax); // Buy Amount
    let bidasc = this.state.bid_value;
    let bidding_amount = parseFloat(decimalPlace(bidasc)); // Bid Amount

    // make sure that bid amount is in average of buy now and bid now
    let min_bid = bidItem.is_auction_min_price;
    let buy_now = bidItem.price.incl_tax;
    let average = (parseFloat(min_bid) + parseFloat(buy_now)) / 2;

    // add 15% on the price if no buy out now price
    if (average === parseFloat(min_bid)) {
      let add_percent = average * 0.15;
      average = average + add_percent;
    }

    if (parseFloat(bidasc) < min_bid) {
      this.setState({ hint_message: decimalPlace(average) });
      return;
    }
    if (bidItem.is_buy) {
      if (product_amount > bidding_amount) {
        if (!post_bid_req_sent) {
          const data = {
            bid_amount: bidasc,
            auction_product: bidItem.auction.id,
            url: bidItem.auction.create_bid_url
          };
          this.setState({ bid_amount: bidasc });
          postBid(data, bidItem.url);
        }
      } else {
        let txt = t("buyingPage.makeABid.bid_hint");
        showSnackbar(txt, "warning");
      }
    } else {
      if (!post_bid_req_sent) {
        const data = {
          bid_amount: bidasc,
          auction_product: bidItem.auction.id,
          url: bidItem.auction.create_bid_url
        };
        this.setState({ bid_amount: bidasc });
        postBid(data, bidItem.url);
      }
    }
  };

  renderAmount = () => {
    const { bid_value } = this.state;
    if (bid_value && bid_value >= 0) {
      return decimalPlace(bid_value);
    }
    return null;
  };

  renderContent = () => {
    const { bidItem, t, classes } = this.props;
    const { bid_value, hint_message } = this.state;
    

    if (bidItem) {
      let image;
      if (bidItem && bidItem.images && bidItem.images.length > 0) {
        image = bidItem.images[0].url; // From the Product detail Page
      } else {
        image = bidItem.image; // From the Quick Add Modal
      }
      return (
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {t("buyingPage.makeABid.title")}
              </h2>
              <h4 className="m-0 mb-3"> {t("buyingPage.makeABid.price")}</h4>
              <GridItem xs={12} className="px-0 py-4 minHeight30">
                <img
                  src={image}
                  className={classNames(
                    classes.img,
                    classes.imgRaised,
                    classes.imgRounded,
                    "img-fluidMakeBid"
                  )}
                  alt="..."
                />
              </GridItem>
              <h4 className="m-0 mb-3">{bidItem && bidItem.title}</h4>
              <form onSubmit={this.placeBid} autoComplete="off">
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} className="p-0">
                    <h5>{t("buyingPage.makeABid.bid")}</h5>
                    <CustomInput
                      labelText={t("buyingPage.makeABid.currency")}
                      id="bid_id"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: this.getBitAmount,
                        name: "bid",
                        required: true,
                        type: "text",
                        value:this.state.bid_value_display,
                        className: "noscroll",
                        disableUnderline: true
                      }}
                      inputActualProps={{
                        step: "1"
                      }}
                    />
                    {hint_message ? (
                      <div>
                        <p>
                          {t("buyingPage.makeABid.hint", {
                            currency: bidItem.price.currency,
                            hint: hint_message
                          })}
                        </p>
                      </div>
                    ) : null}
                  </GridItem>
                </GridContainer>
                <GridItem
                  xs={12}
                  sm={10}
                  md={10}
                  className={classNames(classes.marginAuto, "mt-3")}
                >
                  <Button
                    color="primaryWhiteWithBorder"
                    size="lg"
                    className="mb-3"
                    fullWidth
                    type="submit"
                  >
                    {t("buyingPage.makeABid.place")}
                  </Button>
                </GridItem>
              </form>
              {bid_value ? (
                <GridItem
                  xs={12}
                  sm={10}
                  md={10}
                  className={classNames(classes.marginAuto, "mt-3")}
                >
                  <h5>
                    {t("buyingPage.makeABid.placing", {
                      currency: bidItem.price.currency,
                      amount: this.renderAmount()
                    })}
                  </h5>
                </GridItem>
              ) : null}
            </GridItem>
          </GridContainer>
        </DialogContent>
      );
    } else {
      return (
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12}>
              <p>{t("buyingPage.makeABid.data")}</p>
            </GridItem>
          </GridContainer>
        </DialogContent>
      );
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, direction, bid_fetching } = this.props;

    return (
      <div>
        {bid_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  postBid: (data, prod_url) => {
    dispatch({
      type: BidActionTypes.POST_CREATE_BID_REQUEST,
      payload: data,
      prod_url
    });
  },
  showSnackbar: (txt, variant) => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.productReducer,
  ...state.globalReducer,
  ...state.bidReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(makeBidModal);
