import accordionStyle from "assets/jss/material-kit-pro-react/components/accordionStyle.jsx";
import { htPrimaryLight, htDark } from "kayfik/assets/jss/universal.jsx";
import { container } from "assets/jss/material-kit-pro-react.jsx";

const customAccordionStyle = theme => ({
  ...accordionStyle(theme),
  accordionItem: {
    fontWeight: "600 !important",
    fontSize: "22px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px !important"
    }
  },
  oddRow: {
    backgroundColor: "#49A2AD",
    borderRadius: "0 !important"
  },
  evenRow: {
    backgroundColor: htPrimaryLight,
    borderRadius: "0 !important"
  },
  inline: {
    display: "inline-flex",
    borderBottom: "0px",
    color: "white",
    padding: "25px 10px 25px 0px"
  },
  rowData: {
    color: "white"
  },
  whiteFont: {
    color: "white"
  },
  colorYello: {
    color: "yellow"
  },
  green: {
    backgroundColor: "green"
  },
  red: {
    backgroundColor: "red"
  },
  container,
  accordianColor: {
    color: htDark + "!important"
  },
  htPrimaryLight: {
    color: "red !important"
  },
  width100: {
    width: "100% !important",
    // paddingTop: "45px !important",
    // paddingBottom: "40px !important",
    height: "133px",
    [theme.breakpoints.down("sm")]: {
      height: "70px"
    }
  },
  accordionIcon: {
    fontSize: "2.25rem"
  },
  widthAuto: {
    width: "auto !important",
    [theme.breakpoints.down("sm")]: {
      width: "50% !important",
      display: "contents !important"
    }
  },
  iconColor: {
    color: "colorYellow"
  }
});
export default customAccordionStyle;
