import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GlobalActionTypes from "constants/globalConstants.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// custom core components
import Header from "kayfik/components/Header/Header.jsx";
import ScrollToTop from "kayfik/components/ScrollToTop/scrollToTop.jsx";
// Style
import projectsStyle from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.jsx";
// Sections
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
import LeftAccordian from "kayfik/components/Accordion/LeftAccordian.jsx";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
import compose from "recompose/compose";
// React i18next
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
import ModalActionTypes from "constants/modalConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import parse from "html-react-parser";
// helmet
import {Helmet} from "react-helmet";

class WhyKayfikWorks extends React.Component {
  constructor(props) {
    super(props);
    // get cms data for home page
    this.props.getCMSData("whykayfikworks"); // this 'home' is the identifier of cms landing page data
  }
  componentDidMount() {
    this.setState({
      dummy: "test"
    });
  }

  openModal = modal_type => async () => {
    const { showModal, closeModal, modalProps } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  renderFaqHeader = title => {
    const { direction } = this.props;
    if (title.length > 0) {
      return title.map((item, index) => {
        if (index === 0) {
          return (
            <h4
              key={index}
              className={direction === "rtl" ? "sub-titleAR" : "sub-title"}
            >
              {item.content}
            </h4>
          );
        } else {
          return (
            <h4
              key={index}
              className={
                direction === "ltr"
                  ? "color-title ml-2 pointer"
                  : "color-title mr-2 pointer"
              }
              onClick={this.openModal("contactModal")}
            >
              {item.content}
            </h4>
          );
        }
      });
    }
  };

  renderBuyTitle = () => {
    const { cms_data } = this.props;
    if (cms_data.whykayfikworks) {
      let sections = cms_data.whykayfikworks.sections;
      let buy = sections.find(sec => sec.slug === "whybuyworks");
      let title = buy.title;
      if (title.includes("__")) {
        return null;
      } else return buy.title;
    }
  };

  renderBuyAccordian = () => {
    const { cms_data } = this.props;
    let data = [];
    if (cms_data.whykayfikworks) {
      let sections = cms_data.whykayfikworks.sections;
      let buy = sections.find(sec => sec.slug === "whybuyworks");
      let text_objects = buy.text;
      if (text_objects.length > 0) {
        for (let i = 0; i < text_objects.length; i++) {
          let text = text_objects[i];
          data.push({
            title: text.title,
            content: parse(text.content)
          });
        }
      }
    }
    return data;
  };

  renderSellTitle = () => {
    const { cms_data } = this.props;
    if (cms_data.whykayfikworks) {
      let sections = cms_data.whykayfikworks.sections;
      let sell = sections.find(sec => sec.slug === "whysellworks");
      let title = sell.title;
      if (title.includes("__")) {
        return null;
      } else return sell.title;
    }
  };
 
  renderSellAccordian = () => {
    const { cms_data } = this.props;
    let data = [];
    if (cms_data.whykayfikworks) {
      let sections = cms_data.whykayfikworks.sections;
      let sell = sections.find(sec => sec.slug === "whysellworks");
      let text_objects = sell.text;
      if (text_objects.length > 0) {
        for (let i = 0; i < text_objects.length; i++) {
          let text = text_objects[i];
          data.push({
            title: text.title,
            content: parse(text.content)
          });
        }
      }
    }
    return data;
  };

  render() {
    const { cms_data, classes, direction, global_fetching } = this.props;
    if (cms_data.whykayfikworks) {
      let sections = cms_data.whykayfikworks.sections;
      let whykayfikworks = sections.find(sec => sec.slug === "whykayfikworks");
      if (whykayfikworks) {
        return (
          <div id="landing-top">
            {/* For SEO */}
            <Helmet>
              <title>{cms_data.whykayfikworks.title}</title>
              <meta
                name="description"
                content={cms_data.whykayfikworks.meta_description}
              />
            </Helmet>
            {global_fetching ? <Loader fixed /> : null}
            <Header />
            <ScrollToTop />
            <div className="cd-section main-background-color">
              <GridContainer className="bg-y">
                <GridItem
                  xs={11}
                  sm={10}
                  md={10}
                  className={classNames(
                    classes.container,
                    "header-container px-0"
                  )}
                >
                  <h2 className={classNames("header-title m-0 py-3 p-3")}>
                    {whykayfikworks.title}
                  </h2>
                  <div className="d-flex cms_subTitle">
                    {this.renderFaqHeader(whykayfikworks.text)}
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center" className="min-70vh">
                <GridItem xs={12} md={10} className="px-0">
                  <GridContainer>
                    <GridItem xs={12} md={6}>
                      <h2
                        className={
                          direction === "ltr"
                            ? "htPrimaryGreen section-title"
                            : "htPrimaryGreen section-titleAR"
                        }
                      >
                        {this.renderBuyTitle()}
                      </h2>
                      <LeftAccordian
                        activeColor="htPrimaryLight"
                        color="htPrimaryLight"
                        classes={{
                          title:
                            direction === "ltr"
                              ? "color-pl accordian-title"
                              : "color-pl accordian-titleAR",
                          iconColor: "colorYellow fsize-30",
                          expansionPanelSummary: classNames(classes.noBorder),
                          expansionPanelDetails:
                            direction === "ltr"
                              ? "htPrimaryGreen  section-des dived"
                              : "htPrimaryGreen  section-desAR dived"
                        }}
                        collapses={this.renderBuyAccordian()}
                        arrow={direction === "ltr" ? "left" : null}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <div
                        className={
                          direction === "rtl"
                            ? "floating-bg-ar bg-grey"
                            : "floating-bg bg-grey"
                        }
                      />
                      <h2
                        className={
                          direction === "ltr"
                            ? "htPrimaryGreen section-title position-relative"
                            : "htPrimaryGreen section-titleAR position-relative"
                        }
                      >
                        {this.renderSellTitle()}{" "}
                      </h2>
                      <LeftAccordian
                        activeColor="htPrimaryLight"
                        color="htPrimaryLight"
                        classes={{
                          title:
                            direction === "ltr"
                              ? "color-pl accordian-title"
                              : "color-pl accordian-titleAR",
                          iconColor: "colorYellow fsize-30",
                          expansionPanelSummary: classNames(
                            classes.noBorder,
                            "bg-grey-md"
                          ),
                          expansionPanelDetails:
                            direction === "ltr"
                              ? "htPrimaryGreen  section-des dived bg-grey-md"
                              : " grey-background htPrimaryGreen  section-desAR dived bg-grey-md"
                        }}
                        collapses={this.renderSellAccordian()}
                        arrow={direction === "ltr" ? "left" : null}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </div>
            <BottomBar />
            <CustomFooter />
          </div>
        );
      }
    }
    return <Loader fixed />;
  }
}
const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getCMSData: code => {
    dispatch({
      type: GlobalActionTypes.CMS_PAGE_REQUEST,
      code
    });
  }
});
const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(projectsStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(WhyKayfikWorks);
