import React from "react";
import classNames from "classnames";
import compose from "recompose/compose";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Link as RouterLink } from "react-router-dom";
// React i18next
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
import checkoutStyles from "kayfik/assets/jss/views/Checkout/checkoutStyles.jsx";

// Sections
import CartActionTypes from "constants/cartConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Muted from "components/Typography/Muted.jsx";
import { decimalPlace } from "utils/helpers.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";



class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1,
      editing: null
    };
  }

  renderCartItems = () => {
    const { review_checkout, t } = this.props;
    let lines = [];
    if (review_checkout) {
      lines = review_checkout.basket_info.lines;
    }
    return (
      <div className="cartContainerScroll mb-3">
        {lines.length > 0 ? (
          lines.map((item, i) => {
            let prc = item.price_incl_tax_excl_discounts;
            if (item.from_bid) {
              prc = item.line_price;
            }
            return (
              <GridItem xs={12} className="marginAuto p-0 mt-4" key={i}>
                <CardBody className="p-0">
                  <GridContainer className="w-100 m-0">
                    <GridItem xs={4} sm={4} md={4} className="px-0">
                      <img
                        src={item.image}
                        className={classNames("img-fluid")}
                        alt="..."
                      />
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <GridContainer className="mx-0 h-100">
                        <GridItem xs={12} className="pl-2 dp-f">
                          <div className="pl-2 dp-f fd-c justify-content-between">
                            <p className="mt-0">{item.title}</p>
                            <p>
                              {item.price_currency} {decimalPlace(prc)}
                            </p>
                            <GridContainer className="mx-0 color-pl">
                              <GridItem
                                xs={6}
                                className="cartActionButtons p-0"
                              >
                                <Link
                                  underline="none"
                                  className="color-pl"
                                  component="button"
                                  onClick={this.deleteItem(item)}
                                >
                                  <p className="m-0">
                                    {t("buyingPage.checkOut.reviewcart.remove")}
                                  </p>
                                </Link>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </GridItem>
            );
          })
        ) : (
          <GridItem xs={12} className="marginAuto p-0 mt-4">
            <CardBody className="p-0">
              <p>{t("buyingPage.checkOut.reviewcart.empty")} </p>
              <Link
                underline="none"
                component={RouterLink}
                to="/shop"
                className="color-pl"
              >
                <p>{t("buyingPage.checkOut.reviewcart.continueShopping")} </p>
              </Link>
            </CardBody>
          </GridItem>
        )}
      </div>
    );
  };

  openModal = (modal_type, modal_props = { open: true }) => async () => {
    const { modalProps, closeModal, showModal } = this.props;

    if (modalProps && modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };


  deleteItem = line => () => {
    const { deleteItemRequest } = this.props;
    let url = line.url;
    if(line.from_instant_cash){
      let mprops={
        open: true,
        lineId: line.id
      }
      this.openModal("iCLineDeleteModal", mprops)();
    }else{
      deleteItemRequest(url);
    }
  };

  getSubtotal = basket_info => {
    if (basket_info && basket_info.basket_has_bid_line) {
      return decimalPlace(basket_info.with_bid_total);
    }
    return decimalPlace(basket_info.total_incl_tax_excl_discounts);
  };

  gettotal = basket_info => {
    if (basket_info && basket_info.total_incl_tax) {
      return decimalPlace(basket_info.total_incl_tax);
    }
  };

  renderOfferValues = () => {
    const { review_checkout} = this.props;

    //For General discounts Offer
    let offers =
      review_checkout && review_checkout.basket_info.offer_discounts.length > 0
        ? review_checkout.basket_info.offer_discounts
        : null;

    if (offers && offers.length > 0) {
      //For General discounts Offer
      return offers.map((item, i) => {
        let amount;
        if (item.amount > 0) {
          amount = item.amount * -1;
        } else {
          amount = item.amount * -1;
        }
        return (
          <div className="dp-f justify-content-between text-uppercase" key={i}>
            <h6 className="mt-0">{item.name}</h6>
            <h6 className="mt-0">
              {review_checkout.basket_info.currency} {decimalPlace(amount)}
            </h6>
          </div>
        );
      });
    }
  };

  removeVoucher = voucher_code => {
    const { deleteVoucher } = this.props;
    if (voucher_code) {
      let data = {
        "vouchercode": voucher_code
      };
      deleteVoucher(data);
    }
  };

  //For voucher Offer
  renderVoucherValues = () => {
    const { review_checkout , t } = this.props;

    let vouchers =
      review_checkout &&
      review_checkout.basket_info.voucher_discounts.length > 0
        ? review_checkout.basket_info.voucher_discounts
        : null;

    if (vouchers && vouchers.length > 0) {
      //For voucher Offer
      return vouchers.map((item, i) => {
        let amount;
        if (item.amount > 0) {
          amount = item.amount * -1;
        } else {
          amount = item.amount * -1;
        }
        return (
          <div className="dp-f justify-content-between text-uppercase" key={i}>
            <h6 className="mt-0">
              {t("buyingPage.checkOut.customerInformation.discount")}{" "}
              <span
                className="remove-voucher mb-0"
                onClick={() => this.removeVoucher(item.voucher.code)}
              >
                {t("buyingPage.checkOut.reviewcart.remove")}
              </span>
            </h6>
            <h6 className="mt-0">
              {review_checkout.basket_info.currency} {decimalPlace(amount)}
            </h6>
          </div>
        );
      });
    }
  };

  renderTotalSection = () => {
    const { review_checkout, t } = this.props;

    let basket_info = null;
    if (review_checkout) {
      basket_info = review_checkout.basket_info;
    }

    if (basket_info && basket_info.total_incl_tax > 0) {
      return (
        <div>
          <Divider />
          <Muted>
            <div className="dp-f justify-content-between text-uppercase">
              <h6>{t("buyingPage.checkOut.reviewcart.subtotal")}</h6>
              <h6>
                {basket_info.currency} {this.getSubtotal(basket_info)}
              </h6>
            </div>
            {this.renderOfferValues()}
            {this.renderVoucherValues()}
            <div className="dp-f justify-content-between text-uppercase">
              <h6 className="mt-0">
                {t("buyingPage.checkOut.reviewcart.shipping")}
              </h6>
              <h6 className="mt-0">
                {t("buyingPage.checkOut.reviewcart.free")}
              </h6>
            </div>
          </Muted>
          <Divider />
          <div className="mt-3 dp-f justify-content-between htPrimaryGreen text-uppercase">
            <h5 className="mt-0">
              {t("buyingPage.checkOut.reviewcart.total")}
            </h5>
            <h5 className="mt-0">
              {basket_info.currency} {this.gettotal(basket_info)}
            </h5>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const { className, t } = this.props;
    return (
      <GridItem xs={12} md={4} className={className}>
        <h3>{t("buyingPage.checkOut.reviewcart.title")}</h3>
        {this.renderCartItems()}
        {this.renderTotalSection()}
      </GridItem>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteItemRequest: url => {
    dispatch({
      type: CartActionTypes.DELETE_ITEM_REQUEST,
      payload: url
    });
  },
  updateCartRequest: data => {
    dispatch({
      type: CartActionTypes.UPDATE_CART_REQUEST,
      payload: data
    });
  },
  showSnackbar: txt => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  deleteVoucher: data => {
    dispatch({
      type: CartActionTypes.REMOVE_VOUCHER_REQUEST,
      payload: data
    });
  }
});

const mapStateToProps = state => ({
  ...state.productReducer,
  ...state.cartReducer,
  ...state.globalReducer,
  ...state.modalReducer
});

export default compose(
  withStyles(checkoutStyles),
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(Cart);
