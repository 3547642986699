import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.jsx";
import {
  blackColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

const carouselSectionStyle = theme => ({
  ...headersStyle(theme),
  carouselHt: {
    [theme.breakpoints.down("md")]: {
      // height: " 55vh !important"
    }
  },
  [theme.breakpoints.down("md")]: {
    title: {
      margin: 0 + "!important"
    },
  },
  btnSize: {
    fontWeight: "600 !important"
  },
  [theme.breakpoints.down("sm")]: {
    btnSize: {
      fontSize: "10px !important",
      padding: "6px 8px!important",
      fontWeight: "600 !important"
    }
  },
  btnSizeAR: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: "400 !important"
    }
  },  
  removeBg: {
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.0) !important"
    },
  }
});

export default carouselSectionStyle;
