import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// custom core component
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import AccountActionTypes from "constants/accountConstants.jsx";
import queryString from "query-string";
import IconButton from "@material-ui/core/IconButton";

// eslint-disable-next-line react/prop-types
class ConfirmEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      email: ""
    }
  }
  componentDidMount() {
    const { closeModal } = this.props;

    let params = this.getExtraParameter();
    if (params.key && params.email) {
      this.setState({ key: params.key, email: params.email });
    } else {
      closeModal();
    }
  }

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);

    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }

    return params;
  };

  handleSubmit = e => {
    const { postConfirmEmailKey } = this.props;
    e.preventDefault();

    let form_data = {
      key: e.target.key.value,
    };
    postConfirmEmailKey(form_data);
  };

  render() {
    const { classes, closeModal, t ,direction} = this.props;
    const { key, email } = this.state;
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(classes.modalCloseButton,"previewClose",direction==="rtl"?classes.modalSmallCloseButtonAR:null)}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {t("confirmemail.title")}
              </h2>
              <h4 className="m-0 mb-3">
                {t("confirmemail.message", { email })}
              </h4>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <CustomInput
                  id="key"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "key",
                    required: true,
                    type: "hidden",
                    value: key,
                    disableUnderline: true
                  }}
                />

                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  className="mb-3"
                  fullWidth
                  type="submit"
                >
                 {t("confirmemail.confirm")}
                </Button>
              </form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  postConfirmEmailKey: form_data => {
    dispatch({
      type: AccountActionTypes.CONFIRM_EMAIL_REQUEST,
      form_data
    });
  }
});

ConfirmEmailModal.propTypes = {
  postConfirmEmailKey: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(ConfirmEmailModal);
