import React from "react";
import classNames from "classnames";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
// Core material ui component
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
// react component used to create nice image meadia player
// core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// React i18next
import { withTranslation } from "react-i18next";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// actions
import ModalActionTypes from "constants/modalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";

import mapMarker from "kayfik/assets/img/map_marker.png";
import currentLocation from "kayfik/assets/img/current_loc.png";
// import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
// eslint-disable-next-line react/prop-types
import { store } from "utils/store.jsx";

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      key={store.getState().addressReducer.mapLoader}
      zoom={props.zoom}
      ref={props.onMapMounted}
      onDragEnd={props.onDragEnd}
      fullscreenControl={false}
      animation={window.google.maps.Animation.DROP}
      // center={center}
      defaultCenter={{ lat: props.position.lat, lng: props.position.long }}
      // onCenterChanged={props.onCenterChanged}
      defaultOptions={{
        gestureHandling: "greedy",
        zoomControl: true,
        fullscreenControl: false,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
    </GoogleMap>
  ))
);

class MapModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 29.3759,
      long: 47.9774, //defaultLocation
      results: {},
      zoom:14,
      disableContinue: false
    };
    // this.onMapMounted = this.onMapMounted.bind(this);
    this.map = React.createRef();
  }
  onMapMounted = (ref) => {
    this.map = ref;
  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal, address } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    let props = {};

    if (
      modalProps.calledFrom === "partner-address" &&
      modal_type !== "profileModal"
    ) {
      props = { closeModal: this.openModal("profileModal") };
    } else if (modalProps) {
      let confirm_pickup_data = modalProps.confirm_pickup_data;
      if (confirm_pickup_data) {
        props["confirm_pickup_data"] = confirm_pickup_data;
      }
    }
    this.props.showModal(
      {
        open: true,
        title: modal_type,
        address: address === "pickup" ? address : null,
        modalLarge:
          modal_type === "whatSellingModal" || modal_type === "profileModal"
            ? true
            : false,
        calledFrom: modalProps.calledFrom,
        ...props
      },
      modal_type
    );
  };

  mapPosition = () => {
    const { updateMapDragged } = this.props;
    let a = this.map.getCenter();
    if(a){
      this.setState({ lat: a.lat(), long: a.lng() }, () => {
        this.getAddress(this.state.lat, this.state.long);
      });
      updateMapDragged(true);
    }
    else{
      console.log("FAILED TO GET MAP POSITION USING this.map.getCenter()")
    }
  };


  getAreadandBlock=(data)=>{
    let resultList = data.results;
    let area_add = null;
    let block_add = null;
    if(resultList.length>0){
      let areObj = resultList.filter(i=>i.types && i.types.includes("sublocality"))
      area_add = areObj[0];
      if(area_add!== undefined){
       
      }
       
      let blockObj = resultList.filter(i=>i.types && i.types.includes("neighborhood"))
 
      block_add = blockObj[0];
      if(block_add!== undefined){
      }
      if(area_add||block_add){
        return {
          formatted_area:area_add !== undefined? area_add.formatted_address : null, 
          formatted_block:block_add !== undefined ? block_add.formatted_address : null
        }
      }
    }
  }


  getAddress = (myLat, myLon) => {
    const { showSnackbar, t } = this.props;
    let thisComp = this;

    if (myLat && myLon) {
      let url =
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        myLat +
        "," +
        myLon +
        "&key=" +
        "AIzaSyDY7f3XcceQHopw07PasTSTtI7GsFmzo-Y";
        fetch(url,
        )
        .then(response => response.json())
        .then(responseJson=>{
          let data = responseJson;

          thisComp.setState({ results: data }, () => {
            thisComp.getAddressFromMap();
          })
        })
          .catch(function (error) {
            // handle error
            let txt = t(
              "profileModal.accountSettings.shippingAddress.mapModal.error"
            );
            showSnackbar(txt, "warning");
            return;
          });

      // axios({
      //   method: "get",
      //   url: url,
      //   withCredentials: false
      // })
      //   .then(function (response) {
      //     let data = response.data;
      //     console.log("FOCUSED ADD", data);
      //     thisComp.setState({ results: data }, () => {
      //       thisComp.getAddressFromMap();
      //     });
      //   })

    } else {
      let txt = t(
        "profileModal.accountSettings.shippingAddress.mapModal.error"
      );
      showSnackbar(txt, "warning");
      return;
    }
  };


  getmapdata = () => {
    const {
      guest_address,
      address_list,
      partner_address,
      add_address_event,
      company_address,

      map_address

    } = this.props;

    if (!_.isEmpty(map_address)) {
      setTimeout(() => {
    
        let latVal = parseFloat(map_address.latitude);
        let longVal = parseFloat(map_address.longitude);
        this.setState(
          {
            lat: latVal,
            long: longVal
          },
          () => {
        
            this.updateMapData(this.state.lat, this.state.long)
          }
        );
      }, 500);
    }
    else {
      if (add_address_event === "anonymous" && guest_address) {
        this.setState(
          {
            lat: guest_address.shipping_address.json_address.latitude,
            long: guest_address.shipping_address.json_address.longitude,
          },
          () => {
            this.updateMapData(this.state.lat, this.state.long)
          }
        );
      } else if (
        add_address_event === "loggedin_shipping" &&
        address_list.length > 0
      ) {
        let address = address_list[0];
        let addLat = parseFloat(address.address.latitude);
        let addLong = parseFloat(address.address.longitude);
        this.setState(
          {
            lat: addLat,
            long: addLong
          },
          () => {
            this.updateMapData(this.state.lat, this.state.long)
          }
        );
      } else if (
        add_address_event === "loggedin_partner" &&
        partner_address &&
        partner_address.address.latitude &&
        partner_address.address.longitude
      ) {
        this.setState(
          {
            lat: partner_address.address.latitude,
            long: partner_address.address.longitude
          },
          () => {
            this.updateMapData(this.state.lat, this.state.long)
          }
        );
      } else if (
        add_address_event === "company_signup" &&
        company_address &&
        company_address.latitude &&
        company_address.longitude
      ) {
        this.setState(
          {
            lat: company_address.latitude,
            long: company_address.longitude
          },
          () => {
            this.updateMapData(this.state.lat, this.state.long)
          }
        );
      }
      else {
        setTimeout(() => {
          if (this.props.user_current_location) {
           
           
            this.setState(
              {
                lat: this.props.user_current_location.currentLat,
                long: this.props.user_current_location.currentLong
              },
              () => {
          
                this.updateMapData(this.state.lat, this.state.long)
              }
            );
          }
          else {
         
            this.getAddress(this.state.lat, this.state.long);
          }
        }, 500);
      }
    }
  }

  updateMapData = (lat, long) => {
    if (this.map.props) {
      let latVal = parseFloat(lat);
      let longVal = parseFloat(long);
      this.map.panTo({ lat: latVal, lng: longVal})
      this.getAddress(latVal, longVal);
    }
  }
  componentDidMount() {
    const {
      // guest_address,
      // address_list,
      // partner_address,
      // add_address_event,
      // company_address,
      getAreaList
    } = this.props;

    // Update the address object once this is loaded and just update
    // again when map was changed to avoid to get error about unmounted
    // component.
    getAreaList();
    this.getmapdata();
  }

  getAddressFromMap = () => {
    const { updateMapAddress } = this.props;

    let area = null;
    let block = null;
    // let street = null;
    let map_data = {};

    const { results, lat, long } = this.state;

    
    // Process map data and assigned it to area, block, street
    if (results && results.status === "OK") {
      // area
      let addObj=this.getAreadandBlock(results);
      if(addObj){
        let area_temp = addObj.formatted_area;
        if(area_temp){
          area_temp = area_temp.split(",");
          area_temp = area_temp[0];
        }
        let block_temp = addObj.formatted_block;
        if(block_temp){
          block_temp = block_temp.split(",");
          if(area_temp === null && block_temp.length>2){
            area_temp = block_temp[1];
            area_temp = area_temp.slice(1,area_temp.length);
          }
          block_temp = block_temp[0];
        }
        area = area_temp;
        //block
        block = block_temp;
  
  
        let selectedArea = area.toLowerCase().replace(/ /g, "-");
        map_data = {
          area: area,
          area_id: selectedArea,
          block: block,
          latitude: lat,
          longitude: long
        };
        updateMapAddress(map_data);
      }
      else{
        updateMapAddress(null);
      }
      
    } else {
      updateMapAddress(null);
    }
  };

  confirmClicked = () => {
    const {
      modalProps,
      closeModal,
      area_list,
      map_address,
      t,
      updateActiveArea
    } = this.props;

    if (modalProps.showOnlyMap) {
      closeModal();
    } else {
      let valid_area = null;
      if (area_list && !_.isEmpty(map_address) && map_address.area_id) {
        valid_area = area_list.find(item => item.code === map_address.area_id);
      }
      if (valid_area) {
        let value = {
          area: map_address.area,
          area_id: map_address.area_id
        };
        updateActiveArea(value);
        this.openModal("confirmAddress")();
      } else {
        window.confirm(
          t("profileModal.accountSettings.shippingAddress.mapModal.changeArea")
        );
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
 
    if (prevProps.mapLoader !== this.props.mapLoader) {
      this.setState({
        rerenderDummy: new Date(),
      });
      // this.drawRoutes();
    }
  }
  // onCenterChanged=({onCenterChange})=>{
  //   // onCenterChange(this.map.getCenter());
  //   this.map.center = this.map.getCenter();
  // }
  goToCurrentLocaton=()=>{
    const { t } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
            // const r = {
            //   latitude: position.coords.latitude,
            //   longitude: position.coords.longitude,
            //   latitudeDelta: 0.007,
            //   longitudeDelta: 0.007,
            // };
            // this.map.animateToRegion(r, 2000); 

         this.setState(
            {
              lat: position.coords.latitude,
              long: position.coords.longitude,
              zoom:15
            },
            () => {
              this.updateMapData(this.state.lat, this.state.long);
            }
          );
        },
        error => {window.confirm(
          t("profileModal.accountSettings.shippingAddress.mapModal.permissionError")
        );
      }
        , { enableHighAccuracy: true, timeout: 200000 }
      );
    }


  }

  render() {
    const {
      classes,
      closeModal,
      direction,
      t,
      modalProps,

    } = this.props;
    var { lat, long, disableContinue } = this.state;

    var latVal = parseFloat(lat);
    var longVal = parseFloat(long);

    return (
      <DialogContent
        id="classic-modal-slide-description"
        className={classNames(classes.modalBody, "p-0 m-0")}
      >
        <IconButton
          simple="true"
          className={classNames(
            classes.modalCloseButton,
            "previewCloseLTR  mt-3",
            direction === "rtl" ? classes.modalSmallCloseButtonAR : null
          )}
          key="close"
          aria-label="Close"
          onClick={closeModal}
        >
          {" "}
          <Close
            className={classNames(
              classes.modalClose,
              classes.closeStyle,

            )}
          />
        </IconButton>
        <GridContainer className="mapContainer p-0 m-0">
          <img src={mapMarker} className="centered" alt="..." />
          <img src={currentLocation} className="centered currentLocation" alt="..." onClick={this.goToCurrentLocaton} />
  
          <CustomSkinMap
            onMapMounted={this.onMapMounted}
            onDragEnd={this.mapPosition}
            zoom={this.state.zoom}
            // onCenterChanged={this.onCenterChanged}
            position={{ lat: latVal, long: longVal }}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDY7f3XcceQHopw07PasTSTtI7GsFmzo-Y"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: `100%`,
                  width: `100%`,
                  borderRadius: "6px",
                  overflow: "hidden"
                }}
              />
            }
            mapElement={<div style={{ height: `100%`, width: `100%` }} />}
          />
        </GridContainer>
        <GridContainer
          id="confirm"
          className="p-0 m-0 dp-f jcc confirmContainer"
        >
          <GridItem xs={12} sm={10} md={10} className="marginAuto ">
            {modalProps.address === "pickup" ? (
              <h5 className="dp-f mt-3 jcc">
                {t(
                  "profileModal.accountSettings.shippingAddress.mapModal.pickup"
                )}
              </h5>
            ) : (
                <h5 className="dp-f mt-3 jcc">
                  {t(
                    "profileModal.accountSettings.shippingAddress.mapModal.choose"
                  )}
                </h5>
              )}

            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              fullWidth
              disabled={disableContinue}
              onClick={this.confirmClicked}
            >
              {t(
                "profileModal.accountSettings.shippingAddress.mapModal.confirm"
              )}
            </Button>
            <Button
                link
                size="lg"
                className="mb-3"
                fullWidth
                onClick={this.openModal("confirmAddress")}
              >
               {t("profileModal.accountSettings.shippingAddress.skip")}
              </Button>
          </GridItem>
        </GridContainer>
      </DialogContent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  updateMapAddress: address => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_ADDRESS,
      address
    });
  },
  updateActiveArea: activeArea => {
    dispatch({
      type: AddressActionTypes.UPDATE_ACTIVE_AREA,
      activeArea
    });
  },
  getAreaList: () => {
    dispatch({
      type: AddressActionTypes.GET_AREA_LIST_REQUEST
    });
  },
  updateMapDragged: (mapDragged) => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_DRAGGED,
      mapDragged
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.addressReducer,
  ...state.vendorProductReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MapModal);
