import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import _ from "lodash";
// Core material ui components
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import { removeParam } from "utils/helpers.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import IconButton from "@material-ui/core/IconButton";
import { checkForInstantCash } from "actions/comboProductActions.jsx";
import api from "utils/api.jsx";

const sortAlphaNum = (a, b) =>
  a.label.localeCompare(b.label, "en", { numeric: true });

class WhatSellingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetData: false
    };
    const {
      updateProductClassList,
      updateProductDuplicateList,
      account_token
    } = this.props;
    updateProductClassList();
    if (account_token) {
      updateProductDuplicateList();
    }
    this.props.getTimeWindows("pickup");
    this.props.updatetempModalProps({});
  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type,
        modalLarge: modal_type === "imagesModal" ? true : false,
        activeSell: true
      },
      modal_type
    );
  };

  handleChangeKind = event => {
    const {
      updateProductClassList,
      updateProductDuplicateList,
      resetCombo,
      updateListingKind,
      account_token
    } = this.props;
    // clear selected items
    resetCombo();
    updateProductClassList();
    if (account_token) {
      updateProductDuplicateList();
    }
    updateListingKind(event.target.value);
  };



  handleSelectChange = async value => {
    const { updateSelectedProductClass } = this.props;
    if (value) {
      await updateSelectedProductClass({ text: value.slug, id: null });
      this.loadProductClassSelection();
    }
  };

  handleCategoryChange = async value => {
    const { updateSelectedCategory,filteredProductClass,updateSelectedProductClass } = this.props;
    if (value) {
      await updateSelectedCategory({ text: value.category_id, id: null });
      await updateSelectedProductClass({ text: "", id: null });
      this.loadProductClassSelection();
      filteredProductClass(value.product_classes);
    }
  };

  handleSelectChangeCondition = value => {
    const { updateSelectedCondition } = this.props;
    updateSelectedCondition(value.id, value)
  };

  loadProductClassSelection = () => {
    const {
      product_class_list,
      updateProductClassAttr,
      updateSelectedAttr,
      selected_product_class,
      updateSelectedProductClass
    } = this.props;

    let product_class_obj = product_class_list.find(
      pc =>
        pc.slug &&
        pc.slug.toLowerCase() === selected_product_class.text &&
        selected_product_class.text.toLowerCase()
    );
    // clear selected attr every new request
    updateSelectedAttr([]);
    if (product_class_obj) {
      updateProductClassAttr(product_class_obj.filtered_url);
      let new_product_obj = { ...selected_product_class };
      new_product_obj.id = product_class_obj.id;
      updateSelectedProductClass(new_product_obj);
    }
  };

  renderCustomMenuItems = values => {
    // process menuItems
    let menuItems = [];
    for (let i = 0; i < values.length; i++) {
      let attr_val = values[i];
      menuItems.push({
        value: attr_val.id,
        label: attr_val.option
      });
    }
    return menuItems;
  };

  resetDataFunc = () => {
    const {
      updateSelectedAttr,
      updateOther,
      updateSelectedOther,
      updateInstantCashObj,
      updateSelectedCategory,
      updateSelectedProductClass,
      updateSelectedCondition
    } = this.props;
    updateSelectedCategory({ text: "", id: null });
    updateSelectedProductClass({ text: "", id: null });
    updateSelectedAttr([]); //Reset the Seleceted Attributes
    updateOther(""); //Reset the  other  Input Data
    updateSelectedOther([]); // Reset the whole other  Attributes
    // this.loadProductClassSelection(); //Load the product details
    updateSelectedCondition(0, null);
    this.setState({ resetData: !this.state.resetData });
    updateInstantCashObj(null);
  };
  move = (from, to, arrayData) => {
    arrayData.splice(to, 0, arrayData.splice(from, 1)[0]);
  };

  byKey = key => {
    return function (o) {
      var v = parseInt(o[key], 10);
      return isNaN(v) ? o[key] : v;
    };
  };

  getValue = id => {
    const { is_other } = this.props;
    let selected_data = is_other.toString();
    if (selected_data.length > 0) {
      let a = is_other.find(i => i.attr_id === id);
      if (a) {
        return a.value_name;
      }
    }
  };

  renderDynamicSelect = () => {
    const {
      current_product_class_attributes,
      selected_attr_and_value,
      combo_fetching,
      userDetails,
      t
    } = this.props;

    //  ******************** implementation for "Other Option" under each category *************

    let selected_data = selected_attr_and_value.toString(); // converting object to string to check the length

    if (selected_data.length > 0) {
      var selected_index_value = [];
      for (var i = 0, size = selected_attr_and_value.length; i < size; i++) {
        let selected_index_id = selected_attr_and_value[i].selected_index;
        let selected_value_label = selected_attr_and_value[i].value_id;
        if (selected_value_label === "other") {
          selected_index_value.push(selected_index_id);
        }
      }
    }

    //  ********************e********** Ending Code *******************************************

    if (current_product_class_attributes.length > 0) {
      return (
        <>
        <GridContainer>
          {current_product_class_attributes.map((attr, index) => {
            // check the items of selected attribute and value
            let input_value = "";
            if (
              selected_attr_and_value !== null &&
              selected_attr_and_value.length !== 0
            ) {
              let value_found = selected_attr_and_value.find(
                attr_value => attr_value.attr_id === attr.id
              );

              // assign the found object to the input value
              if (value_found) {
                input_value = value_found.value_id;
              }
            }

            let menuItems = this.renderCustomMenuItems(attr.values);
            let othr = menuItems.find(
              item => item.label.toLowerCase().indexOf("other") !== -1
            );
            if (othr) {
              let othrIndex = menuItems.indexOf(othr);
              this.move(othrIndex, menuItems.length - 1, menuItems);
            }
            let sortedMenuItems = _.sortBy(menuItems, o => o.label);
            sortedMenuItems = sortedMenuItems.sort(sortAlphaNum);

            // append the "other" label into sorted items
            sortedMenuItems.push({
              value: "other",
              label: t("sellingPage.whatSellingModal.other_label")
            });

            let col = 12;
            if (index !== 0) {
              col = 6;
            }
            var input_name = "attr_" + attr.id;
            return (
              <GridItem
                xs={12}
                sm={12}
                md={col}
                key={index}
                id={"gridselect" + attr.id}
              >
                <SelectAutocomplete
                  useFilterOption={true}
                  maxMenuHeight={145}
                  name={input_name}
                  otherLabel={t("sellingPage.whatSellingModal.other_label")}
                  inputId={"select" + input_name}
                  required={true}
                  value={
                    sortedMenuItems.length > 0
                      ? sortedMenuItems.filter(i => i.value === input_value)
                      : null
                  }
                  placeholder={t("sellingPage.whatSellingModal.selectbrand", {
                    attr: attr.name.charAt(0).toUpperCase() + attr.name.slice(1)
                  })}
                  labelText={attr.name}
                  controlProps={{ marginBottom: "15px" }}
                  inputProps={{
                    required: true,
                    disabled: combo_fetching ? true : false
                  }}
                  autocompleteOptions={sortedMenuItems}
                  selectOnChange={this.handleSelectAttrChange(attr, index)}
                />
                {selected_index_value &&
                  selected_index_value.includes(index) ? (
                    <CustomInput
                      labelText={
                        t("sellingPage.whatSellingModal.other_header") +
                        " " +
                        attr.name
                      }
                      id={input_name}
                      size="lg"
                      className="circle"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: input_name,
                        required: true,
                        disableUnderline: true,
                        defaultValue: this.getValue(attr.id),
                        onChange: this.handleOtherChange(attr, index),
                        onFocus: this.otherInputFocus(attr.id)
                      }}
                    />
                  ) : null}
              </GridItem>
            );
          })}
        </GridContainer>
        <GridContainer className="sell_qty">
          {userDetails && userDetails.is_vendor_type === "COMPANY" && userDetails.company_approved === true ?this.renderQuantity():null}
        </GridContainer>
        </>
      );
    }
  };

  handleAttrChange = (e, attr) => {
    const {
      selected_attr_and_value,
      updateSelectedAttr,
      updateProductClassAttr,
      current_product_class_attr_url
    } = this.props;

    let new_object = [...selected_attr_and_value];

    let state_attr = new_object.find(
      selected_attr => selected_attr.attr_id === attr.id
    );

    let state_attr_index = new_object.findIndex(
      selected_attr => selected_attr.attr_id === attr.id
    );

    if (state_attr && state_attr_index >= 0) {
      state_attr.value_id = e.target.value;
      state_attr.value_name = e.target.options[e.target.selectedIndex].text;
      //replacing the current item using splice
      new_object.splice(state_attr_index, 1, state_attr);
    } else {
      new_object.push({
        attr_id: attr.id,
        attr_name: attr.name,
        value_id: e.target.value,
        value_name: e.target.options[e.target.selectedIndex].text
      });
    }
    // update the options while changing attributes selected

    if (current_product_class_attr_url !== null && e.target.value) {
      let url = removeParam("selected_ids", current_product_class_attr_url);
      let result = new_object.map(a => a.value_id);
      let qmark = url.indexOf("?") !== -1 ? "" : "?";
      let new_params = qmark + "selected_ids=" + result;
      url = url + new_params;
      updateProductClassAttr(url);
    }
    updateSelectedAttr(new_object);
  };

  handleSelectAttrChange = (attr, index) => value => {
    const {
      selected_attr_and_value,
      updateSelectedAttr,
      updateProductClassAttr,
      current_product_class_attr_url
    } = this.props;

    let new_object = [...selected_attr_and_value];
    let state_attr = new_object.find(
      selected_attr => selected_attr.attr_id === attr.id
    );
    let state_attr_index = new_object.findIndex(
      selected_attr => selected_attr.attr_id === attr.id
    );
    if (value) {
      if (state_attr && state_attr_index >= 0) {
        state_attr.value_id = value.value;
        state_attr.value_name = value.label;
        //replacing the current item using splice
        new_object.splice(state_attr_index, 1, state_attr);
      } else {
        new_object.push({
          attr_id: attr.id,
          attr_name: attr.name,
          value_id: value.value,
          value_name: value.label,
          selected_index: index // Saving index id of each selection to the redux
        });
      }
      // update the options while changing attributes selected
      if (current_product_class_attr_url !== null && value.value) {
        let url = removeParam("selected_ids", current_product_class_attr_url);
        let result = new_object.map(a => {
          if (a.value_id !== "other") {
            return a.value_id;
          } else {
            return "";
          }
        });
        let qmark = url.indexOf("?") !== -1 ? "" : "?";
        let new_params = qmark + "selected_ids=" + result;
        url = url + new_params;
        updateProductClassAttr(url, attr.id); // fetch url if other attributes not selected in any category
      }
      updateSelectedAttr(new_object);
    }
  };

  handleOtherChange = (attr, index) => e => {
    const { updateOther, is_other } = this.props;

    let other_object = [...is_other];
    let other_attr = other_object.find(
      selected_attr => selected_attr.attr_id === attr.id
    );

    let other_attr_index = other_object.findIndex(
      selected_attr => selected_attr.attr_id === attr.id
    );

    if (other_attr && other_attr_index >= 0) {
      other_attr.value_id = "other";
      other_attr.value_name = e.target.value;
      //replacing the current item using splice
      other_object.splice(other_attr_index, 1, other_attr);
    } else {
      other_object.push({
        attr_id: attr.id,
        attr_name: attr.name,
        value_name: e.target.value,
        value_index: index // Saving index id of each selection to the redux
      });
      updateOther(other_object);
    }
  };

  otherInputFocus = category_id => () => {
    const { updateSelectedOther, is_other } = this.props;
    updateSelectedOther({ id: category_id, data: is_other });
  };

  handleDuplicateChange = value => {
    const {
      product_duplicate_list,
      updateProductClassAttr,
      updateSelectedAttr,
      selected_attr_and_value,
      updateSelectedProductClass,
      selected_product_class,
      updateListToDuplicate,
      updateSelectedDuplicateItem,
      updateBid,
      updateBuy,
      updateDescriptionEN,
      updateDescriptionAR,
      updatePreviousImages
    } = this.props;

    let active_duplicate = product_duplicate_list.find(
      dup => dup.id === parseInt(value.value)
    );
    let new_object = [...selected_attr_and_value];

    if (active_duplicate) {
      // set initial value from the selected ids in the url.
      for (let i = 0; i < active_duplicate.attr_and_value.length; i++) {
        let attr_value = active_duplicate.attr_and_value[i];

        new_object.push({
          attr_id: attr_value.attr_id,
          attr_name: attr_value.attr_name,
          value_id: attr_value.value_id,
          value_name: attr_value.value_name
        });
      }
      // update the combo product url here
      let product_class = { ...selected_product_class };
      product_class.text = active_duplicate.product_class.name;
      product_class.id = active_duplicate.product_class.id;
      updateSelectedProductClass(product_class);
      updateSelectedAttr(new_object);
      updateProductClassAttr(active_duplicate.combo_url);
      updateListToDuplicate(value.value);
      updateSelectedDuplicateItem(active_duplicate);

      // update buy and bid prices on state
      if (active_duplicate.auction_price) {
        updateBid(true, active_duplicate.auction_price, "");
      }
      updateBuy(true, active_duplicate.price.incl_tax, "");
      // update product description only when field is not empty
      if (active_duplicate.description_ar) {
        updateDescriptionAR(active_duplicate.description_ar);
      }

      if (active_duplicate.description_en) {
        updateDescriptionEN(active_duplicate.description_en);
      }
      // update images
      updatePreviousImages(active_duplicate.images);
    }
  };

  renderDuplicateMenuItems = () => {
    const { product_duplicate_list } = this.props;
    let menuItems = [];

    const result = [];
    const map = new Map();
    for (const item of product_duplicate_list) {
      if (!map.has(item.title)) {
        map.set(item.title, true); // set any value to Map
        result.push(item);
      }
    }

    for (let i = 0; i < result.length; i++) {
      let product = result[i];
      menuItems.push({
        value: product.id,
        label: product.title
      });
    }
    return menuItems;
  };

  renderDuplicateSelectBox = () => {
    const { combo_fetching } = this.state;
    const { t, listing_kind, list_to_duplicate } = this.props;

    if (listing_kind === "is_duplicate") {
      let menuItems = this.renderDuplicateMenuItems();
      return (
        <GridContainer>
          <GridItem xs={12}>
            <SelectAutocomplete
              useFilterOption={true}
              maxMenuHeight={145}
              required={true}
              inputId={"select" + list_to_duplicate}
              value={menuItems.filter(i => i.value === list_to_duplicate)}
              placeholder={t("sellingPage.whatSellingModal.selectitem")}
              labelText={t("sellingPage.whatSellingModal.previousListing")}
              controlProps={{ marginBottom: "15px" }}
              inputProps={{
                required: true,
                disabled: combo_fetching ? true : false
              }}
              autocompleteOptions={menuItems}
              selectOnChange={this.handleDuplicateChange}
            />
          </GridItem>
        </GridContainer>
      );
    }
  };

  handleQtyChange = i => {
    const { updateQuantity } = this.props;
    updateQuantity(i);
  }

  renderCategorySuggest = () => {
    const {
      selected_category,
      t,
      listing_kind,
      category_list
    } = this.props;

    const category_list_placeholder = t(
      "sellingPage.whatSellingModal.category_list"
    );
    const categorylabel = t(
      "sellingPage.whatSellingModal.categorylabel"
    );

    if (listing_kind === "new_listing") {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <SelectAutocomplete
              useFilterOption={true}
              maxMenuHeight={145}
              placeholder={category_list_placeholder}
              labelText={categorylabel}
              required={true}
              value={
                category_list.length > 0
                  ? category_list.find(
                    i => i.category_id === selected_category.text
                  )
                  : null
              }
              inputId="selectProductCategory"
              inputProps={{
                name: "selectProductCategory",
                required: true
              }}
              selectProps={{ inputId: "selectProductCategory" }}
              autocompleteOptions={category_list}
              selectOnChange={this.handleCategoryChange}
              getOptionLabel={({ name }) => name}
            />
          </GridItem>
        </GridContainer>
      );
    }
  };

  renderAutoSuggest = () => {
    const {
      product_class_list,
      selected_category,
      selected_product_class,
      t,
      listing_kind,
    } = this.props;

    const product_class_placeholder = t(
      "sellingPage.whatSellingModal.product_class_list"
    );
    const product_class_label = t(
      "sellingPage.whatSellingModal.productClasslabel"
    );

    if (listing_kind === "new_listing") {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <SelectAutocomplete
              useFilterOption={true}
              maxMenuHeight={145}
              placeholder={product_class_placeholder}
              labelText={product_class_label}
              required={true}
              value={
                product_class_list.length > 0
                  ? product_class_list.find(
                    i => i.slug === selected_product_class.text
                  )
                  : null
              }
              inputId="selectProductClass"
              inputProps={{
                name: "selectProductClass",
                required: true
              }}
              selectProps={{ inputId: "selectProductClass" }}
              autocompleteOptions={selected_category && selected_category.text? product_class_list :[]}
              selectOnChange={this.handleSelectChange}
              getOptionLabel={({ name }) => name}
            />
          </GridItem>
        </GridContainer>
      );
    }
  };

  renderCondition = () => {
    const {
      conditions,
      t,
      userDetails,
      selected_condition_object
    } = this.props;

    // if (!selected_condition_object && conditions.length > 0) {
    let conditions_to_show = conditions.filter(i => i.name_en === "Used");
    // if (new_selected_condition) {
    //   updateSelectedCondition(new_selected_condition.id, new_selected_condition);
    // }
    // }

    let condition_disabled = false;
    if (userDetails && userDetails.is_vendor_type === "COMPANY" && userDetails.company_approved) {
      // condition_disabled = false
      conditions_to_show = conditions;
    }

    const product_class_placeholder = t(
      "sellingPage.whatSellingModal.condition_placeholder"
    );
    const product_class_label = t(
      "sellingPage.whatSellingModal.condition"
    );
    
    return (
      <GridContainer>
        <GridItem xs={12}>
          <SelectAutocomplete
            useFilterOption={true}
            maxMenuHeight={145}
            placeholder={product_class_placeholder}
            labelText={product_class_label}
            required={true}
            value={selected_condition_object}
            inputId="selectCondition"
            inputProps={{
              name: "selectCondition",
              required: true
            }}
            selectProps={{ inputId: "selectCondition" }}
            autocompleteOptions={conditions_to_show}
            selectOnChange={this.handleSelectChangeCondition}
            getOptionLabel={({ name }) => name}
            disabled={condition_disabled}
          />
        </GridItem>

      </GridContainer>
    );

  };

  handleSubmit = e => {
    // On this submit we generate the product_data that later on be submitted to post api
    const {
      selected_product_class,
      selected_condition,
      selected_attr_and_value,
      updateProductData,
      updateOtherProductdata,
      product_data,
      is_other,
      language,
      selected_category,
      selected_quantity
    } = this.props;

    e.preventDefault();
    let new_product_data = { ...product_data };
    new_product_data["product_class"] = selected_product_class.id;
    new_product_data["productattr"] = [];
    new_product_data['condition'] = selected_condition;
    new_product_data['quantity'] = selected_quantity;


    let selected_data = is_other.toString(); //convert object to string to check the length;
    let find_string_other = null;
    let a = selected_attr_and_value.find(dup => dup.value_id === "other"); //finding "other" string is available or note before posting
    if (a) {
      find_string_other = a;
    }
    if (selected_data.length > 0 && find_string_other) {
      var new_other_data = {};
      new_other_data["product_class"] = selected_product_class.id;
      new_other_data["language"] = language; //adding current user language to the data
      new_other_data["other"] = true; //adding other is true
      new_other_data["productattr"] = [];
      new_other_data['condition'] = selected_condition;
      new_other_data['quantity'] = selected_quantity;

      for (var i = 0; i < selected_attr_and_value.length; i++) {
        for (var k = 0; k < is_other.length; k++) {
          if (
            is_other[k].value_id === selected_attr_and_value[i].value_id &&
            is_other[k].attr_id === selected_attr_and_value[i].attr_id
          ) {
            selected_attr_and_value[i].value_name = is_other[k].value_name;
            break;
          }
          var other_array = [...selected_attr_and_value]; // adding all the data into the array
        }
      }
      //After looping we mapping to update "value name" if other string is matching with id otherwise it will update "value id"
      var get_values = other_array.map(function (el) {
        let other_obj = { english: null, arabic: null };
        if (language === "ar" && el.value_id === "other") {
          other_obj["arabic"] = el.value_name;
        } else if (language === "en" && el.value_id === "other") {
          other_obj["english"] = el.value_name;
        }

        return {
          attr: el.attr_id,
          value: el.value_id === "other" ? null : el.value_id,
          other: other_obj
        };
      });
      new_other_data["productattr"] = get_values; //assing the data
      updateOtherProductdata(new_other_data);
    } else {
      for (let i = 0; i < selected_attr_and_value.length; i++) {
        let value = selected_attr_and_value[i];
        new_product_data["productattr"].push({
          attr: value.attr_id,
          value: value.value_id
        });
      }
      if(selected_category){
        new_product_data["category_id"]=selected_category.text;
      }
      updateProductData(new_product_data);
    }
    this.checkforInstantCashFunc(new_product_data, find_string_other);
  };

  checkforInstantCashFunc = (new_product_data, find_string_other) => {
    let url = api.instant_cash_available();
    const { selected_product_class, updateInstantCashObj, selected_quantity } = this.props;
    let category = selected_product_class.text;
    url = url + category + "/";
    let prod_attrs = new_product_data.productattr.map(item => item.value);
    let selectedIds = "";
    if (prod_attrs && prod_attrs.length > 0 && !find_string_other && selected_quantity === 1) {
      selectedIds = prod_attrs.join(",");
      url = url + "?selected_ids=" + selectedIds;
      checkForInstantCash(url)
        .then(res => {
          let data = res.data;
          if (data) {
            updateInstantCashObj(data);
            this.openModal("imagesModal")();
          }
        })
        .catch(e => {
          updateInstantCashObj(null);
          this.openModal("imagesModal")();
        });
    } else {
      updateInstantCashObj(null);
      this.openModal("imagesModal")();
    }
  };

  clearComboAndClose = () => {
    const { closeModal, resetCombo } = this.props;
    resetCombo();
    closeModal();
  };
  onKeyUp = event => {
    alert("event" + event.keyCode);
  };
  renderQuantity=()=>{
    let qty = [1, 2, 3, 4, 5,6];
    const {selected_quantity, t} = this.props;
    return (
      <>
      {/* <GridItem xs={12} className="quantity_form">
        <CustomInput
          labelText={
            t("sellingPage.whatSellingModal.quantity")
          }
          id="qty"
          size="lg"
          className="circle"
          formControlProps={{
            fullWidth: true,
            className: "m-0 "
          }}
          inputProps={{
            // name: "qty",
            required: true,
            // type: 'number',
            // disableUnderline: true,
            // onChange: this.handleQtyChange,
            value: 1
          }}
        />
      </GridItem>   */}
      <GridItem > <h4 className="m-0 mb-3">{t("sellingPage.whatSellingModal.qtyText")}</h4></GridItem>
      <GridItem className="d-flex justify-content-between quantity_form">
      {qty.map(i => {
        return (
            <Button
              color={selected_quantity === i?"primaryLight" :"primaryWhiteWithBorder"}
              size="lg"
              className="mb-3 mx-1 zindex50 "
              onClick={()=>this.handleQtyChange(i)}
            >
              {i}
            </Button>
        )
      })}
      </GridItem>
      </>    
    )
  }
  render() {
    const {
      classes,
      t,
      // combo_fetching,
      selected_category,
      direction,
      product_duplicate_list,
      duplicate_loader,
      account_token,
      selected_product_class,
      selected_condition_object,
  
    } = this.props;

    const { listing_kind } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className="dp-f fd-c f-1"
      >
        {duplicate_loader ? <Loader /> : null}
        <div>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <IconButton
              className={classNames(
                classes.modalCloseButton,
                "previewClose",
                direction === "rtl" ? classes.modalSmallCloseButtonAR : null
              )}
              key="close"
              aria-label="Close"
              onClick={this.clearComboAndClose}
            >
              <Close
                className={classNames(classes.modalClose, classes.closeStyle)}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classNames(classes.modalBody, "dp-f")}
          >
            <GridContainer justify="center" className="mb-5">
              <GridItem xs={12} md={8} className="px-0 whatsellingContainer">
                <div>
                  <GridContainer>
                    <GridItem xs={12} className="mb-4">
                      <h2 className={classNames(classes.modalTitle)}>
                        {t("sellingPage.whatSellingModal.title")}
                      </h2>
                      {product_duplicate_list.length > 0 &&
                        account_token !== null ? (
                          <h4 className="m-0 mb-3">
                            {t("sellingPage.whatSellingModal.subTitle")}
                          </h4>
                        ) : (
                          <h4 className="m-0 mb-3">
                            {t("sellingPage.whatSellingModal.subTitleTwo")}
                          </h4>
                        )}

                      <div
                        className={
                          classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal +
                            " " +
                            product_duplicate_list.length >
                            0
                            ? ""
                            : "mb-2"
                        }
                      >
                        {product_duplicate_list.length > 0 ? (
                          <FormControlLabel
                            className={
                              (classes.labelCheckbox,
                                direction === "rtl"
                                  ? classes.labelCheckboxAR
                                  : null)
                            }
                            control={
                              <Radio
                                checked={listing_kind === "new_listing"}
                                onChange={this.handleChangeKind}
                                value="new_listing"
                                name="listing_kind_state"
                                aria-label="new_listing"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classNames(classes.label, classes.htDark)
                            }}
                            label={t("sellingPage.whatSellingModal.newlisting")}
                          />
                        ) : null}
                      </div>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        {product_duplicate_list.length > 0 ? (
                          <FormControlLabel
                            className={
                              (classes.labelCheckbox,
                                direction === "rtl"
                                  ? classes.labelCheckboxAR
                                  : null)
                            }
                            control={
                              <Radio
                                checked={listing_kind === "is_duplicate"}
                                onChange={this.handleChangeKind}
                                value="is_duplicate"
                                name="listing_kind_state"
                                aria-label="is_duplicate"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classNames(classes.label, classes.htDark)
                            }}
                            label={t("sellingPage.whatSellingModal.duplicate")}
                          />
                        ) : null}
                      </div>
                    </GridItem>
                  </GridContainer>
                  {selected_product_class.id != null &&
                    listing_kind === "new_listing" ? (
                      <GridContainer>
                        <GridItem xs={12}>
                          <span
                            className="color-pl headerLink float-right"
                            onClick={this.resetDataFunc}
                          >
                            {" "}
                            {t("sellingPage.whatSellingModal.reset")}
                          </span>
                        </GridItem>
                      </GridContainer>
                    ) : null}
                  {this.renderCategorySuggest()}
                  {this.renderAutoSuggest()}
                  {this.renderDuplicateSelectBox()}
                  {this.renderCondition()}
                  {selected_category && selected_category.text&& 
                  selected_product_class && selected_product_class.text? 
                  this.renderDynamicSelect():null}
                
                </div>

                <GridContainer className="jcc ">
                  <GridItem xs={10}>
                    <Button
                      color="primaryWhiteWithBorder"
                      size="lg"
                      className="mb-3 zindex50 continueContainer"
                      fullWidth
                      type="submit"
                      disabled={!selected_condition_object || !selected_product_class}
                    >
                      {t("sellingPage.whatSellingModal.continue")}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </div>
      </form>
    );
  }
}

WhatSellingModal.propTypes = {
  updateProductClassList: PropTypes.func,
  updateProductDuplicateList: PropTypes.func,
  updateProductClassAttr: PropTypes.func,
  product_class_list: PropTypes.array,
  product_duplicate_list: PropTypes.array,
  modalProps: PropTypes.object,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  updateSelectedProductClass: PropTypes.func,
  classes: PropTypes.object,
  current_product_class_attributes: PropTypes.array,
  t: PropTypes.func,
  current_product_class_attr_url: PropTypes.string,
  selected_attr_and_value: PropTypes.array,
  updateSelectedAttr: PropTypes.func,
  selected_product_class: PropTypes.object,
  resetCombo: PropTypes.func,
  updateProductData: PropTypes.func,
  product_data: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updateProductClassList: () => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PRODUCT_CLASS_LIST_REQUEST
    });
  },
  updateProductClassAttr: (url, id) => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PRODUCT_CLASS_ATTR_REQUEST,
      url,
      id
    });
  },
  updateProductDuplicateList: () => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PRODUCT_DUPLICATE_LIST_REQUEST
    });
  },
  updateSelectedAttr: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_SELECTED_ATTR_VALUE,
      data
    });
  },
  resetCombo: () => {
    dispatch({
      type: ComboProductActionTypes.RESET_COMBO
    });
  },
  updateQuantity: qty => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_QUANTITY,
      qty
    });
  },
  updateSelectedCategory: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_SELECTED_CATEGORY,
      data
    });
  },
  updateSelectedProductClass: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_SELECTED_PRODUCT_CLASS,
      data
    });
  },
  filteredProductClass: data => {
    dispatch({
      type: ComboProductActionTypes.FILTERED_PRODUCT_CLASS_LIST,
      data
    });
  },
  updateSelectedCondition: (data, obj) => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_SELECTED_CONDITION,
      data,
      obj
    });
  },
  updateProductData: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PRODUCT_DATA,
      data
    });
  },
  updateListingKind: value => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_LISTING_KIND,
      value
    });
  },
  updateListToDuplicate: value => {
    dispatch({
      type: ComboProductActionTypes.LIST_TO_DUPLICATE,
      value
    });
  },
  updateSelectedDuplicateItem: duplicate => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_SELECTED_DUPLICATE_ITEM,
      duplicate
    });
  },
  updateBid: (is_bid, is_bid_price, is_bid_price_final) => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_BID_DATA,
      is_bid,
      is_bid_price,
      is_bid_price_final
    });
  },
  updateBuy: (is_buy, is_buy_price, is_buy_price_final) => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_BUY_DATA,
      is_buy,
      is_buy_price,
      is_buy_price_final
    });
  },
  updateDescriptionEN: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_DESCRIPTION_EN,
      data
    });
  },
  updateDescriptionAR: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_DESCRIPTION_AR,
      data
    });
  },
  updatePreviousImages: images => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PREVIOUS_IMAGES,
      images
    });
  },
  getTimeWindows: window => {
    dispatch({
      type: GlobalActionTypes.GET_TIMEWINDOW_REQUEST,
      window
    });
  },
  updateOther: is_other => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_OTHER_INPUT_DATA,
      is_other
    });
  },
  updateOtherArabic: is_other_arabic => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_OTHER_INPUT_DATA_ARABIC,
      is_other_arabic
    });
  },
  updateSelectedOther: selected_other_data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_SELECTED_OTHER_DATA,
      selected_other_data
    });
  },
  updateOtherProductdata: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_OTHER_PRODUCT_DATA,
      data
    });
  },
  updateInstantCashObj: instantCashObj => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_INSTANT_CASH_OBJ,
      instantCashObj
    });
  },
  updatetempModalProps: tempModalProps => {
    dispatch({
      type: ModalActionTypes.UPDATE_TEMP_MODAL_PROPS,
      tempModalProps
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.comboProductReducer,
  ...state.accountReducer,
  ...state.globalReducer,
  ...state.productReducer
});

export default compose(
  withStyles(modalStyle),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WhatSellingModal);
