import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootReducer from "reducers/rootReducers.jsx";
import watcherSaga from "sagas/watcherSaga.jsx";

const sagaMiddleware = createSagaMiddleware();

let store = createStore(rootReducer, applyMiddleware(logger, sagaMiddleware));
sagaMiddleware.run(watcherSaga);
let persistor = persistStore(store);

export { store, persistor };
