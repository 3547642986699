import api from "utils/api.jsx";

const getProductClassList = () => {
  let url = api.product_class_list();
  return api.getFunction(url);
};

const getProductDuplicateList = () => {
  let url = api.product_duplicate_list();
  return api.getFunction(url);
};

const getProductClassAttr = url => {
  return api.getFunction(url);
};

const postProductCreate = data => {
  let url = api.product_create();
  return api.postFunction(url, data);
};

const postProductCreateImages = data => {
  let url = api.product_create_images();
  return api.postFunction(url, data, true);
};

const checkForInstantCash = (url) => {
  return api.getFunction(url);
};
export {
  getProductClassList,
  getProductDuplicateList,
  getProductClassAttr,
  postProductCreate,
  postProductCreateImages,
  checkForInstantCash
};
