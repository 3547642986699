import { call, put } from "redux-saga/effects";

import ActionTypes from "constants/globalConstants.jsx";
import { contactSendMessage, subscribeEmail, getTimeWindow } from "actions/globalActions.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import i18n from "locales/i18n.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import { getPageData } from "actions/globalActions.jsx";
import { store } from "utils/store.jsx";
import * as Sentry from '@sentry/browser';
import history from "history.js";


function* languageWorker(action) {
  const { lang } = action;

  yield put({
    type: ActionTypes.CHANGE_LANGUAGE_SUCCESS,
    lang
  });
}

function* getTimeWindowWorker(params) {
  let { window } = params;
  try {
    let response = yield call(getTimeWindow, window);
    let data = response.data;
    yield put({ type: ActionTypes.GET_TIMEWINDOW_SUCCESS, data });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.GET_TIMEWINDOW_FAILED, error });
  }
}

function* sendMessageWorker(action) {
  let { message_data } = action;
  try {
    let response = yield call(contactSendMessage, message_data);
    let data = response.data.message;
    yield put({ type: ActionTypes.POST_SEND_MESSAGE_SUCCESS, data });
    yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    yield put(
      enqueueSnackbar({
        message: data,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.POST_SEND_MESSAGE_FAILED, error });
  }
}

function* subscribeEmailWorker(action) {
  let { subscribe_data } = action;
  try {
    let response = yield call(subscribeEmail, subscribe_data);
    let status = response.status;
    if (status === 201) {
      yield put({ type: ActionTypes.POST_SUBSCRIBE_SUCCESS });
      let txt = i18n.t("common.subscribedSuccess");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.POST_SUBSCRIBE_FAILED, error });
  }
}

function* pageDataWorker(params) {
  const { code } = params;
  try {
    let response = yield call(getPageData, code);
    let data = response.data;
 
    if(data && data.categories){
      yield put({
        type: ActionTypes.UPDATE_CATEGORIES,
        data:data.categories
      });
    }
    let old_cms = store.getState().globalReducer.cms_data;
    let new_cms = { ...old_cms };
    new_cms[code] = data;
    data = new_cms;
    yield put({
      type: ActionTypes.CMS_PAGE_SUCCESS,
      data
    });

  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response.status === 404) {
        let protocol = window.location.protocol;
        let host = window.location.hostname;
        let port = window.location.port;
        let target_url = host;
        if (port !== "") {
          target_url = target_url + ":" + port;
        }
        // target_url = protocol + "//" + target_url +":"+port+"/404"; //Local Host
        target_url = protocol + "//" + target_url + "/404";
        history.push(target_url);
        let txt = i18n.t("common.page");  //Snanck Bar Message - Page Not Found
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }

      const res_object = e.response.data;
      let messages = [];
      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.CMS_PAGE_FAILED, error });
  }
}

export {
  languageWorker,
  pageDataWorker,
  sendMessageWorker,
  subscribeEmailWorker,
  getTimeWindowWorker
};
