import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Accordion from "kayfik/components/Accordion/coreAccordion.jsx";
import CompanyAccountSettings from "kayfik/views/CompanyDashboard/companyAccountSettings.jsx";
import InstantCashOffer from "kayfik/views/CompanyDashboard/instantCashOffer.jsx";
import InstantCashSales from "kayfik/views/CompanyDashboard/instantCashSales.jsx";
import ItemsSold from "kayfik/views/CompanyDashboard/itemsSold.jsx";
import ItemsPurchased from "kayfik/views/CompanyDashboard/itemsPurchased.jsx";
import ProdReqHistory from "kayfik/views/CompanyDashboard/prodReqHistory.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import customAccordionStyle from "kayfik/assets/jss/components/Accordion/customAccordionStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
//image
import CardAvatar from "components/Card/CardAvatar.jsx";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";

// eslint-disable-next-line react/prop-types
class CompanyProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      showId: "account-settings"
    };
  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal,direction } = this.props;
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classNames(classes.modalHeader, classes.profileHeader)}
        >
          <GridContainer>
            <Button
              simple="true"
              className={classNames(classes.modalCloseButton,"previewClose",direction==="rtl"?classes.modalSmallCloseButtonAR:null)}
              key="close"
              aria-label="Close"
              onClick={closeModal}
            >
              {" "}
              <Close
                className={classNames(classes.modalClose, classes.closeStyle)}
              />
            </Button>
          </GridContainer>
          <GridContainer>
            <GridItem xs={false} sm={false} md={4} />
            <GridItem xs={12} sm={12} md={8} className={"modalAvatar"}>
              <CardAvatar profile plain className={classes.avatar}>
                <img src={cardProfile4Square} alt="..." />
              </CardAvatar>
            </GridItem>
          </GridContainer>
        </DialogTitle>

        <DialogContent
          id="classic-modal-slide-description"
          className={classNames(
            classes.modalBody,
            classes.profileContentGrid,
            "py-0"
          )}
        >
          <GridContainer>
            <GridItem
              xs={false}
              sm={4}
              md={4}
              className={classNames(
                classes.marginAuto,
                classes.profileTabGrid,
                "profile",
                "px-4",
                "py-5"
              )}
            >
              <span> Profile </span>
            </GridItem>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classNames(classes.marginAuto, "dashboard")}
            >
              <h6> [Company Name]'s Dashboard </h6>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={4} className={classes.profileTabGrid}>
              <Accordion
                activeColor="accordianColor"
                classes={{
                  root: classNames("ml-3", classes.profileTabGrid),
                  expansionPanel: classes.profileTabGrid
                }}
                active={0}
                collapses={[
                  {
                    title: (
                      <div
                        onClick={() =>
                          this.setState({ showId: "account-settings" })
                        }
                      >
                        Account Settings
                      </div>
                    ),
                    noIcon: true
                  },
                  {
                    title: "Product Requests",
                    content: (
                      <div>
                        {" "}
                        <span
                          id="one"
                          onClick={() =>
                            this.setState({ showId: "request-product" })
                          }
                        >
                          Request Product
                        </span>
                        <br />{" "}
                        <span
                          id="two"
                          onClick={() =>
                            this.setState({ showId: "product-request-history" })
                          }
                        >
                          {" "}
                          Product Request History
                        </span>
                      </div>
                    )
                  },
                  {
                    title: "Order History",
                    content: (
                      <div>
                        {" "}
                        <span
                          id="one"
                          onClick={() =>
                            this.setState({ showId: "items-sold" })
                          }
                        >
                          Items Sold{" "}
                        </span>{" "}
                        <br />{" "}
                        <span
                          id="two"
                          onClick={() =>
                            this.setState({ showId: "items-purchased" })
                          }
                        >
                          Items Purchased
                        </span>
                      </div>
                    )
                  },
                  {
                    title: "Instant Cash Offers",
                    content: (
                      <div>
                        {" "}
                        <span
                          id="one"
                          onClick={() =>
                            this.setState({ showId: "instant-cash-request" })
                          }
                        >
                          Instant Cash Requests{" "}
                        </span>{" "}
                        <br />{" "}
                        <span
                          id="two"
                          onClick={() =>
                            this.setState({ showId: "instant-cash-sales" })
                          }
                        >
                          Instant Cash Sales
                        </span>
                      </div>
                    )
                  }
                ]}
              />
            </GridItem>
            <GridItem md={8}>
              <div className="ml-3">
                <div
                  className={
                    this.state.showId === "account-settings"
                      ? "display-block"
                      : "display-none"
                  }
                >
                  <CompanyAccountSettings />{" "}
                </div>
                {/* <div
                  className={
                    this.state.showId === "request-product"
                      ? "display-block"
                      : "display-none"
                  }
                >
                  <RequestProduct />{" "}
                </div> */}
                <div
                  className={
                    this.state.showId === "product-request-history"
                      ? "display-block"
                      : "display-none"
                  }
                >
                  <ProdReqHistory />{" "}
                </div>
                <div
                  className={
                    this.state.showId === "items-sold"
                      ? "display-block"
                      : "display-none"
                  }
                >
                  <ItemsSold />{" "}
                </div>
                <div
                  className={
                    this.state.showId === "items-purchased"
                      ? "display-block"
                      : "display-none"
                  }
                >
                  <ItemsPurchased />{" "}
                </div>
                <div
                  className={
                    this.state.showId === "instant-cash-request"
                      ? "display-block"
                      : "display-none"
                  }
                >
                  <InstantCashOffer />{" "}
                </div>
                <div
                  className={
                    this.state.showId === "instant-cash-sales"
                      ? "display-block"
                      : "display-none"
                  }
                >
                  <InstantCashSales />{" "}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle, customAccordionStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CompanyProfileModal);
