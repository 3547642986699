/* eslint-disable react/prop-types */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// React i18next
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
import howItWorksSectionStyle from "kayfik/assets/jss/views//LandingPage/Sections/howItWorksSectionStyle.jsx";

class SectionHowItWorks extends React.Component {
  renderSection = text => {
    const { classes } = this.props;
    if (text.length > 0) {
      return text.map((item, index) => {
        return (
          <div key={index}>
            <h4
              className={classNames(
                classes.description,
                " htPrimaryGreen plLanding"
              )}
            >
              {item.content}
            </h4>
          </div>
        );
      });
    }
  };

  render() {
    const { cms_data, classes } = this.props;
    if (cms_data.home) {
      let sections = cms_data.home.sections;
      let textsection = sections.find(sec => sec.slug === "text");
      if (textsection) {
        return (
          <div className="main-background-color">
            <div className={classNames(classes.container, classes.py30)}>
              <GridContainer>
                <GridItem md={8} sm={8}>
                  <h2
                    className={classNames(
                      classes.title,
                      "htPrimaryGreen plLanding"
                    )}
                  >
                    {textsection.title}
                  </h2>
                  {this.renderSection(textsection.text)}
                </GridItem>
              </GridContainer>
            </div>
          </div>
        );
      }
    }
    return null;
  }
}
const mapStateToProps = state => ({
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(howItWorksSectionStyle),
  connect(mapStateToProps),
  withTranslation()
)(SectionHowItWorks);
