import React from "react";
import classNames from "classnames";

//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import AddCircleOutlined from "@material-ui/icons/AddCircleOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

//custom core components
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import CustomSelectInput from "kayfik/components/CustomInput/customSelectInput.jsx";

class InstantCashOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bidNow: false,
      buyNow: true
    };
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="main-background-color marginAuto pt-3">
        <h4 className={classNames("ta-left my-0 fw-400")}>
          Instant Cash Offers{" "}
        </h4>
        <h5 className={classNames("ta-left mb-3")}>
          What are you looking for{" "}
        </h5>
        <GridContainer
          className={classNames(
            "m-0",
            "modalScroll",
            "scrollbar",
            classes.marginAuto
          )}
        >
          <GridItem xs={12} sm={12} md={11} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Product Category"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "prod_category",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select a category" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={11} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Brand"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "brand",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select a brand" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Model"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "modle",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select a model" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Capacity"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "capacity",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select capaciy" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Color"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "color",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select color" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Conditon"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "condition",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select condition" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Quantity"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "qty",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select quantity" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="price"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "price",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select price" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={5} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Price valid for"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "price",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select price" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={5} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="REACTIVE AFTER DATE EXPIRATION"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "reactive",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select reactive date" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={11} className={classes.marginAuto}>
            <Button link size="lg" className="mb-3 px-0 textTransformNone">
              <AddCircleOutlined />
              Add prooducts to instant cash
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(modalStyle)(InstantCashOffer);
