import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// React i18next
import { withTranslation } from "react-i18next";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Help from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";

// eslint-disable-next-line react/prop-types
class PasswordResetModal extends React.Component {
  handleSubmit = e => {
    const { passwordResetRequest, modalProps } = this.props;
    e.preventDefault();

    let form_data = {
      token: modalProps.token,
      uid: modalProps.uid,
      new_password1: e.target.password.value,
      new_password2: e.target.password1.value
    };
    passwordResetRequest(form_data);
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, t, account_fetching, direction } = this.props;
    return (
      <div>
        {account_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {" "}
                {t("resetPassword.title")}
              </h2>
              <h4 className="m-0 mb-3">{t("resetPassword.message")}</h4>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <CustomInput
                  labelText={t("personalSignupModal.password")}
                  id="id_password"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "password",
                    required: true,
                    type: "password",
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <Tooltip
                          title={t("personalSignupModal.helpText")}
                          placement="top"
                          enterTouchDelay={0}
                          classes={{
                            tooltip: classNames(classes.tooltip)
                          }}
                        >
                          <Help className={classes.inputAdornmentIcon} />
                        </Tooltip>{" "}
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText={t("personalSignupModal.confirmPassword")}
                  id="id_password1"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "password1",
                    required: true,
                    type: "password",
                    disableUnderline: true
                  }}
                />

                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  className="mb-3"
                  fullWidth
                  type="submit"
                >
                  {t("resetPassword.resetButton")}
                </Button>
              </form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  passwordResetRequest: form_data => {
    dispatch({
      type: AccountActionTypes.PASSWORD_RESET_REQUEST,
      form_data
    });
  }
});

PasswordResetModal.propTypes = {
  PersonalSignupRequest: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(PasswordResetModal);
