import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import { Link } from "react-router-dom";
import {
  GraphicArabicWeb
} from "kayfik/assets/jss/universal.jsx";
import classNames from "classnames";

const styles = theme => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
    fontFamily: GraphicArabicWeb + " !important",
  },
  breadFont:{
    fontFamily: GraphicArabicWeb + " !important",
  }
});

function SimpleBreadcrumbs(props) {
  const { classes, linkObjects } = props;
  if (linkObjects && linkObjects.length > 0) {
    let lastIndex = linkObjects.length - 1;

    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label="Breadcrumb" separator={props.separator} >
          {linkObjects.map((prop, key) => {
            if (key === lastIndex) {
              return (
                <Typography className={classNames(classes.breadFont, "color-pl")} key={key}>
                  {prop.text}
                </Typography>
              );
            } else {
              return (
                <Link className={classNames(classes.breadFont, "color-pl")}  to ={prop.url} key={key} onClick={props.onClick} >
                  {prop.text}
                </Link>
              );
            }
          })}
        </Breadcrumbs>
      </div>
    );
  }
  return null;
}

SimpleBreadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  linkObjects: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

export default withStyles(styles)(SimpleBreadcrumbs);
