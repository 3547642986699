import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// React i18next
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
// redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import customFooterStyle from "kayfik/assets/jss/components/Footer/FooterStyles.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import left_footer from "kayfik/assets/images/left_footer.png";
import right_footer from "kayfik/assets/images/right_footer.png";
import apple from "kayfik/assets/images/apple.png";
import google from "kayfik/assets/images/google.png";
import payment from "kayfik/assets/images/payment.png";

class CustomFooter extends React.Component {
  openModal = modal_type => async () => {
    const { showModal, closeModal, modalProps } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  render() {
    const { classes, t, direction } = this.props;
    return (
      <Footer
        theme="dark"
        content={""}
        className="footer-bg-color w-100 footerMain"
      >
        <img
          src={left_footer}
          className={classNames(
            classes.halfLogo,
            direction === "rtl" ? classes.rightLogoRTL : classes.leftLogo
          )}
          alt=""
        />
        <div className={classes.footer}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10} className="px-0">
              <GridContainer>
                <GridItem xs={6} sm={4} md={2}>
                  <h4
                    className={classNames(
                      "white-text text-uppercase ta-initial"
                    )}
                  >
                    {t("homePage.footer.about")}
                  </h4>
                  <ul
                    className={classNames(classes.linksVertical, "ta-initial")}
                  >
                    <li>
                      <Link to="/about">{t("homePage.footer.company")}</Link>
                    </li>
                  </ul>
                </GridItem>
                <GridItem xs={6} sm={4} md={2}>
                  <h4 className="white-text text-uppercase ta-initial">
                    {t("homePage.footer.support")}
                  </h4>
                  <ul
                    className={classNames(classes.linksVertical, "ta-initial")}
                  >
                    <li>
                      <Link to="/faq">{t("homePage.footer.faq")}</Link>
                    </li>
                    <li>
                      <Link to="/terms">
                        {t("homePage.footer.termsOfService")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        {t("homePage.footer.privacyPolicy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/how-it-works">
                        {t("homePage.footer.works")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/why-kayfik-works">
                        {t("homePage.footer.kayfikWorks")}
                      </Link>
                    </li>
                  </ul>
                </GridItem>
                <GridItem xs={6} sm={4} md={2}>
                  <h4 className="white-text text-uppercase ta-initial ">
                    {t("homePage.footer.contactUs")}
                  </h4>
                  <ul
                    className={classNames(classes.linksVertical, "ta-initial")}
                  >
                    <li>
                      <div
                        className={
                          direction === "rtl"
                            ? "footer_linkAR"
                            : "footer_linkEN"
                        }
                        onClick={this.openModal("contactModal")}
                      >
                        {t("homePage.footer.email")}
                      </div>
                    </li>
                  </ul>
                </GridItem>
                <GridItem xs={6} sm={4} md={2}>
                  <h4 className="white-text text-uppercase ta-initial">
                    {t("homePage.footer.follow")}
                  </h4>
                  <div className={"socialFeed"}>
                    <div>
                      <i className="fab fa-facebook-square" />
                      <p className="white-text headerLink">
                        <a href="https://www.facebook.com/kayfikco">
                          {t("homePage.footer.facebook")}
                        </a>
                      </p>
                    </div>
                    <div>
                      <i className="fab fa-instagram" />
                      <p className="white-text headerLink">
                        <a href="https://www.instagram.com/kayfikco/">
                          {t("homePage.footer.insta")}
                        </a>
                      </p>
                    </div>
                    <div>
                      <i className="fab fa-twitter-square" />
                      <p className="white-text headerLink">
                        <a href="https://twitter.com/kayfikco">
                          {t("homePage.footer.twitter")}
                        </a>
                      </p>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={6} sm={4} md={2}>
                  <h4 className="white-text text-uppercase ta-initial">
                    {t("homePage.footer.comingSoon")}
                  </h4>
                  <div className={classes.galleryFeed}>
                    <div
                      className={
                        direction === "rtl"
                          ? "footer_socialAR"
                          : "footer_socialEN"
                      }
                    >
                      <img src={google} className="footer_logo" alt="" />
                    </div>
                    <div
                      className={
                        direction === "rtl"
                          ? "footer_socialAR mt-2"
                          : "footer_socialEN mt-2 "
                      }
                    >
                      <img src={apple} className="footer_logo" alt="" />
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={6} sm={4} md={2}>
                  <h4 className="white-text text-uppercase visibility-hidden">
                    {t("homePage.footer.comingSoon")}
                  </h4>
                  <div className="footer-button-image visibility-hidden" />
                  <div className={classes.galleryFeed}>
                    <div
                      className={
                        direction === "rtl"
                          ? "footer_socialAR mt-2"
                          : "footer_socialEN mt-2"
                      }
                    >
                      <img src={payment} className="footer_paymentImg" alt="" />
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
        <img
          src={right_footer}
          className={classNames(
            classes.halfLogo,
            direction === "rtl" ? classes.leftLogoRTL : classes.rightLogo
          )}
          alt=""
        />
      </Footer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  changeLang: lang => {
    dispatch({ type: GlobalActionTypes.CHANGE_LANGUAGE_REQUEST, lang });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(customFooterStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(CustomFooter);
