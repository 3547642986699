import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// React i18next
import { withTranslation } from "react-i18next";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
// import FlagSelect from "kayfik/components/FlagSelect/FlagSelect.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import { patchPhoneNumber } from "actions/accountActions.jsx";
import { resetUnauthorized } from "utils/helpers.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import IconButton from "@material-ui/core/IconButton";


var IBAN = require("iban");
// eslint-disable-next-line react/prop-types
class GetIban extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      bday: ""
    };
    this.props.getUserDetails();
  }
  componentDidMount = () => {
    const { user_profile, userDetails } = this.props;
    const details = userDetails ? userDetails : user_profile;
    this.setState({ phone: details ? details.phone : "" });
    let bdArr = [];
    if (details.birth_date) {
      bdArr = details.birth_date.split("/");
      this.setState(
        { year: bdArr[2], month: bdArr[1], date: bdArr[0] },
        () => { }
      );
    }
  };

  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal, } = this.props;
    const nextModal = modalProps.nextModal;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type,
        nextModal: nextModal ? nextModal : "profileModal"
      },
      modal_type
    );
  };

  handleSubmit = e => {
    const {
      postIbanDetails,
      user_profile,
      userDetails,
      t,
      showSnackbar,
      setLoader,
      putIbanDetails
      //paymentInfo
    } = this.props;
    const details = userDetails ? userDetails : user_profile;
    const { date, month, year } = this.state;
    e.preventDefault();

    let form_data = {
      // first_name: e.target.first_name.value,
      // last_name: e.target.last_name.value,
      // phone: phone,
      iban: e.target.id_iban.value
    };
    let birth_date = null;
    if (date && month && year) {
      birth_date = date + "/" + month + "/" + year;
    } else {
      let txt = t("profileModal.accountSettings.getIban.valid_birthdate");
      showSnackbar(txt, "warning");
    }

    if (IBAN.isValid(form_data.iban)) {
      setLoader(true);
      if (details && birth_date) {
        let user_data = {
          birth_date: birth_date
        };
        patchPhoneNumber(user_data)
          .then(res => res.data)
          .then(data => {
            if (userDetails.user_payment_info) {
              putIbanDetails(userDetails.user_payment_info, form_data)
            }
            else {
              postIbanDetails(form_data);
            }
            // postIbanDetails(form_data);
          })
          .catch(e => {
            let error = e;
            if (
              e.response &&
              e.response.status >= 400 &&
              e.response.status < 500
            ) {
              if (e.response && e.response.status === 401) {
                resetUnauthorized();
                showSnackbar(t("unauthorizedAccess"), "error");
              } else {
                const res_object = e.response.data;
                let messages = [];

                for (let i in res_object) {
                  let error_val = res_object[i];
                  if (error_val) {
                    messages.push(error_val);
                  }
                }
                for (let i = 0; i < messages.length; i++) {
                  let txt = messages[i];
                  showSnackbar(txt, "warning");
                }
              }
            } else {
              let txt = t("common.error.network");
              showSnackbar(txt, "error");
            }
            this.props.patchPhoneNumberFailed(error);
          });
      }


      // }
    } else {
      showSnackbar(
        t("profileModal.accountSettings.getIban.invalid"),
        "warning"
      );
    }
  };

  handleOnChange = (value, data) => {
    const { userDetails } = this.props;
    let code = "+" + data.dialCode;
    if (value.indexOf(code) === 0) {
      this.setState({ phone: value });
    } else if (userDetails && data.dialCode !== userDetails.country_code) {
      this.setState({ phone: code });
    } else {
      this.setState({ phone: this.state.phone });
    }
  };

  handleChange = label => value => {
    if (label === "year") {
      this.setState({ year: value.value });
    } else if (label === "month") {
      this.setState({ month: value.value });
    } else if (label === "date") {
      this.setState({ date: value.value });
    }
  };

  getDatePickers = () => {
    const { t } = this.props;
    let months = [];
    let dates = [];
    let years = [];
    //months
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        let j = "0" + i;
        let a = {
          label: j,
          value: j
        };
        months.push(a);
      } else {
        let a = {
          label: i,
          value: "" + i
        };
        months.push(a);
      }
    }

    //dates
    for (let i = 1; i <= 31; i++) {
      if (i < 10) {
        let j = "0" + i;
        let a = {
          label: j,
          value: j
        };
        dates.push(a);
      } else {
        let a = {
          label: i,
          value: "" + i
        };
        dates.push(a);
      }
    }

    //years
    for (let i = 2019; i >= 1900; i--) {
      let a = {
        label: i,
        value: "" + i
      };
      years.push(a);
    }
    return (
      <GridContainer className="mt-3">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h5 className="mt-0 greenLabelStyle text-uppercase">
              {" "}
              {t("profileModal.accountSettings.generalUser.birthdate")}{" "}
              <span className="redColor"> * </span>{" "}
            </h5>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <SelectAutocomplete
            maxMenuHeight={145}
            name={"birthdate_date"}
            inputId={"birthdate_date"}
            required={true}
            placeholder={t("profileModal.accountSettings.getIban.select_date")}
            labelText={t("profileModal.accountSettings.getIban.date")}
            value={
              this.state.date && dates
                ? dates.find(i => i.value === this.state.date)
                : null
            }
            controlProps={{ marginBottom: "15px" }}
            inputProps={{
              required: true
            }}
            autocompleteOptions={dates}
            selectOnChange={this.handleChange("date")}
          />
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <SelectAutocomplete
            maxMenuHeight={145}
            name={"birthdate_month"}
            inputId={"birthdate_month"}
            required={true}
            placeholder={t("profileModal.accountSettings.getIban.select_month")}
            labelText={t("profileModal.accountSettings.getIban.month")}
            value={
              this.state.month && months
                ? months.find(i => i.value === this.state.month)
                : null
            }
            controlProps={{ marginBottom: "15px" }}
            inputProps={{
              required: true
            }}
            autocompleteOptions={months}
            selectOnChange={this.handleChange("month")}
          />
        </GridItem>

        <GridItem xs={12} sm={4} md={4}>
          <SelectAutocomplete
            maxMenuHeight={145}
            name={"birthdate_year"}
            inputId={"birthdate_year"}
            required={true}
            placeholder={t("profileModal.accountSettings.getIban.select_year")}
            labelText={t("profileModal.accountSettings.getIban.year")}
            controlProps={{ marginBottom: "15px" }}
            inputProps={{
              required: true
            }}
            value={
              this.state.year && years
                ? years.find(i => i.value === this.state.year)
                : null
            }
            autocompleteOptions={years}
            selectOnChange={this.handleChange("year")}
          />
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      closeModal,
      // user_profile,
      // userDetails,
      t,
      direction,
      account_fetching,
      combo_fetching,
      payment_info
    } = this.props;
    // const details = userDetails ? userDetails : user_profile;

    return (
      <div>
        {account_fetching || combo_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {" "}
                {t("profileModal.accountSettings.getIban.title")}
              </h2>
              <h4 className="m-0 mb-3">
                {t("profileModal.accountSettings.getIban.provide")}
              </h4>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <GridContainer className="mt-3">
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("profileModal.accountSettings.getIban.iban")}
                      id="id_iban"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "iban",
                        required: true,
                        defaultValue:
                          payment_info.length > 0 ? payment_info[0].iban : "",
                        type: "text",
                        disableUnderline: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {this.getDatePickers()}
                <GridContainer justify="center">
                  <GridItem xs={10}>
                    <Button
                      color="primaryWhiteWithBorder"
                      size="lg"
                      className="mb-3"
                      fullWidth
                      type="submit"
                    >
                      {t("profileModal.accountSettings.generalUser.save")}
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  postIbanDetails: form_data => {
    dispatch({
      type: AccountActionTypes.POST_PAYMENT_INFO_REQUEST,
      form_data
    });
  },
  putIbanDetails: (url, form_data) => {
    dispatch({
      type: AccountActionTypes.PUT_PAYMENT_INFO_REQUEST,
      form_data,
      url
    });
  },
  patchPhoneNumberFailed: error => {
    dispatch({
      type: AccountActionTypes.PATCH_PHONE_FAILED,
      error
    });
  },
  getUserDetails: () => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_REQUEST
    });
  },
  showSnackbar: (txt, variant) => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  setLoader: fetching => {
    // only to set fetching true for loader
    dispatch({
      type: AccountActionTypes.RESEND_PHONE_CONFIRMATION_FETCHING,
      fetching
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer,
  ...state.comboProductReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(GetIban);
