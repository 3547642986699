import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// @material-ui/core components
import compose from "recompose/compose";
// React router
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui icons
import Search from "@material-ui/icons/Search";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Cached from "@material-ui/icons/Cached";
import Clearfix from "components/Clearfix/Clearfix.jsx";
// core components
import Accordion from "kayfik/components/Accordion/coreAccordion.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.jsx";
import api from "utils/api.jsx";
// Kayfik components
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Radio from "@material-ui/core/Radio";
//action
import ProductActionTypes from "constants/productConstants.jsx";
import queryString from "query-string";
import _ from "lodash";
import { convertHindiToArabic, converToArabicString, isInputEnglish, fromArabicToASCII } from "utils/helpers.jsx";


class Facets extends React.Component {
  constructor(props) {
    super(props);
    const {
      resetObjects,
      updateFacetValue,
      activeAttributeUpdate,
      updateSearchUrl,
      url_to_facets_mobile,
      active_parent_category,
      getInitialFacets
    } = this.props;
    this.state = {
      applyFilter: false,
      mobileCurrentURL: null,
      low: 0,
      high: 0
    };
    if (window.location.pathname === "/shop") {
      resetObjects();
      activeAttributeUpdate([]);
      updateFacetValue("");
      updateSearchUrl("");
    }
    if (
      url_to_facets_mobile &&
      url_to_facets_mobile.includes("shop") &&
      window.location.pathname === "/facets" && active_parent_category
    ) {
      getInitialFacets(null, api.get_product_list()+"?params=category_exact:"+active_parent_category.text, null, null);
    } else if (
      url_to_facets_mobile &&
      url_to_facets_mobile.includes("shop") &&
      window.location.pathname === "/facets"
    ) {
      getInitialFacets(null, api.get_product_list(), null, null);
    } else if (
      url_to_facets_mobile &&
      url_to_facets_mobile.includes("params") &&
      window.location.pathname === "/facets"
    ) {
      let moburl = new URL(url_to_facets_mobile);
      this.checkStaticCategory(moburl);
    }
    if (
      window.location.href.includes("?") ||
      window.location.href.includes("facets")
    ) {
      let currentWindowUrl = window.location.href;
      this.updateUsingURL(currentWindowUrl);
    }
  }

  checkStaticCategory = moburl => {
    const {
      static_categories,
      getInitialFacets,
      active_category,
      updateSearchUrl
    } = this.props;
    let params = this.getExtraParameter(moburl.search);
    let otherParams = params.params;
    let titleParam = params.q;
    let catParam = "";
    let catActive = "";
    let sortParams = params.sort_by;
    let attParam = [];
    let url = api.get_product_list();
    let urlToFetch = url;

    if (otherParams) {
      if (typeof otherParams === "string") {
        urlToFetch = urlToFetch + "?params=" + otherParams;
        catParam = otherParams.includes("class") ? otherParams : "";
        attParam = otherParams.includes("attribute") ? otherParams : "";
      } else if (typeof otherParams === "object") {
        catParam = otherParams.find(i => i.includes("class"));
        urlToFetch = urlToFetch + "?params=" + otherParams.join("&params=");
        attParam = otherParams.filter(i => i.includes("attribute"));
      }
    }
    if (titleParam) {
      updateSearchUrl(titleParam);
      if (urlToFetch.includes("?")) {
        urlToFetch = urlToFetch + "&q=" + titleParam;
      } else {
        urlToFetch = urlToFetch + "?q=" + titleParam;
      }
    }
    if (sortParams && urlToFetch.includes("?")) {
      urlToFetch = urlToFetch + "&sort_by=" + sortParams;
    }
    if (catParam) {
      catActive = catParam.split(":")[1];
    }

    
    if (static_categories && static_categories.length <= 0 && catActive) {
      const category_url_id = catActive;
      getInitialFacets(category_url_id, urlToFetch, sortParams, attParam);
    } else if (static_categories && static_categories.length > 0 && active_category) {
      let catToSend = active_category.text;
      if (catActive) {
        catToSend = catActive;
      }
      getInitialFacets(catToSend, urlToFetch, sortParams, attParam);
    } else {
      getInitialFacets(catActive, urlToFetch, sortParams, attParam);
    }
  };

  updateUsingURL = currentWindowUrl => {
    const {
      attribute_value,
      activeAttributeUpdate,
    } = this.props;

    let newUrl = new URL(currentWindowUrl);
    let params = this.getExtraParameter(newUrl.search);
    let otherParams = params.params;
    let priceParam = null;
    let attParam = [];

    if (otherParams && typeof otherParams === "string") {
      priceParam = otherParams.includes("price") ? otherParams : "";
    } else if (otherParams) {
      priceParam = otherParams.find(i => i.includes("price"));
    }

    if (priceParam) {
      this.updateActivePrice(priceParam.split(":")[1]);
    }
    if (attParam.length > 0 && attribute_value && attribute_value.length > 0) {
      let activeAtts = [];
      attParam.map(att => {
        let att_id = att.split(":")[1];
        let updateAtt = attribute_value.find(i => i.text === att_id);
        activeAtts.push(updateAtt);
        return null;
      });
      activeAttributeUpdate(activeAtts);
    }
  };

  updateActivePrice = activePrice => {
    const {
      updateFacetValue,
      updateCustomPrice
    } = this.props;
    let price_value = null;
    if (activePrice === "[0 TO 25]") {
      price_value = 1;
    } else if (activePrice === "[25 TO 50]") {
      price_value = 2;
    } else if (activePrice === "[50 TO 100]") {
      price_value = 3;
    } else if (activePrice === "[100 TO 9999]") {
      price_value = 4;
    } else {
      price_value = 5;
      let low = activePrice.split("TO")[0];
      let high = activePrice.split("TO")[1];
      low = low.replace(/\D/g, "");
      high = high.replace(/\D/g, "");
      let price = {
        low: low,
        high: high
      };
      updateCustomPrice({ ...price });
    }
    updateFacetValue(price_value);
  };

  getExtraParameter = url => {
    let params = {};
    params = queryString.parse(url);
    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }
    return params;
  };

  componentDidUpdate() {
    let facetSearch = document.getElementById("id_search");
    let headerSearch = document.getElementById("searchBar");
    let params = this.getExtraParameter(window.location.search);
    if (params.q) {
      if (facetSearch && facetSearch.value !== params.q) {
        facetSearch.value = "";
      }
      if (headerSearch && headerSearch.value !== params.q) {
        headerSearch.value = "";
      }
    }
  }

  handleSelectedProductCategory = (category, type) => () => {

    const {
      updateActiveCategory,
      showLoader,
      updateLoaderHorizontal,
      updateActiveParentCategory,
      active_category,
      history,
      active_parent_category
    } = this.props;
    updateLoaderHorizontal(true);
    let url = category.narrow_url;
    let searchUrl="";
    let search_path="";
    let new_url = new URL(url);
    let params = this.getExtraParameter(new_url.search);

    const { pathname } = history.location;
    let other_params = params.params;
    if(type === "category"){
      if(active_category && active_category.text === category.text){
        other_params = params.params.filter(i=>i.indexOf("category")>-1);
        updateActiveCategory(null); 
        let temp_url = url.split("?");
        let const_url = temp_url[0];
     
        temp_url = temp_url[1].split("&");
        temp_url= temp_url.find(i=>i.indexOf("category")>-1);
        const_url=const_url+"?"+temp_url;
      
        url=const_url;
      }
      else{
        updateActiveCategory({ ...category }, params.params);     
        other_params=params.params.join("&params=");
      }
    }
    else{
      updateActiveCategory(null);
      updateActiveParentCategory({ ...category });
    }

    if(this.props.location.search){
      var queryParams = new URLSearchParams(window.location.search);
      queryParams.set("params", params.params);      
      if(queryParams.get("q")){
        searchUrl = url + "&q="+queryParams.get("q").toString();
        search_path="/search/?"+queryParams.toString();
      }
      else{
        searchUrl = url;
        search_path="/search/?params=" +other_params ;
      }
    }
    else{
      searchUrl = url;
      search_path="/search/?params=" +other_params;
    }

    // resetObjects();
    showLoader(false, null);
    if(type === "category"){
      if(pathname && pathname.indexOf("/shop/category")<0){
        this.getProductsFunc(searchUrl);
      }

      if(active_category && active_category.text === category.text){
        this.historyPushFunc( "/shop/category/"+active_parent_category.text+"/");
  
      }
      else{
        this.historyPushFunc(search_path);
      }
    }
    else{
      this.getProductsFunc(searchUrl);
      var queryParams = new URLSearchParams(window.location.search);
      if(queryParams.get("q")){
        this.historyPushFunc(search_path);
      }
      else{
        this.historyPushFunc("/shop/category/"+category.text+"/");
      }
    }

  };

  handleSelectedAttribute = (attribute, key) => () => {
    const {
      attActive,
      activeAttributeUpdate,
      updateLoaderHorizontal
    } = this.props;

    const { mobileCurrentURL } = this.state;
    let url = attribute.narrow_url;
    let url_root = api.get_product_list();
    let urlDisplay = "";
    let att = attActive;
    let index = att.findIndex(i => i.text === attribute.text);
    let removedAttribute = null;
    if (index > -1) {
      removedAttribute = att.splice(index, 1);
    } else {
      att.push(attribute);
    }

    let attParams = null;

    activeAttributeUpdate(att);
    if (removedAttribute) {
      let remAtt = encodeURIComponent(
        "attribute_value_exact:" + removedAttribute[0].text
      );
      let newURL = new URL(url);
      let otherparams = this.getExtraParameter(newURL.search);

      let params = otherparams.params;
      let titleParam = otherparams.q;
      if (params) {
        if (typeof params === "string") {
          if (params === remAtt) {
            urlDisplay = urlDisplay + "params=" + params.params;
            this.getProductsFunc();
          }
        } else if (typeof params === "object") {
          let newPars = params.filter(a => encodeURIComponent(a) !== remAtt);
          let newUrlFetch = url_root + "?params=" + newPars.join("&params=");
          urlDisplay = urlDisplay + "params=" + newPars.join("&params=");
          if (titleParam) {
            urlDisplay = urlDisplay + "&q=" + titleParam;
            newUrlFetch = newUrlFetch + "&q=" + titleParam;
          }
          this.getProductsFunc(newUrlFetch);
        }
      }
    } else {
      let currentUrl = window.location.href;
      if (currentUrl.includes("?")) {
        let newURL = new URL(currentUrl);
        attParams = this.getExtraParameter(newURL.search);
      } else if (currentUrl.includes("facets") && mobileCurrentURL) {
        let mobParams = mobileCurrentURL.split("?")[1];
        if (mobParams) {
          let newURL = new URL(url_root + "?" + mobParams);
          attParams = this.getExtraParameter(newURL.search);
        }
      }
      if (attParams) {
        let newAtt = encodeURIComponent(
          "attribute_value_exact:" + attribute.text
        );
        if (attParams.params) {
          if (typeof attParams.params === "string") {
            urlDisplay =
              urlDisplay + "params=" + attParams.params + "&params=" + newAtt;
          } else {
            urlDisplay =
              urlDisplay +
              "params=" +
              attParams.params.join("&params=") +
              "&params=" +
              newAtt;
          }
        }
        let titleParam = attParams.q;
        if (titleParam) {
          if (urlDisplay.includes("params")) {
            urlDisplay = urlDisplay + "&q=" + titleParam;
          } else {
            urlDisplay = urlDisplay + "q=" + titleParam;
          }
        }
        let newUrlFetch = url_root + "?" + urlDisplay;
        this.getProductsFunc(newUrlFetch);
      } else {
        this.getProductsFunc(url);
      }
    }
    this.historyPushFunc("/search?" + urlDisplay);
    updateLoaderHorizontal(true);
  };

  byKey = key => {
    return function (o) {
      var v = parseInt(o[key], 10);
      return isNaN(v) ? o[key] : v;
    };
  };

  renderParentCategories = () => {
    const {
      classes,
      static_parent_categories,
      active_parent_category,
      t,
      product_fetching
    } = this.props;
    if (static_parent_categories && static_parent_categories.length > 0) {
      let sortedMenuItems = _.sortBy(static_parent_categories, this.byKey("text"));
      return sortedMenuItems.map((item, index) => {
        let a = active_parent_category && active_parent_category.text === item.text;
        return (
          <FormControlLabel
            key={index}
            control={
              <Radio
                checked={a === null || a === false ? false : true}
                disabled={product_fetching}
                onChange={this.handleSelectedProductCategory(item, "parent_category")}
                value={item.text}
                name="radio-categories"
                aria-label={"A" + item.text}
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
            }
            classes={{
              label: classes.label
            }}
            label={item.title}
          />
        );
      });
    } else {
      return <p>{t("buyingPage.shopFacets.nocategory")}</p>;
    }
  };

  renderCategories = (sortedMenuItems) => {
    const {
      classes,
      active_category,
      t,
      product_fetching
    } = this.props;
    if (sortedMenuItems && sortedMenuItems.length > 0) {
      return sortedMenuItems.map((item, index) => {
        let a = active_category && active_category.text === item.text;
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={a === null || a === false ? false : true}
                disabled={product_fetching}
                onChange={this.handleSelectedProductCategory(item, "category")}
                value={item.text}
                name="radio-categories"
                aria-label={"A" + item.text}
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
            }
            classes={{
              label: classes.label
            }}
            label={item.title}
          />
        );
      });
    } else {
      return <p>{t("buyingPage.shopFacets.nocategory")}</p>;
    }
  };

  renderAttributesValues = (values, key) => {
    const attributeVal = values.attribute;
    const { classes, attActive, product_fetching } = this.props;
    if (attributeVal && attributeVal.length > 0) {
      return attributeVal.map((item, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={
                  attActive.length > 0 &&
                  attActive.some(e => e.text === item.text)
                }
                disabled={product_fetching}
                onChange={this.handleSelectedAttribute(item, key)}
                value={item.text}
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
            }
            classes={{
              label: classes.label
            }}
            label={item.title}
          />
        );
      });
    }
  };

  handlePriceFacetChange = (value = 0) => () => {
    const { updateFacetValue, updateCustomPrice, updateLoaderHorizontal } = this.props;
    const { mobileCurrentURL } = this.state;
    let currentUrl = window.location.href;
    let url = api.get_product_list();
    let url_root = api.get_product_list();
    let otherparams = null;
    if (currentUrl.includes("?")) {
      let newURL = new URL(currentUrl);
      otherparams = this.getExtraParameter(newURL.search);
    } else if (currentUrl.includes("facets") && mobileCurrentURL) {
      let mobParams = mobileCurrentURL.split("?")[1];
      if (mobParams) {
        let newURL = new URL(url_root + "?" + mobParams);
        otherparams = this.getExtraParameter(newURL.search);
      }
    }
    let target_price = "any";
    if (value === 1) {
      target_price = "[0 TO 25]";
    } else if (value === 2) {
      target_price = "[25 TO 50]";
    } else if (value === 3) {
      target_price = "[50 TO 100]";
    } else if (value === 4) {
      target_price = "[100 TO 9999]";
    } else if (value === 5) {
      updateFacetValue(value);
      return;
    }

    if (value !== 5) {
      updateCustomPrice({});
    }

    if (target_price !== "any") {
      url = url + "?params=price_exact:" + target_price + "&";
    } else {
      url = url + "?";
    }

    if (otherparams) {
      let parArr = otherparams.params;
      if (
        typeof parArr === "string" &&
        parArr.includes("price_exact") === false
      ) {
        url = url + "params=" + parArr;
      } else if (typeof parArr === "object") {
        let newarr = parArr.filter(a => a.includes("price_exact") === false);
        if (newarr.length > 0) {
          url = url + "params=" + newarr.join("&params=");
        }
      }
      let titleParam = otherparams.q;
      if (titleParam) {
        url = url + "&q=" + titleParam;
      }
    }
    updateFacetValue(value);
    let urlDisplay = "";
    let newURL = new URL(url);
    let urlParams = this.getExtraParameter(newURL.search);
    if (urlParams.params) {
      if (typeof urlParams.params === "string") {
        urlDisplay = "params=" + urlParams.params;
      } else {
        urlDisplay = "params=" + urlParams.params.join("&params=");
      }
    }

    if (urlParams.q) {
      if (urlDisplay.includes("params")) {
        urlDisplay = urlDisplay + "&q=" + urlParams.q;
      } else {
        urlDisplay = urlDisplay + "q=" + urlParams.q;
      }
    }
    this.getProductsFunc(url_root + "?" + urlDisplay);
    this.historyPushFunc("/search?" + urlDisplay);
    updateLoaderHorizontal(true);
  };

  getProductsFunc = url => {
    const { active_sort, getProducts } = this.props;
    if (url.indexOf("?") > -1) {
      if (active_sort) {
        url = url + "&sort_by=" + active_sort.code;
        getProducts(url);
      } else {
        getProducts(url);
      }
    } else {
      getProducts(url);
    }
  };

  handleCustomPriceSubmit = e => {
    const { updateCustomPrice, updateLoaderHorizontal } = this.props;
    const { mobileCurrentURL } = this.state;
    e.preventDefault();
    let currentUrl = window.location.href;
    let url = api.get_product_list();
    let url_root = api.get_product_list();
    let otherparams = null;
    if (currentUrl.includes("?")) {
      let newURL = new URL(currentUrl);
      otherparams = this.getExtraParameter(newURL.search);
    } else if (currentUrl.indexOf("facets") > -1 && mobileCurrentURL) {
      let mobParams = mobileCurrentURL.split("?")[1];
      if (mobParams) {
        let urlToFetch = url + "?" + mobParams;
        let newURL = new URL(urlToFetch);
        otherparams = this.getExtraParameter(newURL.search);
      }
    }
    let low = e.target.low.value;
    let high = e.target.high.value;

    low = low.toString();
    high = high.toString();
    let custPrice = {
      low: low,
      high: high
    };
    updateCustomPrice(custPrice);

    let lowNum = low;
    if (!isInputEnglish(lowNum)) {
      lowNum = fromArabicToASCII(lowNum);
    }
    let highNum = high;
    if (!isInputEnglish(highNum)) {
      highNum = fromArabicToASCII(highNum);
    }

    let target_price = "[" + lowNum + " TO " + highNum + "]";
    url = url + "?params=price_exact:" + target_price;
    if (otherparams) {
      let parArr = otherparams.params;
      if (
        parArr &&
        typeof parArr === "string" &&
        parArr.includes("price_exact") === false
      ) {
        url = url + "&params=" + parArr;
      } else if (parArr && typeof parArr === "object") {
        let newarr = parArr.filter(a => a.includes("price_exact") === false);
        url = url + "&params=" + newarr.join("&params=");
      }
      let titleParam = otherparams.q;
      if (titleParam) {
        url = url + "&q=" + titleParam;
      }
    }

    let urlDisplay = "";
    let newURL = new URL(url);
    let urlParams = this.getExtraParameter(newURL.search);
    if (urlParams.params) {
      if (typeof urlParams.params === "string") {
        urlDisplay = "params=" + urlParams.params;
      } else if (typeof urlParams.params === "object") {
        urlDisplay = "params=" + urlParams.params.join("&params=");
      }
    }
    if (urlParams.q) {
      if (urlDisplay.includes("params")) {
        urlDisplay = urlDisplay + "&q=" + urlParams.q;
      } else {
        urlDisplay = urlDisplay + "q=" + urlParams.q;
      }
    }

    this.getProductsFunc(url_root + "?" + urlDisplay);
    this.historyPushFunc("/search?" + urlDisplay);
    updateLoaderHorizontal(true);
  };

  isSm = () => {
    if (isWidthDown("sm", this.props.width) || this.props.width === "sm") {
      return true;
    } else {
      return false;
    }
  };

  formatNumber(num) {
    if (num && num.length > 0) {
      num = convertHindiToArabic(num);
      return num;
    } else {
      return '';
    }
  }

  customPriceChange = event => {
    const { updateCustomPrice, custom_price, updateLoaderHorizontal } = this.props;
    let newPrice = {};
    if (event) {
      let name = event.target.name;
      let value = event.target.value;
      let isEng = isInputEnglish(value);
      let targetValue1 = this.formatNumber(value);
      let targetValueInt = parseInt(targetValue1);
      let displayPrice = targetValueInt ? targetValueInt + "" : "";
      if (displayPrice && !isEng) {
        displayPrice = converToArabicString(displayPrice);
      }
      if (name === "low") {
        newPrice["low"] = displayPrice;
        if (custom_price.high) {
          newPrice["high"] = custom_price.high;
        }
      } else if (name === "high") {
        if (custom_price.low) {
          newPrice["low"] = custom_price.low;
        }
        newPrice["high"] = displayPrice;
      }
    }
    updateCustomPrice(newPrice);
    updateLoaderHorizontal(true);
  };

  renderPriceFacets = () => {
    const {
      classes,
      price_facet,
      t,
      custom_price,
      product_fetching
    } = this.props;

    return (
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              onChange={this.handlePriceFacetChange()}
              checked={price_facet === 0}
              disabled={product_fetching}
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            root: "taWebAuto",
            label: classNames(classes.label, classes.fontBlack)
          }}
          label={t("buyingPage.shopFacets.any")}
        />
        <FormControlLabel
          control={
            <Radio
              onChange={this.handlePriceFacetChange(1)}
              checked={price_facet === 1}
              disabled={product_fetching}
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            root: "taWebAuto",
            label: classNames(classes.label, classes.fontBlack)
          }}
          label={t("buyingPage.shopFacets.under1")}
        />
        <FormControlLabel
          control={
            <Radio
              onChange={this.handlePriceFacetChange(2)}
              checked={price_facet === 2}
              disabled={product_fetching}
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            root: "taWebAuto",
            label: classNames(classes.label, classes.fontBlack)
          }}
          label={t("buyingPage.shopFacets.under2")}
        />
        <FormControlLabel
          control={
            <Radio
              onChange={this.handlePriceFacetChange(3)}
              checked={price_facet === 3}
              disabled={product_fetching}
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            root: "taWebAuto",
            label: classNames(classes.label, classes.fontBlack)
          }}
          label={t("buyingPage.shopFacets.under3")}
        />
        <FormControlLabel
          control={
            <Radio
              onChange={this.handlePriceFacetChange(4)}
              checked={price_facet === 4}
              disabled={product_fetching}
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            root: "taWebAuto",
            label: classNames(classes.label, classes.fontBlack)
          }}
          label={t("buyingPage.shopFacets.over")}
        />
        <FormControlLabel
          control={
            <Radio
              onChange={this.handlePriceFacetChange(5)}
              checked={price_facet === 5}
              disabled={product_fetching}
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            root: "taWebAuto",
            label: classNames(classes.label, classes.fontBlack)
          }}
          label={t("buyingPage.shopFacets.custom")}
        />
        <form onSubmit={this.handleCustomPriceSubmit} autoComplete="off">
          <GridContainer alignItems="center" className="mr-0 customPriceInputs">
            <GridItem sm={12} md={5} className="low">
              <CustomInput
                id="id_search_low"
                size="lg"
                formControlProps={{
                  fullWidth: true,
                  className: "mb-0"
                }}
                inputProps={{
                  name: "low",
                  required: true,
                  value: custom_price.low ? custom_price.low : "",
                  onChange: this.customPriceChange,
                  onClick: this.handlePriceFacetChange(5),
                  type: "text",
                  placeholder: t("buyingPage.shopFacets.low"),
                  disableUnderline: true
                }}
              />
            </GridItem>
            <GridItem sm={12} md={2} className="p-0">
              <p className="mb-0 text-center">
                {t("buyingPage.shopFacets.to")}
              </p>
            </GridItem>
            <GridItem sm={12} md={5} className="high">
              <CustomInput
                size="lg"
                id="id_search_high"
                formControlProps={{
                  fullWidth: true,
                  className: "mb-0"
                }}
                inputProps={{
                  name: "high",
                  required: true,
                  value: custom_price.high ? custom_price.high : "",
                  onChange: this.customPriceChange,
                  onClick: this.handlePriceFacetChange(5),
                  type: "text",
                  placeholder: t("buyingPage.shopFacets.high"),
                  disableUnderline: true
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={this.isSm() ? "mt24" : "mt-2"}>
            <GridItem sm={12} md={12}>
              <Button
                color="primaryWhiteWithBorder"
                disabled={price_facet !== 5}
                fullWidth
                size="lg"
                type="submit"
              >
                {t("buyingPage.shopFacets.go")}
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  };

  constructActiveURL = () => {
    const {
      price_facet,
      attActive,
      active_category,
      history,
      active_sort,
      custom_price,
      active_parent_category,
      searchUrl
    } = this.props;
    let url = "/search?";
    if(active_parent_category && !active_category ){
      url="/shop/category/:"+active_parent_category.text;
    }
    else if(active_parent_category && active_category){
      if (active_parent_category && active_parent_category.text) {
        url = url + "params=category_exact:" + active_parent_category.text;
      }
      if (active_category && active_category.text) {
        url = url + "&params=product_class_exact:" + active_category.text;
      }
      if (searchUrl) {
        if (url.includes("params")) {
          url = url + "&q=" + searchUrl;
        } else {
          url = url + "q=" + searchUrl;
        }
      }
      if (price_facet) {
        let target_price = "any";
        if (price_facet === 1) {
          target_price = "[0 TO 25]";
        } else if (price_facet === 2) {
          target_price = "[25 TO 50]";
        } else if (price_facet === 3) {
          target_price = "[50 TO 100]";
        } else if (price_facet === 4) {
          target_price = "[100 TO 9999]";
        } else if (price_facet === 5 && custom_price.low && custom_price.high) {
          let lowNum = custom_price.low;
          if (!isInputEnglish(lowNum)) {
            lowNum = fromArabicToASCII(lowNum);
          }
          let highNum = custom_price.high;
          if (!isInputEnglish(highNum)) {
            highNum = fromArabicToASCII(highNum);
          }
          target_price =
            "[" + custom_price.low + " TO " + custom_price.high + "]";
        }
        if (url.includes("params")) {
          url = url + "&params=price_exact:" + target_price;
        } else {
          url = url + "params=price_exact:" + target_price;
        }
      }
      if (attActive.length > 0) {
        let attUrlArr = [];
        attActive.map(attribute => {
          attUrlArr.push(
            encodeURIComponent("attribute_value_exact:" + attribute.text)
          );
          return null;
        });
        if (url.includes("params")) {
          url = url + "&params=" + attUrlArr.join("&params=");
        } else {
          url = url + "params=" + attUrlArr.join("&params=");
        }
      }

      if (active_sort & url.indexOf("?")>-1) {
        url = url + "&sort_by=" + active_sort.code;
      }
  }
  
  else{
    url="/shop"
  }
    history.push({pathname:url, search:''});
  };

  historyPushFunc = pushTo => {
    const { history, active_sort } = this.props;
    const { applyFilter } = this.state;
    let smDevice = this.isSm();
    if (smDevice) {      
        if (applyFilter && pushTo === "useMobURL") {
          this.constructActiveURL();
        } else {
          this.setState({ mobileCurrentURL: pushTo });
        }
    } else {
      if (active_sort && pushTo.indexOf("?")>-1) {
        pushTo = pushTo + "&sort_by=" + active_sort.code;
      }
      history.push({pathname:pushTo, search:""});
    }
  };

  onKeyDown = event => {
    const {
      getProducts,
      history,
      active_parent_category,
      updateSearchUrl,
      updateLoaderHorizontal
    } = this.props;
    let url = api.get_product_list();
    if (event.keyCode === 13) {
      let searchUrl ="";
      let search_path="/search?q=" + event.target.value;
      if(this.props.location.search){
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("q", event.target.value);
        if(queryParams){
          searchUrl = url + "?"+queryParams.toString();
          search_path="/search/?"+queryParams.toString();
        }
      }
      else if(active_parent_category){
        search_path=search_path+"&params=category_exact:"+active_parent_category.text;
      }
      else{
        searchUrl = url + "?q=" + event.target.value;
      }
      updateSearchUrl(event.target.value);
      if (window.location.search) {
        getProducts(searchUrl);
      }
      if (event.target.value !== "") {
        history.push(search_path);
      }
      updateLoaderHorizontal(true);
    }
  };

  getGroups = () => {
    const { attribute_value ,  } = this.props;
    var attribute_groups = {};
    if (attribute_value) {
      attribute_value.forEach(item => {
        if (
          !attribute_groups.hasOwnProperty(item.attr_name) &&
          item.count > 0
        ) {
          attribute_groups[item.attr_name] = {
            attribute: []
          };
        }
        if (item.count > 0) {
          attribute_groups[item.attr_name].attribute.push({
            title: item.title,
            text: item.text,
            count: item.count,
            attr_order: item.attr_order,
            attr_main: item.attr_main,
            narrow_url: item.narrow_url
          });
        }
      });
    }
    return attribute_groups;
  };

  renderCollapses = () => {
    let collapseArr = [];
    let attributeArr = [];
    const { classes, t,active_parent_category ,category} = this.props;
    const attributeGroups = this.getGroups();
    collapseArr.push({
      title: t("buyingPage.shopFacets.category"),
      titleClasses: classes.accodrianTitle,
      content: (
        <div className={classes.customExpandPanel}>
          <div
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            }
          >
            {this.renderParentCategories()}
          </div>
        </div>
      )
    });

    if(active_parent_category){
      let sortedMenuItems = _.sortBy(category, this.byKey("text"));
      sortedMenuItems = sortedMenuItems.filter(i=>i.count>0);
      if(sortedMenuItems && sortedMenuItems.length>0){
        collapseArr.push({
          title: "Product Types",
          titleClasses: classes.accodrianTitle,
          content: (
            <div className={classes.customExpandPanel}>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                {this.renderCategories(sortedMenuItems)}
              </div>
            </div>
          )
        });
      }
    }

    if (window.location.pathname !== "/shop" && this.props.active_category) {
      collapseArr.push({
        title: t("buyingPage.shopFacets.price"),
        titleClasses: classes.accodrianTitle,
        content: (
          <div className={classes.customExpandPanel}>
            {this.renderPriceFacets()}
          </div>
        )
      });

      for (var key in attributeGroups) {
        if (attributeGroups.hasOwnProperty(key)) {
          let attr_order = 0;
          let attr_main = false;
          if (
            attributeGroups[key].attribute.length > 0 &&
            attributeGroups[key].attribute[0]
          ) {
            attr_order = attributeGroups[key].attribute[0].attr_order;
            attr_main = attributeGroups[key].attribute[0].attr_main;
          }
          attributeArr.push({
            title: key,
            titleClasses: classes.accodrianTitle,
            attr_order: attr_order,
            attr_main: attr_main,
            content: (
              <div className={classes.customExpandPanel}>
                {this.renderAttributesValues(attributeGroups[key], key)}
              </div>
            )
          });
        }
      }
    }

    let priorityIndex = { undefined: 1, true: 2, false: 3 };

    let newAttributeArr = _.sortBy(attributeArr, o => o.title);

    newAttributeArr = _.sortBy(newAttributeArr, o => o.attr_order);

    newAttributeArr.sort(
      (a, b) => priorityIndex[a.attr_main] - priorityIndex[b.attr_main]
    );
    let newCollapseArr = collapseArr.concat(newAttributeArr);
    return newCollapseArr;
  };

  resetFilter = () => {
    const { resetSearchParams, getProducts, history, updateLoaderHorizontal ,updateSearchUrl} = this.props;
    resetSearchParams();
    getProducts();
    updateSearchUrl("");
    history.push({pathname:"/shop", search:""});
    updateLoaderHorizontal(true);
  };

  searchGoClicked = e => {
    const {
      getProducts,
      history,
      updateSearchUrl,
      updateLoaderHorizontal
    } = this.props;

    let searchUrl ="";

    // if(this.props.location.search){
    //   searchUrl = url + this.props.location.search+"&q=" + event.target.value;
    //   search_path="/search/" + this.props.location.search+"&q=" + event.target.value;
    // }
    // else{
    //   searchUrl = url + "?q=" + event.target.value;
    // }
    // updateSearchUrl(event.target.value);
    // // resetSearchParams();
    // if (window.location.search) {
    //   getProducts(searchUrl);
    // }
    // if (event.target.value !== "") {
    //   history.push(search_path);
    // }
    // updateLoaderHorizontal(true);


    let url = api.get_product_list();
    let searchValue = document.getElementById("id_search");
    let search_path="/search?q=" + searchValue;
    if (searchValue) {
    if(this.props.location.search){
      var queryParams = new URLSearchParams(window.location.search);
      queryParams.set("q", searchValue.value);
      if(queryParams){
        searchUrl = url + "?"+queryParams.toString();
        search_path="/search/?"+queryParams.toString();
      }
    }
    else{
      searchUrl = url + "?q=" + searchValue.value;
    }      
    updateSearchUrl(searchValue.value);
    if (window.location.search) {
      getProducts(searchUrl);
    }
    if (searchUrl !== "") {
      history.push(search_path);
    }
    }
    updateLoaderHorizontal(true);
  };

  render() {
    const { classes, className, t, direction, product_fetching } = this.props;
   
    return (
      <GridContainer>
        <GridItem>
          <Card plain className={className}>
            <CardBody className={classes.cardBodyRefine + " p-0"}>
              <GridContainer
                className={
                  classes.textLeft +
                  " mb-3 hideMobile dp-f justify-content-between"
                }
              >
                <GridItem xs={9} className="dp-f p-0">
                  <h3>{t("buyingPage.shopFacets.filter")}</h3>
                </GridItem>
                <GridItem xs={3} className="dp-f align-items-center jcfe p-0">
                  <Tooltip
                    id="tooltip-top"
                    title={t("buyingPage.shopFacets.resetFilter")}
                    placement="top"
                    className="border p-0"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      link
                      justIcon
                      size="sm"
                      onClick={this.resetFilter}
                      className={`${classes.pullRight} ${classes.refineButton}`}
                    >
                      <Cached />
                    </Button>
                  </Tooltip>
                  <Clearfix />
                </GridItem>
              </GridContainer>
              <GridContainer className={this.isSm() ? "display-none" : ""}>
                <GridItem xs={9} className={"p-0"}>
                  <CustomInput
                    id="id_search"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "search",
                      onKeyDown: this.onKeyDown,
                      required: true,
                      type: "search",
                      placeholder: t("buyingPage.shopFacets.search"),
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classNames(
                            direction === "rtl"
                              ? "rotate-factes"
                              : classes.inputAdornmentLeft
                          )}
                        >
                          <Search />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={3}
                  className={direction === "rtl" ? "pl-0" : "pr-0"}
                >
                  <Button
                    color="primaryWhiteWithBorder"
                    fullWidth
                    className="m-0 minWidth"
                    size="sm"
                    onClick={this.searchGoClicked}
                  >
                    {t("buyingPage.shopFacets.go")}
                  </Button>
                </GridItem>
              </GridContainer>

              <Accordion
                active={[0,1]}
                activeColor="accordianColor"
                color="accordianColor"
                disabled={product_fetching}
                classes={{
                  expansionPanelSummary: classes.noBorder,
                  expansionPanel: classNames(classes.borderBottomPreview)
                }}
                collapses={this.renderCollapses()}
              />
              <div className={classes.borderBottomPreview} />
               <GridContainer className="my-5 dp-f jcc d-none-smd">
                <GridItem xs={11}>
                  <Button
                    color="primaryWhiteWithBorder"
                    fullWidth
                    className=" mobile_facet_apply"
                    size="lg"
                    onClick={() =>
                      this.setState({ applyFilter: true }, () =>
                        this.historyPushFunc("useMobURL")
                      )}
                  >
                    {t("buyingPage.shopFacets.applyFilter")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProducts: (url, sort_id) => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_REQUEST,
      payload: url,
      sort_id
    });
  },
  updateActiveCategory: (category, active_category_params) => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_CATEGORY,
      category,
      active_category_params
    });
  },
  updateActiveParentCategory: (parent_category) => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_PARENT_CATEGORY,
      parent_category,
      // active_category_params
    });
  },
  activeAttributeUpdate: attribute => {
    dispatch({
      type: ProductActionTypes.ACTIVE_ATTRIBUTE_UPDATE,
      attribute
    });
  },
  resetObjects: () => {
    dispatch({
      type: ProductActionTypes.RESET_OBJECTS
    });
  },
  updateFacetValue: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_PRICE_FACET,
      value
    });
  },
  resetSearchParams: () => {
    dispatch({
      type: ProductActionTypes.RESET_SEARCH_PARAMS
    });
  },
  updateSearchUrl: url => {
    dispatch({
      type: ProductActionTypes.UPDATE_SEARCH_URL,
      setUrl: url
    });
  },
  showLoader: (payload, currentFetch) => {
    dispatch({
      type: ProductActionTypes.SHOW_PRODUCTS_LOADER,
      payload,
      currentFetch
    });
  },
  updateCustomPrice: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_CUSTOM_PRICE,
      value
    });
  },
  updateLoaderHorizontal: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_LOADER_HORIZONTAL,
      value
    });
  },
  getInitialFacets: (
    category = null,
    urlToFetch = null,
    sortParams = null,
    attParam = null
  ) => {
    dispatch({
      type: ProductActionTypes.GET_INITIAL_FACET_REQUEST,
      category,
      urlToFetch,
      sortParams,
      attParam
    });
  }
});

const mapStateToProps = state => ({
  ...state.productReducer,
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(styles),
  withTranslation(),
  withWidth(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Facets);
