import { htPrimaryYellow } from "kayfik/assets/jss/universal.jsx";
import customHeaderLinksStyle from "kayfik/assets/jss/components/Header/customHeaderLinksStyle.jsx";

const scrollToTopStyle = theme => ({
  ...customHeaderLinksStyle(theme),
  scrollStyle: {
    color: htPrimaryYellow
  }
});

export default scrollToTopStyle;
