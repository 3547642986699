import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import AccountActionTypes from "constants/accountConstants.jsx";

const initialState = {
  account_fetching: false,
  account_error: null,
  account_token: null,
  user_profile: null,
  company_signup_data: {},
  companyForceStep: 0,
  userDetails: null,
  change_password: null,
  payment_info: [],
  account_page_active_component: "settings", // default to settings,
  acc_post_req_sent: [],
  password_reset: null,
  timerValue: null,
  showNextModal: false,
  showCode: false,
  emailModalActive: false,
  nextPageToGo: null,
  reqProductList:[],
  reqProdDetail: null,
  emailConfirmMsg: ""
};


const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    // login
    case AccountActionTypes.LOGIN_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        account_token: action.token,
        user_profile: action.user,
        account_fetching: action.loader,
        account_error: null
      };
    case AccountActionTypes.LOGIN_FAILED:
      return {
        ...state,
        account_fetching: false,
        account_error: action.error
      };
    // SOCIAL LOGIN
    case AccountActionTypes.SOCIAL_LOGIN_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        account_token: action.token,
        user_profile: action.user,
        //account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.SOCIAL_LOGIN_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };
    // personal signup
    case AccountActionTypes.PERSONALSIGNUP_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.PERSONALSIGNUP_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.PERSONALSIGNUP_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };
    // Company signup
    case AccountActionTypes.COMPANY_SIGNUP_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.COMPANY_SIGNUP_SUCCESS:
      return {
        ...state,
        account_fetching: true,
        account_error: null,
        company_signup_data: {}
      };
    case AccountActionTypes.COMPANY_SIGNUP_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };
    // forgot password // NOT USED
    case AccountActionTypes.FOGOTPASSWORD_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.FOGOTPASSWORD_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.FOGOTPASSWORD_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    // Password REset
    case AccountActionTypes.PASSWORD_RESET_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null,
        password_reset: action.data
      };
    case AccountActionTypes.PASSWORD_RESET_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };
    // change password
    case AccountActionTypes.CHANGE_PASSWORD_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        account_fetching: true,
        account_error: null,
        change_password: action.data
      };
    case AccountActionTypes.CHANGE_PASSWORD_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };
    // singnup
    case AccountActionTypes.SIGNOUT_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.SIGNOUT_SUCCESS:
      return {
        ...state,
        account_token: null,
        user_profile: null,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.SIGNOUT_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };
    // company signup data
    case AccountActionTypes.UPDATE_COMPANY_SIGNUP_DATA:
      return { ...state, company_signup_data: action.data };

    case AccountActionTypes.UPDATE_COMPANY_STEP:
      return { ...state, companyForceStep: action.data };

    // reset to initial
    case AccountActionTypes.ACCOUNT_RESET:
      return initialState;
    // Verify the token
    case AccountActionTypes.VERIFY_TOKEN_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.VERIFY_TOKEN_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };
    //User Details
    case AccountActionTypes.USER_DETAIL_REQUEST:
      return { ...state, account_fetching: true, account_error: action.error };
    case AccountActionTypes.USER_DETAIL_SUCCESS:
      return {
        ...state,
        account_fetching: action.loader,
        userDetails: action.data,
        account_error: null
      };
    case AccountActionTypes.USER_DETAIL_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    //EDIT USER DETAILS
    case AccountActionTypes.EDIT_USER_DETAIL_REQUEST:
      return { ...state, account_fetching: true, account_error: action.error };
    case AccountActionTypes.EDIT_USER_DETAIL_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        userDetails: action.data,
        account_error: null
      };
    case AccountActionTypes.EDIT_USER_DETAIL_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    //EDIT USER DETAILSPATCH PHONE NUMBER
    case AccountActionTypes.PATCH_PHONE_REQUEST:
      return { ...state, account_fetching: true, account_error: action.error };
    case AccountActionTypes.PATCH_PHONE_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.PATCH_PHONE_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    // Confirm email
    case AccountActionTypes.CONFIRM_EMAIL_REQUEST:
      return { ...state, account_fetching: true, account_error: action.error };
    case AccountActionTypes.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null,
        emailConfirmMsg: action.emailConfirmMsg
      };
    case AccountActionTypes.CONFIRM_EMAIL_FAILED:
      return { ...state, account_fetching: false, account_error: action.error,  emailConfirmMsg: action.emailConfirmMsg };

    // resend email confirmation
    case AccountActionTypes.RESEND_EMAIL_CONFIRMATION_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.RESEND_EMAIL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.RESEND_EMAIL_CONFIRMATION_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    // phone code verification
    case AccountActionTypes.VERIFY_PHONE_CODE_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.VERIFY_PHONE_CODE_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.VERIFY_PHONE_CODE_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    // resend PHONE confirmation
    case AccountActionTypes.RESEND_PHONE_CONFIRMATION_FETCHING:
      return { ...state, account_fetching: action.fetching };
    case AccountActionTypes.RESEND_PHONE_CONFIRMATION_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.RESEND_PHONE_CONFIRMATION_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.RESEND_PHONE_CONFIRMATION_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    // GET payment information of the user
    case AccountActionTypes.GET_PAYMENT_INFO_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.GET_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null,
        payment_info: action.data
      };
    case AccountActionTypes.GET_PAYMENT_INFO_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    // POST payment information of the user
    case AccountActionTypes.POST_PAYMENT_INFO_REQUEST:
      return { ...state, account_error: null };
    case AccountActionTypes.POST_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null,
        payment_info: action.data
      };
    case AccountActionTypes.POST_PAYMENT_INFO_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    // PUT payment information of the user
    case AccountActionTypes.PUT_PAYMENT_INFO_REQUEST:
      return { ...state, account_error: null };
    case AccountActionTypes.PUT_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null,
        payment_info: action.data
      };
    case AccountActionTypes.PUT_PAYMENT_INFO_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    // Update account page component
    case AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT:
      return { ...state, account_page_active_component: action.component };

    // Update timer
    case AccountActionTypes.UPDATE_TIMER:
      return { ...state, timerValue: action.value};

    // Update timer
    case AccountActionTypes.SHOW_CODE_INPUT:
      return { ...state,  showCode: action.value };

    // Update timer
    case AccountActionTypes.RESET_TIMER:
      return { ...state, timerValue: null };
    //show next modal
    case AccountActionTypes.SHOW_NEXT_MODAL:
      return { ...state, showNextModal: action.showNextModal };
    //show next modal
    case AccountActionTypes.REQ_PROD_LIST_UPDATE:
      return { ...state, reqProductList: action.reqProductList };
    case AccountActionTypes.REQ_PROD_DETAIL_UPDATE:
      return { ...state, reqProdDetail: action.reqProdDetail };
    // next page to go
    case AccountActionTypes.UPDATE_NEXT_PAGE_TO_GO:
      return { ...state, nextPageToGo: action.nextPageToGo };
    case AccountActionTypes.UPDATE_EMAIL_MODAL_STATUS:
      return { ...state, emailModalActive: action.emailModalActive };
    case AccountActionTypes.SET_POST_STATUS:
      return {
        ...state,
        [action.name]: action.status
      };
    case AccountActionTypes.ACCOUNT_FETCHING:
      return {
        ...state,
        account_fetching: action.payload
      };

    // Password REset
    case AccountActionTypes.BUSINESS_ACCOUNT_REQUEST:
      return { ...state, account_error: null, account_fetching: true };
    case AccountActionTypes.BUSINESS_ACCOUNT_SUCCESS:
      return {
        ...state,
        account_fetching: false,
        account_error: null
      };
    case AccountActionTypes.BUSINESS_ACCOUNT_FAILED:
      return { ...state, account_fetching: false, account_error: action.error };

    default:
      return state;
  }
};

const persistConfig = {
  key: "account",
  storage: storage,
  blacklist: [
    "account_error",
    "account_fetching",
    "acc_post_req_sent",
    "emailConfirmMsg"
  ]
};

export default persistReducer(persistConfig, accountReducer);
