import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx";

import { GraphicArabicWeb } from "kayfik/assets/jss/universal.jsx";

const customFooterStyle = theme => ({
  ...styles,
  big: {
    padding: "1.875rem 0",
    "& h5, & h4": {
      fontWeight: 700,
      fontFamily: GraphicArabicWeb,
      marginBottom: "15px"
    }
  },
  halfLogo: {
    position: "absolute",
    height: "80px",
    top: "40%",
    [theme.breakpoints.down("md")]: {
      height: "50px",
      top: "16%"
    }
  },
  rightLogo: {
    right: 0
  },
  leftLogo: {
    left: 0
  },
  rightLogoRTL: {
    right: 0,
    transform: "rotate(180deg)"
  },
  leftLogoRTL: {
    left: 0,
    transform: "rotate(180deg)"
  }
});

export default customFooterStyle;
