import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  USER_CURRENT_LOCATION: null,

  UPDATE_USER_ADDRESS_MAP: null,

  ADD_ADDRESS_REQUEST: null,
  ADD_ADDRESS_SUCCESS: null,
  ADD_ADDRESS_FAILED: null,

  GET_ADDRESS_REQUEST: null,
  GET_ADDRESS_SUCCESS: null,
  GET_ADDRESS_FAILED: null,

  GET_AREA_LIST_REQUEST: null,
  GET_AREA_LIST_SUCCESS: null,
  GET_AREA_LIST_FAILED: null,

  UPDATE_ADDRESS_REQUEST: null,
  UPDATE_ADDRESS_SUCCESS: null,
  UPDATE_ADDRESS_FAILED: null,

  UPDATE_ADD_ADDRESS_EVENT: null,
  UPDATE_MAP_LOADER: null,
  UPDATE_MAP_DRAGGED: null,
  UPDATE_MAP_ADDRESS: null,
  UPDATE_FULL_NAME: null,

  UPDATE_ACTIVE_AREA: null,

  SET_GUEST_ADDRESS: null,
  SET_COMPANY_ADDRESS: null,
  // UPDATE_MAP_COORDINATES: null,

  ADDRESS_RESET: null
});

export default ActionTypes;
