import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import ComboActionTypes from "constants/comboProductConstants.jsx";

const initialState = {
  combo_fetching: false,
  error: null,
  product_class_list: [],
  category_list: [],
  conditions:[],
  current_product_class_attributes: [],
  current_product_class_attr_url: null,
  product_duplicate_list: [],
  selected_attr_and_value: [],
  selected_product_class: { text: "", id: null },
  selected_category: { text: "", id: null },
  selected_quantity: 1,
  selected_condition: 0,
  selected_condition_object: null,
  is_buy: false,
  is_buy_price: "",
  is_buy_price_final: "",
  is_bid: false,
  is_bid_price: "",
  is_bid_price_final: "",
  description_en: "",
  description_ar: "",
  // product form data
  product_images: [],
  product_data_images: [],
  product_data: {},
  product_create: null,
  dummy: null,
  bid_message: null,
  buy_message: null,
  duplicate_loader: false,
  list_item_disable: false,
  listing_kind: "new_listing",
  list_to_duplicate: "",
  selected_duplicate_item: null,
  previous_images: [],
  pickup_time: null,
  is_other: [],
  selected_other_data: [],
  other_product_data: {},
  instantCashObj:null
};

const comboProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ComboActionTypes.UPDATE_PRODUCT_CLASS_LIST_REQUEST:
      return { ...state, error: null, combo_fetching: true };
    case ComboActionTypes.UPDATE_PRODUCT_CLASS_LIST_SUCCESS:
      return {
        ...state,
        product_class_list: action.data.data,
        conditions: action.data.conditions,
        category_list:action.data.category_data,
        combo_fetching: false,
        error: null
      };
    case ComboActionTypes.UPDATE_PRODUCT_CLASS_LIST_FAILED:
      return { ...state, combo_fetching: false, error: action.error };
    // COMBO PRODUCT CLASS ATTIBUTES
    case ComboActionTypes.UPDATE_PRODUCT_CLASS_ATTR_REQUEST:
      return { ...state, error: null, combo_fetching: true };
    case ComboActionTypes.UPDATE_PRODUCT_CLASS_ATTR_SUCCESS:
      return {
        ...state,
        current_product_class_attributes: action.data,
        combo_fetching: false,
        current_product_class_attr_url: action.url,
        error: null
      };
    case ComboActionTypes.UPDATE_PRODUCT_CLASS_ATTR_FAILED:
      return {
        ...state,
        combo_fetching: false,
        error: action.error,
        current_product_class_attributes: []
      };
    // DUPLICATE LIST
    case ComboActionTypes.UPDATE_PRODUCT_DUPLICATE_LIST_REQUEST:
      return { ...state, error: null, duplicate_loader: true };
    case ComboActionTypes.UPDATE_PRODUCT_DUPLICATE_LIST_SUCCESS:
      return {
        ...state,
        product_duplicate_list: action.data,
        duplicate_loader: false,
        error: null
      };
    case ComboActionTypes.UPDATE_PRODUCT_DUPLICATE_LIST_FAILED:
      return { ...state, duplicate_loader: false, error: action.error };
    // Product create
    case ComboActionTypes.PRODUCT_CREATE_REQUEST:
      return { ...state, error: null, combo_fetching: true };
    case ComboActionTypes.PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        combo_fetching: true,
        error: null,
        product_create: action.data
      };
    case ComboActionTypes.PRODUCT_CREATE_FAILED:
      return { ...state, combo_fetching: false, error: action.error };
    case ComboActionTypes.PRODUCT_CREATE_IMAGES_SUCCESS:
      return { ...state, combo_fetching: false };
    case ComboActionTypes.PRODUCT_CREATE_IMAGES_FAILED:
      return { ...state, combo_fetching: false };
    // Updating attr and value
    case ComboActionTypes.UPDATE_SELECTED_ATTR_VALUE:
      return { ...state, selected_attr_and_value: action.data };
    case ComboActionTypes.UPDATE_QUANTITY:
      return { ...state, selected_quantity: action.qty };
    // update selected product category
    case ComboActionTypes.UPDATE_SELECTED_PRODUCT_CLASS:
      return { ...state, selected_product_class: action.data };
    // update selected product class
    case ComboActionTypes.FILTERED_PRODUCT_CLASS_LIST:
      return { ...state, product_class_list: action.data };
    // update selected product class
    case ComboActionTypes.UPDATE_SELECTED_CATEGORY:
      return { ...state, selected_category: action.data };
    case ComboActionTypes.UPDATE_SELECTED_CONDITION:
      return { ...state, selected_condition: action.data, selected_condition_object: action.obj };
    // update selected product data this is the data to be posted while creating product.
    case ComboActionTypes.UPDATE_PRODUCT_DATA:
      return { ...state, product_data: action.data };
    // update Bid data
    case ComboActionTypes.UPDATE_BID_DATA:
      return {
        ...state,
        is_bid: action.is_bid,
        is_bid_price: action.is_bid_price,
        is_bid_price_final: action.is_bid_price_final
      };
    // update Buy data
    case ComboActionTypes.UPDATE_BUY_DATA:
      return {
        ...state,
        is_buy: action.is_buy,
        is_buy_price: action.is_buy_price,
        is_buy_price_final: action.is_buy_price_final
      };
    // UPDATE DESCRIPTION EN
    case ComboActionTypes.UPDATE_DESCRIPTION_EN:
      return { ...state, description_en: action.data };
    // UPDATE DESCRIPTION AR
    case ComboActionTypes.UPDATE_DESCRIPTION_AR:
      return { ...state, description_ar: action.data };

    //UPDATE_LISTING_KIND
    case ComboActionTypes.UPDATE_LISTING_KIND:
      return { ...state, listing_kind: action.value };

    //LIST_TO_DUPLICATE
    case ComboActionTypes.LIST_TO_DUPLICATE:
      return { ...state, list_to_duplicate: action.value };

    //LIST_ITEM_BUTTON_DISABLE product previuew modal
    case ComboActionTypes.LIST_ITEM_BUTTON_DISABLE:
      return { ...state, list_item_disable: action.value };
    // UPDATE Product images
    case ComboActionTypes.UPDATE_PRODUCT_IMAGES:
      return { ...state, product_images: action.files };
    case ComboActionTypes.UPDATE_PRODUCT_DATA_IMAGES:
      return { ...state, product_data_images: action.files };
    // Update bid and buy commission message
    case ComboActionTypes.UPDATE_BID_MESSAGE:
      return { ...state, bid_message: action.message };
    case ComboActionTypes.UPDATE_BUY_MESSAGE:
      return { ...state, buy_message: action.message };
    case ComboActionTypes.RESET_COMBO:
      return initialState;
    // update previous images
    case ComboActionTypes.UPDATE_SELECTED_DUPLICATE_ITEM:
      return { ...state, selected_duplicate_item: action.duplicate };
    case ComboActionTypes.UPDATE_PREVIOUS_IMAGES:
      return { ...state, previous_images: action.images };
    case ComboActionTypes.UPDATE_PICKUP_TIME:
      return { ...state, pickup_time: action.data };
    case ComboActionTypes.UPDATE_INSTANT_CASH_OBJ:
      return { ...state, instantCashObj: action.instantCashObj };

    // update Other data
    case ComboActionTypes.UPDATE_OTHER_INPUT_DATA:
      return { ...state, is_other: action.is_other };
    case ComboActionTypes.UPDATE_SELECTED_OTHER_DATA:
      return {
        ...state,
        selected_other_data: action.selected_other_data
      };
    case ComboActionTypes.UPDATE_OTHER_PRODUCT_DATA:
      return { ...state, other_product_data: action.data };
    default:
      return state;
  }
};

const persistConfig = {
  key: "comboProduct",
  storage: storage,
  whitelist: ["dummy", "pickup_time"]
};

export default persistReducer(persistConfig, comboProductReducer);
