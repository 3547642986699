import api from "utils/api.jsx";

const getProductList = (urlData) => {
  let url = urlData ? urlData: api.get_product_list();
  return api.getFunction(url);
};

const getProductDetail = (url) => {
  return api.getFunction(url);
};



export { 
  getProductList, 
  getProductDetail , 
 };
