import React from "react";
// nodejs library to set properties for components

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import { connect } from "react-redux";
// kayfik components
import shopStyle from "kayfik/assets/jss/views/Shop/ShopStyle.jsx";
import ScrollToTop from "kayfik/components/ScrollToTop/scrollToTop.jsx";
import Header from "kayfik/components/Header/Header.jsx";
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
import SectionProducts from "kayfik/views/Shop/Sections/SectionProducts.jsx";
// Contants
import ProductActionTypes from "constants/productConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
import { withRouter } from "react-router-dom";
class ShopHome extends React.Component {
  constructor(props) {
    super(props);
    // const {match, } = this.props;
  
    // const catId = match.params.catId;

    // request initial facets on first life cycle
    this.props.getInitialFacets(true);
  }

  componentDidMount() {
    const { updateActiveCategory,match,updateActiveParentCategory,history } = this.props;
    const catId = match.params.catId;
    const { pathname } = history.location;
    if(!catId && pathname && pathname.indexOf("facets")<0){
      updateActiveCategory(null);
      updateActiveParentCategory(null);
    }
    window.scrollTo(0, 0);
  }

  // componentWillUnmount() {
  //   const { updateActiveCategory ,updateActiveParentCategory} = this.props; 
  //     updateActiveCategory(null);
  //     updateActiveParentCategory(null);
  // }

  handleClickOpen = modal => () => {
    var x = [];
    x[modal] = true;
    this.setState(x);
  };

  handleClose = modal => () => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  render() {
    const { classes, product_fetching, global_fetching ,loader_horizontal} = this.props;
    // const { pathname } = history.location;
    return (
      <div>
        {(global_fetching || product_fetching ) && !loader_horizontal? <Loader fixed /> : null}
        {global_fetching || product_fetching ? (
          <Hidden smUp implementation="css">
            <Loader fixed />
          </Hidden>
        ) : null}
        <ScrollToTop />
        <Header id="header" />
        <div className={classNames(classes.main, "min-70vh")}>
          <SectionProducts />
        </div>
        <BottomBar />
        <CustomFooter />
      </div>
    );
  }
}

// ShopHome.propTypes = {
//   getInitialFacets: PropTypes.func
// };

const mapDispatchToProps = dispatch => ({
  getInitialFacets: (top_categories = false) => {
    dispatch({
      type: ProductActionTypes.GET_INITIAL_FACET_REQUEST,
      top_categories
    });
  },
  updateActiveCategory: category => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_CATEGORY,
      category
    });
  },
  updateActiveParentCategory: (parent_category) => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_PARENT_CATEGORY,
      parent_category,
      // active_category_params
    });
  },
});

const mapStateToProps = state => ({
  ...state.bidReducer,
  ...state.accountReducer,
  ...state.productReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(shopStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ShopHome);
