import React from "react";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import Muted from "components/Typography/Muted.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import ModalPagination from "kayfik/components/Pagination/newPage.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getOrderDetail } from "actions/cartActions.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import { decimalPlace , postActionNotificationClicked } from "utils/helpers.jsx";
import i18n from "locales/i18n.jsx";

class Orders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      compLoader: false
    };
  }


  showDetailsFunc = url => {
    const {
      updateAccountPageComponent,
      updateOrderListDetails,
      showSnackbar
    } = this.props;
    this.setState({compLoader:true});
    getOrderDetail(url)
      .then(response => response.data)
      .then(data => {
        this.setState({compLoader:false});
        updateOrderListDetails(data);
        updateAccountPageComponent("orderdetail");
      })
      .catch(e => {
        if (e.response && e.response.status >= 400 && e.response.status < 500) {
          const res_object = e.response.data;
          let messages = [];
          for (let i in res_object) {
            let error_val = res_object[i];
            if (error_val) {
              messages.push(error_val);
            }
          }
          for (let i = 0; i < messages.length; i++) {
            let txt = messages[i];
            showSnackbar(txt);
          }
        } else {
          let txt = i18n.t("common.error.network");
          showSnackbar(txt);
        }
        this.setState({compLoader:false});
      });
   
  };

  

  openModal = modal_type => async () => {
    const { showModal } = this.props;
    showModal(
      {
        open: true,
        title: modal_type,
        nextModal: "profileModal"
      },
      modal_type
    );
  };

  notAvailableData=()=>{
    const { t } = this.props;
    return(
      <GridContainer className="mt-2" key="notavailable">
        <p>{t("profileModal.orders.current")}</p>
        <GridItem xs={12} className="mt-2">
          <Button
            color="primaryWhiteWithBorder"
            size="lg"
            className="mb-3"
            fullWidth
            onClick={() => (window.location.href = "/shop")}
          >
            {t("homePage.header.buy")}{" "}
          </Button>
        </GridItem>
      </GridContainer>
    )
  }

  getOrders = () => {
    const { orders_list,  showLoader , classes , t} = this.props;
    let dataToReturn = [];
    if (orders_list) {
      if (orders_list.count > 0) {
         orders_list.results.map((order, i) => {
          dataToReturn.push (
            <Card
              bordered
              key={i}
              className="pointer"
              onClick={() => this.showDetailsFunc(order.url)}
              id={
                i === orders_list.results.length - 1 ? "lastOrder" :( i === 0?"firstorder" : "order" + i)
              }
            >
              <CardBody className="p-0">
                <div className={"p-3"}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5 className="m-0">
            {t("profileModal.orderNumber")}: {order.number} <span className="ml-1">({order.lines.length} {order.lines.length >1?  t("profileModal.order_items") :t("profileModal.order_item") })</span>
                      </h5>
                    </GridItem>
                    <GridItem xs={6}>
                      <h5 className="m-0 dp-f jcfe">
                        {order.date_placed
                          .slice(0, 10)
                          .split("-")
                          .join("/")}
                      </h5>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={6}>
                      <h5 className="mb-1">
                        <Muted>
                          {order.currency} {decimalPlace(order.total_incl_tax)}
                        </Muted>
                      </h5>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
            </Card>
          );
          return true;
        });
        if (showLoader) {
          dataToReturn.push(<div className="dp-f jcc" key="progress" >
            <CircularProgress
              disableShrink
              classes={{ root: classes.indicator }}
            />
          </div>)
        }
      }
      else{
        dataToReturn.push(this.notAvailableData())
      }
    }
    else{
      dataToReturn.push(this.notAvailableData())
    }

    return dataToReturn;
  };

  render() {
    const { cart_fetching, t, orders_list,auction_notification_count} = this.props;
    const { compLoader } = this.state;
    let nextLink = orders_list ? orders_list.next : null;
    let elementID = "lastOrder";
    let nextLinkToFetch = nextLink;
    let loadMore = "orders";
    
      // < --------  cleared order notification count if available -------->
        let order_item_count =
        auction_notification_count && auction_notification_count.order_items
          ? auction_notification_count.order_items
          : null;

          if (order_item_count && order_item_count.count > 0) {
            postActionNotificationClicked(order_item_count.url_to_read);
          }

       // < --------  cleared order notification count if available -------->

    if (cart_fetching || compLoader) {
      return (
        <Hidden smDown implementation="css">
          <Loader noHeight={true}/>
        </Hidden>
      );
    }
    return (
      <div className="main-background-color product_listing">
        <h4 className="profile_section">{t("profileModal.orders.title")}</h4>
        <div
          className="partnerProductListing scrollbar"
          onScroll={() =>
            ModalPagination.trackScrolling(elementID, nextLinkToFetch, loadMore)
          }
        >
          {this.getOrders()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  orderDetailVariable: value => {
    dispatch({
      type: CartActionTypes.UPDATE_ORDER_DETAIL_VARIABLE,
      payload: value
    });
  },
  getOrderDetails: url => {
    dispatch({
      type: CartActionTypes.GET_ORDER_DETAILS_REQUEST,
      payload: url
    });
  },
  updateOrderListDetails: data => {
    dispatch({
      type: CartActionTypes.UPDATE_ORDER_LIST_DETAIL,
      data
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(Orders);
