import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

import customInputStyle from "kayfik/assets/jss/components/CustomInput/customInputStyle.jsx";

function renderMenuItems(menuItems, classes) {
  if (menuItems !== undefined) {
    return menuItems.map((item, index) => {
      return (
        <option
          value={item.value}
          key={index}
          className={classNames(
            classes.selectMenuItem,
            classes.selectMenuItemColor
          )}
        >
          {item.label}
        </option>
      );
    });
  }
}

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    selectProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    size,
    menuItems,
    menuFirstItem
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.whiteInput]: white,
    [classes[size]]: size
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={classNames(formControlClasses,"selectArrow")}>
      {labelText !== undefined ? (
        <div
        className={
          inputProps.required
            ? "required " + classes.formLabel + " " + labelClasses
            : classes.formLabel + " " + labelClasses
        }
      >
          {labelText}
        </div>
      ) : null}
      <Select
        native
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select:
            classes.formInputStyle + " " + inputClasses + " " + classes.select,
          root: marginTop + " " + classes.withAdormentBG,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        {...inputProps}
        {...selectProps}
      >
        {menuFirstItem !== undefined ? <option value="" >{menuFirstItem}</option> : null}
        {renderMenuItems(menuItems, classes)}
      </Select>
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  selectProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg"]),
  menuItems: PropTypes.array,
  menuFirstItem: PropTypes.string
};

export default withStyles(customInputStyle)(CustomInput);
