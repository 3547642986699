import api from "utils/api.jsx";

const getReviewCheckout = code => {
  let url = api.get_review_checkout(code);
  return api.getFunction(url);
};

const getReviewCheckoutRecall = basket_number => {
  let url = api.get_review_checkout_recall(basket_number);
  return api.getFunction(url);
};

const getOrders = () => {
  let url = api.get_orders();
  return api.getFunction(url);
};

const getOrderDetail = url => {
  return api.getFunction(url);
};

const postAddToCart = data => {
  let url = api.post_add_to_cart();
  return api.postFunction(url, data);
};

const postCheckout = data => {
  let url = api.checkout();
  return api.postFunction(url, data);
};

const postPayment = url => {
  return api.postFunction(url);
};

const updateCart = data => {
  return api.patchFunction(data.url, data);
};

const deleteItem = url => {
  return api.deleteFunction(url);
};

const getCardFees = payment_code => {
  let url = api.get_card_fees(payment_code);
  return api.getFunction(url);
};

const postVocher = data => {
  return api.postFunction(api.addVoucher(), data);
};

const deleteVoucher = data => {
  return api.postFunction(api.removeVoucher(), data);
};

export {
  getReviewCheckout,
  postAddToCart,
  updateCart,
  deleteItem,
  getOrders,
  postCheckout,
  postPayment,
  getOrderDetail,
  getReviewCheckoutRecall,
  getCardFees,
  postVocher,
  deleteVoucher
};
