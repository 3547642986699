import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import VendorActionTypes from "constants/vendorProductConstants.jsx";

const initialState = {
  vendor_error: null,
  vendor_fetching: null,
  partner_product_list: null,
  partner_product_delivery_list: null,
  partner_product_detail: null,
  partner_product_delivery_detail: null,

  product_list_prev: null,
  product_list_next: null,
  partner_product_bid_list: null,
  bid_detail: null,
  bid_list_prev: null,
  bid_list_next: null,
  process_bid: null,
  productDetailShowVariable: false,
  pickup_action: null,
  partner_address: null,
  replacement_product: null,
  delete_partner_product: null,
  showLoader: false,
  currentFetch: null,
  counter_offers: null,
  counter_offer_detail: null,
  list_action_req: false,
  auction_notification_count: null,
  selected_new_delivery_status: null,
};

const vendorProductReducer = (state = initialState, action) => {
  switch (action.type) {
    // PARTNER PRODUCT LIST
    case VendorActionTypes.GET_PARTNER_PRODUCT_LIST_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };
    
    case VendorActionTypes.UPDATE_SELECTED_NEW_DELIVERY_STATUS:
      return { ...state, selected_new_delivery_status: action.data };

    case VendorActionTypes.GET_PARTNER_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        partner_product_list: action.data
      };

    case VendorActionTypes.GET_PARTNER_PRODUCT_LIST_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    case VendorActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };

    case VendorActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        partner_product_delivery_list: action.data
      };

    case VendorActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    case VendorActionTypes.PROD_LIST_ACTION_REQ:
      return {
        ...state,
        list_action_req: action.list_action_req
      };
    // PARTNER PRODUCT DETAIL
    case VendorActionTypes.GET_PARTNER_PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        vendor_error: null,
        vendor_fetching: true,
        partner_product_detail: null
      };

    case VendorActionTypes.GET_PARTNER_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        partner_product_detail: action.data
      };

    case VendorActionTypes.GET_PARTNER_PRODUCT_DELIVERY_DETAIL_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        partner_product_delivery_detail: action.data
      };

    case VendorActionTypes.GET_PARTNER_PRODUCT_DETAIL_FAILURE:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: action.error,
        partner_product_detail: null
      };

    case VendorActionTypes.CREATE_COUNTER_OFFER_REQUEST:
      return {
        ...state,
        vendor_error: null,
        vendor_fetching: true
      };

    case VendorActionTypes.CREATE_COUNTER_OFFER_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
      };

    case VendorActionTypes.CREATE_COUNTER_OFFER_FAILURE:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: action.error
      };

    // PARTNER PRODUCT BID LIST
    case VendorActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };

    case VendorActionTypes.GET_PARTNER_PRODUCT_BID_LIST_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        partner_product_bid_list: action.data,
        bid_list_prev: action.prev,
        bid_list_next: action.next
      };

    case VendorActionTypes.GET_PARTNER_PRODUCT_BID_LIST_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // BID DETAIL
    // PARTNER PRODUCT DETAIL
    case VendorActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_REQUEST:
      return {
        ...state,
        vendor_error: null,
        vendor_fetching: true,
        bid_detail: null
      };

    case VendorActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        bid_detail: action.data
      };

    case VendorActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_FAILURE:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: action.error,
        bid_detail: null
      };

    // PROCESS BID
    case VendorActionTypes.PROCESS_BID_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };

    case VendorActionTypes.PROCESS_BID_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        process_bid: action.data
      };

    case VendorActionTypes.PROCESS_BID_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // PICKUP ACTION
    case VendorActionTypes.POST_PICKUP_ACTION_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };

    case VendorActionTypes.POST_PICKUP_ACTION_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        pickup_action: action.data
      };

    case VendorActionTypes.POST_PICKUP_ACTION_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // GET REPLACEMENT PRODUCT
    case VendorActionTypes.GET_REPLACEMENT_PRODUCT_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };

    case VendorActionTypes.GET_REPLACEMENT_PRODUCT_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        replacement_product: action.data
      };

    case VendorActionTypes.GET_REPLACEMENT_PRODUCT_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // GET_PARTNER_ADDRESS
    case VendorActionTypes.GET_PARTNER_ADDRESS_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };

    case VendorActionTypes.GET_PARTNER_ADDRESS_SUCCESS:
      return {
        ...state,
        vendor_fetching: action.fetching ? action.fetching : false,
        vendor_error: null,
        partner_address: action.data
      };

    case VendorActionTypes.GET_PARTNER_ADDRESS_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // POST_PARTNER_ADDRESS
    case VendorActionTypes.POST_PARTNER_ADDRESS_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };

    case VendorActionTypes.POST_PARTNER_ADDRESS_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        partner_address: action.data
      };

    case VendorActionTypes.POST_PARTNER_ADDRESS_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // DELETE PARTNER PRODUCT
    case VendorActionTypes.DELETE_PARTNER_PRODUCT_REQUEST:
      return { ...state, vendor_error: null, vendor_fetching: true };

    case VendorActionTypes.DELETE_PARTNER_PRODUCT_SUCCESS:
      return {
        ...state,
        //vendor_fetching: false,
        vendor_error: null,
        delete_partner_product: action.data
      };

    case VendorActionTypes.DELETE_PARTNER_PRODUCT_FAILURE:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // case VendorActionTypes.SET_LOADER:
    // return { ... state}
    //RESET
    case VendorActionTypes.UPDATE_PRODUCT_DETAIL_VARIABLE:
      return { ...state, productDetailShowVariable: action.payload };

    //SHOW_LOADER
    case VendorActionTypes.SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload,
        currentFetch: action.currentFetch
      };

    // Get counter offer list
    case VendorActionTypes.GET_COUNTER_OFFER_LIST_REQUEST:
      return { ...state, vendor_fetching: true, vendor_error: null };
    case VendorActionTypes.GET_COUNTER_OFFER_LIST_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        counter_offers: action.data
      };
    case VendorActionTypes.GET_COUNTER_OFFER_LIST_FAILED:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // Get counter offer list
    case VendorActionTypes.GET_COUNTER_OFFER_DETAIL_REQUEST:
      return { ...state, vendor_fetching: true, vendor_error: null };
    case VendorActionTypes.GET_COUNTER_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        counter_offer_detail: action.data
      };
    case VendorActionTypes.GET_COUNTER_OFFER_DETAIL_FAILED:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // Get patch counter offer
    case VendorActionTypes.PATCH_COUNTER_OFFER_DETAIL_REQUEST:
      return { ...state, vendor_fetching: true, vendor_error: null };
    case VendorActionTypes.PATCH_COUNTER_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        counter_offer_detail: action.data
      };
    case VendorActionTypes.PATCH_COUNTER_OFFER_DETAIL_FAILED:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // create new counter offer
    case VendorActionTypes.CREATE_NEW_COUNTER_OFFER_REQUEST:
      return { ...state, vendor_fetching: true, vendor_error: null };
    case VendorActionTypes.CREATE_NEW_COUNTER_OFFER_SUCCESS:
      return {
        ...state,
        vendor_fetching: false,
        vendor_error: null,
        counter_offer_detail: action.data
      };
    case VendorActionTypes.CREATE_NEW_COUNTER_OFFER_FAILED:
      return { ...state, vendor_fetching: false, vendor_error: action.error };

    // get auction notification count under profile modal
    case VendorActionTypes.GET_ACTION_COUNT_REQUEST:
      return { ...state,  vendor_error: null };
    case VendorActionTypes.GET_ACTION_COUNT_SUCCESS:
      return {
        ...state,
        vendor_error: null,
        auction_notification_count: action.data
      };
    case VendorActionTypes.GET_ACTION_COUNT_FAILED:
      return { ...state, vendor_error: action.error };

    //RESET
    case VendorActionTypes.VENDOR_PRODUCT_RESET:
      return initialState;

    default:
      return state;
  }
};

const persistConfig = {
  key: "vendor",
  storage: storage,
  blacklist: [
    "partner_product_detail",
    "bid_detail",
    // "account_token",
    // "user_profile"
  ]
};

export default persistReducer(persistConfig, vendorProductReducer);
