import React from "react";
import classNames from "classnames";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import { withTranslation } from "react-i18next";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import Muted from "components/Typography/Muted.jsx";
import Link from "@material-ui/core/Link";
import Loader from "kayfik/components/Loader/loader.jsx";
// @material-ui/core components
import compose from "recompose/compose";
import { connect } from "react-redux";
import ModalActionTypes from "constants/modalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import Hidden from "@material-ui/core/Hidden";
import AccountActionTypes from "constants/accountConstants.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";



class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bidNow: false,
      buyNow: true
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  openModal = (modal_type, nextModal, completeProfFlow) => async () => {
    const { showModal } = this.props;
    let mProps = {
      open: true,
      title: modal_type,
      nextModal: "profileModal"
    };
    if (modal_type === "verifyEmailModal") {
      mProps["verifyFrom"] = "profileModal";
    }
    if (nextModal) {
      mProps["nextModal"] = nextModal;
    }
    if (completeProfFlow) {
      mProps["completeProfFlow"] = true;
    }
    showModal(mProps, modal_type);
  };

  componentDidMount() {
    const {
      getAddressList,
      getAreaList,
      getUserDetails,
      account_token,
      update_user_location,
      updateAddressEvent,
      getPaymentInfo
    } = this.props;
    if (account_token) {
      getUserDetails();
      getAddressList();
      getPaymentInfo();
      getAreaList();
      if (account_token) {
        updateAddressEvent("loggedin_shipping");
      }

      if (navigator.geolocation) {
        this.props.update_map_loader(true);
        navigator.geolocation.getCurrentPosition(
          position => {
            this.setState(
              {
                currentLat: position.coords.latitude,
                currentLong: position.coords.longitude,
                error: null
              },
              () => {
                const location = {
                  currentLat: this.state.currentLat,
                  currentLong: this.state.currentLong
                };
                update_user_location(location);
                this.props.update_map_loader(false);
              }
            );
          },
          error => {
            this.setState({ error: error.message });
            this.props.update_map_loader(false);
          },
          { enableHighAccuracy: true, timeout: 200000 }
        );
      }
    }
  }

  renderProfileCard = () => {
    const { userDetails, t } = this.props;

    if (userDetails) {
      if (userDetails.phone) {
        var split_phone =
          userDetails.phone.substr(0, 4) +
          " " +
          userDetails.phone.substr(4, 12);
      }

      return (
        <Card bordered>
          <CardBody>
            <div className="d-flex justify-content-between">
              <h5 className="m-0">
                {t("profileModal.accountSettings.general")}
              </h5>
              <Link
                underline="none"
                component="button"
                className="color-pl"
                onClick={this.openModal("editAccountDetailsModal")}
              >
                <h5 className="m-0">
                  {t("profileModal.accountSettings.edit")}
                </h5>
              </Link>
            </div>
            {userDetails !== null ? (
              <div>
                <h5 className="mb-1">
                  <Muted>
                    {userDetails.first_name} {userDetails.last_name}
                  </Muted>
                </h5>
                <div className="d-flex jcsb">
                  <h5 className="mt-0 mb-1 ltr-view">
                    <Muted>{split_phone}</Muted>
                  </h5>
                  {!userDetails.is_phone_verified && (
                    <Link
                      underline="none"
                      component="button"
                      className="color-pl"
                      onClick={this.openModal("verifyPhoneModal")}
                    >
                      <h5 className="m-0">
                        {t("profileModal.accountSettings.verify_phone")}
                      </h5>
                    </Link>
                  )}
                </div>
                <div className="d-flex jcsb">
                  <h5 className="mt-0 mb-1">
                    <Muted>{userDetails.email}</Muted>
                  </h5>
                  {!userDetails.is_email_verified && (
                    <Link
                      underline="none"
                      component="button"
                      className="color-pl"
                      onClick={this.openModal("verifyEmailModal")}
                    >
                      <h5 className="m-0">
                        {t("profileModal.accountSettings.verify_email")}
                      </h5>
                    </Link>
                  )}
                </div>
              </div>
            ) : (
                <p>{t("profileModal.accountSettings.setup")}</p>
              )}
          </CardBody>
        </Card>
      );
    } else {
      return null;
    }
  };

  handleAddressChange = () => {
    const { address_list } = this.props;

    // if (account_token) {
    //   updateAddressEvent("loggedin_shipping");
    // }

    if (address_list.length > 0) {
      this.openModal("confirmAddress")();
    } else {
      this.openModal("mapModal")();
    }
  };

  getAreaName = id => {
    const { area_list } = this.props;
    if (area_list && area_list.length > 0) {
      let add = area_list.find(item => item.code === id);
      if (add) {
        return add.name;
      }
      return null;
    }
  };
  renderShippingAddressCard = () => {
    const { address_list, t } = this.props;
    let address = address_list.length > 0 ? address_list[0] : null;
    let fullAddress = null;
    if (address) {
      fullAddress = address.address;
    }

    return (
      <Card bordered>
        <CardBody>
          <div className="d-flex justify-content-between">
            <h5 className="m-0">{t("profileModal.accountSettings.address")}</h5>
            <Link
              underline="none"
              component="button"
              className="color-pl"
              onClick={this.handleAddressChange}
            >
              <h5 className="m-0">{t("profileModal.accountSettings.edit")}</h5>
            </Link>
          </div>
          {address ? (
            <div>
              {address.first_name ? (
                <h5 className="mb-1">
                  <Muted>
                    {t("common.firstname")} {address.first_name}
                  </Muted>
                </h5>
              ) : null}
              {address.last_name ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.lastname")} {address.last_name}
                  </Muted>
                </h5>
              ) : null}

              {fullAddress.area ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.area")} {this.getAreaName(fullAddress.area_id)}
                  </Muted>
                </h5>
              ) : null}

              {fullAddress.block ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.block")} {fullAddress.block}
                  </Muted>
                </h5>
              ) : null}
              {fullAddress.avenue ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.avenue")} {fullAddress.avenue}
                  </Muted>
                </h5>
              ) : null}
              {fullAddress.street ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.street")} {fullAddress.street}
                  </Muted>
                </h5>
              ) : null}
              {fullAddress.building ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.house")} {fullAddress.building}
                  </Muted>
                </h5>
              ) : null}
              {fullAddress.floor ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.floor")} {fullAddress.floor}
                  </Muted>
                </h5>
              ) : null}
              {fullAddress.unit ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.apartment")} {fullAddress.unit}
                  </Muted>
                </h5>
              ) : null}
              {address.notes ? (
                <h5 className="mt-0 mb-1">
                  <Muted>
                    {t("common.additional")} {address.notes}
                  </Muted>
                </h5>
              ) : null}
            </div>
          ) : (
              <p>{t("profileModal.accountSettings.noaddress")}</p>
            )}
        </CardBody>
      </Card>
    );
  };

  renderChangePasswordCard = () => {
    const { t } = this.props;
    return (
      <Card bordered>
        <CardBody>
          <div className="d-flex justify-content-between">
            <h5 className="m-0">
              {t("profileModal.accountSettings.password")}
            </h5>
            <Link
              underline="none"
              component="button"
              className="color-pl"
              onClick={this.openModal("changePassword")}
            >
              <h5 className="m-0">
                {t("profileModal.accountSettings.change")}
              </h5>
            </Link>
          </div>
        </CardBody>
      </Card>
    );
  };

  getDate = birthdate => {
    // let mydate = new Date();
    let newDate = new Date(birthdate);
    let dd = String(newDate.getDate()).padStart(2, "0");
    let mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = newDate.getFullYear();
    let formated_birthdate = dd + "/" + mm + "/" + yyyy;
    return formated_birthdate;
  };

  renderPaymentDetails = () => {
    const { t, payment_info, } = this.props;
    return (
      <Card bordered>
        <CardBody>
          <div className="d-flex justify-content-between">
            <h5 className="m-0">
              {t("profileModal.accountSettings.getIban.title")}
            </h5>
            <Link
              underline="none"
              component="button"
              className="color-pl"
              onClick={this.openModal("getIbanModal")}
            >
              <h5 className="m-0">{t("profileModal.accountSettings.edit")}</h5>
            </Link>
          </div>
          {payment_info.length > 0  && payment_info[0].iban? (
            <div>
              <h5 className="mb-1">
                <Muted>
                  {t("profileModal.accountSettings.getIban.iban")} :{" "}
                  {payment_info[0].iban}
                </Muted>
              </h5>
              {/* <div className="d-flex jcsb">
                <h5 className="mt-0 mb-1">
                  {userDetails.birth_date ? (
                    <Muted>
                      {t("profileModal.accountSettings.generalUser.birthdate")}{" "}
                      : {userDetails.birth_date}
                    </Muted>
                  ) : null}
                </h5>
              </div> */}
            </div>
          ) : null}
        </CardBody>
      </Card>
    );
  };
  renderCivilIdUpload = () => {
    const { t } = this.props;
    return (
      <Card bordered>
        <CardBody>
          <div className="d-flex justify-content-between">
            <h5 className="m-0">{t("profileModal.civilID.uploadCivilId")}</h5>
            <Link
              underline="none"
              component="button"
              className="color-pl"
              onClick={this.openModal("civilIdModal")}
            >
              <h5 className="m-0">{t("profileModal.civilID.upload")}</h5>
            </Link>
          </div>
        </CardBody>
      </Card>
    );
  };

  completeProfileFlow = () => {
    const { userDetails, payment_info } = this.props;
    if (!userDetails.civil_id_back && !userDetails.civil_id_front) {
      this.openModal("civilIdModal")();
    } else if (
      payment_info.length === 0 ||
      (payment_info.length > 0 && !payment_info[0].iban)
    ) {
      this.openModal("getIbanModal")();
    }
    // if (modalsToOpen.length > 0) {
    //   this.openModal(modalsToOpen[0], modalsToOpen[1], true)();
    // }
  };

  renderCompleteProfButton = () => {
    const { userDetails, t } = this.props;
    if (userDetails) {
      return (
        <Card className="mx-1">
          <Button
            color="primaryWhiteWithBorder"
            size="lg"
            className="mb-3"
            fullWidth
            onClick={this.completeProfileFlow}
          >
            {t("profileModal.accountSettings.completeProfile")}
          </Button>
        </Card>
      );
    }
  };

  render() {
    const { t, account_fetching, address_fetching,auction_notification_count } = this.props;
    
   // Count for payment detail
   const payment_items_count =
   auction_notification_count && auction_notification_count.payment_items
     ? auction_notification_count.payment_items
     : null;

    if (account_fetching || address_fetching === true) {
      return (
        <Hidden smDown implementation="css">
          <Loader noHeight={true} />
        </Hidden>
      );
    }
    return (
      <div className="main-background-color product_listing">
        <h4 className={classNames("profile_section")}>
          {t("profileModal.accountSettings.title")}
        </h4>
        {payment_items_count && payment_items_count.count > 0  ? this.renderCompleteProfButton() : null}
        {this.renderProfileCard()}
        {this.renderShippingAddressCard()}
        {this.renderChangePasswordCard()}
        {this.renderPaymentDetails()}
        { this.renderCivilIdUpload()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  showSnackbar: txt => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "warning",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  getAddressList: () => {
    dispatch({
      type: AddressActionTypes.GET_ADDRESS_REQUEST
    });
  },
  updateAddressEvent: event => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT,
      event
    });
  },
  getAreaList: () => {
    dispatch({
      type: AddressActionTypes.GET_AREA_LIST_REQUEST
    });
  },
  getUserDetails: () => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_REQUEST
    });
  },
  update_user_location: location => {
    dispatch({
      type: AddressActionTypes.USER_CURRENT_LOCATION,
      payload: location
    });
  },
  update_map_loader: mapLoader => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_LOADER,
      mapLoader
    });
  },
  getPaymentInfo: () => {
    dispatch({
      type: AccountActionTypes.GET_PAYMENT_INFO_REQUEST
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.addressReducer,
  ...state.vendorProductReducer,
  ...state.cartReducer,
  ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(AccountSettings);
