import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { withTranslation } from "react-i18next";
// @material-ui/core components
import compose from "recompose/compose";
// redux
import { connect } from "react-redux";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import withStyles from "@material-ui/core/styles/withStyles";
import CameraAlt from "@material-ui/icons/CameraAlt";
import InputAdornment from "@material-ui/core/InputAdornment";
import ModalActionTypes from "constants/modalConstants.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// custom core components
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
// Style
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import withWidth from "@material-ui/core/withWidth";
import { enqueueSnackbar } from "actions/notifierActions.jsx";

// api
import api from "utils/api.jsx";

class CompanyDetails2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  openModal = modal_type => async () => {
    const { showModal, modalProps, closeModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  updateCompanyData = event => {
    event.preventDefault();
    const { updateData, company_signup_data } = this.props;
    let new_data = {
      ...company_signup_data,
      signatory_first_name: event.target.auth_sign_first.value,
      signatory_last_name: event.target.auth_sign_last.value
    };


    if (event.target.artical && event.target.artical.files.length > 0) {
      new_data["article_of_association"] = event.target.artical.files[0];
    }

    if (
      event.target.association_file &&
      event.target.association_file.files.length > 0
    ) {
      new_data["authorized_signatory"] = event.target.association_file.files[0];
    }
    if (
      event.target.civil_id_front &&
      event.target.civil_id_front.files.length > 0
    ) {
      new_data["civil_id_front"] = event.target.civil_id_front.files[0];
    }
    if (
      event.target.civil_id_back &&
      event.target.civil_id_back.files.length > 0
    ) {
      new_data["civil_id_back"] = event.target.civil_id_back.files[0];
    }
    if (
      event.target.license_file &&
      event.target.license_file.files.length > 0
    ) {
      new_data["commercial_license"] = event.target.license_file.files[0];
    }

    updateData(new_data);
    // NOTE: its a matter of race condition at the same time previously we are creating the key name differently than
    // what the backend expected
    this.signupSubmit(new_data);
  };

  handleBack = () => {
    const { companyForceStep, update_company_force_step, t } = this.props;
    if (window.confirm(t("sellingPage.imageModal.lossingImage"))) {
      update_company_force_step(companyForceStep - 1);
    }
  };

  signupSubmit = company_signup_data => {
    const { postData, deviceid } = this.props;
    let formData = new FormData();
    if (deviceid) {
      let url = api.company_signup() + "?deviceid=" + deviceid;
      company_signup_data.url = url;
    }
    for (let key in company_signup_data) {
      formData.append(key, company_signup_data[key]);
    }
    postData(formData);
  };
  
  renderBack = () => {
    const { t, direction } = this.props;
    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="company_icons" />
          {t("companySignup.CompanyDetailTWo.back")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="company_icons" />
          {t("companySignup.CompanyDetailTWo.back")}
        </div>
      );
    }
  };

  renderBackMobile = () => {
    const { t, direction } = this.props;
    if (direction === "rtl") {
      return (
        <div className="contact_us">
          <ChevronRight className="company_icons" />
          <span> {t("companySignup.companyDetailOne.back")}</span>
        </div>
      );
    } else {
      return (
        <div className="contact_us">
          <ChevronLeft className="company_icons " />
          <span>{t("companySignup.companyDetailOne.back")}</span>
        </div>
      );
    }
  };

  openModal = modal_type => async () => {
    const { showModal, modalProps, closeModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  handleTextChange = e => {
    const { updateData, company_signup_data } = this.props;
    let new_data = { ...company_signup_data };
    if (e.target.name === "auth_sign_first") {
      new_data["auth_sign"] = e.target.value;
    } else if (e.target.name === "auth_sign_last") {
      new_data["auth_sign_last"] = e.target.value;
    }
    updateData(new_data);
  };

  render() {
    const { company_signup_data, direction, t } = this.props;
    return (
      <div className="main-background-color marginAuto pt-3">
        <GridItem xs={12} sm={10} md={10} className="marginAuto mb-5">
          <h3
            className={classNames(
              direction === "rtl" ? "ta-right m-0" : "ta-left m-0"
            )}
          >
            {t("companySignup.CompanyDetailTWo.companyInformation")}
          </h3>
          <h5
            className={classNames(
              direction === "rtl" ? "ta-right m-0" : "ta-left m-0"
            )}
          >
            {t("companySignup.CompanyDetailTWo.share")}
          </h5>
        </GridItem>
        <form onSubmit={this.updateCompanyData} autoComplete="off">
          <GridContainer className="m-0 py-5 bg-grey">
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={
                direction === "rtl" ? "pr-0 marginAuto" : "pl-0 marginAuto"
              }
            >
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.CompanyDetailTWo.commercial")}
                      id="id_license_file"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "license_file",
                        required: true,
                        type: "file",
                        inputProps: {
                          accept:
                            "image/png,image/jpg,image/jpeg,application/pdf,application/doc,application/docx,application/xsl,application/xslx"
                        },
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <CameraAlt
                              className={classNames("colorYellow", "mr-3")}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className="dp-f align-items-center"
                >
                  <div className="mb-3">
                    <h5>
                      {t("companySignup.CompanyDetailTWo.commercialDetails")}
                    </h5>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.CompanyDetailTWo.articles")}
                      id="id_association_file"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "association_file",
                        required: true,
                        type: "file",
                        disableUnderline: true,
                        inputProps: {
                          accept:
                            "image/png,image/jpg,image/jpeg,application/pdf,application/doc,application/docx,application/xsl,application/xslx"
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <CameraAlt
                              className={classNames("colorYellow", "mr-3")}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className="dp-f align-items-center"
                >
                  <div className="mb-3">
                    <h5>
                      {t("companySignup.CompanyDetailTWo.articlesDetails")}
                    </h5>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.CompanyDetailTWo.article")}
                      id="id_artical_association_file"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "artical",
                        required: true,
                        type: "file",
                        disableUnderline: true,
                        inputProps: {
                          accept:
                            "image/png,image/jpg,image/jpeg,application/pdf,application/doc,application/docx,application/xsl,application/xslx"
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <CameraAlt
                              className={classNames("colorYellow", "mr-3")}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className="dp-f align-items-center"
                >
                  <div className="mb-3">
                    <h5>
                      {t("companySignup.CompanyDetailTWo.articlesDetails")}
                    </h5>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t(
                        "companySignup.CompanyDetailTWo.authorizedFirst"
                      )}
                      id="id_auth_sign"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "auth_sign_first",
                        defaultValue: company_signup_data.auth_sign,
                        required: false,
                        type: "text",
                        onChange: this.handleTextChange,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t(
                        "companySignup.CompanyDetailTWo.authorizedLast"
                      )}
                      id="id_auth_sign"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "auth_sign_last",
                        defaultValue: company_signup_data.auth_sign_last,
                        required: false,
                        type: "text",
                        onChange: this.handleTextChange,
                        disableUnderline: true
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className="dp-f align-items-center"
                >
                  <h5>
                    {t("companySignup.CompanyDetailTWo.authorizedDetails")}
                  </h5>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.CompanyDetailTWo.civiid_front")}
                      id="id_civil_id_front"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "civil_id_front",
                        required: true,
                        type: "file",
                        disableUnderline: true,
                        inputProps: {
                          accept:
                            "image/png,image/jpg,image/jpeg,application/pdf,application/doc,application/docx,application/xsl,application/xslx"
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <CameraAlt
                              className={classNames("colorYellow", "mr-3")}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <div className="mb-3">
                    <CustomInput
                      labelText={t("companySignup.CompanyDetailTWo.civiid_back")}
                      id="id_civil_id_back"
                      size="lg"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "civil_id_back",
                        required: true,
                        type: "file",
                        disableUnderline: true,
                        inputProps: {
                          accept:
                            "image/png,image/jpg,image/jpeg,application/pdf,application/doc,application/docx,application/xsl,application/xslx"
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <CameraAlt
                              className={classNames("colorYellow", "mr-3")}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  className="dp-f align-items-center"
                >
                  <div className="mb-3">
                    <h5>{t("companySignup.CompanyDetailTWo.civiidDetails")}</h5>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <div className="display-sm-up">
            <GridContainer className="py-5">
              <GridItem
                xs={12}
                sm={10}
                md={10}
                className={
                  direction === "rtl"
                    ? "marginAuto dp-f jcsb p-0 pr-0"
                    : "marginAuto dp-f jcsb"
                }
              >
                <Button
                  link
                  size="lg"
                  className={direction === "rtl" ? "pr-0 mb-3" : "pl-0 mb-3"}
                  onClick={this.handleBack}
                >
                  {this.renderBack()}
                </Button>
                <div
                  className={direction === "rtl" ? "pl-4 dp-f" : "pr-4 dp-f"}
                >
                  <h5 className="ta-right">
                    {t("companySignup.companyDetailOne.finishregister")}
                    <br />
                    <span
                      className="contact_us dp-f jcfe"
                      onClick={this.openModal("contactModal")}
                    >
                      {t("companySignup.companyDetailOne.contactUs")}
                    </span>
                  </h5>
                  <Button
                    size="lg"
                    type="submit"
                    color="primaryWhiteWithBorder"
                    className={direction === "rtl" ? "mr-4" : "ml-4"}
                  >
                    {t("companySignup.companyDetailOne.saveAndContinue")}
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <div className="display-sm-down">
            <GridContainer className="py-5">
              <GridItem xs={12} sm={10} md={10} className="marginAuto">
                <GridContainer className="dp-f jcc">
                  <div onClick={this.handleBack}>{this.renderBackMobile()}</div>
                </GridContainer>
                <GridContainer className="dp-f jcc">
                  <h5 className="text-center">
                    {t("companySignup.companyDetailOne.finishregister")}
                    <br />
                    <span
                      className="contact_us"
                      onClick={this.openModal("contactModal")}
                    >
                      {t("companySignup.companyDetailOne.contactUs")}
                    </span>
                  </h5>
                </GridContainer>
                <GridContainer className="dp-f jcc">
                  <Button
                    size="lg"
                    type="submit"
                    color="primaryWhiteWithBorder"
                  >
                    {t("companySignup.companyDetailOne.saveAndContinue")}
                  </Button>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updateData: data => {
    dispatch({
      type: AccountActionTypes.UPDATE_COMPANY_SIGNUP_DATA,
      data
    });
  },
  postData: form_data => {
    dispatch({
      type: AccountActionTypes.COMPANY_SIGNUP_REQUEST,
      form_data
    });
  },
  update_company_force_step: number => {
    dispatch({
      type: AccountActionTypes.UPDATE_COMPANY_STEP,
      data: number
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(signupPageStyle),
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(CompanyDetails2);
