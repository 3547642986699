import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.jsx";

import { container } from "assets/jss/material-kit-pro-react.jsx";

const howItWorksSectionStyle = {
  ...teamStyle,
  container,
  landingContent:{
    fontSize:"22px !important",
  },
  mb0:{
    marginBottom:"0px !important",
  },
  mt0:{
    marginTop:"0px !important",
  },
  mb30:{
    marginBottom:"35px !important"
  },
  mp0:{
    margin:"0 !important",
    padding:"0 !important",
  },
  py35:{
    paddingTop:"35px !important",
    paddingBottom:"35px !important",
  },
  mAuto:{
    margin:"auto !important"
  }
};

export default howItWorksSectionStyle;
