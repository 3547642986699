import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
// core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
import CheckCircle from "@material-ui/icons/CheckCircle";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import IconButton from "@material-ui/core/IconButton";


class AccountTypeModal extends React.Component {
  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    let props = {
      open: true,
      title: modal_type
    };
    let next = modalProps.nextModal;
    let nextPage = modalProps.nextPage;
    let activeSell = modalProps.activeSell;
    let activeCheckout = modalProps.activeCheckout;


    if (next) {
      props["nextModal"] = next;
    }
    if (nextPage) {
      props["nextPage"] = nextPage;
    }
    if (activeSell) {
      props["activeSell"] = activeSell;
    }
    if (activeCheckout) {
      props["activeCheckout"] = activeCheckout;
    }
    showModal(props, modal_type);
  };

  comp_create = () => async () => {
    const { modalProps, updatetempModalProps, dynamicHistoryRoute, hideModal } = this.props;
    await updatetempModalProps(modalProps);
    dynamicHistoryRoute.dynamicHistoryRoute("/company-signup");
    hideModal();
    document.body.classList.remove("stop-scrolling");
  }
  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, t, direction } = this.props;
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5" justify="center">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {t("accountTypeModal.account")}
              </h2>
              <h4 className="m-0 mb-3">{t("accountTypeModal.select")}</h4>
              <div className="justify-center d-flex mb-3">
                <CheckCircle className="big-icon icon-primarylight" />
              </div>
              <Button
                color="primaryWhiteWithBorder"
                size="lg"
                fullWidth
                onClick={this.openModal("personalSignupModal")}
              >
                {t("accountTypeModal.personal")}
              </Button>
              <Button
                color="primaryWhiteWithBorder"
                size="lg"
                fullWidth
                onClick={this.comp_create()}
              >
                {t("accountTypeModal.company")}
              </Button>
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer className="mb-5 mt-4">
            <GridItem
              xs={12}
              sm={10}
              md={8}
              className={classNames(
                classes.marginAuto,
                "d-flex" //flex-sm-column
              )}
            >
              <h4 className="m-0">
                {t("intialLoginModal.already")}{" "}
                <span
                  className="color-pl pointer"
                  onClick={this.openModal("loginModal")}
                >
                  {" "}
                  {t("intialLoginModal.login")}{" "}
                </span>
              </h4>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updatetempModalProps: (tempModalProps) => {
    dispatch({
      type: ModalActionTypes.UPDATE_TEMP_MODAL_PROPS,
      tempModalProps
    });
  },
  hideModal: () => {
    dispatch({
      type: ModalActionTypes.HIDE_MODAL_SUCCESS
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(AccountTypeModal);
