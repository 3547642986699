import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// React i18next
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
// core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import api from "utils/api.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import IconButton from "@material-ui/core/IconButton";

class completeOrderModal extends React.Component {
  componentDidMount() {
    const { getOrderDetails } = this.props;
    var url_string = window.location.href;
    if (url_string) {
      var url = new URL(url_string);
      var tap_id = url.searchParams.get("tap_id");
      getOrderDetails(api.root + "tap/order/detail/?tap_id=" + tap_id);
    }
  }

  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type,
        modalLarge: modal_type === "profileModal" ? true : false
      },
      modal_type
    );
  };
  shopClicked = () => {
    const { closeModal } = this.props;
    this.props.modalProps.goToShop();
    closeModal();
  };
  checkoutClicked = () => {
    const { closeModal } = this.props;
    this.props.modalProps.goToCheckout();
    closeModal();
  };
  render() {
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      closeModal,
      order_details,
      cart_fetching,
      t,
      direction
    } = this.props;
    return (
      <div>
        {cart_fetching || order_details === null ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5 " justify="center">
            <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
              <h2
                className={classNames(classes.modalTitle, classes.primaryText)}
              >
                {order_details === false
                  ? t("buyingPage.checkOut.completeOrder.failed")
                  : null}
                {order_details && order_details.status === "pending"
                  ? t("buyingPage.checkOut.completeOrder.processing")
                  : null}
                {order_details && order_details.status === "failed"
                  ? t("buyingPage.checkOut.completeOrder.failed")
                  : null}
                {order_details && order_details.status === "success"
                  ? t("buyingPage.checkOut.completeOrder.placed")
                  : null}
              </h2>
              {order_details && order_details.status === "success" ? (
                <h4 className="m-0 mb-3">
                  {t("buyingPage.checkOut.completeOrder.email")}
                </h4>
              ) : null}
              <div className="py-3" />
              <GridContainer justify="center">
                <GridItem xs={10}>
                  <Button
                    color="primaryWhiteWithBorder"
                    size="lg"
                    fullWidth
                    onClick={this.shopClicked}
                  >
                    {t("buyingPage.checkOut.completeOrder.browse")}
                  </Button>
                </GridItem>
              </GridContainer>
              {order_details && order_details.status === "failed" ? (
                <GridContainer justify="center">
                  <GridItem xs={10}>
                    <Button
                      color="primaryWhiteWithBorder"
                      size="lg"
                      fullWidth
                      onClick={this.checkoutClicked}
                    >
                      {t("buyingPage.checkOut.completeOrder.checkout")}
                    </Button>
                  </GridItem>
                </GridContainer>
              ) : null }
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getOrderDetails: url => {
    dispatch({
      type: CartActionTypes.GET_ORDER_DETAILS_REQUEST,
      payload: url
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.cartReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(completeOrderModal);
