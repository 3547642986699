import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// custom core component
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CameraAlt from "@material-ui/icons/CameraAlt";
// api
import { postCivilID } from "actions/accountActions.jsx";

class CivilIdModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false
    };
    this.props.getPaymentInfo();
  }

  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal,  } = this.props;

    if (modalProps.open) {
      await closeModal();
    }
    const props = {
      open: true,
      title: modal_type,
    };
    if (modal_type !== "profileModal" && window.location.href.indexOf("civilIdModal")<0) {
      props["nextModal"] = "profileModal";
    }
    let activeSell = modalProps.activeSell;
    let activeCheckout = modalProps.activeCheckout;
    if (activeSell) {
      props["activeSell"] = activeSell;
    }
    if (activeCheckout) {
      props["activeCheckout"] = activeCheckout;
    }
    if (modalProps.completeProfFlow) {
      props["completeProfFlow"] = true;
    }
    showModal(props, modal_type);
  };

  successCivilIdUpload = () => {
    const { closeModal , payment_info } = this.props;
    var modalT = "profileModal";
    if (
      payment_info.length === 0 ||
      (payment_info.length > 0 && !payment_info[0].iban)
    ) {
      modalT = "getIbanModal";
    }
    if (
      window.location.href.indexOf("civilIdModal") > -1 &&
      modalT === "profileModal"
    ) {
      closeModal();
    } else {
      this.openModal(modalT)();
    }
  };

  civilIDSubmit = (event) => {
    const { showSnackbar, t, userDetailsSuccess } = this.props;

    this.setState({ fetching: true });
    event.preventDefault();
    let form_data = {
      civil_id_front: event.target.front_img.files[0],
      civil_id_back: event.target.back_img.files[0]
    };
    let formData = new FormData();
    for (let key in form_data) {
      formData.append(key, form_data[key]);
    }
    postCivilID(formData)
      .then(res => {
        let data = res.data;
        userDetailsSuccess(data);
        this.successCivilIdUpload();
        showSnackbar(t("profileModal.civilID.success"), "success");
        this.setState({ fetching: false });
      })
      .catch(e => {
        this.setState({ fetching: false });
        if (e.response && e.response.status >= 400 && e.response.status < 500) {
          const res_object = e.response.data;
          let messages = [];
          for (let i in res_object) {
            let error_val = res_object[i];
            if (error_val) {
              messages.push(error_val);
            }
          }
          for (let i = 0; i < messages.length; i++) {
            let txt = messages[i];
            showSnackbar(txt);
          }
        } else {
          let txt = t("common.error.network");
          showSnackbar(txt);
        }
      });
  };

  render() {
    const { classes, closeModal, t, direction } = this.props;
    return (
      <div>
        {this.state.fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classNames(classes.modalBody, classes.modalBodyCivilID)}
        >
          <GridContainer className="pb-5 civilIdForm">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {t("profileModal.civilID.title")}
              </h2>
              <h4 className="m-0 mb-3">
                {" "}
                {t("profileModal.civilID.description")}
              </h4>
              <form onSubmit={this.civilIDSubmit} autoComplete="off">
                <CustomInput
                  labelText={t("profileModal.civilID.frontImg")}
                  id="front_img"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "front_img",
                    type: "file",
                    required: true,
                    inputProps: {
                      accept: "image/png,image/jpg,image/jpeg"
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CameraAlt
                          className={classNames("colorYellow", "mr-3")}
                        />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText={t("profileModal.civilID.backImg")}
                  id="back_img"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "back_img",
                    type: "file",
                    required: true,
                    inputProps: {
                      accept: "image/png,image/jpg,image/jpeg"
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CameraAlt
                          className={classNames("colorYellow", "mr-3")}
                        />
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  className="mb-3"
                  fullWidth
                  type="submit"
                >
                  {t("profileModal.civilID.submit")}
                </Button>
                <Button
                  link
                  size="lg"
                  className=""
                  fullWidth
                  onClick={closeModal}
                >
                  {t("profileModal.civilID.cancel")}
                </Button>
              </form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  loginRequest: form_data => {
    dispatch({
      type: AccountActionTypes.LOGIN_REQUEST,
      form_data
    });
  },
  socialLogin: (form_data, social_type = "facebook") => {
    dispatch({
      type: AccountActionTypes.SOCIAL_LOGIN_REQUEST,
      form_data,
      social_type
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  userDetailsSuccess: data => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_SUCCESS,
      data
    });
  },
  getPaymentInfo: () => {
    dispatch({
      type: AccountActionTypes.GET_PAYMENT_INFO_REQUEST
    });
  }
});

CivilIdModal.propTypes = {
  loginRequest: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(CivilIdModal);
