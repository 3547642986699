/* eslint-disable */
import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import videoPlayerStyle from "kayfik/assets/jss/components/VideoPlayer/videoPlayerStyle.jsx";

// react player
import ReactPlayer from "react-player";

class ResponsivePlayer extends React.Component {
  render() {
    const { classes, url } = this.props;
    return (
      <div className={classes.playerWrapper}>
        <ReactPlayer
          controls
          className={classes.reactPlayer}
          url={url ? url : "https://www.youtube.com/watch?v=ysz5S6PUM-U"}
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

export default withStyles(videoPlayerStyle)(ResponsivePlayer);
