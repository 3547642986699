import api from "utils/api.jsx";

const getPageData = code => {
  let url = api.get_cms_page();
  url = url + code + "/";
  return api.getFunction(url);
};

const getTimeWindow = (window) => {
  let url = api.time_window();
  url = url + "?type=" + window;
  return api.getFunction(url);
};

const contactSendMessage = data => {
  return api.postFunction(api.contact_send_message(), data);
};

const subscribeEmail = data => {
  return api.postFunction(api.subscribe_email(), data);
};

export { getPageData, contactSendMessage, subscribeEmail, getTimeWindow };
