import api from "utils/api.jsx";

const getReplacementProduct = url => {
  return api.getFunction(url);
};
const getPartnerAddress = () => {
  let url = api.partner_address();
  return api.getFunction(url);
};

const postPartnerAddress = data => {
  let url = api.partner_address();
  return api.patchFunction(url, data);
};

const getVendorProductList = () => {
  let url = api.vendor_product_list();
  return api.getFunction(url);
};

const getVendorProductDeliveryList = () => {
  let url = api.vendor_product_delivery();
  return api.getFunction(url);
};

const getVendorProductDetail = url => {
  return api.getFunction(url);
};

const getVendorProductBidList = () => {
  let url = api.vendor_product_bid_list();
  return api.getFunction(url);
};

const postProcessBid = data => {
  return api.postFunction(data.url, data);
};

const postPickupAction = url => {
  const data = {};
  return api.postFunction(url, data);
};

const deletePartnerProduct = url => {
  const data = {};
  return api.postFunction(url, data);
};

const createInitailCounterOffer = data => {
  let url = data.create_url;
  return api.postFunction(url, data);
};

const getCounterOfferList = () => {
  return api.getFunction(api.counter_offer_list());
};

const getCounterOfferDetail = url => {
  return api.getFunction(url);
};

const patchCounterOffer = data => {
  let url = data.url;
  return api.patchFunction(url, data);
};

// getting auction notification count on each tab
const getAuctionNotificationCount = () => {
  return api.getFunction(api.auction_notification_count());
};

// posting to clear the action tab based on url
const postAuctionCountUrl = url => {
  const data = {};
  return api.postFunction(url, data);
};

export {
  getVendorProductList,
  getVendorProductDeliveryList,
  getVendorProductBidList,
  postProcessBid,
  getVendorProductDetail,
  postPickupAction,
  getPartnerAddress,
  postPartnerAddress,
  getReplacementProduct,
  deletePartnerProduct,
  createInitailCounterOffer,
  getCounterOfferList,
  getCounterOfferDetail,
  patchCounterOffer,
  getAuctionNotificationCount,
  postAuctionCountUrl
};
