// UncontrolledLottie.jsx
import React, { Component } from "react";
import kayfikLoader from "kayfik/assets/images/kayfik_loader.gif";
import compose from "recompose/compose";
// redux
import { connect } from "react-redux";

class LoaderHorizontal extends Component {
  componentDidMount() {
    document.body.classList.add("stopBody-scrolling");
  }

  componentWillUnmount() {

    document.body.classList.remove("stopBody-scrolling");
  }

  render() {
    const { direction } = this.props;
    const loaderStyle = {
      position: "fixed",
      top: 0,
      bottom: 0,
      width: "75%",
      right: direction === "ltr" ? null : "25%",
      left: direction === "ltr" ? "25%" : null,
      backgroundColor: "#ffffff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 88
    };

    if (this.props.fixed) {
      loaderStyle["position"] = "fixed";
    }

    const imgStyle = {
      maxWidth: "80vw"
    };


    document.body.classList.add("stopBody-scrolling");
    return (
      <div style={loaderStyle}>
        <img src={kayfikLoader} style={imgStyle} alt="Kayfik" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.globalReducer
});

export default compose(connect(mapStateToProps))(LoaderHorizontal);
