import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import AccountCircle from "@material-ui/icons/AccountCircle";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Menu from "@material-ui/icons/Menu";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { withTranslation } from "react-i18next";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
// core components
import customHeaderStyle from "kayfik/assets/jss/components/Header/customHeaderStyle.jsx";
import kayfik from "kayfik/assets/images/KAYFIK_LOGO.jpg";
import kayfikArabic from "kayfik/assets/images/kayfik_logo_arabic.jpg";
import SearchBar from "kayfik/components/Header/SearchBar.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import HeaderLinks from "kayfik/components/Header/HeaderLinks.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import { editUserDetails } from "actions/accountActions.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import { Badge } from "@material-ui/core";


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      languageOpen: false
    };
  }


  handleDrawerToggle = () => {
    const { openMobileDropdownFunc, modalType, hideModal } = this.props;
    if (modalType !== null) {
      hideModal();
    }
    this.setState(
      { mobileOpen: !this.state.mobileOpen, languageOpen: false },
      () => {
        openMobileDropdownFunc(this.state.mobileOpen);
      }
    );
  };

  handleLanguageToggle = () => {
    const { openMobileDropdownFunc, modalType, hideModal } = this.props;
    if (modalType !== null) {
      hideModal();
    }
    this.setState(
      {
        languageOpen: !this.state.languageOpen,
        mobileOpen: false
      },
      () => {
        openMobileDropdownFunc(this.state.mobileOpen);
      }
    );
  };

  gotoHome = () => {
    const { hideModal } = this.props;
    hideModal();
    window.location.href = "/";
  };

  handleCaret = () => {
    if (this.state.languageOpen) {
      return "Connect-CustomDropdown--caret-1385 Connect-CustomDropdown--caretActive-1386";
    } else {
      return "Connect-CustomDropdown--caret-1385";
    }
  };

  componentDidMount() {
    document.addEventListener("scroll", this.ScrollStart, false);
    const { userDetails, language } = this.props;
    if (userDetails && userDetails.language !== language) {
      this.changeAppLangauge(userDetails.language);
    }

  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    let modal_props = { open: true, title: modal_type };
    if (modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  ScrollStart = () => {
    const { openMobileDropdownFunc } = this.props;
    if (isWidthDown("sm", this.props.width)) {
      this.setState({ languageOpen: false, mobileOpen: false }, () => {
        openMobileDropdownFunc(this.state.mobileOpen);
      });
    }
  };

  componentWillUnmount() {
    document.removeEventListener("scroll", this.ScrollStart, false);
  }

  closeAllDrawers = () => {
    const { openMobileDropdownFunc, modalType, hideModal } = this.props;
    if (modalType !== null) {
      hideModal();
    }
    this.setState(
      {
        languageOpen: false,
        mobileOpen: false
      },
      () => {
        openMobileDropdownFunc(this.state.mobileOpen);
        let searchBarElement = document.getElementById("searchBar");
        if(searchBarElement){
          searchBarElement.setAttribute("tabIndex", -1);
          searchBarElement.focus();
        }
      }
    );
  };

  saveLang = lang => {
    const {
      userDetails,
      editUserDetailsSuccess,
      editUserDetailsFailed,
    } = this.props;
    if (userDetails && userDetails.language !== lang) {
      let form_data = {
        language: lang
      };
      editUserDetails(form_data)
        .then(res => res.data)
        .then(data => {
          editUserDetailsSuccess(data);
        })
        .catch(error => {
          editUserDetailsFailed(error);
        });
    }
  };

  changeAppLangauge = lang => async () => {
    const { changeLang, changeDirection, i18n } = this.props;
    await changeLang(lang);

    i18n.changeLanguage(lang);
    if (lang === "ar") {
      changeDirection("rtl");
      document.body.lang = "ar";
      document.body.dir = "rtl";
    } else {
      changeDirection("ltr");
      document.body.lang = "en";
      document.body.dir = "ltr";
    }
    setTimeout(() => {
      window.location = window.location.href;
    }, 1000);
  };

  getActionCount = () => {
    const { auction_notification_count } = this.props;
    // getting listed auction count object
    const listed_count =
      auction_notification_count && auction_notification_count.listed_items
        ? auction_notification_count.listed_items
        : null;

    // getting received_offer auction count object
    const received_offer_count =
      auction_notification_count && auction_notification_count.receive_offer
        ? auction_notification_count.receive_offer
        : null;

    // getting counter_offer auction count object
    const counter_offer_count =
      auction_notification_count && auction_notification_count.counter_offer
        ? auction_notification_count.counter_offer
        : null;

    // Count for delivery items
    const deliverey_items_count =
      auction_notification_count && auction_notification_count.delivery_items
        ? auction_notification_count.delivery_items
        : null;
    
    // Count for payment detail
    const payment_items_count =
    auction_notification_count && auction_notification_count.payment_items
      ? auction_notification_count.payment_items
      : null;
    
    // Count for order
    const order_items_count =
    auction_notification_count && auction_notification_count.order_items
      ? auction_notification_count.order_items
      : null;

    let count = 0;
    
    if (listed_count) {
      count += listed_count.count;
    } 
    if (received_offer_count) {
      count += received_offer_count.count;
    } 
    if (counter_offer_count) {
      count += counter_offer_count.count;
    } 
    if (deliverey_items_count) {
      count += deliverey_items_count.count;
    } 
    if (payment_items_count) {
      count += payment_items_count.count;
    } 
    if (order_items_count) {
      count += order_items_count.count;
    } 
    return count;
  };

  render() {
    const {
      classes,
      absolute,
      direction,
      openMobileDropdown,
      account_token,
      t,
      history,
      language
    } = this.props;

    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes["white"]]: "white",
      [classes.absolute]: absolute,
      [classes.fixed]: "fixed"
    });

    return (
      <div id="main-header">
        <AppBar
          className={classNames(
            appBarClasses,
            classes.container + " mb-0 header-height",
            classes.zSM
          )}
          id={"header"}
        >
          <Toolbar className={classNames(classes.container, classes.p0 )}>
            <Link to="/" onClick={this.gotoHome} id="main-logo">
              <img
                src={direction === "rtl" ? kayfikArabic : kayfik}
                className={classes.kayfikLogo}
                alt="kayfik"
              />
            </Link>
            <div className="d-none-sm ">
              <Button
                color="transparent"
                size="lg"
                yellowText
                className={
                  direction === "rtl"
                    ? "buy_sell_divider_ar"
                    : "buy_sell_divider "
                }
                onClick={this.openModal("whatSellingModal")}
              >
                <span className="green hoverYellow d-none-sm fw-600">
                  {" "}
                  {t("homePage.header.sell")}{" "}
                </span>
              </Button>
            </div>
            <div className="d-none-sm ">
              <Button
                color="transparent"
                className={
                  direction === "rtl"
                    ? classNames(classes.signupbuttonAR)
                    : classNames(classes.signupbuttonEn)
                }
                size="lg"
                onClick={() => history.push("/shop")}
              >
                <span className="green hoverYellow d-none-sm fw-600">
                  {" "}
                  {t("homePage.header.buy")}{" "}
                </span>
              </Button>
              {/* </Link> */}
            </div>
            <div className={classNames(classes.hidden, "d-none-sm ")} id="headerLinks">
              <div className={classNames(classes.collapse, "jcfe ")}>
                <HeaderLinks dropdownHoverColor="htPrimaryLight" />
              </div>
            </div>
            <div className="d-none-smd">
              <IconButton
                classes={{
                  root: classes.bgTransparent
                }}
                color="inherit"
                //disableRipple
                aria-label="open drawer"
                className={
                  direction === "rtl"
                    ? "mobileButtons searchIconBase"
                    : "mobileButtons"
                }
                onClick={this.closeAllDrawers}
              >
                <SearchBar />
              </IconButton>

              {language === "en" ? (
                <IconButton
                  classes={{
                    root: classes.bgTransparent
                  }}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.changeAppLangauge("ar")}
                  className="mobileButtons arabic-text"
                >
                  <h4
                    className={classNames(
                      direction === "rtl"
                        ? classes.listItemAR
                        : classes.listItem,
                      "m-0 lineHt1"
                    )}
                  >
                    {t("homePage.header.mobileLinks.arabic")}
                  </h4>

                  <b className={this.handleCaret()} />
                </IconButton>
              ) : (
                <IconButton
                  classes={{
                    root: classes.bgTransparent
                  }}
                  color="inherit"
                  //disableRipple
                  aria-label="open drawer"
                  onClick={this.changeAppLangauge("en")}
                  className="mobileButtons"
                >
                  <h4
                    className={classNames(
                      direction === "rtl"
                        ? classes.listItemAR
                        : classes.listItem,
                      "m-0 lineHt1"
                    )}
                  >
                    {t("homePage.header.mobileLinks.english")}
                  </h4>

                  <b className={this.handleCaret()} />
                </IconButton>
              )}

              <IconButton
                classes={{
                  root: classes.bgTransparent
                }}
                color="inherit"
                //disableRipple
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
                className="mobileButtons"
              >
                {account_token ? (
                  <div>
                    <Badge
                      badgeContent={this.getActionCount()}
                      color="primary"
                      classes={{
                        badge: "bg-color-primaryyellow badgePositionCart"
                      }}
                    >
                      <AccountCircle
                        className={classNames(classes.fontColor)}
                      />
                    </Badge>
                  </div>
                ) : (
                  <Menu className={classNames(classes.fontColor)} />
                )}
              </IconButton>
            </div>
          </Toolbar>
          <div className="d-none-smd">
            <Drawer
              anchor={"top"}
              open={openMobileDropdown}
              BackdropProps={{
                classes: {
                  root: classes.backdrop
                }
              }}
              classes={{
                root: classes.zSMdropdown
              }}
              onClose={this.handleDrawerToggle}
              className="z-index-1"
            >
              <div
                className={classNames(
                  classes.appResponsive,
                  "mt-5rem appResponsiveMobile "
                )}
              >
                <IconButton
                  classes={{
                    root: classes.bgTransparent
                  }}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classNames(
                    direction === "rtl"
                      ? classes.closeButtonDrawerAR
                      : classes.closeButtonDrawer
                  )}
                >
                  <Close
                    classes={{
                      root: classNames(classes.fontColor)
                    }}
                  />
                </IconButton>
                <HeaderLinks dropdownHoverColor="htPrimaryLight" />
              </div>
            </Drawer>
          </div>
        </AppBar>
        <div className="header-height  " />
      </div>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

const mapDispatchToProps = dispatch => ({
  changeLang: lang => {
    dispatch({ type: GlobalActionTypes.CHANGE_LANGUAGE_REQUEST, lang });
  },
  changeDirection: dir => {
    dispatch({ type: GlobalActionTypes.CHANGE_DIRECTION_SUCCESS, dir });
  },
  openMobileDropdownFunc: value => {
    dispatch({
      type: GlobalActionTypes.UPDATE_MOBILE_DROPDOWN,
      value
    });
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  editUserDetailsSuccess: data => {
    dispatch({
      type: AccountActionTypes.EDIT_USER_DETAIL_SUCCESS,
      data
    });
  },
  editUserDetailsFailed: error => {
    dispatch({
      type: AccountActionTypes.EDIT_USER_DETAIL_FAILED,
      error
    });
  },
  hideModal: () => {
    dispatch({
      type: ModalActionTypes.HIDE_MODAL_SUCCESS
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer,
  ...state.productReducer,
  ...state.cartReducer,
  ...state.vendorProductReducer
});

export default compose(
  withStyles(customHeaderStyle),
  withWidth(),
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
