import { put } from "redux-saga/effects";

import ActionTypes from "constants/modalConstants.jsx";

import { store } from "utils/store.jsx";
import history from "history.js";


const noClearUrl = ["loginModal", "personalSignupModal"];


function* showModalWorker(action) {
  const { modalProps, modalType } = action;
  const language = store.getState().globalReducer.language;
  let padding = 15;
  document.body.classList.add("stop-scrolling");

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  let mainLogo = document.getElementById("main-logo");
  let headerLinks =  document.getElementById("headerLinks");
  if (isSafari && language === 'ar' && headerLinks){
    headerLinks.setAttribute("style", "margin-left:"+padding+"px !important;");
  } else {
    if(language === 'ar' && mainLogo){
      mainLogo.setAttribute("style", "margin-right:"+padding+"px !important;");
    }
    else if(headerLinks){
      headerLinks.setAttribute("style", "margin-right:"+padding+"px !important;");
    }
  }
  yield put({
    type: ActionTypes.SHOW_MODAL_SUCCESS,
    modalProps,
    modalType
  });
}

function* hideModalWorker() {
  const next_modal = store.getState().modalReducer.nextModal;
  const modalPropsNew = store.getState().modalReducer.modalProps;
  const modalType = store.getState().modalReducer.modalType;
  let headerLinks =  document.getElementById("headerLinks");
  if(headerLinks){
    headerLinks.setAttribute("style", "");
  }
  let mainLogo = document.getElementById("main-logo");
  if(mainLogo){
    mainLogo.setAttribute("style", "");
  }
  let bodyClasslist =  document.body.classList;
  if(bodyClasslist){
    bodyClasslist.remove("stop-scrolling");
  }
  yield put({
    type: ActionTypes.HIDE_MODAL_SUCCESS
  });
  let path = window.location.pathname;
  if (next_modal && next_modal.nextModalType) {
    const modalType = next_modal.nextModalType;
    const modalProps = next_modal.nextModalProps;
    yield put({
      type: ActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  } else if (
    modalPropsNew !== undefined &&
    modalPropsNew !== {} &&
    (modalPropsNew.nextModal || modalPropsNew.completeProfFlow)
  ) {
    let modalType = modalPropsNew.nextModal;
    let modalProps = { open: true, title: modalType, completeProfFlow:modalPropsNew.completeProfFlow };
    if (modalPropsNew.activeSell) {
      modalProps["activeSell"] = modalPropsNew.activeSell;
    }
    yield put({
      type: ActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  } else if (modalType && modalType.includes("listingPostedModal") && path && path.includes("company-signup")) {
    history.push("/");
  }
  let currentUrl = window.location.href;
  if (currentUrl.indexOf("?") > -1) {
    if (currentUrl.indexOf("search") === -1) {
      if (!noClearUrl.includes(modalType)) {
        window.history.pushState({}, document.title, "/");
      }
    }
  }

}

export { showModalWorker, hideModalWorker };
