import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  GET_PARTNER_PRODUCT_LIST_REQUEST: null,
  GET_PARTNER_PRODUCT_LIST_SUCCESS: null,
  GET_PARTNER_PRODUCT_LIST_FAILURE: null,

  GET_PARTNER_PRODUCT_DELIVERY_LIST_REQUEST: null,
  GET_PARTNER_PRODUCT_DELIVERY_LIST_SUCCESS: null,
  GET_PARTNER_PRODUCT_DELIVERY_LIST_FAILURE: null,

  PROD_LIST_ACTION_REQ: null,

  GET_PARTNER_PRODUCT_DETAIL_REQUEST: null,
  GET_PARTNER_PRODUCT_DETAIL_SUCCESS: null,
  GET_PARTNER_PRODUCT_DETAIL_FAILURE: null,

  GET_PARTNER_PRODUCT_DELIVERY_DETAIL_REQUEST: null,
  GET_PARTNER_PRODUCT_DELIVERY_DETAIL_SUCCESS: null,
  GET_PARTNER_PRODUCT_DELIVERY_DETAIL_FAILURE: null,

  GET_PARTNER_PRODUCT_BID_LIST_REQUEST: null,
  GET_PARTNER_PRODUCT_BID_LIST_SUCCESS: null,
  GET_PARTNER_PRODUCT_BID_LIST_FAILURE: null,

  GET_PARTNER_PRODUCT_BID_DETAIL_REQUEST: null,
  GET_PARTNER_PRODUCT_BID_DETAIL_SUCCESS: null,
  GET_PARTNER_PRODUCT_BID_DETAIL_FAILURE: null,

  DELETE_PARTNER_PRODUCT_REQUEST: null,
  DELETE_PARTNER_PRODUCT_SUCCESS: null,
  DELETE_PARTNER_PRODUCT_FAILURE: null,

  GET_PARTNER_ADDRESS_REQUEST: null,
  GET_PARTNER_ADDRESS_SUCCESS: null,
  GET_PARTNER_ADDRESS_FAILURE: null,

  GET_REPLACEMENT_PRODUCT_REQUEST: null,
  GET_REPLACEMENT_PRODUCT_SUCCESS: null,
  GET_REPLACEMENT_PRODUCT_FAILURE: null,

  POST_PARTNER_ADDRESS_REQUEST: null,
  POST_PARTNER_ADDRESS_SUCCESS: null,
  POST_PARTNER_ADDRESS_FAILURE: null,

  PROCESS_BID_REQUEST: null,
  PROCESS_BID_SUCCESS: null,
  PROCESS_BID_FAILURE: null,

  POST_PICKUP_ACTION_REQUEST: null,
  POST_PICKUP_ACTION_SUCCESS: null,
  POST_PICKUP_ACTION_FAILURE: null,

  VENDOR_PRODUCT_RESET: null,

  UPDATE_PRODUCT_DETAIL_VARIABLE: null,

  SHOW_LOADER: false,

  CREATE_COUNTER_OFFER_REQUEST: null,
  CREATE_COUNTER_OFFER_SUCCESS: null,
  CREATE_COUNTER_OFFER_FAILURE: null,

  GET_COUNTER_OFFER_LIST_REQUEST: null,
  GET_COUNTER_OFFER_LIST_SUCCESS: null,
  GET_COUNTER_OFFER_LIST_FAILED: null,

  GET_COUNTER_OFFER_DETAIL_REQUEST: null,
  GET_COUNTER_OFFER_DETAIL_SUCCESS: null,
  GET_COUNTER_OFFER_DETAIL_FAILED: null,

  PATCH_COUNTER_OFFER_DETAIL_REQUEST: null,
  PATCH_COUNTER_OFFER_DETAIL_SUCCESS: null,
  PATCH_COUNTER_OFFER_DETAIL_FAILED: null,

  CREATE_NEW_COUNTER_OFFER_REQUEST: null,
  CREATE_NEW_COUNTER_OFFER_SUCCESS: null,
  CREATE_NEW_COUNTER_OFFER_FAILED: null,

  GET_ACTION_COUNT_REQUEST: null,
  GET_ACTION_COUNT_SUCCESS: null,
  GET_ACTION_COUNT_FAILED: null,

  CLEAR_ACTION_COUNT_REQUEST: null,
  CLEAR_ACTION_COUNT_SUCCESS: null,
  CLEAR_ACTION_COUNT_FAILED: null,

  UPDATE_SELECTED_NEW_DELIVERY_STATUS: null,
});

export default ActionTypes;
