import React from "react";
import api from "utils/api.jsx";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation} from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import Muted from "components/Typography/Muted.jsx";
import queryString from "query-string";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AccountActionTypes from "constants/accountConstants.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import { decimalPlace } from "utils/helpers.jsx";
import {
  convertHindiToArabic,
  converToArabicString,
  isInputEnglish,
  postActionNotificationClicked,
  showStatusMessage
} from "utils/helpers.jsx";
import Badge from "components/Badge/Badge.jsx";

class BidDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: null,
      product: null,
      counter_input: "",
      counter_input_display:""
    };
  }
  componentDidMount() {
    this.renderSpecificProduct();
  }

  renderSpecificProduct = () => {
    let params = this.getExtraParameter();
    if (params.auctionId) {
      this.showProductBidDetail(params.auctionId);
    }
  };
  isMd = () => {
    if (isWidthUp("md", this.props.width)) {
      return true;
    } else {
      return false;
    }
  };

  formatNumber(num) {
    if (num && num.length > 0) {
      num = convertHindiToArabic(num, true);
      return num;
    } else {
      return '';
    }
  }

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);

    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }

    return params;
  };

  submitBid = (value, item) => {
    const { processBid } = this.props;
    let data = {
      action: value,
      url: item.bid.process_url,
      item_url: item.url
    };
    processBid(data);
  };

  showProductBidDetail = id => {
    const { getBidDetail } = this.props;
    const detailUrl = api.root + "partner/bid/list/" + id + "/";
    getBidDetail(detailUrl);
  };

  handlePickupChange = value => {
    const { update_pickup_time } = this.props;
    update_pickup_time(value);
  };

  get_is_seller=(item)=>{
    const { userDetails } = this.props;
    let is_seller=false;
    if(item.product && item.product.vendor.pk === userDetails.pk){
      is_seller=true;
    }
    return is_seller;
  }


  submitInitialCounterOffer = e => {
    e.preventDefault();
    const { bid_detail, postCounterOffer,showSnackbar,t } = this.props;
    let offer_amount = parseFloat(this.state.counter_input);
    let seller_buy_price = this.calculateCommission(bid_detail.product.price.incl_tax,'orgPrice'); 
    let is_seller=this.get_is_seller(bid_detail);

    if(is_seller &&( parseFloat(offer_amount) > parseFloat(seller_buy_price))){
      let txt = t("profileModal.offers.productbid.counteroffernotallowed", { amount: seller_buy_price });
      showSnackbar(txt, "warning");
    }
    else if((parseFloat(offer_amount) < parseFloat(bid_detail.product.is_auction_min_price))){
      let txt = t("profileModal.offers.productbid.counteroffernotallowedLower", { amount: bid_detail.product.is_auction_min_price });
      showSnackbar(txt, "warning");
    }
    else{
      let data = {
        bid_url: bid_detail.url,
        create_url: bid_detail.bid.create_counter_url,
        offer_amount:is_seller?this.calculateCommission(offer_amount,'plus'):offer_amount,
        bid_history: bid_detail.bid.id
      };
      this.setState({ counter_input: "" });
      postCounterOffer(data);
    }

  };

  // getBitAmount = e => {
  //   e.preventDefault();
  //   this.setState({ counter_input: e.target.value });
  // };

  handlePriceChange = e => {
    e.preventDefault();

    // let value = "";
    let targetValue = e.target.value ;
    var lastChar = targetValue.substr(targetValue.length - 1);
    let afterDec = targetValue.split(".")[1];
    if(afterDec === undefined || afterDec === "" || afterDec.length<4){
      let isEng = isInputEnglish(targetValue);
      let targetValue1 = this.formatNumber(targetValue);
      let targetValueInt =  parseFloat(targetValue1); //parseFloat(targetValue1);
      if(lastChar === "." || lastChar ==="٫" ){
        targetValueInt = targetValueInt + "."
      }
      let displayPrice = targetValueInt ? targetValueInt + "" : "";
      if (displayPrice && !isEng) {
        displayPrice = converToArabicString(displayPrice);
      }
      this.setState({
        counter_input: targetValueInt,
        counter_input_display: displayPrice
      });
      // if (targetValueInt) {
      //   value = this.calculateCommission(targetValueInt);
      // }
      // if (e.target.name === "offer_amount") {
      //   let message = "";
      //   if (value) {
      //     let currency = direction === "rtl" ? "د.ك." : "KD";
      //     message = (
      //       <Trans i18nKey="profileModal.ifAcceptedyoureceive">
      //         [
      //         <strong>
      //           {{ currency: currency }} {{ amount: value }}
      //         </strong>
      //         ]
      //       </Trans>
      //     );
      //   }
      //   updateBidMessage(message);
      // }
    }
  };

  calculateCommission = (value, type) => {
    const { bid_detail } = this.props;
    let pc = null;
    if (bid_detail.product && bid_detail.product.product_class) {
      pc = bid_detail.product.product_class;
    }
    if (pc) {
      let flat_rate = parseFloat(pc.flat_rate);
      let percent_rate = parseFloat(pc.percentage_rate);

      let float_value = parseFloat(value);
      let partner_value = flat_rate;

      let current_percentage = (percent_rate / 100) * float_value;
      if (current_percentage > flat_rate) {
        partner_value = current_percentage;
        if(type && type==='orgPrice'){
          let orgPrice=(float_value*100)/(100+percent_rate);
          return decimalPlace(orgPrice);
        }
      }
  
      if(type && type==='plus'){
        float_value = float_value + partner_value;
      }
      else{
        float_value = float_value - partner_value;
      }
      float_value = decimalPlace(float_value);
      return float_value;
    }
  };



  // renderInitialPercentageAmount = () => {
  //   const { bid_detail, direction } = this.props;
  //   let currency = direction === "rtl" ? "د.ك." : "KD";
  //   if (bid_detail && bid_detail.bid && bid_detail.bid.bid_amount) {
  //     let value = this.calculateCommission(bid_detail.bid.bid_amount);
  //     return (
  //       <Trans i18nKey="profileModal.ifAcceptedyoureceive">
  //         [
  //         <strong>
  //           {{ currency: currency }} {{ amount: value }}
  //         </strong>
  //         ]
  //       </Trans>
  //     );
  //   }
  // };


  renderCounterOfferSection = () => {
    const { bid_detail, t } = this.props;
    if (bid_detail.bid.create_counter_url) {
      return (
        <form onSubmit={this.submitInitialCounterOffer} autoComplete="off">
          <GridContainer className="mt-3">
            <GridItem
              xs={6}
              className="dp-f jcc align-items-center textButtonDiv"
            >
              <CustomInput
                labelText={t("profileModal.counteroffer")}
                placeholder={t(
                  "profileModal.offers.productbid.counterofferamount"
                )}
                id="offer_amount_id"
                size="lg"
                formControlProps={{
                  fullWidth: true,
                  className: "mb-0"
                }}
                inputProps={{
                  onChange: this.handlePriceChange,
                  name: "offer_amount",
                  required: true,
                  value: this.state.counter_input_display,
                  type: "text",
                  //step: 0.01,
                  disableUnderline: true
                }}
                inputActualProps={{
                  step: ".01"
                }}
              />
            </GridItem>
            <GridItem xs={6} className="dp-f jcc align-items-fe buttonTextDiv">
            {this.isMd() ?
              <Button
                color="primaryWhiteWithBorder"
                type="submit"
                fullWidth
                size="lg"
                className="mb-0"
              >
                {t("profileModal.offers.productbid.counterofferamount")}
              </Button> :
               <Button
               color="primaryWhiteWithBorder"
               type="submit"
               fullWidth
               size="lg"
               className="mb-0 offer-button"
             >
               {t("profileModal.offers.productbid.counter_offer")}
             </Button>}
            </GridItem>
          </GridContainer>
        </form>
      );
    }
    return null;
  };

  renderAction = () => {
    const { bid_detail, t } = this.props;

    if (!bid_detail.bid.accepted && !bid_detail.bid.rejected) {
      return (
        <div className="mt-3">
          <GridContainer>
            <GridItem xs={12} className="d-flex">
              <Button
                color="primaryWhiteWithBorder"
                fullWidth
                size="lg"
                className="text_wrap"
                onClick={() => this.submitBid("accept", bid_detail)}
              >
                {t("profileModal.offers.productbid.acc")}
                {/* {!this.isMd() && " " + bid_detail.product.price.currency}{" "}
                {!this.isMd() && bid_detail.bid.bid_amount} */}
              </Button>
              <Button
                color="primaryWhiteWithBorder"
                fullWidth
                size="lg"
                className="text_wrap"
                onClick={() => this.submitBid("reject", bid_detail)}
              >
                {t("profileModal.offers.productbid.rej")}
                {/* {!this.isMd() && " " + bid_detail.product.price.currency}{" "}
                {!this.isMd() && bid_detail.bid.bid_amount} */}
              </Button>
            </GridItem>
          </GridContainer>
          {this.renderCounterOfferSection()}
        </div>
      );
    } else {
      if (bid_detail.bid.accepted) {
        return (
          <>
          <Badge
            classes={{
              badge: "productBadge font11 p-0 status-badge"
            }}
          >
            <span
              className="order_status accepted"
            >
            {t("profileModal.offers.productbid.accepted")}
            </span>
          </Badge>
          <p>
            {t("profileModal.offers.productbid.acceptbit")}
          </p>
            </>
        );
     } else if (bid_detail.bid.rejected) {
        return (
          <>
          <Badge
          classes={{
            badge: "productBadge font11 p-0 status-badge"
          }}
        >
          <span
            className="order_status rejected"
          >
           {t("profileModal.offers.productbid.rejected")}
          </span>
        </Badge>
        <p >
          {t("profileModal.offers.productbid.rejectbit")}
        </p>
        </>
        );
      }
      return (
        <>
          <Muted>
                <h5 className="mb-1">
                   {this.pendingFrom()}
                </h5>
          </Muted>
        {/* <p>{t("profileModal.offers.productbid.counter_submitted")}</p> */}
        </>
      );
    }
  };

  pendingFrom=()=>{
    const { userDetails , bid_detail, t }=this.props;

    if(userDetails && bid_detail && userDetails.pk === bid_detail.product.vendor.pk){
      return t("profileModal.offers.productbid.pendingResBuyer");
    }
    return t("profileModal.offers.productbid.pendingResSeller");
  }
  renderBack = () => {
    const { t, direction } = this.props;

    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="image_back" />
          {t("profileModal.offers.productbid.backtoList")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="image_back" />
          {t("profileModal.offers.productbid.backtoList")}
        </div>
      );
    }
  };
  partnerProductBidDetail = () => {
    const {
      classes,
      bid_detail,
      vendor_fetching,
      t,
      direction,
      updateAccountPageComponent,
      bid_message,
      auction_notification_count
    } = this.props;
    const {counter_input_display } = this.state;
    let received_offer_item_count =
      auction_notification_count && auction_notification_count.receive_offer
        ? auction_notification_count.receive_offer
        : null;
    // clear the count if page loaded from email link
    if (received_offer_item_count && received_offer_item_count.count > 0) {
      postActionNotificationClicked(received_offer_item_count.url_to_read);
    }

    let product_status = null;
    if (bid_detail && bid_detail.product && bid_detail.product.product_status) {
      product_status = showStatusMessage(
        bid_detail.product.product_status,
        "seller"
      );
    }
    if (vendor_fetching) {
      return (
        <Hidden smDown implementation="css">
          <Loader />
        </Hidden>
      );
    }
    if (
      bid_detail &&
      bid_detail.product &&
      bid_detail.bid &&
      bid_detail.product.availability.is_available_to_buy !== false
    ) {
      return (
        <div>
          <Button
            link
            size="lg"
            onClick={() => updateAccountPageComponent("bidlist")}
            className={direction === "rtl" ? "pr-0" : "pl-0"}
          >
            {this.renderBack()}
          </Button>
          <Card
            bordered
            className={
              product_status && product_status.color
                ? "pointer listed_item "
                : "pointer"
            }
            style={
              product_status && product_status.color
                ? {
                    borderColor: product_status ? product_status.color : null
                  }
                : null
            }
          >
            <CardBody
              className={
                !bid_detail.bid.accepted && !bid_detail.bid.rejected
                  ? "cardBodyMinHeight"
                  : null
              }
            >
              <div className="d-flex justify-content-center">
                <div className={classes.bidDetailContainer}>
                  <img
                    src={bid_detail.product.image}
                    className={classes.img}
                    alt={bid_detail.product.title}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <h5>{bid_detail.product.title}</h5>
                </div>
                <div>
                  {product_status && product_status.message ? (
                    <Badge
                      classes={{
                        badge: "productBadge font11 p-0 status-badge"
                      }}
                    >
                      <span
                        style={{ backgroundColor: product_status.color }}
                        className="order_status"
                      >
                        {product_status.message}
                      </span>
                    </Badge>
                ) : null}
                </div>
              </div>
              <Muted>
                <p>{bid_detail.product.description}</p>
              </Muted>
              <Muted>
                <h5 className="mb-1">
                  {t("profileModal.offers.productbid.receivedbid")}:{" "}
                  {bid_detail.product.price.currency}{" "}
                  {this.calculateCommission(bid_detail.bid.bid_amount)} 
                  {/* {this.renderInitialPercentageAmount()} */}
                </h5>
              </Muted>
              {counter_input_display !== "" &&
              bid_message &&
              !bid_detail.bid.accepted &&
              !bid_detail.bid.rejected ? (
                <Muted>
                  <h5 className="mb-1">
                    {t("profileModal.offers.productbid.counter_offer")}:{" "}
                    {bid_detail.product.price.currency}{" "}
                    {decimalPlace(counter_input_display)} {bid_message}
                  </h5>
                </Muted>
              ) : null}
              {this.renderAction()}
            </CardBody>
          </Card>
        </div>
      );
    } else {
      return <p>{t("profileModal.offers.productbid.product")}</p>;
    }
  };
  render() {
    return this.partnerProductBidDetail();
  }
}

const mapDispatchToProps = dispatch => ({
  processBid: data => {
    dispatch({
      type: VendorProductActionTypes.PROCESS_BID_REQUEST,
      //url:url,
      payload: data
    });
  },

  getBidDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_REQUEST,
      payload: url
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  update_pickup_time: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PICKUP_TIME,
      data
    });
  },
  showSnackbar: (txt, variant = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  postCounterOffer: data => {
    dispatch({
      type: VendorProductActionTypes.CREATE_COUNTER_OFFER_REQUEST,
      data
    });
  },
  updateBidMessage: message => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_BID_MESSAGE,
      message
    });
  }
});

const mapStateToProps = state => ({
  ...state.accountReducer,
  ...state.cartReducer,
  ...state.accountReducer,
  ...state.modalReducer,
  ...state.vendorProductReducer,
  ...state.globalReducer,
  ...state.comboProductReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withWidth()
)(BidDetail);
