import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
// nodejs library that concatenates classes
import Select from "react-select";
// styles
import customInputStyle from "kayfik/assets/jss/components/CustomInput/customInputStyle.jsx";
// detect language for placeholder string & language detector to prevent memory leak
import i18next from 'i18next';


class SelectAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActionIcon: false
    };
  }
  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      document.dispatchEvent(new Event("compositionend"));
    }
  };

  componentDidMount() {
    let inputEl = document.getElementById(this.props.inputId);
    if (inputEl) {
      inputEl.setAttribute("name", this.props.inputId);
    }
  }
  filterOption = (option, inputValue) => {
    const { autocompleteOptions , otherLabel} = this.props;
    if (otherLabel && option.label === otherLabel && inputValue) {
      const result = autocompleteOptions.filter(opt => opt.label.toLowerCase().includes(inputValue.toLowerCase()));
      // this.setState({ hasExtraValue: !result.length });
      return !result.length;
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };
  
  render() {
    const {
      selectOnChange,
      autocompleteOptions,
      controlProps,
      placeholder
    } = this.props;
    const customStyles = {
      option: (styles, state) => {
        return {
          ...styles,
          height: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          zIndex: 50,
          backgroundColor: state.isFocused ? "#00ACC1" : "",
          color: state.isFocused ? "#fff" : "",
          ":active": {
            ...styles[":active"],
            backgroundColor: "#00ACC1"
          }
        };
      },
      control: (styles, state) => ({
        ...controlProps,
        ...styles,
        border: state.isFocused ? 0 : 0,
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        "&:hover": {
          border: state.isFocused ? 0 : 0
        },
        borderColor: "transparent",
        backgroundColor: "#DDE3E8",
        padding: "0.3rem 0.3rem",
        minHeight: "55px"
      }),
      valueContainer: styles => ({
        ...styles,
        overflow: "visible"
      }),

      menu: provided => ({
        ...provided,
        zIndex: 9999,
        width: "100%"
      }),

      dropdownIndicator: defaultStyles => ({
        ...defaultStyles,
        color: "#495057"
      })
    };
    const {
      classes,
      labelText,
      noSelectMb,
      error,
      success
    } = this.props;
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    this.props.inputProps["autoComplete"] = "off";
    let otherprops={};
    if(this.props.useFilterOption){
      otherprops["filterOption"]=this.filterOption
    }
    return (
      <div
        style={{
          position: "relative",
          marginBottom: noSelectMb ? "0" : "17px"
        }}
      >
        {/* "userName-capitalize" */}
        {labelText && (
          <div
            className={classNames(
              this.props.inputProps.required
                ? "required " + classes.formLabel + " " + labelClasses
                : classes.formLabel + "  " + labelClasses
            )}
          >
            {labelText}
          </div>
        )}
        <Select
          maxMenuHeight={this.props.maxMenuHeight}
          options={autocompleteOptions}
          inputProps={this.props.inputProps}
          hideSelectedOptions={this.props.hideSelectedOptions}
          isMulti={false}
          styles={customStyles}
          inputId={this.props.inputId}
          placeholder={i18next.language==='ar' ? "الإختيارات" : placeholder}
          defaultValue={this.props.defaultValue}
          isDisabled={this.props.disabled}
          textFieldProps={{
            label: "Label",
            InputLabelProps: {
              shrink: true
            }
          }}
          {...otherprops}
          menuPlacement={this.props.menuPlacement? this.props.menuPlacement:"bottom"}
          label="Single select"
          theme={theme => ({
            ...theme,
            borderWidth: 0,
            marginTop: "20px",
            marginBottom: "20px",
            borderRadius: 0,
            paddingTop: "3px",
            paddingBottom: "3px",
            colors: {
              ...theme.colors,
              neutral0: "#DDE3E8",
              primary: "#00ACC1",
              primary25: "#ffff",
              primary50: "#00ACC1",
              neutral90: "#3C4858 ",
              neutral80: "#3C4858 ",
              neutral20: "transparent"
            }
          })}
          //menuContainerStyle={{ 'zIndex': 999 }}
          onChange={selectOnChange}
          isSearchable={true}
          isClearable={false}
          onInputChange={this.props.onInputChange}
          value={this.props.value ? this.props.value : ""}
          //components={{
          //    ValueContainer: CustomValueContainer
          //}}
          getOptionLabel={this.props.getOptionLabel}
          selectProps={this.props.selectProps}
          onKeyDown={this.onKeyDown}
          name={this.props.name}
        />
        <input
          tabIndex={-1}
          style={{
            height: 0,
            border: "2px solid red",
            position: "absolute",
            bottom: "2%",
            width: "100%",
            opacity: 0
          }}
          type="text"
          name={this.props.input_name}
          autoComplete="new-password"
          value={this.props.value ? this.props.value : ""}
          required={this.props.required}
          onChange={e => this.props.selectOnChange({ value: e.target.value })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.globalReducer
});

export default compose(withStyles(customInputStyle), connect(mapStateToProps))(
  SelectAutocomplete
);
