import customDropdownStyle from "assets/jss/material-kit-pro-react/components/customDropdownStyle.jsx";

const customDropdownStyles = theme => ({
  ...customDropdownStyle(theme),
  share: {
    //[theme.breakpoints.up("sm")]: {
    minWidth: "unset !important"
    //}
  },
  positionAr: {
    [theme.breakpoints.down("sm")]: {
      top: "2rem !important",
      left: "-1rem !important"
    }
  },
  position: {
    [theme.breakpoints.down("sm")]: {
      top: "1.5rem !important",
      left: "3rem !important"
    }
  }
});

export default customDropdownStyles;
