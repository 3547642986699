import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import customInputStyle from "kayfik/assets/jss/components/CustomInput/customInputStyle.jsx";

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    inputProps,
    // inputActualProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    size
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes[size]]: size
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      classes.formControl,
      formControlProps.className
    );
  } else {
    formControlClasses = classes.formControl;
  }
  inputProps["autoComplete"] = "off";
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <div
          className={
            inputProps.required
              ? "required " + classes.formLabel + " " //+ labelClasses
              : classes.formLabel + " " + labelClasses
          }
        >
          {labelText}
        </div>
      ) : null}
      <Input
        classes={{
          input: classes.formInputStyle + " " + inputClasses + " " + (inputProps.rows ? " " : classes.inputHeight),
          root: marginTop + " " + classes.withAdormentBG,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        {...inputProps}
      />

      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg"])
};

export default withStyles(customInputStyle)(CustomInput);
