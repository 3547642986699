// UncontrolledLottie.jsx
import React, { Component } from "react";
import kayfikLoader from "kayfik/assets/images/kayfik_loader.gif";

class Loader extends Component {
  componentDidMount() {
    this.modalContentHeight = "100%";
    this.modalContentWidth = "100%";
    let modalElement = document.getElementById("modalRootId");
    let scrollPaperElement = document.getElementsByClassName(
      "scrollPaperClass"
    )[0];

    if (scrollPaperElement) {
      let firstChild = scrollPaperElement.firstElementChild;
      if (firstChild) {
        firstChild.firstElementChild.classList.add("stopBody-scrolling");
        this.modalContentHeight = firstChild.clientHeight;
        this.modalContentWidth = firstChild.clientWidth;
      }
    }

    if (modalElement) {
      modalElement.classList.remove("stopBody-scrolling");
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("stopBody-scrolling");
    let modalElement = document.getElementById("modalRootId");
    if (modalElement) {
      modalElement.classList.remove("stopBody-scrolling");
    }
    let scrollPaperElement = document.getElementsByClassName(
      "scrollPaperClass"
    )[0];
    if (scrollPaperElement) {
      let firstChild = scrollPaperElement.firstElementChild;
      if (firstChild) {
        firstChild.firstElementChild.classList.remove("stopBody-scrolling");
      }
    }
  }

  render() {
    document.body.classList.add("stopBody-scrolling");
    const loaderStyle = {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: "#ffffff",
      zIndex: 999999,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };
    if (this.modalContentWidth && this.modalContentHeight && !this.props.noHeight) {
      loaderStyle["height"] = this.modalContentHeight;
    }

    if (this.props.fixed) {
      loaderStyle["position"] = "fixed";
    }

    const imgStyle = {
      maxWidth: "80vw"
    };

    return (
      <div style={loaderStyle}>
        <img src={kayfikLoader} style={imgStyle} alt="Kayfik" />
      </div>
    );
  }
}

export default Loader;
