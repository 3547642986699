import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
import {
  htPrimaryGreen,
  htPrimaryYellow
} from "kayfik/assets/jss/universal.jsx";
import { containerFluid } from "assets/jss/material-kit-pro-react.jsx";

const customHeaderStyle = theme => ({
  ...headerStyle(theme),
  containerFluid,
  mb0: {
    marginBottom: "0px !important",
    paddingRight: "0px !important",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px !important"
    }
  },
  p0: {
    padding: "0 !important"
  },
  backdrop: {
    // top:"60px !important",
  },
  kayfikLogo: {
    height: "75px!important",
    [theme.breakpoints.down("md")]: {
      height: "65px !important"
    }
  },
  headerPaper: {
    width: "100% !important",
    display: "flex",
    height: "43% !important",
    // top:"60px !important",
    [theme.breakpoints.down("sm")]: {
      height: "55% !important",
      overflowY: "overlay !important"
    }
  },
  overlay: {
    overflowY: "overlay !important"
  },
  cartDropdownContainer: {
    width: "400px !important",
    borderWidth: "2px",
    borderColor: "red"
  },
  fontColor: {
    color: htPrimaryGreen + "!important"
  },
  grow: {
    flexGrow: 1
  },
  toolbar: theme.mixins.toolbar,
  buttonSize: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "55px !important"
    }
  },
  zSM: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "2222 !important"
    }
  },
  zSMdropdown: {
    [theme.breakpoints.down("sm")]: {
      zIndex: "2090 !important"
    }
  },
  yellowText: {
    "&:hover": {
      color: htPrimaryYellow + "!important"
    }
  },
  bgTransparent: {
    background: " transparent !important"
  }
});

export default customHeaderStyle;
