import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// materail ui core component
import withStyles from "@material-ui/core/styles/withStyles";
// material ui Icon
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";

import compose from "recompose/compose";
// redux
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
// React i18next
import { withTranslation } from "react-i18next";
// Core Components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// Constants
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import dropZoneStyle from "kayfik/assets/jss/components/CustomInput/dropZoneStyle";

function dropZone(props) {
  const [files, setFiles] = useState([]);

  const {
    classes,
    showFileNames,
    singleLineWithIcon,
    icon,
    inputText,
    inputProps,
    t,
    updateProductImages,
    previous_images,
    updatePreviousImages
    // showSnackbar
  } = props;

  const maxSize = 5048576;
  // const maxNumber = 3;
  // const maxNumberExceeded = t("sellingPage.imageModal.maxNumber", {
  //   number: maxNumber
  // });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    rejectedFiles
  } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: acceptedFiles => {
      let current_files = [...files];
      for (let i = 0; i < acceptedFiles.length; i++) {
        let file = acceptedFiles[i];
        let new_file = Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
        current_files.push(new_file);
      }
      setFiles([...current_files]);

      updateProductImages([...current_files]);
    },
    maxSize
  });

  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  const removeFile = index => () => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const removePreviousFile = index => () => {
    previous_images.splice(index, 1);
    updatePreviousImages([...previous_images]);
  };

  const thumbs = files.map((file, index) => (
    <GridItem xs={4} key={index} className="py-2">
      <IconButton className="drop-zone-remove-icon" onClick={removeFile(index)}>
        <Close />
      </IconButton>
      <div className={classes.thumb}>
        <img src={file.preview} alt={file.name} className={classes.imgFluid} />
      </div>
    </GridItem>
  ));

  const fileNames = files.map((file, index) => (
    <GridItem
      xs={12}
      key={index}
      className={"d-p-f justifyContentSpaceBetween"}
    >
      <h6 className="my-0">{file.name}</h6>
    </GridItem>
  ));

  const renderPreview = previous_images.map((image, index) => (
    <GridItem xs={4} key={index} className="py-2">
      <IconButton
        className="drop-zone-remove-icon"
        onClick={removePreviousFile(index)}
      >
        <Close />
      </IconButton>
      <div className={classes.thumb}>
        <img src={image.url} alt="" className={classes.imgFluid} />
      </div>
    </GridItem>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      // files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section className="container">
      <div {...getRootProps({ className: classes.dropZoneContainer })}>
        <input {...getInputProps()} {...inputProps} />
        <div className={classes.dropZoneTextContainer}>
          <div className="justify-center d-flex mb-3">
            <Add className="big-icon icon-primarylight" />
          </div>

          {isDragActive && !isDragReject ? (
            <p className="m-0">{t("sellingPage.imageModal.dragZone.set")}</p>
          ) : null}

          {!isDragActive && !isDragReject ? (
            <div>
              <h4 className="m-0">
                {t("sellingPage.imageModal.dragZone.drag")}
              </h4>
              <p className="m-0">
                {t("sellingPage.imageModal.dragZone.adding")}
              </p>
              <p className="m-0">
                {t("sellingPage.imageModal.dragZone.images")}
              </p>
            </div>
          ) : null}

          {isFileTooLarge && (
            <div className="text-danger mt-2">
              {t("sellingPage.imageModal.dragZone.file")}
            </div>
          )}

          {isDragReject ? (
            <p className="m-0">{t("sellingPage.imageModal.dragZone.sorry")}</p>
          ) : null}
        </div>
        {singleLineWithIcon ? (
          <GridContainer className="justifyContentSpaceBetween px-1 d-p-f inputIconContainer">
            <span className={classes.formInputStyle + " " + classes.input}>
              {inputText}
            </span>
            {icon}
          </GridContainer>
        ) : null}
      </div>
      <GridContainer className="mb-3">
        {renderPreview}
        {thumbs}
      </GridContainer>
      {showFileNames ? (
        <GridContainer className="mb-3">{fileNames}</GridContainer>
      ) : null}
    </section>
  );
}

const mapDispatchToProps = dispatch => ({
  updateProductImages: files => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PRODUCT_IMAGES,
      files
    });
  },
  showSnackbar: txt => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  updatePreviousImages: images => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PREVIOUS_IMAGES,
      images
    });
  }
});

const mapStateToProps = state => ({
  ...state.comboProductReducer
});

dropZone.propTypes = {
  classes: PropTypes.object,
  showFileNames: PropTypes.array,
  inputProps: PropTypes.object,
  showSnackbar: PropTypes.func,
  t: PropTypes.func,
  updateProductImages: PropTypes.func
};

export default compose(
  withStyles(dropZoneStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(dropZone);
