import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router";
import { SnackbarProvider } from "notistack";
import compose from "recompose/compose";
// KAYFIK
import LandingPage from "kayfik/views/LandingPage/LandingPage.jsx";
import ProductDetail from "kayfik/views/ProductDetail/productDetail.jsx";
import CompanySignup from "kayfik/views/CompanySignup/CompanySignup.jsx";
import FAQ from "kayfik/views/FAQ/faq.jsx";
import HowItWorks from "kayfik/views/HowItWorks/howItWorks.jsx";
import WhyKayfikWorks from "kayfik/views/WhyKayfikWorks/whyKayfikWorks.jsx";
import About from "kayfik/views/About/about.jsx";
import Checkout from "kayfik/views/Checkout/Checkout.jsx";
import ModalContainer from "kayfik/components/Modal/modalRoot.jsx";
import ShopHome from "kayfik/views/Shop/ShopHome.jsx";
import CategoryProductPage from "kayfik/views/Shop/CategoryProductPage.jsx";
import Notifier from "kayfik/components/Notifier/notifier.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import FacetMobile from "kayfik/views/Shop/ShopFacetMobile.jsx";
//404 page
import PageNotFound from "kayfik/components/PageNotFound/PageNotFound.jsx";
import BlankPage from "kayfik/components/BlankPage/BlankPage.jsx";
// core component
import { store } from "utils/store.jsx";
import appStyle from "kayfik/assets/jss/components/appStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Terms from "./kayfik/views/TermsConditions/terms";
import PrivacyPolicy from "./kayfik/views/Privacy/privacy";
import history from "./history.js";

import { getNotificationCount } from "utils/helpers.jsx";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      app_loader: true
    };
  }

  componentDidMount() {
    const language = store.getState().globalReducer.language;
    // const account_token = store.getState().accountReducer.account_token;
    if (language !== "en") {
      document.body.dir = "rtl";
      document.body.lang = "ar";
    }
    this.setState({ app_loader: false });
    //Timer for every 30sec to update the Notification action count
    setInterval(function () {
      getNotificationCount();
    }, 30000);

  }
 
  render() {
    const { classes } = this.props;
    if (this.state.app_loader) {
      return <Loader fixed />;
    }

    return (
      <SnackbarProvider
        id="mainSnack"
        maxSnack={3}
        classes={{
          variantSuccess: classes.zIndexSnack,
          variantError: classes.zIndexSnack,
          variantWarning: classes.zIndexSnack,
          variantInfo: classes.zIndexSnack,
          root: classes.snackbarRootZ
        }}
      >
        <Notifier />
        <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route
              exact
              path="/shop/:categorySlug/:productSlug/:itemId"
              component={ProductDetail}
            />
            <Route
              exact
              path="/shop/category/:catId"
              component={ShopHome}
            />
            <Route exact path="/shop" component={ShopHome} />
            {/* <Route exact path="/shop/main_category/:catId" component={ShopHome} /> */}
            <Route exact path="/search" component={CategoryProductPage} />
            <Route exact path="/company-signup" component={CompanySignup} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/how-it-works" component={HowItWorks} />
            <Route exact path="/why-kayfik-works" component={WhyKayfikWorks} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/about" component={About} />
            {/* verify-email/?key=MTQ:1hao1J:3TuuoqJxv648so1-dFGXCyKI6jI&email=test6@mail.com */}
            <Route exact path="/verify-email" component={BlankPage} />
            <Route exact path="/password-reset" component={BlankPage} />
            <Route exact path="/confirmation" component={LandingPage} />
            <Route exact path="/facets" component={FacetMobile} />
            <Route path="/404" component={PageNotFound} />
            <Redirect from="*" to="/404" />
          </Switch>
        </Router>
        <ModalContainer />
        <div className="dummyScroll" />
      </SnackbarProvider>
    );
  }
}

export default compose(withStyles(appStyle))(App);
