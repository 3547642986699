import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import { grayColor, container } from "assets/jss/material-kit-pro-react.jsx";
import {
  htPrimaryGreen,
  htWhite,
  htPrimaryYellow
} from "kayfik/assets/jss/universal.jsx";

const customHeaderLinksStyle = theme => ({
  ...headerLinksStyle(theme),
  // list: {
  //   [theme.breakpoints.down("sm")]: {
  //     display: "flex",
  //     flexDirection: "column",
  //     padding: 0,
  //     height: "100%",
  //     justifyContent: " space-between"
  //   }
  // },
  [theme.breakpoints.up("sm")]: {
    listItemDivider: {
      "&:not(:last-child)": {
        "&:after": {
          width: "1px",
          content: '""',
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          backgroundColor: grayColor[14]
        }
      }
    },
    listItemDividerAR: {
      "&:not(:last-child)": {
        "&:after": {
          width: 0
        },
        "&:before": {
          width: "1px",
          content: '""',
          position: "absolute",
          top: 0,
          bottom: 0,
          marginLeft: "-12px",
          left: 0,
          backgroundColor: grayColor[14]
        }
      }
    }
  },
  [theme.breakpoints.down("sm")]: {
    listItem: {
      "&:not(:last-child)": {
        "&:after": {
          width: "0px",
          height: "0px",
          content: '""',
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          backgroundColor: grayColor[14]
        }
      },
      paddingLeft: "15px !important"
    }
  },
  listItemMobAR: {
    paddingRight: "15px !important",

    paddingBottom: "0 !important",
    //paddingRight: "15px !important",
    "&:not(:first-child)": {
      paddingTop: "0 !important"
    }
  },
  searchIconAfter: {
    "&:after": {
      width: "1px",
      content: '""',
      position: "absolute",
      top: 15,
      bottom: 15,
      right: 0,
      backgroundColor: grayColor[14]
    }
  },
  searchField: {
    //border:"2px solid red !important",
    backgroundColor: htWhite + "!important",
    "&:hover,&:focus": {
      backgroundColor: grayColor[14] + "!important"
    }
  },
  searchIcon: {
    width: "30px !important",
    height: "30px !important"
  },
  searchInput: {
    paddingTop: "10px !important",
    paddingRight: "20px !important"
  },
  fontColor: {
    color: htPrimaryGreen + "!important"
  },
  modalLarge: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1000px !important"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none !important",
      width: "100%",
      marginLeft: "0 !important",
      marginRight: "0 !important"
    }
  },
  mp0: {
    margin: "0px !important",
    padding: "0px !important"
  },
  mt27: {
    marginTop: "27px !important"
  },
  mb10: {
    marginBottom: "10px !important"
  },
  button: {
    padding: "1.125rem 1.25rem !important"
  },
  profileAvatar: {
    maxHeight: "85px !important",
    maxWidth: "85px !important",
    boxShadow: "unset !important",
    marginLeft: "15px"
  },
  cartCircle: {
    display: "flex",
    width: "1.4rem",
    height: "1.4rem",
    borderRadius: "0.7rem",
    backgroundColor: htPrimaryYellow,
    position: "absolute",
    right: 30,
    top: 15,
    justifyContent: "center",
    alignItems: "center"
  },
  cartNumber: {
    color: htWhite
  },
  container,
  imgContainer: {
    width: "120px",
    height: "100px",
    // overflow: "hidden",
    // display: "block",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  },
  tdNameAnchor: {
    color: grayColor[1]
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300"
  }
});

export default customHeaderLinksStyle;
