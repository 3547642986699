import React from "react";
import classNames from "classnames";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import Muted from "components/Typography/Muted.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalPagination from "kayfik/components/Pagination/newPage.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import Badge from "components/Badge/Badge.jsx";
import {
  postActionNotificationClicked,
  showStatusMessage
} from "utils/helpers.jsx";

class ProductDeliveryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showProductDetail = product => () => {
    const {
      getProductDetail,
      updateAccountPageComponent,
      resetLoadMore
    } = this.props;
    getProductDetail(product);
    updateAccountPageComponent("productdeliverydetail");
    resetLoadMore(false, null);
  };

  // To updated the list if it comes with email link.
  componentDidMount() {
    const { getPartnerProducts } = this.props;
    getPartnerProducts();
  }
  partnerProductList = () => {
    const {
      classes,
      partner_product_delivery_list,
      t,
      auction_notification_count, language,
      showLoader
    } = this.props;

    let dataToReturn = [];
    // Count for delivery items
    const deliverey_items_count =
      auction_notification_count && auction_notification_count.delivery_items
        ? auction_notification_count.delivery_items
        : null;

    if (deliverey_items_count && deliverey_items_count.count > 0) {
      postActionNotificationClicked(deliverey_items_count.url_to_read);
    }

    if (partner_product_delivery_list) {
      if (partner_product_delivery_list.count > 0) {
        partner_product_delivery_list.results.map((product, i) => {
          //checking the traslation based on the product status
          let product_status = null;
          if (product && product.status) {

            let current_status = product.status;
            let cancelled_statuses = [
              'Cancelled','Init refund',
              'Refunded',
              'Refund requested to TAP',
              'Refund requested',
            ]
            if (cancelled_statuses.includes(current_status)) {
              current_status = 'Cancelled';
            }

            product_status = showStatusMessage(
              current_status,
              "seller"
            );
          } 
          dataToReturn.push(
            <Card
              bordered
              key={i}
              className={
                product_status && product_status.color
                  ? "pointer listed_item "
                  : "pointer"
              }
              style={
                product_status && product_status.color
                  ? {
                      borderColor: product_status ? product_status.color : null
                    }
                  : null
              }
              id={
                i === partner_product_delivery_list.results.length - 1
                  ? "lastpartnerProd"
                  :( i === 0?"firstpartnerProd" :  "partnerProd" + i)
              }
            >
              <CardBody className="p-0">
                <div className="d-flex listing-images">
                  <div
                    className={classNames(
                      "d-flex jcc align-items-center",
                      classes.f15
                    )}
                  >
                    <div
                      className={classes.bidListingContainer}
                      onClick={this.showProductDetail(product)}
                    >
                      <img
                        src={product.image}
                        className={classes.img}
                        alt={product.product}
                      />
                    </div>
                  </div>
                  <div className={classes.listDetailContainer + " p-3"}>
                    <div>
                      <div className="dp-f jcsb">
                        <h5
                          className="m-0"
                          onClick={this.showProductDetail(product)}
                        >
                          {product.product}
                        </h5>
                      </div>
                      <div onClick={this.showProductDetail(product)}>
                        <h5 className="mb-1">
                          <Muted>
                            {product.currency} {product.price}
                          </Muted>
                        </h5>
                        {product_status && product_status.message ? (
                          <Badge
                            // color="primary"
                            className={
                              language === "en"
                                ? "dp-f jcfe listing_badge"
                                : "dp-f jcfe listing_badgeAR"
                            }
                            classes={{
                              badge: "productBadge font11 "
                            }}
                          >
                            <span
                              style={{ backgroundColor: product_status.color }}
                              className="order_status"
                            >
                              {product_status.message}
                            </span>
                          </Badge>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          );
          return true;
        });
        if (showLoader) {
          dataToReturn.push(
            <div className="dp-f jcc" key="progress">
              <CircularProgress
                disableShrink
                classes={{ root: classes.indicator }}
              />
            </div>
          );
        }
      } else {
        dataToReturn.push(
          <GridContainer className="mt-4" key="notavailable">
            <p>{t("profileModal.products.data")}</p>
            <GridItem xs={12}>
              <Button
                color="primaryWhiteWithBorder"
                size="lg"
                className="mb-3"
                fullWidth
                onClick={this.openModal("whatSellingModal")}
              >
                {t("homePage.header.sell")}
              </Button>
            </GridItem>
          </GridContainer>
        );
      }
    } else {
      dataToReturn.push(
        <GridContainer className="mt-4" key="notavailable">
          <p>{t("profileModal.products.data")}</p>
          <GridItem xs={12}>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              className="mb-3"
              fullWidth
              onClick={this.openModal("whatSellingModal")}
            >
              {t("homePage.header.sell")}
            </Button>
          </GridItem>
        </GridContainer>
      );
    }

    return dataToReturn;
  };

  openModal = modal_type => async () => {
    const { showModal } = this.props;
    showModal(
      {
        open: true,
        title: modal_type,
        nextModal: "profileModal"
      },
      modal_type
    );
  };

  render() {
    const { vendor_fetching, t, partner_product_delivery_list } = this.props;
    let nextLink = partner_product_delivery_list ? partner_product_delivery_list.next : null;
    let elementID = "lastpartnerProd";
    let nextLinkToFetch = nextLink;
    let loadMore = "partner-products";
    if (vendor_fetching) {
      return (
        <Hidden smDown implementation="css">
          <Loader noHeight={true} />
        </Hidden>
      );
    }
    return (
      <div className="main-background-color product_listing">
        <h4 className="profile_section">{t("profileModal.delivery_products")}</h4>
        <div
          className="partnerProductListing scrollbar"
          onScroll={() =>
            ModalPagination.trackScrolling(elementID, nextLinkToFetch, loadMore)
          }
        >
          {this.partnerProductList()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProductDetail: data => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DELIVERY_DETAIL_SUCCESS,
      data
    });
  },
  deletePartnerProduct: url => {
    dispatch({
      type: VendorProductActionTypes.DELETE_PARTNER_PRODUCT_REQUEST,
      url
    });
  },
  // Update account page component
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  updatePartnerProducts: data => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_SUCCESS,
      data
    });
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  resetLoadMore: (payload, currentFetch) => {
    dispatch({
      type: VendorProductActionTypes.SHOW_LOADER,
      payload,
      currentFetch
    });
  },
  getPartnerProducts: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_REQUEST
    });
  }
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer,
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(ProductDeliveryList);
