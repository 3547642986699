import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import usePushNotifications from "./usePushNotifications";
import { useTranslation } from "react-i18next";
import { browserName } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";

import GlobalActionTypes from "constants/globalConstants.jsx";

export default function PushNotificationDemo() {
  const webpush = useSelector(state => state.globalReducer.webpush);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: GlobalActionTypes.UPDATE_WEB_PUSH,
      webpush: false
    });
  };

  const { t } = useTranslation();

  const { onClickAskUserPermission } = usePushNotifications();

  // return only when firefox and chrome
  if (
    browserName === "Chrome" ||
    browserName === "Firefox" ||
    browserName === "Opera"
  ) {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={webpush}
        // autoHideDuration={6000}
        onClose={handleClose}
        message={t("common.pushmessage")}
        // classes={{
        //   root: "push-snackbar" // class name, e.g. `classes-nesting-root-x`
        // }}
        ContentProps={{
          "aria-describedby": "message-id",
          className: "push-snackbar"
        }}
        action={
          <React.Fragment>
            <Button
              color="primary"
              size="small"
              onClick={onClickAskUserPermission}
              className="push-button"
            >
              {t("common.pushbutton")}
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  } else {
    return null;
  }
}
