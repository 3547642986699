import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
// React i18next
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
// custom core component
import AccountSettings from "kayfik/views/ProfileModal/accountSettings.jsx";
import Orders from "kayfik/views/ProfileModal/orders.jsx";
import OrderDetail from "kayfik/views/ProfileModal/orderDetail.jsx";
import ProductListing from "kayfik/views/ProfileModal/productListing.jsx";
import ProductDeliveryList from "kayfik/views/ProfileModal/productDeliveryList.jsx";

import ProductDetail from "kayfik/views/ProfileModal/productDetailPage.jsx";
import ProductDeliveryDetail from "kayfik/views/ProfileModal/productDetailDeliveryPage.jsx";


import ProductBidListing from "kayfik/views/ProfileModal/productBidListing.jsx";
import CounterOfferList from "kayfik/views/ProfileModal/counterOfferList.jsx";

import BidDetail from "kayfik/views/ProfileModal/productBidDetail.jsx";
import CounterOfferDetail from "kayfik/views/ProfileModal/counterOfferDetail.jsx";

import PartnerAddress from "kayfik/views/ProfileModal/partnerAddress.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import AccountActionTypes from "constants/accountConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
//image
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import queryString from "query-string";
import Hidden from "@material-ui/core/Hidden";
import Loader from "kayfik/components/Loader/loader.jsx";
import IconButton from "@material-ui/core/IconButton";
import RequestProducts from "kayfik/views/ProfileModal/requestProductsForm.jsx";
import ProductRequests from "kayfik/views/ProfileModal/productRequests.jsx"
import ProductReqDetail from "kayfik/views/ProfileModal/reqDetailPage.jsx"

import { Badge } from "@material-ui/core";

class ProfileModal extends React.Component {
  componentDidMount() {
    const {
      updateAccountPageComponent,
      resetLoadMore,
      getActionNotificationCount, closeModal, 
      account_token
    } = this.props;
    if (account_token) {
      getActionNotificationCount(); // for getting notification auction count
      let params = this.getExtraParameter();
      if (params.productId) {
        updateAccountPageComponent("productdetail");
      } else if (params.auctionId) {
        updateAccountPageComponent("biddetail");
      } else if (params.counterOfferId) {
        updateAccountPageComponent("counterofferdetail");
      }else if (params.orderId) {
        updateAccountPageComponent("orderdetail");
      }
      resetLoadMore(false, null);
    } else {
      closeModal(); // close the modal if token not available from the email link
    }
  }
  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);

    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }

    return params;
  };

  handleNavClick = component => () => {
    const {
      updateAccountPageComponent,
      getOrderList,
      getPartnerProducts,
      getPartnerDeliveryProducts,
      getPartnerBidProducts,
      getPartnerAddress,
      resetLoadMore,
      getCounterOffers,
    } = this.props;

    if (component === "productlist") {
      getPartnerProducts();
      getPartnerAddress();
    } else if (component === "bidlist") {
      getPartnerBidProducts();
    } else if (component === "orders") {
      getOrderList();
    } else if (component === "partneraddress") {
      getPartnerAddress();
    } else if (component === "counteroffers") {
      getCounterOffers();
    } else if (component === "productdeliverylist") {
      getPartnerDeliveryProducts();
    }

    updateAccountPageComponent(component);
    resetLoadMore(false, null);
  };


  renderNavigations = () => {
    const {
      account_page_active_component,
      t,
      direction,
      auction_notification_count,
      userDetails
    } = this.props;

    // getting listed auction  object
    let listed_count = auction_notification_count
      ? auction_notification_count.listed_items
      : null;

    // getting received_offer auction  object
    let received_offer_count = auction_notification_count
      ? auction_notification_count.receive_offer
      : null;

    // getting counter_offer auction  object
    let counter_offer_count = auction_notification_count
      ? auction_notification_count.counter_offer
      : null;

    // Count for delivery items
    const deliverey_items_count =
      auction_notification_count && auction_notification_count.delivery_items
        ? auction_notification_count.delivery_items
        : null;
    
    // Count for payment detail
    const payment_items_count =
    auction_notification_count && auction_notification_count.payment_items
      ? auction_notification_count.payment_items
      : null;
    
    // Count for order
    const order_items_count =
    auction_notification_count && auction_notification_count.order_items
      ? auction_notification_count.order_items
      : null;

    return (
      <List component="nav" className="py-0">
        <ListItem
          button
          selected={account_page_active_component === "settings"}
          onClick={this.handleNavClick("settings")}
        >
          <ListItemText
            className={direction === "rtl" ? "profile-Align p8" : "p8"}
            primary={t("profileModal.account")}
          />
          {payment_items_count && payment_items_count.count > 0  ? (
            <Badge
              className={direction === "rtl" ? "count_left" : "count_right"}
              badgeContent={payment_items_count.count}
              classes={{
                badge: "count_badge"
              }}
            >
              <div />
            </Badge>
          ) : null}
        </ListItem>
        <ListItem
          button
          selected={account_page_active_component === "orders"}
          onClick={this.handleNavClick("orders")}
        >
          <ListItemText
            className={direction === "rtl" ? "profile-Align p8" : "p8"}
            primary={t("profileModal.order")}
          />
          {order_items_count && order_items_count.count > 0 ? (
            <Badge
              className={direction === "rtl" ? "count_left" : "count_right"}
              badgeContent={order_items_count.count}
              classes={{
                badge: "count_badge"
              }}
            >
              <div />
            </Badge>
          ) : null}
        </ListItem>
        <ListItem
          button
          selected={
            account_page_active_component === "productlist" ||
            account_page_active_component === "productdetail"
          }
          onClick={this.handleNavClick("productlist")}
        >
          <ListItemText
            className={direction === "rtl" ? "profile-Align p8" : "p8"}
            primary={t("profileModal.product")}
          />
          {listed_count && listed_count.count > 0 ? (
            <Badge
              className={direction === "rtl" ? "count_left" : "count_right"}
              badgeContent={listed_count.count}
              classes={{
                badge: "count_badge"
              }}
            >
              <div />
            </Badge>
          ) : null}
        </ListItem>
        <ListItem
          button
          selected={
            account_page_active_component === "bidlist" ||
            account_page_active_component === "biddetail"
          }
          onClick={this.handleNavClick("bidlist")}
        >
          <ListItemText
            className={direction === "rtl" ? "profile-Align p8" : "p8"}
            primary={t("profileModal.offer")}
          />
          {received_offer_count && received_offer_count.count > 0 ? (
            <Badge
              className={direction === "rtl" ? "count_left" : "count_right"}
              badgeContent={received_offer_count.count}
              classes={{
                badge: "count_badge"
              }}
            >
              <div />
            </Badge>
          ) : null}
        </ListItem>
        <ListItem
          button
          selected={
            account_page_active_component === "counteroffers" ||
            account_page_active_component === "counterofferdetail"
          }
          onClick={this.handleNavClick("counteroffers")}
        >
          <ListItemText
            className={direction === "rtl" ? "profile-Align p8" : "p8"}
            primary={t("profileModal.counteroffers")}
          />
          {counter_offer_count && counter_offer_count.count > 0 ? (
            <Badge
              className={direction === "rtl" ? "count_left" : "count_right"}
              badgeContent={counter_offer_count.count}
              classes={{
                badge: "count_badge"
              }}
            >
              <div />
            </Badge>
          ) : null}
        </ListItem>
        <ListItem
          button
          selected={account_page_active_component === "partneraddress"}
          onClick={this.handleNavClick("partneraddress")}
        >
          <ListItemText
            className={direction === "rtl" ? "profile-Align p8" : "p8"}
            primary={t("profileModal.address")}
          />
        </ListItem>
        {userDetails && userDetails.has_delivery ?
        <ListItem
          button
          selected={
            account_page_active_component === "productdeliverylist" ||
            account_page_active_component === "productdeliverydetail"
          }
          onClick={this.handleNavClick("productdeliverylist")}
        >
          <ListItemText
            className={direction === "rtl" ? "profile-Align p8" : "p8"}
            primary={t("profileModal.delivery_products")}
          />
          {deliverey_items_count && deliverey_items_count.count > 0 ? (
            <Badge
              className={direction === "rtl" ? "count_left" : "count_right"}
              badgeContent={deliverey_items_count.count}
              classes={{
                badge: "count_badge"
              }}
            >
              <div />
            </Badge>
          ) : null}
        </ListItem>
        : null}
        {userDetails && userDetails.is_vendor_type === "COMPANY" && userDetails.company_approved=== true&&  <ListItem
          button
          selected={account_page_active_component === "productRequests" || account_page_active_component === "requestProducts" ||account_page_active_component === "productReqDetail"}
          onClick={this.handleNavClick("productRequests")}
        >
          <ListItemText
            className={direction === "rtl" ? "profile-Align p8" : "p8"}
            primary={t("requestProducts.reqProd")}
          />
        </ListItem>}
      </List>
    );
  };

  renderActiveComponent = () => {
    const { account_page_active_component } = this.props;
    switch (account_page_active_component) {
      case "orders":
        return <Orders />;
      case "orderdetail":
        return <OrderDetail />;
      case "productlist":
        return <ProductListing />;
      case "productdeliverylist":
        return <ProductDeliveryList />;
      case "productdetail":
        return <ProductDetail />;
      case "productdeliverydetail":
        return <ProductDeliveryDetail/>;
      case "bidlist":
        return <ProductBidListing />;
      case "biddetail":
        return <BidDetail />;
      case "partneraddress":
        return <PartnerAddress />;
      case "counteroffers":
        return <CounterOfferList />;
      case "counterofferdetail":
        return <CounterOfferDetail />;
      case "productRequests":
        return <ProductRequests />;
      case "requestProducts":
        return <RequestProducts />;
      case "productReqDetail":
        return <ProductReqDetail />;
      default:
        return <AccountSettings />;
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      closeModal,
      userDetails,
      t,
      direction,
      product_detail_fetching,
      cart_fetching,
      vendor_fetching,
      address_fetching,
      account_fetching
    } = this.props;
    return (
      <div>
        {cart_fetching ||
        product_detail_fetching ||
        vendor_fetching ||
        account_fetching ||
        address_fetching ? (
          <Hidden mdUp implementation="css">
            <Loader />
          </Hidden>
        ) : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classNames(
            classes.modalHeader,
            classes.profileHeader,
            "account_header"
          )}
        >
          <GridContainer>
            <IconButton
              simple="true"
              className={classNames(
                classes.modalCloseButton,
                "previewClose",
                direction === "rtl" ? classes.modalSmallCloseButtonAR : null
              )}
              key="close"
              aria-label="Close"
              onClick={closeModal}
            >
              {" "}
              <Close
                className={classNames(classes.modalClose, classes.closeStyle)}
              />
            </IconButton>
          </GridContainer>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classNames(classes.modalBody, "pt-0 px-0")}
        >
          <GridContainer>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              className={classNames(
                classes.marginAuto,
                classes.profileTabGrid,
                "px-4",
                "py-4"
              )}
            >
              <h4 className="text-uppercase">{t("profileModal.title")}</h4>
            </GridItem>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classNames(classes.marginAuto, "dashboard")}
            >
              <h6 className="profile-name">
                {userDetails && userDetails.first_name}{" "}
                {userDetails && userDetails.last_name}
              </h6>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              md={4}
              className={classNames(
                classes.marginAuto + " p-0 ht-secondary-light"
              )}
            >
              <div className={classes.profileTabGrid + " full-height"}>
                <Divider />
                {this.renderNavigations()}
              </div>
            </GridItem>
            <GridItem xs={12} md={8} className={classes.marginAuto}>
              <div className={"full-height"}>
                {this.renderActiveComponent()}
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getOrderList: () => {
    dispatch({
      type: CartActionTypes.GET_ORDERS_REQUEST
    });
  },
  orderDetailVariable: value => {
    dispatch({
      type: CartActionTypes.UPDATE_ORDER_DETAIL_VARIABLE,
      payload: value
    });
  },
  productDetailVariable: value => {
    dispatch({
      type: VendorProductActionTypes.UPDATE_PRODUCT_DETAIL_VARIABLE,
      payload: value
    });
  },
  getPartnerProducts: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_LIST_REQUEST
    });
  },
  getPartnerDeliveryProducts: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DELIVERY_LIST_REQUEST
    });
  },
  getPartnerBidProducts: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST
    });
  },
  getPartnerAddress: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_ADDRESS_REQUEST
    });
  },
  // updata redux
  updateAddress: data => {
    dispatch({
      type: AddressActionTypes.GET_ADDRESS_SUCCESS,
      data
    });
  },
  updateProductList: data => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_LIST_SUCCESS,
      data
    });
  },
  updateBidList: data => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_LIST_SUCCESS,
      data
    });
  },
  updateOrderList: data => {
    dispatch({
      type: CartActionTypes.GET_ORDERS_SUCCESS,
      data
    });
  },
  // Update account page component
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  resetLoadMore: (payload, currentFetch) => {
    dispatch({
      type: VendorProductActionTypes.SHOW_LOADER,
      payload,
      currentFetch
    });
  },
  getCounterOffers: () => {
    dispatch({
      type: VendorProductActionTypes.GET_COUNTER_OFFER_LIST_REQUEST
    });
  },
  //getting pending auction notification count
  getActionNotificationCount: () => {
    dispatch({
      type: VendorProductActionTypes.GET_ACTION_COUNT_REQUEST
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.addressReducer,
  ...state.vendorProductReducer,
  ...state.cartReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(ProfileModal);
