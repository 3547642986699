import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAILED: null,

  PERSONALSIGNUP_REQUEST: null,
  PERSONALSIGNUP_SUCCESS: null,
  PERSONALSIGNUP_FAILED: null,

  COMPANY_SIGNUP_REQUEST: null,
  COMPANY_SIGNUP_SUCCESS: null,
  COMPANY_SIGNUP_FAILED: null,

  FORGOTPASSWORD_REQUEST: null,
  FORGOTPASSWORD_SUCCESS: null,
  FORGOTPASSWORD_FAILED: null,

  PASSWORD_RESET_REQUEST: null,
  PASSWORD_RESET_SUCCESS: null,
  PASSWORD_RESET_FAILED: null,

  CHANGE_PASSWORD_REQUEST: null,
  CHANGE_PASSWORD_SUCCESS: null,
  CHANGE_PASSWORD_FAILED: null,

  SIGNOUT_REQUEST: null,
  SIGNOUT_SUCCESS: null,
  SIGNOUT_FAILED: null,

  UPDATE_COMPANY_SIGNUP_DATA: null,
  UPDATE_COMPANY_STEP: null,
  ACCOUNT_RESET: null,

  VERIFY_TOKEN_REQUEST: null,
  VERIFY_TOKEN_SUCCESS: null,
  VERIFY_TOKEN_FAILED: null,

  CONFIRM_EMAIL_REQUEST: null,
  CONFIRM_EMAIL_SUCCESS: null,
  CONFIRM_EMAIL_FAILED: null,

  GET_PAYMENT_INFO_REQUEST: null,
  GET_PAYMENT_INFO_SUCCESS: null,
  GET_PAYMENT_INFO_FAILED: null,

  POST_PAYMENT_INFO_REQUEST: null,
  POST_PAYMENT_INFO_SUCCESS: null,
  POST_PAYMENT_INFO_FAILED: null,

  RESEND_EMAIL_CONFIRMATION_REQUEST: null,
  RESEND_EMAIL_CONFIRMATION_SUCCESS: null,
  RESEND_EMAIL_CONFIRMATION_FAILED: null,

  RESEND_PHONE_CONFIRMATION_REQUEST: null,
  RESEND_PHONE_CONFIRMATION_SUCCESS: null,
  RESEND_PHONE_CONFIRMATION_FAILED: null,
  RESEND_PHONE_CONFIRMATION_FETCHING: null, 
  PATCH_PHONE_REQUEST: null,
  PATCH_PHONE_SUCCESS: null,
  PATCH_PHONE_FAILED: null,

  VERIFY_PHONE_CODE_REQUEST: null,
  VERIFY_PHONE_CODE_SUCCESS: null,
  VERIFY_PHONE_CODE_FAILED: null,

  USER_DETAIL_REQUEST: null,
  USER_DETAIL_SUCCESS: null,
  USER_DETAIL_FAILED: null,

  EDIT_USER_DETAIL_REQUEST: null,
  EDIT_USER_DETAIL_SUCCESS: null,
  EDIT_USER_DETAIL_FAILED: null,

  SOCIAL_LOGIN_REQUEST: null,
  SOCIAL_LOGIN_SUCCESS: null,
  SOCIAL_LOGIN_FAILED: null,

  SHOW_NEXT_MODAL: null,
  UPDATE_ACCOUNT_PAGE_COMPONENT: null,
  UPDATE_NEXT_PAGE_TO_GO: null,
  UPDATE_EMAIL_MODAL_STATUS: null,
  UPDATE_TIMER: null,
  SHOW_CODE_INPUT: null,
  REQ_PROD_LIST_UPDATE : null,
  REQ_PROD_DETAIL_UPDATE : null,
  RESET_TIMER : null,
  ACCOUNT_FETCHING: null,

  PUT_PAYMENT_INFO_REQUEST: null,
  PUT_PAYMENT_INFO_SUCCESS: null,
  PUT_PAYMENT_INFO_FAILED: null,

  BUSINESS_ACCOUNT_REQUEST: null,
  BUSINESS_ACCOUNT_SUCCESS: null,
  BUSINESS_ACCOUNT_FAILED: null

});

export default ActionTypes;
