import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// React i18next
import { withTranslation } from "react-i18next";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import api from "utils/api.jsx";
import queryString from "query-string";
import Loader from "kayfik/components/Loader/loader.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Muted from "components/Typography/Muted.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import ComboProductActionTypes from "constants/comboProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import IconButton from "@material-ui/core/IconButton";

class ReplacementProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goBack: true
    };
  }
  componentDidMount() {
    const { getReplacementProduct } = this.props;

    let params = this.getExtraParameter();
    if (params.orderLine && params.productId) {
      getReplacementProduct(
        api.root +
          "partner/product/" +
          params.productId +
          "/" +
          params.orderLine +
          "/"
      );
    }
    this.props.getTimeWindows("pickup");
  }

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);

    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }

    return params;
  };

  renderAttributes = () => {
    const { classes, replacement_product } = this.props;
    if (replacement_product) {
      const p = replacement_product.parent_attributes;
      var attributes = [];
      for (var key in p) {
        if (p[key]) {
          attributes.push(
            <div key={key}>
              <Muted>
                <h6>
                  <span className={classes.label}> {key} : </span> {p[key]}
                </h6>
              </Muted>
            </div>
          );
        }
      }
      return attributes;
    } else {
      return null;
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      postActionClicked,
      replacement_product,
      pickup_time,
      t,
      showSnackbar,
      partner_address,
      updateAddressEvent,
      showModal
    } = this.props;

    if (replacement_product) {
      if (pickup_time) {
        let pickup_id = pickup_time.id;
        let url = replacement_product.accept_url;
        url = url + "?pickuptime=" + pickup_id;
        if (
          !partner_address ||
          Object.keys(partner_address.address).length === 0
        ) {
          const modalProps = {
            title: "mapModal",
            open: true,
            address: "pickup",
            confirm_pickup_data: {
              payload: url
            }
          };
          const modalType = "mapModal";
          let event = "loggedin_partner";
          showSnackbar(t("companySignup.companyDetailOne.fillup"), "warning");
          showModal(modalProps, modalType);
          updateAddressEvent(event);
        } else {
          postActionClicked(url);
        }
      } else {
        let txt = t(
          "profileModal.accountSettings.shippingAddress.productDetailPage.pickupTimewarning"
        );
        showSnackbar(txt, "warning");
        return;
      }
    }
  };
  handlePickupChange = value => {
    const { update_pickup_time } = this.props;
    update_pickup_time(value);
  };
  productDetail = () => {
    const {
      classes,
      replacement_product,
      t,
      pickup_time,
      timeWindows,
      vendor_error
    } = this.props;

    if (replacement_product !== null) {
      return (
        <Card bordered className="cardOverflowVisible">
          <CardBody>
            <div className="d-flex justify-content-center">
              {replacement_product.images ? (
                <div className={classes.bidDetailContainer}>
                  <img
                    src={replacement_product.images[0].original}
                    className={classes.img}
                    alt={replacement_product.title}
                  />
                </div>
              ) : null}
            </div>
            <h5>{replacement_product.title}</h5>
            <h5 className="mb-1">
              {t(
                "profileModal.accountSettings.shippingAddress.replacementProduct.offer"
              )}
              : {replacement_product.price.currency}{" "}
              {replacement_product.offered_amount}
            </h5>
            <h5 className="mb-1">
              <Muted>
                {t(
                  "profileModal.accountSettings.shippingAddress.replacementProduct.current"
                )}
                : {replacement_product.price.currency}{" "}
                {replacement_product.price.incl_tax}
              </Muted>
            </h5>
            {this.renderAttributes()}
            <Muted>
              <p>{replacement_product.description}</p>
            </Muted>
            <GridContainer>
              <GridItem xs={12} className="py-3">
                <SelectAutocomplete
                  maxMenuHeight={145}
                  inputId="select pickuptime"
                  value={pickup_time ? pickup_time : ""}
                  inputProps={{
                    required: true,
                    name: "pickuptime"
                  }}
                  placeholder={t(
                    "profileModal.accountSettings.shippingAddress.productDetailPage.select"
                  )}
                  labelText={t(
                    "profileModal.accountSettings.shippingAddress.productDetailPage.pickup_time"
                  )}
                  controlProps={{ marginBottom: "15px" }}
                  autocompleteOptions={timeWindows ? timeWindows : []}
                  selectOnChange={this.handlePickupChange}
                  getOptionLabel={({ timefrom, timeto, name }) =>
                    timefrom && timeto ? timefrom + " - " + timeto : name
                  }
                />
              </GridItem>
            </GridContainer>
            <Button
              color="primaryWhiteWithBorder"
              fullWidth
              size="lg"
              type="submit"
            >
              {t(
                "profileModal.accountSettings.shippingAddress.replacementProduct.accept"
              )}
            </Button>
          </CardBody>
        </Card>
      );
    } else if (vendor_error && vendor_error.response.status === 403) {
      if (vendor_error.response.data.detail) {
        return <p> {vendor_error.response.data.detail}</p>;
      }
    } else {
      return (
        <p>
          {" "}
          {t(
            "profileModal.accountSettings.shippingAddress.replacementProduct.data"
          )}
        </p>
      );
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      closeModal,
      vendor_fetching,
      replacement_product,
      direction,
      t
    } = this.props;
    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        {vendor_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classNames(classes.modalBody)}
        >
          <GridContainer className="mb-3">
            <GridItem xs={11} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classes.modalTitle}>
                {t(
                  "profileModal.accountSettings.shippingAddress.replacementProduct.productOffer"
                )}
              </h2>
              {replacement_product && (
                <h4 className="m-0 mb-3">
                  {t(
                    "profileModal.accountSettings.shippingAddress.replacementProduct.sell"
                  )}{" "}
                  {replacement_product.price.currency}{" "}
                  {replacement_product.offered_amount}
                </h4>
              )}
            </GridItem>
          </GridContainer>
          {this.productDetail()}
        </DialogContent>
      </form>
    );
  }
}

ReplacementProductModal.propTypes = {
  classes: PropTypes.object,
  description_en: PropTypes.string,
  description_ar: PropTypes.string,
  is_bid_price: PropTypes.string,
  is_buy_price: PropTypes.string,
  // t: PropTypes.func,
  product_images: PropTypes.array,
  selected_attr_and_value: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
  getReplacementProduct: url => {
    dispatch({
      type: VendorProductActionTypes.GET_REPLACEMENT_PRODUCT_REQUEST,
      payload: url
    });
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  postActionClicked: url => {
    dispatch({
      type: VendorProductActionTypes.POST_PICKUP_ACTION_REQUEST,
      payload: url
    });
  },
  update_pickup_time: data => {
    dispatch({
      type: ComboProductActionTypes.UPDATE_PICKUP_TIME,
      data
    });
  },
  showSnackbar: (txt, variant = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  getTimeWindows: window => {
    dispatch({
      type: GlobalActionTypes.GET_TIMEWINDOW_REQUEST,
      window
    });
  },
  updateAddressEvent: event => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT,
      event
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.vendorProductReducer,
  ...state.globalReducer,
  ...state.comboProductReducer
});

export default compose(
  withStyles(modalStyle),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReplacementProductModal);
