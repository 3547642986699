import React from "react";
import api from "utils/api.jsx";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Muted from "components/Typography/Muted.jsx";
import queryString from "query-string";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import {
  postActionNotificationClicked,
  showStatusMessage
} from "utils/helpers.jsx";
import Badge from "components/Badge/Badge.jsx";

class ProductDetail extends React.Component {
  componentDidMount() {
    this.renderSpecificProduct();
    // this.props.getTimeWindows("pickup");
  }

  renderSpecificProduct = () => {
    let params = this.getExtraParameter();
    if (params.productId) {
      this.showProductDetail(params.productId)();
    }
  };
  deleteItem = deleteUrl => {
    const { deletePartnerProduct, t } = this.props;
    if (
      window.confirm(
        t(
          "profileModal.accountSettings.shippingAddress.productDetailPage.deleteItem"
        )
      )
    ) {
      deletePartnerProduct(deleteUrl);
    }
  };

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);

    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }
    return params;
  };

  showProductDetail = id => () => {
    const { getProductDetail } = this.props;
    const detailUrl = api.root + "partner/product/todeliver/" + id + "/";
    getProductDetail(detailUrl);
  };
  renderBack = () => {
    const { t, direction } = this.props;

    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="image_back" />
          {t("profileModal.products.backtoList")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="image_back" />
          {t("profileModal.products.backtoList")}
        </div>
      );
    }
  };

  handlePickupChange = value => {
    const { update_pickup_time } = this.props;
    update_pickup_time(value);
  };

  handleSubmit = () => {
    const { selected_new_delivery_status, partner_product_delivery_detail, updateProductDetail, t, showSnackbar, update_pickup_time } = this.props;
    let formData = new FormData();
    formData.append('new_status', selected_new_delivery_status.id);
    
    api.postFunction(partner_product_delivery_detail.url, formData)
    .then(res => {
      let data = res.data;
      updateProductDetail(data);
      this.setState({ fetching: false });
      update_pickup_time(null);
      showSnackbar(t("profileModal.new_status_updated"), "success");

    })
    .catch(e => {
      this.setState({ fetching: false });
      update_pickup_time(null);
      if (e.response && e.response.status >= 400 && e.response.status < 500) {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          showSnackbar(txt);
        }
      } else {
        let txt = t("common.error.network");
        showSnackbar(txt);
      }
    });

  }

  renderAction = () => {
    const { partner_product_delivery_detail, t, selected_new_delivery_status } = this.props;
    if (partner_product_delivery_detail) {
      return (
        <div>
          {partner_product_delivery_detail &&
          partner_product_delivery_detail.status_allowed.length > 0 ? (
            <div className="mt-3">
              <GridContainer>
                <GridItem xs={12}>
                  <SelectAutocomplete
                    maxMenuHeight={120}
                    inputId="new_status"
                    value={selected_new_delivery_status}
                    inputProps={{
                      required: true,
                      name: "new_status"
                    }}
                    menuPlacement = "top"
                    placeholder={t(
                      "profileModal.accountSettings.shippingAddress.productDetailPage.new_status_placeholder"
                    )}
                    labelText={t(
                      "profileModal.accountSettings.shippingAddress.productDetailPage.new_status_label"
                    )}
                    selectProps={{ inputId: "new_status" }}
                    controlProps={{ marginBottom: "15px" }}
                    autocompleteOptions={partner_product_delivery_detail.status_allowed}
                    selectOnChange={this.handlePickupChange}
                    getOptionLabel={({name}) => name}
                  />
                  <div className={"dp-f jcfe w-100"}>
                    <Button
                      color="primaryWhiteWithBorder"
                      size="sm"
                      onClick={this.handleSubmit}
                    >
                      {t(
                        "profileModal.accountSettings.shippingAddress.productDetailPage.new_status_submit"
                      )}
                  </Button>
                </div>
                </GridItem>
              </GridContainer>
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  partnerProductDetail = () => {
    const {
      classes,
      partner_product_delivery_detail,
      vendor_fetching,
      t,
      direction,
      updateAccountPageComponent,
      auction_notification_count
    } = this.props;
     let address = partner_product_delivery_detail.shipping_address;
    let fullAddress = address.address;

    // Count for delivery items
    const deliverey_items_count =
      auction_notification_count && auction_notification_count.delivery_items
        ? auction_notification_count.delivery_items
        : null;

    if (deliverey_items_count && deliverey_items_count.count > 0) {
      postActionNotificationClicked(deliverey_items_count.url_to_read);
    }
    let product_status = null;
    if (
      partner_product_delivery_detail &&
      partner_product_delivery_detail.status
    ) {

      let current_status = partner_product_delivery_detail.status;
      let cancelled_statuses = [
        'Cancelled','Init refund',
        'Refunded',
        'Refund requested to TAP',
        'Refund requested',
      ]
      if (cancelled_statuses.includes(current_status)) {
        current_status = 'Cancelled';
      }

      product_status = showStatusMessage(
        current_status,
        "seller"
      );
    }
    if (partner_product_delivery_detail !== null) {
      return (
        <div>
          <Button
            link
            size="lg"
            onClick={() => updateAccountPageComponent("productdeliverylist")}
            className={direction === "rtl" ? "pr-0" : "pl-0"}
          >
            {this.renderBack()}
          </Button>
          <Card
            bordered
            className={
              product_status && product_status.color
                ? "pointer cardOverflowVisible"
                : "pointer cardOverflowVisible"
            }
            style={
              product_status && product_status.color
                ? {
                    borderColor: product_status ? product_status.color : null
                  }
                : null
            }
          >
            {vendor_fetching ? (
              <Hidden smDown implementation="css">
                <Loader />
              </Hidden>
            ) : null}
            <CardBody>
              <div className="d-flex justify-content-center">
                {partner_product_delivery_detail.images ? (
                  <div className={classes.bidDetailContainer}>
                    <img
                      src={
                        partner_product_delivery_detail &&
                        partner_product_delivery_detail.images.length &&
                        partner_product_delivery_detail.images[0].url
                      }
                      className={classes.img}
                      alt={partner_product_delivery_detail.product}
                    />
                  </div>
                ) : null}
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <h5>{partner_product_delivery_detail.product}</h5>
                </div>
                <div>
                  {product_status && product_status.message ? (
                    <Badge
                      classes={{
                        badge: "productBadge font11 p-0 status-badge"
                      }}
                    >
                      <span
                        style={{ backgroundColor: product_status.color }}
                        className="order_status"
                      >
                        {product_status.message}
                      </span>
                    </Badge>
                  ) : null}
                </div>
              </div>
              <h5 className="mb-1">
                <Muted>
                  {partner_product_delivery_detail.currency}{" "}
                  {partner_product_delivery_detail.price}
                </Muted>
              </h5>
             
              {address ? (
                <div>
                  <div className="d-flex justify-content-between">
                    <h5 className="m-0">{t("profileModal.customer_address")}</h5>
                  </div>
               
                  {address.first_name ? (
                    <h5 className="mb-1">
                      <Muted>
                        {t("common.firstname")} {address.first_name}
                      </Muted>
                    </h5>
                  ) : null}
                  {address.last_name ? (
                    <h5 className=" mt-0 mb-1">
                      <Muted>
                        {t("common.lastname")} {address.last_name}
                      </Muted>
                    </h5>
                  ) : null}
                  {fullAddress.area ? (
                    <h5 className="mt-0 mb-1">
                      <Muted>
                        {t("common.area")} {fullAddress.area}
                      </Muted>
                    </h5>
                  ) : null}
                  {fullAddress.block ? (
                    <h5 className="mt-0 mb-1">
                      <Muted>
                        {t("common.block")} {fullAddress.block}
                      </Muted>
                    </h5>
                  ) : null}
                  {fullAddress.avenue ? (
                    <h5 className="mt-0 mb-1">
                      <Muted>
                        {t("common.avenue")} {fullAddress.avenue}
                      </Muted>
                    </h5>
                  ) : null}
                  {fullAddress.street ? (
                    <h5 className="mt-0 mb-1">
                      <Muted>
                        {t("common.street")} {fullAddress.street}
                      </Muted>
                    </h5>
                  ) : null}
                  {fullAddress.building ? (
                    <h5 className="mt-0 mb-1">
                      <Muted>
                        {t("common.house")} {fullAddress.building}
                      </Muted>
                    </h5>
                  ) : null}
                  {fullAddress.floor ? (
                    <h5 className="mt-0 mb-1">
                      <Muted>
                        {t("common.floor")} {fullAddress.floor}
                      </Muted>
                    </h5>
                  ) : null}
                  {fullAddress.unit ? (
                    <h5 className="mt-0 mb-1">
                      <Muted>
                        {t("common.apartment")} {fullAddress.unit}
                      </Muted>
                    </h5>
                  ) : null}
                </div>
              ) : null}
              {partner_product_delivery_detail.pickup_time ?
                <div>
                  <h5 className="mt-3">{t("profileModal.delivery_time")}</h5>
                  <p>{partner_product_delivery_detail.pickup_time}</p>
                </div>
              :null }
              {this.renderAction()}
            </CardBody>
          </Card>
        </div>
      );
    } else {
      return (
        <div>
          {vendor_fetching ? (
            <Hidden smDown implementation="css">
              <Loader />
            </Hidden>
          ) : null}
          <p>
            {t(
              "profileModal.accountSettings.shippingAddress.productDetailPage.data"
            )}
          </p>
        </div>
      );
    }
  };

  render() {
    return this.partnerProductDetail();
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getProductDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DELIVERY_DETAIL_REQUEST,
      payload: url
    });
  },
  updateProductDetail: data => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DELIVERY_DETAIL_SUCCESS,
      data
    });
  },
  postActionClicked: (url, detailUrl) => {
    dispatch({
      type: VendorProductActionTypes.POST_PICKUP_ACTION_REQUEST,
      payload: url,
      detailUrl: detailUrl
    });
  },
  deletePartnerProduct: url => {
    dispatch({
      type: VendorProductActionTypes.DELETE_PARTNER_PRODUCT_REQUEST,
      url
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  update_pickup_time: data => {
    dispatch({
      type: VendorProductActionTypes.UPDATE_SELECTED_NEW_DELIVERY_STATUS,
      data
    });
  },
  showSnackbar: (txt, variant = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  getTimeWindows: window => {
    dispatch({
      type: GlobalActionTypes.GET_TIMEWINDOW_REQUEST,
      window
    });
  },
  updateAddressEvent: event => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT,
      event
    });
  },
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer,
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.comboProductReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductDetail);
