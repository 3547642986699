import api from "utils/api.jsx";

const postCreateBid = data => {
  return api.postFunction(data.url, data);
};

const getBidHistory = () => {
  let url = api.create_bid_history();
  return api.getFunction(url);
};

export { postCreateBid, getBidHistory };
