import { call, put } from "redux-saga/effects";
import i18n from "locales/i18n.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import ActionTypes from "constants/cartConstants.jsx";
import {
  getReviewCheckout,
  getReviewCheckoutRecall,
  postAddToCart,
  deleteItem,
  updateCart,
  getOrders,
  postCheckout,
  postPayment,
  getOrderDetail,
  postVocher,
  deleteVoucher
} from "actions/cartActions.jsx";
import { store } from "utils/store.jsx";
//actions
import CartActionTypes from "constants/cartConstants.jsx";
import { resetUnauthorized } from "utils/helpers.jsx";
import * as Sentry from '@sentry/browser';

function* getReviewCheckoutWorker(params) {
  const { recall, code } = params;
  // let basket_number = store.getState().cartReducer.basket_id;
  try {
    let response = [];
    if (recall) {
      response = yield call(getReviewCheckoutRecall, recall);
      let data = null;
      yield put({ type: ActionTypes.UPDATE_BASKET_ID_SUCCESS, data });
    } else {
      response = yield call(getReviewCheckout, code);
    }
    let data = response.data;
    yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_SUCCESS, data });
  } catch (e) {
    let error = e;
    if (e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("unauthorizedAccess"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    else {
      Sentry.captureException(e);
    }
    yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_FAILED, error });
  }
}

function* getOrderListWorker() {
  try {
    let response = yield call(getOrders);
    let data = response.data;
    yield put({ type: ActionTypes.GET_ORDERS_SUCCESS, data });
  } catch (e) {

    let error = e;
    if (e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("unauthorizedAccess"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.GET_ORDERS_FAILED, error });
  }
}

function* getOrderDetailWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(getOrderDetail, payload);
    let data = response.data;
    let reqNumber = store.getState().cartReducer.reqNumber;
    let basket_number = store.getState().cartReducer.basket_id;
    reqNumber = reqNumber + 1;
    let ordstatus = data.status;
    if (basket_number && (ordstatus === "success" || ordstatus === "failed")) {
      if(ordstatus === "failed"){
        yield put({
          type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST,
          recall: basket_number,
        });
      }
      else{
        yield put({
          type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST,
          recall: null,
        });
        let data = null;
        yield put({ type: ActionTypes.UPDATE_BASKET_ID_SUCCESS, data });
      }

    }

    if (data && data.status === "pending" && reqNumber < 10) {
      yield put({
        type: ActionTypes.UPDATE_ORDERS_DETAILS_REQUEST_NUMBER,
        reqNumber
      });
      yield put({ type: ActionTypes.GET_ORDER_DETAILS_REQUEST, payload });
    } else {
      yield put({ type: ActionTypes.GET_ORDER_DETAILS_SUCCESS, data });
      yield put({ type: ActionTypes.UPDATE_ORDER_LIST_DETAIL, data });
    }
  } catch (e) {
    console.log("e",e)
    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.GET_ORDER_DETAILS_FAILED, error });
  }
}

function* postAddToCartWorker(action) {
  const { payload, success_modal } = action;
  try {
    let response = yield call(postAddToCart, payload);
    let data = response.data;
    yield put({ type: ActionTypes.ADD_TO_CART_SUCCESS, data });
    yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
    yield put({ type: ModalActionTypes.HIDE_MODAL_SUCCESS });
    if (success_modal) {
      let modalType = success_modal.success_modal_type;
      let modalProps = success_modal.success_modalProps;
      yield put({
        type: ModalActionTypes.SHOW_MODAL_REQUEST,
        modalProps,
        modalType
      });
    }
  } catch (e) {

    let error = e;
    if (e.response && e.response.status === 406) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }

    yield put({ type: ActionTypes.ADD_TO_CART_FAILED, error });
  }
}

function* postCheckoutWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(postCheckout, payload);
    let data = response.data;
    yield put({ type: ActionTypes.POST_CHECKOUT_SUCCESS, data });

    // Call the payment url from this point
    let url = data.payment_url;
    yield put({ type: ActionTypes.POST_PAYMENT_URL_REQUEST, url });
    // yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else if (e.response.status === 403) {
        yield put({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
        let messageError = e.response.data.detail;
        let modalType = "verifyEmailModal";
        if (messageError.indexOf("Phone") !== -1) {
          modalType = "verifyPhoneModal";
        }
        let modalProps = {
          open: true,
          title: modalType,
          verifyFrom: "checkout",
          activeCheckout: true
        };
        yield put({
          type: ModalActionTypes.SHOW_MODAL_REQUEST,
          modalProps,
          modalType
        });
      } else {
        const res_object = e.response.data;

        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.POST_CHECKOUT_FAILED, error });
    yield put({ type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
  }
}

function* postPaymentWorker(params) {
  const { url } = params;

  try {
    let response = yield call(postPayment, url);
    // Open window from the url api
    let data = response.data;
    window.open(data.url, "_self");
    yield put({ type: ActionTypes.POST_PAYMENT_URL_SUCCESS, data });
  } catch (e) {
    //Sentry.captureException(e);
    // let error = e;
    if (e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("unauthorizedAccess"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    } else if (e.response && e.response.status === 400) {
      const res_object = e.response.data;
      let messages = [];

      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.POST_PAYMENT_URL_FAILED, e });
  }
}

function* deleteItemWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(deleteItem, payload);
    let data = response.data;
    yield put({ type: ActionTypes.DELETE_ITEM_SUCCESS, data });
    let txt = i18n.t("buyingPage.checkOut.reviewcart.itemdeleted");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
    yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
  } catch (e) {

    let error = e;
    if (e.response && e.response.status >= 400 && e.response.status < 500) {
      if (e.response.status === 401) {
        resetUnauthorized();
        yield put(
          enqueueSnackbar({
            message: i18n.t("unauthorizedAccess"),
            options: {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      } else if (e.response.status === 404) {
        let txt = i18n.t("buyingPage.checkOut.reviewcart.itemdeleted");
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
        yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
      } else {
        const res_object = e.response.data;
        let messages = [];
        for (let i in res_object) {
          let error_val = res_object[i];
          if (error_val) {
            messages.push(error_val);
          }
        }
        for (let i = 0; i < messages.length; i++) {
          let txt = messages[i];
          yield put(
            enqueueSnackbar({
              message: txt,
              options: {
                variant: "warning",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center"
                }
              }
            })
          );
        }
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.DELETE_ITEM_FAILED, error });
  }
}

function* updateCartWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(updateCart, payload);
    let data = response.data;
    yield put({ type: ActionTypes.UPDATE_CART_SUCCESS, data });
    yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
  } catch (e) {
    let error = e;
    if (e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("unauthorizedAccess"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    } else if (e.response && e.response.status === 403) {
      const res_object = e.response.data;
      let messages = [];
      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.UPDATE_CART_FAILED, error });
  }
}

//For Coupon code Post function
function* PostPromoCodeWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(postVocher, payload);
    let data = response.data;
    yield put({ type: ActionTypes.POST_VOUCHER_SUCCESS, data });
    yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
    //Promo code applied successfully message
    let txt = i18n.t("buyingPage.checkOut.customerInformation.couponSuccess");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {
    let error = e;
    if (e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("unauthorizedAccess"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    } else if (e.response && e.response.status === 406) {
      const res_object = e.response.data;
      let messages = [];
      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.POST_VOUCHER_FAILED, error });
  }
}

//For Coupon code Delete function
function* DeletePromoCodeWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(deleteVoucher, payload);
    let data = response.data;
    yield put({ type: ActionTypes.REMOVE_VOUCHER_SUCCESS, data });
    //update cart items
    yield put({ type: ActionTypes.GET_REVIEW_CHECKOUT_REQUEST });
    //voucher code removed successfully message
    let txt = i18n.t("buyingPage.checkOut.customerInformation.removed");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  } catch (e) {
    let error = e;
    if (e.response.status === 401) {
      resetUnauthorized();
      yield put(
        enqueueSnackbar({
          message: i18n.t("unauthorizedAccess"),
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    } else if (e.response && e.response.status === 406) {
      const res_object = e.response.data;
      let messages = [];
      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.REMOVE_VOUCHER_FAILED, error });
  }
}

export {
  getReviewCheckoutWorker,
  postAddToCartWorker,
  deleteItemWorker,
  updateCartWorker,
  getOrderListWorker,
  postCheckoutWorker,
  postPaymentWorker,
  getOrderDetailWorker,
  PostPromoCodeWorker,
  DeletePromoCodeWorker
};
