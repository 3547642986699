import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// React i18next
import { withTranslation } from "react-i18next";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Help from "@material-ui/icons/Help";
import FlagSelect from "kayfik/components/FlagSelect/FlagSelect.jsx";
import IconButton from "@material-ui/core/IconButton";

// api
import api from "utils/api.jsx";

// eslint-disable-next-line react/prop-types
class PersonalSignupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      stateDialCode: this.props.defaultCountryCode
    };
  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    const props = {
      open: true,
      title: modal_type
    };
    let activeSell = modalProps.activeSell;
    let activeCheckout = modalProps.activeCheckout;
    if (activeSell) {
      props["activeSell"] = activeSell;
    }
    if (activeCheckout) {
      props["activeCheckout"] = activeCheckout;
    }
    showModal(props, modal_type);
  };

  handleSubmit = e => {
    const { PersonalSignupRequest, deviceid } = this.props;
    e.preventDefault();

    let form_data = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      phone: this.state.phone,
      email: e.target.email.value,
      password1: e.target.password.value,
      password2: e.target.password1.value
    };

     // added device id so we can save it on the backend
     if (deviceid) {
      let url = api.personal_signup() + "?deviceid=" + deviceid;
      form_data.url = url;
    }

    PersonalSignupRequest(form_data);
  };
  handleOnChange = (value, data) => {
    let code = "+" + data.dialCode;
    if (value.indexOf(code) === 0) {
      this.setState({ phone: value });
    } else {
      this.setState({ phone: code });
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, t, account_fetching, direction } = this.props;
    return (
      <div>
        {account_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-3">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {" "}
                {t("personalSignupModal.title")}
              </h2>
              <h4 className="m-0 mb-3">{t("personalSignupModal.message")}</h4>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <CustomInput
                  labelText={t("personalSignupModal.firstName")}
                  id="id_first_name"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "first_name",
                    required: true,
                    type: "text",
                    disableUnderline: true
                  }}
                />
                <CustomInput
                  labelText={t("personalSignupModal.lastName")}
                  id="id_last_name"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "last_name",
                    required: true,
                    type: "text",
                    disableUnderline: true
                  }}
                />
                <CustomInput
                  labelText={t("personalSignupModal.email")}
                  id="id_email"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "email",
                    required: true,
                    type: "email",
                    disableUnderline: true
                  }}
                />
                <div className="dp-f alignItem">
                  <FlagSelect
                    value={this.state.phone}
                    handleFlagOnChange={this.handleOnChange}
                  />
                  <CustomInput
                    labelText={t("personalSignupModal.phone")}
                    id="id_phone"
                    size="lg"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "phone",
                      type: "number",
                      required: true,
                      disableUnderline: true,
                      disabled: true
                    }}
                  />
                </div>
                <CustomInput
                  labelText={t("personalSignupModal.password")}
                  labelHelpText={"ENter pass 0-9 a-z and A-Z"}
                  labelHelpIcon
                  id="id_password"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "password",
                    required: true,
                    type: "password",
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <Tooltip
                          title={t("personalSignupModal.helpText")}
                          placement="top"
                          enterTouchDelay={0}
                          classes={{
                            tooltip: classNames(classes.tooltip)
                          }}
                        >
                          <Help className={classes.inputAdornmentIcon} />
                        </Tooltip>{" "}
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText={t("personalSignupModal.confirmPassword")}
                  id="id_password1"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "password1",
                    required: true,
                    type: "password",
                    disableUnderline: true
                  }}
                />

                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  className="mb-3"
                  fullWidth
                  type="submit"
                >
                  {t("personalSignupModal.button")}
                </Button>
              </form>
              <p>
                {t("personalSignupModal.clicking")}{" "}
                <a
                  className="color-pl"
                  href={"/terms"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {t("personalSignupModal.termsandconditions")}
                </a>
              </p>
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer className="mb-5 mt-4">
            <GridItem
              xs={12}
              sm={10}
              md={8}
              className={classNames(
                classes.marginAuto,
                "d-flex" //flex-sm-column
              )}
            >
              <h4 className="m-0">
                {t("intialLoginModal.already")}{" "}
                <span
                  className="color-pl pointer"
                  onClick={this.openModal("loginModal")}
                >
                  {" "}
                  {t("intialLoginModal.login")}{" "}
                </span>
              </h4>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  PersonalSignupRequest: form_data => {
    dispatch({
      type: AccountActionTypes.PERSONALSIGNUP_REQUEST,
      form_data
    });
  }
});

PersonalSignupModal.propTypes = {
  PersonalSignupRequest: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(PersonalSignupModal);
