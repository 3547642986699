import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// custom core component
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import IconButton from "@material-ui/core/IconButton";

// api
import api from "utils/api.jsx";

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      email: "",
      password: ""
    };
  }

  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    const nextModal = modalProps.nextModal;
    if (modalProps.open) {
      await closeModal();
    }
    const props = {
      open: true,
      title: modal_type,
      nextModal: nextModal
    };
    let activeSell = modalProps.activeSell;
    let activeCheckout = modalProps.activeCheckout;
    if (activeSell) {
      props["activeSell"] = activeSell;
    }
    if (activeCheckout) {
      props["activeCheckout"] = activeCheckout;
    }
    showModal(props, modal_type);
  };

  handleToggle = () => {
    const { checked } = this.state;
    this.setState({
      checked: !checked
    });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = e => {
    const { loginRequest, deviceid } = this.props;
    e.preventDefault();

    let form_data = {
      email: e.target.email.value,
      password: e.target.password.value
    };
    // added device id so we can save it on the backend
    if (deviceid) {
      let url = api.login() + "?deviceid=" + deviceid;
      form_data.url = url;
    }

    loginRequest(form_data);
  };
  responseFacebook = response => {
    const { socialLogin, showSnackbar, t } = this.props;
    let raw_access_token = "";
    try {
      raw_access_token = response.accessToken;

      let form_data = {
        access_token: raw_access_token
      };

      socialLogin(form_data, "facebook");
    } catch (e) {
      let txt = t("loginModal.error");
      showSnackbar(txt, "error");
    }
  };

  successResponseGoogle = response => {
    const { socialLogin, showSnackbar, t } = this.props;
    let raw_access_token = "";
    try {
      raw_access_token = response.tokenObj.access_token;
      let form_data = {
        access_token: raw_access_token
      };
      socialLogin(form_data, "google");
    } catch (e) {
      let txt = t("loginModal.error");
      showSnackbar(txt, "error");
    }
  };

  failedResponseGoogle = response => {
    const { showSnackbar, t } = this.props;
    let txt = t("loginModal.error");
    showSnackbar(txt, "error");
  };

  render() {
    const { classes, closeModal, t, account_fetching, direction } = this.props;
    return (
      <div>
        {account_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {t("loginModal.welcome")}
              </h2>
              <h4 className="m-0 mb-3">{t("loginModal.signInBelow")}</h4>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <CustomInput
                  labelText={t("loginModal.email")}
                  id="id_email"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "email",
                    required: true,
                    type: "email",
                    // placeholder: t("loginModal.emailLabel"),
                    disableUnderline: true
                  }}
                />
                <CustomInput
                  labelText={t("loginModal.passWord")}
                  id="id_password"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "password",
                    required: true,
                    type: "password",
                    disableUnderline: true
                  }}
                />
                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  className="mb-3"
                  fullWidth
                  type="submit"
                >
                  {t("loginModal.login")}
                </Button>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                />
              </form>
              <Button
                color="primary"
                link
                className="underline text-transform-none p-0 m-0 color-pl"
                onClick={this.openModal("forgotPassword")}
              >
                {t("loginModal.forgetPassword")}
              </Button>
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer className="mb-5 mt-4">
            <GridItem
              xs={12}
              sm={10}
              md={8}
              className={classNames(
                classes.marginAuto,
                "d-flex" //flex-sm-column
              )}
            >
              <h4 className="m-0">
                {t("loginModal.create")}{" "}
                <span
                  className="color-pl pointer"
                  onClick={this.openModal("accountTypeModal")}
                >
                  {t("loginModal.signup")}
                </span>
              </h4>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  loginRequest: form_data => {
    dispatch({
      type: AccountActionTypes.LOGIN_REQUEST,
      form_data
    });
  },
  socialLogin: (form_data, social_type = "facebook") => {
    dispatch({
      type: AccountActionTypes.SOCIAL_LOGIN_REQUEST,
      form_data,
      social_type
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

LoginModal.propTypes = {
  loginRequest: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(LoginModal);
