import { store } from "utils/store.jsx";
import { successSnack } from "kayfik/assets/jss/universal.jsx";

const appStyle = theme => ({
  zIndexSnack: {
    zIndex: 5000,
    display: "flex !important",
    justifyContent: "space-between",
    flexWrap: " inherit !important",
    width: "600px",
    [theme.breakpoints.down("sm")]: {
      position: " absolute",
      top: "50px",
      width: "70%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%"
    },
    "& > div:nth-child(2)": {
      marginLeft: "unset !important"
    },
    "& svg": {
      marginRight:
        store.getState().globalReducer.direction === "ltr"
          ? "8px !important"
          : "0px !important",
      marginLeft:
        store.getState().globalReducer.direction === "ltr"
          ? "0px !important"
          : "8px !important"
    },
    button: {
      color: "red",
      "& svg": {
        marginRight: store.getState().globalReducer.direction === "ltr" ? 0 : 0,
        marginLeft: store.getState().globalReducer.direction === "ltr" ? 0 : 0
      }
    }
  },
  snackbarRootZ:{
    zIndex: 5000,
  },
  success: {
    background: successSnack
  }
});

export default appStyle;
