import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// nodejs library that concatenates classes

import scrollToTopStyle from "kayfik/assets/jss/components/ScrollToTop/scrollToTop.jsx";

class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActionIcon: false
    };
  }

  smoothScroll = () => {
    this.scrollGo(
      document.scrollingElement || document.documentElement,
      0,
      20
    ); //(0,1250) Scroll Speed previously 1250
  };
  scrollGo(element, to, duration) {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }.bind(this);
    animateScroll();
  }

  easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    if (window.pageYOffset > 250) {
      this.setState({ showActionIcon: true });
    } else {
      this.setState({ showActionIcon: false });
    }
  };

  render() {
    const { direction } = this.props;
    return this.state.showActionIcon ? (
      <Button
        round
        justIcon
        color="primaryYellow"
        size="lg"
        className={classNames(direction === "rtl" ? "arrowToTopAR" : "arrowToTop","scrollToTopTouch")}
        onClick={this.smoothScroll}
      >
        <KeyboardArrowUp />
      </Button>
    ) : null;
  }
}

const mapStateToProps = state => ({
  ...state.globalReducer
});

export default compose(
  withStyles(scrollToTopStyle),
  connect(mapStateToProps)
)(ScrollToTop);
