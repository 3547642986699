/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// import Hidden from "@material-ui/core/Hidden";
import { withRouter } from "react-router-dom";
// React i18next
import { withTranslation } from "react-i18next";
// nodejs library that concatenates classes
import classNames from "classnames";
// React i18next
import { useTranslation } from "react-i18next";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import compose from "recompose/compose";
import Link from "@material-ui/core/Link";
// redux
import { connect } from "react-redux";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// material ui Icon
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardBody from "components/Card/CardBody.jsx";
//kayfik
import customHeaderLinksStyle from "kayfik/assets/jss/components/Header/customHeaderLinksStyle.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import SearchBar from "kayfik/components/Header/SearchBar.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import BidActionTypes from "constants/bidConstants.jsx";
import VendorActionTypes from "constants/vendorProductConstants.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import withWidth from "@material-ui/core/withWidth";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { decimalPlace, postActionNotificationClicked } from "utils/helpers.jsx";
import { editUserDetails, postSignOut } from "actions/accountActions.jsx";
// import Badge from "components/Badge/Badge.jsx";
import { Badge } from "@material-ui/core";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";

// api
import api from "utils/api.jsx";

function HeaderLinks({ ...props }) {
  const {
    classes,
    dropdownHoverColor,
    showModal,
    modalProps,
    closeModal,
    language,
    changeLang,
    account_token,
    //signoutRequest,
    review_checkout,
    getProductDetail,
    checkValidToken,
    userDetails,
    history,
    changeDirection,
    direction,
    reviewCheckout,
    deleteItemRequest,
    updateGoTo,
    showNextModalFunc,
    editUserDetailsSuccess,
    editUserDetailsFailed,
    showSnackbar,
    signoutSuccessClearReducers,
    signoutFailed,
    updateLoaderHorizontal,
    dynamicHistPushFunc,
    deviceid,
    auction_notification_count,
    basket_id
    // list_action_req
    //editUserDetails
  } = props;

  const { t, i18n } = useTranslation();
  const dynamicHistoryRoute = path => {
    history.push(path);
  };

  // getting listed auction count object
  const listed_count =
    auction_notification_count && auction_notification_count.listed_items
      ? auction_notification_count.listed_items
      : null;

  // getting received_offer auction count object
  const received_offer_count =
    auction_notification_count && auction_notification_count.receive_offer
      ? auction_notification_count.receive_offer
      : null;

  // getting counter_offer auction count object
  const counter_offer_count =
    auction_notification_count && auction_notification_count.counter_offer
      ? auction_notification_count.counter_offer
      : null;

  // Count for delivery items
  const deliverey_items_count =
    auction_notification_count && auction_notification_count.delivery_items
      ? auction_notification_count.delivery_items
      : null;

  // Count for payment detail
  const payment_items_count =
  auction_notification_count && auction_notification_count.payment_items
    ? auction_notification_count.payment_items
    : null;

  // Count for order
  const order_items_count =
  auction_notification_count && auction_notification_count.order_items
    ? auction_notification_count.order_items
    : null;

  useEffect(() => {
    //check if token is available
    if (account_token != null) {
      let form_data = {
        token: account_token
      };

      // try to update device id with the login user
      if (deviceid) {
        let url = api.verify_token() + "?deviceid=" + deviceid;
        form_data.url = url;
      }

      checkValidToken(form_data);
      if (userDetails.language !== language) {
        //changeAppLangauge(language);
        let form_data = {
          language: language
        };
        editUserDetails(form_data)
          .then(res => res.data)
          .then(data => {
            editUserDetailsSuccess(data);
          })
          .catch(error => {
            editUserDetailsFailed(error);
          });
      }
      let path = window.location.pathname;
      if (path.indexOf("checkout") < 0 && path.indexOf("confirmation")<0) {
        reviewCheckout(null,basket_id);
      }
    }
    dynamicHistPushFunc({ dynamicHistoryRoute: dynamicHistoryRoute });
  }, []);

  const openModal = (modal_type, modal_props = { open: true }) => async () => {
    closeMobileDropdown();
    if (modalProps.open && closeModal) {
      await closeModal();
    }
    if (modal_type === "profileModal") {
      modal_props["noScrollModal"] = true;
    }
    showModal(modal_props, modal_type);
  };

  const goToCheckout = () => {
    updateGoTo("checkout");
    showNextModalFunc(false);
    if (!account_token) {
      openModal("initialLoginModal", {
        open: true,
        nextPage: "/checkout",
        location: "checkout",
        activeCheckout: true
      })();
    } else {
      if (userDetails && !userDetails.is_email_verified) {
        openModal("verifyEmailModal", {
          open: true,
          verifyFrom: "checkout",
          activeCheckout: true
        })();
      } else if (userDetails && !userDetails.is_phone_verified) {
        openModal("verifyPhoneModal", {
          open: true,
          verifyFrom: "checkout",
          activeCheckout: true
        })();
      } else {
        history.push("/checkout");
      }
    }
  };

  const changeAppLangauge = lang => async () => {
    await changeLang(lang);
    i18n.changeLanguage(lang);
    if (lang === "ar") {
      changeDirection("rtl");
      document.body.lang = "ar";
      document.body.dir = "rtl";
    } else {
      changeDirection("ltr");
      document.body.lang = "en";
      document.body.dir = "ltr";
    }
    setTimeout(() => {
      window.location = window.location.href;
    }, 1000);

    updateLoaderHorizontal(false);
  };

  const handleSignout = () => {
    //signoutRequest();
    postSignOut()
      .then(res => res.data)
      .then(data => {
        let txt = t("signout.successmessage");
        showSnackbar(txt, "success");
        signoutSuccessClearReducers();
        history.push("/");
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          let txt = t("unauthorizedAccess");
          showSnackbar(txt, "success");
          signoutSuccessClearReducers();
        } else {
          let txt = t("common.error.network");
          showSnackbar(txt, "error");
          signoutFailed(error);
        }
      });
    closeMobileDropdown();
  };

  const handleNavClick = component => () => {
    openModal("profileModal")();
    const {
      updateAccountPageComponent,
      getOrderList,
      getPartnerProducts,
      getPartnerBidProducts,
      getPartnerAddress,
      getCounterOffers
    } = props;

    if (component === "productlist") {
      //clear the count for listed items
      if (listed_count && listed_count.count !== 0) {
        postActionNotificationClicked(listed_count.url_to_read);
      }
      getPartnerProducts();
      getPartnerAddress(true);
    } else if (component === "bidlist") {
      //clear the count for received offer
      if (received_offer_count && received_offer_count.count !== 0) {
        postActionNotificationClicked(received_offer_count.url_to_read);
      }
      getPartnerBidProducts();
    } else if (component === "orders") {
      if (order_items_count && order_items_count.count !== 0) {
        postActionNotificationClicked(order_items_count.url_to_read);
      }
      getOrderList();
    } else if (component === "partneraddress") {
      getPartnerAddress();
    } else if (component === "counteroffers") {
      //clear the count for Counter offer
      if (counter_offer_count && counter_offer_count.count !== 0) {
        postActionNotificationClicked(counter_offer_count.url_to_read);
      }
      getCounterOffers();
    }
    updateAccountPageComponent(component);
  };

  const profileDropdownContent = () => {
    let a = [
      { comp: t("profileModal.account"), key: "settings", 
      count:payment_items_count && payment_items_count.count? payment_items_count.count :0 },
      { comp: t("profileModal.order"), key: "orders", 
      count:order_items_count && order_items_count.count? order_items_count.count :0
      },
      {
        comp: t("profileModal.product"),
        key: "productlist",
        count: listed_count ? listed_count.count : 0
      },
      {
        comp: t("profileModal.offer"),
        key: "bidlist",
        count: received_offer_count ? received_offer_count.count : 0
      },
      {
        comp: t("profileModal.counteroffers"),
        key: "counteroffers",
        count: counter_offer_count ? counter_offer_count.count : 0
      },
      { comp: t("profileModal.address"), key: "partneraddress", count: 0 }
    ];
    if (userDetails && userDetails.is_vendor_type === "COMPANY" && userDetails.company_approved=== true) {
      a.push({
        comp: t("requestProducts.reqProd"),
        key: "productRequests",
        count: 0
      });
    }
    if (userDetails && userDetails.has_delivery) {
      a.push({
        comp: t("profileModal.delivery_products"),
        key: "productdeliverylist",
        count:deliverey_items_count && deliverey_items_count.count? deliverey_items_count.count :0
      });
    }
    var b = [];
    b.push(
      <div className="cartDropdownContainer profileDropdownContainer pt-4">
        <CardBody className="p-0">
          <GridContainer className="w-100 m-0 p-4">
            <GridItem xs={12} className="dp-f align-items-center text_wrap">
              <GridContainer className="mx-0">
                <GridItem xs={2} className="px-0">
                  <AccountCircle className="htPrimaryGreen dropdownUserIcon" />
                </GridItem>
                <GridItem xs={10}>
                  <h3
                    className={
                      direction === "rtl"
                        ? "m-0 htPrimaryGreen dropdownItemView"
                        : "m-0 htPrimaryGreen  "
                    }
                  >
                    <span className="userName-capitalize">
                      {" "}
                      {userDetails && userDetails.first_name}{" "}
                    </span>
                    <span className="userName-capitalize">
                      {" "}
                      {userDetails && userDetails.last_name}{" "}
                    </span>
                  </h3>
                  <h5
                    className={
                      direction === "rtl"
                        ? "m-0 htPrimaryGreen dropdownItemView"
                        : "m-0 htPrimaryGreen "
                    }
                    onClick={openModal("editAccountDetailsModal")}
                  >
                    {t("homePage.header.profileLinks.view")}
                  </h5>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </div>
    );
    for (var item in a) {
      b.push(
        <div
          className={classNames(
            "cartDropdownContainer kayfik-dark-grey profileDropdownContainer"
          )}
        >
          <CardBody className="p-0" onClick={handleNavClick(a[item].key)}>
            <GridContainer
              className={classNames(
                "w-100 m-0 px-4 py-2",
                item === 0 ? "pt-4" : "",
                item === a.length - 1 ? "pb-4" : ""
              )}
            >
              <GridItem xs={12}>
                <div className="d-flex justify-content-between">
                  <div>
                    <h4
                      className={
                        direction === "rtl"
                          ? "m-0 htPrimaryGreen dropdownItemView dp-f justify-content-between"
                          : "m-0 htPrimaryGreen dp-f justify-content-between"
                      }
                    >
                      {a[item].comp}
                    </h4>
                  </div>
                  <div>
                    {a[item].count !== 0 ? (
                      <Badge
                        className={
                          direction === "rtl"
                            ? "count_left  mt-2"
                            : "count_right mt-2"
                        }
                        badgeContent={a[item].count}
                        classes={{
                          badge: "count_badge"
                        }}
                      >
                        <div />
                      </Badge>
                    ) : null}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </div>
      );
    }
    b.push(
      <div
        className="cartDropdownContainer profileDropdownContainer"
        onClick={handleSignout}
      >
        <CardBody className="p-0">
          <GridContainer className="w-100 m-0 px-4 py-4">
            <GridItem xs={12}>
              <h3
                className={
                  direction === "rtl"
                    ? "m-0 htPrimaryGreen dropdownItemView"
                    : "m-0 htPrimaryGreen"
                }
              >
                {t("homePage.header.profileLinks.signOut")}
              </h3>
            </GridItem>
          </GridContainer>
        </CardBody>
      </div>
    );
    return b;
  };

  const cartItemClicked = item => () => {
    getProductDetail(item.product);
    history.push("/shop" + item.product_uri);
  };

  const deleteItem = line => () => {
    let url = line.url;
    if(line.from_instant_cash){
      let mprops={
        open: true,
        lineId: line.id
      }
      openModal("iCLineDeleteModal", mprops)();
    }else{
      deleteItemRequest(url);
    }
  };

  const renderCartItems = () => {
    let content = [];
    let lines = [];

    if (review_checkout) {
      lines = review_checkout.basket_info.lines;
    }

    if (lines.length > 0) {
      for (let i = 0; i < lines.length; i++) {
        let line = lines[i];
        let prc = line.price_incl_tax_excl_discounts;
        if (line.from_bid) {
          prc = line.line_price;
        }
        content.push(
          <div
            key={i}
            className={
              classes.dropdownLink +
              " " +
              "d-flex align-items-center m-3 pointer-auto minWidth260"
            }
          >
            <div
              className={classNames(
                classes.imgContainer,
                direction === "rtl" ? " bottom_itemsAR" : " bottom_items "
              )}
              onClick={cartItemClicked(line)}
            >
              <img
                src={line.image}
                alt={line.title}
                className={classNames(classes.img, "Cart_height")}
              />
              {/* {line.is_pending ? (
                <Badge
                  color="gray"
                  className="image-badge image-badge-cart"
                  classes={{
                    badge:classNames(" productBadge-cart")
                  }}
                >
                  <span className="pendingVerificationBackground">
                    {" "}
                    {t("buyingPage.shopProductDetail.pending")}{" "}
                  </span>
                </Badge>
              ) : null} */}
            </div>
            <div className="taWebAuto">
              <p
                className={classes.tdNameAnchor + " m-0 pointer cartItemTitle"}
                onClick={cartItemClicked(line)}
              >
                {line.title}
              </p>
              <p className={classNames(classes.tdNameAnchor, "m-0")}>
                {t("homePage.header.cartLinks.quantity")}: {line.quantity}
              </p>
              <p className={classNames(classes.tdNameAnchor, " m-0")}>
                {line.price_currency}: {decimalPlace(prc)}
              </p>
              <Link
                underline="none"
                component="button"
                className="color-pl"
                onClick={deleteItem(line)}
              >
                <p className="mt-1">
                  {t("buyingPage.checkOut.reviewcart.remove")}
                </p>
              </Link>
            </div>
          </div>
        );
      }
      content.push(
        <div
          className={
            classes.dropdownLink +
            " " +
            "d-flex justify-center align-items-center m-3"
          }
        >
          <Button
            fullWidth
            color="primaryWhiteWithBorder"
            size="lg"
            onClick={goToCheckout}
          >
            {t("homePage.header.cartLinks.checkout")}
          </Button>
        </div>
      );
    } else {
      content.push(
        <div
          className={
            classes.dropdownLink +
            " " +
            "d-flex justify-center align-items-center emptyCart"
          }
        >
          <p className="dropdown-color">
            {t("homePage.header.cartLinks.noItem")}
          </p>
        </div>
      );
    }
    return content;
  };

  const getLineCount = () => {
    let count = 0;
    if (review_checkout) {
      if (review_checkout.basket_info.lines.length > 0) {
        count = review_checkout.basket_info.lines.length;
      }
    }
    return count;
  };

  const getActionCount = () => {
    let count = 0;
    if (listed_count) {
      count += listed_count.count;
    } 
    if (received_offer_count) {
      count += received_offer_count.count;
    } 
    if (counter_offer_count) {
      count += counter_offer_count.count;
    } 
    if (deliverey_items_count) {
      count += deliverey_items_count.count;
    } 
    if (payment_items_count) {
      count += payment_items_count.count;
    } 
    if (order_items_count) {
      count += order_items_count.count;
    } 
    return count;
  };

  const closeMobileDropdown = () => {
    const { openMobileDropdown } = props;
    openMobileDropdown(false);
  };

  const buyClicked = () => {
    history.push("/shop");
    closeMobileDropdown();
  };

  const renderMobileLink = () => {
    // getting listed auction count
    let listed_count =
      auction_notification_count && auction_notification_count.listed_items
        ? auction_notification_count.listed_items.count
        : 0;

    // getting received_offer auction count
    let received_offer_count =
      auction_notification_count && auction_notification_count.receive_offer
        ? auction_notification_count.receive_offer.count
        : 0;

    // getting counter_offer auction count
    let counter_offer_count =
      auction_notification_count && auction_notification_count.counter_offer
        ? auction_notification_count.counter_offer.count
        : 0;

    let a = [
      { comp: t("profileModal.account"), key: "settings", 
        count:payment_items_count && payment_items_count.count? payment_items_count.count :0
      },
      { comp: t("profileModal.order"), key: "orders", 
        count:order_items_count && order_items_count.count? order_items_count.count :0
      },
      {
        comp: t("profileModal.product"),
        key: "productlist",
        count: listed_count
      },
      {
        comp: t("profileModal.offer"),
        key: "bidlist",
        count: received_offer_count
      },
      {
        comp: t("profileModal.counteroffers"),
        key: "counteroffers",
        count: counter_offer_count
      },
      { comp: t("profileModal.address"), key: "partneraddress", count: 0 },
    ];
    if (userDetails && userDetails.is_vendor_type === "COMPANY" && userDetails.company_approved=== true) {
      a.push({
        comp: t("requestProducts.reqProd"),
        key: "productRequests",
        count: 0
      });
    }
    if (userDetails && userDetails.has_delivery) {
      a.push({
        comp: t("profileModal.delivery_products"),
        key: "productdeliverylist",
        count:deliverey_items_count && deliverey_items_count.count? deliverey_items_count.count :0
      });
    }
   
    if (!account_token) {
      return (
        <div className="d-none-smd">
          <ListItem
            className={classNames(
              direction === "rtl"
                ? (classes.mobileRight, classes.listItemMobAR)
                : classes.listItem,
              "pt-5 d-none-smd"
            )}
          >
            <Link
              underline="none"
              component="button"
              className={classes.fontColor}
              onClick={openModal("loginModal")}
            >
              <h4 className="text-uppercase">
                {t("homePage.header.mobileLinks.login")}
              </h4>
            </Link>
          </ListItem>
          <ListItem
            className={classNames(
              direction === "rtl"
                ? (classes.mobileRight, classes.listItemMobAR)
                : classes.listItem
            )}
          >
            <Link
              underline="none"
              component="button"
              className={classes.fontColor}
              onClick={openModal("accountTypeModal")}
            >
              <h4 className="text-uppercase">
                {t("homePage.header.mobileLinks.signUp")}
              </h4>
            </Link>
          </ListItem>
          <ListItem
            className={classNames(
              direction === "rtl"
                ? (classes.mobileRight, classes.listItemMobAR)
                : classes.listItem
            )}
          >
            <Link
              underline="none"
              component="button"
              className={classes.fontColor}
              onClick={openModal("whatSellingModal")}
            >
              <h4 className="text-uppercase">
                {t("homePage.header.mobileLinks.sellonKayfik")}
              </h4>
            </Link>
          </ListItem>
          <ListItem
            className={classNames(
              direction === "rtl"
                ? (classes.mobileRight, classes.listItemMobAR)
                : classes.listItem
            )}
          >
            <Link
              underline="none"
              component="button"
              className={classes.fontColor}
              onClick={buyClicked}
              //to={"/shop"}
            >
              <h4 className="text-uppercase">
                {t("homePage.header.mobileLinks.buyonKayfik")}
              </h4>
            </Link>
          </ListItem>
        </div>
      );
    } else {
      return (
        <div className="d-none-smd">
          <ListItem
            className={classNames(
              direction === "rtl"
                ? (classes.mobileRight, classes.listItemMobAR)
                : classes.listItem,
              "pt-5 d-none-sm"
            )}
          >
            <Link
              underline="none"
              component="button"
              className={classes.fontColor}
              onClick={openModal("editAccountDetailsModal")}
            >
              <h4>
                <span className="userName-capitalize">
                  {" "}
                  {userDetails && userDetails.first_name}{" "}
                </span>
                <span className="userName-capitalize">
                  {" "}
                  {userDetails && userDetails.last_name}{" "}
                </span>
              </h4>
            </Link>
          </ListItem>
          <ListItem
            className={classNames(
              direction === "rtl"
                ? (classes.mobileRight, classes.listItemMobAR)
                : classes.listItem
            )}
          >
            <Link
              underline="none"
              component="button"
              className={classes.fontColor}
              onClick={openModal("editAccountDetailsModal")}
            >
              <h4> {t("homePage.header.mobileLinks.view")}</h4>
            </Link>
          </ListItem>
          {a.map((item, index) => {
            return (
              <ListItem key={index} className={classNames(classes.listItem)}>
                <div className="d-flex justify-content-between">
                  <div className={direction === "rtl" ? "pr-3" : ""}>
                    <Link
                      underline="none"
                      component="button"
                      className={classes.fontColor}
                      onClick={handleNavClick(item.key)}
                    >
                      <h4>{item.comp}</h4>
                    </Link>
                  </div>
                  <div className="mr-4 ml-4 mt-2">
                    {item && item.count !== 0 ? (
                      <Badge
                        className={
                          direction === "rtl"
                            ? "count_left ml-5"
                            : "count_right"
                        }
                        badgeContent={item.count}
                        classes={{
                          badge: "count_badge"
                        }}
                      >
                        <div />
                      </Badge>
                    ) : null}
                  </div>
                 </div>
              </ListItem>
            );
          })}
          <ListItem
            className={classNames(
              direction === "rtl"
                ? (classes.mobileRight, classes.listItemMobAR)
                : classes.listItem
            )}
          >
            <Link
              underline="none"
              component="button"
              className={classes.fontColor}
              onClick={handleSignout}
            >
              <h4>{t("homePage.header.mobileLinks.signOut")}</h4>
            </Link>
          </ListItem>
        </div>
      );
    }
  };

  const expandSearch = () => {
    let i = document.getElementById("searchBar");
    if (i) i.focus();
  };

  const dropdownMenuPlacement = () => {
    var windowHeight = window.innerHeight;
    if (windowHeight < 563) {
      setTimeout(() => {
        let menuElem = document.getElementById("menu-list");
        if(menuElem){
          let parentElem = menuElem.parentNode;
          if(parentElem){
            parentElem.style.maxHeight=windowHeight-100+"px";
            parentElem.style.overflowY="auto" ;      
          }
        }
      }, 100);
    }
  };

  return (
    <List className={classNames(classes.list, "HeaderForMobile ")}>
      {renderMobileLink()}
      {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
      {/* // HIDE FOR MOBILE */}
      <div className="kayfik_header d-none-sms">
        <ListItem
          className={
            direction === "rtl" ? classes.listItemAR : classes.listItem
          }
        >
          <Button
            color="transparent"
            className={classes.navLink + " custom-search-icon"}
            size="lg"
            onClick={expandSearch}
          >
            <SearchBar />
          </Button>
        </ListItem>

        <ListItem className={classes.listItem}>
          {language === "en" ? (
            <Button
              color="transparent"
              className={classes.navLink}
              size="lg"
              onClick={changeAppLangauge("ar")}
            >
              <span className="hoverYellow">
                {t("homePage.header.language.arabic")}
              </span>
            </Button>
          ) : (
            <Button
              color="transparent"
              className={classes.navLink}
              size="lg"
              onClick={changeAppLangauge("en")}
            >
              <span className="hoverYellow">
                {t("homePage.header.language.english")}
              </span>
            </Button>
          )}
        </ListItem>
        {account_token ? (
          <ListItem
            className={classes.listItem}
            onClick={dropdownMenuPlacement}
          >
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverYellow
              hoverColor={dropdownHoverColor}
              buttonProps={{
                className: classes.navLink + " custom-nav-icon",
                color: "transparent",
                size: "lg"
              }}
              buttonIcon={AccountCircle}
              dropdownList={profileDropdownContent()}
              caret={false}
              dropdownBG={true}
              badge={true}
              badgeCount={getActionCount()}
            />
          </ListItem>
        ) : null}
        {account_token ? null : (
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              size="lg"
              onClick={openModal("loginModal")}
            >
              <span className="hoverYellow">{t("homePage.header.login")}</span>
            </Button>
          </ListItem>
        )}
        {account_token ? null : (
          <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              size="lg"
              onClick={openModal("accountTypeModal")}
            >
              <span className="hoverYellow">
                {" "}
                {t("homePage.header.signUp")}{" "}
              </span>
            </Button>
          </ListItem>
        )}

        <ListItem
          className={
            direction === "rtl" ? classes.listItemAR : classes.listItem
          }
        >
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonProps={{
              className: classes.navLink + " custom-nav-icon cart_items_dropdown",
              color: "transparent",
              size: "lg"
            }}
            hoverYellow
            buttonIcon={ShoppingCart}
            dropdownList={renderCartItems()}
            lastNoHover={true}
            caret={false}
            badge={true}
            badgeCount={getLineCount()}
          />
        </ListItem>
      </div>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "htPrimaryGreen",
    "htPrimaryLight"
  ])
};

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  changeLang: lang => {
    dispatch({ type: GlobalActionTypes.CHANGE_LANGUAGE_REQUEST, lang });
  },
  changeDirection: dir => {
    dispatch({ type: GlobalActionTypes.CHANGE_DIRECTION_SUCCESS, dir });
  },
  signoutRequest: () => {
    dispatch({
      type: AccountActionTypes.SIGNOUT_REQUEST
    });
  },
  getProductDetail: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  reviewCheckout: (code, recall ) => {
    dispatch({
      type: CartActionTypes.GET_REVIEW_CHECKOUT_REQUEST,
      recall: recall,
      code: code
    });
  },
  checkValidToken: form_data => {
    dispatch({
      type: AccountActionTypes.VERIFY_TOKEN_REQUEST,
      form_data
    });
  },
  deleteItemRequest: url => {
    dispatch({
      type: CartActionTypes.DELETE_ITEM_REQUEST,
      payload: url
    });
  },
  openMobileDropdown: value => {
    dispatch({
      type: GlobalActionTypes.UPDATE_MOBILE_DROPDOWN,
      value
    });
  },
  updateGoTo: goTo => {
    dispatch({
      type: GlobalActionTypes.UPDATE_GO_TO,
      goTo
    });
  },
  showNextModalFunc: showNextModal => {
    dispatch({
      type: AccountActionTypes.SHOW_NEXT_MODAL,
      showNextModal
    });
  },
  getUserDetails: () => {
    dispatch({
      type: AccountActionTypes.USER_DETAIL_REQUEST
    });
  },
  editUserDetailsSuccess: data => {
    dispatch({
      type: AccountActionTypes.EDIT_USER_DETAIL_SUCCESS,
      data
    });
  },
  editUserDetailsFailed: error => {
    dispatch({
      type: AccountActionTypes.EDIT_USER_DETAIL_FAILED,
      error
    });
  },
  showSnackbar: (txt, variant) => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  signoutSuccessClearReducers: () => {
    dispatch({ type: AccountActionTypes.SIGNOUT_SUCCESS });
    // if its successfull close the modal
    dispatch({ type: ModalActionTypes.HIDE_MODAL_REQUEST });
    dispatch({ type: AccountActionTypes.ACCOUNT_RESET });
    dispatch({ type: AddressActionTypes.ADDRESS_RESET });
    dispatch({ type: CartActionTypes.CART_RESET });
    dispatch({ type: BidActionTypes.BID_RESET });
    dispatch({ type: GlobalActionTypes.RESET_GO_TO });
    dispatch({ type: VendorActionTypes.VENDOR_PRODUCT_RESET });
  },
  signoutFailed: error => {
    dispatch({ type: AccountActionTypes.SIGNOUT_FAILED, error });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  getPartnerProducts: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_LIST_REQUEST
    });
  },
  getPartnerBidProducts: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_LIST_REQUEST
    });
  },
  getPartnerAddress: (backgroundFetch) => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_ADDRESS_REQUEST,
      backgroundFetch:backgroundFetch
    });
  },
  getOrderList: () => {
    dispatch({
      type: CartActionTypes.GET_ORDERS_REQUEST
    });
  },
  updateLoaderHorizontal: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_LOADER_HORIZONTAL,
      value
    });
  },
  getCounterOffers: () => {
    dispatch({
      type: VendorProductActionTypes.GET_COUNTER_OFFER_LIST_REQUEST
    });
  },
  dynamicHistPushFunc: dynamicHistoryRoute => {
    dispatch({
      type: GlobalActionTypes.DYNAMIC_HISTORY_PUSH,
      dynamicHistoryRoute
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.accountReducer,
  ...state.productReducer,
  ...state.cartReducer,
  ...state.vendorProductReducer,
});

export default compose(
  withStyles(customHeaderLinksStyle),
  withWidth(),
  withTranslation(),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HeaderLinks);
