import infoStyle from "assets/jss/material-kit-pro-react/components/infoStyle.jsx";
import customHeaderLinksStyle from "kayfik/assets/jss/components/Header/customHeaderLinksStyle.jsx";
import { container } from "assets/jss/material-kit-pro-react.jsx";
import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.jsx";
import { htDark } from "kayfik/assets/jss/universal.jsx";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

const customInfoStyle = theme => ({
  ...projectsStyle,
  ...modalStyle(theme),
  ...infoStyle,
  ...customHeaderLinksStyle(theme),
  ...contactStyle,
  textTransform: {
    textTransform: "none !important"
  },
  iconSmall: {
    height: "2.5rem",
    width: "2.5rem",
    color: htDark,
    alignSelf: " center"
  },
  // mb2:{
  //   marginBottom:" 0.5rem !important",
  // },
  btnSize: {
    fontWeight: "600 !important"
  },
  [theme.breakpoints.down("sm")]: {
    iconSmall: {
      height: "2rem !important",
      width: "1.5rem !important",
      alignSelf: " center"
    },
    mar: {
      display: "none !important",
      marginTop: "-10px !important",
      marginBottom: "0px !important"
    },
    btnSize: {
      fontSize: "10px !important",
      padding: "6px 8px!important",
      fontWeight: "600 !important"
    }
  },
  container,
  flex: {
    display: "flex !important",
    justifyContent: "center !important",
    alignSelf: "center !important"
  },
  flex1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100px"
  },
  mb24: {
    marginBottom: "24px !important"
  },
  mb2: {
    marginBottom: "10px !important"
  },
  jcfs: {
    justifyContent: "flex-start !important"
  },
  iconLevel1: {
    height: "2.5rem !important",
    width: "2.5rem !important",
    [theme.breakpoints.down("md")]: {
      height: "1.5rem !important",
      width: "1.5rem !important"
    }
    // [theme.breakpoints.down("sm")]: {
    //   height:"1.0rem !important",
    //   width:"1.0rem !important",
    // }
  },
  iconLevel2: {
    height: "3.5rem !important",
    width: "3.0rem !important",
    [theme.breakpoints.down("md")]: {
      height: "2rem !important",
      width: "2rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      height: "1.7rem !important",
      width: "1.7rem !important"
    }
  },
  iconLevel3: {
    height: "4.5rem !important",
    width: "5rem !important",
    // marginBottom:"-10px",
    [theme.breakpoints.down("md")]: {
      height: "2.5rem !important",
      width: "2.5rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      height: "2.0rem !important",
      width: "2.0rem !important"
    }
  },
  iconWrapper: {
    height: "3rem !important",
    alignItems: " flex-end !important",
    marginTop: "1rem !important"
  },
  bannerImg: {
    height: "43px !important",
    width: "auto !important",
    [theme.breakpoints.down("sm")]: {
      height: "20px !important",
      width: "auto !important"
    }
  },
  bannerTitle: {
    color: "#006978",
    marginTop: "0.5rem !important",
    fontSize: ".75rem",
    fontWeight: "400",
    lineHeight: "1.5",
    fontFamily: "Graphik Arabic Web",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".25rem"
    }
  },
  flex2: {
    display: "flex !important",
    justifyContent: "center !important",
    alignSelf: "center !important",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start !important"
    }
  },
  flex2AR: {
    display: "flex !important",
    justifyContent: "center !important",
    alignSelf: "center !important",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start !important"
    }
  }
});

export default customInfoStyle;
