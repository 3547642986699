import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// core component
// material ui Icon
import Close from "@material-ui/icons/Close";
import CheckCircle from "@material-ui/icons/CheckCircle";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import IconButton from "@material-ui/core/IconButton";

class otherListingPostedModal extends React.Component {
  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  shareActionTitle = () => {
    const { t } = this.props;
    return t("sellingPage.otherListingPostedModal.share");
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, t, direction, combo_fetching } = this.props;

    return combo_fetching ? (
      <Loader />
    ) : (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5 " justify="center">
            <GridItem xs={12} sm={10} md={8}>
              <h2
                className={classNames(classes.modalTitle, classes.primaryText)}
              >
                {t("sellingPage.otherListingPostedModal.congrats")}
              </h2>
              <div className="justify-center d-flex mb-3">
                <CheckCircle className="big-icon icon-primarylight" />
              </div>
              <h4 className="m-0 mb-3">
                {t("sellingPage.otherListingPostedModal.success")}
              </h4>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.productReducer,
  ...state.comboProductReducer,
  ...state.globalReducer,
  ...state.accountReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(otherListingPostedModal);
