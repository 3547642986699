import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import CustomSelectInput from "kayfik/components/CustomInput/customSelectInput.jsx";
import AddCircleOutlined from "@material-ui/icons/AddCircleOutlined";
import IconButton from "@material-ui/core/IconButton";

class RequestProductModal extends React.Component {
  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal,direction } = this.props;
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(classes.modalCloseButton,"previewClose",direction==="rtl"?classes.modalSmallCloseButtonAR:null)}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5" justify="center">
            <GridItem xs={11} sm={11} md={11} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                Lets help you find what you're looking for
              </h2>
              <h3 className={classNames("mb-3")}>
                Request a Product{" "}
              </h3>  
              <CustomInput
                labelText="NAme"
                id="id_name"
                size="lg"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "name",
                  required: true,
                  disableUnderline: true
                }}
              />              
              
              <CustomInput
                labelText="Email Address"
                id="id_email"
                size="lg"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "email",
                  required: true,
                  type: "email",
                  disableUnderline: true
                }}
              />
              <h3 className={classNames("mb-3")}>
                What are you looking for{" "}
            </h3>
        <GridContainer
          className={classNames(
            "m-0",
            classes.marginAuto
          )}
        >
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Product Category"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "prod_category",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select a category" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Brand"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "brand",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select a brand" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Model"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "modle",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select a model" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Capacity"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "capacity",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select capaciy" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Color"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "color",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select color" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Conditon"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "condition",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select condition" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Quantity"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "qty",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select quantity" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="Date request filled by"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "date",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select date" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="price"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "price",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select price" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
            <CustomSelectInput
              labelText="xxxxx"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "date",
                required: true,
                disableUnderline: true
              }}
              menuFirstItem="Select date" // string to display on first item in select default to none
              selectProps={{
                value: ""
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
            <Button link size="lg" className="mb-3 px-0 textTransformNone">
              <AddCircleOutlined />
              Add additional product request
            </Button>
          </GridItem>
        </GridContainer>
              <Button
                color="primaryWhiteWithBorder"
                size="lg"
                fullWidth
                // href="/company-signup"
              >
                Submit Request
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RequestProductModal);
