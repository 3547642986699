import React from "react";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
import classNames from "classnames";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import ModalPagination from "kayfik/components/Pagination/newPage.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import { instantCashProductList, getDetail } from "actions/accountActions.jsx";
import { deleteItem } from "actions/cartActions.jsx";
import Link from "@material-ui/core/Link";
import Muted from "components/Typography/Muted.jsx";

class ProductRequests extends React.Component {

  openModal = modal_type => async () => {
    const { showModal } = this.props;
    showModal(
      {
        open: true,
        title: modal_type,
        nextModal: "profileModal",
        verifyFrom: "reqProd"
      },
      modal_type
    );
  };

  getReqProds = () => {
    const { updateReqProdList } = this.props;
    instantCashProductList()
      .then(res => {
        let data = res.data;
        if (data) {
          updateReqProdList(data);
        }
      })
  }

  componentDidMount() {
    this.getReqProds();
  }

  deleteItem = deleteUrl => {
    const { t, showSnackbar } = this.props;
    if (
      window.confirm(
        t(
          "requestProducts.deleteConfirm"
        )
      )
    ) {
      deleteItem(deleteUrl)
        .then(res => {
          if (res.status === 204) {
            let txt = t("requestProducts.deleteSuccess");
            showSnackbar(txt, "success");
            this.getReqProds();
          }
        })
        .catch(e => {
          if (
            e &&
            e.response.status === 403 &&
            e.response.data &&
            e.response.data.message
          ) {
            let msg = e.response.data.message;
            let txt = msg;
            showSnackbar(txt, "warning");
          }
          console.log(e.response, e.response); //For error
        });
    }
  };

  getDetail = (url) => {
    const { updateAccountPageComponent, updateReqProdDetail } = this.props;
    if (url) {
      getDetail(url)
        .then(res => {
          let data = res.data;
          if (data) {
            updateReqProdDetail(data);
            updateAccountPageComponent("productReqDetail");
          }
        })
        .catch(e => {
          console.log("e.response", e.reponse);
        })
    }
  }

  getReqProdList = () => {
    const { t, reqProductList, showLoader, classes, language } = this.props;
    let dataToReturn = [];
    if (reqProductList) {
      if (reqProductList.count > 0) {
        reqProductList.results.map((item, i) => {
          dataToReturn.push(
            <Card
              bordered
              key={i}
              id={
                i === reqProductList.results.length - 1 ? "lastReqProd" : (i===0? "firstreqProd" :"reqProd" + i)
              }
            >
              <CardBody className="p-0"  >
                <div className={"px-3 py-4"}>
                  <GridContainer>
                    <GridItem xs={12} className={classNames("dp-f jcsb align-items-center ", language === "ar" ? "pl-0" : "pr-0")}>
                      <h5 className="pointer m-0" onClick={() => this.getDetail(item.url)} >{item.product_class} - {item.name} </h5>
                      <Link
                        underline="none"
                        component="button"
                        className="color-pl"
                        onClick={() => this.getDetail(item.url)}
                      >
                        <h5 className="m-0">
                          {t("profileModal.accountSettings.edit")}
                        </h5>
                      </Link>

                    </GridItem>
                  </GridContainer>
                  <div onClick={() => this.getDetail(item.url)} className="pointer">
                    <GridContainer>
                      <GridItem xs={6}>
                        <h5 className="m-0 request-item-price">
                          <span className={language === "en"?"request-price-view-en":"request-price-view-ar"}>{t("buyingPage.shopFacets.price")}:</span>
                          <Muted>
                            {" "}{item.currency}{" "} {item.price}
                          </Muted>
                        </h5>
                      </GridItem>
                      <GridItem xs={6}>
                        <h5 className="m-0">
                          {t("homePage.header.cartLinks.quantity")}: {item.quantity}
                        </h5>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={6}>
                        <h5 className="m-0">
                          {t("requestProducts.from")}: {item.start_date.slice(0, 10)
                            .split("-")
                            .join("/")}
                        </h5>
                      </GridItem>
                      <GridItem xs={6}>
                        <h5 className="m-0">
                          {t("requestProducts.to")}: {item.end_date.slice(0, 10)
                            .split("-")
                            .join("/")}
                        </h5>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </CardBody>
            </Card>
          );
          return true;
        });
        if (showLoader) {
          dataToReturn.push(<div className="dp-f jcc">
            <CircularProgress
              disableShrink
              classes={{ root: classes.indicator }}
            />
          </div>)
        }
      }
      else {
        dataToReturn.push(
          <GridContainer className="mt-2"  key={"extra"}>
            <p>{t("requestProducts.noReqProd")}</p>
          </GridContainer>
        )
      }
    }
    else {
      dataToReturn.push(
        <GridContainer className="mt-2">
          <p>{t("requestProducts.noReqProd")}</p>
        </GridContainer>
      )
    }
    return dataToReturn;
  };

  reqProdClicked = () => {
    const { userDetails, updateAccountPageComponent, } = this.props;
    if (!userDetails.is_email_verified) {
      this.openModal("verifyEmailModal")();
    } else if (!userDetails.is_phone_verified) {
      this.openModal("verifyPhoneModal")();
    } else {
      updateAccountPageComponent("requestProducts");
    }
  }
  render() {
    const { cart_fetching, t, reqProductList } = this.props;
    let nextLink = reqProductList ? reqProductList.next : null;
    let elementID = "lastReqProd";
    let nextLinkToFetch = nextLink;
    let loadMore = "reqProd";
    if (cart_fetching) {
      return (
        <Hidden smDown implementation="css">
          <Loader noHeight={true} />
        </Hidden>
      );
    }
    return (
      <div className="main-background-color product_listing">
        <div className="dp-f jcsb mb-3">
          <h4 className="profile_section">{t("requestProducts.requestedProds")}</h4>
          <Button
            color="primaryWhiteWithBorder"
            className="m-0 minWidth request-item"
            size="sm"
            onClick={this.reqProdClicked}
          >
            {t("requestProducts.reqProd")}
          </Button>
        </div>
        <div
          className="partnerProductListing scrollbar"
          onScroll={() =>
            ModalPagination.trackScrolling(elementID, nextLinkToFetch, loadMore)
          }
        >
          {this.getReqProdList()}
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  orderDetailVariable: value => {
    dispatch({
      type: CartActionTypes.UPDATE_ORDER_DETAIL_VARIABLE,
      payload: value
    });
  },
  getOrderDetails: url => {
    dispatch({
      type: CartActionTypes.GET_ORDER_DETAILS_REQUEST,
      payload: url
    });
  },
  updateOrderListDetails: data => {
    dispatch({
      type: CartActionTypes.UPDATE_ORDER_LIST_DETAIL,
      data
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  updateReqProdList: reqProductList => {
    dispatch({
      type: AccountActionTypes.REQ_PROD_LIST_UPDATE,
      reqProductList
    });
  },
  updateReqProdDetail: reqProdDetail => {
    dispatch({
      type: AccountActionTypes.REQ_PROD_DETAIL_UPDATE,
      reqProdDetail
    });
  },
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(ProductRequests);
