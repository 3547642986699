import React from "react";
import classNames from "classnames";
import api from "utils/api.jsx";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
// React i18next
import { withTranslation } from "react-i18next";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// actions
import ModalActionTypes from "constants/modalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import VendorActionTypes from "constants/vendorProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import SelectAutocomplete from "kayfik/components/SelectAutocomplete/SelectAutocomplete.jsx";
import FlagSelect from "kayfik/components/FlagSelect/FlagSelect.jsx";
import IconButton from "@material-ui/core/IconButton";
// eslint-disable-next-line react/prop-types
import _ from "lodash";


class ConfirmAddress extends React.Component {
  constructor(props) {
    super(props);
    const { userDetails } = this.props;
    this.state = {
      phone: userDetails && userDetails.phone ? userDetails.phone : "",
      areaState: null
    };
  }

  onInputChange=(value)=>{
    const { updateActiveArea } = this.props;
    if(value){
      updateActiveArea({});
    }    
  }
  componentDidMount() {
    const {
      address_list,
      map_address,
      updateActiveArea,
      add_address_event,
      partner_address,
      company_address,
      updateMapAddress
    } = this.props;
    if (address_list.length > 0) {
      let address = address_list[0];
      this.setState({ phone: address.phone_number });
    }
    if (add_address_event === "loggedin_shipping") {
      let full_address = null;
      if (address_list.length > 0) {
        full_address = address_list[0].address;
      }

      let display_address = map_address &&  !_.isEmpty(map_address) ? map_address : full_address;
      if (display_address &&  !_.isEmpty(display_address) ) {
        let value = {
          area: display_address.area,
          area_id: display_address.area_id
        };
        updateActiveArea(value);
        let map_data = {
          area: display_address.area,
          area_id: display_address.area,
          block: display_address.block,
          latitude: display_address.latitude,
          longitude: display_address.longitude
        };
        updateMapAddress(map_data);
      }
    } else if (add_address_event === "loggedin_partner") {
      let full_address = null;
      if (partner_address&& !_.isEmpty(partner_address)) {
        full_address = partner_address.address;
      }

      let display_address = map_address && !_.isEmpty(map_address) ? map_address : full_address;

      if (display_address && !_.isEmpty(display_address)) {
        let value = {
          area: display_address.area,
          area_id: display_address.area_id
        };
        updateActiveArea(value);
        let map_data = {
          area: display_address.area,
          area_id: display_address.area,
          block: display_address.block,
          latitude: display_address.latitude,
          longitude: display_address.longitude
        };
        updateMapAddress(map_data);
      }
    } else if (add_address_event === "company_signup") {
      let display_address = map_address;
      if (display_address&& !_.isEmpty(display_address)) {
        let value = {
          area: display_address.area,
          area_id: display_address.area_id
        };
        updateActiveArea(value);
        
      } else if (company_address && company_address.area_id) {
        let value = {
          area: company_address.area,
          area_id: company_address.area_id
        };
        updateActiveArea(value);
      }
    }
  }

  openModal = modal_type => async () => {
    const { modalProps, closeModal, address } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    let props = {
      open: true,
      address: address === "pickup" ? address : null
    };
    this.props.showModal(props, modal_type);
  };

  handleOnChange = (value, data) => {
    const { userDetails } = this.props;

    let code = "+" + data.dialCode;
    if (value.indexOf(code) === 0) {
      this.setState({ phone: value });
    } else if (userDetails && data.dialCode !== userDetails.country_code) {
      this.setState({ phone: code });
    } else {
      this.setState({ phone: this.state.phone });
    }
  };

  handleTextChange = e => {
    const { updateData, company_signup_data } = this.props;
    let new_data = { ...company_signup_data };
    if (e.target.name === "first_name") {
      new_data["first_name"] = e.target.value;
      updateData(new_data);
    } else if (e.target.name === "last_name") {
      new_data["last_name"] = e.target.value;
      updateData(new_data);
    }
  };

  customClose = () => {
    const { modalProps, closeModal } = this.props;
    if (modalProps.nextModal) {
      this.openModal(modalProps.nextModal)();
    } else {
      closeModal();
    }
  };

  handleSubmitGuest = e => {
    e.preventDefault();
    const { guestAddress, closeModal, showSnackbar, t } = this.props;
    let data = {
      guest_email: e.target.email.value,
      shipping_address: {
        country: api.root + "countries/KW/",
        first_name: e.target.first_name.value,
        last_name: e.target.last_name.value,
        notes: e.target.notes.value,
        phone_number: this.state.phone,
        json_address: {
          area: e.target.area.value,
          block: e.target.block.value,
          street: e.target.street.value,
          avenue: e.target.avenue.value,
          building: e.target.building.value,
          floor: e.target.floor.value,
          unit: e.target.apartment.value,
          latitude: e.target.latitude.value,
          longitude: e.target.longitude.value
        }
      }
    };

    // now update guest address in redux
    guestAddress(data);
    closeModal();
    let txt = t("profileModal.accountSettings.shippingAddress.successsave");
    showSnackbar(txt, "success");
  };

  handleSubmitLoggedInShipping = e => {
    e.preventDefault();
    const {
      addAddress,
      patchAddressRequest,
      address_list,
      checkout_firstName,
      checkout_lastName,
      activeArea,
      map_address
    } = this.props;
    let data = {
      country: api.root + "countries/KW/",
      first_name: e.target.first_name.value
        ? e.target.first_name.value
        : checkout_firstName,
      last_name: e.target.last_name.value
        ? e.target.last_name.value
        : checkout_lastName,
      notes: e.target.notes.value,
      phone_number: this.state.phone,
      json_address: {
        area: activeArea.area,
        area_id: activeArea.area_id,
        block: e.target.block.value,
        street: e.target.street.value,
        avenue: e.target.avenue.value,
        building: e.target.building.value,
        floor: e.target.floor.value,
        unit: e.target.apartment.value,
        latitude: map_address? map_address.latitude: e.target.latitude.value,
        longitude: map_address? map_address.longitude: e.target.longitude.value
      }
    };

    if (address_list.length > 0) {
      // IF address exist patch it
      data["url"] = address_list[0].url;
      let address = address_list[0];

      if (data["phone_number"] === "" && address.phone_number) {
        data["phone_number"] = address.phone_number;
      }

      patchAddressRequest(data);
    } else {
      // if not exits create a new one
      addAddress(data);
    }
  };

  handleSubmitLoggedInPartner = e => {
    e.preventDefault();

    const { patchPartnerAddress, partner_address, activeArea } = this.props;

    if (partner_address && partner_address["first_name"]) {
      delete partner_address["first_name"];
    }
    if (partner_address && partner_address["last_name"]) {
      delete partner_address["last_name"];
    }
    

    let data = {
      ...partner_address,
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      json_address: {
        area: activeArea.area,
        area_id: activeArea.area_id,
        block: e.target.block.value,
        street: e.target.street.value,
        avenue: e.target.avenue.value,
        building: e.target.building.value,
        floor: e.target.floor.value,
        unit: e.target.apartment.value,
        latitude: e.target.latitude.value,
        longitude: e.target.longitude.value
      }
    };
    patchPartnerAddress(data);
  };

  handleSubmitCompanyAddress = e => {
    e.preventDefault();
    const { updateCompanyAddress, closeModal, activeArea } = this.props;
    
    let data = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      area: activeArea.area,
      area_id: activeArea.area_id,
      block: e.target.block.value,
      street: e.target.street.value,
      avenue: e.target.avenue.value,
      building: e.target.building.value,
      floor: e.target.floor.value,
      unit: e.target.apartment.value,
      latitude: e.target.latitude.value,
      longitude: e.target.longitude.value
    };
    updateCompanyAddress(data);
    closeModal();
  };

  renderGuestAddressForm = () => {
    const {
      checkout_firstName,
      checkout_lastName,
      map_address,
      t,
      guest_address
    } = this.props;

    let first_name = "";
    let last_name = "";
    // address fields
    let area = "";
    let block = "";
    let latitude = "";
    let longitude = "";
    let street = "";
    let building = "";
    let avenue = "";
    let unit = "";
    let floor = "";
    let guest_email = "";
    let notes = "";

    if (guest_address && map_address) {
      first_name = guest_address.shipping_address.first_name;
      last_name = guest_address.shipping_address.last_name;
      latitude = guest_address.shipping_address.json_address.latitude;
      longitude = guest_address.shipping_address.json_address.longitude;
      street = guest_address.shipping_address.json_address.street;
      building = guest_address.shipping_address.json_address.building;
      avenue = guest_address.shipping_address.json_address.avenue;
      unit = guest_address.shipping_address.json_address.unit;
      floor = guest_address.shipping_address.json_address.floor;
      guest_email = guest_address.guest_email;
      notes = guest_address.shipping_address.notes;
      if (
        guest_address.shipping_address.json_address.area !== map_address.area
      ) {
        area = map_address.area;
        block = map_address.block;
      } else {
        area = guest_address.shipping_address.json_address.area;
        block = guest_address.shipping_address.json_address.block;
      }
    } else if (checkout_firstName && checkout_lastName && map_address) {
      // address fields
      first_name = checkout_firstName;
      last_name = checkout_lastName;
      area = map_address.area;
      block = map_address.block;
      latitude = map_address.latitude;
      longitude = map_address.longitude;
    }

    return (
      <form onSubmit={this.handleSubmitGuest} autoComplete="off">
        <CustomInput
          id="id_latitude"
          size="lg"
          formControlProps={{
            fullWidth: true,
            className: "m-0"
          }}
          inputProps={{
            name: "latitude",
            type: "hidden",
            defaultValue: latitude,
            disableUnderline: true
          }}
        />
        <CustomInput
          id="id_longitude"
          size="lg"
          formControlProps={{
            fullWidth: true,
            className: "m-0"
          }}
          inputProps={{
            name: "longitude",
            type: "hidden",
            defaultValue: longitude,
            disableUnderline: true
          }}
        />

        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.firstname"
              )}
              id="id_first_name"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "first_name",
                required: true,
                defaultValue: first_name,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.lastname"
              )}
              id="id_last_name"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "last_name",
                required: true,
                defaultValue: last_name,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.email"
              )}
              id="id_email"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "email",
                type: "email",
                required: true,
                defaultValue: guest_email,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} className="dp-f alignItem">
            <FlagSelect
              value={this.state.phone}
              handleFlagOnChange={this.handleOnChange}
            />
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.phone"
              )}
              id="id_phone_number"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "phone_number",
                required: true,
                //defaultValue: phone_number,
                type: "number",
                disabled: true,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t("profileModal.accountSettings.shippingAddress.area")}
              id="id_area"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "area",
                required: true,
                defaultValue: area,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.block"
              )}
              id="id_block"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "block",
                required: true,
                defaultValue: block,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.street"
              )}
              id="id_street"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "street",
                required: true,
                defaultValue: street,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.avenue"
              )}
              id="id_avenue"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "avenue",
                required: true,
                defaultValue: avenue,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.house"
              )}
              id="id_house"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "building",
                required: true,
                defaultValue: building,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.floor"
              )}
              id="id_floor"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "floor",
                required: true,
                defaultValue: floor,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.apartment"
              )}
              id="id_apartment"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "apartment",
                required: true,
                defaultValue: unit,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.notes"
              )}
              id="id_notes"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "notes",
                multiline: true,
                rows: 3,
                defaultValue: notes,
                disableUnderline: true
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} md={10}>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              fullWidth
              type="submit"
            >
              {t("profileModal.accountSettings.shippingAddress.addaddress")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };
  renderLoggeInAddressForm = () => {
    const {
      checkout_firstName,
      checkout_lastName,
      map_address,
      t,
      address_list,
      userDetails,
      area_list,
      activeArea
    } = this.props;

    let first_name = "";
    let last_name = "";
    let block = "";
    let latitude = "";
    let longitude = "";
    let street = "";
    let building = "";
    let avenue = "";
    let unit = "";
    let floor = "";
    let notes = "";

    if (address_list.length > 0 ) {
      let address = address_list[0];
      let full_address = address.address;
      first_name = address.first_name;
      last_name = address.last_name;
      latitude = full_address.latitude;
      longitude = full_address.longitude;
      street = full_address.street;
      building = full_address.building;
      avenue = full_address.avenue;
      unit = full_address.unit;
      floor = full_address.floor;
      notes = address.notes;
      block = full_address.block
      //phone_number = address.phone_number;
      // this.setState({ phone: phone_number });
      if ( !_.isEmpty(map_address) && full_address.area !== map_address.area) {
        block = map_address.block;
      } else {
        block = full_address.block;
      }
    } else if (checkout_firstName && checkout_lastName &&  !_.isEmpty(map_address)) {
      first_name = checkout_firstName;
      last_name = checkout_lastName;
      block = map_address.block;
      latitude = map_address.latitude;
      longitude = map_address.longitude;
    } else if ( !_.isEmpty(map_address)) {
      first_name = checkout_firstName;
      last_name = checkout_lastName;
      block = map_address.block;
      latitude = map_address.latitude;
      longitude = map_address.longitude;
    }
    return (
      <form onSubmit={this.handleSubmitLoggedInShipping} autoComplete="off">
        <CustomInput
          id="id_latitude"
          size="lg"
          formControlProps={{
            fullWidth: true,
            className: "m-0"
          }}
          inputProps={{
            name: "latitude",
            type: "hidden",
            defaultValue: latitude,
            disableUnderline: true
          }}
        />
        <CustomInput
          id="id_longitude"
          size="lg"
          formControlProps={{
            fullWidth: true,
            className: "m-0"
          }}
          inputProps={{
            name: "longitude",
            type: "hidden",
            defaultValue: longitude,
            disableUnderline: true
          }}
        />

        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.firstname"
              )}
              id="id_first_name"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "first_name",
                required: true,
                defaultValue: first_name ? first_name : userDetails.first_name,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.lastname"
              )}
              id="id_last_name"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "last_name",
                required: true,
                defaultValue: last_name ? last_name : userDetails.last_name,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className="dp-f alignItem">
            <FlagSelect
              value={this.state.phone}
              handleFlagOnChange={this.handleOnChange}
            />
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.phone"
              )}
              id="id_phone_number"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "phone_number",
                required: true,
                type: "number",
                disabled: true,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <SelectAutocomplete
              labelText={t("profileModal.accountSettings.shippingAddress.area")}
              required={true}
              value={area_list.find(i => i.code === activeArea.area_id)}
              inputProps={{
                required: true
              }}
              name="area_active"
              onInputChange={this.onInputChange}
              placeholder={t("common.label")}
              autocompleteOptions={area_list}
              selectOnChange={this.handleSelectChange}
              getOptionLabel={({ name }) => name}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.block"
              )}
              id="id_block"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "block",
                required: true,
                defaultValue: block,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.street"
              )}
              id="id_street"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "street",
                required: true,
                defaultValue: street,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.avenue"
              )}
              id="id_avenue"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "avenue",
                required: false,
                defaultValue: avenue,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.house"
              )}
              id="id_house"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "building",
                required: true,
                defaultValue: building,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.floor"
              )}
              id="id_floor"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "floor",
                required: false,
                defaultValue: floor,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.apartment"
              )}
              id="id_apartment"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "apartment",
                required: false,
                defaultValue: unit,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.notes"
              )}
              id="id_notes"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "notes",
                multiline: true,
                rows: 3,
                defaultValue: notes,
                disableUnderline: true
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} md={10}>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              fullWidth
              type="submit"
            >
              {t("profileModal.accountSettings.shippingAddress.addaddress")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };
  handleSelectChange = value => {
    const { updateActiveArea } = this.props;
    let areaObj = {
      area: value.name,
      area_id: value.code
    };
    updateActiveArea(areaObj);
  };
  
  renderLoggeInAddressPartnerForm = () => {
    const {
      map_address,
      t,
      partner_address,
      userDetails,
      area_list,
      activeArea
    } = this.props;
    let first_name = "";
    let last_name = "";
    let block = "";
    let latitude = "";
    let longitude = "";
    let street = "";
    let building = "";
    let avenue = "";
    let unit = "";
    let floor = "";

    if (partner_address) {
      let address = partner_address;
      let full_address = address.address;

      first_name = address.first_name;
      last_name = address.last_name;
      latitude = full_address.latitude;
      longitude = full_address.longitude;
      street = full_address.street;
      building = full_address.building;
      avenue = full_address.avenue;
      unit = full_address.unit;
      floor = full_address.floor;

      if ( !_.isEmpty(map_address) && full_address.area !== map_address.area) {
        block = map_address.block;
        latitude = map_address.latitude;
        longitude = map_address.longitude;
      } else {
        block = full_address.block;
        latitude = full_address.latitude;
        longitude = full_address.longitude;
      }
    } else if (map_address&& !_.isEmpty(map_address)) {
      block = map_address.block;
      latitude = map_address.latitude;
      longitude = map_address.longitude;
    }

    return (
      <form onSubmit={this.handleSubmitLoggedInPartner} autoComplete="off">
        {/* Hidden inputs */}
        <CustomInput
          id="id_latitude"
          size="lg"
          formControlProps={{
            fullWidth: true,
            className: "m-0"
          }}
          inputProps={{
            name: "latitude",
            type: "hidden",
            defaultValue: latitude,
            disableUnderline: true
          }}
        />
        <CustomInput
          id="id_longitude"
          size="lg"
          formControlProps={{
            fullWidth: true,
            className: "m-0"
          }}
          inputProps={{
            name: "longitude",
            type: "hidden",
            defaultValue: longitude,
            disableUnderline: true
          }}
        />

        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.firstname"
              )}
              id="id_first_name"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "first_name",
                required: true,
                defaultValue: first_name ? first_name : userDetails.first_name,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.lastname"
              )}
              id="id_last_name"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "last_name",
                required: true,
                defaultValue: last_name ? last_name : userDetails.last_name,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <SelectAutocomplete
              labelText={t("profileModal.accountSettings.shippingAddress.area")}
              required={true}
              value={area_list.find(i => i.code === activeArea.area_id)}
              inputProps={{
                required: true,
                
              }}
              name="area_active"
              input_name="input_area_active"
              onInputChange={this.onInputChange}
              placeholder={t("common.label")}
              autocompleteOptions={area_list}
              selectOnChange={this.handleSelectChange}
              getOptionLabel={({ name }) => name}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.block"
              )}
              id="id_block"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "block",
                required: true,
                defaultValue: block,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.street"
              )}
              id="id_street"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "street",
                required: true,
                defaultValue: street,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.avenue"
              )}
              id="id_avenue"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "avenue",
                required: false,
                defaultValue: avenue,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.house"
              )}
              id="id_house"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "building",
                required: true,
                defaultValue: building,
                disableUnderline: true
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.floor"
              )}
              id="id_floor"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "floor",
                required: false,
                defaultValue: floor,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.apartment"
              )}
              id="id_apartment"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "apartment",
                required: false,
                defaultValue: unit,
                disableUnderline: true
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} md={10}>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              fullWidth
              type="submit"
            >
              {t("profileModal.partner.add")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  renderCompanyAddressForm = () => {
    const {
      map_address,
      t,
      company_address,
      company_signup_data,
      area_list,
      activeArea
    } = this.props;

    let block = "";
    let latitude = "";
    let longitude = "";
    let street = "";
    let building = "";
    let avenue = "";
    let unit = "";
    let floor = "";

    if (company_address ) {
      let address = company_address;
      latitude = address.latitude;
      longitude = address.longitude;
      street = address.street;
      building = address.building;
      avenue = address.avenue;
      unit = address.unit;
      floor = address.floor;

      if (map_address && !_.isEmpty(map_address) && address.area !== map_address.area) {
        block = map_address.block;
        latitude = map_address.latitude;
        longitude = map_address.longitude;
      } else {
        block = address.block;
        latitude = address.latitude;
        longitude = address.longitude;
      }
    } else if (map_address && !_.isEmpty(map_address)) {
      block = map_address.block;
      latitude = map_address.latitude;
      longitude = map_address.longitude;
    }


    return (
      <form onSubmit={this.handleSubmitCompanyAddress} autoComplete="off">
        {/* Hidden inputs */}
        <CustomInput
          id="id_latitude"
          size="lg"
          formControlProps={{
            fullWidth: true,
            className: "m-0"
          }}
          inputProps={{
            name: "latitude",
            type: "hidden",
            defaultValue: latitude,
            disableUnderline: true
          }}
        />
        <CustomInput
          id="id_longitude"
          size="lg"
          formControlProps={{
            fullWidth: true,
            className: "m-0"
          }}
          inputProps={{
            name: "longitude",
            type: "hidden",
            defaultValue: longitude,
            disableUnderline: true
          }}
        />

        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.firstname"
              )}
              id="id_first_name"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "first_name",
                required: true,
                defaultValue: company_signup_data.first_name,
                disableUnderline: true,
                onChange: this.handleTextChange
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.lastname"
              )}
              id="id_last_name"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "last_name",
                required: true,
                defaultValue: company_signup_data.last_name,
                disableUnderline: true,
                onChange: this.handleTextChange
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <SelectAutocomplete
              labelText={t("profileModal.accountSettings.shippingAddress.area")}
              required={true}
              value={area_list.find(i => i.code === activeArea.area_id)}
              inputProps={{
                required: true
              }}
              onInputChange={this.onInputChange}
              placeholder={t("common.label")}
              autocompleteOptions={area_list}
              selectOnChange={this.handleSelectChange}
              getOptionLabel={({ name }) => name}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.block"
              )}
              id="id_block"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "block",
                required: true,
                defaultValue: block,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.street"
              )}
              id="id_street"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "street",
                required: true,
                defaultValue: street,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.avenue"
              )}
              id="id_avenue"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "avenue",
                required: false,
                defaultValue: avenue,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.house"
              )}
              id="id_house"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "building",
                required: true,
                defaultValue: building,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.floor"
              )}
              id="id_floor"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "floor",
                required: false,
                defaultValue: floor,
                disableUnderline: true
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText={t(
                "profileModal.accountSettings.shippingAddress.apartment"
              )}
              id="id_apartment"
              size="lg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "apartment",
                required: false,
                defaultValue: unit,
                disableUnderline: true
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} md={10}>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              fullWidth
              type="submit"
            >
              {t("companySignup.addCompAddress")} 
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  renderAddressForm = () => {
    const { add_address_event } = this.props;
    switch (add_address_event) {
      case "loggedin_shipping":
        return this.renderLoggeInAddressForm();
      case "loggedin_partner":
        return this.renderLoggeInAddressPartnerForm();
      case "company_signup":
        return this.renderCompanyAddressForm();
      default:
        return this.renderGuestAddressForm();
    }
  };
  renderAddressFormTitle = () => {
    const { add_address_event, t } = this.props;
    switch (add_address_event) {
      case "loggedin_partner":
        return  t("profileModal.accountSettings.shippingAddress.confirmPickAdd");
      case "company_signup":
        return t("companySignup.confirmAddress");
      default:
        return  t("profileModal.accountSettings.shippingAddress.confirm");
    }
  };

  render() {
    const { classes, t, direction } = this.props;
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={this.customClose}
          >
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h3 className="m-0">
                    {this.renderAddressFormTitle()}
                  </h3>
                </GridItem>
              </GridContainer>
              {this.renderAddressForm()}
              <GridContainer justify="center">
                <GridItem xs={12} md={10}>
                  <Button
                    link
                    size="lg"
                    fullWidth
                    type="button"
                    onClick={this.openModal("mapModal")}
                  >
                    {t("profileModal.accountSettings.shippingAddress.gotomap")}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  addAddress: (address, page = "checkout") => {
    dispatch({
      type: AddressActionTypes.ADD_ADDRESS_REQUEST,
      payload: address,
      page
    });
  },
  patchAddressRequest: (address, page = "checkout") => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADDRESS_REQUEST,
      payload: address,
      page
    });
  },
  guestAddress: address => {
    dispatch({
      type: AddressActionTypes.SET_GUEST_ADDRESS,
      payload: address
    });
  },
  patchPartnerAddress: address => {
    dispatch({
      type: VendorActionTypes.POST_PARTNER_ADDRESS_REQUEST,
      payload: address
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  updateData: data => {
    dispatch({
      type: AccountActionTypes.UPDATE_COMPANY_SIGNUP_DATA,
      data
    });
  },
  updateCompanyAddress: data => {
    dispatch({
      type: AddressActionTypes.SET_COMPANY_ADDRESS,
      data
    });
  },
  updateActiveArea: activeArea => {
    dispatch({
      type: AddressActionTypes.UPDATE_ACTIVE_AREA,
      activeArea
    });
  },
  updateMapAddress: address => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_ADDRESS,
      address
    });
  },
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.addressReducer,
  ...state.vendorProductReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ConfirmAddress);
