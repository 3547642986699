import { call, put } from "redux-saga/effects";
import i18n from "locales/i18n.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import ActionTypes from "constants/productConstants.jsx";
import { getProductList, getProductDetail } from "actions/productActions.jsx";
import { store } from "utils/store.jsx";
import * as Sentry from '@sentry/browser';
import history from "history.js";


function* getInitialFacetsWorker(params) {
  const { parent_cat, catActive, top_categories, urlToFetch, sortParams, attParam } = params;
  try {
    let response = yield call(getProductList);
    let data = response.data;
    let parent_category = data &&
    data.fields &&
    data.fields.category &&
    data.fields.category.length > 0
    ? data.fields.category
    : [];
    let categories =
      data &&
        data.fields &&
        data.fields.product_class &&
        data.fields.product_class.length > 0
        ? data.fields.product_class
        : []; //NOTE: uses product class for now. instead of prouduct category
    let attribute_value =
      data &&
        data.fields &&
        data.fields.attribute_value &&
        data.fields.attribute_value.length > 0
        ? data.fields.attribute_value
        : []; //NOTE: uses product class for now. instead of prouduct category
    yield put({
      type: ActionTypes.GET_INITIAL_FACET_SUCCESS,
      categories,
      attribute_value,
      parent_category
    });

    // Used in showing active category product listing
    if (parent_cat || urlToFetch) {
      let active_cat = parent_category.find(cat => cat.text === parent_cat);
      let catActiveObj = categories.find(cat => cat.text === catActive);
      if (active_cat !== undefined || urlToFetch) {
        let sort_id = sortParams;
        if (active_cat) {
          yield put({
            type: ActionTypes.UPDATE_ACTIVE_PARENT_CATEGORY,
            parent_category: active_cat
          });
        }
        if (catActiveObj) {
          yield put({
            type: ActionTypes.UPDATE_ACTIVE_CATEGORY,
            category: catActiveObj
          });
        }
        yield put({
          type: ActionTypes.GET_PRODUCT_LIST_REQUEST,
          payload: urlToFetch,
          sort_id,
          attParam
        });
      } else {
        // else redirect to 404 page
        let protocol = window.location.protocol;
        let host = window.location.hostname;
        let port = window.location.port;
        let target_url = host;
        if (port !== "") {
          target_url = target_url + ":" + port;
        }
        // target_url = protocol + "//" + target_url +":"+port+"/404"; //Local Host
        target_url = protocol + "//" + target_url + "/404";
        history.push(target_url);

        let txt = i18n.t("common.page"); //Snanck Bar Message - Page Not Found
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    }

    // Used in shop home page to show the top categories in rows.
    if (top_categories) {
      // populate the redux state top_categories by items from the narrow_url of categories.
      let temp_top_categories = [];
      for (let i = 0; i < categories.length; i++) {
        let category = categories[i];

        try {
          let res = yield call(getProductList, category.narrow_url);
          let data = res.data;
          let parent_category = data.fields.category.find(i=>i.count>0);
          temp_top_categories.push({
            category: category,
            products: data.objects.results,
            parent_category:parent_category
          });
        } catch (e) {
          //Sentry.captureException(e);
          // We dont really need to do anythin when error.
        }
      }
      // update the redux top_categories list
      yield put({
        type: ActionTypes.UPDATE_TOP_CATEGORY,
        top_categories: temp_top_categories
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    let error = e;
    yield put({ type: ActionTypes.GET_PRODUCT_LIST_FAILED, error });
    let txt = i18n.t("common.error.network");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
}

function* getProductListWorker(action) {
  const { payload, sort_id, attParam } = action;
  try {
    let response = payload
      ? yield call(getProductList, payload)
      : yield call(getProductList);
    let data = response.data;
    let objects = data.objects;
    let sort_by = data.sort_by;
    let category =
      data &&
        data.fields &&
        data.fields.product_class &&
        data.fields.product_class.length > 0
        ? data.fields.product_class
        : [];
        let parent_category = data &&
        data.fields &&
        data.fields.category &&
        data.fields.category.length > 0
        ? data.fields.category
        : [];
    let attribute_value =
      data &&
        data.fields &&
        data.fields.attribute_value &&
        data.fields.attribute_value.length > 0
        ? data.fields.attribute_value
        : [];
    if (sort_id) {
      let value = sort_by.find(i => i.code === sort_id);
      yield put({
        type: ActionTypes.UPDATE_ACTIVE_SORT,
        value
      });
    }
    if (attParam && attribute_value.length > 0) {
      let attribute = [];
      attParam.map(att => {
        let att_id = att.split(":")[1];
        let updateAtt = attribute_value.find(i => i.text === att_id);
        attribute.push(updateAtt);
        return null;
      });
      yield put({
        type: ActionTypes.ACTIVE_ATTRIBUTE_UPDATE,
        attribute
      });
    }
    yield put({
      type: ActionTypes.GET_PRODUCT_LIST_SUCCESS,
      objects,
      sort_by,
      category,
      parent_category,
      // product_class,
      attribute_value
    });
  } catch (e) {
    Sentry.captureException(e);
    let error = e;
    yield put({ type: ActionTypes.GET_PRODUCT_LIST_FAILED, error });
    let txt = i18n.t("common.error.network");
    yield put(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
}

function* getProductDetailWorker(action) {
  const { payload } = action;
  try {
    let response = yield call(getProductDetail, payload);
    let data = response.data;
    yield put({ type: ActionTypes.GET_PRODUCT_DETAIL_SUCCESS, data });
    let userDetails = store.getState().accountReducer.userDetails;
    if (
      userDetails &&
      data &&
      data.vendor &&
      userDetails.pk === data.vendor.pk
    ) {
      let txt = i18n.t("buyingPage.productGrid.belong");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
  } catch (e) {

    let error = e;
    let catched_errors = [400, 403, 401, 406];
    if (e.response && catched_errors.includes(e.response.status)) {
      const res_object = e.response.data;
      let messages = [];
      for (let i in res_object) {
        let error_val = res_object[i];
        if (error_val) {
          messages.push(error_val);
        }
      }
      for (let i = 0; i < messages.length; i++) {
        let txt = messages[i];
        yield put(
          enqueueSnackbar({
            message: txt,
            options: {
              variant: "warning",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center"
              }
            }
          })
        );
      }
    } else if (e.response.status === 404) {
      let txt = i18n.t("common.page");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    } else {
      Sentry.captureException(e);
      let txt = i18n.t("common.error.network");
      yield put(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    }
    yield put({ type: ActionTypes.GET_PRODUCT_DETAIL_FAILED, error });
  }
}

export { getProductListWorker, getProductDetailWorker, getInitialFacetsWorker };
