import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

import {  htPrimaryGreen, htPrimaryLight} from "kayfik/assets/jss/universal.jsx"; 

const shopStyle = theme => ({
    ...styles,
    ...modalStyle(theme),
    shopModal:{
        width:"500px",
        position:"fixed",
        zIndex:"100",
        left: "0!important",
        bottom:"35% !important",
        background:htPrimaryGreen +"!important",
        flexDirection:"column",
        [theme.breakpoints.down("xs")]: {
            width:"350px",
        }
    },
    close:{
        color: htPrimaryLight +"!important",
        width:"1.5em !important",
        height:"2em !important"
    },
    closeRow:{
        display:"flex",
        justifyContent:"flex-end",
        paddingTop:"15px !important"
    },
    paper:{
        backgroundColor: "transparent !important",
    }
})

export default shopStyle;