import React from "react";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import { withTranslation } from "react-i18next";
// @material-ui/core components
import compose from "recompose/compose";
import { connect } from "react-redux";
import ModalActionTypes from "constants/modalConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Muted from "components/Typography/Muted.jsx";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";

class PartnerAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bidNow: false,
      buyNow: true
    };
  }

  componentDidMount() {
    const {
      getPartnerAddress,
      getAreaList,
      updateAddressEvent,
      account_token,
      updateMapAddress,
      update_user_location
    } = this.props;
    getPartnerAddress();
    getAreaList();
    if (account_token) {
      updateAddressEvent("loggedin_partner");
    }
    updateMapAddress({});
    if (navigator.geolocation) {
      this.props.update_map_loader(true);
      navigator.geolocation.getCurrentPosition(
        position => {
         this.setState(
            {
              currentLat: position.coords.latitude,
              currentLong: position.coords.longitude,
              error: null
            },
            () => {
              const location = {
                currentLat: this.state.currentLat,
                currentLong: this.state.currentLong
              };
              update_user_location(location);
              this.props.update_map_loader(false);
            }
          );
        },
        error => {this.setState({ error: error.message }); this.props.update_map_loader(false);}
        , { enableHighAccuracy: true, timeout: 200000 }
      );
    }
  }

  openModal = modal_type => async () => {
    const { showModal } = this.props;
    showModal(
      {
        open: true,
        title: modal_type,
        nextModal: "profileModal",
        address: "pickup"
      },
      modal_type
    );
  };

  handleAddressChange = () => {
    const { partner_address } = this.props;
    if (partner_address && partner_address.address.area) {
      this.openModal("confirmAddress")();
    } else {
      this.openModal("mapModal")();
    }
  };

  getAreaName = id => {
    const { area_list } = this.props;
    if (area_list && area_list.length > 0) {
      let add = area_list.find(item => item.code === id);
      if (add) {
        return add.name;
      }
      return null;
    }
  };

  renderAddressCard = () => {
    const { partner_address, t } = this.props;
    let address = partner_address;
    let fullAddress = null;
    if (address) {
      fullAddress = address.address;
      return (
        <Card bordered>
          <CardBody>
            <div className="d-flex justify-content-between">
              <h5 className="m-0">{t("profileModal.address")}</h5>
              <Link
                underline="none"
                component="button"
                className="color-pl"
                onClick={this.handleAddressChange}
              >
                <h5 className="m-0">{t("profileModal.partner.edit")}</h5>
              </Link>
            </div>
            {address ? (
              <div>
                {address.first_name ? (
                  <h5 className="mb-1">
                    <Muted>
                      {t("common.firstname")} {address.first_name}
                    </Muted>
                  </h5>
                ) : null}
                {address.last_name ? (
                  <h5 className=" mt-0 mb-1">
                    <Muted>
                      {t("common.lastname")} {address.last_name}
                    </Muted>
                  </h5>
                ) : null}
                {fullAddress.area ? (
                  <h5 className="mt-0 mb-1">
                    <Muted>
                      {t("common.area")} {this.getAreaName(fullAddress.area_id)}
                    </Muted>
                  </h5>
                ) : null}
                {fullAddress.block ? (
                  <h5 className="mt-0 mb-1">
                    <Muted>
                      {t("common.block")} {fullAddress.block}
                    </Muted>
                  </h5>
                ) : null}
                {fullAddress.avenue ? (
                  <h5 className="mt-0 mb-1">
                    <Muted>
                      {t("common.avenue")} {fullAddress.avenue}
                    </Muted>
                  </h5>
                ) : null}
                {fullAddress.street ? (
                  <h5 className="mt-0 mb-1">
                    <Muted>
                      {t("common.street")} {fullAddress.street}
                    </Muted>
                  </h5>
                ) : null}
                {fullAddress.building ? (
                  <h5 className="mt-0 mb-1">
                    <Muted>
                      {t("common.house")} {fullAddress.building}
                    </Muted>
                  </h5>
                ) : null}
                {fullAddress.floor ? (
                  <h5 className="mt-0 mb-1">
                    <Muted>
                      {t("common.floor")} {fullAddress.floor}
                    </Muted>
                  </h5>
                ) : null}
                {fullAddress.unit ? (
                  <h5 className="mt-0 mb-1">
                    <Muted>
                      {t("common.apartment")} {fullAddress.unit}
                    </Muted>
                  </h5>
                ) : null}
              </div>
            ) : (
              <p>{t("profileModal.partner.noaddress")}</p>
            )}
          </CardBody>
        </Card>
      );
    } else {
      return (
        <GridContainer className="mt-4">
          <GridItem xs={12}>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              className="mb-3"
              fullWidth
              onClick={this.openModal("whatSellingModal")}
            >
              {t("homePage.header.sell")}
            </Button>
          </GridItem>
        </GridContainer>
      );
    }
  };
  render() {
    const { vendor_fetching, t, direction, partner_address } = this.props;
    let address_available = partner_address;
    if (vendor_fetching) {
      return (
        <Hidden smDown implementation="css">
          <Loader noHeight={true} />
        </Hidden>
      );
    }
    return (
      <div className="main-background-color  product_listing marginAuto pt-3">
        <h4
          className={
            direction === "rtl" ? "profile-Align" : "ta-left my-0 fw-400"
          }
        >
          {t("profileModal.address")}
        </h4>
        {address_available !== null ? null : (
          <p className="mt-4"> {t("profileModal.partner.noaddress")}</p>
        )}
        {this.renderAddressCard()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updateAddressEvent: event => {
    dispatch({
      type: AddressActionTypes.UPDATE_ADD_ADDRESS_EVENT,
      event
    });
  },
  getPartnerAddress: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_ADDRESS_REQUEST
    });
  },
  getAreaList: () => {
    dispatch({
      type: AddressActionTypes.GET_AREA_LIST_REQUEST
    });
  },
  updateMapAddress: address => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_ADDRESS,
      address
    });
  },
  update_user_location: location => {
    dispatch({
      type: AddressActionTypes.USER_CURRENT_LOCATION,
      payload: location
    });
  },
  update_map_loader: mapLoader => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_LOADER,
      mapLoader
    });
  },
  
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.vendorProductReducer,
  ...state.accountReducer,
  ...state.globalReducer,
  ...state.addressReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(PartnerAddress);
