import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import { connect } from "react-redux";

// kayfik components
import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.jsx";
import queryString from "query-string";

// REdux
import ModalActionTypes from "constants/modalConstants.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import { withTranslation } from "react-i18next";
import AccountActionTypes from "constants/accountConstants.jsx";

class BlankPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmEmail: false,
    };
  }

  componentDidMount() {
    this.renderModalBaseOnParams();
  }

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);
    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }
    return params;
  };

  renderModalBaseOnParams = () => {
    const { account_token, t, showSnackbar } = this.props;
    let params = this.getExtraParameter();
    // Do something if modal exists on the url and match anything from modal_types
    let modal_props = {
      open: true
    };

    if (params.token && params.uid) {
      modal_props["token"] = params.token;
      modal_props["uid"] = params.uid;
      let modal_type = "passwordResetModal";
      this.openModal(modal_type, modal_props)();
    }

    if (params.modal) {
      let target_modal = params.modal;
      let modal_type = target_modal;

      // Force the user to login before opening the next modal
      if (params.modal && !account_token) {
        modal_type = "loginModal";
        modal_props["nextModal"] = target_modal;
        this.openModal(modal_type, modal_props)();
      } else {
        let largeModal = false;
        if (modal_type === "profileModal") {
          largeModal = true;
        }
        modal_props["modalLarge"] = largeModal;
        this.openModal(modal_type, modal_props)();
      }
    }

    if (params.tap_id) {
      let modal_type = "completeOrderModal";
      modal_props["goToShop"] = () => this.props.history.push("/shop");
      modal_props["goToCheckout"] = () => this.props.history.push("/checkout");
      this.openModal(modal_type, modal_props)();
    }
    // open confirm email modal
    if (params.key && params.email) {
      // let modal_type = "confirmEmailModal";
      // modal_props["nextModal"] = "loginModal";
      // this.setState({ key: params.key, email: params.email });
      this.setState({ confirmEmail: true}, ()=>{
        this.postConfirmEmail(params.key);
      });
      // this.openModal(modal_type, modal_props)();
    }
    // check if itwas from success company signup
    if (params.company) {
      // show a notification
      let txt = t("loginModal.successLogin");
      showSnackbar(txt, "success");
      this.props.history.push("/");
    }
  };

  postConfirmEmail=(key)=>{
    const { postConfirmEmailKey } = this.props;
    let form_data = {
      key: key
    };
    postConfirmEmailKey(form_data);
  };

  openModal = (modal_type, modal_props) => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  render() {
    const { confirmEmail, } = this.state;
    const { t, emailConfirmMsg } = this.props;
    return (
      <div style={{ height:"100vh", width: "100vw" , background:"#fff"}}>
        <div className="confirmEmailCardContainer">
        {confirmEmail? 
        <div  className=" confirmEmailCard">
         <h2 className="m-0">{t("confirmemail.title")}</h2> 
         <h5 className="mx-0 my-3">{emailConfirmMsg} </h5> 
         {/* <h2 >
         {t("confirmemail.title")} 
                  </h2>
                  <div className="">
                  {emailConfirmMsg} 
                  </div> */}
        </div> : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
    showModal: (modalProps, modalType) => {
      dispatch({
        type: ModalActionTypes.SHOW_MODAL_REQUEST,
        modalProps,
        modalType
      });
    },
    updateNextModal: nextModal => {
      dispatch({
        type: ModalActionTypes.UPDATE_NEXT_MODAL,
        nextModal: nextModal
      });
    },
    productDetailVariable: value => {
      dispatch({
        type: VendorProductActionTypes.UPDATE_PRODUCT_DETAIL_VARIABLE,
        payload: value
      });
    },
    getCMSData: code => {
      dispatch({
        type: GlobalActionTypes.CMS_PAGE_REQUEST,
        code
      });
    },
    showSnackbar: (txt, action = "error") => {
      dispatch(
        enqueueSnackbar({
          message: txt,
          options: {
            variant: action,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        })
      );
    },
    postConfirmEmailKey: form_data => {
      dispatch({
        type: AccountActionTypes.CONFIRM_EMAIL_REQUEST,
        form_data
      });
    }
  });

const mapStateToProps = state => ({
    ...state.modalReducer,
    ...state.accountReducer,
    ...state.globalReducer
  });
  

export default compose(
  withStyles(errorPageStyle),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(BlankPage);
