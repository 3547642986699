import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  SHOW_MODAL_REQUEST: null,
  SHOW_MODAL_SUCCESS: null,

  HIDE_MODAL_REQUEST: null,
  HIDE_MODAL_SUCCESS: null,

 
  UPDATE_TEMP_MODAL_PROPS:null, 
  UPDATE_NEXT_MODAL:null, 
});

export default ActionTypes;
