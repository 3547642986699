import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  POST_CREATE_BID_REQUEST: null,
  POST_CREATE_BID_SUCCESS: null,
  POST_CREATE_BID_FAILED: null,

  GET_BID_HISTORY_REQUEST: null,
  GET_BID_HISTORY_SUCCESS: null,
  GET_BID_HISTORY_FAILED: null,

  UPDATE_BID_ITEM: null,
  BID_RESET: null
});

export default ActionTypes;
