import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import ActionTypes from "constants/globalConstants.jsx";

const initialState = {
  language: "en",
  cms_data: {},
  global_fetching: false,
  error: null,
  direction: "ltr",
  sendMessage: null,
  openMobileDropdown: false,
  goTo: null,
  defaultCountryCode: "965",
  grid_video_url: "",
  banner_video_url: "",
  dynamicHistoryRoute: {},
  deviceid: null,
  webpush: true,
  categories: [],
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_LANGUAGE_SUCCESS:
      return { ...state, language: action.lang, global_fetching: true };

    case ActionTypes.CMS_PAGE_REQUEST:
      return { ...state, global_fetching: true, error: null };
    case ActionTypes.CMS_PAGE_SUCCESS:
      return {
        ...state,
        global_fetching: false,
        error: null,
        cms_data: action.data
      };
    case ActionTypes.CMS_PAGE_FAILED:
      return { ...state, global_fetching: false, error: action.error };
    
    case ActionTypes.UPDATE_CATEGORIES:
        return {
          ...state,
          global_fetching: false,
          error: null,
          categories: action.data
        };

    case ActionTypes.CHANGE_DIRECTION_SUCCESS:
      return { ...state, direction: action.dir };

    case ActionTypes.POST_SEND_MESSAGE_REQUEST:
      return { ...state, error: null, global_fetching: true };
    case ActionTypes.POST_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessage: action.data,
        global_fetching: false,
        error: null
      };
    case ActionTypes.POST_SEND_MESSAGE_FAILED:
      return { ...state, global_fetching: false, error: action.error };

    case ActionTypes.POST_SUBSCRIBE_REQUEST:
      return { ...state, error: null, global_fetching: true };
    case ActionTypes.POST_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        subscribe: action.data,
        global_fetching: false,
        error: null
      };
    case ActionTypes.POST_SUBSCRIBE_FAILED:
      return { ...state, global_fetching: false, error: action.error };

    // update mobile dropdown

    case ActionTypes.GET_TIMEWINDOW_REQUEST:
      return { ...state, error: null };
    case ActionTypes.GET_TIMEWINDOW_SUCCESS:
      return {
        ...state,
        timeWindows: action.data,
        error: null
      };
    case ActionTypes.GET_TIMEWINDOW_FAILED:
      return { ...state, error: action.error };

    // update mobile dropdown
    case ActionTypes.UPDATE_MOBILE_DROPDOWN:
      return { ...state, openMobileDropdown: action.value };

    case ActionTypes.UPDATE_GO_TO:
      return { ...state, goTo: action.goTo };

    case ActionTypes.RESET_GO_TO:
      return { ...state, goTo: null };

    case ActionTypes.UPDATE_GRID_VIDEO_URL:
      return { ...state, grid_video_url: action.value };

    case ActionTypes.UPDATE_BANNER_VIDEO_URL:
      return { ...state, banner_video_url: action.value };

    case ActionTypes.DYNAMIC_HISTORY_PUSH:
      return { ...state, dynamicHistoryRoute: action.dynamicHistoryRoute };
    case ActionTypes.UPDATE_DEVICE_ID:
      return { ...state, deviceid: action.deviceid };

    case ActionTypes.UPDATE_WEB_PUSH:
      return { ...state, webpush: action.webpush };

    default:
      return state;
  }
};

const persistConfig = {
  key: "global",
  storage: storage,
  blacklist: [
    "global_fetching",
    "error",
    "openMobileDropdown",
    "goTo",
    "deviceid"
  ]
};

export default persistReducer(persistConfig, globalReducer);
