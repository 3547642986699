/* eslint-disable react/prop-types */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import compose from "recompose/compose";
// @material-ui/icons
import CameraAlt from "@material-ui/icons/CameraAlt";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// core components
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// React i18next
import { withTranslation } from "react-i18next";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
// redux
import { connect } from "react-redux";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import carouselSectionStyle from "kayfik/assets/jss/views/LandingPage/Sections/carouselSectionStyle";
import parse from "html-react-parser";


const settings = {
  dots: true,
  infinite: true,
  autoplaySpeed: 8000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true
  // rtl: true
};

class SectionCarousel extends React.Component {
  handleOpenSellModal = () => {
    // const { account_token } = this.props;

    let target_modal = "whatSellingModal";
    let modal_type = target_modal;
    let modal_props = {
      open: true,
      modalLarge: true
    };

    this.openModal(modal_type, modal_props)();
  };

  openModal = (modal_type, modal_props) => async () => {
    const { modalProps, closeModal, showModal } = this.props;

    if (modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  renderSlickItems = images => {
    const { classes, direction, t, carousel_height } = this.props;
    let value = carousel_height + "vh";
    if (images.length > 0) {
      if (direction === "rtl") {
        images = images.reverse();
      }
      return images.map((item, index) => {        
        return (
          <div key={index}>
            <div
              className={classNames(classes.pageHeader, classes.carouselHt,classes.removeBg )}
              style={
                item.img
                  ? { backgroundImage: `url("${item.img}")`, height: value}
                  : null
              }
            >
              <div className={classes.container}>
                {direction === "rtl" ? (
                  <GridContainer>
                    <GridItem xs={false} sm={false} md={1} />

                    <GridItem
                      xs={12}
                      sm={11}
                      md={5}
                      className={classNames(
                        classes.mlAuto,
                        classes.mrAuto,
                        classes.textRight,
                        "jcc",
                        "landingCarouselSMGrid"
                      )}
                    >
                      {item.title ? (
                        // eslint-disable-next-line prettier/prettier
                        <h1 className={classNames(classes.title, "arabic")}>{item.title}</h1>
                      ) : null}
                      {item.content ? (
                        <h4 className="arabic">{item.content?parse(item.content):""}</h4>
                      ) : null}
                      <div className="text-center mt-3">
                        <Button
                          size="lg"
                          color="primaryLight"
                          className={classNames(
                            classes.btnSize,
                            classes.btnSizeAR
                          )}
                          onClick={this.handleOpenSellModal}
                        >
                          <span
                            className={
                              direction === "rtl"
                                ? "hide-cameraAR"
                                : "hide-camera"
                            }
                          >
                            {t("homePage.sectionTwo.sellonKayfik")}
                          </span>
                          <span
                            className={
                              direction === "rtl"
                                ? "show-cameraAR"
                                : "show-camera"
                            }
                          >
                            {t("homePage.sectionTwo.sell")}
                          </span>
                          <CameraAlt className="ml-1" />
                        </Button>
                      </div>
                    </GridItem>
                    <GridItem xs={false} sm={false} md={6} />
                  </GridContainer>
                ) : (
                    <GridContainer>
                      <GridItem xs={false} sm={false} md={6} />
                      <GridItem
                        xs={12}
                        sm={11}
                        md={5}
                        className={classNames(
                          classes.mlAuto,
                          classes.mrAuto,
                          classes.textLeft,
                          "jcc",
                          "landingCarouselSMGrid"
                        )}
                      >
                        {item.title ? (
                          <h1 className={classes.title}>{item.title}</h1>
                        ) : null}
                        {item.content ? <h4>{parse(item.content)}</h4> : null}
                        <div className="text-center mt-3 ">
                          <Button
                            size="lg"
                            color="primaryLight"
                            className={classes.btnSize}
                            onClick={this.handleOpenSellModal}
                          >
                            <CameraAlt className="mr-1" />
                            <span className="hide-camera">
                              {t("homePage.sectionTwo.sellonKayfik")}
                            </span>
                            <span className="show-camera">
                              {t("homePage.sectionTwo.sell")}
                            </span>
                          </Button>
                        </div>
                      </GridItem>
                      <GridItem xs={false} sm={false} md={1} />
                    </GridContainer>
                  )}
              </div>
            </div>
          </div>
        );
      });
    }
  };

  render() {
    const { cms_data, direction, modalType } = this.props;
    if (isWidthDown("sm", this.props.width) || modalType !== null) {
      if(this.slider){
        this.slider.slickPause();
      }
    }else{
      if(this.slider){
        this.slider.slickPlay();
      }     
    }

    if (cms_data.home) {
      if (direction === "rtl") {
        settings["rtl"] = true;
      }
      let sections = cms_data.home.sections;
      let carousel = sections.find(sec => sec.slug === "carousel-section");
      if (carousel) {
        return (
          <div id={this.props.id}>
            <Carousel ref={slider => (this.slider = slider)}  {...settings}  >
              {this.renderSlickItems(carousel.images)}
            </Carousel>
          </div>
        );
      }
    }
    return null;
  }
}
const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  }
});

export default compose(
  withStyles(carouselSectionStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withWidth()
)(SectionCarousel);
