import React from "react";
import classNames from "classnames";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import Muted from "components/Typography/Muted.jsx";
import Typography from "@material-ui/core/Typography";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalPagination from "kayfik/components/Pagination/newPage.jsx";
import Close from "@material-ui/icons/Close";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import Badge from "components/Badge/Badge.jsx";
import {
  postActionNotificationClicked,
  showStatusMessage
} from "utils/helpers.jsx";
import { decimalPlace } from "utils/helpers.jsx";

class ProductListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showProductDetail = (url , action_url) => () => {
    const {
      getProductDetail,
      updateAccountPageComponent,
      resetLoadMore,
      getBidDetail,
      getCounterDetail
    } = this.props;

    //Redirecting to the concern Page for Partners accounts
    if (action_url && action_url.page && action_url.url) {
        if (action_url.page === "bid_page") {
          getBidDetail(action_url.url)
          updateAccountPageComponent("biddetail"); // Bid Detail Page
        } else if (action_url.page === "counter_offer_page") {
          getCounterDetail(action_url.url)
          updateAccountPageComponent("counterofferdetail");  // Counter Offer Detail Page
        }
    } else {
      getProductDetail(url); // Product listing detail page
      updateAccountPageComponent("productdetail");
    }
    resetLoadMore(false, null);
  };

  // To updated the list if it comes with email link.
  componentDidMount() {
    const { getPartnerProducts } = this.props;
    getPartnerProducts();
  }

  deleteItem = deleteUrl => {
    const { deletePartnerProduct, t } = this.props;
    if (
      window.confirm(
        t(
          "profileModal.accountSettings.shippingAddress.productDetailPage.deleteItem"
        )
      )
    ) {
      deletePartnerProduct(deleteUrl);
    }
  };

  calculateCommission = product => {
    let pc = null;
    if (product && product.product_class) {
      pc = product.product_class;
    }
    if (pc) {
      let flat_rate = parseFloat(pc.flat_rate);
      let percent_rate = parseFloat(pc.percentage_rate);

      let float_value = parseFloat(product.price.incl_tax);
      let partner_value = flat_rate;
      let current_percentage = (percent_rate / 100) * float_value;
      
      if (current_percentage > flat_rate) {
        partner_value = current_percentage;
        let orgPrice=(float_value*100)/(100+percent_rate);
        return decimalPlace(orgPrice);
      }

      float_value = float_value - partner_value;
      float_value = decimalPlace(float_value);
      return float_value;
    }
  };

  partnerProductList = () => {
    const {
      classes,
      partner_product_list,
      t,
      auction_notification_count, language,
      showLoader
    } = this.props;

    let dataToReturn = [];
    let listed_item_count =
      auction_notification_count && auction_notification_count.listed_items
        ? auction_notification_count.listed_items
        : null;

    if (listed_item_count && listed_item_count.count > 0) {
      postActionNotificationClicked(listed_item_count.url_to_read);
    }
    if (partner_product_list) {
      if (partner_product_list.count > 0) {
        partner_product_list.results.map((product, i) => {
          //checking the traslation based on the product status
          let product_status = null;
          if (product && product.is_ordered && product.is_ordered.status) {
            product_status = showStatusMessage(
              product.is_ordered.status,
              "seller"
            );
          } else if (product && product.product_status) {
            product_status = showStatusMessage(
              product.product_status,
              "seller",
              product.action_url
            );
          }
          dataToReturn.push(
            <Card
              bordered
              key={i}
              className={
                product_status && product_status.color
                  ? "pointer listed_item "
                  : "pointer"
              }
              style={
                product_status && product_status.color
                  ? {
                      borderColor: product_status ? product_status.color : null
                    }
                  : null
              }
              id={
                i === partner_product_list.results.length - 1
                  ? "lastpartnerProd"
                  :( i === 0?"firstpartnerProd" :  "partnerProd" + i)
              }
            >
              <CardBody className="p-0">
                <div className="d-flex listing-images">
                  <div
                    className={classNames(
                      "d-flex jcc align-items-center",
                      classes.f15
                    )}
                  >
                    <div
                      className={classes.bidListingContainer}
                      onClick={this.showProductDetail(product.url ,product.action_url)}
                    >
                      <img
                        src={product.image}
                        className={classes.img}
                        alt={product.title}
                      />
                    </div>
                  </div>
                  <div className={classes.listDetailContainer + " p-3"}>
                    <div>
                      <div className="dp-f jcsb">
                        <h5
                          className="m-0"
                          onClick={this.showProductDetail(product.url, product.action_url)}
                        >
                          {product.title}
                        </h5>
                        {product.delete_product_url !== null ? (
                          <Close
                            className={classNames(classes.modalClose)}
                            onClick={() =>
                              this.deleteItem(product.delete_product_url)
                            }
                          />
                        ) : null}
                      </div>
                      <div onClick={this.showProductDetail(product.url,product.action_url)}>
                        <h5 className="mb-1">
                          <Muted>
                            {product.price.currency} {this.calculateCommission(product)}{" "} 
                            {/* {product.price.incl_tax} */}
                          </Muted>
                        </h5>
                        <Muted>
                          <Typography component="p" className="listed-product-description">
                            {product.description
                              ? product.description
                              : ""}
                          </Typography>
                        </Muted>
                        {product_status && product_status.message ? (
                          <Badge
                            // color="primary"
                            className={
                              language === "en"
                                ? "dp-f jcfe listing_badge"
                                : "dp-f jcfe listing_badgeAR"
                            }
                            classes={{
                              badge: "productBadge font11 "
                            }}
                          >
                            <span
                              style={{ backgroundColor: product_status.color }}
                              className="order_status"
                            >
                              {product_status.message}
                            </span>
                          </Badge>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          );
          return true;
        });
        if (showLoader) {
          dataToReturn.push(
            <div className="dp-f jcc" key="progress">
              <CircularProgress
                disableShrink
                classes={{ root: classes.indicator }}
              />
            </div>
          );
        }
      } else {
        dataToReturn.push(
          <GridContainer className="mt-4" key="notavailable">
            <p>{t("profileModal.products.data")}</p>
            <GridItem xs={12}>
              <Button
                color="primaryWhiteWithBorder"
                size="lg"
                className="mb-3"
                fullWidth
                onClick={this.openModal("whatSellingModal")}
              >
                {t("homePage.header.sell")}
              </Button>
            </GridItem>
          </GridContainer>
        );
      }
    } else {
      dataToReturn.push(
        <GridContainer className="mt-4" key="notavailable">
          <p>{t("profileModal.products.data")}</p>
          <GridItem xs={12}>
            <Button
              color="primaryWhiteWithBorder"
              size="lg"
              className="mb-3"
              fullWidth
              onClick={this.openModal("whatSellingModal")}
            >
              {t("homePage.header.sell")}
            </Button>
          </GridItem>
        </GridContainer>
      );
    }

    return dataToReturn;
  };

  openModal = modal_type => async () => {
    const { showModal } = this.props;
    showModal(
      {
        open: true,
        title: modal_type,
        nextModal: "profileModal"
      },
      modal_type
    );
  };

  render() {
    const { vendor_fetching, t, partner_product_list } = this.props;
    let nextLink = partner_product_list ? partner_product_list.next : null;
    let elementID = "lastpartnerProd";
    let nextLinkToFetch = nextLink;
    let loadMore = "partner-products";
    if (vendor_fetching) {
      return (
        <Hidden smDown implementation="css">
          <Loader noHeight={true} />
        </Hidden>
      );
    } else {
      return (
        <div className="main-background-color product_listing">
          <h4 className="profile_section">{t("profileModal.products.title")}</h4>
          <div
            className="partnerProductListing scrollbar"
            onScroll={() =>
              ModalPagination.trackScrolling(elementID, nextLinkToFetch, loadMore)
            }
          >
            {this.partnerProductList()}
          </div>
        </div>
      );
   }
  }
}

const mapDispatchToProps = dispatch => ({
  getProductDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  deletePartnerProduct: url => {
    dispatch({
      type: VendorProductActionTypes.DELETE_PARTNER_PRODUCT_REQUEST,
      url
    });
  },
  // Update account page component
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  updatePartnerProducts: data => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_LIST_SUCCESS,
      data
    });
  },
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  resetLoadMore: (payload, currentFetch) => {
    dispatch({
      type: VendorProductActionTypes.SHOW_LOADER,
      payload,
      currentFetch
    });
  },
  getPartnerProducts: () => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_LIST_REQUEST
    });
  },
  getBidDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_BID_DETAIL_REQUEST,
      payload: url
    });
  },
  getCounterDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_COUNTER_OFFER_DETAIL_REQUEST,
      payload: url
    });
  },
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer,
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(ProductListing);
