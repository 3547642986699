import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// React router
import { withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.jsx";

//core comp
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import BreadCrumbs from "kayfik/components/BreadCrumbs/BreadCrumbs.jsx";
// kayfik components
import Tagline from "kayfik/views/Shop/Sections/Tagline.jsx";
import Facets from "kayfik/views/Shop/Sections/Facets.jsx";
import ScrollToTop from "kayfik/components/ScrollToTop/scrollToTop.jsx";
import Header from "kayfik/components/Header/Header.jsx";
import HeaderLinks from "kayfik/components/Header/HeaderLinks.jsx";
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
import ProductGrid from "kayfik/views/Shop/Sections/ProductGrid.jsx";
import Hidden from "@material-ui/core/Hidden";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import LoaderHorizontal from "kayfik/components/Loader/loaderhorizontal.jsx";
import api from "utils/api.jsx";
//actions
import BidActionTypes from "constants/bidConstants.jsx";
import ProductActionTypes from "constants/productConstants.jsx";
import queryString from "query-string";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.checkStaticCategory();
  }

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);

    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }

    return params;
  };

  checkStaticCategory = () => {
    const {
      static_parent_categories,
      getInitialFacets,
      active_parent_category,
      active_category,
      updateSearchUrl,
      UpdateActiveSort
    } = this.props;
    let params = this.getExtraParameter();
    let otherParams = params.params;
    let titleParam = params.q;
    let catParam = "";
    let parentCatParam="";
    let parentCatActive="";
    let catActive = "";
    let sortParams = params.sort_by;
    let attParam = [];
    let url = api.get_product_list();
    let urlToFetch = url;
    
    if (otherParams) {
      if (typeof otherParams === "string") {
        urlToFetch = urlToFetch + "?params=" + otherParams;
        catParam = otherParams.includes("class") ? otherParams : "";
        parentCatParam = otherParams.includes("category") ? otherParams : "";
        attParam = otherParams.includes("attribute") ? otherParams : "";
      } else if (typeof otherParams === "object") {
        catParam = otherParams.find(i => i.includes("class"));
        parentCatParam = otherParams.find(i => i.includes("category"));
        urlToFetch = urlToFetch + "?params=" + otherParams.join("&params=");
        attParam = otherParams.filter(i => i.includes("attribute"));
      }
    }
    if (titleParam) {
      updateSearchUrl(titleParam);
      if (urlToFetch.includes("?")) {
        urlToFetch = urlToFetch + "&q=" + titleParam;
      } else {
        urlToFetch = urlToFetch + "?q=" + titleParam;
      }
    }
    if (sortParams && urlToFetch.indexOf("?")>-1) {
      urlToFetch = urlToFetch + "&sort_by=" + sortParams;
      UpdateActiveSort(sortParams);
    } else {
      UpdateActiveSort(null);
    }
    if (catParam) {
      catActive = catParam.split(":")[1];
    }
    if (parentCatParam) {
      parentCatActive = parentCatParam.split(":")[1];
    }

    if (static_parent_categories && static_parent_categories.length <= 0 && parentCatActive) {
      const category_url_id = parentCatActive;
      const actPC=catActive;
      getInitialFacets(category_url_id,actPC, urlToFetch, sortParams, attParam);
    } else if (static_parent_categories && static_parent_categories.length > 0 && active_parent_category) {
      let catToSend = active_parent_category.text;
      if (parentCatActive) {
        catToSend = parentCatActive;
      }
      let actPC = active_category? active_category.text:catActive;
      getInitialFacets(catToSend, actPC, urlToFetch, sortParams, attParam);
    } else {
      getInitialFacets(parentCatActive,catActive,  urlToFetch, sortParams, attParam);
    }
  };

  navigateToFacets = () => {
    const { history, updateMobileFacetsURL } = this.props;
    updateMobileFacetsURL(window.location.href);
    history.push("/facets");
  };

  render() {
    const {
      classes,
      t,
      active_category,
      product_fetching,
      global_fetching,
      loader_horizontal
    } = this.props;

    return (
      <div>
        {global_fetching ? <Loader fixed /> : null}
        {loader_horizontal === false && product_fetching ? (
          <Loader fixed />
        ) : null}
        {loader_horizontal === true && product_fetching ? (
          <Hidden smUp implementation="css">
            <Loader fixed />
          </Hidden>
        ) : null}
        <ScrollToTop />
        <Header
          id={"header"}
          color="white"
          brand="Kayfik"
          fixed
          links={<HeaderLinks dropdownHoverColor="info" />}
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />
        <div className={classNames(classes.main, "min-70vh")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem sm={12} md={3} className="d-none sm">
                <Facets />
              </GridItem>
              <GridItem md={9} sm={12} className="px-0">
                {loader_horizontal === true && product_fetching? (
                  <Hidden smDown implementation="css">
                    <LoaderHorizontal />
                  </Hidden>
                ) : null}
                <GridContainer>
                  <GridItem xs={12} className="px-0">
                    <Tagline text={t("buyingPage.tagline")} />
                  </GridItem>
                  <GridItem xs={12}>
                    <div className="d-flex justify-content-between align-items-center">
                      <BreadCrumbs
                        linkObjects={[
                          {
                            text: t("buyingPage.categoryProductPage.home"),
                            url: "/"
                          },
                          {
                            text: t("buyingPage.categoryProductPage.shop"),
                            url: "/shop"
                          },
                          {
                            text: active_category
                               && active_category.title ? active_category.title 
                              : t("buyingPage.categoryProductPage.search")
                          }
                        ]}
                      />
                      <Hidden mdUp implementation="css">
                        <Button
                          color="secondaryLight"
                          onClick={this.navigateToFacets}
                        >
                          <i className="fa fa-sliders-h mr-2 ml-2" />{" "}
                          {t("buyingPage.categoryProductPage.filter")}
                        </Button>
                      </Hidden>
                    </div>
                  </GridItem>
                  <ProductGrid />
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <BottomBar />
        <CustomFooter />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBidHistory: () => {
    dispatch({
      type: BidActionTypes.GET_BID_HISTORY_REQUEST
    });
  },
  getProducts: (url, sort_id = null) => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_REQUEST,
      payload: url,
      sort_id
    });
  },
  getInitialFacets: (
    parent_category = null,
    catActive=null,
    urlToFetch = null,
    sortParams = null,
    attParam = null
  ) => {
    dispatch({
      type: ProductActionTypes.GET_INITIAL_FACET_REQUEST,
      parent_cat:parent_category,
      catActive,
      urlToFetch,
      sortParams,
      attParam
    });
  },
  updateMobileFacetsURL: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_MOBILE_FACET_URL,
      value
    });
  },
  updateActiveCategory: (category, active_category_params) => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_CATEGORY,
      category,
      active_category_params
    });
  },
  UpdateActiveSort: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_SORT,
      value
    });
  },
  updateSearchUrl: url => {
    dispatch({
      type: ProductActionTypes.UPDATE_SEARCH_URL,
      setUrl: url
    });
  },
  updateLoaderHorizontal: value => {
    dispatch({
      type: ProductActionTypes.UPDATE_LOADER_HORIZONTAL,
      value
    });
  }
});

const mapStateToProps = state => ({
  ...state.bidReducer,
  ...state.accountReducer,
  ...state.productReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(styles),
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductList);
