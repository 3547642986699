import customInputStyle from "assets/jss/material-kit-pro-react/components/customInputStyle.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";

import {
  htPrimaryGreen,
  htSecondaryLight,
  htPrimaryLight,
  GraphicArabicWeb
} from "kayfik/assets/jss/universal.jsx";

import {
  whiteColor,
  defaultFont
  // grayColor
} from "assets/jss/material-kit-pro-react.jsx";

const customInputStyles = {
  ...customInputStyle,
  ...customSelectStyle,
  input: {
    fontWeight: "unset !important"
  },
  formLabel: {
    ...defaultFont,
    color: htPrimaryGreen,
    fontSize: "0.8rem",
    fontWeight: "400",
    textTransform: "uppercase",
    marginBottom: " 7px",
    fontFamily: GraphicArabicWeb,
    textAlign: "-webkit-auto"
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "0.6rem",
    // },
  },
  formInputStyle: {
    padding: ".375rem .75rem",
    display: "block",
    fontSize: "1rem",
    backgroundColor: htSecondaryLight,
    lineHeight:"1.5rem",
  },
  inputHeight:{
    height: "1.1875em"
  },
  lg: {
    padding: "1.125rem 1rem",
    fontSize: "1rem",
    fontFamily: GraphicArabicWeb + " !important",
    color: "#3C4858 !important"
  },
  sm: {
    padding: "0.40625rem 1rem",
    fontSize: "0.6875rem"
  },
  whiteInput: {
    backgroundColor: whiteColor + " !important",
    border: "solid",
    borderWidth: "0.05rem",
    borderColor: htPrimaryLight,
    color: htPrimaryGreen
  },
  formControl: {
    margin: "0 0 17px 0",
    position: "relative",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: "#006978 !important"
    }
  },
  withAdormentBG: {
    backgroundColor: htSecondaryLight
  },
  selectMenuItemSelectedColor: {
    background: htPrimaryLight + "!important"
  },
  selectMenuItemColor: {
    "&:hover": {
      background: htPrimaryLight + "!important"
    }
  }
};
export default customInputStyles;
