import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
// core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import IconButton from "@material-ui/core/IconButton";


class InitialLoginModal extends React.Component {
  openModal = modal_type => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    let next = modalProps.nextModal;
    let nextPage = modalProps.nextPage;
    let activeSell = modalProps.activeSell;
    let activeCheckout = modalProps.activeCheckout;

    if (modalProps.open) {
      await closeModal();
    }

    let props = {
      open: true,
      title: modal_type
    };

    if (next) {
      props["nextModal"] = next;
    }

    if (nextPage) {
      props["nextPage"] = nextPage;
    }
    if (activeSell) {
      props["activeSell"] = activeSell;
    }
    if (activeCheckout) {
      props["activeCheckout"] = activeCheckout;
    }

    showModal(props, modal_type);
  };

  responseFacebook = response => {
    const { socialLogin, showSnackbar, t } = this.props;

    let raw_access_token = "";

    try {
      raw_access_token = response.accessToken;

      let form_data = {
        access_token: raw_access_token
      };

      socialLogin(form_data, "facebook");
    } catch (e) {
      let txt = t("intialLoginModal.error");
      showSnackbar(txt, "error");
    }
  };

  successResponseGoogle = response => {
    const { socialLogin, showSnackbar, t } = this.props;

    let raw_access_token = "";

    try {
      raw_access_token = response.tokenObj.access_token;

      let form_data = {
        access_token: raw_access_token
      };

      socialLogin(form_data, "google");
    } catch (e) {
      let txt = t("intialLoginModal.error");
      showSnackbar(txt, "error");
    }
  };

  failedResponseGoogle = response => {
    const { showSnackbar, t } = this.props;
    let txt = t("intialLoginModal.error");
    showSnackbar(txt, "error");
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      closeModal,
      t,
      direction,
      modalProps,
      account_fetching
    } = this.props;
    return (
      <div>
        {account_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {modalProps.location !== "checkout"
                  ? t("intialLoginModal.sellwelcome")
                  : t("intialLoginModal.checkoutwelcome")}
              </h2>
              <h4 className="m-0 mb-3">
                {modalProps.location !== "checkout"
                  ? t("intialLoginModal.sellsupporttext")
                  : t("intialLoginModal.checkoutsupporttext")}
              </h4>
              <Button
                color="primaryWhiteWithBorder"
                size="lg"
                fullWidth
                onClick={this.openModal("accountTypeModal")}
              >
                {t("intialLoginModal.email")}
              </Button>
            </GridItem>
          </GridContainer>
          <hr />
          <GridContainer className="mb-5 mt-4">
            <GridItem
              xs={12}
              sm={10}
              md={8}
              className={classNames(
                classes.marginAuto,
                "d-flex " //flex-sm-column
              )}
            >
              <h4 className="m-0">
                {t("intialLoginModal.already")}{" "}
                <span
                  className="color-pl pointer"
                  onClick={this.openModal("loginModal")}
                >
                  {t("intialLoginModal.login")}
                </span>
              </h4>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  socialLogin: (form_data, social_type = "facebook") => {
    dispatch({
      type: AccountActionTypes.SOCIAL_LOGIN_REQUEST,
      form_data,
      social_type
    });
  },
  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.accountReducer
});

export default compose(
  withStyles(modalStyle),
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InitialLoginModal);
