import React from "react";
import PropTypes from "prop-types";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
//action
import ProductActionTypes from "constants/productConstants.jsx";
import api from "utils/api.jsx";


const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

const styles = theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    padding: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: "32px",
    height: "32px",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
    // color: "#006978"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: isChrome ? theme.spacing.unit : 7,
    paddingBottom: isChrome ? theme.spacing.unit : 6,
    // paddingRight: theme.spacing.unit,
    paddingLeft: "32px",
    transition: theme.transitions.create("width"),
    fontSize: "1rem",
    width: 0,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      paddingBottom: 0
    },
    "&:focus": {
      width: 200,
      [theme.breakpoints.down("md")]: {
        width: 150
      },
      [theme.breakpoints.down("sm")]: {
        width: 70
      }
    }
  },
  inputInputAR: {
    paddingRight: "32px",
    paddingTop: isChrome ? theme.spacing.unit : 7,
    transition: theme.transitions.create("width"),
    fontSize: "1rem",
    width: 0,
    "&:focus": {
      width: 200,
      [theme.breakpoints.down("sm")]: {
        width: 70
      }
    }
  }
});

function SearchAppBar(props) {

  const onKeyDown = event => {
    const { getProducts, updateSearchUrl, history, active_parent_category  } = props;
    let url = api.get_product_list();
    if (event.keyCode === 13) {
      let searchUrl ="";
      let search_path="/search?q=" + event.target.value;
      if(props.location.search){
        var queryParams = new URLSearchParams(window.location.search);
        queryParams.set("q", event.target.value);
       

        searchUrl = url + "?"+queryParams.toString();
        search_path="/search/?"+queryParams.toString();
      }
      else if(active_parent_category){
        search_path=search_path+"&params=category_exact:"+active_parent_category.text;
      }
      else{
        searchUrl = url + "?q=" + event.target.value;
      }
      updateSearchUrl(event.target.value);

      if (window.location.search) {
        getProducts(searchUrl);
      }
      if (event.target.value !== "") {
        history.push(search_path);
      }
    }


  };


  const { classes, direction, t } = props;
  const yellowHover = val => {
    if (val === "entered") {
      document.getElementById("searchIconID").classList.add("colorYellow");
    } else if (val === "exit") {
      document.getElementById("searchIconID").classList.remove("colorYellow");
    }
  };
  return (
    <div
      className={classes.search}
      onMouseEnter={() => yellowHover("entered")}
      onMouseLeave={() => yellowHover("exit")}
    >
      <div className={classes.searchIcon}>
        {direction === "rtl" ? (
          <i className="rotate-45">
            <SearchIcon
              classes={{ root: "htPrimaryGreen hoverYellow" }}
              id="searchIconID"
            />
          </i>
        ) : (
          <SearchIcon
            classes={{ root: "htPrimaryGreen hoverYellow" }}
            id="searchIconID"
          />
        )}
      </div>
      <InputBase
        placeholder={t("homePage.header.search")}
        classes={{
          root: classes.inputRoot,
          input: classNames(
            direction === "rtl"
              ? classes.inputInputAR + " htPrimaryGreen "
              : classes.inputInput + " htPrimaryGreen ",
            "searchBariOS"
          )
        }}
        inputProps={{
          tabIndex: 1,
          onKeyDown: onKeyDown,
          name: "searchBar",
          type: "search",
          id: "searchBar"
        }}
      />
    </div>
  );
}

SearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  getProducts: url => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCT_LIST_REQUEST,
      payload: url
    });
  },
  updateSearchUrl: url => {
    dispatch({
      type: ProductActionTypes.UPDATE_SEARCH_URL,
      setUrl: url
    });
  },
  resetSearchParams: () => {
    dispatch({
      type: ProductActionTypes.RESET_SEARCH_PARAMS
    });
  }
});

const mapStateToProps = state => ({
  ...state.productReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(styles),
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchAppBar);
