import {
  section,
  container,
  cardTitle,
  coloredShadow,
  mlAuto,
  mrAuto,
  grayColor,
  title,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

import { htPrimaryLight, htDark } from "kayfik/assets/jss/universal.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";

const styles = {
  ...customCheckboxRadioSwitch,
  ...tooltipsStyle,
  coloredShadow,
  mlAuto,
  mrAuto,
  title,
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    marginBottom: "0px !important"
  },
  cardDescription: {
    color: grayColor[0],
    textAlign: "center"
  },
  container: {
    ...container
  },
  description: {
    color: grayColor[0]
  },
  section: {
    ...section,
    padding: "70px 0px"
  },
  priceContainer: {
    display: "inline-flex"
  },
  price: {
    fontSize: "18px",
    color: grayColor[22]
  },
  pullRight: {
    float: "right"
  },
  cardHeaderImage: {
    position: "relative",
    padding: "0",
    zIndex: "1",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    borderRadius: "6px",
    "& img": {
      width: "100%",
      borderRadius: "6px",
      pointerEvents: "none"
    },
    "& a": {
      display: "block"
    }
  },
  justifyContentBetween: {
    WebkitBoxPack: "justify!important",
    justifyContent: "space-between !important"
  },
  customExpandPanel: {
    //maxHeight: "273px",
    overflowX: "hidden",
    overflowY: "auto",
    "&  label": {
      display: "block",
      marginRight: "0px"
    }
  },
  priceSlider: {
    fontWeight: "500"
  },
  refineButton: {
    margin: "-3px 0"
  },
  cardBodyRefine: {
    paddingLeft: "15px",
    paddingRight: "15px"
  },
  textLeft: {
    textAlign: "left"
  },
  inputAdornmentLeft: {
    marginLeft: "15px",
    marginRight: "0",
    position: "relative"
  },
  svgIcon: {
    width: "22px",
    height: "22px",
    verticalAlign: "middle",
    color: htPrimaryLight
  },

  fontAwesomeIcon: {
    width: "100%",
    height: "100%",
    fontSize: "20px",
    color: htPrimaryLight
  },
  prodGridImage: {
    borderRadius: "0px !important"
  },
  noBorder: {
    borderBottom: " 0px solid #ddd !important"
  },
  selectLabel: {
    fontSize: "12px",
    textTransform: "uppercase",
    color: grayColor[1] + " !important",
    top: "8px"
  },
  accodrianTitle: {
    fontWeight: "600 !important"
  },
  fontBlack: {
    color: htDark + "!important"
  },
  borderBottomPreview: {
    borderTop: "1px solid rgba(" + hexToRgb(htDark) + ", .4)",
    // borderBottom: "1px solid rgba(" + hexToRgb(htDark) + ", .2)",
    width: "100% !important",
    marginTop: "1rem !important",
    marginBottom: "1rem !important"
  }
};

export default styles;
