import {
  primaryColor,
  dangerColor,
  //roseColor,
  grayColor,
  blackColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

import {
  htPrimaryGreen,
  htPrimaryLight,
  htPrimaryYellow,
  htSecondaryLight,
  htDark,
  GraphicArabicWeb,
  htDarkGrey
} from "kayfik/assets/jss/universal.jsx";

const customCheckboxRadioSwitch = {
  checkRoot: {
    padding: "14px",
    textAlign: "-webkit-auto"
  },
  radioRoot: {
    padding: "16px",
    color: htPrimaryLight
  },
  checkboxAndRadio: {
    position: "relative",
    display: "block",
    marginTop: "10px",
    marginBottom: "10px"
  },
  checkboxAndRadioHorizontal: {
    position: "relative",
    display: "block",
    "&:first-child": {
      marginTop: "10px"
    },
    "&:not(:first-child)": {
     marginTop: "-14px" //removed to fix what selling modal style 
    },
    marginTop: "0",
    marginBottom: "0"
  },
  checked: {
    color: htPrimaryGreen + "!important"
  },
  checkedIcon: {
    width: "20px", // mod 20px
    height: "20px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.84)",
    borderRadius: "3px"
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "3px"
  },
  disabledCheckboxAndRadio: {
    "& $checkedIcon,& $uncheckedIcon,& $radioChecked,& $radioUnchecked": {
      borderColor: blackColor,
      opacity: "0.26",
      color: blackColor
    }
  },
  label: {
    paddingRight: "1px",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    transition: "0.3s ease all",
    fontFamily: GraphicArabicWeb,
    textAlign: "-webkit-auto"
  },
  labelAR: {
    paddingLeft: "3px",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    transition: "0.3s ease all",
    fontFamily: GraphicArabicWeb,
    textAlign: "-webkit-auto"
  },
  labelHorizontal: {
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "39px",
    marginRight: "0",
    "@media (min-width: 992px)": {
      float: "right"
    }
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: "22px"
  },
  labelLeftHorizontal: {
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "22px",
    marginRight: "0"
  },
  labelError: {
    color: dangerColor[0]
  },
  radio: {
    color: htPrimaryLight + "!important"
  },
  radioChecked: {
    width: "16px",
    height: "16px",
    color: htPrimaryLight,
    border: "1px solid " + htPrimaryLight,
    borderRadius: "50%"
  },
  radioUnchecked: {
    width: "0px",
    height: "0px",
    padding: "7px",
    border: "1px solid rgba(" + hexToRgb(htPrimaryLight) + ", .54)",
    borderRadius: "50%"
  },
  inlineChecks: {
    marginTop: "8px"
  },
  iconCheckbox: {
    height: "116px",
    width: "116px",
    color: grayColor[0],
    "& > span:first-child": {
      borderWidth: "4px",
      borderStyle: "solid",
      borderColor: grayColor[17],
      textAlign: "center",
      verticalAlign: "middle",
      borderRadius: "50%",
      color: "inherit",
      margin: "0 auto 20px",
      transition: "all 0.2s"
    },
    "&:hover": {
      color: htPrimaryLight,
      "& > span:first-child": {
        borderColor: htPrimaryLight
      }
    }
  },
  iconCheckboxChecked: {
    color: htPrimaryLight,
    "& > span:first-child": {
      borderColor: htPrimaryLight
    }
  },
  iconCheckboxIcon: {
    fontSize: "40px",
    lineHeight: "111px"
  },
  switchBase: {
    color: htPrimaryGreen + "!important"
  },
  switchIcon: {
    boxShadow: "0 1px 3px 1px rgba(" + hexToRgb(blackColor) + ", 0.4)",
    color: whiteColor + "  !important",
    // border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    transform: "none",
    width: "17px",
    height: "17px"
  },
  switchIconAR: {
    boxShadow: "0 1px 3px 1px rgba(" + hexToRgb(blackColor) + ", 0.4)",
    color: whiteColor + "  !important",
    transform: "translateX(-4px)!important",
    marginLeft:"-19px",
  },
  switchIconChecked: {
    borderColor: primaryColor[0],
    transform: "translateX(15px)!important" // 0px
  },
  switchBar: {
    width: "50px", //modified 30px
    height: "20px", // modified 15px
    backgroundColor: "rgba(" + hexToRgb(htPrimaryYellow) + ", 1) !important",
    borderRadius: "15px",
    opacity: "1 !important",
    marginTop: "-9px" //added=
  },
  switchChecked: {
    "& + $switchBar": {
      backgroundColor: "rgba(" + hexToRgb(htPrimaryLight) + ", 1) !important"
    }
  },
  lightGrey: {
    backgroundColor: "rgba(" + hexToRgb(htSecondaryLight) + ", 1) !important"
  },
  stars: {
    color: "rgba(" + hexToRgb(htPrimaryYellow) + ", 1) !important",
    fontSize: "16px !important"
  },
  qtyLabel: {
    background: "rgba(" + hexToRgb(htPrimaryLight) + ", 1) !important",
    width: "60px !important",
    padding: "0px !important"
  },
  qtyLabel2: {
    background: "rgba(" + hexToRgb(htPrimaryLight) + ", 1) !important",
    width: "80px !important",
    padding: "0px !important"
  },
  previewQty: {
    padding: "unset !important"
  },
  previewBtn: {
    padding: " 15px 15px !important",
    fontSize: "10px !important"
  },
  lightText: {
    color: "rgba(" + hexToRgb(htPrimaryLight) + ", 1) !important"
  },
  primaryText: {
    color: "rgba(" + hexToRgb(htPrimaryGreen) + ", 1) !important"
  },
  f10: {
    marginLeft: "10px !important"
  },
  qty: {
    color: "rgba(" + hexToRgb(htPrimaryGreen) + ", 1) !important",
    fontSize: "10px !important"
  },
  borderBottom: {
    borderTop: "1px solid rgba(" + hexToRgb(htDark) + ", .2)",
    // borderBottom: "1px solid rgba(" + hexToRgb(htDark) + ", .2)",
    width: "80% !important"
  },
  borderBottomPreview: {
    borderTop: "1px solid rgba(" + hexToRgb(htDark) + ", .2)",
    // borderBottom: "1px solid rgba(" + hexToRgb(htDark) + ", .2)",
    width: "100% !important"
  },
  dropdown: {
    width: "50px !important",
    height: "30px !important",
    backgroundColor: "rgba(" + hexToRgb(htSecondaryLight) + ", 1) !important"
  },
  closeStyle: {
    width: "30px !important",
    height: "30px !important",
    color: htPrimaryLight + "!important",
    fontWeight: "bold !important"
  },
  qtyInput: {
    margin: ".3125rem 1px !important",
    padding: "0rem !important",
    textAlign: "center !important"
  },
  input: {
    // width:"58px !important",
    paddingLeft: "15px !important",
    height: "52px !important"
  },
  profileHeader: {
    height: "80px",
    background: htPrimaryGreen + "!important",
  },
  profileTabGrid: {
    background: htSecondaryLight + "!important"
  },
  profileContentGrid: {
    paddingTop: "0px !important",
    paddingLeft: "15px !important"
  },
  navRoot: {
    borderRadius: "0px !important",
    margin: "0px !important",
    display: "flex !important",
    justifyContents: "flex-start !important"
  },
  border: {
    border: "2px solid red !important"
  },
  navLabel: {
    textAlign: "start !important"
  },

  htDark: {
    color: htDarkGrey + "!important"
  },
  labelSwitch: {
    paddingLeft: "0",
    color: htPrimaryGreen,
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    transition: "0.3s ease all",
    fontFamily: GraphicArabicWeb,
    textAlign: "-webkit-auto",
  },
  labelSwitchAR: {
    color: htPrimaryGreen,
    marginLeft: "20px",
    marginRight:"26px"
  
  },
  addToCartButtonAR:{
    marginRight:"-14px"
 },
};

export default customCheckboxRadioSwitch;
