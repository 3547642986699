import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import compose from "recompose/compose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// React i18next
import { withTranslation } from "react-i18next";
// core components
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import WorkBag from "@material-ui/icons/Work";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import customInfoStyle from "kayfik/assets/jss/components/Banner/customBannerStyle.jsx";
// react components for routing our app without refresh
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
// redux
import { connect } from "react-redux";
import ModalActionTypes from "constants/modalConstants.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import app from "kayfik/assets/images/app.png";

class Banner extends React.Component {
  openModal = modal_type => async () => {
    const { showModal, closeModal, modalProps, banner_video_url, } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    const video_modalProps = {
      open: true,
      url: banner_video_url //video_url from redux
    };

    showModal(video_modalProps, modal_type);
  };

  getCategoryTree=(categories)=>{
    let cat_tree = categories.filter(i=>i.depth===1 && i.show_on_app);
    return cat_tree;
  }

  categoryDropdownContent=(category)=>{
    let descendants = category.descendants;
    if(descendants){
      descendants = descendants.filter(i=>i.show_on_app);
      return descendants.map((item, index)=>{
          return (
            <Link key={index} className="category_wrapper category_item" to={"/search/?params=category_exact:"+category.id+"&params=product_class_exact:"+item.slug} >
              <img src={item.image? item.image :app} alt={item.name}></img>
              <span className="title" > {item.name}</span>
            </Link>
          )
      })
    }
  }
  renderBanner = categories => {
    const { classes } = this.props;
    if (categories.length > 0) {
      let category_tree = this.getCategoryTree(categories);

      return category_tree.map((item, index) => {
        if(item.show_on_app && item.descendants && item.descendants.length>0){
          return (
            <div key={index} className="text-center">
              <CustomDropdown
                buttonText={
                <div className="category_wrapper"  >
                  <img src={item.image? item.image :app} alt={item.name}></img>
                  <span className="title" > {item.name}</span>
                </div>
              }
              childDropdown={false}
              zoomOnHover={true}
              category_button={true}
              hoverColor="htPrimaryLight"
              dropdownBG={true}
              categories_dropdown={true}
              categories_dropdown_menu={true}
              openOnHover={false}
              className={{
                root: classNames( classes.categories_dropdown),
              }}
              caret={false}
              dropdownList={this.categoryDropdownContent(item)}
              />
            </div>
          )}
          else{
            return (
              <Link key={index} className="text-center" to={"/search/?params=product_class_exact:"+item.slug}>
              <div className="category_button zoom">
              <Button>
                <div className="category_wrapper">
                <img src={item.image? item.image :app} alt={item.name}></img>
                <span className="title" > {item.name}</span>
                </div>
              </Button>
              </div>
              </Link>
            )
          }
      });
    }
  };

  render() {
    const {
      classes,
      cms_data,
      direction,
      t,
      update_BannervideoUrl,
      banner_video_url,
      categories
    } = this.props;
    if (cms_data.home) {
      let sections = cms_data.home.sections;
      // let banner = sections.find(sec => sec.slug === "banner");

      // <------------CMS BANNER VIDEO URL  --------------------->

      let bannerVideo = sections.find(sec => sec.slug === "bannervideo");

      if (bannerVideo && bannerVideo.images[0].link) {
        let videolink = bannerVideo.images[0];
        if (videolink) {
          update_BannervideoUrl(videolink.link);
        }
      }

      // updating banner video URL to redux

      // <------------CMS BANNER VIDEO URL  --------------------->

      if (categories) {
        return (
          <div
            id={this.props.id}
            className={classNames(classes.container + " bg-y py-4")}
          >
            <GridContainer className={classes.banner}>
              <GridItem
                xs={4}
                md={4}
                className={classNames(classes.flex, classes.jcfs)}
              >
                <Button
                  size="lg"
                  className={
                    direction === "rtl" ? "banner_left" : "banner_right"
                  }
                  color="primaryLight"
                  onClick={
                    banner_video_url
                      ? this.openModal("VideoModal")
                      : () => this.props.history.push("/how-it-works")
                  }
                >
                  <span
                    className={
                      direction === "rtl" ? "hide-mobileAR" : "hide-mobile"
                    }
                  >
                    {t("homePage.sectionOne.howItWorks")}
                  </span>
                  <span
                    className={
                      direction === "rtl" ? "show-mobileAR" : "show-mobile"
                    }
                  >
                    {t("homePage.sectionOne.howItWorks")}
                  </span>
                </Button>
              </GridItem>
              <GridItem xs={8} md={4} className="categeory_dropdown_col">
                <div
                  className={classNames(
                    direction === "rtl" ? classes.flex2AR : classes.flex2, "category_tree"
                  )}
                >
                  {this.renderBanner(categories)}
                </div>
              </GridItem>
              <GridItem xs={false} md={4} sm={false} />
            </GridContainer>
          </div>
        );
      }
      return null;
    }
    return null;
  }
}

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  update_BannervideoUrl: url => {
    dispatch({
      type: GlobalActionTypes.UPDATE_BANNER_VIDEO_URL,
      value: url
    });
  }
});

export default compose(
  withStyles(customInfoStyle),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(Banner);
