import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// custom core component
import CustomInput from "kayfik/components/CustomInput/customInput.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import IconButton from "@material-ui/core/IconButton";

// eslint-disable-next-line react/prop-types
class ForgotPasswordModal extends React.Component {

  openModal = modal_type => async () => {
    const { modalProps, closeModal, showModal } = this.props;

    if (modalProps && modalProps.open) {
      await closeModal();
    }
    showModal(
      {
        open: true,
        title: modal_type
      },
      modal_type
    );
  };

  handleSubmit = e => {
    const { forgotpasswordRequest } = this.props;
    e.preventDefault();
    let form_data = {
      email: e.target.email.value
    };
    forgotpasswordRequest(form_data);
  };

  render() {
    const { classes, closeModal, t, direction, account_fetching } = this.props;
    if (account_fetching) {
      return <Loader />;
    }
    return (
      <div>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5">
            <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
              <h2 className={classNames(classes.modalTitle, "htPrimaryGreen")}>
                {t("forgotPassword.title")}
              </h2>
              <h4 className="m-0 mb-3">{t("forgotPassword.message")}</h4>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <CustomInput
                  labelText={t("forgotPassword.email")}
                  id="id_email"
                  size="lg"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: "email",
                    required: true,
                    type: "email",
                    disableUnderline: true
                  }}
                />
                <Button
                  color="primaryWhiteWithBorder"
                  size="lg"
                  className="mb-3"
                  fullWidth
                  type="submit"
                >
                  {t("forgotPassword.button")}
                </Button>
              </form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  forgotpasswordRequest: form_data => {
    dispatch({
      type: AccountActionTypes.FORGOTPASSWORD_REQUEST,
      form_data
    });
  }
});

ForgotPasswordModal.propTypes = {
  forgotpasswordRequest: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  t: PropTypes.func,
  modalProps: PropTypes.object,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(ForgotPasswordModal);
