import React from "react";
// @material-ui/core components
import withWidth from "@material-ui/core/withWidth";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
// core components
import Header from "kayfik/components/Header/Header.jsx";
import Accordion from "kayfik/components/Accordion/Accordion.jsx";
import { withRouter } from "react-router";
// Style
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";
// redux
import { connect } from "react-redux";
// React i18next
import { withTranslation } from "react-i18next";
// Sections
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionHowItWorks from "./Sections/SectionHowItWorks.jsx";
import SectionContact from "kayfik/components/SectionContact/sectionContact.jsx";
import Banner from "kayfik/components/Banner/Banner.jsx";
import ScrollToTop from "kayfik/components/ScrollToTop/scrollToTop.jsx";
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
// REdux
import ModalActionTypes from "constants/modalConstants.jsx";
import GlobalActionTypes from "constants/globalConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import queryString from "query-string";
import Loader from "kayfik/components/Loader/loader.jsx";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
import SectionGrid from "./Sections/SectionGrid.jsx";
// helmet
import { Helmet } from "react-helmet";
import PushNotificationDemo from "kayfik/components/push/PushNotificationComponent.js";
import ProductActionTypes from "constants/productConstants.jsx";
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    // get cms data for home page
    this.props.getCMSData("home"); // this 'home' is the identifier of cms landing page data
  }

  componentDidMount() {
    this.renderModalBaseOnParams();
  }


  componentDidUpdate() {
    const {updateActiveCategory } = this.props;
    updateActiveCategory(null)
  }

  getExtraParameter = () => {
    let params = {};
    params = queryString.parse(window.location.search);
    for (let item in params) {
      let new_item = item.replace("amp;", "");
      params[new_item] = params[item];
    }
    return params;
  };

  renderModalBaseOnParams = () => {
    const { account_token, t, showSnackbar, updateDeviceID } = this.props;
    let params = this.getExtraParameter();
    // Do something if modal exists on the url and match anything from modal_types
    let modal_props = {
      open: true
    };

    if (params.token && params.uid) {
      modal_props["token"] = params.token;
      modal_props["uid"] = params.uid;
      let modal_type = "passwordResetModal";
      this.openModal(modal_type, modal_props)();
    }

    if (params.modal) {
      let target_modal = params.modal;
      let modal_type = target_modal;

      // Force the user to login before opening the next modal
      if (params.modal && !account_token) {
        modal_type = "loginModal";
        modal_props["nextModal"] = target_modal;
        this.openModal(modal_type, modal_props)();
      } else {
        let largeModal = false;
        if (modal_type === "profileModal") {
          largeModal = true;
        }
        modal_props["modalLarge"] = largeModal;
        this.openModal(modal_type, modal_props)();
      }
    }

    if (params.tap_id) {
      let modal_type = "completeOrderModal";
      modal_props["goToShop"] = () => this.props.history.push("/shop");
      modal_props["goToCheckout"] = () => this.props.history.push("/checkout");
      this.openModal(modal_type, modal_props)();
    }
    // open confirm email modal
    if (params.key && params.email) {
      let modal_type = "confirmEmailModal";
      this.openModal(modal_type, modal_props)();
    }
    // check if itwas from success company signup
    if (params.company) {
      let txt = t("loginModal.successLogin");
      showSnackbar(txt, "success");
      this.props.history.push("/");
    }

    if (params.deviceid) {
      updateDeviceID(params.deviceid);
    } 
  };

  openModal = (modal_type, modal_props) => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  renderAccordianOne = () => {
    const { cms_data } = this.props;
    if (cms_data.home) {
      let sections = cms_data.home.sections;
      let accordianOne = sections.find(sec => sec.slug === "accordianone");
      if (accordianOne) {
        return (
          <Accordion
            classes={{
              root: "mb-0"
            }}
            activeColor="secondary"
            collapses={[
              {
                title: accordianOne.title ? accordianOne.title : null
              }
            ]}
            items={accordianOne.images ? accordianOne.images : null}
          />
        );
      }
    }
    return null;
  };

  renderAccordianTwo = () => {
    const { cms_data } = this.props;
    if (cms_data.home) {
      let sections = cms_data.home.sections;
      let accordianTwo = sections.find(sec => sec.slug === "accordiantwo");
      if (accordianTwo) {
        return (
          <Accordion
            classes={{
              root: "mb-0"
            }}
            activeColor="secondary"
            collapses={[
              {
                title: accordianTwo.title ? accordianTwo.title : null
              }
            ]}
            items={accordianTwo.images ? accordianTwo.images : null}
            color={"odd"}
          />
        );
      }
    }
    return null;
  };

  render() {
    const { global_fetching, product_fetching, cms_data } = this.props;

    const headerElement = document.getElementById("header");
    const bannerElement = document.getElementById("banner");
    const bottomElement = document.getElementById("bottombar");

    const header = headerElement ? headerElement.clientHeight : null;
    const banner = bannerElement ? bannerElement.clientHeight : null;
    const bottombar = bottomElement ? bottomElement.clientHeight : null;

    let header_vh = Math.round((100 * header) / window.innerHeight);
    let banner_vh = Math.round((100 * banner) / window.innerHeight);

    let bottom_vh = Math.round((100 * bottombar) / window.innerHeight);
    let final_add = header_vh + banner_vh + bottom_vh;
    let final_carousel = 100 - final_add;
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    var isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    var isDevelopment = navigator.userAgent.indexOf("ht_development_agent") !== -1;
    var isAppleWebKit = !!navigator.userAgent.match(/(?:AppleWebKit)/);
    var isEdge = !!navigator.userAgent.match(/(?:Edg)/);
    return (
      <div id="landing-top">
        {/* For SEO */}
        {cms_data && cms_data.home ? (
          <Helmet>
            <title>{cms_data.home.title}</title>
            <meta name="description" content={cms_data.home.meta_description} />
          </Helmet>
        ) : null}
        {global_fetching || product_fetching ? <Loader fixed /> : null}
        <ScrollToTop />
        <Header id="header" />
        <Banner id="banner" />
        <SectionCarousel id="carousel" carousel_height={final_carousel} />
        {!isDevelopment &&
        (!(isAppleWebKit || iOS || isSafari) || isChrome || isEdge) ? (
          <PushNotificationDemo />
        ) : null}

        {/* <button onClick={()=>this.methodDoesNotExist()}>Break the world</button> */}
        <SectionGrid />
        <SectionHowItWorks />
        <div id={"landing_accordian"}>
          {this.renderAccordianOne()}
          {this.renderAccordianTwo()}
        </div>
        <SectionContact />
        <BottomBar id="bottombar" />
        <CustomFooter />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updateNextModal: nextModal => {
    dispatch({
      type: ModalActionTypes.UPDATE_NEXT_MODAL,
      nextModal: nextModal
    });
  },
  productDetailVariable: value => {
    dispatch({
      type: VendorProductActionTypes.UPDATE_PRODUCT_DETAIL_VARIABLE,
      payload: value
    });
  },
  getCMSData: code => {
    dispatch({
      type: GlobalActionTypes.CMS_PAGE_REQUEST,
      code
    });
  },
  updateDeviceID: deviceid => {
    dispatch({
      type: GlobalActionTypes.UPDATE_DEVICE_ID,
      deviceid
    });
  },

  showSnackbar: (txt, action = "error") => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: action,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },
  updateActiveCategory: category => {
    dispatch({
      type: ProductActionTypes.UPDATE_ACTIVE_CATEGORY,
      category
    });
  },
});
const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(landingPageStyle),
  withWidth(),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)(LandingPage);
