import React from "react";
import classNames from "classnames";
// Core material ui component
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// redux
import { connect } from "react-redux";
// core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";
// material ui Icon
import Close from "@material-ui/icons/Close";
// @material-ui/core components
import { withTranslation } from "react-i18next";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import GlobalActionTypes from "constants/globalConstants.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import AccountActionTypes from "constants/accountConstants.jsx";
import IconButton from "@material-ui/core/IconButton";

class AddToCartModal extends React.Component {
  openModal = (modal_type, modal_props = { open: true }) => async () => {
    // eslint-disable-next-line react/prop-types
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  continueShopping = () => {
    const { closeModal } = this.props;
    this.props.modalProps.shopping();
    closeModal();
  };

  checkoutNow = () => {
    const {
      closeModal,
      account_token,
      userDetails,
      updateGoTo,
      showNextModalFunc
    } = this.props;
    updateGoTo("checkout");
    showNextModalFunc(false);
    if (!account_token) {
      this.openModal("initialLoginModal", {
        open: true,
        nextPage: "/checkout",
        location: "checkout",
        activeCheckout: true
      })();
    } else {
      if (!userDetails.is_email_verified) {
        this.openModal("verifyEmailModal", {
          open: true,
          verifyFrom: "checkout",
          activeCheckout: true
        })();
      } else if (!userDetails.is_phone_verified) {
        this.openModal("verifyPhoneModal", {
          open: true,
          verifyFrom: "checkout",
          activeCheckout: true
        })();
      } else {
        this.props.modalProps.checkoutNow();
      }
    }
    closeModal();
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes, closeModal, cart_fetching, t, direction } = this.props;
    return (
      <div>
        {cart_fetching ? <Loader /> : null}
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            simple="true"
            className={classNames(
              classes.modalCloseButton,
              "previewClose",
              direction === "rtl" ? classes.modalSmallCloseButtonAR : null
            )}
            key="close"
            aria-label="Close"
            onClick={closeModal}
          >
            {" "}
            <Close
              className={classNames(classes.modalClose, classes.closeStyle)}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer className="mb-5 " justify="center">
            <GridItem xs={12} sm={10} md={10} className={classes.marginAuto}>
              <h2
                className={classNames(classes.modalTitle, classes.primaryText)}
              >
                {t("buyingPage.addToCart.product")}
              </h2>
              <h4 className="m-0 mb-3">{t("buyingPage.addToCart.continue")}</h4>
              <div className="py-3" />
              <GridContainer justify="center">
                <GridItem xs={10}>
                  <Button
                    color="primaryWhiteWithBorder"
                    size="lg"
                    fullWidth
                    onClick={this.continueShopping}
                  >
                    {t("buyingPage.addToCart.shopping")}
                  </Button>
                  <Button
                    color="primaryWhiteWithBorder"
                    size="lg"
                    fullWidth
                    onClick={this.checkoutNow}
                  >
                    {t("buyingPage.addToCart.checkout")}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  updateGoTo: goTo => {
    dispatch({
      type: GlobalActionTypes.UPDATE_GO_TO,
      goTo
    });
  },
  showNextModalFunc: showNextModal => {
    dispatch({
      type: AccountActionTypes.SHOW_NEXT_MODAL,
      showNextModal
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.productReducer,
  ...state.cartReducer,
  ...state.globalReducer,
  ...state.accountReducer
});

export default compose(
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(AddToCartModal);
