import contactStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/contactStyle.jsx";

const customSectionStyle = {
    ...contactStyle,
    margin:{
        margin:"0 !important",
    },
    pd0:{
        paddingTop:"0 !important"
    },
    pd13:{
        padding:"13px 30px !important"
    }
  };
  
  export default customSectionStyle;