import React from "react";
import classNames from "classnames";
// @material-ui/core components
//material ui core
import withStyles from "@material-ui/core/styles/withStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Typography } from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";

//redux
import { connect } from "react-redux";
import compose from "recompose/compose";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
//core comp
import GridItem from "components/Grid/GridItem.jsx";
//project styles

import customStepperStyle from "kayfik/assets/jss/components/CustomStepper/customStepperStyle.jsx";

class CustomStepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      skipped: new Set()
    };
  }

  getSteps = () => {
    const { stepProps } = this.props;
    return stepProps; // ['Company Details / Preferences ', 'Payment Information', 'Create Account'];
  };

  isStepOptional = step => {
    // return step === 1;
    return false;
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  renderBack=()=>{
    let protocol = window.location.protocol;
    let host = window.location.hostname;
    let port = window.location.port;
    let target_url = host;
    if (port !== "") {
      target_url = target_url + ":" + port;
    }
    target_url = protocol + "//" + target_url;
    window.location.replace(target_url);
  }

  getStepContent = step => {
    const { stepContentProps } = this.props;
    switch (step) {
      case 0:
        return stepContentProps[0];
      case 1:
        return stepContentProps[1];
      case 2:
        return stepContentProps[2];
      case 3:
        return stepContentProps[3];
      default:
        return this.renderBack();
        
    }
  };


  getHide = index => {
    const { classes, companyForceStep, combinedSteps } = this.props;
    // const { activeStep } = this.state;
    if (combinedSteps) {
      if (companyForceStep === combinedSteps[0] && index === combinedSteps[1]) {
        return classes.hide;
      } else if (companyForceStep >= combinedSteps[1] && index === combinedSteps[0]) {
        return classes.hide;
      } else if (companyForceStep > combinedSteps[1] && index === combinedSteps[0]) {
        return classes.hide;
      }
    }
  };

  render() {
    const { classes, companyForceStep } = this.props;
    const steps = this.getSteps();
    return (
      <div>
        <GridItem
          xs={12}
          sm={10}
          md={10}
          className={classNames("marginAuto", classes.backgroundTransparent)}
        >
          <Stepper
            activeStep={companyForceStep}
            className={classNames(
              "d-i-f",
              classes.stepperRoot,
              classes.stepsContainerMain,
              classes.stepsContainerMainProps,
              classes.backgroundTransparent
            )}
          >
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};
              if (this.isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (this.isStepSkipped(index)) {
                props.completed = false;
              }
              return (
                <Step
                  key={label}
                  {...props}
                  className={classNames(
                    classes.stepsContainerMain,
                    index === companyForceStep
                      ? classes.activeBorder
                      : classes.StepBorder,
                  )}
                >
                  <StepLabel
                    {...labelProps}
                    icon={""}
                    StepIconProps={{
                      className: classes.customIconContainer
                    }}
                    classes={{
                      active: classes.activeStyle,
                      completed: classes.labelStyle,
                      labelContainer: classes.labelContainer,
                      label:
                      index === companyForceStep
                        ? classes.activeStyle
                        : classes.label
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </GridItem>
        <div className={classes.instructions}>
          {this.getStepContent(companyForceStep)}
        </div>
        <GridItem className="sectionSpace" />

      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  }
});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.globalReducer,
  ...state.accountReducer
});
export default compose(
  withStyles(customStepperStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withWidth()
)(CustomStepper);
