import React from "react";
//material ui comp
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import compose from "recompose/compose";
import { connect } from "react-redux";
// core components
import Card from "components/Card/Card.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import ModalActionTypes from "constants/modalConstants.jsx";
import VendorProductActionTypes from "constants/vendorProductConstants.jsx";
import Muted from "components/Typography/Muted.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import AccountActionTypes from "constants/accountConstants.jsx";
import Button from "kayfik/components/CustomButtons/customButton.jsx";
import { decimalPlace, showCurrentOrderStep , getExtraParameter, postActionNotificationClicked} from "utils/helpers.jsx";
import Badge from "components/Badge/Badge.jsx";
import OrderStatusStepper from "./orderStatusStepper.jsx";
import { htPrimaryLight, } from "kayfik/assets/jss/universal.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import api from "utils/api.jsx";

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineDetail: false,
      lineDetailData: null
    };
    
  }
  componentDidMount() {
    this.renderSpecificOrder();
  }

  renderSpecificOrder = () => {
    const {getOrderDetails} = this.props;
    let params = getExtraParameter();
    if (params.orderId) {
      let orderUrl = api.root+"orders/"+params.orderId + "/";
      getOrderDetails(orderUrl);
    }
  };

 
  goToLineDetail = (line) => {
    this.setState({ lineDetail: true, lineDetailData: line });
  }

  getProducts = (lines, bordered) => {
    const { language, t } = this.props;
    return lines.map((line, index) => {
      let product_status = showCurrentOrderStep(line && line.status);
      return (
        <Card bordered={bordered} key={index} onClick={() => this.goToLineDetail(line)} className={"px-2 py-4  " + (!bordered ? " m-0 pt-0" : "  ")}>
          <GridContainer >
            <GridItem xs={5}>
              <h5 className="mb-1"> {line.title} </h5>
            </GridItem>
            <GridItem xs={3}>
              <h5 className="mb-1" >
                {line.price_currency}{" "}{decimalPlace(line.price_incl_tax_excl_discounts)}{" "}
              </h5>
            </GridItem>
            <GridItem xs={4}>
              {product_status ? (
                <Badge
                  color={null}
                  className={
                    language === "en" ? "jcfe order_badge" : "jcfe order_badgeAR"
                  }
                  classes={{
                    badge: "productBadge font_order p-0 text_wrap"
                  }}
                >
                  <span
                    style={{ backgroundColor: htPrimaryLight }}
                    className="order_status"
                  >
                    {product_status}
                  </span>
                </Badge>
              ) : null}
            </GridItem>
          </GridContainer>
          <GridContainer className="px-2">
            <GridItem>
              <div className="verticalSepperContainer my-3">
                <h4>{t("OrderSteps.order_status")}</h4>
                <OrderStatusStepper lineSteps={line.status} activeStep={this.getActiveStep(line.status)} />
              </div>
            </GridItem>
          </GridContainer>
        </Card>
      );
    });
  };
  getActiveStep = (status) => {
    let act = 0;
    act = status.findIndex(i => i.status === true);
    if (act < 0) {
      act = 0;
    }
    return act;
  }

  renderBack = (type) => {
    const { t, direction, order_list_detail } = this.props;


    if (direction === "rtl") {
      return (
        <div>
          <ChevronRight className="image_back" />
          {type && type === "viewOrder"
            ? t("profileModal.viewOrder", {
                number: order_list_detail.number
              })
            : t("profileModal.orders.backtoList")}
        </div>
      );
    } else {
      return (
        <div>
          <ChevronLeft className="image_back" />
          {type && type === "viewOrder"
            ? t("profileModal.viewOrder", {
                number: order_list_detail.number
              })
            : t("profileModal.orders.backtoList")}
        </div>
      );
    }
  };

  renderOfferValues = (array) => {
    const { order_list_detail } = this.props;
    if (array && array.length > 0) {
      return array.map((item, i) => {
        let amount;
        if (parseFloat(item.amount) > 0) {
          amount = parseFloat(item.amount) * -1;
        } else {
          amount = parseFloat(item.amount) * -1;
        }
        let signToShow = " + ";
        if (amount < 0) {
          signToShow = " - ";
        }
        return (
          <GridContainer className="priceSection" key={i}>
            <GridItem xs={6}>
              <h5 className="m-0">{item.name}</h5>
            </GridItem>
            <GridItem xs={6}>
              <h5 className="dp-f jcfe m-0">
                {signToShow}{order_list_detail.currency} {" "}
                {decimalPlace(Math.abs(amount))}
              </h5>
            </GridItem>
          </GridContainer>
        );
      });
    }
  };

  orderDetail = () => {
    const {
      order_list_detail,
      cart_fetching,
      t,
      auction_notification_count,
      updateAccountPageComponent,
      direction
    } = this.props;

      // < --------  cleared order notification count if available -------->
      let order_item_count =
      auction_notification_count && auction_notification_count.order_items
        ? auction_notification_count.order_items
        : null;

        if (order_item_count && order_item_count.count > 0) {
          postActionNotificationClicked(order_item_count.url_to_read);
        }

     // < --------  cleared order notification count if available -------->

    var discounts = order_list_detail && order_list_detail.offer_discounts && order_list_detail.offer_discounts.length > 0 ? order_list_detail.offer_discounts : null;
    var subtotal = 0;
    if (discounts && discounts.length > 0 && order_list_detail && order_list_detail.lines) {
      order_list_detail.lines.map(line => {
        subtotal = subtotal + parseFloat(line.price_incl_tax_excl_discounts);
        return true;
      })
    }
    if (order_list_detail !== null) {
      return (
        <div >
          <Button
            link
            size="lg"
            onClick={() => updateAccountPageComponent("orders")}
            className={direction === "rtl" ? "pr-0" : "pl-0"}
          >
            {this.renderBack("viewOrder")}
          </Button>
          <GridContainer>
            <GridItem xs={6}>
              <h5>
                {t("profileModal.orderNumber")}: {order_list_detail.number}
              </h5>
            </GridItem>
            <GridItem xs={6}>
              <h5 className="dp-f jcfe">
                {" "}
                {t("profileModal.datePlaced")}: {" "}
                {order_list_detail.date_placed
                  .slice(0, 10)
                  .split("-")
                  .join("/")}
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem>
              {cart_fetching ? (
                <Hidden smDown implementation="css">
                  <Loader />
                </Hidden>
              ) : null}
              <div className="d-flex justify-content-center" />
              <Muted>{this.getProducts(order_list_detail.lines, true)}</Muted>

              {subtotal > 0 && <GridContainer className="priceSection">
                <GridItem xs={6}>
                  <h5 className="mb-0">{t("buyingPage.checkOut.reviewcart.subtotal")}</h5>
                </GridItem>
                <GridItem xs={6}>
                  <h5 className="dp-f jcfe mb-0">
                    {order_list_detail.currency}{" "}
                    {decimalPlace(subtotal)}
                  </h5>
                </GridItem>
              </GridContainer>}
              {discounts && discounts.length > 0 &&
                this.renderOfferValues(discounts)
              }
              <GridContainer className="priceSection">
                <GridItem xs={6}>
                  <h5>{t("buyingPage.checkOut.reviewcart.total")}</h5>
                </GridItem>
                <GridItem xs={6}>
                  <h5 className="dp-f jcfe">
                    {order_list_detail.currency} {" "}
                    {decimalPlace(order_list_detail.total_incl_tax)}

                  </h5>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      );
    } else {
      return (
        <div>
          {cart_fetching ? (
            <Hidden smDown implementation="css">
              <Loader />
            </Hidden>
          ) : null}
        </div>
      );
    }
  };



  render() {

    return this.orderDetail();

  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  getProductDetail: url => {
    dispatch({
      type: VendorProductActionTypes.GET_PARTNER_PRODUCT_DETAIL_REQUEST,
      payload: url
    });
  },
  postActionClicked: (url, detailUrl) => {
    dispatch({
      type: VendorProductActionTypes.POST_PICKUP_ACTION_REQUEST,
      payload: url,
      detailUrl: detailUrl
    });
  },
  updateAccountPageComponent: component => {
    dispatch({
      type: AccountActionTypes.UPDATE_ACCOUNT_PAGE_COMPONENT,
      component
    });
  },
  getOrderDetails: url => {
    dispatch({
      type: CartActionTypes.GET_ORDER_DETAILS_REQUEST,
      payload: url
    });
  },
});

const mapStateToProps = state => ({
  ...state.cartReducer,
  ...state.vendorProductReducer,
  ...state.modalReducer,
  ...state.globalReducer
});

export default compose(
  withTranslation(),
  withStyles(modalStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(OrderDetail);
