import React from "react";
import classNames from "classnames";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
// Core material ui component
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import compose from "recompose/compose";
// react component used to create nice image meadia player
//  core component
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import { withTranslation } from "react-i18next";
// redux
import { connect } from "react-redux";
// custom core component
import Button from "kayfik/components/CustomButtons/customButton.jsx";

// material ui Icon
import Close from "@material-ui/icons/Close";
// styles
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
// Constants
import ModalActionTypes from "constants/modalConstants.jsx";
import CartActionTypes from "constants/cartConstants.jsx";
import BidActionTypes from "constants/bidConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";
import Link from "@material-ui/core/Link";
import SharePopOver from "kayfik/components/SharePopOver/sharePopOver.jsx";
import Badge from "components/Badge/Badge.jsx";
import { enqueueSnackbar } from "actions/notifierActions.jsx";
import IconButton from "@material-ui/core/IconButton";
import company_icon from "kayfik/assets/images/company_icon.png";
import Tooltip from "@material-ui/core/Tooltip";
import { checkItemAvailableInCart , getuserDetails } from "utils/helpers.jsx";

class QuickAddModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1
    };
  }

  componentDidMount = () => {
    const { userDetails, quick_add, showSnackbar, t , account_token } = this.props;
    if(account_token){
      getuserDetails() // Update user details to check email is verfied or not
    }
    if (
      userDetails &&
      quick_add &&
      quick_add.vendor &&
      userDetails.pk === quick_add.vendor.pk
    ) {
      showSnackbar(t("buyingPage.productGrid.belong"), "warning");
    }
  };

  isMd = () => {
    if (isWidthDown("md", this.props.width)) {
      return true;
    } else {
      return false;
    }
  };

  renderAttributes = () => {
    const { quick_add } = this.props;
    const p = quick_add.parent_attributes;
    var attributes = [];
    for (var key in p) {
      if (p[key]) {
        attributes.push(
          <div key={key}>
            <h4 className="my-0 mt-2 mb-2">
              {key}: {p[key]}
            </h4>
          </div>
        );
      }
    }
    return attributes;
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleOpenSellModal = () => {
    //const { account_token } = this.props;

    let target_modal = "whatSellingModal";
    let modal_type = target_modal;
    let modal_props = {
      open: true
    };
    this.openModal(modal_type, modal_props)();
  };

  onAddToCart = () => {
    const { quick_add, addToCartRequest, modalProps } = this.props;
    // const { qty } = this.state;
    const data = {
      url: quick_add.url,
      quantity: 1
    };
    const success_modal_type = "addtocartModal";
    const success_modalProps = {
      open: true,
      title: "addtocartModal",
      shopping: modalProps.success_modalProps.shopping,
      checkoutNow: modalProps.success_modalProps.checkoutNow
    };
    addToCartRequest(data, { success_modal_type, success_modalProps });
  };

  renderBuyButton = () => {
    const {
      quick_add,
      t,
      direction,
      classes,
      userDetails,
    } = this.props;

    let is_buy_disable = false;
    let available_status = null;
    if (userDetails && quick_add && quick_add.vendor) {
      is_buy_disable = userDetails.pk === quick_add.vendor.pk;
    }
    //from the helper Class
    if (quick_add && quick_add.url) {
      available_status = checkItemAvailableInCart(quick_add.url);
    }
    //disable add to cart button if item already available on the cart
    if (available_status) {
      is_buy_disable = true;
    }
    if (quick_add.is_buy) {
      return (
        <Button
          color="primaryWhiteWithBorder"
          size="lg"
          className={
            direction === "rtl"
              ? classNames(classes.addToCartButtonAR, "product-button-text")
              : "mb-1 product-button-text"
          }
          fullWidth
          disabled={is_buy_disable}
          onClick={this.onAddToCart}
        >
          {!available_status
            ? t("buyingPage.quickAdd.cart")
            : t("buyingPage.shopProductDetail.item_in_cart")}
        </Button>
      );
    }
  };

  handleOpenBidModal = () => {
    const { account_token, updateBidItem, quick_add, userDetails } = this.props;
    let target_modal = "makeBidModal";
    let modal_type = target_modal;
    let modal_props = {
      open: true
    };
    if (quick_add) {
      updateBidItem({ ...quick_add });
    }

    if (!account_token) {
      modal_type = "loginModal";
      modal_props["nextModal"] = target_modal;
      this.openModal(modal_type, modal_props)();
    }
    else {
      if (userDetails && !userDetails.is_email_verified) {
        this.openModal("verifyEmailModal", {
          open: true,
          verifyFrom: "makeBid",
          activeBid: true
        })();
      } else if (userDetails && !userDetails.is_phone_verified) {

        this.openModal("verifyPhoneModal", {
          open: true,
          verifyFrom: "makeBid",
          activeBid: true
        })();
      } else {
        this.openModal(modal_type, modal_props)();
      }

    }
  };

  openModal = (modal_type, modal_props) => async () => {
    const { modalProps, closeModal, showModal } = this.props;
    if (modalProps.open) {
      await closeModal();
    }
    showModal(modal_props, modal_type);
  };

  renderBidButton = () => {
    const { quick_add, t, direction, classes, userDetails } = this.props;
    let available_status = null;
    if (quick_add && quick_add.url) {
      available_status = checkItemAvailableInCart(quick_add.url);
    }
    if (quick_add && quick_add.is_auction && !available_status) {
      return (
        <Button
          color="primaryWhiteWithBorder"
          size="lg"
          fullWidth
          disabled={
            !quick_add.is_allowed_to_bid[0] ||
            (userDetails &&
              quick_add &&
              quick_add.vendor &&
              userDetails.pk === quick_add.vendor.pk)
          }
          className={
            direction === "rtl"
              ? classNames(classes.addToCartButtonAR, "product-button-text")
              : "mb-1 product-button-text"
          }
          onClick={this.handleOpenBidModal}
        >
          {quick_add &&
            quick_add.is_allowed_to_bid &&
            quick_add.is_allowed_to_bid[0] === false
            ? t("buyingPage.shopProductDetail.bid_unAvailable")
            : t("buyingPage.shopProductDetail.bid")}
        </Button>
      );
    }
    return null;
  };

  renderButton = () => {
    const { quick_add, t } = this.props;
    if (quick_add !== null) {
      if (quick_add.availability.is_available_to_buy !== true) {
        return (
          <Button
            color="primaryWhiteWithBorder"
            size="lg"
            fullWidth
            disabled={true}
          >
            {t("buyingPage.quickAdd.unAvailable")}
          </Button>
        );
      } else {
        return (
          <div>
            {this.renderBuyButton()}
            {this.renderBidButton()}
          </div>
        );
      }
    }
  };

  shareActionTitle = () => {
    const { t, direction } = this.props;
    if (direction === "rtl") {
      return (
        <h5 className="color-pl m-0">
          {t("buyingPage.quickAdd.share")}{" "}
          <i className={"fas fa-share fa-flip-horizontal  "} />
        </h5>
      );
    } else {
      return (
        <h5 className="color-pl m-0">
          {t("buyingPage.quickAdd.share")} <i className={"fas fa-share "} />
        </h5>
      );
    }
  };
  renderProductTypeIcon = () => {
    const { quick_add, t , classes} = this.props;
    let is_company = quick_add && quick_add.vendor && quick_add.vendor.is_vendor_type === "COMPANY" && quick_add.vendor.company_approved=== true? true : false;
    if (is_company) {
      return (
        <Tooltip
          title={t("buyingPage.shopFacets.verifiedCompany")}
          placement="top"
          enterTouchDelay={0}
          classes={{
            tooltip: classNames(classes.tooltip)
          }}
        >
          <img
            src={company_icon}
            className="typeIcon productDetailIcon"
            alt={t("buyingPage.shopFacets.verifiedCompany")}
          />
        </Tooltip>
      );
    }
  };

  renderVerfiedTitle = () => {
    const { quick_add, t } = this.props;
    let is_company =
      quick_add &&
      quick_add.vendor &&
      quick_add.vendor.is_vendor_type === "COMPANY" &&
      quick_add.vendor.company_approved === true
        ? true
        : false;
    if (is_company) {
      return (
        <Badge
          className="quick-add-text-badge image-badge-prodDetail"
          classes={{
            badge: "productBadge"
          }}
        >
          <span className="verification-text-Background">
            {" "}
            {t("buyingPage.shopFacets.verifiedCompany")}{" "}
          </span>
        </Badge>
      );
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      classes,
      closeModal,
      quick_add,
      cart_fetching,
      t,
      direction
    } = this.props;

    let is_company =
      quick_add &&
      quick_add.vendor &&
      quick_add.vendor.is_vendor_type === "COMPANY" &&
      quick_add.vendor.company_approved === true
        ? true
        : false;

    return (
      <DialogContent
        id="classic-modal-slide-description"
        className={classNames(classes.modalBody, "p-0")}
      >
        {cart_fetching ? <Loader /> : null}
        <IconButton
          simple="true"
          className={classNames(
            classes.modalCloseButton,
            "previewClose",
            direction === "rtl" ? classes.modalSmallCloseButtonAR : null,
            this.isMd() && this.renderProductTypeIcon()? "mt-5 p-2" : "mt-1",

          )}
          key="close"
          aria-label="Close"
          onClick={closeModal}
        >
          {" "}
          <Close
            className={classNames(
              classes.modalClose,
              classes.closeStyle,
              this.isMd() ? "mt-1" : "mt-0",
            )}
          />
        </IconButton>
        <GridContainer className="m-0">
          <GridItem xs={12} sm={12} md={7} className={"p-0 "}>
            <img
              src={quick_add.image}
              className="previewProduct"
              alt="previewProduct"
            />
           
            {/* {this.renderProductTypeIcon()} */}
            {quick_add.is_pending ? (
              <Badge
                color="gray"
                className="image-badge"
                classes={{
                  badge: "productBadge"
                }}
              >
                <span className="pendingVerificationBackground">
                  {" "}
                  {t("buyingPage.shopProductDetail.pending")}{" "}
                </span>
              </Badge>
            ) : null}
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={5}
            className="bg-secondary-light quickAdd "
          >
            <GridItem xs={11} sm={11} md={11} className="marginAuto">
              <GridContainer>
                <GridItem
                  sm={6}
                  md={12}
                  className={classNames(
                    "p-0",
                    this.isMd() && this.props.width !== "xs" ? "py-4" : ""
                  )}
                >
                  {this.renderVerfiedTitle() ?
                  <div className="pt-5">{this.renderVerfiedTitle()}</div> : null}
                  <h4
                    className={
                      (classNames(classes.modalTitle),
                      is_company ? "pt-0" : "pt-5")
                    }
                  >
                    {quick_add.title}
                  </h4>
                  {quick_add && quick_add.price && quick_add.price.incl_tax ? (
                    <h4
                      className={direction === "rtl" ? "similar_arabic" : null}
                    >
                      {t("common.priceamount", {
                        amount: quick_add.price.incl_tax
                      })}
                    </h4>
                  ) : null}
                  <p>{quick_add.description}</p>

                  {this.renderAttributes()}
                </GridItem>
                <GridItem
                  sm={6}
                  md={12}
                  className={classNames(
                    this.isMd() && this.props.width !== "xs" ? "py-5" : "p-0",
                    "pr-0  mb-5"
                  )}
                >
                  <div className={classes.borderBottomPreview} />
                  <GridContainer className={classes.marginAuto}>
                    <GridItem
                      xs={10}
                      sm={10}
                      md={10}
                      className={classNames("py-2", "pl-0")}
                    >
                      {this.renderButton()}
                    </GridItem>
                    <div className={classes.borderBottomPreview} />
                  </GridContainer>
                  <div className="d-flex">
                    <SharePopOver
                      noLiPadding
                      noPosition
                      dropPlacement={"right-start"}
                      caret={false}
                      className={classes.svgIcon + " pointer"}
                      action_title={this.shareActionTitle()}
                      product={quick_add}
                    />
                  </div>
                  <div className="d-flex">
                    <h5
                      className={direction === "rtl" ? "m-0 ml-1" : "m-o mr-1"}
                    >
                      {t("buyingPage.quickAdd.sell")}
                    </h5>
                    <Link
                      underline="none"
                      component="button"
                      onClick={this.handleOpenSellModal}
                    >
                      <h5 className="color-pl m-0">
                        {t("buyingPage.quickAdd.now")}
                      </h5>
                    </Link>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
        </GridContainer>
      </DialogContent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showModal: (modalProps, modalType) => {
    dispatch({
      type: ModalActionTypes.SHOW_MODAL_REQUEST,
      modalProps,
      modalType
    });
  },
  addToCartRequest: (data, success_modal) => {
    dispatch({
      type: CartActionTypes.ADD_TO_CART_REQUEST,
      payload: data,
      success_modal: success_modal
    });
  },
  updateBidItem: data => {
    dispatch({
      type: BidActionTypes.UPDATE_BID_ITEM,
      data
    });
  },
  showSnackbar: (txt, variant) => {
    dispatch(
      enqueueSnackbar({
        message: txt,
        options: {
          variant: variant,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }
      })
    );
  },

});

const mapStateToProps = state => ({
  ...state.modalReducer,
  ...state.productReducer,
  ...state.bidReducer,
  ...state.cartReducer,
  ...state.globalReducer,
  ...state.accountReducer
});

export default compose(
  withStyles(modalStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation(),
  withWidth()
)(QuickAddModal);
