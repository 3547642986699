import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import compose from "recompose/compose";
// redux
import { connect } from "react-redux";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// custom core components
import Header from "kayfik/components/Header/Header.jsx";
import ScrollToTop from "kayfik/components/ScrollToTop/scrollToTop.jsx";
import CustomStepper from "kayfik/components/CustomStepper/CustomStepper.jsx";
import BreadCrumbs from "kayfik/components/BreadCrumbs/BreadCrumbs.jsx";
// React i18next
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
// Style
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";
// Sections
import CompanyDetails1 from "./Sections/companyDetails1.jsx";
import CompanyDetails2 from "./Sections/companyDetails2.jsx";
import SectionContact from "kayfik/components/SectionContact/sectionContact.jsx";
import CustomFooter from "kayfik/components/Footer/Footer.jsx";
import BottomBar from "kayfik/components/BottomBar/BottomBar.jsx";
// Constants
import AccountActionTypes from "constants/accountConstants.jsx";
import AddressActionTypes from "constants/addressConstants.jsx";
import Loader from "kayfik/components/Loader/loader.jsx";

class CompanySignup extends React.Component {
  constructor(props) {
    super(props);
    this.checkIfLoginIn();
  }

  componentDidMount() {
    const { update_company_force_step, update_user_location,getAreaList , updateMapAddress} = this.props;
    update_company_force_step(0);
    getAreaList();
    updateMapAddress({});
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.props.update_map_loader(true);
         
          this.setState(
            {
              currentLat: position.coords.latitude,
              currentLong: position.coords.longitude,
              error: null
            },
            () => {
              const location = {
                currentLat: this.state.currentLat,
                currentLong: this.state.currentLong
              };
              update_user_location(location);
              this.props.update_map_loader(false);
            }
          );
        },
        error =>{ this.setState({ error: error.message });  this.props.update_map_loader(false);}
        , { enableHighAccuracy: true, timeout: 200000 }
      );
    }
  }

  checkIfLoginIn = () => {
    const { account_token, history } = this.props;
    if (account_token) {
      history.push("/");
    }
  };

  render() {
    const {
      classes,
      t,
      direction,
      account_fetching,
      global_fetching,
    } = this.props;
    const steps = [
      t("companySignup.companyDetailsPreference"),
      t("companySignup.createAccount")
    ];
    const stepContents = [
      <CompanyDetails1 key="0" />,
      <CompanyDetails2 key="1" />
    ];
    const continueButton = [
      t("companySignup.saveAndContinue"),
      t("companySignup.saveAndContinue")
    ];
    const continueData = [
      <span key="0">
        {t("companySignup.companyDetailOne.finishregister")}
        <br />
        <span className="color-pl">
          {t("companySignup.companyDetailOne.contactUs")}
        </span>
      </span>,
      <span key="1">
        {t("companySignup.companyDetailOne.finishregister")}
        <br />
        <span className="color-pl">
          {t("companySignup.companyDetailOne.contactUs")}
        </span>
      </span>
    ];
    const combinedSteps = [0, 1];
    return (
      <div id="landing-top">
        {account_fetching || global_fetching ? <Loader fixed /> : null}
        <Header />
        <ScrollToTop />
        <div className="cd-section main-background-color min-70vh">
          <GridContainer className="bg-pl">
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classNames(classes.container, "marginAuto")}
            >
              <div className={classes.sectionSpace} />
              <h2 className={classNames("white-text m-0  px-3")}>
                {t("companySignup.shop")}
              </h2>
              
              <h5 className={"white-text  px-3"}>{t("companySignup.build")}</h5>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classNames(classes.container, "marginAuto")}
            >
              <div className="px-3 py-4 my-4">
                <BreadCrumbs
                  separator={ direction === "rtl" ? <NavigateBeforeIcon fontSize="small" /> : <NavigateNextIcon fontSize="small" />}
                  linkObjects={[
                    {
                      text: t("companySignup.home"),
                      url: "/"
                    },
                    {
                      text: t("companySignup.title")
                    }
                  ]}
                />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={classNames(classes.container, "marginAuto","company-title")}
            >
              <CustomStepper
                classes={{
                  stepsContainerMainProps: classes.stepsContainerMainProps,
                  backButtonPadding: "backButtonPadding"
                }}
                continueData={continueData}
                continueButton={continueButton}
                stepProps={steps}
                stepContentProps={stepContents}
                getStepContent={this.getStepContent}
                combinedSteps={combinedSteps}
              />
            </GridItem>
          </GridContainer>
          <SectionContact />
        </div>
        <BottomBar />
        <CustomFooter />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  update_company_force_step: number => {
    dispatch({
      type: AccountActionTypes.UPDATE_COMPANY_STEP,
      data: number
    });
  },
  update_user_location: location => {
    dispatch({
      type: AddressActionTypes.USER_CURRENT_LOCATION,
      payload: location
    });
  },
  getAreaList: () => {
    dispatch({
      type: AddressActionTypes.GET_AREA_LIST_REQUEST
    });
  },
  updateMapAddress: address => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_ADDRESS,
      address
    });
  },
  update_map_loader: mapLoader => {
    dispatch({
      type: AddressActionTypes.UPDATE_MAP_LOADER,
      mapLoader
    });
  },
});

const mapStateToProps = state => ({
  ...state.accountReducer,
  ...state.globalReducer
});

export default compose(
  withStyles(projectsStyle),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(CompanySignup);
