import cardStyle from "assets/jss/material-kit-pro-react/components/cardStyle.jsx";

import { whiteColor, hexToRgb } from "assets/jss/material-kit-pro-react.jsx";

const cardNoBorderStyle = {
  ...cardStyle,
  cardBackground: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    textAlign: "center",
    color: whiteColor,
    minHeight: "480px",
    "& h3": {
      color: whiteColor + " !important"
    },
    "& p": {
      color: "rgba(" + hexToRgb(whiteColor) + ",0.7)!important"
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.56)",
      borderRadius: "0"
    },
    "& small": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.7) !important"
    }
  }
};

export default cardNoBorderStyle;
